import {
  IPitch,
  IPitchPrediction,
  IPressRelease,
} from '../../../../../../../api.types';
import { FC, useMemo } from 'react';
import * as S from '../PdfExport.styles';
import ProphetLogoIcon from '../../../../../../../assets/icons/ProphetLogo';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import Typography from '../../../../../../../components/Typography';
import { formatDate } from '../../../../../../../../utils/helpers';
import Pill from '../../../../../../../components/UI/Pill';
import Avatar from '../../../../../../../components/UI/Avatar';
import { getTotalSamplesFromPredictionReports } from '../PdfExport.helpers';

interface IProps {
  pitch: IPitch | IPressRelease;
  prediction: IPitchPrediction;
  contentType: 'PRESS_RELEASE' | 'PITCH';
}

const PdfCover: FC<IProps> = ({ pitch, prediction, contentType }) => {
  const { headline, publishDate } = useMemo(() => {
    if (contentType === 'PRESS_RELEASE') {
      return {
        headline: (pitch as IPressRelease).title,
        publishDate: formatDate(pitch.createdAt),
      };
    }

    return {
      headline: (pitch as IPitch).headline,
      publishDate: formatDate((pitch as IPitch).publishDate),
    };
  }, [contentType, pitch]);

  return (
    <S.Page dark>
      <S.PageContent>
        <S.PageHeader>
          <ProphetLogoIcon />
          <FlexBlock
            justifyContent="flex-end"
            alignItems="center"
            columnGap="16px"
          >
            <Typography.Text $colorName="aluminum">
              {publishDate}
            </Typography.Text>
            <Pill variant="pitch-version" pitchVersion={pitch.status}>
              V{prediction.version}
            </Pill>
          </FlexBlock>
        </S.PageHeader>

        <FlexBlock alignItems="center" flex={1}>
          <FlexBlock padding="0 92px">
            <Avatar
              margin="24px 24px 24px 0"
              url={pitch.project.logo}
              name={pitch.project.name}
              size="84"
            />
            <FlexBlock flexDirection="column">
              <Typography.Text
                $colorName="white"
                $dmSans
                $bold
                $size="48"
                $lineHeight="54"
                $margin="32px 0 0 0"
              >
                {headline}
              </Typography.Text>
              <FlexBlock padding="56px 0 0" columnGap="50px">
                <FlexBlock flexDirection="column">
                  <Typography.Text $dmSans $bold $colorName="white" $size="16">
                    Timing
                  </Typography.Text>
                  <Typography.Text $colorName="white">
                    {publishDate}
                  </Typography.Text>
                </FlexBlock>

                <FlexBlock flexDirection="column">
                  <Typography.Text $dmSans $bold $colorName="white" $size="16">
                    Sample Total
                  </Typography.Text>
                  <Typography.Text $colorName="white">
                    {getTotalSamplesFromPredictionReports(prediction)}
                  </Typography.Text>
                </FlexBlock>

                <FlexBlock flexDirection="column">
                  <Typography.Text $dmSans $bold $colorName="white" $size="16">
                    Countries
                  </Typography.Text>
                  <Typography.Text $colorName="white">
                    {prediction.countries
                      ?.map(country => country.displayName)
                      ?.join(', ')}
                  </Typography.Text>
                </FlexBlock>
              </FlexBlock>
            </FlexBlock>
          </FlexBlock>
        </FlexBlock>
      </S.PageContent>
    </S.Page>
  );
};

export default PdfCover;
