import { FC, useMemo } from 'react';
import * as S from './SocialPostCard.style';
import FlexBlock from '../UI/FlexBlock';
import Typography from '../Typography';
import Button from '../UI/Button/Button';
import Gap from '../UI/Gap';
import CopyIcon from '../../assets/icons/CopyIcon';
import { getSocialConfig, SocialType } from './SocialPostCard.config';
import { showErrorToast, showSuccessToast } from '../../../utils/toast';

interface IProps {
  social: SocialType;
  content: string;
}

const SocialPostCard: FC<IProps> = props => {
  const contentHtml: string = useMemo(() => {
    return props.content.replace(/#([^ ]+)/g, "<span class='hash'>#$1</span>");
  }, [props.content]);

  const config = useMemo(() => {
    return getSocialConfig(props.social);
  }, [props.social]);

  const onTextCopy = async () => {
    try {
      await window.navigator.clipboard.writeText(props.content);
      showSuccessToast('Text copied to clipboard!');
    } catch (e) {
      showErrorToast(
        'Your browser does not support this function.',
        'Failed to copy text.',
      );
    }
  };

  return (
    <S.Wrapper>
      <FlexBlock justifyContent="space-between">
        <FlexBlock alignItems="center" columnGap="8px">
          <S.IconWrapper color={config.color}>{config.icon}</S.IconWrapper>
          <Typography.Text
            $dmSans
            $bold
            $size={18}
            $colorName="nero"
            capitalize
          >
            {props.social}
          </Typography.Text>
        </FlexBlock>

        <S.ButtonRow>
          <Button compact variant="gray" onClick={onTextCopy}>
            <CopyIcon />
            <Gap size={8} />
            Copy
          </Button>
        </S.ButtonRow>
      </FlexBlock>
      <S.PostContent dangerouslySetInnerHTML={{ __html: contentHtml }} />
    </S.Wrapper>
  );
};

export default SocialPostCard;
