import { createGlobalStyle } from 'styled-components';
import RiftBold from '../fonts/Rift-Bold.otf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Rift';
    src: url(${RiftBold}) format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: auto;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body,button {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
    height:100%;
  }


  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  a {
    text-decoration: none;
  }
  
  :root {
  --toastify-toast-width: 700px;
  }

  .Toastify__toast {
    border-radius: 12px;
    align-items: center;
  }
  
  .Toastify__close-button {
    align-self: center;
    display: none;
  }
  
  .toast-violet {
    background: #372175 !important; 
  }

  .toast-red {
    background: #ca3023 !important; 
  }
`;
