import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

export const useClickOutsideListener = (
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target) && open) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      if (open) {
        document.removeEventListener('click', handleClickOutside);
      }
    };
  }, [open]);

  return ref;
};
