import { FC } from 'react';
import FlexBlock from '../../FlexBlock';
import { SkeletonDiv } from '../Skeletons.styles';

interface IArticlesSkeletonProps {
  numberOfArticles: number;
}

const ArticlesSkeleton: FC<IArticlesSkeletonProps> = ({ numberOfArticles }) => {
  return (
    <>
      {Array.from({ length: numberOfArticles }).map((_, index) => (
        <FlexBlock
          key={index}
          flexDirection="column"
          maxWidth="90%"
          rowGap="7px"
          margin="20px auto"
          alignItems="left"
        >
          <SkeletonDiv
            shimmerAnimation
            height="20px"
            borderRadius="12px"
            width="20%"
          />
          <SkeletonDiv
            shimmerAnimation
            height="20px"
            borderRadius="12px"
            width="45%"
          />
          <SkeletonDiv
            shimmerAnimation
            height="20px"
            borderRadius="12px"
            width="100%"
          />
          <SkeletonDiv
            shimmerAnimation
            height="90px"
            borderRadius="12px"
            width="100%"
          />
        </FlexBlock>
      ))}
    </>
  );
};

export default ArticlesSkeleton;
