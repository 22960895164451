import { downloadFile, formatDate } from 'utils/helpers';
import {
  createPermanentToast,
  showErrorToast,
  showSuccessToast,
} from 'utils/toast';
import { request } from 'utils/request';
import Typography from 'app/components/Typography';
import { ReactElement, Ref, forwardRef } from 'react';

export enum ExportSourceType {
  JOURNALIST = 'JOURNALIST',
  TARGET_MEDIA = 'TARGET-MEDIA',
}

// To Do: Upgrade endpoint
async function downloadMediaListCsv(mediaListIds: string[] | number[]) {
  return request({
    method: 'POST',
    url: `/media-list/export`,
    data: {
      mediaListIds: mediaListIds,
    },
    responseType: 'blob',
  });
}

async function downloadJournalistsCsv(journalistsIds: string[] | number[]) {
  return request({
    method: 'POST',
    url: `/v2/author/export`,
    data: {
      journalistsIds,
    },
    responseType: 'blob',
  });
}

export const handleMediaListExport = async (
  journalistsIds: string[] | number[],
  source: ExportSourceType = ExportSourceType.JOURNALIST,
) => {
  const exportProgressMessage = (
    <Typography.Text $dmSans $bold $colorName="white" $size={16}>
      Exporting journalists list...
    </Typography.Text>
  );

  const exportProgressToast = createPermanentToast(exportProgressMessage);

  try {
    let file;

    if (source === ExportSourceType.TARGET_MEDIA) {
      file = await downloadMediaListCsv(journalistsIds);
    } else {
      file = await downloadJournalistsCsv(journalistsIds);
    }

    const currentDate = formatDate(new Date().toISOString(), 'MM-DD-YYYY');
    const exportFileName = `PRophet Media List (${currentDate}).xlsx`;
    downloadFile(exportFileName, URL.createObjectURL(file));
    showSuccessToast(
      source === ExportSourceType.JOURNALIST
        ? `Exported ${journalistsIds.length} ${
            journalistsIds.length > 1 ? 'journalists' : 'journalist'
          }`
        : `Media list exported`,
    );
  } catch (error) {
    showErrorToast('Could not export journalists list. Please try again.');
  } finally {
    exportProgressToast.close();
  }
};

export function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: Ref<T>) => ReactElement | null,
): (props: P & React.RefAttributes<T>) => ReactElement | null {
  return forwardRef(render) as any;
}
