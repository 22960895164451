import {
  IAuthorResponse,
  IAuthorSuggestEditPayload,
} from '../../../../../../../api.types';
import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import * as S from '../DigDeeperSlideout.styles';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import Button from '../../../../../../../components/UI/Button/Button';
import ArrowLeftIcon from '../../../../../../../assets/icons/ArrowLeftIcon';
import Typography from '../../../../../../../components/Typography';
import RoundIconWrapper from '../../../../../../../components/UI/RoundIconWrapper';
import { useSuggestAnEditMutation } from '../../../../../../../api';
import * as Form from '../../../../../../../components/UI/Form';
import Input from '../../../../../../../components/UI/Input/Input';
import TwitterIcon from '../../../../../../../assets/icons/TwitterIcon';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../../../utils/toast';
import Gap from '../../../../../../../components/UI/Gap';
import { validateEmail } from '../../../../../../../../utils/helpers';
import { IDigDeeperCreator } from 'app/containers/Pitch/slice/types';

interface IProps {
  creator: IDigDeeperCreator;
  onClose: () => void;
}

interface ISuggestionDataState {
  firstName?: string;
  lastName?: string;
  emails?: string[];
  twitter?: string;
}

const SuggestAnEdit: FC<IProps> = ({ creator, onClose }) => {
  const [suggestionData, setSuggestionData] =
    useState<ISuggestionDataState | null>(null);
  const [madeChanges, setMadeChanges] = useState(false);

  useEffect(() => {
    storeAuthorInLocalState();
  }, [creator]);

  const storeAuthorInLocalState = () => {
    if (creator) {
      const [firstName, ...otherNames] = creator.name.split(' ');
      const emails = creator.email?.split(',').filter(e => !!e);
      setSuggestionData({
        firstName: firstName || '',
        lastName: otherNames.join(' ') || '',
        emails: emails || [''],
        twitter:
          creator.socials.find(s => s.socialType === 'TWITTER')?.url || '',
      });
      setMadeChanges(false);
    }
  };

  const handleChange: (
    type: 'firstName' | 'lastName' | 'twitter',
  ) => ChangeEventHandler<HTMLInputElement> = type => event => {
    if (!suggestionData) return;
    setSuggestionData(state => ({
      ...state,
      [type]: event.target.value || '',
    }));
    setMadeChanges(true);
  };

  const handleEmailChange: (
    index: number,
  ) => ChangeEventHandler<HTMLInputElement> = index => event => {
    setSuggestionData(state => ({
      ...state,
      emails: state?.emails?.map((email, i) =>
        index === i ? event.target.value : email,
      ) || [''],
    }));
    setMadeChanges(true);
  };

  const addBlankEmail = () => {
    setSuggestionData(state => ({
      ...state,
      emails: [...(state?.emails || []), ''],
    }));
  };

  const [submitSuggestion, { isLoading, isSuccess, isError }] =
    useSuggestAnEditMutation();

  const handleSubmit = () => {
    const hasDuplicateEmails = suggestionData?.emails?.some(
      (email, i) => suggestionData?.emails?.indexOf(email) !== i,
    );

    if (hasDuplicateEmails) {
      showErrorToast('Duplicate emails are not allowed');
      return;
    }

    const allEmailsValid = suggestionData?.emails?.every(email =>
      validateEmail(email),
    );

    if (!allEmailsValid) {
      showErrorToast('Some of email addresses are invalid');
      return;
    }

    const payload: IAuthorSuggestEditPayload = {
      name: `${suggestionData?.firstName || ''} ${
        suggestionData?.lastName || ''
      }`,
      email: suggestionData?.emails?.join(',') || '',
      comments: '',
      notes: '',
      socials: [],
    };

    if (suggestionData?.twitter) {
      payload.socials.push({
        socialType: 'TWITTER',
        url: suggestionData.twitter,
      });
    }

    submitSuggestion({ id: creator.id, ...payload });
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Suggestion Submitted');
      handleClose();
    }
    if (isError) {
      showErrorToast('Unknown error');
    }
  }, [isSuccess, isError]);

  const handleClose = () => {
    onClose();
    storeAuthorInLocalState();
  };

  return (
    <>
      <S.Section noBorder padding="24px">
        <FlexBlock columnGap="24px">
          <FlexBlock>
            <Button variant="icon-button-border" onClick={handleClose}>
              <ArrowLeftIcon size={16} />
            </Button>
          </FlexBlock>
          <FlexBlock flexDirection="column" rowGap="4">
            <Typography.Text $dmSans $bold $size="20">
              Suggest An Edit
            </Typography.Text>
            <Typography.Text $colorName="steel">
              Below are the current details we have for{' '}
              <Typography.Text $bold>{creator.name}</Typography.Text>, please
              suggest any edits you feel are needed.
            </Typography.Text>
          </FlexBlock>
        </FlexBlock>
      </S.Section>
      {suggestionData && (
        <>
          <S.Section>
            <Form.Wrapper>
              <Form.Row padding="4px 0" columnEnd={-1}>
                <Typography.Text $dmSans $bold $size={16} $colorName="orbit">
                  Personal Information
                </Typography.Text>
              </Form.Row>
              <Form.Row padding="0" columnEnd={-1}>
                <Form.Section flex={1}>
                  <Typography.Text>First Name</Typography.Text>
                </Form.Section>
                <Form.Section flex={3}>
                  <Input
                    value={suggestionData.firstName}
                    onChange={handleChange('firstName')}
                  />
                </Form.Section>
              </Form.Row>

              <Form.Row padding="0" columnEnd={-1}>
                <Form.Section flex={1}>
                  <Typography.Text>Last Name</Typography.Text>
                </Form.Section>
                <Form.Section flex={3}>
                  <Input
                    value={suggestionData.lastName}
                    onChange={handleChange('lastName')}
                  />
                </Form.Section>
              </Form.Row>

              {suggestionData.emails?.map((email, index) => (
                <Form.Row padding="0" columnEnd={-1} key={index}>
                  <Form.Section flex={1}>
                    <Typography.Text>Email Address</Typography.Text>
                  </Form.Section>
                  <Form.Section flex={3}>
                    <Input value={email} onChange={handleEmailChange(index)} />
                    {Boolean(
                      suggestionData?.emails?.some(
                        (e, i) =>
                          i !== index &&
                          e === email &&
                          index === Number(suggestionData?.emails?.length) - 1,
                      ),
                    ) && (
                      <>
                        <Gap size={8} />
                        <Typography.Text $colorName="redOrange">
                          Thank you for your suggestion. This email address is
                          already referenced above.
                        </Typography.Text>
                      </>
                    )}
                  </Form.Section>
                </Form.Row>
              ))}

              <Form.Row columnEnd={-1} padding="0">
                <Form.Section flex={1}></Form.Section>
                <FlexBlock alignItems="center" flex={3} padding="24px 0">
                  <Button
                    variant="gray-smoke"
                    compact
                    type="button"
                    onClick={addBlankEmail}
                  >
                    Add email address
                  </Button>
                </FlexBlock>
              </Form.Row>
            </Form.Wrapper>
          </S.Section>
          <S.Section>
            <Form.Wrapper>
              <Form.Row padding="4px 0" columnEnd={-1}>
                <Typography.Text $dmSans $bold $size={16} $colorName="orbit">
                  Social
                </Typography.Text>
              </Form.Row>

              <Form.Row padding="0" columnEnd={-1}>
                <Form.Section flex={1}>
                  <RoundIconWrapper>
                    <TwitterIcon size={24} />
                  </RoundIconWrapper>
                </Form.Section>
                <Form.Section flex={3}>
                  <Input
                    value={suggestionData.twitter}
                    onChange={handleChange('twitter')}
                    placeholder="Twitter Handle"
                  />
                </Form.Section>
              </Form.Row>
            </Form.Wrapper>
          </S.Section>
          <S.SidebarFooter>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              isLoading={isLoading}
              disabled={!madeChanges}
            >
              Submit
            </Button>
          </S.SidebarFooter>
        </>
      )}
    </>
  );
};

export default SuggestAnEdit;
