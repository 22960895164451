import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.pitch || initialState;

export const selectPitch = createSelector([selectSlice], state => state);

export const selectDigDeeperAuthorId = createSelector(
  [selectPitch],
  state => state.digDeeperAuthorId,
);

export const selectDigDeeperCreator = createSelector(
  [selectPitch],
  state => state.digDeeperCreator,
);

export const selectDigDeeperAuthorSentiment = createSelector(
  [selectPitch],
  state => state.digDeeperSentiment,
);

export const selectDigDeeperAuthorInterest = createSelector(
  [selectPitch],
  state => state.digDeeperInterest,
);

export const selectDigDeeperPodcastId = createSelector(
  [selectPitch],
  state => state.digDeeperPodcastId,
);

export const selectDigDeeperReportItem = createSelector(
  [selectPitch],
  state => state.digDeeperReportItem,
);

export const selectDigDeepeTargetMedia = createSelector(
  [selectPitch],
  state => state.digDeeperTargetMedia,
);

export const selectVersionDrawerOpen = createSelector(
  [selectPitch],
  state => state.versionDrawerOpen,
);

export const selectPdfPrint = createSelector(
  [selectPitch],
  state => state.pdfPrint,
);

export const selectContentGenerationTone = createSelector(
  [selectPitch],
  state => state.contentGenerationTone,
);

export const selectContentGenerationToneLength = createSelector(
  [selectPitch],
  state => state.contentGenerationToneLength,
);

export const selectContentGenerationDrawerOpen = createSelector(
  [selectPitch],
  state => state.contentGenerationDrawerOpen,
);

export const selectGeneratedContent = createSelector(
  [selectPitch],
  state => state.generatedContent,
);

export const selectForceRegenerateEmailContent = createSelector(
  [selectPitch],
  state => !!state.forceRegenerateEmailContent,
);

export const selectEmailSuggestionsResponse = createSelector(
  [selectPitch],
  state => state.emailSuggestionsResponse,
);

export const selectIsEmailSuggestionsLoading = createSelector(
  [selectPitch],
  state => state.isEmailSuggestionsLoading || false,
);

export const selectEmailSuggestionsPayload = createSelector(
  [selectPitch],
  state => state.emailSuggestionsPayload,
);
