interface IProps {
  size?: number;
  fill?: string;
}

const UploadIcon = ({ size = 24, fill = '#BCBCC1' }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill={fill} transform="translate(-1025 -791)">
        <g transform="translate(837 117)">
          <g transform="translate(152 638)">
            <g transform="translate(36 36)">
              <path d="M12 .6A11.4 11.4 0 1023.4 12 11.408 11.408 0 0012 .6zm4.878 10.06a1.035 1.035 0 01-1.465 0l-2.377-2.376v9.934a1.036 1.036 0 11-2.072 0V8.284L8.587 10.66a1.036 1.036 0 11-1.465-1.465l4.145-4.145a1.036 1.036 0 011.466 0l4.145 4.145a1.036 1.036 0 010 1.465z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UploadIcon;
