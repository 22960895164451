import { ICreateBylinePayload } from 'app/api.types';
import { TONE_SELECT_OPTIONS } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';

export const getToneObjectByValue = (toneValue: string) => {
  const toneObject = TONE_SELECT_OPTIONS.find(tone => tone.value === toneValue);
  if (!toneObject) return TONE_SELECT_OPTIONS[0];
  return toneObject;
};

export const validateCreateBylinePayload = (payload: ICreateBylinePayload) => {
  if (!payload.projectId) return false;

  if (
    !payload.bylineContentCreateBody ||
    !payload.bylineContentCreateBody.bylineTopic ||
    !payload.bylineContentCreateBody.body ||
    !payload.bylineContentCreateBody.title ||
    !payload.bylineContentCreateBody.tone
  )
    return false;

  return true;
};
