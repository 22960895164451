import { FC } from 'react';
import { Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import ProtectedRoute from '../Global/permissions/ProtectedRoute';
import { userPermissionEnum } from '../Global/permissions/userPermissions.enum';
import SocialPostsPage from './pages/SocialPostsPage/SocialPostsPage';
import CreateOrEditSocialPostsPage from './pages/CreateOrEditSocialPosts/CreateOrEditSocialPostsPage';

interface IProps {}

const SocialPosts: FC<IProps> = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={RouteConstants.socialPosts.socialPosts}
        component={SocialPostsPage}
        requirePermission={userPermissionEnum.READ_SOCIAL_POST_CONTENT}
      />
      <ProtectedRoute
        path={RouteConstants.socialPosts.createSocialPosts}
        component={CreateOrEditSocialPostsPage}
        requirePermission={userPermissionEnum.CREATE_SOCIAL_POST_CONTENT}
      />
    </Switch>
  );
};

export default SocialPosts;
