import { createContext, useContext } from 'react';
import { IPromptContextDefaultState, IPromptModal } from './PromptModal.types';

export const PromptModalContext = createContext<IPromptContextDefaultState>({
  createModal: (modal: IPromptModal) => {},
});

export function usePromptModalContext() {
  const { createModal } = useContext(PromptModalContext);
  return { createModal };
}
