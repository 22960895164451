import { FC } from 'react';
import * as S from '../CreateOrEditBioPage.styles';
import Typography from 'app/components/Typography';

interface IProps {
  type: string;
  emphasized: boolean;
  understated: boolean;
  setEmphasized: (option: string, isEmphasized: boolean) => void;
  setUnderstated: (option: string, isEmphasized: boolean) => void;
  compact: boolean;
}

const PillRadioButton: FC<IProps> = ({
  type,
  emphasized,
  understated,
  setEmphasized,
  setUnderstated,
  compact,
}) => {
  return (
    <S.DoublePillButton>
      <S.Pill
        onClick={() => setEmphasized(type, emphasized)}
        $selected={emphasized}
        $width={compact ? '64px' : '115px'}
        $height={compact ? '40px' : '56px'}
        variant="left"
      >
        <Typography.Text
          $dmSans
          $bold
          $size={compact ? 16 : 18}
          $lineHeight={24}
          $colorName={emphasized ? 'white' : 'onyx'}
        >
          Yes
        </Typography.Text>
      </S.Pill>
      <S.Pill
        onClick={() => setUnderstated(type, understated)}
        $selected={understated}
        $width={compact ? '64px' : '115px'}
        $height={compact ? '40px' : '56px'}
        variant="right"
      >
        <Typography.Text
          $dmSans
          $bold
          $size={compact ? 16 : 18}
          $lineHeight={24}
          $colorName={understated ? 'white' : 'onyx'}
        >
          No
        </Typography.Text>
      </S.Pill>
    </S.DoublePillButton>
  );
};

export default PillRadioButton;
