import { IOption } from 'types/commonTypes';

export const pageSizeOptions: IOption<number>[] = [
  {
    value: 10,
    label: '10 Per Page',
  },
  {
    value: 20,
    label: '20 Per Page',
  },
  {
    value: 30,
    label: '30 Per Page',
  },
  {
    value: 50,
    label: '50 Per Page',
  },
];

export const MAX_JOURNALISTS_TO_EXPORT = 500;

export const JOURNALISTS_PAGINATION_SELECT_PROPS = {
  minWidth: '178px',
  background: 'transparent',
  fontSize: 14,
  controlPadding: '10.5px 12px',
};
