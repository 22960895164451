import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ $padding?: string; advanced?: boolean }>`
  display: flex;
  padding: ${({ $padding = '16px 8px' }) => $padding};
  position: relative;
  align-items: center;

  ${({ advanced }) =>
    advanced
      ? css`
          &:hover {
            ${TooltipSectionAdvanced} {
              display: block;
            }
          }
        `
      : ''};
`;

export const TextSection = styled.div`
  flex: 1;
`;

export const TooltipSection = styled.div<{
  text: string;
  $tooltipPosition?: 'right' | 'left';
}>(
  ({ text, $tooltipPosition }) => css`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    //left: 0;
    z-index: 15;

    &:after {
      ${$tooltipPosition === 'right'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}

      content: '${text}';
      position: absolute;
      min-width: 150px;
      top: calc(100% + 5px);
      z-index: 10;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      padding: 12px;
      font-size: 12px;
      border-radius: 8px;
      display: none;
    }

    &:hover,
    &:active {
      &:after {
        display: block;
      }
    }
  `,
);

export const NewTooltipSection = styled.div<{
  text: string;
  $tooltipPosition?: 'right' | 'left';
}>(
  ({ text, $tooltipPosition }) => css`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    //left: 0;
    z-index: 15;

    &:after {
      ${$tooltipPosition === 'right'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}

      content: '${text}';
      position: absolute;
      min-width: 150px;
      top: calc(100% + 5px);
      z-index: 10;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      padding: 12px;
      font-size: 12px;
      border-radius: 8px;
      display: none;
    }

    &:hover,
    &:active {
      &:after {
        display: block;
      }
    }
  `,
);

export const TooltipSectionAdvanced = styled.div<{
  $tooltipPosition?: 'right' | 'left';
}>(
  ({ $tooltipPosition, theme }) => css`
    //position: absolute;
    //height: 100%;
    //width: 100%;
    //top: 0;
    //left: 0;
    //z-index: 15;

    ${$tooltipPosition === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}

    position: absolute;
    width: 250px;
    top: calc(100% + 5px);
    z-index: 100;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.aluminum};
    color: #fff;
    padding: 16px;
    font-size: 12px;
    border-radius: 8px;
    display: none;
  `,
);
