import { IAuthorSearchResponse } from 'app/api.types';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { showErrorToast } from 'utils/toast';
import {
  defaultJournalistsSearchPagination,
  journalistsListConfig,
} from './JournalistsListPage.constants';
import JournalistsTable from './parts/Table/JournalistsTable';
import JournalistHeader from './parts/Header/JournalistsHeader';
import JournalistTabs, { TableView } from './parts/Tabs/JournalistTabs';

import { useCreatorPanel } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/hooks/useCreatorPanel';
import { DigDeeperSlideout } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/DigDeeperSlideout';
import FiltersSideDrawer from './parts/Filters/FiltersSideDrawer';
import { IEmptyDataNoticeProps } from 'app/components/EmptyDataNotice/EmptyDataNotice';
import ReactTooltip from 'react-tooltip';
import { FavoriteJournalistTooltip } from './parts/Favorites/FavoritesTooltip';
import { ContentHeader } from 'app/components/UI/ContentLayout/ContentLayout';
import MainGrid from 'app/components/UI/MainGrid/MainGrid';
import { Helmet } from 'react-helmet-async';
import { ContentFooter } from 'app/components/UI/ContentLayout/ContentLayout';
import {
  ContentLayout,
  ContentWrapper,
  TableContainer,
} from './JournalistsList.styles';
import {
  getFavoriteJournalistTooltipProps,
  getJournalistsEmptyNotice,
  scrollToPageTop,
} from './JournalistList.helpers';
import {
  useJournalistsData,
  useMediaListData,
} from './hooks/useJournalistData';
import { useJournalistsSearch } from './hooks/useJournalistSearch';
import JournalistsTableHeader from './parts/Table/shared/TableHeader';
import { MediaListTable } from './parts/MediaListTable/MediaListTable';
import { useParams } from 'react-router-dom';
import CreateMediaListModal from './parts/MediaListTable/parts/Modals/CreateMediaListModal';
import Button from 'app/components/UI/Button/Button';
import AddToMediaListModal from './parts/MediaListTable/parts/Modals/AddToMediaListModal';
import { useAppDispatch } from 'store/hooks';
import { useJournalistsSlice } from '../slice';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';

const JournalistsListPage: FC = () => {
  const dispatch = useAppDispatch();
  const { actions } = useJournalistsSlice();

  const pageParams = useParams<{ view?: string }>();
  const [tableView, setTableView] = useState(
    pageParams.view === 'media-list'
      ? TableView.MediaList
      : TableView.AllJournalists,
  );
  const [allJournalistCount, setAllJournalistCount] = useState(0);
  const [favoriteJournalistCount, setFavoriteJournalistCount] = useState(0);
  const [mediaListCount, setMediaListCount] = useState(0);

  const [pagination, setPagination] = useState(
    defaultJournalistsSearchPagination,
  );

  const searchParameters = useJournalistsSearch();

  // Keep track of previous search parameters to reset pagination when search paramater changes
  const previousSearchTerm = useRef(searchParameters.debouncedSearchParamater);
  const previousSearchByPropertyKey = useRef(
    searchParameters.searchByJournalistProperty,
  );

  const journalists = useJournalistsData({
    pagination,
    debouncedSearchTerm: searchParameters.debouncedSearchParamater,
    searchByProperty: searchParameters.searchByJournalistProperty,
    currentTab: tableView,
  });

  const mediaLists = useMediaListData({
    pagination,
    currentTab: tableView,
    debouncedSearchTerm: searchParameters.debouncedSearchParamater,
  });

  const { openCreatorPanel } = useCreatorPanel();

  const handleRowClick = useCallback(
    (row: IAuthorSearchResponse) => {
      if (row.id) {
        openCreatorPanel(String(row.id), row);
      }
    },
    [openCreatorPanel],
  );

  useEffect(() => {
    if (
      searchParameters.debouncedSearchParamater !==
        previousSearchTerm.current ||
      searchParameters.searchByJournalistProperty !==
        previousSearchByPropertyKey.current
    ) {
      previousSearchTerm.current = searchParameters.debouncedSearchParamater;
      resetPagination();
    }
  }, [
    searchParameters.debouncedSearchParamater,
    searchParameters.searchByJournalistProperty,
  ]);

  useEffect(() => {
    if (journalists.data) {
      if (tableView === TableView.AllJournalists) {
        setAllJournalistCount(journalists?.data?.totalElements || 0);
      }

      if (tableView === TableView.FavoriteJournalists) {
        setFavoriteJournalistCount(journalists?.data?.totalElements || 0);
      }
    }
  }, [journalists.data, tableView]);

  useEffect(() => {
    if (mediaLists.data) {
      setMediaListCount(mediaLists?.data?.totalElements || 0);
    }
  }, [mediaLists.data]);

  useEffect(() => {
    if (mediaLists.isError) {
      showErrorToast('Could not fetch media lists.', 'Error', {
        toastId: 'MEDIA_LISTS_FETCH_ERROR',
      });
      setMediaListCount(0);
    }
  }, [mediaLists.isError]);

  useEffect(() => {
    if (journalists.isError) {
      showErrorToast('Could not fetch journalists.', 'Error', {
        toastId: 'MEDIA_LISTS_FETCH_ERROR',
      });
      setAllJournalistCount(0);
    }
  }, [journalists.isError]);

  const clearSearchParameter = () => {
    searchParameters.onSearchChange('');

    if (tableView === TableView.AllJournalists) {
      journalists.setFilters(currentFilters => ({
        ...currentFilters,
        email: undefined,
        name: undefined,
        publicationName: undefined,
        primaryPublicationName: undefined,
        title: undefined,
      }));
    }
  };

  const resetPagination = () => {
    setPagination(currentPagination => ({
      ...currentPagination,
      page: { page: 0, size: currentPagination.page.size },
    }));
  };

  const handleTabChange = () => {
    clearSearchParameter();
    resetPagination();
  };

  const tabs = [
    {
      label: TableView.AllJournalists,
      amount: allJournalistCount,
      isActive: true,
      onClickLogic: handleTabChange,
    },
    {
      label: TableView.FavoriteJournalists,
      amount: favoriteJournalistCount,
      isActive: true,
      onClickLogic: handleTabChange,
    },
    {
      label: TableView.MediaList,
      amount: mediaListCount,
      isActive: true,
      onClickLogic: handleTabChange,
    },
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
    ReactTooltip.hide();
  }, [journalists.data, mediaLists.data, tableView]);

  const renderTableTabs = () => {
    return (
      <JournalistTabs
        tableView={tableView}
        setTableView={setTableView}
        tabData={tabs}
        isFetching={
          tableView === TableView.MediaList
            ? mediaLists.isFetching && !mediaLists.isLoading
            : journalists.isFetching && !journalists.isLoading
        }
      />
    );
  };

  const renderJournalistRowActions = useCallback(
    (row: IAuthorSearchResponse) => {
      return (
        <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
          <div
            {...getFavoriteJournalistTooltipProps(
              'Add Journalist to a Media List',
            )}
          >
            <Button
              compact
              variant="anchor-hover"
              padding="10px 8px"
              onClick={e => {
                e.stopPropagation();
                if (row && row.id) {
                  dispatch(
                    actions.toggleAddToMediaListModal({
                      rows: [
                        {
                          authorId: String(row.id),
                          mediaOutletId: row.primaryOutlet?.id,
                        },
                      ],
                    }),
                  );
                }
              }}
            >
              + Add
            </Button>
          </div>
        </IfHasPermission>
      );
    },
    [actions, dispatch],
  );

  const noJournalistsNoticeConfig: IEmptyDataNoticeProps = useMemo(() => {
    return getJournalistsEmptyNotice(
      tableView === TableView.FavoriteJournalists,
    );
  }, [tableView]);

  return (
    <>
      <Helmet title="Journalist Database" />
      <ContentLayout id="journalist-database-page-container">
        <ContentHeader>
          <JournalistHeader
            isLoading={journalists.isFetching || mediaLists.isFetching}
            searchTerm={searchParameters.searchParameter}
            searchHandler={searchParameters.onSearchChange}
            searchByHandler={searchParameters.onSearchByChange}
            selectedSearchType={searchParameters.searchByJournalistProperty}
            currentTab={tableView}
          />
        </ContentHeader>
        <MainGrid minContent>
          <ContentWrapper>
            <TableContainer>
              <JournalistsTableHeader
                pagination={pagination}
                onPaginationChange={setPagination}
                renderContainerHeader={renderTableTabs}
                renderFilterSelector={
                  tableView !== TableView.MediaList
                    ? () => (
                        <FiltersSideDrawer
                          setFilters={journalists.setFilters}
                          activeFilters={journalists.filters}
                        />
                      )
                    : undefined
                }
              />
              {tableView === TableView.MediaList ? (
                <MediaListTable
                  pagination={pagination}
                  onPaginationChange={pagination => {
                    setPagination(pagination);
                    scrollToPageTop();
                  }}
                  data={mediaLists.data}
                  isDataLoading={mediaLists.isLoading}
                />
              ) : (
                <JournalistsTable
                  showHeaderOnEmptyData
                  showNoResources
                  isDataLoading={journalists.isFetching}
                  data={journalists.data}
                  columns={journalistsListConfig}
                  dataTypeName="Journalists"
                  pagination={pagination}
                  onPaginationChange={pagination => {
                    setPagination(pagination);
                    scrollToPageTop();
                  }}
                  onRowClick={handleRowClick}
                  renderRowActions={renderJournalistRowActions}
                  emptyNoticeConfig={{
                    ...noJournalistsNoticeConfig,
                    buttonLinkTo: '',
                  }}
                />
              )}
            </TableContainer>
            <DigDeeperSlideout />
            <FavoriteJournalistTooltip />
          </ContentWrapper>
        </MainGrid>
        <ContentFooter />
      </ContentLayout>
      <AddToMediaListModal />
      <CreateMediaListModal />
    </>
  );
};

export default JournalistsListPage;
