import { useGetBylineContentVersionQuery, useGetBylineQuery } from 'app/api';
import {
  IBylineGenerationPayload,
  IBylineGenerationResponse,
} from 'app/api.types';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getToneObjectByValue } from '../BylinePage.helpers';

interface IBylineDraft {
  generatedResponse: IBylineGenerationResponse;
  generationPayload: IBylineGenerationPayload;
}

export const useBylineData = () => {
  const params: {
    tenantId: string;
    projectId: string;
    bylineId: string;
    versionId: string;
  } = useParams();

  const [tenantId, projectId, bylineId, versionId] = useMemo(
    () => [
      Number(params.tenantId),
      Number(params.projectId),
      Number(params.bylineId),
      Number(params.versionId),
    ],
    [params],
  );

  const location = useLocation<IBylineDraft | undefined>();
  const draft = location.state;
  const isDraft = Boolean(draft);

  const {
    data: bylineData,
    isError: isBylineError,
    isLoading: isBylineLoading,
  } = useGetBylineQuery({ bylineId }, { skip: !bylineId });

  const bylineContentId = useMemo(() => {
    if (!bylineData) return undefined;
    if (bylineData.bylineContents) {
      return bylineData.bylineContents.find(
        content => content.version === versionId,
      )?.id;
    }
  }, [bylineData, versionId]);

  const byline = useMemo(() => {
    if (bylineData) return bylineData;
    return undefined;
  }, [bylineData]);

  const {
    data: bylineContent,
    isError: isBylineContentError,
    isLoading: isBylineContentLoading,
  } = useGetBylineContentVersionQuery(
    { bylineContentId },
    { skip: !bylineData || !bylineContentId },
  );

  const bylineTitle = useMemo(() => {
    if (draft) {
      if (draft.generatedResponse && draft.generatedResponse.generatedTitle) {
        return draft.generatedResponse.generatedTitle;
      }
    }

    return undefined;
  }, [draft]);

  const payload = useMemo(() => {
    if (draft && draft.generationPayload) {
      return draft.generationPayload;
    }

    if (bylineContent) {
      return {
        bylineTopic: bylineContent.bylineTopic,
        tone: bylineContent.tone,
        productKeywords: bylineContent.productKeywords,
      } as IBylineGenerationPayload;
    }

    return undefined;
  }, [draft, bylineContent]);

  const content = useMemo(() => {
    if (draft) {
      return {
        body: draft?.generatedResponse.generatedBody,
        title: draft?.generatedResponse.generatedTitle,
      };
    }

    if (bylineContent) {
      return {
        body: bylineContent.body,
        title: bylineContent.title,
      };
    }

    return undefined;
  }, [draft, bylineContent]);

  const tone = useMemo(() => {
    if (draft) {
      return draft.generationPayload.tone
        ? getToneObjectByValue(draft.generationPayload.tone)
        : undefined;
    }

    if (bylineContent) {
      return bylineContent?.tone
        ? getToneObjectByValue(bylineContent.tone)
        : undefined;
    }
  }, [draft, bylineContent]);

  return {
    bylineId,
    tenantId,
    tone,
    versionId,
    projectId,
    isDraft,
    bylineTitle,
    payload,
    content,
    byline,
    isBylineContentLoading,
    isBylineLoading,
    isError: isBylineError || isBylineContentError,
  };
};
