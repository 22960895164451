import { useAppSelector } from '../../../../store/hooks';
import { selectUser } from '../slice/selectors';
import { UserRoleEnum } from '../slice/types';

const useGetCurrentUserPlan = () => {
  const user = useAppSelector(selectUser);

  const userStatus = user?.statusType;
  const userRole = user?.role.code;

  const isFreePlan = userRole === UserRoleEnum.FREE_USER;
  const isFreemiumPlan = userRole === UserRoleEnum.FREEMIUM_USER;
  const isFreeOrFreemiumPlan =
    userRole === UserRoleEnum.FREE_USER ||
    userRole === UserRoleEnum.FREEMIUM_USER;

  const isProPlan = userRole === UserRoleEnum.PRO_USER;
  const isProV2Plan = userRole === UserRoleEnum.PRO_V2_USER;
  const isProOrProV2Plan =
    userRole === UserRoleEnum.PRO_USER || userRole === UserRoleEnum.PRO_V2_USER;

  const isV2Plan =
    userRole === UserRoleEnum.PRO_V2_USER ||
    userRole === UserRoleEnum.FREEMIUM_USER;

  return {
    isProPlan,
    isProV2Plan,
    isProOrProV2Plan,
    isFreePlan,
    isFreemiumPlan,
    isFreeOrFreemiumPlan,
    isV2Plan,
    userRole,
    userStatus,
  };
};

export default useGetCurrentUserPlan;
