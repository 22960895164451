import { Dispatch, FC, createContext, useContext, useReducer } from 'react';
import { TONE_SELECT_OPTIONS } from './ToneSelectDropDown/ToneSelectDropDown.config';
import { IToneSelectOption } from './ToneSelectDropDown/ToneSelectDropDown.types';
import {
  ICustomTargetMediaBase,
  IPitchEvaluation,
  IPitchPrediction,
  IProject,
} from 'app/api.types';
import { PitchProcessStatusEnum } from '../CreateOrEditPitchPage';
import {
  IPitchContent,
  IPitchState,
  PitchContentName,
} from '../CreateOrEditPitch.types';
import {
  DEFAULT_NEW_PITCH_STATE,
  DEFAULT_PITCH_CONTENT_STATE,
} from '../CreateOrEditPitch.constants';

export enum PitchStateActions {
  UPDATE_PITCH = 'UPDATE_PITCH',
  UPDATE_PITCH_CONTENTS = 'UPDATE_PITCH_CONTENTS',
  UPDATE_PITCH_CONTENT = 'UPDATE_PITCH_CONTENT',
  SET_SELECTED_PITCH_CONTENT = 'SET_SELECTED_PITCH_CONTENT',
  SET_SELECTED_BRAND_MEDIA = 'SET_SELECTED_BRAND_MEDIA',
  SET_EVALUATION = 'SET_EVALUATION',
  SET_PREDICTION = 'SET_PREDICTION',
  SET_TONE = 'SET_TONE',
  SET_PITCH_PROCESS_STATUS = 'SET_PITCH_PROCESS_STATUS',
  TOGGLE_SETTINGS = 'TOGGLE_SETTINGS',
  TOGGLE_VERSIONS = 'TOGGLE_VERSIONS',
  TOGGLE_SOCIALS = 'TOGGLE_SOCIALS',
}

type PitchAction =
  | { type: PitchStateActions.UPDATE_PITCH; payload: Partial<IPitchState> }
  | { type: PitchStateActions.UPDATE_PITCH_CONTENTS; payload: IPitchContent[] }
  | {
      type: PitchStateActions.UPDATE_PITCH_CONTENT;
      payload: { contentType: PitchContentName; data: IPitchContent };
    }
  | {
      type: PitchStateActions.SET_SELECTED_PITCH_CONTENT;
      payload: PitchContentName;
    }
  | {
      type: PitchStateActions.SET_SELECTED_BRAND_MEDIA;
      payload: ICustomTargetMediaBase[];
    }
  | {
      type: PitchStateActions.SET_EVALUATION;
      payload: Partial<IPitchEvaluation>;
    }
  | {
      type: PitchStateActions.SET_PREDICTION;
      payload: IPitchPrediction;
    }
  | {
      type: PitchStateActions.SET_TONE;
      payload: IToneSelectOption;
    }
  | {
      type: PitchStateActions.SET_PITCH_PROCESS_STATUS;
      payload: PitchProcessStatusEnum | null;
    }
  | {
      type: PitchStateActions.TOGGLE_SETTINGS;
      payload: boolean;
    }
  | {
      type: PitchStateActions.TOGGLE_VERSIONS;
      payload: boolean;
    }
  | {
      type: PitchStateActions.TOGGLE_SOCIALS;
      payload: boolean;
    };

interface PitchState {
  pitch: IPitchState;
  pitchContents: IPitchContent[];
  selectedPitchContent: PitchContentName | null;
  selectedBrandMediaList: ICustomTargetMediaBase[];
  prediction: IPitchPrediction | null;
  evaluation: IPitchEvaluation | null;
  tone: IToneSelectOption;
  pitchProcessStatus: PitchProcessStatusEnum | null;
  settingsOpen: boolean;
  versionsOpen: boolean;
  socialsTabOpen: boolean;
}

const initialPitchState: PitchState = {
  pitch: DEFAULT_NEW_PITCH_STATE,
  pitchContents: [{ ...DEFAULT_PITCH_CONTENT_STATE }],
  selectedPitchContent: null,
  selectedBrandMediaList: [],
  prediction: null,
  evaluation: null,
  tone: TONE_SELECT_OPTIONS[0],
  pitchProcessStatus: null,
  settingsOpen: false,
  versionsOpen: false,
  socialsTabOpen: false,
};

export interface RoutePitchState {
  tone: IToneSelectOption;
  content: IPitchContent[];
  project: IProject;
}

function pitchReducer(pitchState: PitchState, action: PitchAction): PitchState {
  switch (action.type) {
    case PitchStateActions.UPDATE_PITCH: {
      return {
        ...pitchState,
        pitch: {
          ...pitchState.pitch,
          ...action.payload,
        },
      };
    }
    case PitchStateActions.UPDATE_PITCH_CONTENTS: {
      return {
        ...pitchState,
        pitchContents: [...action.payload],
      };
    }
    case PitchStateActions.UPDATE_PITCH_CONTENT: {
      const hasRequiredContentType = pitchState.pitchContents.find(
        content => content.contentName === action.payload.contentType,
      );

      const updatedPitchContent = hasRequiredContentType
        ? pitchState.pitchContents.map(content => {
            if (content.contentName === action.payload.contentType)
              return action.payload.data;
            return content;
          })
        : [...pitchState.pitchContents, action.payload.data];

      return {
        ...pitchState,
        versionsOpen: false,
        settingsOpen: false,
        pitchContents: [...updatedPitchContent],
      };
    }

    case PitchStateActions.SET_EVALUATION: {
      if (pitchState.evaluation) {
        return {
          ...pitchState,
          evaluation: {
            ...pitchState.evaluation,
            ...action.payload,
          },
        };
      }
      return pitchState;
    }
    case PitchStateActions.SET_PREDICTION: {
      return {
        ...pitchState,
        prediction: action.payload,
      };
    }
    case PitchStateActions.SET_SELECTED_BRAND_MEDIA: {
      return {
        ...pitchState,
        selectedBrandMediaList: action.payload,
      };
    }
    case PitchStateActions.SET_PITCH_PROCESS_STATUS: {
      return {
        ...pitchState,
        pitchProcessStatus: action.payload,
      };
    }
    case PitchStateActions.SET_TONE: {
      return {
        ...pitchState,
        tone: action.payload,
      };
    }
    case PitchStateActions.SET_SELECTED_PITCH_CONTENT: {
      return {
        ...pitchState,
        selectedPitchContent: action.payload,
      };
    }

    case PitchStateActions.TOGGLE_SOCIALS: {
      return {
        ...pitchState,
        socialsTabOpen: action.payload,
      };
    }
    case PitchStateActions.TOGGLE_SETTINGS: {
      return {
        ...pitchState,
        settingsOpen: action.payload,
        versionsOpen: false,
      };
    }
    case PitchStateActions.TOGGLE_VERSIONS: {
      return {
        ...pitchState,
        versionsOpen: action.payload,
        settingsOpen: false,
      };
    }

    default: {
      return pitchState;
    }
  }
}

const PitchContext = createContext<PitchState>(initialPitchState);
const PitchDispatchContext = createContext<Dispatch<PitchAction>>(() => {});

export const PitchProvider: FC<{ routeInitialState?: RoutePitchState }> = ({
  children,
  routeInitialState,
}) => {
  const initialState = routeInitialState
    ? {
        ...initialPitchState,
        tone: routeInitialState.tone,
        pitchContents: routeInitialState.content,
        selectedPitchContent: 'generated' as PitchContentName,
      }
    : initialPitchState;

  const [pitch, dispatch] = useReducer(pitchReducer, initialState);

  return (
    <PitchDispatchContext.Provider value={dispatch}>
      <PitchContext.Provider value={pitch}>{children}</PitchContext.Provider>
    </PitchDispatchContext.Provider>
  );
};

export function usePitch() {
  return useContext(PitchContext);
}

export function usePitchDispatch() {
  return useContext(PitchDispatchContext);
}
