import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { IReportItem } from '../../../../api.types';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';

export const findAuthorByHighestInterest = (
  items: IReportItem[],
): IReportItem | null => {
  const interestScores = items.map(item => item.explain?.value || 0);
  const indexOfMax = interestScores.indexOf(Math.max(...interestScores));
  return items[indexOfMax] || null;
};

export const handlePitchReportError = (
  error: FetchBaseQueryError,
  errorContext: string,
) => {
  if (!error) return <EmptyDataNotice title={'Unexpected error happened'} />;

  if (error && typeof error === 'object' && 'status' in error) {
    const errorCode = error?.status;
    if (errorCode === 404)
      return <EmptyDataNotice title={`${errorContext} not found`} />;
    return <EmptyDataNotice title={'Unexpected error happened'} />;
  }

  return <EmptyDataNotice title={'Unexpected error happened'} />;
};
