import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';
import { ToneSelectDropDown } from '../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import styled, { css } from 'styled-components';
import Gap from 'app/components/UI/Gap';
import { RouteConstants } from 'app/routes';
import Typography from 'app/components/Typography';
import RegenerateIcon from 'app/assets/icons/RegenerateIcon';
import { theme } from 'styles/theme';
import { IToneSelectOption } from '../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';

import { useHistory } from 'react-router-dom';

interface IProps {
  tone: IToneSelectOption;
  setTone: (opt: IToneSelectOption) => void;
  regenerateContent: () => Promise<void>;
}

export const FooterContainer = styled.div<{
  $height?: number;
}>(
  ({ $height }) => css`
    width: 100%;
    height: ${$height ? `${$height}px` : '80px'};
    position: fixed;
    bottom: 0;
    background: white;
    box-shadow: 0 -2px 12px 0 rgba(12, 12, 13, 0.05);
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    z-index: 3;
  `,
);

const GeneratedContentFooter: FC<IProps> = ({
  tone,
  setTone,
  regenerateContent,
}) => {
  const history = useHistory();

  return (
    <FooterContainer>
      <Button
        variant="gray"
        onClick={() => history.push(RouteConstants.projects.list)}
      >
        Done
      </Button>
      <FlexBlock flexDirection="row" alignItems="center">
        <ToneSelectDropDown
          value={tone}
          onChange={setTone}
          height="58px"
          menuPlacement="top"
        />
        <Gap size={8} />
        <Button variant="secondary-blue" onClick={regenerateContent}>
          <RegenerateIcon fill={theme.colors.orbit} />
          <Gap size={8} />
          <Typography.Text
            $dmSans
            $bold
            $size={16}
            $lineHeight={24}
            $colorName="orbit"
          >
            Regenerate Content
          </Typography.Text>
        </Button>
      </FlexBlock>
    </FooterContainer>
  );
};

export default GeneratedContentFooter;
