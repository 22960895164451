import { FC, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  fill?: string;
}

const FilterIcon: FC<Props> = ({ fill = '#090909', ...props }) => (
  <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-376.000000, -100.000000)" fill={fill}>
        <g transform="translate(0.000000, 64.000000)">
          <g transform="translate(364.000000, 24.000000)">
            <g transform="translate(12.000000, 12.000000)">
              <path d="M10,10.8 C10.6627417,10.8 11.2,11.3372583 11.2,12 C11.2,12.6627417 10.6627417,13.2 10,13.2 L6,13.2 C5.3372583,13.2 4.8,12.6627417 4.8,12 C4.8,11.3372583 5.3372583,10.8 6,10.8 L10,10.8 Z M11.6,6.8 C12.2627417,6.8 12.8,7.3372583 12.8,8 C12.8,8.6627417 12.2627417,9.2 11.6,9.2 L4.4,9.2 C3.7372583,9.2 3.2,8.6627417 3.2,8 C3.2,7.3372583 3.7372583,6.8 4.4,6.8 L11.6,6.8 Z M14.4,2.8 C15.0627417,2.8 15.6,3.3372583 15.6,4 C15.6,4.6627417 15.0627417,5.2 14.4,5.2 L1.6,5.2 C0.9372583,5.2 0.4,4.6627417 0.4,4 C0.4,3.3372583 0.9372583,2.8 1.6,2.8 L14.4,2.8 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FilterIcon;
