interface IProps {
  size?: number;
}

const ArrowLeftIcon = ({ size = 24 }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.52 12.612c.081.196.2.373.349.523l6.786 6.797a1.598 1.598 0 0 0 2.261-.001 1.604 1.604 0 0 0 .002-2.265L7.86 13.603H20c.884 0 1.6-.718 1.6-1.603s-.716-1.603-1.6-1.603H7.86l4.057-4.063a1.604 1.604 0 0 0-.002-2.265 1.598 1.598 0 0 0-2.261 0l-6.786 6.796a1.61 1.61 0 0 0-.348 1.747Z"
      fill="#090909"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowLeftIcon;
