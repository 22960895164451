import styled, { css } from 'styled-components/macro';

type PillVariant = 'default' | 'green' | 'dark' | 'orbit' | 'haze';

interface IPillProps {
  green?: boolean; // TODO: Remove this prop and use variant=green instead
  variant?: PillVariant;
  size?: number;
}

const RoundedPill = styled.div<IPillProps>(
  ({ theme, green, variant = 'default', size = 32 }) => css`
    background-color: ${theme.colors.smoke};
    font-family: ${theme.fonts.inter};
    font-size: 12px;
    color: ${theme.colors.onyx};
    padding: 6px 12px;
    border-radius: 14px;
    position: relative;
    text-transform: capitalize;
    ${green || variant === 'green'
      ? css`
          background-color: rgba(0, 211, 119, 0.2);
        `
      : ''}

    ${variant === 'dark'
      ? css`
          background-color: ${theme.colors.stratos};
          color: #fff;
          font-size: 14px;
          line-height: 1px;
          font-family: ${theme.fonts.dmSans};
          font-weight: bold;
          padding: 0;
          width: ${size}px;
          height: ${size}px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 4px 0;
        `
      : ''}
    ${variant === 'orbit'
      ? css`
          background-color: #1d76de24;
          color: ${theme.colors.onyx};
          font-size: 14px;
          line-height: 1px;
          font-family: ${theme.fonts.dmSans};
          font-weight: bold;
          padding: 0;
          width: ${size}px;
          height: ${size}px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 4px 0;
        `
      : ''}

    ${variant === 'haze'
      ? css`
          background-color: ${theme.colors.haze};
          color: ${theme.colors.onyx};
          font-size: 14px;
          line-height: 1px;
          font-family: ${theme.fonts.dmSans};
          font-weight: bold;
          padding: 0;
          width: ${size}px;
          height: ${size}px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 4px 0;
        `
      : ''}
  `,
);

export default RoundedPill;
