import WandIcon from 'app/assets/icons/WandIcon';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import useOutOfLimitsMessages from 'app/containers/Pitch/pages/CreateOrEditPitch/hooks/useOutOfLimitsMessages';
import { FC } from 'react';
import { ToastMessageStyle } from 'utils/toast';

interface IProps {
  currentLimit: number;
  message: string;
}

export const BioGenerationSuccessToast: FC<IProps> = ({
  currentLimit,
  message,
}) => {
  const messages = useOutOfLimitsMessages();

  return (
    <ToastMessageStyle.VioletToastWrapper>
      <WandIcon />
      <FlexBlock justifyContent="space-between" flex={1}>
        <Typography.Text $dmSans $bold $size={16} $colorName="white">
          {message}
        </Typography.Text>
        <Typography.Text $dmSans $size={16} $colorName="white">
          {currentLimit < 4 && (
            <>
              <strong>{currentLimit} Content generations</strong> remaining.{' '}
            </>
          )}
          {currentLimit === 1 && (
            <>
              <a onClick={messages.goToBilling}>
                Upgrade to our {messages.planToUpgrade}
              </a>{' '}
              for more usage
            </>
          )}
        </Typography.Text>
      </FlexBlock>
    </ToastMessageStyle.VioletToastWrapper>
  );
};
