import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';

import { RouteConstants } from 'app/routes';
import { FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  usePressRelease,
  usePressReleaseDispatch,
} from '../../context/PressReleaseContext';
import {
  PressReleaseContentType,
  PressReleaseStateActions,
} from '../../CreateOrEditPressRelease.constants';
import { useCreatePressReleaseMutation } from 'app/api';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { getWordCount } from 'utils/helpers';
import Typography from 'app/components/Typography';

export const PressReleaseActions: FC = () => {
  const history = useHistory();

  const pressReleaseState = usePressRelease();
  const dispatch = usePressReleaseDispatch();

  const [createPressRelease] = useCreatePressReleaseMutation();

  const hasGeneratedContent = useMemo(() => {
    return Boolean(
      pressReleaseState.pressReleaseContents.find(
        item => item.contentType === PressReleaseContentType.GENERATED,
      ),
    );
  }, [pressReleaseState.pressReleaseContents]);

  const canRunPrediction = useMemo(() => {
    if (hasGeneratedContent && !pressReleaseState.selectedPressReleaseContent)
      return false;
    return true;
  }, [hasGeneratedContent, pressReleaseState.selectedPressReleaseContent]);

  const getDisplayNameForContentType = (
    contentType: PressReleaseContentType,
  ) => {
    switch (contentType) {
      case PressReleaseContentType.ORIGINAL:
        return 'Original';
      case PressReleaseContentType.GENERATED:
        return 'Generated';
      default:
        return 'Original';
    }
  };

  const headerNotice = useMemo(() => {
    if (hasGeneratedContent && !pressReleaseState.selectedPressReleaseContent)
      return (
        <Typography.Text>
          <strong>Edit</strong> and <strong>Select</strong> which Content you’d
          like to predict.
        </Typography.Text>
      );

    if (hasGeneratedContent && !!pressReleaseState.selectedPressReleaseContent)
      return (
        <Typography.Text>
          You’ve selected to predict the{' '}
          <Typography.Text capitalize $bold>
            {getDisplayNameForContentType(
              pressReleaseState.selectedPressReleaseContent,
            )}{' '}
            Content.
          </Typography.Text>
        </Typography.Text>
      );
    return null;
  }, [hasGeneratedContent, pressReleaseState.selectedPressReleaseContent]);

  const params: {
    tenantId: string;
    projectId: string;
  } = useParams();

  const [tenantId, projectId] = useMemo(
    () => [Number(params.tenantId), Number(params.projectId)],
    [params],
  );

  const getSelectedPitchContent = () => {
    if (pressReleaseState.selectedPressReleaseContent) {
      return pressReleaseState.pressReleaseContents.find(
        pc => pc.contentType === pressReleaseState.selectedPressReleaseContent,
      );
    }
    return pressReleaseState.pressReleaseContents.find(
      pc => pc.contentType === PressReleaseContentType.ORIGINAL,
    );
  };

  const handleCancel = () => {
    history.push(RouteConstants.projects.makeContentUrl(tenantId, projectId));
  };

  const handleSave = async () => {
    if (!projectId) return;
    const pressReleaseContent = getSelectedPitchContent();

    if (!pressReleaseContent?.content) return;

    try {
      await createPressRelease({
        projectId,
        pressReleaseContentCreateBody: {
          ...pressReleaseContent.content,
          date: pressReleaseContent.content.date
            ? moment(
                pressReleaseContent.content.date,
                'YYYY-MM-DD HH:mm:ss',
              ).format('YYYY-MM-DDTHH:mm:ss')
            : undefined,
        },
      }).unwrap();
      showSuccessToast('Press Release created successfully.');
    } catch (error) {
      showErrorToast(
        'Could not create press release.',
        getErrorText(error as FetchBaseQueryError),
      );
    }
  };

  const toggleSettings = () => {
    const contentToPredict = getSelectedPitchContent();

    const canPredictSelectedContent =
      contentToPredict?.content.title &&
      contentToPredict?.content.pressReleaseCopy &&
      getWordCount(contentToPredict.content.pressReleaseCopy) > 49;

    if (!canPredictSelectedContent) {
      showErrorToast(
        'Cannot run a prediction. Press Release content too short. Please type at least 50 words and add a headline',
      );
      return;
    }

    dispatch({
      type: PressReleaseStateActions.TOGGLE_SETTINGS,
    });
  };

  return (
    <FlexBlock flexDirection="row" alignItems="center">
      {headerNotice && (
        <>
          {headerNotice}
          <Gap size={12} />
        </>
      )}
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      {/* <Gap size={12} /> */}
      {/* <Button variant="secondary" onClick={handleSave} disabled={false}>
        Save
      </Button> */}
      <Gap size={12} />
      <Button onClick={toggleSettings} disabled={!canRunPrediction}>
        Predict Interest
      </Button>
    </FlexBlock>
  );
};
