import { FC } from 'react';
import { Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import ProtectedRoute from '../Global/permissions/ProtectedRoute';
import { userPermissionEnum } from '../Global/permissions/userPermissions.enum';
import CreateOrEditBlogPage from './pages/CreateOrEditBlog/CreateOrEditBlogPage';
import BlogPage from './pages/BlogPage/BlogPage';

interface IProps {}

const Blog: FC<IProps> = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={RouteConstants.blog.blog}
        component={BlogPage}
        requirePermission={userPermissionEnum.READ_BLOG}
      />
      <ProtectedRoute
        path={RouteConstants.blog.createBlog}
        component={CreateOrEditBlogPage}
        requirePermission={userPermissionEnum.CREATE_BLOG}
      />
      <ProtectedRoute
        path={RouteConstants.blog.editBlog}
        component={CreateOrEditBlogPage}
        requirePermission={userPermissionEnum.MANAGE_BLOG}
      />
      <ProtectedRoute
        path={RouteConstants.blog.draftBlog}
        component={BlogPage}
        requirePermission={userPermissionEnum.MANAGE_BLOG}
      />
    </Switch>
  );
};

export default Blog;
