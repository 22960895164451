import Typography from 'app/components/Typography';
import Tooltip from 'app/components/UI/Tooltip/Tooltip';
import { FC } from 'react';
import useOutOfLimitsMessages from '../../../CreateOrEditPitch/hooks/useOutOfLimitsMessages';

const PitchTooltips: FC = () => {
  const upgradeMessages = useOutOfLimitsMessages();
  return (
    <>
      <Tooltip
        id="authorTwitterContactTooltip"
        getContent={dataTip => {
          return (
            <>
              <div>
                <Typography.Text $colorName="nero" $size={14} $bold $dmSans>
                  Twitter
                </Typography.Text>
              </div>
              <Typography.Text $colorName="steel" $size={14}>
                {dataTip}
              </Typography.Text>
            </>
          );
        }}
      />
      <Tooltip
        id="authorEmailContactTooltip"
        getContent={dataTip => {
          return (
            <>
              <div>
                <Typography.Text $colorName="nero" $size={14} $bold $dmSans>
                  Email
                </Typography.Text>
              </div>
              <Typography.Text $colorName="steel" $size={14}>
                {dataTip}
              </Typography.Text>
            </>
          );
        }}
      />
      <Tooltip
        id="averagePercentInterestOfResultsTooltip"
        getContent={(categoryName = 'journalist') => {
          return (
            <Typography.Text $colorName="orbit" $size={14}>
              Average percent interest of top 25 results in this category
            </Typography.Text>
          );
        }}
      />
      <Tooltip
        id="upgradeAccountTooltip"
        delayHide={100}
        getContent={currentPlan => {
          return (
            <Typography.Text $colorName="steel" $size={14}>
              Upgrade to{' '}
              <a onClick={upgradeMessages.goToBilling}>{currentPlan}</a> to view
              content
            </Typography.Text>
          );
        }}
      />
      <Tooltip
        id="upgradeArticlesTooltip"
        offset={{ top: -40 }}
        getContent={currentPlan => {
          return (
            <Typography.Text $colorName="steel" $size={14}>
              Upgrade to{' '}
              <a onClick={upgradeMessages.goToBilling}>{currentPlan}</a> to view
              content
            </Typography.Text>
          );
        }}
      />
    </>
  );
};

export default PitchTooltips;
