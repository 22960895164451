import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC, useState } from 'react';
import { formatDate } from 'utils/helpers';
import {
  ISocialPostContentVersion,
  SocialPostType,
} from '../../../types/SocialPost.types';
import LeftChevron from '../../icons/LeftChevron';
import SocialPostTonePill from './SocialPostTonePill';
import SocialPostTypePill from './SocialPostTypePill';

interface ISocialPostHistoryItemProps {
  content: ISocialPostContentVersion;
  olderVersions?: ISocialPostContentVersion[];
  compact?: boolean;
  onPostContentVersionLoad: (id: number, postType: SocialPostType) => void;
}

const SocialPostHistoryItem: FC<ISocialPostHistoryItemProps> = ({
  content,
  olderVersions,
  onPostContentVersionLoad,
  compact = false,
}) => {
  const olderVersionsCount = olderVersions?.length || 0;
  const [isViewingOlderVersions, setIsViewingOlderVersions] = useState(false);

  return (
    <FlexBlock
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ borderBottom: '1px solid #E4E4E9' }}
      padding="8px"
      minWidth="100%"
      flexWrap="wrap"
    >
      <FlexBlock
        flexDirection="column"
        padding={!compact ? '16px' : '0px'}
        rowGap="8px"
      >
        {!compact && <SocialPostTypePill type={content.postType} />}

        <FlexBlock
          flexDirection="row"
          alignItems="center"
          minWidth="100%"
          columnGap="8px"
        >
          <div
            style={{
              color: '#007424',
              background: '#CAFCD2',
              padding: '6px',
              borderRadius: '4px',
              cursor: 'default',
            }}
          >
            <Typography.Text
              style={{ color: '#007424' }}
              $letterSpacing="0.004em"
              $lineHeight={16}
              $size={14}
              $bold
            >
              V.{content.version}
            </Typography.Text>
          </div>
          <SocialPostTonePill tone={content.tone || ''} />
        </FlexBlock>
        <FlexBlock flexDirection="column">
          <Typography.Text $colorName="steel">
            Created By:{' '}
            <b>
              {content.creator?.firstName || ''}{' '}
              {content.creator?.lastName || ''}
            </b>
          </Typography.Text>
          <Typography.Text $colorName="steel">
            {formatDate(new Date(content.updatedAt).toISOString())}
          </Typography.Text>
        </FlexBlock>
      </FlexBlock>
      {compact && (
        <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
          <Button
            variant="anchor"
            onClick={() =>
              onPostContentVersionLoad(content.id, content.postType)
            }
            style={{ textUnderlineOffset: '3px' }}
          >
            <Typography.Text $size={12} $lineHeight={16} $colorName="orbit">
              Restore
            </Typography.Text>
          </Button>
        </FlexBlock>
      )}
      {olderVersionsCount > 0 && !compact && (
        <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
          <Button
            variant="anchor"
            onClick={() => setIsViewingOlderVersions(o => !o)}
            style={{ textUnderlineOffset: '3px' }}
          >
            <Typography.Text $size={12} $lineHeight={16} $colorName="orbit">
              {isViewingOlderVersions ? 'Viewing' : 'View'} Past Versions
            </Typography.Text>
          </Button>
          <LeftChevron
            rotate={isViewingOlderVersions ? 270 : 90}
            duration={0.2}
          />
        </FlexBlock>
      )}
      {olderVersionsCount > 0 && isViewingOlderVersions && (
        <FlexBlock
          minWidth="100%"
          flexDirection="column"
          padding="16px"
          style={{
            border: '1px solid rgba(228, 228, 233, 1)',
            borderRadius: '8px',
          }}
        >
          {olderVersions?.map(contentVersion => (
            <SocialPostHistoryItem
              content={contentVersion}
              key={contentVersion.id}
              onPostContentVersionLoad={onPostContentVersionLoad}
              compact
            />
          ))}
        </FlexBlock>
      )}
    </FlexBlock>
  );
};

export default SocialPostHistoryItem;
