import { FC, ReactNode } from 'react';
import * as S from './InfoTip.styles';
import InfoIcon from '../../../assets/icons/InfoIcon';
import Typography from '../../Typography';

interface IProps {
  tooltipText: string;
  padding?: string;
  tooltipPosition?: 'right' | 'left';
  customTrigger?: ReactNode;
  hideIcon?: boolean;
}

const InfoTip: FC<IProps> = ({
  tooltipText,
  children,
  padding,
  tooltipPosition,
  hideIcon,
}) => {
  return (
    <S.Wrapper $padding={padding}>
      <S.TextSection>{children}</S.TextSection>
      <S.TooltipSection text={tooltipText} $tooltipPosition={tooltipPosition} />
      {!hideIcon && <InfoIcon />}
    </S.Wrapper>
  );
};

export const HoverTip: FC<IProps> = ({
  tooltipText,
  children,
  padding,
  tooltipPosition,
}) => {
  return (
    <div>
      {children}
      <S.NewTooltipSection
        text={tooltipText}
        $tooltipPosition={tooltipPosition}
      />
    </div>
  );
};

export const InfoTipAdvanced: FC<IProps> = ({
  tooltipText,
  children,
  padding,
  tooltipPosition,
  customTrigger,
}) => {
  const trigger = customTrigger || <InfoIcon />;

  return (
    <S.Wrapper $padding={padding} advanced>
      <S.TooltipSectionAdvanced $tooltipPosition={tooltipPosition}>
        {children}
      </S.TooltipSectionAdvanced>
      {trigger}
    </S.Wrapper>
  );
};

export const FormattedTooltip: FC<{
  title?: string;
  message: string;
  tooltipPositionLeft?: boolean;
}> = props => (
  <InfoTipAdvanced
    tooltipText={''}
    padding="0"
    tooltipPosition={props.tooltipPositionLeft ? 'left' : 'right'}
  >
    {props.title && (
      <div>
        <Typography.Text
          $colorName="onyx"
          $size={14}
          $bold
          $dmSans
          $lineHeight={14}
          $margin="0 0 9px"
        >
          {props.title}
        </Typography.Text>
      </div>
    )}
    <Typography.Text
      $colorName="steel"
      $size={12}
      $lineHeight={14}
      maxWidth="200px"
      whiteSpace="break-spaces"
    >
      {props.message}
    </Typography.Text>
  </InfoTipAdvanced>
);

export default InfoTip;
