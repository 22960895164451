import { FC, SVGProps } from 'react';

interface IProps {
  size?: number;
  color?: string;
}

const LocationIconThin: FC = (props: IProps & SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 13}
    height={props.size || 13}
    fill="none"
    {...props}
  >
    <path
      stroke={props.color || '#3F3F42'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5 5c0 3.5-4.5 6.5-4.5 6.5S1.5 8.5 1.5 5a4.5 4.5 0 0 1 9 0Z"
    />
    <path
      stroke={props.color || '#3F3F42'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
  </svg>
);

export default LocationIconThin;
