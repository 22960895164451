import {
  ContentHeader,
  ContentLayout,
} from 'app/components/UI/ContentLayout/ContentLayout';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  BylinePageTabs,
  ContentControls,
  TopHeader,
} from './parts/BylinePageHeader';
import MainGrid from 'app/components/UI/MainGrid/MainGrid';
import BylinePageContent from './parts/BylinePageContent';
import { ContentWrapper, FullWidth, StickToTop } from './BylinePage.styles';
import BylineVersionDrawer from './parts/BylineVersionDrawer';
import BylineSettingsDrawer from './parts/BylineSettingsDrawer';
import { useBylineData } from './hooks/useBylineData';
import FlexBlock from 'app/components/UI/FlexBlock';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';
import PageLoader from 'app/components/UI/PageLoader';
import { RouteConstants } from 'app/routes';
import { showCustomToast, showErrorToast, showSuccessToast } from 'utils/toast';
import useLimitExceededPopup from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/hooks/useLimitExceededPopup';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import { useHistory } from 'react-router-dom';
import CreateBylineForm from '../CreateOrEditByline/parts/CreateBylineForm';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {
  useCreateNewBylineMutation,
  useGetGeneratedBylineMutation,
  useSaveBylineContentMutation,
} from 'app/api';
import {
  IByline,
  IBylineContentPayload,
  IBylineContentResponse,
  IBylineGenerationPayload,
  IBylineGenerationResponse,
} from 'app/api.types';
import { useBylineGenerationLimits } from './hooks/useBylineGenerationLimits';
import { validateCreateBylinePayload } from './BylinePage.helpers';
import BylineRegenerationModal from './parts/BylineRegenerationModal';
import { BylineGenerationSuccessToast } from './parts/BylineGenerationSuccessToast';
import { logger } from 'utils/apm/logger';

const BylinePage: FC = () => {
  const [isVersionDrawerOpen, setIsVersionDrawerOpen] = useState(false);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);

  const history = useHistory();

  const {
    isBylineLoading,
    isBylineContentLoading,
    content,
    payload,
    byline: bylineData,
    isDraft,
    bylineId,
    tone,
    versionId,
    projectId,
    tenantId,
    isError,
  } = useBylineData();

  const [generateByline, { isLoading: isGenerateLoading }] =
    useGetGeneratedBylineMutation();

  const [saveGeneratedByline, { isLoading: isBylineSaving }] =
    useSaveBylineContentMutation();

  const [createByline] = useCreateNewBylineMutation();

  const [currentBylineContent, setCurrentBylineContent] = useState({
    title: content?.title || '',
    body: content?.body || '',
  });

  useEffect(() => {
    setCurrentBylineContent({
      title: content?.title || '',
      body: content?.body || '',
    });
  }, [content]);

  const isBylineModified = useMemo(() => {
    if (!currentBylineContent.title || !currentBylineContent.body) return false;

    if (
      content?.title !== currentBylineContent.title ||
      content.body !== currentBylineContent.body
    ) {
      return true;
    }

    return false;
  }, [
    content?.title,
    content?.body,
    currentBylineContent.title,
    currentBylineContent.body,
  ]);

  const { createModal } = usePromptModalContext();
  const limitExceededPopup = useLimitExceededPopup();

  const { bylineGenerationsLeft, refresh: refreshLimits } =
    useBylineGenerationLimits();

  const redirectToContentVersion = (bylineId: number, versionId: number) => {
    const bylineRoute = RouteConstants.byline.makeBylineUrl(
      tenantId,
      projectId,
      bylineId,
      versionId,
    );
    history.push(bylineRoute);
  };

  const handleRegenerate = async ({
    modifiedPayload,
    tone,
  }: {
    modifiedPayload?: IBylineGenerationPayload;
    tone?: string;
  }) => {
    if (bylineGenerationsLeft === 0) {
      limitExceededPopup.show();
      return;
    }

    setIsSettingsDrawerOpen(false);
    if (!payload) return;

    let createdByline: IByline | undefined;
    let latestSavedContent: IBylineContentResponse | undefined;
    let currentContentVersion = versionId;

    try {
      // Persist draft or modified content as a new Byline or Byline's content version before regenerating content
      if (isBylineModified || isDraft) {
        if (!bylineId) {
          createdByline = await createNewByline(payload);
          if (createdByline && createdByline.latestVersion) {
            currentContentVersion = createdByline.latestVersion;
          }
        } else {
          latestSavedContent = await persistBylineContent();
          if (latestSavedContent) {
            currentContentVersion = versionId + 1;
          }
        }
      }

      const regenerationPayload = modifiedPayload || payload;

      if (regenerationPayload) {
        if (tone) {
          regenerationPayload.tone = tone;
        }

        let regeneratedContent: IBylineGenerationResponse;
        regeneratedContent = await generateByline(regenerationPayload).unwrap();

        const currentBylineId = bylineId || createdByline?.id;

        // Must save newly generated content as a new content version
        if (!currentBylineId) {
          showErrorToast('Could not save generated Byline.');
          logger.logError('Byline - Save - Missing Byline ID');
          return;
        }

        if (regeneratedContent) {
          const updatedBylineBody = {
            title: regeneratedContent.generatedTitle,
            body: regeneratedContent.generatedBody,
            ...regenerationPayload,
          };

          latestSavedContent = await persistBylineContent(
            updatedBylineBody,
            currentBylineId,
          );
        }

        if (latestSavedContent) {
          // Redirect to the newest content version
          showCustomToast(
            <BylineGenerationSuccessToast
              currentLimit={bylineGenerationsLeft - 1}
              message="Byline Regenerated"
            />,
            'toast-violet',
          );

          refreshLimits();
          redirectToContentVersion(currentBylineId, currentContentVersion + 1);
        }
      }
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Could not regenerate Byline.',
      );
    }
  };

  const persistBylineContent = async (
    regeneratedContent?: IBylineContentPayload,
    currentBylineId?: number,
  ) => {
    try {
      const bylinePayload = regeneratedContent || {
        ...currentBylineContent,
        bylineTopic: payload?.bylineTopic || '',
        tone: payload?.tone || '',
        productKeywords: payload?.productKeywords || '',
      };

      const contentPayload = {
        bylineId: currentBylineId || bylineId,
        body: bylinePayload,
      };

      if (
        contentPayload.body &&
        contentPayload.body.bylineTopic &&
        contentPayload.body.tone
      ) {
        const savedContent = await saveGeneratedByline(contentPayload).unwrap();
        return savedContent;
      }
    } catch (error) {
      throw error;
    }
  };

  const createNewByline = async (payload: IBylineGenerationPayload) => {
    try {
      const createBylinePayload = {
        projectId,
        bylineContentCreateBody: {
          ...currentBylineContent,
          bylineTopic: payload.bylineTopic,
          productKeywords: payload.productKeywords,
          tone: payload.tone,
        },
      };

      if (!validateCreateBylinePayload(createBylinePayload)) {
        showErrorToast('Could not save byline.');
        logger.logError('Byline - Save - Invalid byline payload');
        return undefined;
      }

      const createdByline = createByline(createBylinePayload).unwrap();
      return createdByline;
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Could not save byline.',
      );
      return undefined;
    }
  };

  const handleSaveAction = async () => {
    if (!payload) return;
    if (bylineId) {
      const savedContent = await persistBylineContent();
      if (savedContent) {
        showSuccessToast('Byline Content Saved.');
        redirectToContentVersion(savedContent.bylineId, versionId + 1);
      }
    } else {
      const savedByline = await createNewByline(payload);
      if (savedByline) {
        showSuccessToast('Byline Saved.');
        redirectToContentVersion(
          savedByline.id,
          savedByline.latestVersion || 1,
        );
      }
    }
  };

  const regenerateContentClick = async (tone?: string) => {
    if (bylineGenerationsLeft === 0) {
      limitExceededPopup.show();
    } else {
      createModal({
        title: `Regenerate Byline`,
        description: `This will regenerate your Byline content, overwritting current content. You'll need to revert to a previous version to restore any overwritten content.`,
        okButtonText: 'Regenerate',
        cancelButtonText: 'Cancel',
        okButtonDanger: false,
        okButtonCallback: async () => {
          await handleRegenerate({ tone });
        },
      });
    }
  };

  if (isBylineLoading) return <PageLoader />;

  if (isError)
    return (
      <FlexBlock>
        <EmptyDataNotice
          title="Could not load Byline"
          description="Something went wrong."
        />
      </FlexBlock>
    );

  return (
    <ContentLayout>
      <StickToTop>
        <ContentHeader>
          {tone && (
            <TopHeader
              toggleVersionsDrawer={setIsVersionDrawerOpen}
              regenerateContent={regenerateContentClick}
              toggleSettings={setIsSettingsDrawerOpen}
              saveByline={handleSaveAction}
              isContentModified={isBylineModified}
              isSaveLoading={isBylineSaving}
              isDraft={isDraft}
              tone={tone}
            />
          )}
        </ContentHeader>

        <BylinePageTabs>
          {!isDraft && bylineId && (
            <ContentControls
              toggleVersionsDrawer={setIsVersionDrawerOpen}
              version={versionId || 1}
              bylineId={bylineId}
            />
          )}
        </BylinePageTabs>
      </StickToTop>
      <MainGrid minContent>
        <ContentWrapper>
          <FullWidth>
            {isBylineContentLoading ? (
              <PageLoader />
            ) : (
              <BylinePageContent
                content={currentBylineContent}
                handleContentChange={setCurrentBylineContent}
                regenerateContentClick={regenerateContentClick}
              />
            )}
          </FullWidth>
        </ContentWrapper>
      </MainGrid>
      {bylineData && (
        <BylineVersionDrawer
          isOpen={isVersionDrawerOpen}
          setIsOpen={setIsVersionDrawerOpen}
          byline={bylineData}
        />
      )}
      {isSettingsDrawerOpen && (
        <BylineSettingsDrawer
          isOpen={isSettingsDrawerOpen}
          setIsOpen={setIsSettingsDrawerOpen}
        >
          <CreateBylineForm
            compact
            payload={payload}
            regenerate={handleRegenerate}
          />
        </BylineSettingsDrawer>
      )}
      <BylineRegenerationModal isOpen={isGenerateLoading} />
    </ContentLayout>
  );
};

export default BylinePage;
