import Typography from 'app/components/Typography';
import { SOCIAL_FIELDS_CONFIG } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/parts/Socials/Socials.config';
import { SocialPostType } from '../../../types/SocialPost.types';
import { FC } from 'react';

const SocialPostTypePill: FC<{ type: SocialPostType }> = ({ type }) => {
  const socialProviderDisplayMeta = SOCIAL_FIELDS_CONFIG.find(
    socialProvider => socialProvider.value === type,
  );

  const formatSocialProviderLabel = (label: string) => {
    if (label === 'X') {
      return 'X/Threads';
    }

    return label;
  };

  return (
    <Typography.Text $dmSans $bold $lineHeight={24} $size={16}>
      {socialProviderDisplayMeta
        ? formatSocialProviderLabel(socialProviderDisplayMeta.label)
        : type}
    </Typography.Text>
  );
};

export default SocialPostTypePill;
