import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import Input from 'app/components/UI/Input/Input';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import * as S from 'app/containers/Blog/pages/CreateOrEditBlog/CreateOrEditBlogPage.styles';

import Button from 'app/components/UI/Button/Button';
import { ToneSelectDropDown } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import { TONE_SELECT_OPTIONS } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import { getWordCount } from 'utils/helpers';
import { useGetGeneratedBlogMutation } from 'app/api';
import useLimitExceededPopup from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/hooks/useLimitExceededPopup';
import { IBlogGenerationPayload } from 'app/api.types';
import { showCustomToast, showErrorToast } from 'utils/toast';
import { MIN_BLOG_CONTENT_WORD_LENGTH } from '../CreateOrEditBlogPage.constants';
import { BlogGenerationSuccessToast } from '../../BlogPage/parts/BlogGenerationSuccessToast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { useHistory, useParams } from 'react-router-dom';
import { getToneObjectByValue } from '../../BlogPage/BlogPage.helpers';
import { useBlogGenerationLimits } from '../../BlogPage/hooks/useBlogGenerationLimits';
import FormSection from './FormSection';
import { RouteConstants } from 'app/routes';
import BlogRegenerationModal from '../../BlogPage/parts/BlogRegenerationModal';

interface ICreateBioForm {
  compact?: boolean;
  payload?: IBlogGenerationPayload;

  regenerate?: ({
    modifiedPayload,
    tone,
  }: {
    modifiedPayload?: IBlogGenerationPayload;
    tone?: string;
  }) => void;
}

const CreateBlogForm: FC<ICreateBioForm> = ({
  compact = false,
  payload,
  regenerate,
}) => {
  const params: {
    tenantId: string;
    projectId: string;
  } = useParams();

  const [tenantId, projectId] = useMemo(
    () => [Number(params.tenantId), Number(params.projectId)],
    [params],
  );

  const history = useHistory();

  const [blogContent, setBlogContent] = useState(payload?.blogTopic || '');
  const [tone, setTone] = useState(
    payload?.tone ? getToneObjectByValue(payload.tone) : TONE_SELECT_OPTIONS[0],
  );
  const [keywords, setKeywords] = useState(payload?.productKeywords || '');

  const [isValidBlogContentLength, setIsValidBlogContentLength] = useState<
    number | null
  >(null);

  const validateBlogContentLength = () => {
    const wordCount = getWordCount(blogContent || '');
    if (wordCount < MIN_BLOG_CONTENT_WORD_LENGTH) {
      setIsValidBlogContentLength(MIN_BLOG_CONTENT_WORD_LENGTH - wordCount);
    }
  };

  const handleBlogContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setBlogContent(event.target.value);
    if (isValidBlogContentLength !== null) setIsValidBlogContentLength(null);
  };

  const [generateBlog, { isLoading: isGenerating }] =
    useGetGeneratedBlogMutation();

  const limitExceededPopup = useLimitExceededPopup();

  const { blogGenerationsLeft, refresh: refreshLimits } =
    useBlogGenerationLimits();

  const canGenerate =
    !isGenerating && isValidBlogContentLength === null && blogContent;

  const handleGenerate = async () => {
    if (!blogContent) return;

    if (blogGenerationsLeft === 0) {
      limitExceededPopup.show();
      return;
    }

    const generationPayload: IBlogGenerationPayload = {
      blogTopic: blogContent,
      productKeywords: keywords || undefined,
      tone: tone.value,
    };

    try {
      const generatedBlog = await generateBlog(generationPayload).unwrap();

      showCustomToast(
        <BlogGenerationSuccessToast
          currentLimit={blogGenerationsLeft - 1}
          message="Blog Generated"
        />,
        'toast-violet',
      );

      refreshLimits();

      history.push({
        pathname: RouteConstants.blog.makeCreateDraftBlogUrl(
          tenantId,
          projectId,
        ),
        state: {
          generatedResponse: generatedBlog,
          generationPayload: generationPayload,
        },
      });
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Failed to generate Blog',
      );
    }
  };

  const handleRegenerate = () => {
    if (!blogContent || !regenerate) return;

    const regenerationPayload: IBlogGenerationPayload = {
      blogTopic: blogContent,
      productKeywords: keywords || undefined,
      tone: tone.value,
    };

    regenerate({ modifiedPayload: regenerationPayload });
  };

  return (
    <>
      <FormSection
        index={1}
        title="Topic"
        subtitle="What is your blog about?"
        compact={compact}
      >
        <S.ContentInputWrapper>
          <S.NativeInputBox
            placeholder={`Please enter at least ${MIN_BLOG_CONTENT_WORD_LENGTH} words…`}
            value={blogContent}
            onChange={handleBlogContentChange}
            onBlur={() => validateBlogContentLength()}
            style={
              isValidBlogContentLength !== null
                ? { border: `1px solid red` }
                : undefined
            }
            minRows={10}
            maxRows={20}
          />
          {isValidBlogContentLength !== null && (
            <FlexBlock justifyContent="left" padding="8px">
              <Typography.Text
                $colorName="redOrange"
                $size={16}
                $lineHeight={24}
              >
                Please enter{' '}
                <Typography.Text $bold $colorName="redOrange">
                  {isValidBlogContentLength}
                </Typography.Text>{' '}
                more word{isValidBlogContentLength > 1 && 's'}
              </Typography.Text>
            </FlexBlock>
          )}
        </S.ContentInputWrapper>
      </FormSection>
      <Gap size={30} />
      <FormSection
        index={2}
        title="Tone"
        subtitle="What would you like your blog’s tone to be?"
        compact={compact}
      >
        <S.FullWidth>
          <ToneSelectDropDown
            value={tone}
            onChange={setTone}
            selectComponentWidth="100%"
            height="56px"
          />
        </S.FullWidth>
      </FormSection>
      <Gap size={30} />
      <FormSection
        index={3}
        title="Optional Details"
        subtitle="What do you want to highlight?"
        compact={compact}
      >
        <Input
          value={keywords}
          onChange={e => setKeywords(e.target.value)}
          placeholder="e.g. Statistic, Product, Keywords, etc."
        />
      </FormSection>
      <Gap size={24} />
      <S.FullWidth>
        <Button
          onClick={compact ? handleRegenerate : handleGenerate}
          disabled={!canGenerate}
        >
          <Typography.Text
            $dmSans
            $bold
            $size={18}
            $lineHeight={24}
            $colorName="white"
          >
            {compact ? 'Save & Regenerate' : 'Generate Blog'}
          </Typography.Text>
        </Button>
      </S.FullWidth>
      {!compact && (
        <BlogRegenerationModal isOpen={isGenerating} regenerating={false} />
      )}
    </>
  );
};

export default CreateBlogForm;
