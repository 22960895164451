import styled, { css } from 'styled-components';

export const UpgradePlanButton = styled.button(
  () => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 100, 0, 0.1);
    border: none;
    margin: 0 8px;
    margin-bottom: 4px;
    padding: 0 16px;
    cursor: pointer;
    height: 40px;
    border-radius: 4px;
  `,
);

export const UpgradePlanSubMenu = styled.div<{
  $isOpen: boolean;
  $topPosition: number;
}>(
  ({ theme, $isOpen, $topPosition }) => css`
    position: absolute;
    top: ${$topPosition}px;
    left: -248px;
    display: ${$isOpen ? 'flex' : 'none'};
    flex-direction: column;
    width: 240px;
    border: 1px solid ${theme.colors.aluminum};
    border-radius: 8px;
    background-color: ${theme.colors.white};
    box-shadow: 0 2px 16px 0 rgba(12, 12, 13, 0.1);
  `,
);

export const CheckIconBlueWrapper = styled.div(
  ({ theme }) => css`
    border-radius: 50%;
    min-height: 24px;
    min-width: 24px;
    background: rgba(29, 118, 222, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    > svg path {
      fill: ${theme.colors.orbit};
    }
  `,
);

export const UpgradeButtonContainer = styled.div`
  padding: 16px;

  > button {
    height: 40px;
    width: 100%;
    padding: 0;
  }
`;
