import { FC, ReactElement, useMemo } from 'react';
import { userPermissionEnum } from './userPermissions.enum';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserPermissions } from '../slice/selectors';
import { satisfiesPermission } from './permissions.helpers';

import DefaultFallback from './DefaultFallback';

interface IProps {
  require: userPermissionEnum;
  showFallback?: boolean;
  customFallback?: ReactElement;
}

const IfHasPermission: FC<IProps> = props => {
  const userPermissions = useAppSelector(selectUserPermissions);

  const hasPermission = useMemo(() => {
    if (!userPermissions) return false;
    return satisfiesPermission(props.require, userPermissions);
  }, [userPermissions, props.require]);

  if (!hasPermission) {
    if (props.showFallback) {
      return (
        props.customFallback || <DefaultFallback require={props.require} />
      );
    }
    return <></>;
  }

  return <>{props.children}</>;
};

export default IfHasPermission;
