import Tabs from 'app/components/UI/Tabs';
import GeneratedContentHeader from 'app/containers/Pitch/pages/CreateFirstPitch/parts/GeneratedContentHeader';
import { FC } from 'react';
import { Actions } from './parts/Actions';
import { IProject } from 'app/api.types';
import { PredictedContentTab } from './parts/Tabs';

interface IFirstPitchHeaderProps {
  activeTab: PredictedContentTab;
  setActiveTab: (tab: PredictedContentTab) => void;
  project?: IProject;
}

export const FirstPitchHeader: FC<IFirstPitchHeaderProps> = ({
  activeTab,
  setActiveTab,
  project,
}) => {
  return (
    <>
      {project && (
        <GeneratedContentHeader
          brandTitle={project.name}
          brandAvatar={project.logo}
        />
      )}
      <Tabs.Bar $padding="0 50px">
        <Tabs.Tab
          active={activeTab === PredictedContentTab.PITCH}
          onClick={() => setActiveTab(PredictedContentTab.PITCH)}
        >
          Pitch
        </Tabs.Tab>
        <Tabs.Tab
          active={activeTab === PredictedContentTab.SOCIALS}
          onClick={() => setActiveTab(PredictedContentTab.SOCIALS)}
        >
          Social Posts
        </Tabs.Tab>
        <Actions firstPitch={true} />
      </Tabs.Bar>
    </>
  );
};
