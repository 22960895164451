import { FC } from 'react';
import FlexBlock from '../../FlexBlock';
import { SkeletonDiv } from '../Skeletons.styles';

interface ISeenInSkeletonProps {}

const SeenInSkeleton: FC<ISeenInSkeletonProps> = () => {
  return (
    <FlexBlock
      flexDirection="column"
      maxWidth="90%"
      rowGap="8px"
      margin="20px auto"
    >
      <SkeletonDiv
        shimmerAnimation
        height="40px"
        borderRadius="8px"
        width="100%"
      />
      <SkeletonDiv
        shimmerAnimation
        height="40px"
        borderRadius="8px"
        width="100%"
      />
      <SkeletonDiv
        shimmerAnimation
        height="40px"
        borderRadius="8px"
        width="100%"
      />
      <SkeletonDiv
        shimmerAnimation
        height="40px"
        borderRadius="8px"
        width="100%"
      />
    </FlexBlock>
  );
};

export default SeenInSkeleton;
