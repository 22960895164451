import { useGetTenantsQuery } from 'app/api';
import { ITenant } from 'app/api.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { showErrorToast } from 'utils/toast';

export const useTenantSelector = () => {
  const [tenantSearchPhrase, setTenantSearchPhrase] = useState('');
  const [selectedTenant, setSelectedTenant] = useState<ITenant | undefined>(
    undefined,
  );

  const {
    data: tenants,
    isLoading,
    isError,
  } = useGetTenantsQuery({
    name: tenantSearchPhrase,
    pagination: {
      page: { page: 0, size: 500 },
      sort: [{ property: 'name', ascending: true }],
    },
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Could not load available tenants.', 'Error');
    }
  }, [isError]);

  const availableTenantsOptions = useMemo(() => {
    if (!selectedTenant) return tenants?.content;

    return (
      tenants?.content.filter(tenant => selectedTenant?.id !== tenant.id) || []
    );
  }, [tenants, selectedTenant]);

  const handleTenantSelect = useCallback((tenant: ITenant) => {
    setSelectedTenant(tenant);
    setTenantSearchPhrase('');
  }, []);

  const handleTenantRemove = useCallback(
    () => () => {
      setSelectedTenant(undefined);
    },
    [],
  );

  return {
    selectedTenant,
    availableTenantsOptions,
    handleTenantSelect,
    handleTenantRemove,
    tenantSearchPhrase,
    setTenantSearchPhrase,
    isLoading,
  };
};
