import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectActiveTenantId } from '../../../Global/slice/selectors';
import { useDeleteProjectMutation, useGetProjectsQuery } from '../../../../api';
import Button from '../../../../components/UI/Button/Button';
import MainGrid from '../../../../components/UI/MainGrid/MainGrid';
import PageHeader from '../../../../components/UI/PageHeader';
import Input from '../../../../components/UI/Input/Input';
import SearchGrayIcon from '../../../../assets/icons/SearchGrayIcon';
import { IProject, IPaginationRequestPart } from '../../../../api.types';
import {
  defaultPaginationSettings,
  noDataNoticeConfig,
  tableColumnConfig,
} from './ProjectsList.constants';
import DataTable from '../../../../components/Table/DataTable';
import Card from 'app/components/UI/Card';
import Avatar from '../../../../components/UI/Avatar';
import Typography from '../../../../components/Typography';
import { useHistory, useParams } from 'react-router-dom';
import { RouteConstants } from '../../../../routes';
import { Helmet } from 'react-helmet-async';
import DotsVertical from '../../../../assets/icons/DotsVertical';
import ContextMenu, {
  ContextMenuOption,
} from '../../../../components/UI/ContextMenu/ContextMenu';
import { usePromptModalContext } from '../../../Global/parts/PromptModal/PromptModal.context';
import { ActionPasserProvider } from '../../../../../utils/actionPasser/actionPasser';
import { showErrorToast, showSuccessToast } from '../../../../../utils/toast';
import { getErrorText } from '../../../../../utils/error-messages';
import { useGlobalSlice } from '../../../Global/slice';
import IfHasPermission from '../../../Global/permissions/IfHasPermission';
import { userPermissionEnum } from '../../../Global/permissions/userPermissions.enum';
import FolderIcon from 'app/assets/icons/FolderIcon';
import Gap from 'app/components/UI/Gap';
import FlexBlock from 'app/components/UI/FlexBlock';

import {
  ContentLayout,
  ContentHeader,
  ContentFooter,
} from 'app/components/UI/ContentLayout/ContentLayout';
import { Spinner } from 'app/components/UI/Spinner';
import { ContentCount } from './parts/ContentCount';
import AddNewProjectModal from './parts/AddNewProjectModal';

interface IProps {}

const ProjectsList: FC<IProps> = () => {
  const [pagination, setPagination] = useState<IPaginationRequestPart>(
    defaultPaginationSettings,
  );
  const [searchPhrase, setSearchPhrase] = useState('');
  const history = useHistory();
  const params = useParams<{ tenantId?: string }>();
  const { createModal } = usePromptModalContext();

  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState<
    boolean | number
  >(false);

  const activeTenantId = useAppSelector(selectActiveTenantId);

  const dispatch = useAppDispatch();
  const { actions: globalActions } = useGlobalSlice();

  const routeTenantId = useMemo<number | null>(() => {
    return params?.tenantId ? Number(params.tenantId) : null;
  }, [params]);

  useEffect(() => {
    if (routeTenantId) {
      dispatch(globalActions.setActiveTenantId(routeTenantId));
    }
  }, [routeTenantId, dispatch, globalActions]);

  const { data: projects, isFetching } = useGetProjectsQuery({
    tenantId: routeTenantId || activeTenantId || undefined,
    pagination,
    search: searchPhrase,
  });

  useEffect(() => {
    setPagination(defaultPaginationSettings);
  }, [activeTenantId]);

  const [deleteProject] = useDeleteProjectMutation();

  const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> =
    event => {
      if (searchPhrase !== '') {
        setPagination(defaultPaginationSettings);
      }
      setSearchPhrase(event.target.value);
    };

  const goToContent = (project: IProject) => {
    if (activeTenantId)
      history.push(
        RouteConstants.projects.makeContentUrl(activeTenantId, project.id),
      );
  };

  const handleProjectDeleteClick = (project: IProject) => () => {
    createModal({
      title: `Remove Project`,
      description: `Are you sure you want to delete project "${project.name}". This action is irreversible.`,
      okButtonText: 'Delete',
      cancelButtonText: 'Keep',
      okButtonDanger: true,
      okButtonCallback: async () => {
        try {
          await deleteProject({ projectId: project.id }).unwrap();
          showSuccessToast('Project deleted');
        } catch (e: any) {
          showErrorToast(getErrorText(e), 'Failed to delete project');
        }
      },
    });
  };

  const renderProjectCard = (item: IProject) => {
    const project = item;

    const contentCount =
      item.contentMeta?.reduce((acc, obj) => acc + obj.count, 0) || 0;

    return (
      <Card.Wrapper
        clickable
        onClick={() => goToContent(project)}
        key={project.id}
      >
        <Card.Header padding="6px 6px 12px 6px">
          <Avatar name={project.name} url={project.logo} size="56" />
          <Card.HeaderSection>
            <Typography.Text $size={16} $colorName="orbit" $bold $dmSans>
              {project.name}
            </Typography.Text>
            <Typography.Text $colorName="steel">
              {`${contentCount} ${
                contentCount === 1 ? 'Content Piece' : 'Content Pieces'
              }`}
            </Typography.Text>
          </Card.HeaderSection>
          <Card.HeaderSection alignRight alignTop className="hover-visible">
            <IfHasPermission require={userPermissionEnum.MANAGE_PROJECTS}>
              <ContextMenu
                buttonText={<DotsVertical />}
                preventDefaultMenuButtonAction
                buttonProps={{
                  variant: 'icon-button-no-padding',
                  padding: '0',
                }}
                menuOptions={{
                  align: 'right',
                }}
              >
                {activeTenantId && (
                  <ContextMenuOption
                    onClick={() => setIsNewProjectModalOpen(project.id)}
                  >
                    <Typography.Text>Edit Project</Typography.Text>
                  </ContextMenuOption>
                )}
                <ContextMenuOption onClick={handleProjectDeleteClick(project)}>
                  <Typography.Text $colorName="redOrange">
                    Delete
                  </Typography.Text>
                </ContextMenuOption>
              </ContextMenu>
            </IfHasPermission>
          </Card.HeaderSection>
        </Card.Header>

        <Card.Content padding="6px 6px 12px 6px">
          {project.contentMeta &&
            project.contentMeta.map(contentMeta => (
              <ContentCount
                key={contentMeta.key}
                count={contentMeta.count}
                displayName={contentMeta.displayName}
              />
            ))}
        </Card.Content>

        <Card.Footer padding="6px 6px 12px 6px"></Card.Footer>
      </Card.Wrapper>
    );
  };

  return (
    <>
      <Helmet title="Projects" />
      <ContentLayout>
        <ContentHeader>
          <PageHeader.Section>
            <Typography.Text
              $size={20}
              $lineHeight={24}
              $dmSans
              $bold
              $colorName="onyx"
            >
              My Projects
            </Typography.Text>
          </PageHeader.Section>
          <PageHeader.Section justifyContent="flex-end" gridColumnGap="12px">
            <Input
              icon={
                projects && isFetching ? (
                  <FlexBlock justifyContent="center" alignItems="center">
                    <Spinner $margin="auto" $size="20px" $isStratos />
                  </FlexBlock>
                ) : (
                  <SearchGrayIcon />
                )
              }
              variant="default"
              placeholder="Search..."
              onChange={handleSearchInputChange}
            />

            {Boolean(activeTenantId) && (
              <IfHasPermission require={userPermissionEnum.CREATE_PROJECTS}>
                <Button onClick={() => setIsNewProjectModalOpen(true)}>
                  <FolderIcon />
                  <Gap size={8} />
                  New Project
                </Button>
              </IfHasPermission>
            )}
          </PageHeader.Section>
        </ContentHeader>
        <MainGrid>
          <ActionPasserProvider
            action={({ type, arg }) => {
              if (type === 'deleteProject') {
                handleProjectDeleteClick(arg)();
              }

              if (type === 'editProject') {
                setIsNewProjectModalOpen(arg);
              }
            }}
          >
            <DataTable<IProject>
              dataTypeName="Projects"
              data={projects}
              columns={tableColumnConfig}
              pagination={pagination}
              onPaginationChange={setPagination}
              renderListItem={renderProjectCard}
              onRowClick={goToContent}
              emptyNoticeConfig={noDataNoticeConfig}
              isDataLoading={isFetching}
            />
          </ActionPasserProvider>
        </MainGrid>
        <ContentFooter />
      </ContentLayout>
      {isNewProjectModalOpen && (
        <AddNewProjectModal
          isOpen={isNewProjectModalOpen}
          setIsOpen={setIsNewProjectModalOpen}
        />
      )}
    </>
  );
};

export default ProjectsList;
