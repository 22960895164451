import { useAppSelector } from '../../store/hooks';
import { selectActiveTenantObject } from '../../app/containers/Global/slice/selectors';
import Analytics from '../analytics';
import { useEffect, useRef } from 'react';

/**
 * This function takes a tenant name and formats it for use as an ID in analytics.
 * It removes all non-alphanumeric, non-space, non-dash characters,
 * replaces spaces with underscores, and replaces multiple underscores with a single underscore.
 *
 * @param {string} tenantName - The name of the client to be formatted.
 * @returns {string} - The formatted client name.
 */
const getClientDisplayNameForId = (tenantName: string): string => {
  return tenantName
    .replace(/[^a-zA-Z0-9 -]/g, '')
    .replace(/\s/g, '_')
    .replace(/_+/g, '_');
};

/**
 * This function takes a tenant ID and a tenant name, and formats them for use in analytics.
 * It prefixes the tenant ID and name with 'prophet_'.
 *
 * @param {number} tenantId - The ID of the tenant.
 * @param {string} tenantName - The name of the tenant.
 * @returns {string} - The formatted tenant ID and name.
 */
const formatPrefixedClientIdForAnalytics = (
  tenantId: number,
  tenantName: string,
): string => {
  return `prophet_${tenantId}_${tenantName}`;
};

/**
 * This hook is used to track active tenant changes.
 * When the active tenant changes, it sends a group event to Analytics.
 * Tenant ID gets converted into `prophet_TENANT-ID_TENANT-NAME` format to store in Analytics.
 */
export function useClientChangeTracking(): void {
  const activeTenant = useAppSelector(selectActiveTenantObject);

  const previousTenantIdRef = useRef<number | undefined>();

  useEffect(() => {
    if (
      activeTenant &&
      activeTenant.id &&
      activeTenant.name &&
      activeTenant.id !== previousTenantIdRef.current
    ) {
      const tenantNameAsId = getClientDisplayNameForId(activeTenant.name);

      if (tenantNameAsId) {
        const prefixedClientId = formatPrefixedClientIdForAnalytics(
          activeTenant.id,
          tenantNameAsId,
        );
        Analytics.group(prefixedClientId, activeTenant.name);
        previousTenantIdRef.current = activeTenant.id;
      }
    }
  }, [activeTenant]);
}
