import styled, { css } from 'styled-components/macro';

interface IGapProps {
  size?: string | number;
}

const Gap = styled.div<IGapProps>(
  ({ size = 0 }) => css`
    height: ${size}px;
    width: ${size}px;
  `,
);

export default Gap;
