import { RouteConstants } from 'app/routes';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  ICreateOrEditPageParams,
  PitchProcessStatusEnum,
} from '../CreateOrEditPitchPage';
import { useEffect, useMemo } from 'react';
import {
  PitchStateActions,
  usePitch,
  usePitchDispatch,
} from '../parts/PitchContext';
import {
  useCreateNewPitchMutation,
  useGetGeneratedContentByPitchIDMutation,
  useGetGeneratedContentMutation,
  useGetPitchQuery,
  useGetPredictionQuery,
  useRunPredictionMutation,
  useUpdatePitchMutation,
} from 'app/api';
import { useGenerationLimits } from './useGenerationLimits';
import useOutOfLimitsMessages from './useOutOfLimitsMessages';
import useLimitExceededPopup from '../parts/DigDeeperSlideout/hooks/useLimitExceededPopup';
import moment from 'moment';
import {
  isPitchFormValid,
  validateContentForGenerate,
} from '../CreateOrEditPitch.helpers';
import {
  createPermanentToast,
  showCustomToast,
  showErrorToast,
  showSuccessToast,
} from 'utils/toast';
import {
  GENERATE_LOADING_TOOLTIP_CONTENT,
  GenerateSuccessToastContent,
} from '../CreateOrEditPitch.constants';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import Typography from 'app/components/Typography';

export const usePitchDataWithContext = () => {
  const createPitchPage = useRouteMatch<{
    tenantId: string;
    projectId: string;
  }>(RouteConstants.pitch.createPitch);

  const editPitchPage = useRouteMatch<{
    tenantId: string;
    projectId: string;
    pitchId: string;
    versionId: string;
  }>(RouteConstants.pitch.editPitch);

  const params: ICreateOrEditPageParams | null = useMemo(() => {
    if (createPitchPage) return createPitchPage.params;
    if (editPitchPage) return editPitchPage.params;
    return null;
  }, [createPitchPage, editPitchPage]);

  const pitchState = usePitch();
  const dispatch = usePitchDispatch();

  const history = useHistory();
  const generationLimits = useGenerationLimits();
  const limitExceededPopup = useLimitExceededPopup();
  const limitMessages = useOutOfLimitsMessages();

  // Mutations
  const [createPitch] = useCreateNewPitchMutation();
  const [updatePitch] = useUpdatePitchMutation();

  const [runNotEvaluatedPitchPrediction] = useRunPredictionMutation();

  const [getGeneratedContent, { isLoading: isGenerateLoadingWithID }] =
    useGetGeneratedContentByPitchIDMutation();

  const [
    getGeneratedContentWithoutPitch,
    { isLoading: isGenerateWithoutPitchLoading },
  ] = useGetGeneratedContentMutation();

  const { data: pitchData, isFetching: isPitchDataFetching } = useGetPitchQuery(
    {
      pitchId: Number(editPitchPage?.params.pitchId),
    },
    {
      skip: Boolean(createPitchPage),
    },
  );

  const { data: predictionData, isFetching: isPredictionDataFetching } =
    useGetPredictionQuery(
      {
        pitchId: Number(editPitchPage?.params.pitchId),
        versionId: Number(editPitchPage?.params.versionId),
        predictionType: 'PITCH',
      },
      {
        skip: Boolean(createPitchPage),
      },
    );

  // Derived state

  const pageTitle: string = useMemo(() => {
    if (createPitchPage) {
      return 'New Pitch';
    }
    if (editPitchPage) {
      return 'Edit Pitch';
    }
    return 'Pitch';
  }, [createPitchPage, editPitchPage]);

  const hasGeneratedContent = useMemo(() => {
    return Boolean(
      pitchState.pitchContents.find(item => item.contentName === 'generated'),
    );
  }, [pitchState.pitchContents]);

  const hasOriginalContent = useMemo(() => {
    return Boolean(
      pitchState.pitchContents.find(
        item => item.contentName === 'original' && item.body,
      ),
    );
  }, [pitchState.pitchContents]);

  const hasSocialPosts = useMemo(() => {
    return pitchState.pitchContents.some(
      pitchContent => pitchContent.socialPosts,
    );
  }, [pitchState.pitchContents]);

  const pitchRunDisabled =
    (hasGeneratedContent && !pitchState.selectedPitchContent) ||
    generationLimits.outOfPitchRuns;

  const isGenerateLoading = useMemo(() => {
    return isGenerateLoadingWithID || isGenerateWithoutPitchLoading;
  }, [isGenerateLoadingWithID, isGenerateWithoutPitchLoading]);

  // Load Pitch data if Edit mode
  useEffect(() => {
    if (pitchData && editPitchPage && predictionData) {
      const { countries, states, mediaSourceFilter, contentTypes } =
        predictionData;

      dispatch({
        type: PitchStateActions.UPDATE_PITCH,
        payload: {
          id: pitchData.id,
          timingDate: moment(pitchData.publishDate),
          countries: countries && countries.length > 0 ? countries : [],
          runAgainstPodcasts: predictionData.reports.some(
            report => report.mediaList.type?.code === 'PODCAST',
          ),
          mediaSourceFilter: mediaSourceFilter ? mediaSourceFilter : undefined,
          contentTypes:
            contentTypes && contentTypes.length > 0 ? contentTypes : undefined,
          states: states && states.length > 0 ? states : undefined,
        },
      });

      dispatch({
        type: PitchStateActions.UPDATE_PITCH_CONTENTS,
        payload: [
          {
            contentName: 'original',
            headline: predictionData.headline,
            body: predictionData.body,
          },
        ],
      });
    }
  }, [pitchData, predictionData]);

  // Uncheck Podcasts flag if not USA
  useEffect(() => {
    if (
      !pitchState.pitch.countries.find(country => country.code === 'US') &&
      pitchState.pitch.runAgainstPodcasts
    ) {
      dispatch({
        type: PitchStateActions.UPDATE_PITCH,
        payload: {
          runAgainstPodcasts: false,
        },
      });
    }
  }, [pitchState.pitch.countries]);

  // Helper methods

  const updatePitchProcessingState = (
    processingState: PitchProcessStatusEnum | null,
  ) => {
    dispatch({
      type: PitchStateActions.SET_PITCH_PROCESS_STATUS,
      payload: processingState,
    });
  };

  const getSelectedPitchContent = () => {
    if (pitchState.selectedPitchContent) {
      return pitchState.pitchContents.find(
        pc => pc.contentName === pitchState.selectedPitchContent,
      );
    }
    return pitchState.pitchContents.find(pc => pc.contentName === 'original');
  };

  const getSuccessToastMessage = () => {
    if (
      generationLimits.pitchPredictionLeft < 5 &&
      generationLimits.isFreeUserPlan
    ) {
      const predictionsLeft = generationLimits.pitchPredictionLeft - 1;
      const pitchRunsPluralText = 'Pitch Runs';
      const pitchRunsSingleText = 'Pitch Run';
      return (
        <>
          <strong>
            {predictionsLeft}{' '}
            {predictionsLeft !== 1 ? pitchRunsPluralText : pitchRunsSingleText}
          </strong>{' '}
          remaining. Upgrade to our{' '}
          <a onClick={limitMessages.goToBilling}>Pro Plan</a> for more usage
        </>
      );
    }

    return <></>;
  };

  const headerNotice = useMemo(() => {
    if (
      hasGeneratedContent &&
      !pitchState.selectedPitchContent &&
      !pitchState.socialsTabOpen
    )
      return (
        <Typography.Text>
          <strong>Edit</strong> and <strong>Select</strong> which Content you’d
          like to pitch.
        </Typography.Text>
      );

    if (
      hasGeneratedContent &&
      !!pitchState.selectedPitchContent &&
      !pitchState.socialsTabOpen
    )
      return (
        <Typography.Text>
          You’ve selected to pitch the{' '}
          <Typography.Text capitalize $bold>
            {pitchState.selectedPitchContent} Content.
          </Typography.Text>
        </Typography.Text>
      );

    if (
      !hasGeneratedContent &&
      generationLimits.outOfContentGenerations &&
      !generationLimits.outOfPitchRuns &&
      !pitchState.socialsTabOpen
    )
      return (
        <Typography.Text>{limitMessages.outOfGenerations}</Typography.Text>
      );

    if (hasGeneratedContent && pitchState.socialsTabOpen)
      return (
        <Typography.Text>
          Social content <strong>will not</strong> be pitched.
        </Typography.Text>
      );

    if (
      !generationLimits.outOfContentGenerations &&
      generationLimits.outOfPitchRuns
    )
      return <Typography.Text>{limitMessages.outOfPitchRuns}</Typography.Text>;

    if (
      generationLimits.outOfContentGenerations &&
      generationLimits.outOfPitchRuns
    )
      return (
        <Typography.Text>
          {limitMessages.outOfContentGenerationsAndPitchRuns}
        </Typography.Text>
      );

    return null;
  }, [
    hasGeneratedContent,
    pitchState.selectedPitchContent,
    pitchState.socialsTabOpen,
    generationLimits,
    limitMessages,
  ]);

  // Mutations handlers

  const saveOrUpdatePitch = async () => {
    if (!params?.projectId) return;
    const valid = isPitchFormValid(
      pitchState.pitch,
      getSelectedPitchContent() || null,
    );
    if (!valid) return;

    let currentPitchId: number | null = null;

    const pitchContent = getSelectedPitchContent();

    if (!pitchContent) return;
    if (createPitchPage) {
      const newPitch = await createPitch({
        body: pitchContent.body,
        headline: pitchContent.headline,
        publishDate: pitchState.pitch.timingDate?.toISOString() || '',
        status: 'ACTIVE',
        projectId: Number(params.projectId),
      });

      if ('error' in newPitch) {
        showErrorToast('Error creating pitch');
        updatePitchProcessingState(null);
      }
      if ('data' in newPitch) {
        currentPitchId = newPitch.data.id;
      }
    } else {
      currentPitchId = Number(editPitchPage?.params.pitchId);
      const updatedPitch = await updatePitch({
        id: currentPitchId,
        pitch: {
          body: pitchContent.body,
          headline: pitchContent.headline,
          publishDate: pitchState.pitch.timingDate?.toISOString() || '',
          status: 'ACTIVE',
          projectId: Number(params.projectId),
        },
      });

      if ('error' in updatedPitch) {
        showErrorToast('Error updating pitch');
        updatePitchProcessingState(null);
        return;
      }
    }

    return currentPitchId;
  };

  const runPredictionWithoutEvaluation = async () => {
    const pitchId = await saveOrUpdatePitch();

    if (!pitchId) return;

    const successToastMessage = getSuccessToastMessage();

    updatePitchProcessingState(PitchProcessStatusEnum.predictionRunning);

    const predictionResponse = await runNotEvaluatedPitchPrediction({
      pitchId: pitchId,
      customMediaListIds: pitchState.selectedBrandMediaList.map(
        item => item.id,
      ),
      runAgainstPodcastSelected: pitchState.pitch.runAgainstPodcasts,
      countries:
        pitchState.pitch.countries && pitchState.pitch.countries.length > 0
          ? pitchState.pitch.countries.map(country => country.code)
          : ['US'],
      states:
        pitchState.pitch.states && pitchState.pitch.states.length > 0
          ? pitchState.pitch.states.map(state => state.code)
          : [],
      contentTypes:
        pitchState.pitch.contentTypes &&
        pitchState.pitch.contentTypes.length > 0
          ? pitchState.pitch.contentTypes.map(contentType => contentType.code)
          : undefined,
      currentVersionNumber: pitchData?.latestPredictionVersion || 0,
    });

    if ('data' in predictionResponse) {
      updatePitchProcessingState(PitchProcessStatusEnum.complete);
      dispatch({
        type: PitchStateActions.SET_PREDICTION,
        payload: predictionResponse.data,
      });

      const hasResults =
        predictionResponse.data.reports &&
        predictionResponse.data.reports.some(
          reports => reports.reportItemsPage?.content.length > 0,
        );

      const hasEmptyResults =
        predictionResponse.data.reports &&
        predictionResponse.data.reports.find(
          report => report.reportItemsPage?.content.length < 1,
        );

      if (!hasResults) {
        showErrorToast(
          'Consider removing some of the filters to see more results.',
          'No Results',
        );
      } else {
        if (hasEmptyResults) {
          showSuccessToast(
            'Consider removing some of the filters to see more results.',
            'Results Generated',
          );
        } else {
          showSuccessToast(successToastMessage, 'Results Generated');
        }
      }

      if (params)
        history.push(
          RouteConstants.pitch.makePitchUrl(
            Number(params.tenantId),
            Number(params.projectId),
            predictionResponse.data.pitch.id,
            predictionResponse.data.version,
          ),
        );
    }

    if ('error' in predictionResponse) {
      showErrorToast('Something went wrong.');
      updatePitchProcessingState(null);
    }
  };

  const onGenerateClick = async () => {
    if (generationLimits.contentSuggestionsLeft === 0) {
      limitExceededPopup.show();
      return;
    }

    const isValid = validateContentForGenerate(
      getSelectedPitchContent() || null,
    );

    if (!isValid) return;

    const loader = createPermanentToast(GENERATE_LOADING_TOOLTIP_CONTENT);
    try {
      const pitchContent = getSelectedPitchContent();
      const pitchId = pitchData && pitchData.id;

      if (!pitchContent) return loader.close();

      let generatedContent;

      if (!pitchData || !pitchId) {
        generatedContent = await getGeneratedContentWithoutPitch({
          tone: pitchState.tone.value,
          title: pitchContent.headline,
          bodyCopy: pitchContent.body,
        }).unwrap();
      } else {
        generatedContent = await getGeneratedContent({
          pitchId: pitchData.id,
          body: {
            tone: pitchState.tone.value,
            title: pitchContent.headline,
            bodyCopy: pitchContent.body,
          },
        }).unwrap();
      }

      const {
        generatedTitle,
        generatedSubTitle,
        articleByline,
        ...socialPosts
      } = generatedContent;

      dispatch({
        type: PitchStateActions.UPDATE_PITCH_CONTENT,
        payload: {
          contentType: 'generated',
          data: {
            contentName: 'generated',
            headline: generatedTitle,
            body: articleByline,
            socialPosts,
          },
        },
      });

      showCustomToast(
        <GenerateSuccessToastContent
          limit={generationLimits.contentSuggestionsLeft - 1}
        />,
        'toast-violet',
      );
      generationLimits.refresh();
    } catch (e) {
      showErrorToast(getErrorText(e as FetchBaseQueryError));
    } finally {
      loader.close();
    }
  };

  return {
    pitchData,
    predictionData,
    isPitchFetching: isPitchDataFetching,
    isPredictionFetching: isPredictionDataFetching,
    pageParameters: params,
    pageTitle,
    handlePrediction: runPredictionWithoutEvaluation,
    handleSaveOrUpdate: saveOrUpdatePitch,
    handleGenerateContent: onGenerateClick,
    generationLimits,
    limitExceededPopup,
    isGenerateLoading,
    hasOriginalContent,
    hasGeneratedContent,
    hasSocialPosts,
    pitchRunDisabled,
    headerNotice,
  };
};
