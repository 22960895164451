import { PITCH_BODY_MIN_WORD_COUNT } from 'utils/constants';
import { getWordCount } from 'utils/helpers';
import { showErrorToast } from 'utils/toast';
import { IPitchContent } from '../CreateOrEditPitch/CreateOrEditPitch.types';
import { IGeneratedContentResponse } from 'app/api.types';

export const isContentFormValid = (
  pitchContent: IPitchContent | null,
): boolean => {
  const errors: string[] = [];

  if (!pitchContent) {
    showErrorToast('Content not found', 'Configuration error');
    return false;
  }

  const wordCount = getWordCount(pitchContent.body);

  if (wordCount < PITCH_BODY_MIN_WORD_COUNT) {
    errors.push('Pitch content too short. Please type at least 30 words');
  }

  if (!pitchContent.headline) {
    errors.push('Please add headline');
  }
  if (errors.length) {
    showErrorToast(
      `
          <ul style="list-style: none;">
              ${errors.map(error => `<li>${error}</li>`).join('')}
          </ul>
        `,
      'Pitch cannot be created',
      {
        autoClose: 1e4,
      },
    );
    return false;
  }
  return true;
};

export const getOriginalContentAsObject = (content: string) => {
  return {
    generatedSubTitle: '',
    generatedTitle: '',
    twitterPost: '',
    facebookPost: '',
    instagramPost: '',
    linkedinPost: '',
    articleByline: content,
  } as IGeneratedContentResponse;
};
