import styled, { css } from 'styled-components/macro';

// TODO: Add full flex support

interface IFlexBlockProps {
  flexDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';
  alignItems?: string;
  margin?: string;
  padding?: string;
  flex?: number;
  columnGap?: string;
  rowGap?: string;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  maxWidth?: string;
  minWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  gridColumnStart?: string | number;
  gridColumnEnd?: string | number;
  cursorPointer?: boolean;
  transition?: boolean;
  disabled?: boolean;
  disablePointerEvents?: boolean;
  borderLeft?: boolean;
  overflowYAuto?: boolean;
  blur?: number;
  hover?: boolean;
}

const FlexBlock = styled.div<IFlexBlockProps>(
  ({
    flexDirection = 'row',
    justifyContent = 'flex-start',
    alignItems = 'flex-start',
    margin = '0',
    padding = '0',
    flex,
    columnGap = '0px',
    rowGap = '0px',
    flexWrap,
    maxWidth = 'auto',
    minWidth = 'auto',
    minHeight = 'auto',
    maxHeight = 'auto',
    gridColumnStart,
    gridColumnEnd,
    cursorPointer,
    transition,
    disabled,
    disablePointerEvents,
    borderLeft,
    theme,
    overflowYAuto,
    blur,
    hover,
  }) => css`
    display: flex;
    justify-content: ${justifyContent};
    flex-direction: ${flexDirection};
    align-items: ${alignItems};
    margin: ${margin};
    padding: ${padding};
    column-gap: ${columnGap};
    row-gap: ${rowGap};
    max-width: ${maxWidth};
    min-width: ${minWidth};
    min-height: ${minHeight};
    max-height: ${maxHeight};
    ${flex
      ? css`
          flex: ${flex};
        `
      : ''}

    ${flexWrap
      ? css`
          flex-wrap: ${flexWrap};
        `
      : ``}

    ${cursorPointer
      ? css`
          cursor: pointer;
        `
      : ``}
    
    ${gridColumnStart !== undefined
      ? css`
          grid-column-start: ${gridColumnStart};
        `
      : ''}
    
    ${gridColumnEnd !== undefined
      ? css`
          grid-column-end: ${gridColumnEnd};
        `
      : ''}
    
    ${transition
      ? css`
          transition: 0.3s all ease-in-out;
        `
      : ''}
    
    ${disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : ''}
    
    ${disablePointerEvents
      ? css`
          pointer-events: none;
        `
      : ''}


    ${borderLeft
      ? css`
          border-left: 1px solid ${theme.colors.smoke};
        `
      : ''}
    
    ${overflowYAuto
      ? css`
          overflow-y: auto;
        `
      : ''}


    ${blur
      ? css`
          filter: blur(${blur}px);
        `
      : ''}

      &:hover {
      background-color: ${() => (hover ? theme.colors.lightBlue : '')};
    }
  `,
);

export default FlexBlock;
