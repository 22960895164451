import { SVGProps } from 'react';

const GenderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Pitch" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SXSW-2.0.0.4" transform="translate(-1408.000000, -552.000000)">
        <g id="Panel" transform="translate(1368.000000, -338.000000)">
          <g id="demo" transform="translate(0.000000, 726.000000)">
            <g id="metric" transform="translate(24.000000, 144.000000)">
              <g
                id="gender-neuter-fill"
                transform="translate(16.000000, 20.000000)"
              >
                <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                <path
                  d="M20.98,13.54375 C21.1718055,15.6406094 20.029402,17.6329261 18.1228303,18.5265664 C16.2162585,19.4202067 13.9541476,19.0236398 12.4652539,17.5347461 C10.9763602,16.0458524 10.5797933,13.7837415 11.4734336,11.8771697 C12.3670739,9.97059802 14.3593906,8.82819452 16.45625,9.02 C18.8577827,9.23967478 20.7603252,11.1422173 20.98,13.54375 L20.98,13.54375 Z M27,5 L27,27 C27,28.1045695 26.1045695,29 25,29 L7,29 C5.8954305,29 5,28.1045695 5,27 L5,5 C5,3.8954305 5.8954305,3 7,3 L25,3 C26.1045695,3 27,3.8954305 27,5 Z M23,14 C23.0003753,10.2639008 20.0666417,7.18601102 16.3348187,7.00730876 C12.6029957,6.82860649 9.3885843,9.61208587 9.03196246,13.3311259 C8.67534062,17.0501658 11.3022207,20.3937715 15,20.9275 L15,24.96625 C14.9934039,25.5013942 15.4005814,25.9510882 15.93375,25.9975 C16.2102462,26.0158589 16.4819642,25.9186639 16.6840858,25.729101 C16.8862073,25.539538 17.0006088,25.2746044 17,24.9975 L17,20.9275 C20.4412692,20.4259165 22.9948215,17.4776275 23,14 Z"
                  id="Shape"
                  fill="#3F3F42"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GenderIcon;
