import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
  ClipboardEvent,
  KeyboardEvent,
} from 'react';
import styled from 'styled-components';
import { StyledInput } from './Input/Input.styles';

interface IProps {
  onCodeEntered: (text: string) => void;
}

interface ISingleInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  isFocused: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const Styles = {
  Outer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    margin-bottom: 24px;
  `,
  Input: styled(StyledInput)`
    text-align: center;
    font-size: 32px;
    font-family: ${({ theme }) => theme.fonts.dmSans};
  `,
};

const CodeInput: FC<IProps> = props => {
  const [inputValues, setInputValues] = useState<string[]>(Array(6).fill(''));
  const [focusedInputId, setFocusedInputId] = useState<number>(0);

  const onInputChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInputValues(i => i.map((v, i) => (i === index ? value : v)));
      if (value) {
        setFocusedInputId(index + 1);
      } else {
        setFocusedInputId(index - 1);
      }
    };

  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Backspace' && !inputValues[focusedInputId]) {
      setFocusedInputId(i => i - 1);
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pasteValue = event.clipboardData?.getData('text');
    if (
      pasteValue &&
      typeof pasteValue === 'string' &&
      pasteValue.length === 6
    ) {
      setInputValues(pasteValue.split(''));
      setFocusedInputId(5);
    }
  };

  const codeOutput = useMemo(() => {
    return inputValues.join('');
  }, [inputValues]);

  useEffect(() => {
    if (codeOutput && codeOutput.length === 6) {
      props.onCodeEntered(codeOutput);
    }
  }, [codeOutput, props]);

  return (
    <Styles.Outer onKeyDown={onKeyDown}>
      {inputValues.map((inputValue, index) => (
        <SingleInput
          key={index}
          value={inputValue}
          isFocused={focusedInputId === index}
          onChange={onInputChange(index)}
          onFocus={() => setFocusedInputId(index)}
          maxLength={1}
          onPaste={handlePaste}
        />
      ))}
    </Styles.Outer>
  );
};

const SingleInput: FC<ISingleInputProps> = ({ value, isFocused, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus();
    }
  }, [isFocused]);

  return <Styles.Input value={value} ref={ref} autoComplete="none" {...rest} />;
};

export default CodeInput;
