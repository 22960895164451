import { FC } from 'react';
import { Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import ProtectedRoute from '../Global/permissions/ProtectedRoute';
import { userPermissionEnum } from '../Global/permissions/userPermissions.enum';

import BioPage from './pages/BioPage/BioPage';
import CreateOrEditBioPage from './pages/CreateOrEditBio/CreateOrEditBioPage';

interface IProps {}

const Bio: FC<IProps> = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={RouteConstants.bio.bio}
        component={BioPage}
        requirePermission={userPermissionEnum.READ_BIO}
      />
      <ProtectedRoute
        path={RouteConstants.bio.createBio}
        component={CreateOrEditBioPage}
        requirePermission={userPermissionEnum.CREATE_BIO}
      />
      <ProtectedRoute
        path={RouteConstants.bio.editBio}
        component={CreateOrEditBioPage}
        requirePermission={userPermissionEnum.MANAGE_BIO}
      />
      <ProtectedRoute
        path={RouteConstants.bio.draftBio}
        component={BioPage}
        requirePermission={userPermissionEnum.MANAGE_BIO}
      />
    </Switch>
  );
};

export default Bio;
