import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useMoveMediaListMembersMutation } from 'app/api';
import { ICustomTargetMediaBase } from 'app/api.types';
import AutoComplete from 'app/components/AutoComplete/AutoComplete';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Modal from 'app/components/UI/Modal/Modal';

import { useJournalistsSlice } from 'app/containers/Journalists/slice';
import { selectJournalistsToMove } from 'app/containers/Journalists/slice/selectors';
import { FC, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getErrorText } from 'utils/error-messages';
import useDebounce from 'utils/hooks/useDebounce';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { TableView } from '../../../Tabs/JournalistTabs';
import { useMediaListData } from 'app/containers/Journalists/pages/hooks/useJournalistData';
import * as S from 'app/components/AutoComplete/AutoComplete.styles';

const MoveMediaListEntries: FC = () => {
  const dispatch = useAppDispatch();
  const { actions } = useJournalistsSlice();
  const { modalOpen, ids, sourceObject } = useAppSelector(
    selectJournalistsToMove,
  );

  const [searchMediaListName, setSearchMediaListName] = useState('');

  const [selectedMediaListDestination, setSelectedMediaListDestination] =
    useState<ICustomTargetMediaBase | null>(sourceObject);

  const debouncedMediaListName = useDebounce(searchMediaListName, 500);

  const pagination = useMemo(() => {
    return {
      page: {
        page: 0,
        size: 50,
      },
      sort: [{ property: 'name', ascending: true }],
    };
  }, []);

  const displayName = ids && ids.length > 1 ? 'Journalists' : 'Journalist';

  const [moveMediaListEntries, { isLoading: isMoving }] =
    useMoveMediaListMembersMutation();

  const mediaLists = useMediaListData({
    pagination,
    currentTab: modalOpen ? TableView.MediaList : TableView.AllJournalists,
    debouncedSearchTerm: debouncedMediaListName,
  });

  const handleModalClose = () => {
    dispatch(actions.toggleMoveMediaListEntriesModal(false));
    setSearchMediaListName('');
    setSelectedMediaListDestination(null);
  };

  const handleMoveMediaListEntries = async () => {
    try {
      if (!selectedMediaListDestination) {
        showErrorToast('Select a media list to move the entries to.');
        return;
      }

      if (!sourceObject) {
        showErrorToast('Could not find the source media list.');
        return;
      }

      await moveMediaListEntries({
        mediaListId: sourceObject.id,
        targetMediaListId: selectedMediaListDestination.id,
        memberIds: ids,
      }).unwrap();

      showSuccessToast(
        `${displayName} has been moved into the selected Media List.`,
      );
      handleModalClose();
    } catch (error) {
      showErrorToast(getErrorText(error as FetchBaseQueryError));
    }
  };

  const isLoading = mediaLists.isLoading || mediaLists.isFetching;

  return (
    <Modal open={modalOpen} $maxWidth="512px" overflowVisible>
      <FlexBlock
        padding="40px 40px 8px 40px"
        rowGap="8px"
        flexDirection="column"
        alignItems="center"
        maxWidth="500px"
        margin="auto"
      >
        <Typography.Text $dmSans $bold $size={24}>
          Move {displayName}
        </Typography.Text>
        <Typography.Text style={{ textAlign: 'center' }}>
          Select a Media List. Until the search box is populated, the first 50
          Media Lists will appear alphabetically as options below.
        </Typography.Text>
      </FlexBlock>

      <FlexBlock justifyContent="center" alignItems="center" margin="auto">
        <FlexBlock padding="24px" minWidth="350px">
          <AutoComplete
            options={mediaLists.data?.content || []}
            displayAccessor={'name'}
            onOptionSelect={() => {}}
            searchValue={searchMediaListName}
            onSearchValueChange={value => setSearchMediaListName(value)}
            icon="right-carrot"
            loading={isLoading}
            noOptionsText="No Media Lists Found"
            placeholder={
              selectedMediaListDestination?.name ||
              sourceObject?.name ||
              'Select Media List'
            }
            optionRenderFunction={option => (
              <S.Option
                key={`${option.id}-${option.name}`}
                onClick={() => {
                  setSelectedMediaListDestination(option);
                  setSearchMediaListName('');
                }}
              >
                {option.name}
              </S.Option>
            )}
          />
        </FlexBlock>
      </FlexBlock>

      <FlexBlock
        padding="24px 40px 40px"
        justifyContent="center"
        columnGap="12px"
      >
        <Button variant="secondary" compact onClick={handleModalClose}>
          Cancel
        </Button>
        <Button
          compact
          onClick={handleMoveMediaListEntries}
          isLoading={isMoving}
          disabled={
            !selectedMediaListDestination ||
            selectedMediaListDestination?.id === sourceObject?.id ||
            isMoving
          }
        >
          Move
        </Button>
      </FlexBlock>
    </Modal>
  );
};

export default MoveMediaListEntries;
