import { FC, memo } from 'react';

import * as S from './ContentLayout.styles';
import Typography from 'app/components/Typography';

const ContentFooter: FC = () => {
  return (
    <S.ContentFooter>
      <Typography.Text $colorName="aluminum" $size={14} $lineHeight={32}>
        © {new Date().getFullYear()} Ahteed LLC.
      </Typography.Text>
      <Typography.Text $colorName="aluminum" $size={14} $lineHeight={32}>
        <S.ContentFooterLink
          href="https://www.prprophet.ai/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy / Terms of Use / Cookies Policy
        </S.ContentFooterLink>
      </Typography.Text>
    </S.ContentFooter>
  );
};

const MemoizedContentFooter = memo(ContentFooter);

const ContentLayout: FC = ({ children }) => {
  return <S.Content>{children}</S.Content>;
};

const ContentHeader: FC<{
  autoHeight?: boolean;
  padding?: string;
  contentLayoutMaxWidth?: boolean;
}> = ({ children, autoHeight, padding, contentLayoutMaxWidth }) => {
  return (
    <S.ContentHeaderWrapper autoHeight={autoHeight} padding={padding}>
      <S.ContentHeaderContainer contentLayoutMaxWidth={contentLayoutMaxWidth}>
        {children}
      </S.ContentHeaderContainer>
    </S.ContentHeaderWrapper>
  );
};

export { ContentLayout, ContentHeader, MemoizedContentFooter as ContentFooter };
