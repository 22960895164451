import WandIcon from 'app/assets/icons/WandIcon';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import InfoTip from 'app/components/UI/InfoTip/InfoTip';
import { WandAvatar } from 'app/containers/Bio/pages/BioPage/BioPage.styles';
import { TAYLOR_SOCIAL_POSTS_TOOLTIP } from 'app/containers/SocialPosts/pages/CreateOrEditSocialPosts/CreateOrEditSocialPosts.constants';
import React from 'react';
import { FC } from 'react';

interface ISocialPostTaylorHeaderSectionProps {}

const SocialPostTaylorHeaderSection: FC<ISocialPostTaylorHeaderSectionProps> =
  () => {
    return (
      <>
        <WandAvatar $size="56px">
          <WandIcon />
        </WandAvatar>
        <FlexBlock flexDirection="column">
          <FlexBlock flexDirection="row" alignItems="center">
            <Typography.Text
              $colorName="orbit"
              $size={16}
              $lineHeight={20}
              $dmSans
              $bold
              $padding="2px 0"
            >
              Taylor
            </Typography.Text>
            <InfoTip
              tooltipPosition="right"
              tooltipText={TAYLOR_SOCIAL_POSTS_TOOLTIP}
              padding="0 0 0 4px"
            />
          </FlexBlock>
          <Typography.Text $colorName="steel" $size={14} $lineHeight={16}>
            Generate Social Posts
          </Typography.Text>
        </FlexBlock>
      </>
    );
  };

export default React.memo(SocialPostTaylorHeaderSection);
