import { FC } from 'react';
import styled from 'styled-components/macro';
import ProphetLogoIcon from '../../assets/icons/ProphetLogo';
import { Spinner } from './Spinner';

interface IProps {}

const SplashWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #040329;
  flex-direction: column;
  padding: 56px;
`;

const Splash: FC<IProps> = () => {
  return (
    <SplashWrapper>
      <Spinner $size="40px" />
      <ProphetLogoIcon />
    </SplashWrapper>
  );
};

export default Splash;
