import { FC, Fragment, useMemo } from 'react';
import SideDrawer from '../../../../../../components/UI/SideDrawer/SideDrawer';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../store/hooks';
import { selectVersionDrawerOpen } from '../../../../slice/selectors';
import { usePitchSlice } from '../../../../slice';
import { useParams } from 'react-router-dom';
import {
  useGetPitchPredictionVersionsQuery,
  useGetPitchQuery,
  useGetPressReleaseQuery,
} from '../../../../../../api';
import PageLoader from '../../../../../../components/UI/PageLoader';
import FlexBlock from '../../../../../../components/UI/FlexBlock';
import Typography from '../../../../../../components/Typography';
import Button from '../../../../../../components/UI/Button/Button';
import RemoveIcon from '../../../../../../assets/icons/RemoveIcon';
import { Divider } from '../../../../../../components/UI/Divider';
import { PitchVersionPill } from '../../../../../Projects/pages/PItchList/PitchList.styles';
import { formatDate } from '../../../../../../../utils/helpers';
import { VerticalDivider } from '../../../../../../components/UI/Divider';
import ContextMenu, {
  ContextMenuOption,
} from '../../../../../../components/UI/ContextMenu/ContextMenu';
import { NavLink } from 'react-router-dom';
import { RouteConstants } from '../../../../../../routes';

interface IProps {}

const VersionDrawer: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectVersionDrawerOpen);
  const { actions: pitchActions } = usePitchSlice();

  const handleClose = () => {
    dispatch(pitchActions.toggleVersionDrawer(false));
  };

  const { pitchId, pressReleaseId, versionId, tenantId, projectId } =
    useParams<{
      pitchId: string;
      pressReleaseId: string;
      versionId: string;
      tenantId: string;
      projectId: string;
    }>();

  const { data: pitchData } = useGetPitchQuery(
    { pitchId: Number(pitchId) },
    { skip: !isOpen || !pitchId },
  );

  const { data: pressReleaseData } = useGetPressReleaseQuery(
    { id: Number(pressReleaseId) },
    { skip: !isOpen || !pressReleaseId },
  );

  const predictedContentTitle = useMemo(() => {
    if (pressReleaseId) {
      return pressReleaseData?.title;
    }

    if (pitchId) {
      return pitchData?.headline;
    }

    return '';
  }, [pitchData, pressReleaseData, pitchId, pressReleaseId]);

  const contentDisplayName = useMemo(() => {
    if (pressReleaseId) return 'Press Releases';
    return 'Pitches';
  }, [pressReleaseId]);

  const contentId = useMemo(() => {
    if (pressReleaseId) return pressReleaseId;
    return pitchId;
  }, [pressReleaseId, pitchId]);

  const contentType = useMemo(() => {
    if (pressReleaseId) return 'PRESS_RELEASE';
    return 'PITCH';
  }, [pressReleaseId]);

  const { data: predictionData, isFetching } =
    useGetPitchPredictionVersionsQuery(
      { pitchId: contentId, contentType: contentType },
      { skip: !isOpen || !contentId },
    );

  const createPredictionUrl = (version: number) => {
    if (pressReleaseId) {
      return RouteConstants.pressRelease.makePressReleaseUrl(
        Number(tenantId),
        Number(projectId),
        Number(contentId),
        version,
      );
    } else {
      return RouteConstants.pitch.makePitchUrl(
        Number(tenantId),
        Number(projectId),
        Number(contentId),
        version,
      );
    }
  };

  const exportPdf = (version: number) => () => {
    dispatch(
      pitchActions.setPdfPrint({
        pitchId: Number(pitchId),
        versionId: version,
        contentType: pressReleaseId ? 'PRESS_RELEASE' : 'PITCH',
      }),
    );
  };

  return (
    <SideDrawer open={isOpen}>
      {isFetching ? (
        <PageLoader />
      ) : (
        <>
          <FlexBlock padding="24px">
            <FlexBlock flexDirection="column" rowGap="4px" flex={1}>
              <Typography.Text $dmSans $bold $size={20}>
                Versions
              </Typography.Text>
              <Typography.Text $colorName="steel">
                See all Past, Draft and Current {contentDisplayName} for{' '}
                <Typography.Text>{predictedContentTitle}</Typography.Text>
              </Typography.Text>
            </FlexBlock>
            <FlexBlock>
              <Button
                variant="icon-button-border"
                hoverMode="black"
                onClick={handleClose}
              >
                <RemoveIcon />
              </Button>
            </FlexBlock>
          </FlexBlock>

          {predictionData?.content.map((prediction, index, arr) => {
            const isActive = Boolean(Number(versionId) === prediction.version);

            return (
              <Fragment key={prediction.version}>
                <FlexBlock padding="24px" columnGap="16px" alignItems="center">
                  <PitchVersionPill
                    status={isActive ? 'ACTIVE' : 'ARCHIVED'}
                    borderRadius="6px"
                  >
                    V{prediction.version}
                  </PitchVersionPill>
                  <FlexBlock flexDirection="column" flex={1}>
                    <Typography.Text>
                      By: {prediction.creator?.firstName || ''}{' '}
                      {prediction.creator?.lastName || ''}{' '}
                      {isActive ? (
                        <Typography.Text $colorName="kermit">
                          (Active)
                        </Typography.Text>
                      ) : null}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDate(prediction.createdAt)}
                    </Typography.Text>
                  </FlexBlock>
                  <FlexBlock columnGap="8px" alignItems="stretch">
                    <NavLink to={createPredictionUrl(prediction.version)}>
                      <Button variant="anchor" compact>
                        Review
                      </Button>
                    </NavLink>
                    <VerticalDivider />
                    <ContextMenu
                      buttonText={'Export'}
                      buttonProps={{ variant: 'anchor', compact: true }}
                      menuOptions={{ align: 'right' }}
                    >
                      <ContextMenuOption
                        onClick={exportPdf(prediction.version)}
                      >
                        PDF
                      </ContextMenuOption>
                    </ContextMenu>
                  </FlexBlock>
                </FlexBlock>
                {Boolean(index + 1 !== arr.length) && <Divider />}
              </Fragment>
            );
          })}
        </>
      )}
    </SideDrawer>
  );
};

export default VersionDrawer;
