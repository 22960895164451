import { FC, useMemo } from 'react';
import Modal from 'app/components/UI/Modal/Modal';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';
import { getPitchAnimation } from './PItchProgressModal.helpers';

interface IProps {
  isOpen: boolean;
  regenerating?: boolean;
  contentDisplayName?: string;
}

const PitchModal: FC<IProps> = ({ isOpen, contentDisplayName }) => {
  const animationImage = useMemo(() => {
    return getPitchAnimation(isOpen);
  }, [isOpen]);

  return (
    <Modal open={Boolean(isOpen)}>
      <FlexBlock padding="24px" justifyContent="center">
        <img src={animationImage} alt="Pitching..." width={158} height={158} />
      </FlexBlock>

      <FlexBlock
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="0 40px 24px"
        rowGap="8px"
      >
        <Typography.Text $dmSans $bold $size={24}>
          Pitching...
        </Typography.Text>
        <Typography.Text maxWidth="425px" textAlign="center">
          We're testing your {contentDisplayName || 'pitch'}, sit tight!
        </Typography.Text>
      </FlexBlock>
    </Modal>
  );
};

export default PitchModal;
