import styled, { css } from 'styled-components';

interface IPodcastDetailsContainerProps {
  alignItems?: boolean;
}

export const DetailsContainer = styled.div<IPodcastDetailsContainerProps>(
  ({ alignItems = false }) => css`
    min-height: 76px;
    box-sizing: border-box;
    border: 1px solid #e4e4e9;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 16.5px 20px;
    margin-top: 8px;
    ${!alignItems && 'align-items: center;'};
  `,
);

export const PodcastInformationWrapper = styled.div`
  width: 115%;
  margin-left: -33px;
  margin-bottom: 20px;
`;
