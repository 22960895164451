import { useState, useEffect, MutableRefObject, useRef } from 'react';

import { useEventListener } from './useEventListener';

export function useHover<T extends HTMLElement = HTMLElement>(
  forwardedRef: React.Ref<T>,
): boolean {
  const [value, setValue] = useState<boolean>(false);

  const elementRef: MutableRefObject<T | null> = useRef<T | null>(null);

  useEffect(() => {
    if (typeof forwardedRef === 'function') {
      forwardedRef(elementRef.current);
    } else if (
      forwardedRef &&
      typeof forwardedRef === 'object' &&
      'current' in forwardedRef
    ) {
      elementRef.current = forwardedRef.current;
    }
  }, [forwardedRef]);

  const handleMouseEnter = () => setValue(true);
  const handleMouseLeave = () => setValue(false);

  useEventListener('mouseenter', handleMouseEnter, elementRef);
  useEventListener('mouseleave', handleMouseLeave, elementRef);

  // If no ref is provided, return true as the element is always hovered
  return forwardedRef === null ? true : value;
}
