import { TooltipProps } from 'react-tooltip';
import { IFilterOptions } from './parts/Filters/FiltersPanel';
import { IAuthorSocial } from 'app/api.types';

export const mapFilterOptionsToPayload = (filterOptions: IFilterOptions) => {
  const preparedFilterOptions = Object.entries(filterOptions).reduce(
    (acc, [key, value]) => {
      if (value === null || value === '') {
        acc[key as keyof IFilterOptions] = undefined;
      } else {
        acc[key as keyof IFilterOptions] = value;
      }
      return acc;
    },
    {} as Partial<IFilterOptions>,
  );

  return {
    ...preparedFilterOptions,
    countries:
      filterOptions.countries.length > 0
        ? filterOptions.countries.map(country => country.code)
        : undefined,
    states:
      filterOptions.states.length > 0
        ? filterOptions.states.map(state => state.code)
        : undefined,
    outletScopeFilter:
      filterOptions.outletScopeFilter &&
      filterOptions.outletScopeFilter.inclusions &&
      filterOptions.outletScopeFilter.inclusions.length > 0
        ? {
            inclusions: filterOptions.outletScopeFilter.inclusions.map(
              inclusion => inclusion.code,
            ),
            exclusions: [],
          }
        : undefined,
  };
};

export const getFavoriteJournalistTooltipProps = (
  tooltipText: string,
  isDanger = false,
) =>
  ({
    'data-tip': tooltipText,
    'data-class': 'orbit-tooltip',
    'data-for': 'favoriteJournalistTooltip',
    'data-place': 'bottom',
    'data-effect': 'solid',
    'data-background-color': !isDanger ? '#ebf2fb' : 'rgba(252, 227, 210, 1)',
    'data-padding': '16px',
  } as TooltipProps);

export const getJournalistsEmptyNotice = (
  isFavoritesTab: boolean | null | undefined,
) => {
  return {
    title: isFavoritesTab ? 'No favorites Yet' : 'No matches for your search!',
    description: isFavoritesTab
      ? 'Journalists that are indicated as favorites on All Journalists tab will show up in this section'
      : 'Use the Searchbar and filters to start your search!',
    buttonText: 'Add Journalist',
  };
};

export const journalistSocialFieldsFilter = (social: IAuthorSocial) => {
  return social.socialType !== 'PHONE_NUMBER';
};

export const scrollToPageTop = () => {
  const node = document.getElementById('journalist-database-page-container');
  if (node) {
    node.scrollTo({ top: 0, behavior: 'smooth' });
  }
};
