import { FC } from 'react';
import { Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import PitchPage from './pages/PitchPage/PitchPage';
import CreateOrEditPitchPage from './pages/CreateOrEditPitch/CreateOrEditPitchPage';
import { usePitchSlice } from './slice';
import ProtectedRoute from '../Global/permissions/ProtectedRoute';
import { userPermissionEnum } from '../Global/permissions/userPermissions.enum';
import CreateFirstPitch from './pages/CreateFirstPitch/CreateFirstPitch';

interface IProps {}

const Pitch: FC<IProps> = () => {
  usePitchSlice();

  return (
    <Switch>
      <ProtectedRoute
        path={RouteConstants.pitch.pitch}
        component={PitchPage}
        requirePermission={userPermissionEnum.READ_PITCH}
      />
      <ProtectedRoute
        path={RouteConstants.pitch.createPitch}
        component={CreateOrEditPitchPage}
        requirePermission={userPermissionEnum.CREATE_PITCH}
      />
      <ProtectedRoute
        path={RouteConstants.pitch.createFirstPitch}
        component={CreateFirstPitch}
        requirePermission={userPermissionEnum.CREATE_PITCH}
      />
      <ProtectedRoute
        path={RouteConstants.pitch.editPitch}
        component={CreateOrEditPitchPage}
        requirePermission={userPermissionEnum.MANAGE_PITCH}
      />
    </Switch>
  );
};

export default Pitch;
