import { FC, useMemo } from 'react';
import Modal from 'app/components/UI/Modal/Modal';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import ClickAwayListener from 'react-click-away-listener';
import {
  Header,
  SectionFlexEnd,
} from 'app/containers/Projects/pages/ProjectsList/parts/AddNewProjectModal.styles';
import {
  Section,
  UserModalContainer,
  UserModalLoadingOverlay,
} from './UserModal.styles';
import { CloseIcon } from 'app/components/UI/CloseIcon';
import Button from 'app/components/UI/Button/Button';

import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import TenantSelector from '../TenantModal/TenantSelector';
import { useUserData } from '../hooks/useUserData';
import RolesSelector from './RolesSelector';
import Gap from 'app/components/UI/Gap';
import UserFieldInput from './UserFieldInput';
import UserAvatar from './UserAvatar';
import UserStatusToggle from './UserStatusToggle';

import { Spinner } from 'app/components/UI/Spinner';
import UserLoggedInDate from './UserLoggedInDate';

interface IProps {
  open: boolean | number;
  onClose: () => void;
}

const UserModal: FC<IProps> = props => {
  const isEditMode = useMemo(() => Number.isInteger(props.open), [props.open]);

  const hasPlatformManagementPermission = useHasPermission(
    userPermissionEnum.SYSTEM_MANAGEMENT,
  );

  const { user, validation, avatar } = useUserData({
    userId: props.open,
    onClose: props.onClose,
  });

  const renderUserStatusToggle = useMemo(() => {
    return <UserStatusToggle persistedUser={user.persistedUser} />;
  }, [user.persistedUser]);

  return (
    <Modal open={Boolean(props.open)} $minWidth="810px" overflowVisible={false}>
      <ClickAwayListener onClickAway={() => props.onClose()}>
        <UserModalContainer flexDirection="column" alignItems="center">
          {user.isLoadingUserData && (
            <UserModalLoadingOverlay>
              <Spinner $size="45px" $isBlue />
            </UserModalLoadingOverlay>
          )}

          <Header>
            <FlexBlock
              flexDirection="row"
              minWidth="95%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography.Text
                $colorName="onyx"
                $size={18}
                $lineHeight={32}
                $dmSans
                $bold
              >
                {isEditMode ? 'Edit' : 'Add'} User
              </Typography.Text>
              {isEditMode && (
                <UserLoggedInDate
                  lastLoggedInDate={user.persistedUser?.lastLoginTime}
                />
              )}
            </FlexBlock>
            <FlexBlock
              style={{ alignSelf: 'flex-start', paddingTop: '16px' }}
              columnGap="5px"
            >
              <CloseIcon size={16} onClick={() => props.onClose()} />
            </FlexBlock>
          </Header>
          <FlexBlock flexDirection="column" minWidth="100%" padding="16px">
            <UserAvatar
              image={avatar.image}
              handleFileSelect={avatar.handleFileSelect}
              handleImageFileLoad={avatar.handleImageFileLoad}
              renderRightSection={renderUserStatusToggle}
            />
            <Section flexDirection="column">
              <FlexBlock
                flexDirection="row"
                justifyContent="left"
                minWidth="100%"
              >
                <Typography.Text
                  $colorName="onyx"
                  $dmSans
                  $bold
                  $size={16}
                  $lineHeight={24}
                >
                  Basic Information
                </Typography.Text>
              </FlexBlock>

              <FlexBlock
                flexDirection="row"
                justifyContent="space-around"
                columnGap="10px"
                minWidth="100%"
              >
                <FlexBlock flexDirection="column" maxWidth="350px">
                  <UserFieldInput
                    placeholder="Enter first name"
                    fieldKey="firstName"
                    inputTitle="First Name"
                    onValueChange={user.onUserValueChange}
                    resetValidation={validation.resetFieldValidation}
                    validateField={validation.validateUserField}
                    value={user.userFields.firstName}
                    validationMessage={validation.fieldsValidation.firstName}
                  />
                  <UserFieldInput
                    placeholder="Enter email address"
                    fieldKey="email"
                    inputTitle="Email Address"
                    onValueChange={user.onUserValueChange}
                    resetValidation={validation.resetFieldValidation}
                    validateField={validation.validateUserField}
                    value={user.userFields.email}
                    validationMessage={validation.fieldsValidation.email}
                    titlePadding="8px 0 8px 0"
                  />
                </FlexBlock>
                <FlexBlock flexDirection="column" maxWidth="350px">
                  <UserFieldInput
                    placeholder="Enter Last Name"
                    fieldKey="lastName"
                    inputTitle="Last Name"
                    onValueChange={user.onUserValueChange}
                    resetValidation={validation.resetFieldValidation}
                    validateField={validation.validateUserField}
                    value={user.userFields.lastName}
                    validationMessage={validation.fieldsValidation.lastName}
                  />
                  <UserFieldInput
                    placeholder="Enter phone number"
                    fieldKey="mobilePhone"
                    inputTitle="Phone Number"
                    onValueChange={user.onUserValueChange}
                    resetValidation={validation.resetFieldValidation}
                    validateField={validation.validateUserField}
                    value={user.userFields.mobilePhone}
                    validationMessage={validation.fieldsValidation.mobilePhone}
                    titlePadding="8px 0 8px 0"
                  />
                </FlexBlock>
              </FlexBlock>
            </Section>
            <Section flexDirection="column" noBottomBorder>
              <FlexBlock flexDirection="column" minWidth="100%">
                <Typography.Text
                  $colorName="onyx"
                  $dmSans
                  $bold
                  $size={16}
                  $lineHeight={24}
                >
                  User Permissions
                </Typography.Text>
                <FlexBlock
                  flexDirection="row"
                  justifyContent="space-around"
                  columnGap="10px"
                  minWidth="100%"
                >
                  <FlexBlock flexDirection="column" minWidth="350px">
                    <RolesSelector
                      userRole={user.userRole}
                      onUserRoleChange={user.onUserRoleChange}
                    />
                  </FlexBlock>
                  <FlexBlock flexDirection="column" minWidth="350px">
                    <TenantSelector
                      editMode={isEditMode}
                      canManageTenants={hasPlatformManagementPermission}
                      userRole={user.userRole}
                      userTenant={user.userTenant}
                      setSelectedTenant={user.onTenantChange}
                    />
                  </FlexBlock>
                </FlexBlock>
              </FlexBlock>
            </Section>
          </FlexBlock>

          <SectionFlexEnd>
            <Button
              variant="secondary"
              compact
              disabled={!validation.canResetFields}
              onClick={user.resetUser}
            >
              Reset
            </Button>
            <Gap size={10} />
            <Button
              compact
              onClick={isEditMode ? user.updateUser : user.createUser}
              variant="primary"
              isLoading={user.isLoading}
              disabled={!validation.canSubmit}
            >
              {isEditMode ? 'Save' : 'Add'} User
            </Button>
          </SectionFlexEnd>
        </UserModalContainer>
      </ClickAwayListener>
    </Modal>
  );
};

export default UserModal;
