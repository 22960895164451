import { SVGProps } from 'react';

const EnvelopeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-16 -20)">
        <path
          d="M37.47 24.8H18.53c-1.066 0-1.93.871-1.93 1.946v11.708c0 1.075.864 1.946 1.93 1.946h18.94c1.066 0 1.93-.871 1.93-1.946V26.746a1.938 1.938 0 0 0-1.93-1.946Zm-1.058 1.642L28 32.805l-8.412-6.363h16.824Z"
          fill="#090909"
        />
      </g>
    </g>
  </svg>
);

export default EnvelopeIcon;
