import { FC } from 'react';
import * as S from './LogoAndCopyright.styles';
import ProphetDarkLogoIcon from '../../../../assets/icons/ProphetDarkLogo';
import Typography from '../../../../components/Typography';

interface IProps {}

const LogoAndCopyright: FC<IProps> = () => {
  return (
    <>
      <S.LogoWrapper>
        <ProphetDarkLogoIcon />
      </S.LogoWrapper>
      <S.CopyrightWrapper>
        <Typography.Text $colorName="steel">
          © {new Date().getFullYear()} Ahteed LLC.
        </Typography.Text>
      </S.CopyrightWrapper>
    </>
  );
};

export default LogoAndCopyright;
