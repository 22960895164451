import { FC, KeyboardEventHandler, RefObject, useState } from 'react';
import { InputErrorType, InputHtmlType } from 'types/commonTypes';
import FieldErrors from '../FieldErrors/FieldErrors';
import * as S from './Input.styles';
import { Spinner } from '../Spinner';

export interface IInputProps {
  variant?:
    | 'default'
    | 'searchBar'
    | 'searchBar-autoWidth'
    | 'textField'
    | 'textFieldWhite'
    | 'loginTextField'
    | 'contentInput';
  iconVariant?: 'right';
  name?: string;
  type?: InputHtmlType;
  placeholder?: string;
  margin?: string;
  icon?: JSX.Element;
  errors?: InputErrorType[];
  borderRadius?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<Element>;
  value?: string | ReadonlyArray<string> | number;
  defaultValue?: string | ReadonlyArray<string> | number | null;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  fontSize?: number;
  readOnly?: boolean;
  disabled?: boolean;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  required?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  showLoadingIndicator?: boolean;
  textIndent?: string;
  width?: string | number;
  customErrorComponent?: JSX.Element;
  border?: string;
}

const Input: FC<IInputProps> = ({
  variant = 'default',
  iconVariant,
  name,
  type = 'text',
  placeholder,
  margin,
  icon,
  errors,
  borderRadius,
  fontSize,
  onFocus,
  defaultValue,
  onBlur,
  inputRef,
  disabled,
  showLoadingIndicator,
  textIndent,
  customErrorComponent,
  border,
  ...restProps
}) => {
  const isIcon = Boolean(icon);
  const isError = Boolean(errors?.length);
  const isPassword = type === 'password';

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onShowPasswordClick = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <S.StyledInputContainer $margin={margin}>
      <S.StyledInputAndIconContainer>
        {isIcon && (
          <S.StyledIconContainer variant={iconVariant || variant}>
            {icon}
          </S.StyledIconContainer>
        )}
        {isPassword && (
          <S.StyledEyeIconButton
            $isPasswordVisible={isPasswordVisible}
            onClick={onShowPasswordClick}
          >
            <S.StyledEyeIcon />
          </S.StyledEyeIconButton>
        )}
        <S.StyledInput
          autoComplete="off"
          onFocus={onFocus}
          $isError={isError}
          $withIcon={isIcon}
          $borderRadius={borderRadius}
          $border={border}
          $textIndent={textIndent}
          name={name}
          $fontSize={fontSize}
          type={isPassword && isPasswordVisible ? 'text' : type}
          placeholder={placeholder}
          defaultValue={defaultValue || undefined}
          onBlur={onBlur || undefined}
          variant={variant}
          ref={inputRef}
          disabled={disabled}
          {...restProps}
        />
        {showLoadingIndicator && (
          <S.StyledIconContainer variant="right">
            <Spinner $isStratos $size="18px" />
          </S.StyledIconContainer>
        )}
      </S.StyledInputAndIconContainer>
      {isError && variant !== 'textField' && <FieldErrors errors={errors} />}
      {customErrorComponent && customErrorComponent}
    </S.StyledInputContainer>
  );
};

export default Input;
