import { put, takeLatest, all } from 'redux-saga/effects';
import { globalActions as actions } from '.';

import { PayloadAction } from '@reduxjs/toolkit';

import { api } from 'app/api';
import { localStorageEnum } from '../../../../types';
import { publicRoutes, RouteConstants } from '../../../routes';
import { showErrorToast } from '../../../../utils/toast';
import { userPermissionEnum } from '../permissions/userPermissions.enum';
import { IUser } from 'app/api.types';

function* getCurrentUserSaga(action: PayloadAction<{ redirectUrl?: string }>) {
  try {
    const token = localStorage.getItem(localStorageEnum.token);
    if (!token) {
      yield put(actions.setUser({ user: null }));
      return;
    }

    const userDataPromise: Promise<unknown> = yield (put as any)(
      api.endpoints.getUserProfile.initiate({}),
    );

    const userPermissionPromise: Promise<unknown> = yield (put as any)(
      api.endpoints.getUserPermissions.initiate(),
    );

    const [{ data: userProfileData }, { data: userPermissionData }]: [
      { data: IUser },
      { data: userPermissionEnum[] },
    ] = yield all([userDataPromise, userPermissionPromise]);

    yield put(
      actions.setUser({
        user: userProfileData,
        permissions: userPermissionData,
      }),
    );
    if (action.payload.redirectUrl) {
      yield put(actions.redirectTo(action.payload.redirectUrl));
    }
  } catch (e) {
    yield put(actions.setUser({ user: null }));
    if (!publicRoutes.includes(window.location.pathname)) {
      showErrorToast('Session expired. Please re-login');
      yield put(actions.redirectTo(RouteConstants.authorization.login));
    }
  }
}

export function* globalSaga() {
  yield takeLatest(actions.authorizeUser.type, getCurrentUserSaga);
  yield takeLatest(actions.reloadUser.type, getCurrentUserSaga);
}
