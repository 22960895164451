interface IProps {
  hoverMode?: boolean;
  width?: number;
  height?: number;
}

const UploadCloudIcon = ({ hoverMode, width = 26, height = 20 }: IProps) => {
  const { calculatedWidth, calculatedHeight } = {
    calculatedWidth: hoverMode ? width * 1.8 : width,
    calculatedHeight: hoverMode ? height * 1.8 : height,
  };
  return (
    <svg
      width={calculatedWidth}
      height={calculatedHeight}
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transition: 'all 0.1s ease-in-out' }}
    >
      <path
        d="M26 13.7016C25.9607 13.4561 25.9308 13.2091 25.8816 12.9664C25.5781 11.4721 24.8295 10.2908 23.6251 9.4437C23.4427 9.31495 23.3948 9.21293 23.452 8.97513C23.7961 7.55535 23.5079 6.28121 22.5184 5.2491C21.5316 4.21979 20.3305 3.92008 18.9856 4.29648C18.9204 4.31477 18.8559 4.33306 18.7906 4.35065C18.784 4.35276 18.7747 4.34643 18.8226 4.3598C18.4493 3.7864 18.1452 3.21019 17.7439 2.72333C15.9965 0.601401 13.7766 -0.339254 11.1454 0.108208C8.22149 0.605622 6.30103 2.44402 5.3448 5.39895C5.21171 5.80983 5.15382 6.25026 5.08861 6.68154C5.06332 6.84828 5.00077 6.90316 4.85836 6.93693C1.93443 7.64189 -0.115122 10.4807 0.0053215 13.6524C0.113123 16.4849 1.89317 18.9431 4.46376 19.7557C4.9269 19.9021 5.42665 19.9759 5.91109 19.9844C7.58599 20.0118 9.26223 19.9949 10.9378 19.9949C11.037 19.9949 11.1368 19.9949 11.2506 19.9949C11.2506 18.0025 11.2506 16.0388 11.2506 14.0492C11.1647 14.0443 11.0829 14.0351 11.0017 14.0351C10.5445 14.0337 10.0874 14.0288 9.63089 14.0365C9.34009 14.0414 9.06726 14.0224 8.91155 13.7073C8.75517 13.3914 8.89957 13.1444 9.06992 12.8968C9.69676 11.9856 10.3196 11.071 10.9438 10.1578C11.4489 9.41837 11.9533 8.67823 12.4603 7.9402C12.7997 7.4456 13.2076 7.4449 13.5463 7.9395C14.6869 9.60622 15.8248 11.2744 16.964 12.9418C16.9886 12.9784 17.0133 13.0142 17.0372 13.0508C17.1723 13.2584 17.2102 13.4779 17.0958 13.708C16.982 13.9352 16.795 14.0351 16.5541 14.0344C15.9625 14.033 15.3716 14.0337 14.7594 14.0337C14.7594 16.0248 14.7594 17.9947 14.7594 19.9949C14.8679 19.9949 14.967 19.9949 15.0669 19.9949C16.9114 19.9949 18.7567 19.9872 20.6013 19.997C22.4439 20.0069 23.8433 19.1296 24.912 17.6014C25.4883 16.7748 25.8277 15.8369 25.9521 14.8139C25.9581 14.764 25.9834 14.7169 26 14.6683C26 14.3461 26 14.0239 26 13.7016Z"
        fill={hoverMode ? '#1D76DEE5' : '#BCBCC1'}
      />
    </svg>
  );
};

export default UploadCloudIcon;
