import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import { CloseIcon } from 'app/components/UI/CloseIcon';
import FlexBlock from 'app/components/UI/FlexBlock';
import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';
import { FC, useMemo } from 'react';
import {
  ISocialPostContentVersion,
  SocialPostType,
} from '../../../types/SocialPost.types';
import SocialPostHistoryItem from './SocialPostHistoryItem';

interface ISocialPostHistoryDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  contentVersions: ISocialPostContentVersion[];
  onPostContentVersionLoad: (
    postContentId: number,
    postType: SocialPostType,
  ) => void;
}

const SocialPostHistoryDrawer: FC<ISocialPostHistoryDrawerProps> = ({
  open,
  toggleDrawer,
  contentVersions,
  onPostContentVersionLoad,
}) => {
  const contentVersionsByPostType = useMemo(() => {
    const groupedByPostType = contentVersions.reduce((acc, version) => {
      if (!acc[version.postType]) {
        acc[version.postType] = [];
      }
      acc[version.postType].push(version);
      return acc;
    }, {} as Record<SocialPostType, ISocialPostContentVersion[]>);

    const result = Object.keys(groupedByPostType).reduce((acc, type) => {
      const postType = type as SocialPostType;
      const versions = groupedByPostType[postType];

      versions.sort((a, b) => b.version - a.version);
      const latestVersion = versions[0];
      const olderVersions = versions.slice(1);

      acc[postType] = { latestVersion, olderVersions };
      return acc;
    }, {} as Record<SocialPostType, { latestVersion: ISocialPostContentVersion; olderVersions: ISocialPostContentVersion[] }>);

    return result;
  }, [contentVersions]);

  return (
    <SideDrawer open={open}>
      <FlexBlock flexDirection="column" style={{ width: '100%' }}>
        <FlexBlock
          flexDirection="row"
          minWidth="100%"
          justifyContent="space-between"
          style={{
            borderBottom: '1px solid rgba(228, 228, 233, 1)',
            padding: '16px',
          }}
        >
          <FlexBlock flexDirection="column" rowGap="8px">
            <Typography.Text $dmSans $bold $lineHeight={24} $size={16}>
              Social Posts Version History
            </Typography.Text>
            <Typography.Text $lineHeight={20} $size={14} $colorName="steel">
              Previously generated Social Posts
            </Typography.Text>
          </FlexBlock>
          <Button variant="icon-button-border" onClick={toggleDrawer}>
            <CloseIcon />
          </Button>
        </FlexBlock>
        {Object.keys(contentVersionsByPostType).map(postType => {
          const { latestVersion, olderVersions } =
            contentVersionsByPostType[postType as SocialPostType];
          return (
            <SocialPostHistoryItem
              content={latestVersion}
              key={latestVersion.id}
              olderVersions={olderVersions}
              onPostContentVersionLoad={onPostContentVersionLoad}
            />
          );
        })}
      </FlexBlock>
    </SideDrawer>
  );
};

export default SocialPostHistoryDrawer;
