import styled, { css } from 'styled-components';

export const HeaderButtonWrapper = styled.div<{
  $borderColor?: string;
}>(
  ({ $borderColor }) => css`
    > button {
      width: 56px;
      height: 56px;
      ${$borderColor
        ? css`
            border-color: ${$borderColor} !important;
          `
        : ''}
    }
  `,
);

export const WandAvatar = styled.div(
  ({ theme }) => css`
    width: 56px;
    height: 56px;
    background: ${theme.colors.stratos};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);
