import styled, { css } from 'styled-components/macro';
import { AuthBackgroundWrapper } from '../LoginPage/LoginPage.styles';

export const Wrapper = styled(AuthBackgroundWrapper)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  max-width: 464px;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: center;

  .center-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const IconWrapper = styled.div(
  () => css`
    @keyframes popup {
      0% {
        transform: scale(0.4);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    img {
      max-width: 162px;
      max-height: 162px;
      animation: 0.3s popup;
    }
  `,
);
