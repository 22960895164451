import Button from 'app/components/UI/Button/Button';
import { FC } from 'react';
import { SettingsBarFooter } from '../../CreateOrEditPitchPage.styles';
import { PitchStateActions, usePitch, usePitchDispatch } from '../PitchContext';

const SettingsFooter: FC = ({ children }) => {
  const { settingsOpen } = usePitch();
  const dispatch = usePitchDispatch();

  const handleClose = () => {
    dispatch({
      type: PitchStateActions.TOGGLE_SETTINGS,
      payload: !settingsOpen,
    });
  };

  return (
    <SettingsBarFooter>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      {children}
    </SettingsBarFooter>
  );
};

export default SettingsFooter;
