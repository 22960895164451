import { FC, ReactNode, useMemo } from 'react';
import {
  JOURNALISTS_PAGINATION_SELECT_PROPS,
  pageSizeOptions,
} from '../JournalistsTable.constants';
import PageHeader from 'app/components/UI/PageHeader';
import Typography from 'app/components/Typography';
import Select from 'app/components/UI/Select/Select';
import { IOption } from 'types/commonTypes';
import { IPaginationRequestPart } from 'app/api.types';

interface IJournalistsTableHeaderProps {
  renderContainerHeader?: () => ReactNode;
  renderFilterSelector?: () => ReactNode;
  pagination: IPaginationRequestPart;
  onPaginationChange: (pagination: IPaginationRequestPart) => void;
  // handleItemsPerPageChange: (option: IOption<number>) => void;
}

const JournalistsTableHeader: FC<IJournalistsTableHeaderProps> = props => {
  const activePageSizeOption = useMemo(() => {
    return (
      pageSizeOptions.find(pso => pso.value === props.pagination.page.size) ||
      null
    );
  }, [props.pagination]);

  const handleItemsPerPageChange = (value: unknown) => {
    const option = value as IOption<number>;
    if (option.value) {
      props.onPaginationChange({
        ...props.pagination,
        page: {
          ...props.pagination.page,
          size: option.value,
        },
      });
    }
  };

  return (
    <PageHeader.Wrapper padding="0" noBorder>
      <PageHeader.Section $padding="0 0px" flex="unset">
        {props.renderContainerHeader && props.renderContainerHeader()}
      </PageHeader.Section>
      <PageHeader.Section
        $padding="0 0px"
        justifyContent="flex-end"
        gridColumnGap="16px"
        height="2rem"
      >
        <Typography.Text
          $colorName="steel"
          $margin="0 0 0 16px"
          style={{ wordBreak: 'keep-all' }}
        >
          View:
        </Typography.Text>
        <Select<IOption<number>>
          {...JOURNALISTS_PAGINATION_SELECT_PROPS}
          value={activePageSizeOption || undefined}
          options={pageSizeOptions}
          onChange={handleItemsPerPageChange}
        />
        {props.renderFilterSelector && props.renderFilterSelector()}
      </PageHeader.Section>
    </PageHeader.Wrapper>
  );
};

export default JournalistsTableHeader;
