import { useAppDispatch } from '../../../../../../store/hooks';
import { useGlobalSlice } from '../../../../Global/slice';
import { createEMailToLink } from 'utils/helpers';
import useGetCurrentUserPlan from 'app/containers/Global/permissions/useGetCurrentUserPlan.hook';

const useOutOfLimitsMessages = () => {
  const globalSlice = useGlobalSlice();
  const dispatch = useAppDispatch();

  const onUpgradeClick = () => {
    dispatch(globalSlice.actions.goToUpgradeToPro());
  };

  const { isFreemiumPlan, isFreeOrFreemiumPlan } = useGetCurrentUserPlan();

  const currentPlan = isFreeOrFreemiumPlan
    ? isFreemiumPlan
      ? 'Free Trial'
      : 'Free Plan'
    : 'Pro Plan';

  const planToUpgrade = isFreeOrFreemiumPlan ? 'Pro Plan' : 'Enterprise Plan';

  const accountToUpgrade = isFreeOrFreemiumPlan ? 'Pro' : 'Enterprise';

  const outOfGenerations = (
    <>
      Please upgrade to our <a onClick={onUpgradeClick}>{planToUpgrade}</a> for
      additional content generations.
    </>
  );

  const outOfPitchRuns = (
    <>
      Please upgrade to our <a onClick={onUpgradeClick}>{planToUpgrade}</a> for
      additional pitch runs.
    </>
  );

  const outOfContentGenerationsAndPitchRuns = (
    <>
      Please upgrade to our <a onClick={onUpgradeClick}>{planToUpgrade}</a> for
      additional content generations and pitch runs.
    </>
  );

  const runningLowOnPitchRuns = (
    <>
      Running low on runs? Upgrade to our{' '}
      <a onClick={onUpgradeClick}>{planToUpgrade}.</a>
    </>
  );

  const runningLowOnPitchGenerations = (
    <>
      Running low on content generations? Upgrade to a{' '}
      <a onClick={onUpgradeClick}>{planToUpgrade}.</a>
    </>
  );

  const runningLowOnBlogGenerations = (
    <>
      Running low on blog generations? Upgrade to a{' '}
      <a onClick={onUpgradeClick}>{planToUpgrade}.</a>
    </>
  );
  const runningLowOnBioGenerations = (
    <>
      Running low on bio generations? Upgrade to a{' '}
      <a onClick={onUpgradeClick}>{planToUpgrade}.</a>
    </>
  );

  const runningLowOnPitchGenerationsWithProPlan = (title: string) => (
    <>
      Running low on content generations? Upgrade to an{' '}
      <a href={createEMailToLink('sales@prprophet.ai', title)}>
        Enterprise Plan
      </a>
      .
    </>
  );

  const runningLowOnContentGenerationsWithProPlan = (
    title: string,
    contentType: string,
  ) => (
    <>
      Running low on {contentType} generations? Upgrade to an{' '}
      <a href={createEMailToLink('sales@prprophet.ai', title)}>
        Enterprise Plan
      </a>
      .
    </>
  );

  return {
    outOfContentGenerationsAndPitchRuns,
    outOfGenerations,
    outOfPitchRuns,
    runningLowOnPitchGenerations,
    runningLowOnPitchRuns,
    runningLowOnBlogGenerations,
    runningLowOnBioGenerations,
    runningLowOnPitchGenerationsWithProPlan,
    runningLowOnContentGenerationsWithProPlan,
    goToBilling: onUpgradeClick,
    planToUpgrade,
    accountToUpgrade,
    currentPlan,
  };
};

export default useOutOfLimitsMessages;
