import { request } from '../../../utils/request';

export const requestResendMfaToken = (mfaToken: string) =>
  request({
    url: 'authentication/mfa/resend',
    data: { mfaToken },
    method: 'POST',
  });

export const submitMfaCode = (mfaToken: string, code: string) =>
  request({
    url: `authentication/mfa/verify`,
    method: 'POST',
    data: { code, mfaToken },
  });

export const getCurrentUser = (token: string) =>
  request({
    url: 'profile',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

export const submitForgotPassword = (email: string) => {
  return request({
    method: 'POST',
    url: 'authentication/password/forgot',
    data: { email },
  });
};

export const __localLogin = (username: string, password: string) => {
  return request({
    method: 'POST',
    url: 'authentication/login',
    data: { username, password },
  });
};
