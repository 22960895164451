import { useCreateUserMutation } from 'app/api';
import { showErrorToast, showSuccessToast } from 'utils/toast';

import {
  isSelectedTenantValid,
  getSelectedTenantId,
} from '../UserModal/UserModal.helpers';
import {
  IClassifiedProperty,
  ICreateUserPayload,
  ITenantBase,
  IUser,
} from 'app/api.types';
import { UserRoleEnum } from 'app/containers/Global/slice/types';
import { useMemo, useState } from 'react';
import { ImageFile, ImageURL } from 'app/components/FileDropzone/ImageDropzone';
import { uploadLogoResource } from 'utils/helpers';

interface IUseCreateUserHookArgs {
  user: ICreateUserPayload;
  userRole: IClassifiedProperty<UserRoleEnum> | null;
  userTenant: ITenantBase | null;
  currentUser: IUser | undefined;
  avatar: ImageURL | ImageFile | null;
  onClose: () => void;
}

export const useCreateUser = ({
  userRole,
  userTenant,
  user,
  currentUser,
  avatar,
  onClose,
}: IUseCreateUserHookArgs) => {
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [createUser, { isLoading: isCreatingUser }] = useCreateUserMutation();

  const isLoading = useMemo(() => {
    return isCreatingUser || isUploadingImage;
  }, [isCreatingUser, isUploadingImage]);

  const createUserHandler = async () => {
    const isTenantValid = isSelectedTenantValid({
      userRole,
      userTenant,
      currentUser,
    });

    if (!isTenantValid) {
      showErrorToast('Select a valid tenant', 'Error');
      return;
    }

    if (!userRole || !userRole.code) {
      showErrorToast('Select a role', 'Error');
      return;
    }

    const selectedTenantId = getSelectedTenantId({
      userRole,
      userTenant,
      currentUser,
    });

    try {
      let uploadedImage = '';

      if (avatar) {
        if (avatar.type === 'File') {
          try {
            setIsUploadingImage(true);
            uploadedImage = await uploadLogoResource(avatar.content);
          } catch (error) {
            // To Do: Show additional error message if image was not uploaded
          } finally {
            setIsUploadingImage(false);
          }
        }

        if (avatar.type === 'URL') {
          uploadedImage = avatar.content;
        }
      }

      const newUserPayload = {
        ...user,
        avatar: uploadedImage || '',
        role: userRole.code,
        tenantId: selectedTenantId,
      };

      await createUser(newUserPayload).unwrap();

      showSuccessToast('New User has been added');
      onClose();
    } catch (error) {
      //@ts-ignore
      const errorMessage = (error as FetchBaseQueryError).data?.message;
      showErrorToast(errorMessage || 'Could not create a new user', 'Error');
    }
  };

  return { createUser: createUserHandler, isLoading };
};
