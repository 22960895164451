import Typography from 'app/components/Typography';
import { FC } from 'react';

interface IJournalistHeaderCellProps {
  title: string;
}

export const JournalistHeaderCell: FC<IJournalistHeaderCellProps> = ({
  title,
}) => {
  return (
    <Typography.Text $dmSans $bold $size={14}>
      {title}
    </Typography.Text>
  );
};
