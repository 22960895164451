import { FC } from 'react';
import Accordion from '../../../../../../../components/Accordion/Accordion';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import Typography from '../../../../../../../components/Typography';
import RoundedPill from '../../../../../../../components/UI/RoundedPill';
import InfoTip from '../../../../../../../components/UI/InfoTip/InfoTip';

interface IProps {
  reasons: string[];
  defaultExpanded?: boolean;
}

const ReasonsAccordion: FC<IProps> = props => {
  return (
    <Accordion title="Tell Me Why" variant="orbit-blue">
      <FlexBlock flexDirection="column" margin="4px 0" rowGap="28px">
        <InfoTip
          tooltipText="Items are not listed in a particular order."
          hideIcon
          padding="0"
        >
          <Typography.Text $colorName="orbit">
            Why is Taylor suggesting this content?
          </Typography.Text>
        </InfoTip>
        <FlexBlock flexDirection="column" rowGap="24px">
          {props.reasons.map((reason, index) => (
            <FlexBlock columnGap="12px" key={`${index}-${reason}`}>
              <FlexBlock>
                <RoundedPill variant="dark">{String(index + 1)}</RoundedPill>
              </FlexBlock>
              <Typography.Text $colorName="onyx">{reason}</Typography.Text>
            </FlexBlock>
          ))}
        </FlexBlock>
      </FlexBlock>
    </Accordion>
  );
};

export default ReasonsAccordion;
