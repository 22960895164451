import React from 'react';

function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <filter
          id="filter-1"
          width="109.8%"
          height="167.5%"
          x="-4.9%"
          y="-33.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="-2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="6"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.0480762252 0 0 0 0 0.0480491123 0 0 0 0 0.0500554624 0 0 0 0.0472130409 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-1820 -1032)">
          <g transform="translate(1368 72)">
            <g filter="url(#filter-1)" transform="translate(1 928)">
              <g transform="translate(435 12)">
                <g transform="translate(16 20)">
                  <path d="M15.43.57a.582.582 0 00-.61-.135L.782 5.55a.581.581 0 000 1.093L6.48 8.71 8.89 6.3a.498.498 0 11.705.704L7.234 9.365l2.123 5.852a.581.581 0 001.093 0l5.115-14.036a.582.582 0 00-.135-.61z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SendIcon;
