import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { IPaginationRequestPart, ITableData } from 'app/api.types';
import { ColumnInstance, useRowSelect, useTable } from 'react-table';
import * as S from './JournalistsTable.styles';
import { JournalistsTablePagination } from './parts/JournalistsTablePagination';

import { scrollMainContentToTop } from 'utils/helpers';
import EmptyDataNotice, {
  IEmptyDataNoticeProps,
} from 'app/components/EmptyDataNotice/EmptyDataNotice';
import { IDataTableColumn } from 'app/components/Table/Table.types';
import {
  selectedRowsReducer,
  useCheckboxes,
  useSelectedFlatRows,
} from './JournalistsTable.hooks';
import SortIcon from 'app/assets/icons/tone-icons/SortIcon';
import FlexBlock from 'app/components/UI/FlexBlock';
import { SkeletonDiv } from 'app/components/UI/LoadingSkeletons/Skeletons.styles';
import TablePaginationSkeleton from 'app/components/UI/LoadingSkeletons/Tables/PaginationSkeleton';

interface IProps<DataItemType> {
  dataTypeName: string;
  data?: ITableData<DataItemType[]> | DataItemType[];
  columns: any[];
  pagination?: IPaginationRequestPart;
  onPaginationChange?: (arg1: IPaginationRequestPart) => void;
  isDataLoading?: boolean;
  renderListItem?: (item: DataItemType) => ReactNode;
  renderRowActions?: (item: DataItemType) => ReactNode;
  onRowClick?: (item: DataItemType) => void;
  onSelectedRowIdsChange?: (selectedIds: string[]) => void;
  showCount?: boolean;
  emptyNoticeConfig?: IEmptyDataNoticeProps;
  showHeaderOnEmptyData?: boolean;
  showNoResources?: boolean;
  noHeader?: boolean;
  isParentListSelected?: boolean;
}

const JournalistsTable = <DataItem,>(props: IProps<DataItem>) => {
  useEffect(() => {
    handlePageNumberChange(0);
  }, [props.pagination?.page.size]);

  const tableData: DataItem[] =
    'content' in (props.data || {})
      ? (props.data as ITableData<DataItem[]>).content
      : (props.data as DataItem[]);

  const emptyNoticeProps: IEmptyDataNoticeProps = useMemo(() => {
    if (props.emptyNoticeConfig) return props.emptyNoticeConfig;
    return {
      title: `No ${props.dataTypeName}`,
    };
  }, [props.dataTypeName, props.emptyNoticeConfig]);

  const columns = useMemo(() => props.columns, [props.columns]);

  const initialRender = useRef(true);

  const tableInstance = useTable(
    {
      columns,
      autoResetSelectedRows: false,
      getRowId: row => {
        //@ts-ignore
        return row?.id;
      },
      // @ts-ignore
      data: tableData || [],
      stateReducer: selectedRowsReducer,
    },
    useRowSelect,
    useCheckboxes,
  );

  const { onSelectedRowIdsChange } = props;

  useEffect(() => {
    if (onSelectedRowIdsChange) {
      onSelectedRowIdsChange(Object.keys(tableInstance.state.selectedRowIds));
    }
  }, [tableInstance.state.selectedRowIds, onSelectedRowIdsChange]);

  // Custom hook to get selected rows and persist them between table data changes when selectedRowIds is not enough
  const { selectedRowsRef, resetSelectedRowsRef } =
    useSelectedFlatRows(tableInstance);

  const handlePageNumberChange = (page: number) => {
    if (props.onPaginationChange && props.pagination) {
      props.onPaginationChange({
        ...props.pagination,
        page: {
          ...props.pagination.page,
          page,
        },
      });

      scrollMainContentToTop();
    }
  };

  const handleSortClick =
    (column: IDataTableColumn<IProps<DataItem>>) => () => {
      if (column.disableSort || !column?.id) return;
      const { isSortedAsc } = getTdSortProps(column.id);

      if (props.onPaginationChange && props.pagination) {
        props.onPaginationChange({
          ...props.pagination,
          sort: [{ property: column.id, ascending: !isSortedAsc }],
        });
      }
    };

  const handleSelectedRowsReset = useCallback(() => {
    tableInstance.dispatch({
      type: 'resetAllSelectedRows',
    });

    resetSelectedRowsRef();
  }, [tableInstance, resetSelectedRowsRef]);

  const handleSelectedRowsToggle = useCallback(
    isParentListSelected => {
      tableInstance.dispatch({
        type: 'toggleSelectedRowsInCurrentPage',
        currentRows: tableInstance.rows,
        isParentSelected: isParentListSelected,
      });
    },
    [tableInstance],
  );

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      // It's safe to reset selected rows when data changes because Media List Entries table has no pagination
      if (props.dataTypeName === 'Media List Entries') {
        handleSelectedRowsReset();
      }
    }
  }, [props.data, props.dataTypeName, handleSelectedRowsReset]);

  useEffect(() => {
    if (props.isParentListSelected !== undefined) {
      handleSelectedRowsToggle(props.isParentListSelected);
    }
  }, [props.isParentListSelected, handleSelectedRowsToggle]);

  const getTdSortProps: (arg1: string) => {
    isSorted: boolean;
    isSortedAsc: boolean;
  } = (propName: string) => {
    const columnSort = props.pagination
      ? props.pagination.sort.find(s => s.property === propName)
      : undefined;
    if (columnSort) {
      return { isSorted: true, isSortedAsc: columnSort.ascending };
    }
    return { isSorted: false, isSortedAsc: false };
  };

  const isSortEnabled = (columnId: string) => {
    if (columnId === 'selection' || columnId === 'export') return false;
    const column = props.columns.find(c => c.id === columnId);
    return !column?.disableSort;
  };

  const shouldDisplayEmptyNotice = useMemo(() => {
    return (
      (!tableData || tableData.length === 0) &&
      !props.isDataLoading &&
      props.showNoResources
    );
  }, [tableData, props.isDataLoading, props.showNoResources]);

  return (
    <S.Wrapper
      borderTopLeft={0}
      noWhitespace={props.noHeader}
      noBorderRadius={!props.noHeader}
    >
      <S.TableScrollWrapper>
        <S.Table {...tableInstance.getTableProps()}>
          {!props.noHeader && (
            <S.THead>
              <S.Tr>
                {tableInstance.headers.map(column => (
                  <S.Th
                    {...getTdSortProps(column.id)}
                    key={column.id}
                    columnWidth={column.width}
                  >
                    {column.render('Header', { rowsRef: selectedRowsRef })}
                    {isSortEnabled(column.id) && (
                      <SortIcon
                        width={20}
                        height={20}
                        color={'blue'}
                        onClick={handleSortClick(column)}
                      />
                    )}
                  </S.Th>
                ))}
              </S.Tr>
            </S.THead>
          )}
          <S.TBody {...tableInstance.getTableBodyProps()}>
            {props.isDataLoading &&
              Array.from({ length: 10 }).map((_, rowIndex) => (
                <S.Tr key={rowIndex}>
                  {(props.noHeader
                    ? Array.from({ length: 4 })
                    : tableInstance.headers
                  ).map((item, columnIndex) => {
                    if (typeof item !== 'object') {
                      return (
                        <S.Td key={columnIndex} width="25%">
                          {columnIndex === 3 ? (
                            <FlexBlock
                              flexDirection="row"
                              columnGap="8px"
                              minWidth="100%"
                            >
                              {Array.from({
                                length: Math.floor(Math.random() * 5) + 1,
                              }).map((_, divIndex) => (
                                <SkeletonDiv
                                  key={divIndex}
                                  shimmerAnimation
                                  width="30px"
                                  height="30px"
                                  borderRadius="50%"
                                />
                              ))}
                            </FlexBlock>
                          ) : (
                            <SkeletonDiv
                              shimmerAnimation
                              borderRadius="8px"
                              width="50%"
                            />
                          )}
                        </S.Td>
                      );
                    }

                    // Cast item to ColumnInstance
                    const column = item as ColumnInstance;

                    if (column.id === 'selection')
                      return (
                        <S.Td
                          key={columnIndex}
                          width={props.noHeader ? '1%' : undefined}
                        />
                      );

                    if (column.id === 'socials')
                      return (
                        <S.Td
                          key={columnIndex}
                          width={
                            //@ts-ignore
                            props.noHeader ? column.originalWidth : undefined
                          }
                        >
                          <FlexBlock
                            flexDirection="row"
                            columnGap="8px"
                            minWidth="100%"
                          >
                            {Array.from({
                              length: Math.floor(Math.random() * 5) + 1,
                            }).map((_, divIndex) => (
                              <SkeletonDiv
                                key={divIndex}
                                shimmerAnimation
                                width="30px"
                                height="30px"
                                borderRadius="50%"
                              />
                            ))}
                          </FlexBlock>
                        </S.Td>
                      );

                    return (
                      <S.Td
                        key={columnIndex}
                        width={
                          //@ts-ignore
                          props.noHeader ? column.originalWidth : undefined
                        }
                      >
                        <SkeletonDiv
                          shimmerAnimation
                          borderRadius="8px"
                          width="50%"
                        />
                      </S.Td>
                    );
                  })}
                </S.Tr>
              ))}
            {!props.isDataLoading &&
            tableData &&
            tableData.length &&
            tableData.length >= 0 ? (
              tableInstance.rows.map(row => {
                tableInstance.prepareRow(row);
                return (
                  <S.Tr
                    isEven={!Boolean(row.index % 2)}
                    isSelected={row.isSelected}
                    hoverEffect
                    onClick={(event: any) => {
                      if (
                        event?.target?.tagName === 'DIV' ||
                        event?.target?.tagName === 'TD'
                      ) {
                        if (props.onRowClick) {
                          const item = row.original as unknown as DataItem;
                          props.onRowClick(item);
                        }
                      }
                    }}
                    key={row.id}
                  >
                    {row.cells.map(cell => (
                      <S.Td
                        width={cell.column.width}
                        key={`${cell.column.id}_${cell.row.id}_${cell.row.index}`}
                      >
                        {cell.render('Cell')}
                      </S.Td>
                    ))}
                    {props.renderRowActions && (
                      <FlexBlock className="journalist-row-actions">
                        {props.renderRowActions(
                          row.original as unknown as DataItem,
                        )}
                      </FlexBlock>
                    )}
                  </S.Tr>
                );
              })
            ) : (
              <></>
            )}
          </S.TBody>
        </S.Table>
      </S.TableScrollWrapper>
      {props.isDataLoading && props.dataTypeName !== 'Media List Entries' ? (
        <TablePaginationSkeleton />
      ) : (
        tableData &&
        tableData.length !== 0 &&
        props.pagination && (
          <JournalistsTablePagination
            pageInfo={(props.data as ITableData<DataItem[]>).pageable}
            onPageNumberChange={handlePageNumberChange}
            totalElements={(props.data as ITableData<DataItem[]>).totalElements}
            selectedCount={
              Object.keys(tableInstance.state.selectedRowIds).length
            }
            resetSelectedRows={handleSelectedRowsReset}
          />
        )
      )}

      {shouldDisplayEmptyNotice && <EmptyDataNotice {...emptyNoticeProps} />}
    </S.Wrapper>
  );
};

export default JournalistsTable;
