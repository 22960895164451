import { IProject, IPaginationRequestPart } from '../../../../api.types';
import Avatar from '../../../../components/UI/Avatar';
import { formatDate } from '../../../../../utils/helpers';
import { IEmptyDataNoticeProps } from '../../../../components/EmptyDataNotice/EmptyDataNotice';
import ContextMenu, {
  ContextMenuOption,
} from '../../../../components/UI/ContextMenu/ContextMenu';
import DotsVertical from '../../../../assets/icons/DotsVertical';
import { IDataTableColumn } from 'app/components/Table/Table.types';
import { ActionPasserConsumer } from '../../../../../utils/actionPasser/actionPasser';
import IfHasPermission from '../../../Global/permissions/IfHasPermission';
import { userPermissionEnum } from '../../../Global/permissions/userPermissions.enum';

export const defaultPaginationSettings: IPaginationRequestPart = {
  page: { page: 0, size: 10 },
  sort: [{ ascending: true, property: 'name' }],
};

export const tableColumnConfig: IDataTableColumn<IProject>[] = [
  {
    Header: 'Project Name',
    accessor: 'name',
    id: 'name',
    Cell: ({ row }) => (
      <>
        <Avatar
          margin="0 12px 0 0"
          size="40"
          url={row.original.logo}
          name={row.original.name}
        />
        <strong>{row.original.name}</strong>
      </>
    ),
  },
  {
    Header: 'Content Pieces',
    accessor: 'contentMeta',
    id: 'contentMeta',
    disableSort: true,
    // @ts-ignore
    Cell: ({ row }) =>
      row.original.contentMeta?.reduce(
        (acc, currentValue) => acc + currentValue.count,
        0,
      ) || 0,
  },
  {
    Header: 'Creator',
    id: 'creator.firstName',
    // @ts-ignore
    Cell: ({ row }) =>
      `${row.original.creator?.firstName || ''} ${
        row.original.creator?.lastName || ''
      }`,
  },
  {
    Header: 'Created',
    id: 'createdAt',
    // @ts-ignore
    Cell: ({ row }) => formatDate(row.original.createdAt),
  },
  {
    Header: '',
    id: 'actions',
    disableSort: true,
    // @ts-ignore
    Cell: ({ row }) => (
      <IfHasPermission require={userPermissionEnum.MANAGE_PROJECTS}>
        <div className="row-hover-visible">
          <ContextMenu
            buttonText={<DotsVertical />}
            preventDefaultMenuButtonAction
            usePortal
            buttonProps={{
              variant: 'icon-button',
            }}
            menuOptions={{
              align: 'right',
            }}
          >
            <ActionPasserConsumer>
              {({ action }) => (
                <>
                  <ContextMenuOption
                    onClick={() =>
                      action({
                        type: 'editProject',
                        arg: row.original.id,
                      })
                    }
                  >
                    Edit
                  </ContextMenuOption>
                  <ContextMenuOption
                    onClick={() =>
                      action({ type: 'deleteProject', arg: row.original })
                    }
                  >
                    Delete
                  </ContextMenuOption>
                </>
              )}
            </ActionPasserConsumer>
          </ContextMenu>
        </div>
      </IfHasPermission>
    ),
  },
];

export const noDataNoticeConfig: IEmptyDataNoticeProps = {
  title: 'No Projects',
  description: 'Here’s where it all starts, add some projects!',
  buttonText: 'Add Projects',
};
