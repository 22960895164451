import styled, { css } from 'styled-components';
import { IconButton } from 'app/containers/Bio/pages/BioPage/BioPage.styles';

export const StyledIconButton = styled(IconButton)<{ $noMargin?: boolean }>`
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  padding: 0.5rem;
  border-radius: 20%;
  margin: ${({ $noMargin }) => ($noMargin ? '0' : '0 0 0 10px')};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #1d76de1a;
  }

  svg {
    margin-left: 0;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      padding: 0;
    `}
`;

export const LoadingButtonWrapper = styled.div`
  margin: '11px  16px 0 24px';
`;
