import { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <filter
        id="filter-1"
        width="113.9%"
        height="225%"
        x="-6.9%"
        y="-62.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="8"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.0459708808 0 0 0 0 0.0459471255 0 0 0 0 0.0484882299 0 0 0 0.04 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#3F3F42" transform="translate(-1408 -190)">
        <g transform="translate(1368 72)">
          <g filter="url(#filter-1)" transform="translate(24 100)">
            <g transform="translate(16 18)">
              <path d="M15.473 13.631a8.108 8.108 0 001.694-4.94C17.167 4.173 13.413.5 8.823.5S.5 4.173.5 8.691c0 4.518 3.732 8.191 8.323 8.191a8.39 8.39 0 004.655-1.414l.043.042 3.646 3.589c.258.253.622.401.966.401.343 0 .707-.127.965-.401a1.323 1.323 0 000-1.9l-3.625-3.568zm-6.65.57c-3.089 0-5.599-2.47-5.599-5.51 0-3.04 2.51-5.51 5.599-5.51 3.089 0 5.599 2.47 5.599 5.51 0 3.04-2.51 5.51-5.6 5.51z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SearchIcon;
