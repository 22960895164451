import WarningIcon from 'app/assets/icons/WarningIcon';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';
import { ToastMessageStyle } from 'utils/toast';

export const SelectReportItemsToastError: FC = () => {
  return (
    <ToastMessageStyle.VolatileRedToastWrapper>
      <WarningIcon />
      <FlexBlock justifyContent="space-between" flex={1}>
        <Typography.Text $dmSans $bold $size={16} $colorName="white">
          Too Many Journalists.
        </Typography.Text>
        <Typography.Text $size={14} $colorName="whiteDimmed">
          Whoops, you’ve selected too many journalists.
        </Typography.Text>
      </FlexBlock>
    </ToastMessageStyle.VolatileRedToastWrapper>
  );
};
