enum SettingsSelectorTitle {
  PITCH_GENERATION = 'Pitch Generation',
  PERSONALIZED_PITCH_GENERATION = 'Personalized Email Pitch Generation',
}

export const taylorSettingsConfiguration = {
  email: {
    endpoint: 'author-email',
    title: SettingsSelectorTitle.PERSONALIZED_PITCH_GENERATION,
  },
  pitch: {
    endpoint: 'pitch-content',
    title: SettingsSelectorTitle.PITCH_GENERATION,
  },
};

export const taylorSelectorStyles = {
  minWidth: '220px',
  background: 'transparent',
  fontSize: 14,
  controlPadding: '10.5px 12px',
};
