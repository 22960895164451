import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';
import { formatDate } from 'utils/helpers';

interface IUserLoggedInDateProps {
  lastLoggedInDate?: string;
}

const UserLoggedInDate: FC<IUserLoggedInDateProps> = ({ lastLoggedInDate }) => {
  return (
    <FlexBlock flexDirection="row" columnGap="5px">
      <Typography.Text $colorName="steel" $size={14} $lineHeight={24}>
        Last Logged in:{' '}
      </Typography.Text>
      <Typography.Text $size={14} $lineHeight={24}>
        {lastLoggedInDate ? formatDate(lastLoggedInDate) : 'Never'}
      </Typography.Text>
    </FlexBlock>
  );
};

export default UserLoggedInDate;
