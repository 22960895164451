import { ContentFooter } from 'app/components/UI/ContentLayout/ContentLayout';
import { FC } from 'react';
import MainGrid from 'app/components/UI/MainGrid/MainGrid';
import {
  SocialPostsContainer,
  SocialPostsWrapper,
} from '../CreateOrEditSocialPosts/CreateOrEditSocialPostsPage.styles';
import Gap from 'app/components/UI/Gap';
import SocialPosts from './parts/SocialPosts';
import { useSocialPostsData } from './hooks/useSocialPostsData';
import SocialPostsPageHeader from './parts/SocialPostPageHeader/GenerationControls/SocialPostsPageHeader';
import { Helmet } from 'react-helmet-async';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';

const SocialPostsPage: FC = () => {
  const {
    socialPost,
    socialPostsState,
    isLoading,
    isSavingContent,
    canSaveContent,
    isError,
    loadContentById,
    handleContentChange,
    handleVideoDescriptionChange,
    handleContentRegenerate,
    handleContentSave,
  } = useSocialPostsData();

  return (
    <SocialPostsWrapper>
      <Helmet title={socialPost?.title || ''} />
      <SocialPostsPageHeader
        onSave={handleContentSave}
        onPostContentVersionLoad={loadContentById}
        onRegenerate={handleContentRegenerate}
        socialPost={socialPost}
        canSave={canSaveContent}
        isSaving={isSavingContent}
        isError={isError}
      />
      <Gap size={24} />
      <MainGrid minContent marginBottom={80}>
        {isError ? (
          <EmptyDataNotice
            title="Something went wrong"
            description="Could not load social post"
          />
        ) : (
          <SocialPostsContainer padding={'0'}>
            <SocialPosts
              socialPostState={socialPostsState}
              socialPost={socialPost}
              handleContentChange={handleContentChange}
              handleVideoDescriptionChange={handleVideoDescriptionChange}
              handleContentRegenerate={handleContentRegenerate}
              isLoading={isLoading}
            />
          </SocialPostsContainer>
        )}
      </MainGrid>
      <ContentFooter />
    </SocialPostsWrapper>
  );
};

export default SocialPostsPage;
