import Typography from 'app/components/Typography';
import { MAX_ITEMS_TO_MULTI_GENERATE } from 'app/containers/Pitch/pages/PitchPage/PitchPage.constants';
import { FC } from 'react';

const DefaultSelectionText: FC = () => {
  return (
    <Typography.Text $colorName="onyx" $lineHeight={16} $size={14}>
      Select up to{' '}
      <Typography.Text $bold>
        {MAX_ITEMS_TO_MULTI_GENERATE} Journalists
      </Typography.Text>{' '}
    </Typography.Text>
  );
};

export default DefaultSelectionText;
