import EmptyDataNotice from '../../../components/EmptyDataNotice/EmptyDataNotice';
import { userPermissionEnum } from './userPermissions.enum';
import { FC } from 'react';

interface IProps {
  require?: userPermissionEnum;
}

const DefaultFallback: FC<IProps> = props => {
  return (
    <EmptyDataNotice
      title="You do not have access to see this."
      description={`Required permission to see this is "${
        props.require || 'UNKNOWN'
      }"`}
    />
  );
};

export default DefaultFallback;
