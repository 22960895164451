import { SVGProps } from 'react';

const ArrowUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#737377" transform="translate(-917 -331)">
        <g transform="translate(260 72)">
          <g transform="translate(40 225)">
            <g transform="translate(500 24)">
              <g transform="translate(32 8)">
                <g transform="translate(85 2)">
                  <path
                    d="M10.532 7.32L6.65 11.135a.93.93 0 01-1.298 0L1.468 7.32a.89.89 0 010-1.272.927.927 0 011.295 0l2.321 2.281V1.5c0-.497.41-.9.916-.9s.916.403.916.9v6.828l2.321-2.282a.927.927 0 011.295.001.89.89 0 010 1.272z"
                    transform="matrix(1 0 0 -1 0 12)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowUpIcon;
