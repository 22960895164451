function EnvelopeBigIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#0C0C0D" transform="translate(-1066 -325)">
          <g transform="translate(834 281)">
            <g transform="translate(212 24)">
              <g transform="translate(20 20)">
                <path d="M42.94 9.6H5.06c-2.132 0-3.86 1.742-3.86 3.892v23.416c0 2.15 1.728 3.892 3.86 3.892h37.88c2.132 0 3.86-1.742 3.86-3.892V13.492c0-2.15-1.728-3.892-3.86-3.892zm-2.117 3.284L24 25.611 7.177 12.884h33.646z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EnvelopeBigIcon;
