import { useCallback, useState } from 'react';

import { validateEmail } from 'utils/helpers';
import { validatePhoneField } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/parts/Socials/Socials.helpers';
import { ICreateUserPayload } from 'app/api.types';

const DEFAULT_USER_FIELDS = {
  firstName: '',
  lastName: '',
  email: '',
  mobilePhone: '',
};

export type UserField = keyof typeof DEFAULT_USER_FIELDS;

export const useUserValidation = (user: ICreateUserPayload) => {
  const [fieldsValidation, setFieldsValidation] = useState(DEFAULT_USER_FIELDS);

  const validateUserField = useCallback(
    (field: UserField) => {
      if (field === 'email') {
        const isValidEmail = validateEmail(user?.email?.trim() || '');
        if (!isValidEmail) {
          setFieldsValidation(validations => ({
            ...validations,
            email: 'Enter a valid email address',
          }));
        }
      }

      if (field === 'mobilePhone') {
        const phoneNumber = user?.mobilePhone?.trim() || '';

        if (!phoneNumber) {
          setFieldsValidation(validations => ({
            ...validations,
            mobilePhone: 'Enter a phone number',
          }));
        }

        const isValidPhoneNumber = validatePhoneField(phoneNumber);
        if (!isValidPhoneNumber) {
          setFieldsValidation(validations => ({
            ...validations,
            mobilePhone: 'Enter a valid phone number',
          }));
        }
      }

      if (field === 'firstName') {
        const trimmedFirstName = user?.firstName?.trim() || '';
        if (trimmedFirstName.length < 2 || trimmedFirstName.length > 50) {
          setFieldsValidation(validations => ({
            ...validations,
            firstName: 'Name must be between 2 and 50 characters',
          }));
        }
      }

      if (field === 'lastName') {
        const trimmedLastName = user?.lastName?.trim() || '';
        if (trimmedLastName.length < 2 || trimmedLastName.length > 50) {
          setFieldsValidation(validations => ({
            ...validations,
            lastName: 'Name must be between 2 and 50 characters',
          }));
        }
      }
    },
    [user],
  );

  const resetFieldValidation = useCallback((field: UserField) => {
    setFieldsValidation(validations => ({
      ...validations,
      [field]: '',
    }));
  }, []);

  const resetAllValidationFields = useCallback(() => {
    setFieldsValidation(DEFAULT_USER_FIELDS);
  }, []);

  return {
    fieldsValidation,
    validateUserField,
    resetFieldValidation,
    resetAllValidationFields,
  };
};
