import {
  ITenant,
  IPaginationRequestPart,
  IUpdateUserPayload,
  IUser,
} from '../../../../api.types';
import { formatDate } from '../../../../../utils/helpers';
import { IEmptyDataNoticeProps } from '../../../../components/EmptyDataNotice/EmptyDataNotice';
import { IDataTableColumn } from '../../../../components/Table/Table.types';
import { Toggle, ToggleCheckbox, ToggleSwitch } from './PeopleList.styles';
import { useUpdateUserMutation } from 'app/api';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { UserStatusEnum } from 'app/containers/Global/slice/types';
import { useMemo } from 'react';
import { Spinner } from 'app/components/UI/Spinner';
import FlexBlock from 'app/components/UI/FlexBlock';
import { useAppSelector } from 'store/hooks';
import { selectUser } from 'app/containers/Global/slice/selectors';
import { getOrbitTooltipProps } from 'app/components/UI/Tooltip/Tooltip';
import { logger } from 'utils/apm/logger';

export const defaultUsersPaginationSettings: IPaginationRequestPart = {
  page: { page: 0, size: 10 },
  sort: [{ ascending: true, property: 'firstName' }],
};

export const defaultGroupsPaginationSettings: IPaginationRequestPart = {
  page: { page: 0, size: 10 },
  sort: [{ ascending: true, property: 'name' }],
};

export const defaultJournalistsPaginationSettings: IPaginationRequestPart = {
  page: { page: 0, size: 10 },
  sort: [{ ascending: true, property: 'journalistName' }],
};

export const usersTableColumnConfig: IDataTableColumn<IUser>[] = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    id: 'firstName',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    id: 'lastName',
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    id: 'email',
  },
  {
    Header: 'User Role',
    accessor: 'role',
    id: 'role',
    Cell: ({ row }) => (row.original.role ? row.original.role.displayName : ''),
  },
  {
    Header: 'Date Added',
    accessor: 'createdAt',
    id: 'createdAt',
    Cell: ({ row }) => formatDate(row.original.createdAt),
  },
  {
    Header: 'Last Login',
    accessor: 'lastLoginTime',
    id: 'lastLoginTime',
    disableSort: true,
    Cell: ({ row }) => formatDate(row.original.lastLoginTime),
  },
  {
    Header: 'Status',
    id: 'status',
    //@ts-ignore
    Cell: ({ row }) => {
      const [updateUser, { isLoading }] = useUpdateUserMutation();

      const currentUser = useAppSelector(selectUser);

      const currentUserId = currentUser?.id;

      const isInspectingOwnProfile = useMemo(() => {
        return currentUserId === row.original.id;
      }, [currentUserId, row.original.id]);

      const tooltip = useMemo(() => {
        if (isInspectingOwnProfile) {
          return getOrbitTooltipProps('Cannot disable your own account');
        }

        return undefined;
      }, [isInspectingOwnProfile]);

      const isActiveUser = useMemo(() => {
        if (!row.original || !row.original.statusType) return false;
        return row.original.statusType.code === UserStatusEnum.ACTIVE;
      }, [row.original]);

      const getUserPayload = (
        user: IUser,
        status: UserStatusEnum,
      ): IUpdateUserPayload => {
        return {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          avatar: user.avatar,
          email: user.email,
          mobilePhone: user.mobilePhone,
          status,
          tenantId: user.tenant?.id || undefined,
          role: user.role.code,
        };
      };

      const handleUserStatusChange = async (user: IUser) => {
        if (user && user.id) {
          try {
            const updatedStatus =
              user.statusType.code === UserStatusEnum.ACTIVE
                ? UserStatusEnum.DISABLED
                : UserStatusEnum.ACTIVE;

            const updateUserPayload = getUserPayload(user, updatedStatus);
            const updatedUser = await updateUser(updateUserPayload).unwrap();
            showSuccessToast(
              updatedUser.statusType?.code === UserStatusEnum.ACTIVE
                ? 'User activated.'
                : 'User de-activated.',
            );
          } catch (error) {
            showErrorToast(
              getErrorText(error as FetchBaseQueryError),
              'Could not update user status.',
            );
          }
        } else {
          showErrorToast('Could not update user status.');
          logger.logError(
            'Users List - Update user - Missing User object or ID',
          );
          return;
        }
      };

      if (isLoading) {
        return (
          <FlexBlock justifyContent="flex-start" alignItems="center" margin="0">
            <Spinner $isBlue $margin="0" />
          </FlexBlock>
        );
      }

      return (
        <Toggle
          onClick={e => {
            e.stopPropagation();
            if (isInspectingOwnProfile) return;
            if (isLoading) return;
            handleUserStatusChange(row.original);
          }}
        >
          <ToggleCheckbox
            defaultChecked={isActiveUser}
            disabled={isInspectingOwnProfile}
          />
          <ToggleSwitch {...tooltip} />
        </Toggle>
      );
    },
    disableSort: true,
  },
];

export const groupsTableColumnConfig: IDataTableColumn<ITenant>[] = [
  {
    Header: 'Tenant Name',
    accessor: 'name',
    id: 'name',
  },
  {
    Header: 'Projects',
    accessor: 'projectCount',
    id: 'projectCount',
    disableSort: true,
  },
  {
    Header: 'Date Added',
    accessor: 'createdAt',
    id: 'createdAt',
    Cell: ({ row }) => formatDate(row.original.createdAt),
  },
];

export const noDataNoticeConfig: IEmptyDataNoticeProps = {
  title: 'No Users',
  description: 'No users here.',
  buttonText: 'Add Users',
};
