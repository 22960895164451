import {
  IApiResponse,
  ILegacyApiResponse,
  IPaginationRequestPart,
} from '../app/api.types';

/**
 * Transforms legacy response to v2 responses model.
 * Not all values are functional because of information lack in legacy controllers
 *
 * Functional values:
 * response.content;
 * response.pageable.pageNumber;
 * response.pageable.pageSize;
 * response.totalElements;
 * response.totalPages (simulated);
 * response.first (simulated);
 * response.numberOfElements;
 * response.size;
 * */
export function transformLegacyResponse<DataItemType>(
  input: ILegacyApiResponse<DataItemType>,
): IApiResponse<DataItemType> {
  const sort = {
    unsorted: false,
    sorted: false,
    empty: false,
  };
  return {
    content: input.data,
    pageable: {
      sort,
      pageNumber: input.pageInfo.curPage,
      pageSize: input.pageInfo.pageSize,
      offset: 0,
      paged: false,
      unpaged: false,
    },
    last: false,
    totalElements: input.pageInfo.total,
    totalPages: Math.ceil(input.pageInfo.total / input.pageInfo.pageSize),
    sort,
    number: input.pageInfo.curPage,
    first: Boolean(input.pageInfo.curPage === 1),
    numberOfElements: input.pageInfo.total,
    size: input.pageInfo.pageSize,
    empty: false,
  };
}

type TransformPaginationPartFn = (pagination: IPaginationRequestPart) => {
  page: string | number;
  size: string | number;
  sort: string | undefined;
};

export const transformPaginationPart: TransformPaginationPartFn =
  pagination => {
    return {
      page: pagination.page.page,
      size: pagination.page.size,
      sort: pagination.sort[0]
        ? `${pagination.sort[0]?.property},${
            pagination.sort[0]?.ascending ? 'asc' : 'desc'
          }`
        : undefined,
    };
  };
