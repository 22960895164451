/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { RootRoutes } from './routes';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalSlice } from './containers/Global/slice';
import AuthWrapper from './containers/Global/parts/AuthWrapper';
import ReduxRedirectWrapper from './containers/Global/parts/ReduxRedirectWrapper';
import { AvatarConfigProvider } from './components/UI/Avatar';
import NeedHelpModal from './components/NeedHelpModal/NeedHelpModal';
import { PromptModalProvider } from './containers/Global/parts/PromptModal/PrompModalProvider';
import useDevTools from '../utils/hooks/useDevTools';

export const App = () => {
  useGlobalSlice();
  useDevTools();

  return (
    <BrowserRouter>
      <AvatarConfigProvider>
        <ReduxRedirectWrapper>
          <AuthWrapper>
            <PromptModalProvider>
              <Helmet
                titleTemplate="%s - Prophet Platform"
                defaultTitle="Prophet Platform"
              >
                <meta
                  name="description"
                  content="A React Boilerplate application"
                />
              </Helmet>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <RootRoutes />
              <GlobalStyle />
              <NeedHelpModal />
              {/* <StripeModal /> */}
            </PromptModalProvider>
          </AuthWrapper>
        </ReduxRedirectWrapper>
      </AvatarConfigProvider>
    </BrowserRouter>
  );
};
