import { IPitchMediaFilter } from 'app/api.types';
import { IFilterOptions } from './FiltersPanel';

export const filtersEqual = (
  selectedFilters: IFilterOptions,
  activeFilters: Pick<
    IFilterOptions,
    'countries' | 'states' | 'outletScopeFilter' | 'relevant'
  >,
) => {
  const isCountriesEqual = compareFilterArrays(
    selectedFilters.countries,
    activeFilters.countries,
  );
  const isStatesEqual = compareFilterArrays(
    selectedFilters.states,
    activeFilters.states,
  );
  const isInclusionsEqual = compareFilterArrays(
    selectedFilters.outletScopeFilter.inclusions,
    activeFilters.outletScopeFilter.inclusions,
  );
  const isExclusionsEqual = compareFilterArrays(
    selectedFilters.outletScopeFilter.exclusions,
    activeFilters.outletScopeFilter.exclusions,
  );
  const isRelevantEqual = selectedFilters.relevant === activeFilters.relevant;

  return (
    isCountriesEqual &&
    isStatesEqual &&
    isInclusionsEqual &&
    isExclusionsEqual &&
    isRelevantEqual
  );
};

const compareFilterArrays = (
  firstArray: IPitchMediaFilter[],
  secondArray: IPitchMediaFilter[],
) => {
  if (!firstArray || !secondArray) {
    return false;
  }

  const firstArrayLength = firstArray.length;
  const secondArrayLength = secondArray.length;

  if (firstArrayLength !== secondArrayLength) {
    return false;
  }

  const firstFilterCodes = firstArray.map(filter => filter.code);
  const secondFilterCodes = secondArray.map(filter => filter.code);

  return firstFilterCodes.every(code => secondFilterCodes.includes(code));
};
