import { IPreparedReportItem } from '../../MediaReportCard/MediaReportCard.helpers';
import { FC, useMemo } from 'react';
import { IndicatorDot } from '../../../../../../../components/UI/IndicatorDot';
import { SentimentTypeEnum } from '../../../../../../../api.types';

interface IProps {
  row: IPreparedReportItem;
}

enum sentimentColors {
  'red' = '#ca3032',
  'yellow' = '#fdb515',
  'green' = '#00d377',
}

const SentimentCell: FC<IProps> = props => {
  const color: string | null = useMemo(() => {
    if (!props.row.sentimentType) return null;

    if (props.row.sentimentType === SentimentTypeEnum.POSITIVE)
      return sentimentColors.green;

    if (props.row.sentimentType === SentimentTypeEnum.NEGATIVE)
      return sentimentColors.red;

    return sentimentColors.yellow;
  }, [props.row]);

  if (!color) return <></>;

  return <IndicatorDot color={color} />;
};

export default SentimentCell;

// NEGATIVE = RED (#ca3032),
// POSITIVE = GREEN (#00d377),
// MIXED = ORANGE (#FF8D1A),
// NEUTRAL = ORANGE (#FF8D1A),
//
