import { IPitchPrediction } from 'app/api.types';

export function getTotalSamplesFromPredictionReports(
  prediction: IPitchPrediction,
) {
  if (!prediction || !prediction.reports || !prediction.reports.length) {
    return 0;
  }

  return prediction.reports.reduce(
    (acc, report) => acc + report.totalSamples,
    0,
  );
}
