import { request } from '../../../../../utils/request';

export async function downloadCsvExport(
  pitchId: number,
  version: number,
  pitchType: 'PITCH' | 'PRESS_RELEASE',
  mediaListIds: number[],
) {
  return request({
    method: 'POST',
    url: `/media-list/export`,
    data: {
      pitchId,
      version,
      pitchType,
      mediaListIds,
    },
    responseType: 'blob',
  });
}
