import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import * as S from './CreateFirstPitch.styles';
import { IProject, IGeneratedContentResponse } from 'app/api.types';
import ContentForm from './parts/ContentForm';

import { TONE_SELECT_OPTIONS } from '../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import { useAppDispatch } from 'store/hooks';
import { globalActions } from 'app/containers/Global/slice';
import { RouteConstants } from 'app/routes';
import { useGetTenantsQuery, useGetUserProfileQuery } from 'app/api';
import PageLoader from 'app/components/UI/PageLoader';
import useGetCurrentUserPlan from 'app/containers/Global/permissions/useGetCurrentUserPlan.hook';
import { Redirect } from 'react-router-dom';
import { IPitchContent } from '../CreateOrEditPitch/CreateOrEditPitch.types';

export interface IFirstPitchFormState {
  content: {
    showGenerated: boolean;
    generatedContent: null | IGeneratedContentResponse;
    originalContent: null | IGeneratedContentResponse;
  };
  project: null | IProject;
}

const CreateFirstPitch: FC = () => {
  const [formData, setFormData] = useState<IFirstPitchFormState>({
    content: {
      showGenerated: false,
      generatedContent: null,
      originalContent: null,
    },
    project: null,
  });

  const [tone, setTone] = useState(TONE_SELECT_OPTIONS[0]);

  const [tenantId, setTenantId] = useState<null | number>(null);

  const dispatch = useAppDispatch();

  const { isFreeOrFreemiumPlan, isProOrProV2Plan } = useGetCurrentUserPlan();

  const isFreeOrProUser = isFreeOrFreemiumPlan || isProOrProV2Plan;

  const {
    data: userFlag,
    isLoading: isLoadingUserFlag,
    isError: isErrorUserFlag,
  } = useGetUserProfileQuery(
    { firstLoginFlag: true },
    {
      skip: !isFreeOrProUser,
    },
  );

  useEffect(() => {
    if ((!userFlag || !userFlag?.firstTimeLogin) && !isLoadingUserFlag) {
      dispatch(
        globalActions.authorizeUser({
          redirectUrl: RouteConstants.projects.list,
        }),
      );
    }
  }, [userFlag, isLoadingUserFlag, dispatch]);

  const {
    data: tenantsData,
    isLoading: isLoadingTenants,
    isError: isTenantsError,
  } = useGetTenantsQuery({
    tenantStatus: 'ACTIVE',
    pagination: { page: { page: 0, size: 1 }, sort: [] },
  });

  useEffect(() => {
    if (isTenantsError || isErrorUserFlag) {
      dispatch(
        globalActions.authorizeUser({
          redirectUrl: RouteConstants.projects.list,
        }),
      );
    }
  }, [isTenantsError, isErrorUserFlag, dispatch]);

  useEffect(() => {
    if (tenantsData && tenantsData.content && tenantsData.content[0]) {
      setTenantId(tenantsData.content[0].id);
    }
  }, [tenantsData]);

  const showGeneratedResults = useMemo(() => {
    if (
      formData.content.generatedContent &&
      formData.content.showGenerated &&
      formData.project &&
      formData.project.id
    )
      return true;
    return false;
  }, [
    formData.content.generatedContent,
    formData.content.showGenerated,
    formData.project,
  ]);

  const newPitchRouteParamaters = useMemo(() => {
    if (!tenantId || !formData.project || !formData.project.id) return null;

    return RouteConstants.pitch.makeCreatePitchUrl(
      tenantId,
      formData.project.id,
    );
  }, [formData.project, tenantId]);

  const generatedContentArray = useMemo(() => {
    const content = [];

    if (!formData.content) return [];

    const { originalContent, generatedContent } = formData.content;

    if (originalContent) {
      content.push({
        contentName: 'original',
        headline: '',
        body: originalContent.articleByline,
      } as IPitchContent);
    }

    if (generatedContent) {
      content.push({
        contentName: 'generated',
        headline: generatedContent.generatedTitle,
        body: generatedContent.articleByline,
        socialPosts: {
          twitterPost: generatedContent.twitterPost || '',
          facebookPost: generatedContent.facebookPost || '',
          instagramPost: generatedContent.instagramPost || '',
          linkedinPost: generatedContent.linkedinPost || '',
        },
      } as IPitchContent);
    }

    return content;
  }, [formData.content]);

  if (isLoadingUserFlag || isLoadingTenants) return <PageLoader />;

  return (
    <>
      <Helmet title={'Welcome to PRophet'} />
      <S.PageWrapper>
        <S.ContentWrapper>
          {tenantId && (
            <S.PitchContent>
              {showGeneratedResults && newPitchRouteParamaters ? (
                <Redirect
                  to={{
                    pathname: newPitchRouteParamaters,
                    state: {
                      tone,
                      content: generatedContentArray,
                      project: formData.project,
                    },
                  }}
                />
              ) : (
                <ContentForm
                  setData={setFormData}
                  tenantId={tenantId}
                  tone={tone}
                  setTone={setTone}
                />
              )}
            </S.PitchContent>
          )}
        </S.ContentWrapper>
      </S.PageWrapper>
    </>
  );
};

export default CreateFirstPitch;
