import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Tabs from 'app/components/UI/Tabs';
import { useState } from 'react';
import { IPodcastDemographics } from 'app/api.types';

import * as S from './PodcastDemographic.styles';
import {
  renderValueString,
  capitalizeFirstLetter,
  getDefaultActiveTab,
} from './PodcastDemographic.helpers';
import { useDemographicInformation } from './hooks/useDemographicInformation';

const PodcastDemographicsEntry = ({
  title,
  value,
  measurementLabel = '',
  icon,
}: {
  title: string;
  value: string | string[];
  measurementLabel?: string;
  icon?: JSX.Element;
}) => {
  return (
    <S.DetailsContainer alignItems={Array.isArray(value)}>
      {Array.isArray(value) ? (
        <>
          <Typography.Text $size={16} $dmSans $bold $colorName="onyx">
            {title}
          </Typography.Text>
          <FlexBlock flexDirection="column" alignItems="end">
            {value.map(element => (
              <Typography.Text
                key={element}
                textAlign="right"
                $colorName="onyx"
              >
                {element}
              </Typography.Text>
            ))}
          </FlexBlock>
        </>
      ) : (
        <>
          <FlexBlock alignItems="center" columnGap="12px">
            {icon && icon}
            <Typography.Text $size={16} $colorName="onyx">
              {title}
            </Typography.Text>
          </FlexBlock>

          {measurementLabel ? (
            <FlexBlock flexDirection="column" alignItems="center">
              <Typography.Text $dmSans $bold $size={20} $colorName="onyx">
                {value}
              </Typography.Text>
              <Typography.Text $size={14} $lineHeight={16} $colorName="onyx">
                {measurementLabel}
              </Typography.Text>
            </FlexBlock>
          ) : (
            <Typography.Text $dmSans $bold $size={20} $colorName="onyx">
              {value}
            </Typography.Text>
          )}
        </>
      )}
    </S.DetailsContainer>
  );
};

const PodcastDemographic = ({
  demographicsData,
}: {
  demographicsData: IPodcastDemographics;
}) => {
  const demographics = useDemographicInformation(demographicsData);

  const defaultActiveTab = getDefaultActiveTab(demographics);

  const [activePodcastDemographicsTab, setActivePodcastDemographicsTab] =
    useState<'breakdown' | 'topSegments' | 'geographies'>(defaultActiveTab);

  return (
    <>
      <S.PodcastInformationWrapper>
        <Tabs.Bar>
          {demographics?.breakdown && demographics.breakdown.length > 0 && (
            <Tabs.Tab
              active={activePodcastDemographicsTab === 'breakdown'}
              onClick={() => setActivePodcastDemographicsTab('breakdown')}
            >
              Breakdown
            </Tabs.Tab>
          )}
          {demographics?.geographies && demographics.geographies.length > 0 && (
            <Tabs.Tab
              active={activePodcastDemographicsTab === 'geographies'}
              onClick={() => setActivePodcastDemographicsTab('geographies')}
            >
              Geographies
            </Tabs.Tab>
          )}
          {demographics?.segments && demographics.segments.length > 0 && (
            <Tabs.Tab
              active={activePodcastDemographicsTab === 'topSegments'}
              onClick={() => setActivePodcastDemographicsTab('topSegments')}
            >
              Top Segments
            </Tabs.Tab>
          )}
        </Tabs.Bar>
      </S.PodcastInformationWrapper>
      {activePodcastDemographicsTab === 'breakdown' && (
        <>
          {demographics?.breakdown.map(element => (
            <PodcastDemographicsEntry
              key={element?.meta.cardTitle}
              title={element?.meta.cardTitle}
              icon={element?.meta.image}
              measurementLabel={
                typeof element.data !== 'number'
                  ? capitalizeFirstLetter(element.data.title)
                  : ''
              }
              value={renderValueString(
                element?.data,
                element?.meta.valueSuffix,
              )}
            />
          ))}
        </>
      )}
      {activePodcastDemographicsTab === 'geographies' && (
        <>
          {demographics?.geographies
            .filter(element => element && element.data)
            .map(element => {
              return (
                <PodcastDemographicsEntry
                  key={element?.meta.cardTitle}
                  title={element?.meta.cardTitle}
                  value={element.data.map(element => element.title)}
                />
              );
            })}
        </>
      )}
      {activePodcastDemographicsTab === 'topSegments' && (
        <>
          {demographics?.segments.map(element => {
            return (
              <PodcastDemographicsEntry
                key={element?.meta.cardTitle}
                title={element?.meta.cardTitle}
                value={element.data.map(element => element.title)}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default PodcastDemographic;
