import { MainGridSection } from 'app/components/UI/MainGrid/MainGrid.styles';
import { PitchPageContext } from './PitchPage.context';
import { FC, useContext } from 'react';
import { MultiGenerationResults } from './parts/MultiGenerationResults/MultiGenerationResults';
import MediaReportCard from './parts/MediaReportCard/MediaReportCard';
import { IReportV31 } from 'app/api.types';
import { FavoriteJournalistTooltip } from 'app/containers/Journalists/pages/parts/Favorites/FavoritesTooltip';
import { useParams } from 'react-router-dom';

interface IProps {
  reports: IReportV31[];
  predictionId: number;
  isPredictionFetching: boolean;
}

const PitchPageContent: FC<IProps> = ({
  reports,
  predictionId,
  isPredictionFetching,
}) => {
  const { isRapidGenerationResultsView } = useContext(PitchPageContext);

  const { pitchId, pressReleaseId } = useParams<{
    pitchId: string;
    pressReleaseId: string;
  }>();

  const predictedContentId = Number(pitchId) || Number(pressReleaseId);

  return (
    <MainGridSection start="1" end="-1">
      <MultiGenerationResults />
      {!isRapidGenerationResultsView &&
        reports.map((report, idx) => (
          <MediaReportCard
            initialReport={report}
            key={report.id}
            pitchId={predictedContentId}
            predictionId={predictionId}
            reportId={report.id}
            isReportExpanded={true}
            isPredictionFetching={isPredictionFetching}
          />
        ))}
      <FavoriteJournalistTooltip />
    </MainGridSection>
  );
};

export default PitchPageContent;
