import { IPreparedReportItem } from './MediaReportCard.helpers';
import { IOption } from '../../../../../../../types/commonTypes';
import { IDataTableColumn } from '../../../../../../components/Table/Table.types';

// @ts-ignore
export const getMediaReportColumnConfig: (
  sortByOutlet?: boolean,
  isPodcast?: boolean,
) => IDataTableColumn<IPreparedReportItem>[] = (byOutlet, isPodcast) => {
  return [
    {
      id: 'parent_source',
      Header: 'Source',
      columns: [
        {
          id: 'reportItem.mediaOutlet.name',
          accessor: 'reportItem.mediaOutlet.name',
          Header: 'Primary Outlet',
        },
        {
          id: 'reportItem.author.name',
          accessor: 'reportItem.author.name',
          Header: 'Journalist',
        },
        {
          id: 'classifiedCategories',
          Header: 'Beat',
        },
        {
          id: 'umw',
          Header: 'UMW',
          Cell: () => <>UMW</>,
        },
      ],
    },
    {
      id: 'parent_contacts',
      Header: 'Contacts',
      columns: [
        {
          id: 'reportItem.author.email',
          accessor: 'reportItem.author.email',
          Header: 'Email',
        },
        {
          id: 'social',
          Header: 'Journalist',
          Cell: () => <>SOCIALS</>,
        },
      ],
    },
    {
      id: 'parent_interest',
      Header: 'Interest',
      collapsable: true,
      columns: [
        {
          id: 'reportItem.certainty',
          accessor: 'reportItem.certainty',
          Cell: () => <>00 %</>,
          Header: 'Overall',
          doNotCollapse: true,
        },
        {
          id: 'category',
          Cell: () => <>00 %</>,
          Header: 'Category',
        },
        {
          id: 'productivity',
          Cell: () => <>00 %</>,
          Header: 'Productivity',
        },
        {
          id: 'focus',
          Cell: () => <>00 %</>,
          Header: 'Focus',
        },
        {
          id: 'keyword',
          Cell: () => <>00 %</>,
          Header: 'Keyword',
        },
        {
          id: 'sentiment',
          Cell: () => <>00 %</>,
          Header: 'Sentiment',
          doNotCollapse: true,
        },
      ],
    },
    {
      id: 'parent_feedback',
      Header: 'Feedback',
      columns: [
        {
          id: 'feedback',
          Cell: () => <>+</>,
          Header: 'Feedback',
        },
      ],
    },
  ].filter(col => !isPodcast || col.id !== 'sentiment');
};

export interface ISortOption extends IOption<'score' | 'outlet'> {}
export interface ILimitOption extends IOption<number> {
  hidden?: boolean; // if true, is not seen in select dd
  last?: boolean; // if true, hides "see more" button
  order: number; // for selecting next one then see more is clicked
}

export const groupByOptions: ISortOption[] = [
  { value: 'score', label: 'Score' },
  { value: 'outlet', label: 'Outlet' },
];

export const limitOptionsOf100: ILimitOption[] = [
  { value: 25, label: 'Top 25', order: 1 },
  { value: 50, label: 'Top 50', order: 2 },
  { value: 100, label: 'Top 100', order: 3, last: true },
];

export const limitOptionsOf300: ILimitOption[] = [
  { value: 25, label: 'Top 25', order: 1 },
  { value: 50, label: 'Top 50', order: 2 },
  { value: 100, label: 'Top 100', order: 3 },
  { value: 200, label: 'Top 200', order: 4 },
  { value: 300, label: 'Top 300', order: 5, last: true },
];

export const getLimitOptionsByTotalItemsCount = (
  totalItems: number,
): ILimitOption[] => {
  if (totalItems === 300) return limitOptionsOf300;
  return limitOptionsOf100;
};

export const getLimitOptionsByLength = (length?: number): ILimitOption[] => {
  if (!length) return [];

  return limitOptionsOf100
    .filter(option => option.value <= length)
    .map((option, index, arr) => ({
      ...option,
      last: index + 1 === arr.length,
    }));
};
