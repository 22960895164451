import { TONE_SELECT_OPTIONS } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import {
  DEFAULT_PRESS_RELEASE_CONTENT_STATE,
  DEFAULT_PRESS_RELEASE_STATE,
} from '../CreateOrEditPressRelease.constants';
import { PressReleaseState } from './PressReleaseContext.types';

export const INITIAL_PRESS_RELEASE_STATE: PressReleaseState = {
  pressRelease: DEFAULT_PRESS_RELEASE_STATE,
  pressReleaseContents: [{ ...DEFAULT_PRESS_RELEASE_CONTENT_STATE }],
  selectedPressReleaseContent: null,
  selectedCustomMediaLists: [],
  prediction: null,
  tone: TONE_SELECT_OPTIONS[0],
  pressReleaseProcessStatus: null,
  settingsOpen: false,
  versionsOpen: false,
  socialsTabOpen: false,
  contentVersions: [],
};
