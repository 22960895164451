import { FC, ReactNode } from 'react';
import * as S from './EmptyDataNotice.styles';
import EmptyFolderImage from 'app/assets/images/icon-none.png';
import Typography from '../Typography';
import Button from '../UI/Button/Button';
import { NavLink } from 'react-router-dom';
import SearchEmptyImage from 'app/assets/images/search.png';

export interface IEmptyDataNoticeProps {
  title: string;
  description?: string;
  buttonText?: string;
  buttonLinkTo?: string;
  image?: string | null;
  buttonNode?: ReactNode;
}

const EmptyDataNotice: FC<IEmptyDataNoticeProps> = props => {
  return (
    <S.Wrapper>
      <S.Image
        src={props?.image ? SearchEmptyImage : EmptyFolderImage}
        alt="No Data"
      />
      <S.TextBlock>
        <Typography.Text $dmSans $bold $size="32" $lineHeight="40">
          {props.title}
        </Typography.Text>
        {Boolean(props.description) && (
          <Typography.Text $size="16" $colorName="steel">
            {props.description}
          </Typography.Text>
        )}
      </S.TextBlock>

      {Boolean(props.buttonLinkTo && props.buttonText) && (
        <NavLink to={props.buttonLinkTo || ''}>
          <Button variant="secondary">{props.buttonText}</Button>
        </NavLink>
      )}
      {props.buttonNode && props.buttonNode}
    </S.Wrapper>
  );
};

export default EmptyDataNotice;
