import { FC, useMemo } from 'react';
import { BackButton } from '../Toolbar.styles';
import ArrowLeftIcon from '../../../../../assets/icons/ArrowLeftIcon';
import Typography from '../../../../Typography';
import Pill from '../../../../UI/Pill';
import { useHistory } from 'react-router-dom';
import Gap from '../../../../UI/Gap';
import { useGetPitchQuery } from '../../../../../api';
import { usePitchSlice } from '../../../../../containers/Pitch/slice';
import { useAppDispatch } from '../../../../../../store/hooks';

interface IProps {
  pitchData: {
    versionId?: number;
    page?: 'details' | 'create' | 'edit';
    pitchId?: number;
  } | null;
}

const PitchDetailsHeader: FC<IProps> = ({ pitchData }) => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { actions } = usePitchSlice();

  const text = useMemo(() => {
    if (pitchData?.page === 'edit') return 'Edit Pitch';
    if (pitchData?.page === 'create') return 'New Pitch';
    return 'Pitch';
  }, [pitchData]);

  const { data } = useGetPitchQuery(
    { pitchId: pitchData?.pitchId },
    { skip: !Boolean(pitchData?.pitchId) },
  );

  const toggleVersionDrawer = () => {
    dispatch(actions.toggleVersionDrawer(true));
  };

  return (
    <>
      <BackButton onClick={history.goBack}>
        <ArrowLeftIcon />
      </BackButton>
      <Typography.Text $colorName="white" $dmSans $bold $size={20}>
        {text}
      </Typography.Text>
      <Gap size="18" />
      {pitchData?.page !== 'create' && (
        <Pill
          variant="pitch-version"
          pitchVersion={data?.status}
          onClick={toggleVersionDrawer}
        >
          V{pitchData?.versionId}
        </Pill>
      )}
    </>
  );
};

export default PitchDetailsHeader;
