import { ToneValue } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';
import { RouteConstants } from 'app/routes';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ISocialPost } from '../../SocialPostsPage/types/SocialPost.types';

export const useNavigateToContent = () => {
  const history = useHistory();

  const { tenantId, projectId } = useParams<{
    projectId: string;
    tenantId: string;
  }>();

  const goToProjectContentPage = useCallback(() => {
    if (tenantId && projectId) {
      history.push(
        RouteConstants.projects.makeContentUrl(
          Number(tenantId),
          Number(projectId),
        ),
      );
    }
  }, [history, projectId, tenantId]);

  const goToSocialPostPage = useCallback(
    (socialPostId: number, tone?: ToneValue) => {
      if (tenantId && projectId && socialPostId) {
        const routeState = tone ? { tone } : undefined;
        history.push(
          RouteConstants.socialPosts.makeSocialPostsUrl(
            Number(tenantId),
            Number(projectId),
            Number(socialPostId),
          ),
          routeState,
        );
      }
    },
    [history, projectId, tenantId],
  );

  const goToCreateSocialPostPage = useCallback(
    (socialPost?: ISocialPost) => {
      if (tenantId && projectId) {
        history.push(
          RouteConstants.socialPosts.makeCreateSocialPostsUrl(
            Number(tenantId),
            Number(projectId),
          ),
          socialPost,
        );
      }
    },
    [history, projectId, tenantId],
  );

  return {
    goToProjectContentPage,
    goToSocialPostPage,
    goToCreateSocialPostPage,
    projectId: Number(projectId),
  };
};
