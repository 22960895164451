import Typography from 'app/components/Typography';
import { Anchor } from 'app/components/UI/Anchor';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';
import { NEWS_WIRE_LINK } from 'utils/constants';

export const NewsWireNavigationLink: FC = () => {
  return (
    <Anchor
      href={NEWS_WIRE_LINK}
      target="_blank"
      style={{ textDecoration: 'none' }}
    >
      <FlexBlock flexDirection="column" rowGap="4px">
        <Typography.Text
          $colorName="onyx"
          $size={16}
          $lineHeight={24}
          $dmSans
          $bold
        >
          Distribute
        </Typography.Text>
        <Typography.Text $colorName="steel" $size={14} $lineHeight={16}>
          Easily distribute a press release to media outlets through EZ Newswire
        </Typography.Text>
      </FlexBlock>
    </Anchor>
  );
};
