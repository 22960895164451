import React from 'react';

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-1867 -335)">
          <g transform="translate(1368 72)">
            <g transform="translate(0 228)">
              <g transform="translate(488 24)">
                <g transform="translate(11 11)">
                  <circle cx="9" cy="9" r="8.775" fill="#1977F3"></circle>
                  <path
                    fill="#FFF"
                    d="M12.416 11.537L12.804 9h-2.433V7.354c0-.694.34-1.371 1.43-1.371h1.107v-2.16s-1.004-.171-1.964-.171c-2.004 0-3.315 1.214-3.315 3.414V9H5.4v2.537H7.63v6.132a8.81 8.81 0 002.742 0v-6.132h2.045z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FacebookIcon;
