import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useAddMembersToCustomMediaListMutation } from 'app/api';
import { IBrandMedia } from 'app/api.types';
import CreateListIcon from 'app/assets/icons/CreateListIcon';
import AutoComplete from 'app/components/AutoComplete/AutoComplete';
import Typography from 'app/components/Typography';

import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';

import Modal from 'app/components/UI/Modal/Modal';

import RoundIconWrapper from 'app/components/UI/RoundIconWrapper';

import { useJournalistsSlice } from 'app/containers/Journalists/slice';
import { selectAddToMediaListModalOpen } from 'app/containers/Journalists/slice/selectors';
import { FC, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getErrorText } from 'utils/error-messages';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { TableView } from '../../../Tabs/JournalistTabs';
import useDebounce from 'utils/hooks/useDebounce';
import { useMediaListData } from 'app/containers/Journalists/pages/hooks/useJournalistData';
import * as S from 'app/components/AutoComplete/AutoComplete.styles';

const AddToMediaListModal: FC = () => {
  const dispatch = useAppDispatch();
  const { actions } = useJournalistsSlice();
  const { modalOpen, ids } = useAppSelector(selectAddToMediaListModalOpen);

  const [searchMediaListName, setSearchMediaListName] = useState('');

  const defaultMediaList = {
    id: 0,
    name: 'Select Media List',
  };

  const pagination = useMemo(() => {
    return {
      page: {
        page: 0,
        size: 50,
      },
      sort: [{ property: 'name', ascending: true }],
    };
  }, []);

  const [selectedMediaListDestination, setSelectedMediaListDestination] =
    //@ts-ignore
    useState<IBrandMedia>(defaultMediaList);

  const debouncedMediaListName = useDebounce(searchMediaListName, 500);

  const mediaLists = useMediaListData({
    pagination,
    currentTab: modalOpen ? TableView.MediaList : TableView.AllJournalists,
    debouncedSearchTerm: debouncedMediaListName,
  });

  const [addMembersToMediaList, { isLoading: isAddingMembersToMediaList }] =
    useAddMembersToCustomMediaListMutation();

  const handleModalClose = (persistSelectedItems?: boolean) => {
    if (persistSelectedItems) {
      dispatch(actions.transitionFromAddToCreateMediaListModal());
    } else {
      dispatch(actions.toggleAddToMediaListModal(false));
    }

    //@ts-ignore
    setSelectedMediaListDestination(defaultMediaList);
    setSearchMediaListName('');
  };

  const handleSubmit = async () => {
    if (
      !selectedMediaListDestination ||
      selectedMediaListDestination.id === 0
    ) {
      showErrorToast('Select media list to add journalists to.');
    }
    try {
      await addMembersToMediaList({
        id: selectedMediaListDestination.id,
        creatorIds: Object.values(ids)
          .map(id => Number(id.authorId))
          .filter(Number.isFinite),
      }).unwrap();

      showSuccessToast('Journalist(s) added to the Media List.');
      handleModalClose();
    } catch (e) {
      showErrorToast(
        getErrorText(e as FetchBaseQueryError),
        'Failed to update media list',
      );
    }
  };

  const handleOpenCreateNewList = () => {
    dispatch(actions.toggleCreateMediaListModal(true));
    handleModalClose(true);
  };

  const isLoading = mediaLists.isLoading || mediaLists.isFetching;

  return (
    <Modal open={modalOpen} $maxWidth="512px">
      <FlexBlock padding="24px" justifyContent="center" alignItems="center">
        <RoundIconWrapper size={88}>
          <CreateListIcon />
        </RoundIconWrapper>
      </FlexBlock>

      <FlexBlock
        padding="0 40px 8px 40px"
        rowGap="8px"
        flexDirection="column"
        alignItems="center"
        maxWidth="500px"
        margin="auto"
      >
        <Typography.Text $dmSans $bold $size={24}>
          Add to Media List
        </Typography.Text>
        <Typography.Text style={{ textAlign: 'center' }}>
          Add journalists to existing media list. Until the search box is
          populated, the first 50 Media Lists will appear alphabetically as
          options below.
        </Typography.Text>
      </FlexBlock>
      <FlexBlock justifyContent="center" alignItems="center" margin="auto">
        <FlexBlock padding="24px" minWidth="400px">
          <AutoComplete
            options={mediaLists.data?.content || []}
            displayAccessor={'name'}
            searchValue={searchMediaListName}
            onSearchValueChange={value => setSearchMediaListName(value)}
            icon="right-carrot"
            loading={isLoading}
            noOptionsText="No Media Lists Found"
            placeholder={selectedMediaListDestination.name}
            onOptionSelect={() => {}}
            optionRenderFunction={option => (
              <S.Option
                key={`${option.id}-${option.name}`}
                onClick={() => {
                  //@ts-ignore
                  setSelectedMediaListDestination(option);
                  setSearchMediaListName('');
                }}
              >
                {option.name}
              </S.Option>
            )}
            renderCustomOption={() => (
              <S.Option onClick={() => handleOpenCreateNewList()}>
                + Create new Media List
              </S.Option>
            )}
            maxMenuHeight={350}
          />
        </FlexBlock>
      </FlexBlock>

      <FlexBlock
        padding="24px 40px 40px"
        justifyContent="center"
        columnGap="12px"
      >
        <Button
          variant="secondary"
          compact
          onClick={() => handleModalClose(false)}
        >
          Cancel
        </Button>
        <Button
          compact
          onClick={handleSubmit}
          isLoading={isAddingMembersToMediaList}
          disabled={
            selectedMediaListDestination.id === 0 || isAddingMembersToMediaList
          }
        >
          Add Journalist
        </Button>
      </FlexBlock>
    </Modal>
  );
};

export default AddToMediaListModal;
