import { formatClassifiedCategories } from '../../MediaReportCard/MediaReportCard.helpers';
import { FC } from 'react';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';
import Gap from 'app/components/UI/Gap';
import styled, { css } from 'styled-components';

interface IProps {
  categories: string[];
}

const MinifiedPill = styled.div(
  ({ theme }) => css`
    border-radius: 12px;
    background: ${theme.colors.smoke};
  `,
);

const ClassifiedCategories: FC<IProps> = props => {
  const { formattedCategories, truncatedCategoriesCount } =
    formatClassifiedCategories(props.categories);

  return (
    <FlexBlock flexDirection="row" alignItems="center">
      <Typography.Text $lineHeight={14}>{formattedCategories}</Typography.Text>
      {truncatedCategoriesCount ? (
        <>
          <Gap size={4} />
          <MinifiedPill>
            <Typography.Text $size={12} $lineHeight={16} $padding="4px 8px">
              + {truncatedCategoriesCount}
            </Typography.Text>
          </MinifiedPill>
        </>
      ) : (
        ''
      )}
    </FlexBlock>
  );
};

export default ClassifiedCategories;
