import useHasPermission from '../../../../../../Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from '../../../../../../Global/permissions/userPermissions.enum';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import Typography from '../../../../../../../components/Typography';
import useOutOfLimitsMessages from '../../../../CreateOrEditPitch/hooks/useOutOfLimitsMessages';

export const ContactsTh = () => {
  const hasPermissionToSeeEmail = useHasPermission(
    userPermissionEnum.JOURNALIST_EMAIL_INFO_ACCESS,
  );

  const hasPermissionToSeeObscuredEmail = useHasPermission(
    userPermissionEnum.JOURNALIST_EMAIL_INFO_OBSCURED,
  );

  const cannotSeeContactInformation =
    hasPermissionToSeeObscuredEmail || !hasPermissionToSeeEmail;

  const messages = useOutOfLimitsMessages();

  return (
    <FlexBlock justifyContent="space-between" flex={1}>
      <Typography.Text inheritColor $dmSans $bold $size={14}>
        Contacts
      </Typography.Text>
      {cannotSeeContactInformation && (
        <Typography.Text>
          Upgrade to our{' '}
          <a onClick={messages.goToBilling}>{messages.planToUpgrade}</a>
        </Typography.Text>
      )}
    </FlexBlock>
  );
};

export default ContactsTh;
