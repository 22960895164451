import { FC, useContext, useEffect } from 'react';
import { ToolbarTitlePortalContext } from './ToolbarTitlePortal.context';

interface IProps {
  backButtonRoute?: string;
  title?: string;
  goBackButton?: boolean;
}

const ToolbarTitlePortal: FC<IProps> = ({
  backButtonRoute,
  goBackButton,
  title,
  children,
}) => {
  const context = useContext(ToolbarTitlePortalContext);

  useEffect(() => {
    context.setBackButtonRoute(backButtonRoute);
  }, [backButtonRoute]);

  useEffect(() => {
    if (goBackButton) {
      context.setGoBackButton(true);
    }
  }, [goBackButton]);

  useEffect(() => {
    context.setNode(children || null);
  }, [children]);

  useEffect(() => {
    context.setTitle(title || undefined);
  }, [title]);

  useEffect(() => {
    return function unmount() {
      context.setTitle(undefined);
      context.setNode(undefined);
      context.setBackButtonRoute(undefined);
      context.setGoBackButton(undefined);
    };
  }, []);

  return <></>;
};

export default ToolbarTitlePortal;
