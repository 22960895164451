interface IProps {
  size?: number;
}

const PodcastLogoIcon = ({ size = 24 }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="linearGradient-1" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#DA40E4"></stop>
        <stop offset="100%" stopColor="#832BC1"></stop>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1535 -336)">
        <g transform="translate(260 72)">
          <g transform="translate(1243 80)">
            <g transform="translate(0 56)">
              <g transform="translate(32 128)">
                <circle
                  cx="12"
                  cy="12"
                  r="11.7"
                  fill="url(#linearGradient-1)"
                ></circle>
                <path
                  fill="#FFF"
                  fillRule="nonzero"
                  d="M11.994 13.042c.6 0 1.111.209 1.402.525a.914.914 0 01.259.578c.051.47.026.874-.033 1.526a39.39 39.39 0 01-.271 2.28c-.09.598-.162.921-.226 1.15-.104.37-.504.699-1.131.699s-1.021-.323-1.131-.7c-.065-.228-.136-.55-.226-1.15a38.71 38.71 0 01-.272-2.279c-.052-.652-.084-1.055-.032-1.526a.966.966 0 01.258-.578c.291-.323.802-.525 1.403-.525zm-.02-8.842c3.768-.007 6.838 3.18 6.851 7.094 0 3.093-1.907 5.729-4.563 6.697a.09.09 0 01-.123-.1l.097-.727a.228.228 0 01.136-.195c2.1-.961 3.574-3.14 3.574-5.675 0-3.43-2.695-6.213-5.997-6.186-3.225.027-5.862 2.763-5.894 6.125-.026 2.562 1.454 4.775 3.573 5.736.072.04.123.108.136.195.026.242.065.484.097.726.007.068-.058.128-.123.101-2.682-.982-4.601-3.658-4.562-6.784.051-3.86 3.089-6.994 6.798-7.007zm4.589 7.094c0 1.627-.795 3.073-1.997 3.927-.065.047-.149-.007-.142-.08.013-.29.02-.545.006-.808 0-.087.033-.168.09-.228a3.927 3.927 0 001.17-2.804c0-2.165-1.731-3.927-3.838-3.84-1.94.08-3.497 1.722-3.548 3.739a3.922 3.922 0 001.17 2.905.28.28 0 01.09.228c-.013.27-.013.525.006.814 0 .074-.084.12-.142.08a4.807 4.807 0 01-1.997-3.987c.032-2.515 1.971-4.592 4.388-4.687 2.598-.107 4.75 2.065 4.744 4.741zm-4.57-2.232c.88 0 1.59.746 1.59 1.654 0 .914-.71 1.654-1.59 1.654-.878 0-1.589-.74-1.589-1.654 0-.915.71-1.654 1.59-1.654z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PodcastLogoIcon;
