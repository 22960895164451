import { SVGProps } from 'react';

const LinkedIcon = (props: SVGProps<SVGSVGElement>) => {
  const { width = '40', height = '40', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M40.0001 18.75V21.1667C39.9834 21.3 39.9501 21.4167 39.9334 21.55C39.7668 22.5833 39.6834 23.65 39.4334 24.6667C36.5668 36.65 23.6501 43.2 12.2668 38.45C3.66678 34.85 -1.29988 25.7833 0.300116 16.5667C1.70012 8.46667 7.96678 2.01667 16.0168 0.4C16.9334 0.216667 17.8834 0.133333 18.8168 0C19.6168 0 20.4334 0 21.2334 0C21.3334 0.0166667 21.4334 0.05 21.5334 0.0666667C24.5334 0.3 27.3668 1.13333 29.9501 2.66667C35.2668 5.83333 38.5334 10.45 39.6834 16.55C39.8168 17.2833 39.8834 18.0167 39.9834 18.75H40.0001ZM21.2668 30.5167C21.2668 30.3167 21.2668 30.1667 21.2668 30.0167C21.2668 27.85 21.2668 25.6667 21.2668 23.5C21.2668 22.7 21.3335 21.9167 21.7001 21.1833C22.1668 20.25 23.1168 19.85 24.3668 20.0167C25.2168 20.1333 25.8168 20.7167 26.0001 21.7167C26.1001 22.2333 26.1501 22.7833 26.1501 23.3C26.1668 25.5667 26.1501 27.8333 26.1501 30.1C26.1501 30.2333 26.1501 30.3833 26.1501 30.5333H30.4834C30.4834 30.4 30.5001 30.2833 30.5001 30.1667C30.5001 27.7 30.5334 25.2167 30.5001 22.75C30.4834 21.7333 30.3834 20.7167 30.2001 19.7333C29.8834 18.0833 28.9668 16.8667 27.2834 16.4167C26.7168 16.2667 26.1334 16.1833 25.5501 16.1667C23.7334 16.1167 22.2334 16.7333 21.2168 18.3C21.2168 18.3333 21.1668 18.3333 21.0834 18.3833V16.5333H16.9501V30.55H21.2501L21.2668 30.5167ZM14.1668 30.5167V16.5H9.86678V30.5167H14.1668ZM11.9668 14.55C13.3834 14.55 14.5335 13.4167 14.5335 12.0167C14.5335 10.6333 13.3834 9.48333 12.0001 9.48333C10.6168 9.48333 9.46678 10.6167 9.46678 12.0167C9.46678 13.4167 10.5834 14.55 11.9834 14.5667L11.9668 14.55Z"
        fill="#0077B5"
      />
    </svg>
  );
};

export default LinkedIcon;
