import React, { useState } from 'react';
import { ReactComponent as Icon } from '../../icons/tone-icons/sort.svg';
import styled from 'styled-components';

const StyledFavoriteSortIcon = styled(Icon)<{ $isClicked: boolean }>`
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    path {
      fill: #52f3fd; /* Change to your desired hover color */
    }
  }

  ${props =>
    props.$isClicked &&
    `
    color: blue;
      background-color: rgb(29,118,222, 0.2); /* Add your click background color */
    `}
`;

interface SVGProps {
  width: number;
  height: number;
  color: string;
  onClick: any;
}

const SortIcon: React.FC<SVGProps> = ({ width, height, color, onClick }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleOnClick = () => {
    onClick();
    setIsClicked(!isClicked);
  };

  return (
    <StyledFavoriteSortIcon
      width={width}
      height={height}
      fill={color}
      onClick={handleOnClick}
      $isClicked={isClicked}
    />
  );
};

export default SortIcon;
