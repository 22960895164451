import { FC, useState } from 'react';

import * as S from './CreateOrEditPitchPage.styles';

import { IPitchContent, PitchContentName } from './CreateOrEditPitch.types';
import { useGetGeneratedContentHistoryQuery } from '../../../../api';
import PageLoader from '../../../../components/UI/PageLoader';

import { IProject } from '../../../../api.types';
import { Helmet } from 'react-helmet-async';
import PitchContentEditor from './parts/PitchContentEditor/PitchContentEditor';
import FlexBlock from '../../../../components/UI/FlexBlock';

import { ToneSelectDropDown } from './parts/ToneSelectDropDown/ToneSelectDropDown';

import ContentVersionsBar from './parts/ContentVersionsBar/ContentVersionsBar';

import ContentPageHeader, {
  ContentTabsHeader,
} from 'app/components/ContentGeneration/parts/header/ContentHeader';
import { ContentHeader } from 'app/components/UI/ContentLayout/ContentLayout';
import { ContentContainer } from 'app/components/ContentGeneration/ContentGeneration.styles';
import PredictionSettings from './parts/SettingsBar/PredictionSettings';
import PitchModal from './parts/PitchProgressModal/PitchingModal';
import {
  PitchProvider,
  PitchStateActions,
  RoutePitchState,
  usePitch,
  usePitchDispatch,
} from './parts/PitchContext';
import { usePitchDataWithContext } from './hooks/usePitchDataWithContext';
import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';
import { useLocation } from 'react-router-dom';
import { FirstPitchHeader } from 'app/components/ContentGeneration/parts/header/FirstPitchHeader';
import GeneratedContentFooter from '../CreateFirstPitch/parts/GeneratedContentFooter';
import { PredictedContentTab } from 'app/components/ContentGeneration/parts/header/parts/Tabs';

interface IProps {
  headerVariant: PitchPageHeaderVariant;
  project?: IProject;
}

enum PitchPageHeaderVariant {
  NORMAL_HEADER = 'normal',
  FIRST_PITCH_HEADER = 'first',
}

export enum PitchProcessStatusEnum {
  evaluating = 'evaluating',
  categorySelect = 'categorySelect',
  predictionRunning = 'predictionRunning',
  complete = 'complete',
}

export interface ICreateOrEditPageParams {
  tenantId: string;
  projectId: string;
  pitchId?: string;
  versionId?: string;
}

const CreateOrEditPitchPage: FC<IProps> = ({ headerVariant, project }) => {
  const pitchState = usePitch();
  const dispatch = usePitchDispatch();

  const {
    pitchData,
    isPitchFetching,
    isPredictionFetching,
    isGenerateLoading,
    hasGeneratedContent,
    hasSocialPosts,
    pageTitle,
    headerNotice,
    handleGenerateContent,
    handlePrediction,
  } = usePitchDataWithContext();

  const {
    data: contentHistory,
    isLoading: isHistoryLoading,
    isError: isHistoryError,
  } = useGetGeneratedContentHistoryQuery(
    { pitchId: pitchData?.id, size: 10 },
    {
      skip: !pitchData?.id,
    },
  );

  const handlePitchEditorChangeEvent =
    (contentType: PitchContentName) => (data: IPitchContent) => {
      dispatch({
        type: PitchStateActions.UPDATE_PITCH_CONTENT,
        payload: { contentType, data },
      });
    };

  const handlePredictionSettingsClose = () => {
    dispatch({ type: PitchStateActions.TOGGLE_SETTINGS, payload: false });
  };

  const [activeTab, setActiveTab] = useState<PredictedContentTab>(
    PredictedContentTab.PITCH,
  );

  return isPitchFetching || isPredictionFetching ? (
    <PageLoader />
  ) : (
    <>
      <Helmet title={pageTitle} />
      <S.PageWrapper>
        <S.ContentWrapper>
          <S.PitchContent>
            {headerVariant === PitchPageHeaderVariant.NORMAL_HEADER ? (
              <ContentHeader>
                <ContentPageHeader
                  handleGenerate={handleGenerateContent}
                  headerNotice={headerNotice}
                  hasContentHistory={
                    (contentHistory &&
                      !contentHistory.empty &&
                      !isHistoryError) ||
                    false
                  }
                >
                  <ToneSelectDropDown
                    value={pitchState.tone}
                    onChange={option =>
                      dispatch({
                        type: PitchStateActions.SET_TONE,
                        payload: option,
                      })
                    }
                  />
                </ContentPageHeader>
              </ContentHeader>
            ) : (
              <FirstPitchHeader
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                project={project}
              />
            )}
            {headerVariant === PitchPageHeaderVariant.NORMAL_HEADER && (
              <ContentTabsHeader
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                hasSocialPosts={hasSocialPosts}
              />
            )}

            <ContentContainer>
              <FlexBlock
                flexDirection="row"
                flex={1}
                alignItems="stretch"
                minHeight="100%"
              >
                {pitchState.pitchContents.map(pitchContent => (
                  <PitchContentEditor
                    key={pitchContent.contentName}
                    pitchData={pitchContent}
                    onChange={handlePitchEditorChangeEvent(
                      pitchContent.contentName,
                    )}
                    showContentSelectPill={Boolean(
                      pitchState.pitchContents.length > 1,
                    )}
                    selectedContentName={pitchState.selectedPitchContent}
                    onSelectedContentChange={contentName =>
                      dispatch({
                        type: PitchStateActions.SET_SELECTED_PITCH_CONTENT,
                        payload: contentName,
                      })
                    }
                    fadeOut={isGenerateLoading}
                    disableEditing={isGenerateLoading}
                    showTabs={hasGeneratedContent}
                    onTabChange={tab =>
                      dispatch({
                        type: PitchStateActions.TOGGLE_SOCIALS,
                        payload: tab === 'socials',
                      })
                    }
                    tab={activeTab}
                  />
                ))}
              </FlexBlock>
            </ContentContainer>
          </S.PitchContent>
          {pitchState.versionsOpen && pitchData && pitchData.id && (
            <ContentVersionsBar pitchId={pitchData.id} />
          )}

          <SideDrawer open={pitchState.settingsOpen}>
            <PredictionSettings
              onPredict={handlePrediction}
              pitchMeta={pitchData}
            />
          </SideDrawer>
        </S.ContentWrapper>
        {pitchState.settingsOpen && !Boolean(pitchState.pitchProcessStatus) && (
          <S.PageBackdrop onClick={handlePredictionSettingsClose} />
        )}
        {headerVariant === PitchPageHeaderVariant.FIRST_PITCH_HEADER && (
          <GeneratedContentFooter
            tone={pitchState.tone}
            setTone={option =>
              dispatch({
                type: PitchStateActions.SET_TONE,
                payload: option,
              })
            }
            regenerateContent={handleGenerateContent}
          />
        )}
      </S.PageWrapper>
      <PitchModal isOpen={Boolean(pitchState.pitchProcessStatus)} />
    </>
  );
};

const CreateOrEditPitch: FC = () => {
  const state = useLocation().state as RoutePitchState;

  return (
    <PitchProvider routeInitialState={state}>
      <CreateOrEditPitchPage
        headerVariant={
          state
            ? PitchPageHeaderVariant.FIRST_PITCH_HEADER
            : PitchPageHeaderVariant.NORMAL_HEADER
        }
        project={state?.project}
      />
    </PitchProvider>
  );
};

export default CreateOrEditPitch;
