import { ICustomTargetMediaBase } from 'app/api.types';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MediaListItemContainer,
  ExpandedTitleDivider,
} from '../../MediaListTable.styles';
import FlexBlock from 'app/components/UI/FlexBlock';
import CheckBox from 'app/components/UI/CheckBox/CheckBox';
import Typography from 'app/components/Typography';
import { theme } from 'styles/theme';
import CarrotTopIcon from 'app/assets/icons/CarrotTopIcon';
import MediaListItemsTable from './MediaListItemsTable';
import MediaListItemActions from './MediaListItemActions';
import Button from 'app/components/UI/Button/Button';
import ArrowRightIcon from 'app/assets/icons/ArrowRightIcon';
import CopyIcon from 'app/assets/icons/CopyIcon';
import TrashIcon from 'app/assets/icons/TrashIcon';
import { UploadFilesThinIcon } from 'app/assets/icons/UploadFilesIcon';
import { useJournalistRowActions } from 'app/containers/Journalists/pages/hooks/useJournalistRowActions';
import {
  ExportSourceType,
  handleMediaListExport,
} from '../../../Table/JournalistsTable.helpers';
import { getFavoriteJournalistTooltipProps } from 'app/containers/Journalists/pages/JournalistList.helpers';
import ReactTooltip from 'react-tooltip';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import MoveMediaListEntries from '../Modals/MoveMediaListEntries';
import CopyMediaListEntries from '../Modals/CopyMediaListEntries';

interface IMediaListItemProps {
  onSelect: (item: ICustomTargetMediaBase, selected: boolean) => void;
  onDelete: (row: ICustomTargetMediaBase) => void;
  isSelected?: boolean;
  item: ICustomTargetMediaBase;
}

const MediaListItem: FC<IMediaListItemProps> = ({
  item,
  onSelect,
  isSelected,
  onDelete,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [selectedMediaListEntries, setSelectedMediaListEntries] = useState<
    number[]
  >([]);

  const [selectedAuthorIds, setSelectedAuthorIds] = useState<string[]>([]);

  const hoverRef = useRef<HTMLDivElement | null>(null);

  const { handleMove, handleCopy, handleDelete, handleExport } =
    useJournalistRowActions();

  const handleMediaListDelete = useCallback(() => {
    onDelete(item);
  }, [item, onDelete]);

  const onSelectedRowIdsChange = useCallback(
    (selectedMemberIds: number[], selectedAuthorIds: string[]) => {
      setSelectedMediaListEntries(selectedMemberIds);
      setSelectedAuthorIds(selectedAuthorIds);
    },
    [],
  );

  const hasSelectedRows = useMemo(
    () => selectedMediaListEntries && selectedMediaListEntries.length > 0,
    [selectedMediaListEntries],
  );

  const hasEntries = useMemo(() => {
    return Boolean(item && item.totalMembers && item.totalMembers > 0);
  }, [item]);

  // Close expanded view if table has been updated and no longer has entries
  useEffect(() => {
    if (isExpanded && !hasEntries) {
      setIsExpanded(false);
    }
  }, [isExpanded, hasEntries]);

  const showActions = useMemo(() => {
    return isExpanded && hasSelectedRows && hasEntries;
  }, [isExpanded, hasSelectedRows, hasEntries]);

  useEffect(() => {
    if (showActions) {
      ReactTooltip.rebuild();
      ReactTooltip.hide();
    }
  }, [showActions]);

  return (
    <>
      <MediaListItemContainer
        ref={hoverRef}
        onClick={() => {
          if (!hasEntries) return;
          setIsExpanded(expanded => !expanded);
        }}
      >
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          columnGap="16px"
          padding="8px"
          justifyContent="flex-end"
        >
          <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
            <div onClick={e => e.stopPropagation()}>
              <CheckBox
                checked={isSelected || false}
                onChange={checked => onSelect(item, checked)}
              />
            </div>
          </IfHasPermission>

          <Typography.Text
            $size={16}
            $lineHeight={24}
            $dmSans
            $bold
            style={{ userSelect: 'text' }}
          >
            {item.name}
          </Typography.Text>
          {showActions && (
            <>
              <ExpandedTitleDivider />
              <FlexBlock
                flexDirection="row"
                alignItems="center"
                columnGap="4px"
                margin="0 16px 0 0"
              >
                <div
                  {...getFavoriteJournalistTooltipProps(
                    'Move to another Media List',
                  )}
                >
                  <Button
                    variant="gray-smoke-hover-blue"
                    padding="10px"
                    onClick={e => {
                      e.stopPropagation();
                      handleMove(selectedMediaListEntries, item);
                    }}
                  >
                    <ArrowRightIcon />
                  </Button>
                </div>
                <div
                  {...getFavoriteJournalistTooltipProps(
                    'Copy to another Media List',
                  )}
                >
                  <Button
                    variant="gray-smoke-hover-blue"
                    padding="10px"
                    onClick={e => {
                      e.stopPropagation();
                      handleCopy(selectedMediaListEntries, item);
                    }}
                  >
                    <CopyIcon />
                  </Button>
                </div>
                <div
                  {...getFavoriteJournalistTooltipProps('Export journalists')}
                >
                  <Button
                    variant="gray-smoke-hover-blue"
                    padding="10px"
                    onClick={e => {
                      e.stopPropagation();
                      handleExport(selectedAuthorIds);
                    }}
                  >
                    <UploadFilesThinIcon fill="black" width={16} height={16} />
                  </Button>
                </div>
                <div
                  {...getFavoriteJournalistTooltipProps(
                    'Delete journalists',
                    true,
                  )}
                >
                  <Button
                    variant="gray-smoke-hover-danger"
                    padding="10px"
                    onClick={e => {
                      e.stopPropagation();
                      handleDelete(selectedMediaListEntries, item.id);
                    }}
                  >
                    <TrashIcon fill="black" width={16} height={16} />
                  </Button>
                </div>
              </FlexBlock>
            </>
          )}
        </FlexBlock>
        <FlexBlock flexDirection="row" alignItems="center">
          <MediaListItemActions
            onDelete={handleMediaListDelete}
            onExport={() =>
              handleMediaListExport([item.id], ExportSourceType.TARGET_MEDIA)
            }
            hasEntries={hasEntries}
            expanded={isExpanded}
            ref={hoverRef}
          />
          <CarrotTopIcon
            rotateDown={!isExpanded}
            fill={hasEntries ? theme.colors.orbit : theme.colors.steel}
          />
        </FlexBlock>
        {isExpanded && (
          <MediaListItemsTable
            mediaListId={item.id}
            onSelectedRowIdsChange={onSelectedRowIdsChange}
            isParentListSelected={isSelected || false}
          />
        )}
      </MediaListItemContainer>
      <MoveMediaListEntries />
      <CopyMediaListEntries />
    </>
  );
};

export default MediaListItem;
