import {
  IAuthorEmailSuggestionsPayload,
  IAuthorEmailSuggestionsResponse,
} from 'app/api.types';
import { request } from 'utils/request';

export const getSuggestions: (
  requestBody: IAuthorEmailSuggestionsPayload,
  regenerate: boolean,
) => Promise<IAuthorEmailSuggestionsResponse[]> = (
  requestBody,
  regenerate = false,
) => {
  return request({
    url: 'v3/pitch/author-email-suggestion-v2',
    method: 'POST',
    data: requestBody,
    params: { regenerate },
  });
};
