import {
  IContentGenerationLimits,
  LimitTypesEnum,
} from '../../../../../api.types';
import { api, useGetClientLimitsByIdQuery } from '../../../../../api';
import { useAppDispatch } from '../../../../../../store/hooks';
import useHasPermission from '../../../../Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from '../../../../Global/permissions/userPermissions.enum';
import { useMemo } from 'react';
import useGetCurrentUserPlan from 'app/containers/Global/permissions/useGetCurrentUserPlan.hook';

interface IUserGenerationLimits extends IContentGenerationLimits {
  refresh: () => void;
  loading: boolean;
  bylineGenerationsLeft: number;
  isFreeUserPlan: boolean;
}

const NO_LIMIT = 1000;

export const useBylineGenerationLimits = () => {
  const hasLimitations = useHasPermission(
    userPermissionEnum.GENERATE_CONTENT_LIMIT,
  );
  const dispatch = useAppDispatch();

  const { isFreeOrFreemiumPlan } = useGetCurrentUserPlan();

  const { data: contentGenerationLimits, isFetching: loading } =
    useGetClientLimitsByIdQuery(
      {
        limitTypes: [LimitTypesEnum.BLOG_GENERATIONS],
      },
      { skip: !hasLimitations },
    );

  const refresh = () => {
    if (!hasLimitations) return;
    dispatch(
      api.util.invalidateTags(['content-generation-limit', 'user-limits']),
    );
  };

  const bylineGenerationsLeft = useMemo(() => {
    if (
      !contentGenerationLimits ||
      !contentGenerationLimits.userLimitDetails ||
      contentGenerationLimits.userLimitDetails[
        LimitTypesEnum.BLOG_GENERATIONS
      ] === undefined
    ) {
      return NO_LIMIT;
    }

    return contentGenerationLimits.userLimitDetails[
      LimitTypesEnum.BLOG_GENERATIONS
    ]?.left;
  }, [contentGenerationLimits]);

  return {
    loading,
    refresh,
    resetTimestamp: new Date(
      contentGenerationLimits?.resetTimestamp || '2023-03-07T16:32:49.412Z',
    ).toLocaleDateString(),
    bylineGenerationsLeft,
    isFreeUserPlan: isFreeOrFreemiumPlan,
  } as IUserGenerationLimits;
};
