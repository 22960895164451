import { SVGProps } from 'react';

const UploadFilesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="52"
    height="48"
    viewBox="0 0 52 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.4232 0.756079C23.3646 1.68551 23.2683 2.55633 23.2599 3.42297C23.2348 5.62095 23.2516 7.82312 23.2474 10.0211C23.2432 14.1952 26.5548 17.4691 30.7289 17.4273C32.8766 17.4063 35.0202 17.4356 37.1679 17.4147C38.0011 17.4063 38.8342 17.3226 39.7469 17.2682C39.7469 17.3686 39.7469 17.5529 39.7469 17.7371C39.7469 23.1923 39.7469 28.6474 39.7469 34.1026C39.7469 36.7863 38.0011 38.5237 35.3091 38.5237C29.1003 38.5237 22.8957 38.5237 16.6869 38.5237C14.0326 38.5237 12.2742 36.7737 12.27 34.1319C12.27 24.4734 12.27 14.8148 12.27 5.16042C12.27 2.50609 14.02 0.756079 16.6702 0.751892C18.9393 0.751892 21.2085 0.756079 23.4232 0.756079ZM27.7229 23.8119C27.9532 24.0254 28.0913 24.1594 28.2295 24.2892C28.9538 24.9548 29.9711 24.959 30.6452 24.2933C31.315 23.6319 31.3485 22.5852 30.6661 21.8818C29.5315 20.7138 28.3802 19.5624 27.2163 18.4279C26.5046 17.7371 25.5123 17.7371 24.809 18.4237C23.6577 19.5499 22.5189 20.6887 21.3927 21.8358C20.7061 22.5391 20.7103 23.5732 21.3718 24.2473C22.0458 24.9381 23.0841 24.9339 23.8251 24.2431C23.9549 24.1217 24.0889 24.0003 24.2899 23.8119C24.2899 25.8926 24.2605 27.8394 24.3108 29.7862C24.3233 30.23 24.5201 30.7324 24.7839 31.0966C25.1984 31.666 25.9896 31.8042 26.6344 31.5279C27.3419 31.2222 27.7187 30.6696 27.7229 29.8909C27.7271 27.9148 27.7229 25.9303 27.7229 23.8119Z"
      fill="#0C0C0D"
    />
    <path
      d="M26.0021 47.1147C20.3166 47.1147 14.627 47.1231 8.94155 47.1105C4.70886 47.1022 1.2507 44.2845 0.413376 40.1482C0.30871 39.6248 0.27103 39.0847 0.258471 38.553C0.233351 37.4645 0.250097 36.3718 0.250097 35.2791C0.254284 34.1445 0.961825 33.37 1.97918 33.3784C2.97141 33.3867 3.67895 34.1613 3.68314 35.2582C3.68732 36.2964 3.67895 37.3305 3.68314 38.3688C3.6957 41.446 5.9188 43.6775 9.00435 43.6775C20.3418 43.6817 31.6834 43.6817 43.0208 43.6775C46.1021 43.6775 48.3169 41.446 48.3336 38.3605C48.3378 37.3054 48.3294 36.2504 48.3378 35.1954C48.3462 34.1445 49.0663 33.3825 50.0459 33.3784C51.0214 33.3742 51.7876 34.1403 51.7625 35.1828C51.7206 36.9119 51.8294 38.6786 51.5238 40.3617C50.8205 44.2385 47.2828 47.0812 43.3348 47.1022C37.5572 47.1315 31.7796 47.1105 26.0021 47.1147Z"
      fill="#0C0C0D"
    />
    <path
      d="M34.2163 13.9984C33.0189 13.9984 31.8216 13.9984 30.62 13.9984C28.4262 14.0026 26.6846 12.2987 26.6804 10.109C26.672 7.64311 26.6804 5.173 26.6929 2.70707C26.6929 2.42657 26.7264 2.12932 26.8185 1.86556C27.2581 0.651433 28.7444 0.383489 29.7073 1.3506C31.4364 3.08806 33.1404 4.84644 34.8569 6.59227C36.2594 8.0241 37.6745 9.43918 39.0519 10.8919C39.3449 11.2018 39.6087 11.6162 39.7008 12.0265C39.9311 13.0313 39.1105 13.9607 38.022 13.9817C36.7534 14.0068 35.4807 13.9859 34.2121 13.9859C34.2163 13.9901 34.2163 13.9942 34.2163 13.9984Z"
      fill="#0C0C0D"
    />
  </svg>
);

const UploadFilesThinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? '12'}
    height={props.height ?? '12'}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.5C2.27614 5.5 2.5 5.72386 2.5 6V10C2.5 10.1326 2.55268 10.2598 2.64645 10.3536C2.74021 10.4473 2.86739 10.5 3 10.5H9C9.13261 10.5 9.25978 10.4473 9.35355 10.3536C9.44732 10.2598 9.5 10.1326 9.5 10V6C9.5 5.72386 9.72386 5.5 10 5.5C10.2761 5.5 10.5 5.72386 10.5 6V10C10.5 10.3978 10.342 10.7794 10.0607 11.0607C9.77936 11.342 9.39783 11.5 9 11.5H3C2.60217 11.5 2.22064 11.342 1.93934 11.0607C1.65804 10.7794 1.5 10.3978 1.5 10V6C1.5 5.72386 1.72386 5.5 2 5.5Z"
      fill={props.fill ?? '#1D76DE'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64645 0.646447C5.84171 0.451184 6.15829 0.451184 6.35355 0.646447L8.35355 2.64645C8.54882 2.84171 8.54882 3.15829 8.35355 3.35355C8.15829 3.54882 7.84171 3.54882 7.64645 3.35355L6 1.70711L4.35355 3.35355C4.15829 3.54882 3.84171 3.54882 3.64645 3.35355C3.45118 3.15829 3.45118 2.84171 3.64645 2.64645L5.64645 0.646447Z"
      fill={props.fill ?? '#1D76DE'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0.5C6.27614 0.5 6.5 0.723858 6.5 1V7.5C6.5 7.77614 6.27614 8 6 8C5.72386 8 5.5 7.77614 5.5 7.5V1C5.5 0.723858 5.72386 0.5 6 0.5Z"
      fill={props.fill ?? '#1D76DE'}
    />
  </svg>
);

export { UploadFilesIcon, UploadFilesThinIcon };
