/* --- STATE --- */
import { ITenant, IUser } from 'app/api.types';
import { userPermissionEnum } from '../permissions/userPermissions.enum';

export interface GlobalState {
  initialized: boolean;
  authorized: boolean;
  redirectTo: string | null;
  user?: IUser;
  permissions: userPermissionEnum[];
  activeTenantId: number | null;
  activeTenantObject: ITenant | null;
  needHelpModalOpen: boolean;
  stripeModalOpen: boolean;
  redirectAfterPayment: string | null;
}

export enum roleEnum {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_CLIENT_USER = 'ROLE_CLIENT_USER',
  ROLE_AGENCY_ADMIN = 'ROLE_AGENCY_ADMIN',
}

export enum UserRoleEnum {
  SUPER_ADMIN = 'SUPER_ADMIN',
  CLIENT_USER = 'CLIENT_USER',
  CLIENT_MONITOR_USER = 'CLIENT_MONITOR_USER',
  TENANT_ADMIN = 'TENANT_ADMIN',
  TENANT_USER = 'TENANT_USER',
  PAYG_STANDARD_USER = 'PAYG_STANDARD_USER',
  PAYG_PLUS_USER = 'PAYG_PLUS_USER',
  AGENCY_ADMIN = 'AGENCY_ADMIN',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  FREE_USER = 'FREE_USER',
  FREEMIUM_USER = 'FREEMIUM_USER',
  PRO_USER = 'PRO_USER',
  PRO_V2_USER = 'PRO_V2_USER',
  BETA_TESTER = 'BETA_TESTER',
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED',
  DISABLED = 'DISABLED',
}

export interface IDecodedToken {
  sub: string;
  roles: roleEnum[];
  exp: number;
  iat: number;
}

export interface IAgencyBase {
  id: number;
  name: string;
  logo?: any;
}

interface ICreatorObject {
  id: number;
  firstName: string;
  lastName: string;
  avatar?: any;
}

export type ICreator = ICreatorObject | null;

export interface IRoleObject {
  name: UserRoleEnum;
}

export interface IUserProfileBase {
  id: number;
  firstName: string;
  lastName: string;
  avatar?: string;
  agency: IAgencyBase;
  status: string;
  statusType: UserStatusEnum;
  clientPermissions: number[];
  brandPermissions: number[];
  email: string;
  mobilePhone: string;
  creator: ICreator;
  createdAt: string;
  updatedAt: string;
  lastLoginTime?: any;
  payAsYouGo: boolean;
}

export interface IUserProfile extends IUserProfileBase {
  role: UserRoleEnum;
  firstTimeLogin?: boolean;
  monitorHash?: string;
}
