import { IAuthorLocation } from 'app/api.types';
import LocationIconThin from 'app/assets/icons/LocationThinIcon';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC, useMemo } from 'react';
import { MAX_CREATOR_COUNTRIES_FOR_DISPLAY } from './Locations.constants';
import { formatMultipleLocations } from './Locations.helpers';

interface ICreatorCountriesProps {
  locations: IAuthorLocation[];
}

const CreatorCountries: FC<ICreatorCountriesProps> = ({ locations }) => {
  const uniqueLocations = useMemo(
    () =>
      Array.from(new Set(locations.map(location => location.country)))
        .filter(country => country !== undefined && typeof country === 'string')
        .slice(0, MAX_CREATOR_COUNTRIES_FOR_DISPLAY),
    [locations],
  );

  const formattedLocations = useMemo(
    //@ts-ignore
    () => formatMultipleLocations(uniqueLocations),
    [uniqueLocations],
  );

  return (
    <FlexBlock
      flexDirection="row"
      alignItems="center"
      margin="2px 0"
      rowGap="5px"
      columnGap="6px"
    >
      <LocationIconThin />
      <Typography.Text
        $colorName="orbit"
        $size={14}
        $lineHeight={16}
        maxWidth="235px"
      >
        {formattedLocations}
      </Typography.Text>
    </FlexBlock>
  );
};

export default CreatorCountries;
