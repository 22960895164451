import Avatar from 'app/components/UI/Avatar';
import { FC, useMemo } from 'react';
import XIcon from './XIcon';
import LinkedIcon from './LinkedIcon';
import FacebookIcon from './FacebookIcon';
import GooglePlusIcon from './GooglePlusIcon';
import InstagramIcon from './InstagramIcon';

interface ISocialProviderIcon {
  socialType: string;
}

export const SocialProviderIcon: FC<ISocialProviderIcon> = ({ socialType }) => {
  const icon = useMemo(() => {
    switch (socialType) {
      case 'TWITTER':
        return <XIcon />;
      case 'FACEBOOK':
        return <FacebookIcon />;
      case 'INSTAGRAM':
        return <InstagramIcon />;
      case 'LINKEDIN':
        return <LinkedIcon />;
      case 'GOOGLE_PLUS':
        return <GooglePlusIcon />;
      default:
        return <Avatar name={socialType} size="40" />;
    }
  }, [socialType]);

  return <>{icon}</>;
};
