import Typography from 'app/components/Typography';
import DoublePill from 'app/components/UI/DoublePill';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import { usePitchDataWithContext } from 'app/containers/Pitch/pages/CreateOrEditPitch/hooks/usePitchDataWithContext';
import { usePitchSlice } from 'app/containers/Pitch/slice';
import { FC } from 'react';
import { useAppDispatch } from 'store/hooks';
import { formatDate } from 'utils/helpers';

interface IVersionProps {
  version: number;
}

export const Version: FC<IVersionProps> = ({ version }) => {
  const { pitchData } = usePitchDataWithContext();
  const dispatch = useAppDispatch();
  const { actions: pitchActions } = usePitchSlice();

  const openVersionDrawer = () => {
    dispatch(pitchActions.toggleVersionDrawer(true));
  };

  if (!pitchData) return <></>;

  return (
    <FlexBlock flexDirection="row" alignItems="center">
      <FlexBlock flexDirection="column" alignItems="flex-end">
        <Typography.Text $lineHeight={16} $colorName="steel">
          Last Edited
        </Typography.Text>
        <Typography.Text $lineHeight={16}>
          {formatDate(new Date().toISOString())}
        </Typography.Text>
      </FlexBlock>
      <Gap size={16} />
      <DoublePill
        leftContent="Pitch"
        rightContent={`V${version}`}
        onClick={openVersionDrawer}
        compact
      />
    </FlexBlock>
  );
};
