import styled from 'styled-components';

export const MediaListItemContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 8px 22px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.smoke};
  width: 100%;
  flex-wrap: wrap;
  user-select: none;

  &:hover {
    box-shadow: 0px 2px 8px 0px rgba(63, 63, 66, 0.24);
  }
`;

export const ExpandedMediaListItemContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.smoke};
  margin: 8px 0;
`;

export const ExpandedTitleDivider = styled.div`
  width: 2px;
  height: 16px;
  background: ${({ theme }) => theme.colors.smoke};
`;
