import moment from 'moment';
import { request } from './request';
import html2canvas from 'html2canvas';
import {
  UserRoleEnum,
  IRoleObject,
  roleEnum,
} from '../app/containers/Global/slice/types';

export const msToMinutes = (ms: number) => {
  const seconds = ms / 1000;
  if (seconds > 60) {
    return `${Math.floor(seconds / 60)} minute${seconds > 119 ? 's' : ''}`;
  } else {
    return `${seconds} seconds`;
  }
};

export const asyncDelay = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const DEFAULT_DATE_FORMAT = 'MMMM D, YYYY';

export const formatDate: (date?: string, format?: string) => string = (
  date,
  format = DEFAULT_DATE_FORMAT,
) => {
  if (!date) return '';
  return moment.utc(date).format(format);
};

export const formatNumber = (
  n?: number | null,
  fallbackString?: string,
): string => {
  if (n === undefined || n === null) return fallbackString || '';
  return n.toLocaleString();
};

export const uploadLogoResource: (file: File) => Promise<string> = file => {
  const data = new FormData();
  data.append('file', file);

  return request({
    url: 'v2/resource/logo',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data; boundary=---',
    },
    data,
  }).then(result => {
    return result;
  });
};

export const clearBitLogo = (url?: string) => {
  if (!url) return undefined;
  return `https://logo.clearbit.com/${url}`;
};

const emailValidationRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneValidationRegexp = /^\+?\d{7,}$/;

export const validateEmail = (email: string) => {
  return Boolean(emailValidationRegexp.test(String(email).toLowerCase()));
};

export const validatePhoneNumber = (input: string): boolean => {
  return phoneValidationRegexp.test(input);
};

export const getFirstEmail = (emails?: string) =>
  `${emails?.split(';')[0] || ''}`;

export const openInNewTab = (url?: string) => {
  if (!url) return;
  window.open(url, '_blank');
};

export const captureScreenShot: (elId: string) => Promise<string> = async (
  elementId: string,
) => {
  const node = document.getElementById(elementId);
  if (!node) return '';

  const canvas = await html2canvas(node);
  const imageUrl = canvas.toDataURL('image/png');
  return imageUrl;
};

export const depersonalizeEmail = (email: string) => {
  const [username, domain] = email.split('@');
  const [root, tld] = (domain || '').split('.');
  if (username && tld) {
    return `${username}@***.${tld}`;
  }
  return 'your email';
};

export const scrollMainContentToTop: () => void = () => {
  const node = document.getElementById('app-main-content-scroll-container');
  if (node) node.scrollTo(0, 0);
};

export const readFileAsDataUrl: (files?: FileList) => Promise<string> =
  files => {
    return new Promise(resolve => {
      if (!files || !files[0]) {
        return resolve('');
      }

      const reader = new FileReader();
      reader.addEventListener('load', function () {
        resolve(reader.result as string);
      });

      reader.readAsDataURL(files[0]);
    });
  };

export const getRoleName = (roles: roleEnum[]) => {
  if (roles.includes(roleEnum.ROLE_SUPER_ADMIN)) return 'Super Admin';
  if (roles.includes(roleEnum.ROLE_AGENCY_ADMIN)) return 'Agency Admin';
  return 'Standard User';
};

export const getClientUserRoleName = (roleObjects: IRoleObject[]) => {
  const roles = roleObjects.map(o => o.name);
  if (roles.includes(UserRoleEnum.SUPER_ADMIN)) return 'Super Admin';
  if (roles.includes(UserRoleEnum.AGENCY_ADMIN)) return 'Agency Admin';
  if (roles.includes(UserRoleEnum.CLIENT_ADMIN)) return 'Group Admin';
  return 'Standard User';
};

export function secondsToHms(seconds: number) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
}

export const downloadFile = (fileName: string, fileUrl: string) => {
  const invisibleLinkEl = document.createElement('a');
  invisibleLinkEl.href = fileUrl;
  invisibleLinkEl.download = fileName;
  invisibleLinkEl.click();
};

export const truncateText: (text: string, limit: number) => string = (
  text,
  limit,
) => {
  if (text.length < limit) return text;
  return `${text.slice(0, limit)} ...`;
};

export const toPercentage: (
  number?: number,
  fractionDigits?: number,
) => string = (number = 0, fractionDigits = 0) => {
  return `${(number * 100).toFixed(fractionDigits)}%`;
};

export const toPercentageStatistics: (number?: number) => string = (
  number = 0,
) => {
  const percentage = number * 100;
  return `${
    percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1)
  }%`;
};

export const getScreenSize = () => {
  const w = window.innerWidth;
  const h = window.innerHeight;
  return { w, h };
};

export const createEMailToLink = (
  email = '',
  subject?: string,
  body?: string,
) => {
  return `mailto:${email}?subject=${subject || ''}&body=${encodeURIComponent(
    body || '',
  )}`;
};

export const getWordCount = (body: string): number => {
  if (!body) return 0;
  return body.split(/ |\n/).filter(word => word.length >= 1).length;
};

export const getRandomNumber = (from: number, to: number) => {
  return Math.floor(from + Math.random() * (to - from));
};

export const getRandomString = (length: number) => {
  const letters = 'abcdefghijoklmopqrtuvwxyz';
  return new Array(length)
    .fill(null)
    .map(() => letters[getRandomNumber(0, letters.length - 1)])
    .join('');
};

export const generateRedactedEmail = () => {
  return `${getRandomString(getRandomNumber(4, 10))}@redacted.email`;
};

/* Examples:
 * Receives: "****-****@****.***" Generates: "uikm-hypf@igeg.vxc"
 * Receives: "**** **** **** ***" Generates: "uhwq qokc hugi uex"
 */
export const generateObscuredContent = (
  content: string | null,
  shouldTruncate: boolean = false,
) => {
  if (!content) return '';

  if (content.length > 190 && shouldTruncate)
    content = truncateText(content, 191);

  return content
    .split(/(?=[-.@\s])?:|([-.@\s])/g)
    .map(chars =>
      chars && chars.includes('*') ? getRandomString(chars.length) : chars,
    )
    .join('');
  // return obscuredContent;
};

export const isNumber = (value: any) => {
  return !isNaN(value - parseFloat(value));
};

export const handleSocialIconClick = (url: string) => () => {
  if (url.match('@')) {
    openInNewTab(createEMailToLink(url));
  } else {
    openInNewTab(url);
  }
};

export const hexToRGBA = (hex: string, opacity: number): string => {
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
    return 'transparent';
  }

  let r: number;
  let g: number;
  let b: number;

  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  } else if (hex.length === 4) {
    // Handles shorthand Hex color code
    r = parseInt(hex.slice(1, 2).repeat(2), 16);
    g = parseInt(hex.slice(2, 3).repeat(2), 16);
    b = parseInt(hex.slice(3, 4).repeat(2), 16);
  } else {
    return 'transparent';
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
