import { FC } from 'react';
import SettingsHeader, { ISettingsHeaderProps } from './SettingsHeader';
import SettingsBar from './SettingsBar';
import FlexBlock from 'app/components/UI/FlexBlock';
import SettingsFooter from './SettingsFooter';
import Button from 'app/components/UI/Button/Button';

import { usePitchDataWithContext } from '../../hooks/usePitchDataWithContext';

const PredictionSettings: FC<ISettingsHeaderProps & { onPredict: () => void }> =
  props => {
    const { generationLimits } = usePitchDataWithContext();
    return (
      <FlexBlock
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100%"
      >
        <FlexBlock flexDirection="column" flex={1} minWidth="100%">
          {props.pitchMeta && <SettingsHeader pitchMeta={props.pitchMeta} />}

          <SettingsBar />
        </FlexBlock>
        <SettingsFooter>
          <Button
            onClick={props.onPredict}
            disabled={generationLimits.outOfPitchRuns}
          >
            Predict Interest
          </Button>
        </SettingsFooter>
      </FlexBlock>
    );
  };

export default PredictionSettings;
