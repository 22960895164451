import { FC } from 'react';
import FlexBlock from '../../FlexBlock';
import { SkeletonDiv } from '../Skeletons.styles';

interface IPodcastDemographicsSkeleton {}

const PodcastDemographicsSkeleton: FC<IPodcastDemographicsSkeleton> = () => {
  return (
    <FlexBlock
      flexDirection="column"
      maxWidth="90%"
      rowGap="8px"
      margin="20px auto"
    >
      <FlexBlock
        flexDirection="row"
        maxWidth="70%"
        alignItems="left"
        columnGap="8px"
      >
        <SkeletonDiv
          shimmerAnimation
          height="50px"
          borderRadius="8px"
          width="140px"
        />
        <SkeletonDiv
          shimmerAnimation
          height="50px"
          borderRadius="8px"
          width="140px"
        />
        <SkeletonDiv
          shimmerAnimation
          height="50px"
          borderRadius="8px"
          width="140px"
        />
      </FlexBlock>
      <FlexBlock
        flexDirection="column"
        alignItems="center"
        minWidth="100%"
        rowGap="8px"
      >
        <SkeletonDiv
          shimmerAnimation
          height="65px"
          borderRadius="8px"
          width="100%"
        />
        <SkeletonDiv
          shimmerAnimation
          height="65px"
          borderRadius="8px"
          width="100%"
        />
        <SkeletonDiv
          shimmerAnimation
          height="65px"
          borderRadius="8px"
          width="100%"
        />
      </FlexBlock>
    </FlexBlock>
  );
};

export default PodcastDemographicsSkeleton;
