import { IAuthorSearchResponse, IPaginationRequestPart } from 'app/api.types';
import { IDataTableColumn } from 'app/components/Table/Table.types';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import React from 'react';
import { handleMediaListExport } from './parts/Table/JournalistsTable.helpers';
import { MAX_JOURNALISTS_TO_EXPORT } from './parts/Table/JournalistsTable.constants';
import { useAppDispatch } from 'store/hooks';
import { useJournalistsSlice } from '../slice';
import { showErrorToast } from 'utils/toast';
import { HeaderProps } from 'react-table';
import { JournalistHeaderCell } from './parts/Table/shared/TableHeaderCells';
import {
  JournalistEmailCell,
  JournalistNameCell,
  JournalistOutletCell,
  JournalistSocialLinksCell,
} from './parts/Table/shared/TableDataCells';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import { logger } from 'utils/apm/logger';

interface IMovableRow {
  id: string;
  primaryOutlet?: {
    id: string;
  };
}

export const defaultSearchByPropertyKey = 'name';
export const journalistSearchDebounceTimeMs = 500;

export const defaultMediaListPagination: IPaginationRequestPart = {
  page: {
    page: 0,
    size: 10,
  },
  sort: [{ property: 'name', ascending: true }],
};

export const defaultJournalistsSearchPagination: IPaginationRequestPart = {
  page: {
    page: 0,
    size: 50,
  },
  sort: [{ property: 'name', ascending: true }],
};

export const defaultJournalistsFilters = {
  countries: [],
  states: [],
  outletScopeFilter: { inclusions: [], exclusions: [] },
  relevant: true,
};

const JournalistsHeaderActions = (
  props: React.PropsWithChildren<HeaderProps<IAuthorSearchResponse>>,
) => {
  const selectedRows = Object.keys(props.state.selectedRowIds).length > 0;
  const dispatch = useAppDispatch();
  const { actions } = useJournalistsSlice();

  const openAddToMediaListModal = () => {
    if (!props.rowsRef.current) {
      showErrorToast(
        'Something went wrong.',
        'Could not determine selected rows.',
      );
      logger.logError(
        'Journalists - Add to Media List - Missing reference for rows',
      );
      return;
    }

    const rows = Object.values(
      props.rowsRef.current as Record<string, IMovableRow>,
    )
      .filter(row => row.id && row.primaryOutlet && row.primaryOutlet.id)
      .map(row => ({
        authorId: row.id,
        mediaOutletId: row.primaryOutlet?.id,
      }));

    dispatch(actions.toggleAddToMediaListModal({ rows }));
  };

  if (!selectedRows) return <></>;

  return (
    <FlexBlock
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      columnGap="10px"
      flexWrap="wrap"
    >
      <IfHasPermission require={userPermissionEnum.READ_TARGET_MEDIA}>
        <Button
          compact
          disabled={
            Object.keys(props.state.selectedRowIds).length >
            MAX_JOURNALISTS_TO_EXPORT
          }
          padding="10px 8px"
          margin="0"
          variant="secondary"
          onClick={() => {
            handleMediaListExport(Object.keys(props.state.selectedRowIds));
          }}
        >
          Export Media List
        </Button>
      </IfHasPermission>
      <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
        <Button
          compact
          disabled={
            Object.keys(props.state.selectedRowIds).length >
            MAX_JOURNALISTS_TO_EXPORT
          }
          padding="10px 8px"
          margin="0"
          variant="secondary"
          onClick={() => {
            openAddToMediaListModal();
          }}
        >
          Add to Media List
        </Button>
      </IfHasPermission>
    </FlexBlock>
  );
};

export const journalistsListConfig: IDataTableColumn<IAuthorSearchResponse>[] =
  [
    {
      id: 'primaryOutlet.name',
      accessor: 'primaryOutlet',
      Header: <JournalistHeaderCell title="Primary Outlet" />,
      width: '25%',
      Cell: ({ row }) => (
        <JournalistOutletCell
          outletName={row?.original?.primaryOutlet?.name || ''}
        />
      ),
      disableSort: true,
    },
    {
      id: 'name',
      accessor: 'name',
      Header: <JournalistHeaderCell title="Journalist's Name" />,
      width: '20%',
      Cell: ({ row }) => (
        <JournalistNameCell
          name={row.original.name}
          journalistId={row.original.id}
          isFavorite={row.original.favorite}
          isContributor={row.original.contributor}
        />
      ),
    },
    {
      id: 'email',
      accessor: 'socials',
      Header: <JournalistHeaderCell title="Email" />,
      disableSort: true,
      width: '25%',
      Cell: ({ row }) => {
        const email = row?.original?.socials?.find(
          social => social?.socialType === 'PRIMARY_EMAIL',
        );

        if (!email || !email.handler) {
          return <></>;
        }

        return <JournalistEmailCell email={email.handler} />;
      },
    },
    {
      id: 'socials',
      accessor: 'socials',
      Header: props => (
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <JournalistHeaderCell title="Socials" />
          <JournalistsHeaderActions {...props} />
        </FlexBlock>
      ),
      disableSort: true,
      width: '30%',
      Cell: ({ row }) => {
        return <JournalistSocialLinksCell socials={row?.original?.socials} />;
      },
    },
  ];
