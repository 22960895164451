import { useGetAllTenantsByIdsQuery, useGetTenantsQuery } from 'app/api';
import { IClassifiedProperty, ITenant, ITenantBase } from 'app/api.types';
import AutoComplete from 'app/components/AutoComplete/AutoComplete';
import Typography from 'app/components/Typography';
import { selectActiveTenantId } from 'app/containers/Global/slice/selectors';
import { UserRoleEnum } from 'app/containers/Global/slice/types';
import { FC, useEffect, useMemo, useState } from 'react';

import { useAppSelector } from 'store/hooks';
import TenantOption from './TenantOption';

interface ITenantSelectorProps {
  editMode: boolean;
  canManageTenants: boolean;
  userRole: IClassifiedProperty<UserRoleEnum> | null;
  userTenant: ITenantBase | null;
  setSelectedTenant: (tenant: ITenantBase | null) => void;
}

const TENANT_SELECTOR_STYLE_PROPS = {
  $maxWidth: '350px',
  wrapperStyles: {
    padding: '7.5px',
    minHeight: '20px',
  },
};

const TenantSelector: FC<ITenantSelectorProps> = ({
  editMode,
  userRole,
  canManageTenants,
  userTenant,
  setSelectedTenant,
}) => {
  const activeTenantId = useAppSelector(selectActiveTenantId);
  const [tenantSearchValue, setTenantSearchValue] = useState<string>('');

  const [selectedOption, setSelectedOption] = useState<ITenantBase | null>(
    null,
  );

  const { data: tenants, isLoading: isLoadingTenants } = useGetTenantsQuery({
    name: tenantSearchValue,
    pagination: {
      page: { page: 0, size: 500 },
      sort: [{ property: 'name', ascending: true }],
    },
  });

  const { data: tenant, isLoading: isLoadingTenant } =
    useGetAllTenantsByIdsQuery(
      {
        ids: !canManageTenants ? [activeTenantId || 0] : undefined,
        pagination: {
          page: { page: 0, size: 1 },
          sort: [],
        },
      },
      { skip: canManageTenants || !editMode },
    );

  const currentTenant = useMemo(() => {
    if (tenant && tenant.content && tenant.content.length > 0) {
      return tenant.content[0];
    }
    return null;
  }, [tenant]);

  // Sync user tenant with selected option
  useEffect(() => {
    if (userTenant) {
      setSelectedOption(userTenant);
    }

    if (!userTenant) {
      setSelectedOption(null);
    }
  }, [userTenant]);

  const handleTenantChange = (tenant: ITenantBase) => {
    // Parent tenant state
    setSelectedTenant(tenant);

    // Clear the tenant search after selecting a tenant
    setTenantSearchValue('');
  };

  const tenantSelectorProps = useMemo(() => {
    const isAdminRole = userRole?.code === UserRoleEnum.SUPER_ADMIN;

    const readOnlyInput =
      !canManageTenants || isAdminRole || isLoadingTenant || isLoadingTenants;
    const disabled =
      !canManageTenants || isAdminRole || isLoadingTenant || isLoadingTenants;

    const getTenantSelectorPlaceholder = () => {
      if (isAdminRole) {
        return 'All Tenants';
      }

      if (selectedOption) {
        return selectedOption.name;
      }

      if (editMode && !canManageTenants) {
        return currentTenant?.name || '';
      }

      if (!editMode && !currentTenant && canManageTenants) {
        return 'Select a Tenant';
      }

      return userTenant?.name || '';
    };

    const placeholder = getTenantSelectorPlaceholder();

    const icon: 'right-carrot' | 'no-icon' =
      canManageTenants && !isAdminRole ? 'right-carrot' : 'no-icon';

    return {
      placeholder,
      icon,
      readOnlyInput,
      disabled,
    };
  }, [
    canManageTenants,
    currentTenant,
    userRole,
    editMode,
    selectedOption,
    userTenant?.name,
    isLoadingTenant,
    isLoadingTenants,
  ]);

  return (
    <>
      <Typography.Text
        $colorName="nero"
        $size={14}
        $lineHeight={20}
        $padding="16px 0 8px 0"
      >
        Access to Tenant
      </Typography.Text>
      <AutoComplete<ITenant>
        options={tenants?.content || []}
        noOptionsText="No tenants found"
        displayAccessor="name"
        onOptionSelect={handleTenantChange}
        searchValue={tenantSearchValue}
        maxMenuHeight={320}
        menuPadding="0"
        onSearchValueChange={value => setTenantSearchValue(value)}
        optionRenderFunction={(option, _, __, onClick) => (
          <TenantOption option={option} onClickHandler={onClick} />
        )}
        {...TENANT_SELECTOR_STYLE_PROPS}
        {...tenantSelectorProps}
        closeMenuAfterOptionSelect
        doNotUsePortal
      />
    </>
  );
};

export default TenantSelector;
