import styled from 'styled-components';

// Journalist Page Layout components

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;

export const ContentLayout = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.haze};
  overflow-y: auto;
  padding-bottom: 70px;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

// Journalist Filters components

export const FilterActionsWrapper = styled.div`
  position: relative;
  background: white;
  height: 72px;
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  border-top: 1px solid #e5e5e5;
  padding: 6px;
`;
