import { UserRoleEnum } from 'app/containers/Global/slice/types';

export const USER_PAYLOAD = {
  firstName: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  avatar: '',
  status: 'ACTIVE',
  role: UserRoleEnum.TENANT_USER,
};
