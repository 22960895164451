import {
  IApiResponse,
  ICategorySentimentStatistics,
  IEpisode,
  ILatestArticle,
  IOutlet,
  IPodcastDemographics,
} from '../../../../../../../api.types';
import { useMemo } from 'react';
import {
  useGetAuthorLatestArticlesQuery,
  useGetAuthorPodcastsQuery,
  useGetSimilarArticlesQuery,
} from '../../../../../../../api';
import { useAppSelector } from '../../../../../../../../store/hooks';
import {
  selectDigDeepeTargetMedia,
  selectDigDeeperReportItem,
} from '../../../../../slice/selectors';

export interface ICreatorSummary {
  articles?: {
    latest?: ILatestArticle[];
    relevant?: ILatestArticle[];
  };
  episodes?: IApiResponse<IEpisode[]>;
  demographics?: IPodcastDemographics;
  sentimentStatistics?: ICategorySentimentStatistics | null;
  outlets?: IOutlet[];
}

interface IHookResponse {
  data?: ICreatorSummary;
  isFetching: boolean;
  isError: boolean;
}

type UseAuthorSummaryHookType = (
  payload: { authorId?: string; podcastId?: string },
  options?: { skip: boolean },
) => IHookResponse;

export const useAuthorSummary: UseAuthorSummaryHookType = (
  payload,
  options,
) => {
  const reportItem = useAppSelector(selectDigDeeperReportItem);
  const reportTargetMedia = useAppSelector(selectDigDeepeTargetMedia);

  const {
    data: authorLatestArticlesData,
    isFetching: isAuthorLatestArticlesFetching,
    isError: isAuthorLatestArticlesError,
  } = useGetAuthorLatestArticlesQuery(
    {
      authorId: payload.authorId,
      size: 10,
      reportItemId: reportItem?.id || '',
      targetMediaId: reportTargetMedia?.id || '',
    },
    { skip: options?.skip || !!payload.podcastId },
  );

  const {
    data: latestSimilarArticles,
    isFetching: isFetchingLatestSimilarArticles,
    isError: isLatestSimilarArticlesError,
  } = useGetSimilarArticlesQuery(
    {
      authorId: payload.authorId || '',
      size: 10,
      reportItemId: reportItem?.id || '',
    },
    {
      skip:
        options?.skip ||
        !authorLatestArticlesData ||
        !authorLatestArticlesData.havingSimilarityArticles ||
        !!payload.podcastId,
    },
  );

  const {
    data: podcastData,
    isFetching: isPodcastDataFetching,
    isError: isPodcastDataError,
  } = useGetAuthorPodcastsQuery(
    { authorId: payload.authorId, podcastId: payload.podcastId, size: 50 },
    { skip: options?.skip || !payload.podcastId },
  );

  const responseData: undefined | ICreatorSummary = useMemo(() => {
    if (payload.podcastId) {
      if (!podcastData) return undefined;
      return {
        episodes: podcastData.episodes || [],
        demographics: podcastData.demographics,
        outlets: podcastData.author.outlets,
      };
    }

    if (!authorLatestArticlesData) return undefined;

    return {
      articles: {
        latest: authorLatestArticlesData?.latestArticles.content || [],
        relevant: latestSimilarArticles?.content || [],
      },
      sentimentStatistics: authorLatestArticlesData?.categorySentimentStatistic,
      outlets: authorLatestArticlesData?.author.outlets,
    };
  }, [
    authorLatestArticlesData,
    latestSimilarArticles,
    podcastData,
    payload.podcastId,
  ]);

  const isFetching: boolean = useMemo(() => {
    return (
      isAuthorLatestArticlesFetching ||
      isFetchingLatestSimilarArticles ||
      isPodcastDataFetching
    );
  }, [
    isAuthorLatestArticlesFetching,
    isFetchingLatestSimilarArticles,
    isPodcastDataFetching,
  ]);

  const isError: boolean = useMemo(() => {
    return isAuthorLatestArticlesError || isPodcastDataError;
  }, [isAuthorLatestArticlesError, isPodcastDataError]);

  const response: IHookResponse = useMemo(() => {
    return {
      data: responseData,
      isFetching,
      isError,
    };
  }, [responseData, isError, isFetching]);

  return response;
};
