import FlexBlock from 'app/components/UI/FlexBlock';
import PageLoader from 'app/components/UI/PageLoader';
import { useTaylorSettingsOptions } from './hooks/useTaylorSettingsOptions';
import * as S from './TaylorSettings.styles';
import TaylorSettingSection from './TaylorSettingSection';

const TaylorSettings = () => {
  const { defaultOptions, options, isFetching } = useTaylorSettingsOptions();
  return (
    <FlexBlock flexDirection="column" margin="25px 25px 80px" minWidth="90%">
      {!options && isFetching && <PageLoader />}
      {options && (
        <>
          <S.Divider />
          <TaylorSettingSection
            settingVariant={'pitch'}
            settings={options.pitchContentOptions}
            value={defaultOptions?.pitchContent || undefined}
          />
          <S.Divider />
          <TaylorSettingSection
            settingVariant={'email'}
            settings={options.authorEmailOptions}
            value={defaultOptions?.authorEmail || undefined}
          />
        </>
      )}
    </FlexBlock>
  );
};

export default TaylorSettings;
