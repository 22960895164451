import Button from 'app/components/UI/Button/Button';
import { RouteConstants } from 'app/routes';
import { FC, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';

const EditPitchButton: FC = () => {
  const params: {
    tenantId: string;
    projectId: string;
    pitchId?: string;
    pressReleaseId?: string;
    versionId: string;
  } = useParams();

  const [tenantId, projectId, pitchId, pressReleaseId, versionId] = useMemo(
    () => [
      Number(params.tenantId),
      Number(params.projectId),
      Number(params.pitchId),
      Number(params.pressReleaseId),
      Number(params.versionId),
    ],
    [params],
  );

  const routeToEditPredictedContent = useMemo(() => {
    if (pressReleaseId) {
      return RouteConstants.pressRelease.makeEditPressReleaseUrl(
        tenantId,
        projectId,
        pressReleaseId,
        versionId,
      );
    }

    return RouteConstants.pitch.makeEditPitchUrl(
      tenantId,
      projectId,
      pitchId,
      versionId,
    );
  }, [tenantId, projectId, pitchId, pressReleaseId, versionId]);

  return (
    <NavLink to={routeToEditPredictedContent}>
      <Button>Edit {pressReleaseId ? 'Press Release' : 'Pitch'}</Button>
    </NavLink>
  );
};

export default EditPitchButton;
