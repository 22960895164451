import { Anchor } from 'app/components/UI/Anchor';
import Input from 'app/components/UI/Input/Input';
import styled, { css } from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  border-bottom: ${({ theme }) => theme.colors.smoke} 1px solid;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 16px;
`;

export const SectionFlexEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  padding: 24px;
`;

export const ProjectInput = styled(Input)`
  height: 40px;
  border-radius: 8px;
`;

export const Header = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.smoke};
`;

export const AddLogoAnchor = styled(Anchor)<{
  $disabled: boolean;
  $color?: string;
}>(
  ({ theme, $disabled, $color }) => css`
    color: ${$disabled ? theme.colors.smoke : $color || theme.colors.onyx};
    text-underline-position: under;
    &:hover {
      color: ${theme.colors.orbit};
    }
  `,
);
