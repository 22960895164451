import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { PitchPageContext } from '../../PitchPage.context';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Reasons from './parts/Reasons';
import styled from 'styled-components';
import { Anchor } from 'app/components/UI/Anchor';
import Button from 'app/components/UI/Button/Button';
import {
  ContentLengthDropDown,
  ToneSelectDropDown,
} from '../../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import {
  CONTENT_LENGTH_SELECT_OPTIONS,
  TONE_SELECT_OPTIONS,
} from '../../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import {
  IAuthorEmailSuggestionsResponse,
  IJournalistReportItemV31,
  IPodcasterReportItemV31,
  IReportItemV31,
} from 'app/api.types';
import RegenerateIcon from 'app/assets/icons/RegenerateIcon';
import SendIcon from 'app/assets/icons/SendIcon';
import useEmailSuggestionsRequestBody from './hooks/useEmailSuggestionsRequestBody';
import { SpinningAnimation } from 'app/components/UI/SpinningAnimation';
import { createEMailToLink } from 'utils/helpers';
import CarrotTopIcon from 'app/assets/icons/CarrotTopIcon';
import { useSuggestionsData } from './hooks/useSuggestionsData';
import { theme } from 'styles/theme';
import { useAppDispatch } from 'store/hooks';
import { api } from 'app/api';
import {
  getPublicationTitle,
  getReporterName,
} from './MultiGenerationResults.helpers';

const ResultAccordionContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #e4e4e9;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
`;

const Content: FC<{
  isExpanded: boolean;
  reportItem: IReportItemV31;
  suggestionData?: IAuthorEmailSuggestionsResponse;
}> = ({ isExpanded, reportItem, suggestionData }) => {
  const [expanded, setExpanded] = useState(false);

  const { setLocalTonesForRegeneration, setLocalLengthsForRegeneration } =
    useContext(PitchPageContext);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const emailSuggestion = useMemo(() => {
    if (!suggestionData) return { subject: '', pitch: '' };
    const subject =
      suggestionData.subject && suggestionData.subject.length > 0
        ? suggestionData.subject[0]
        : '';
    const pitch =
      suggestionData.pitch && suggestionData.pitch.length > 0
        ? suggestionData.pitch[0]
        : '';

    return { subject, pitch };
  }, [suggestionData]);

  const emailRecipient =
    (reportItem as IJournalistReportItemV31).journalist?.email ||
    (reportItem as IPodcasterReportItemV31).podcaster?.email ||
    '';

  const sendEmail = createEMailToLink(
    emailRecipient,
    emailSuggestion.subject,
    emailSuggestion.pitch,
  );

  const findToneOption = (value?: string) => {
    if (!value) return undefined;
    const option = TONE_SELECT_OPTIONS.find(
      option => option.value === value.toLowerCase(),
    );
    return option;
  };

  const findLengthOption = (value?: string) => {
    if (!value) return undefined;
    const option = CONTENT_LENGTH_SELECT_OPTIONS.find(
      option => option.value === value,
    );
    return option;
  };

  const [toneOption, setToneOption] = useState(
    findToneOption(suggestionData?.tone) || TONE_SELECT_OPTIONS[0],
  );

  const [lengthOption, setLengthOption] = useState(
    findLengthOption(suggestionData?.authorEmailLength) ||
      CONTENT_LENGTH_SELECT_OPTIONS[0],
  );

  useEffect(() => {
    setLocalTonesForRegeneration(state => ({
      ...state,
      [reportItem.id]: toneOption,
    }));
  }, [toneOption]);

  useEffect(() => {
    setLocalLengthsForRegeneration(state => ({
      ...state,
      [reportItem.id]: lengthOption,
    }));
  }, [lengthOption]);

  const { toggleGeneration, payload } = useSuggestionsData();

  const handleRegenerationClick = () => {
    if (!payload) return;
    const requestBody = {
      ...payload,
      details: payload.details.map(item =>
        item.reportItemId === reportItem.id
          ? {
              ...item,
              regenerated: true,
              tone: toneOption.value,
              authorEmailLength: lengthOption.value,
            }
          : { ...item, regenerated: false },
      ),
    };
    toggleGeneration(requestBody, false);
  };

  return !suggestionData ? (
    <></>
  ) : (
    <ResultAccordionContainer>
      <FlexBlock flexDirection="row" alignItems="center">
        <Button
          variant="gray-smoke-square"
          onClick={() => setExpanded(state => !state)}
        >
          <CarrotTopIcon rotateDown={!expanded} />
        </Button>
        <FlexBlock flexDirection="column" margin="0 0 0 10px">
          <Typography.Text
            $bold
            $dmSans
            $size={16}
            $lineHeight={24}
            $colorName="onyx"
          >
            {getReporterName(reportItem)}
          </Typography.Text>
          <Typography.Text $size={14} $lineHeight={16}>
            {getPublicationTitle(reportItem)}
          </Typography.Text>
        </FlexBlock>
      </FlexBlock>
      <FlexBlock flexDirection="row" columnGap="5px">
        <ToneSelectDropDown value={toneOption} onChange={setToneOption} />
        <ContentLengthDropDown
          value={lengthOption}
          onChange={setLengthOption}
        />
        <Button variant="gray-smoke-square" onClick={handleRegenerationClick}>
          <RegenerateIcon fill={theme.colors.nero} />
        </Button>
        <Anchor href={sendEmail} target="_blank" rel="noopener noreferrer">
          <Button variant="blue-square" tooltipText="Draft Email">
            <SendIcon />
          </Button>
        </Anchor>
      </FlexBlock>
      {expanded && (
        <>
          <FlexBlock
            flexDirection="row"
            minWidth="100%"
            justifyContent="space-between"
          >
            <FlexBlock flexDirection="column" padding="20px">
              <Typography.Text
                $lineHeight={24}
                $bold
                $dmSans
                $colorName="onyx"
                $size={18}
                $margin="16px"
              >
                {emailSuggestion.subject}
              </Typography.Text>
              <Typography.Text
                $colorName="nero"
                $lineHeight={24}
                $size={16}
                $padding="16px"
              >
                {emailSuggestion.pitch}
              </Typography.Text>
            </FlexBlock>
            <FlexBlock
              padding="20px"
              flexDirection="column"
              alignItems="stretch"
              minWidth="40%"
            >
              {suggestionData?.reasons && suggestionData.reasons.length > 0 && (
                <Reasons reasons={suggestionData?.reasons} />
              )}
            </FlexBlock>
          </FlexBlock>
        </>
      )}
    </ResultAccordionContainer>
  );
};

export const MultiGenerationResults: FC = () => {
  const {
    selectedReportItems,
    contentLengthOptions,
    contentToneOptions,
    setIsGenerating,
    globalTone,
    globalLength,
  } = useContext(PitchPageContext);

  const [defaultExpanded, setDefaultExpanded] = useState(false);

  const requestBody = useEmailSuggestionsRequestBody({
    reportItems: selectedReportItems,
    tones: contentToneOptions,
    lengths: contentLengthOptions,
    tone: globalTone,
    length: globalLength,
  });

  const { data: suggestionData, isLoading, setPayload } = useSuggestionsData();

  useEffect(() => {
    setPayload(requestBody);
  }, [requestBody]);

  useEffect(() => {
    setIsGenerating(isLoading);
  }, [isLoading]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (suggestionData && suggestionData.length > 0) {
      dispatch(api.util.invalidateTags(['pitch-prediction']));
    }
  }, [suggestionData, dispatch]);

  if ((!suggestionData || suggestionData.length < 1) && !isLoading)
    return <></>;

  return suggestionData && !isLoading ? (
    <>
      <FlexBlock
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        margin="32px 0 24px 0"
      >
        <FlexBlock flexDirection="column">
          <Typography.Text
            $bold
            $size={36}
            $dmSans
            $lineHeight={40}
            $colorName="onyx"
          >
            Multi-Pitch Generation
          </Typography.Text>
          <Typography.Text $size={16} $lineHeight={24} $colorName="steel">
            Review your personalized pitches
          </Typography.Text>
        </FlexBlock>
        <Anchor onClick={() => setDefaultExpanded(state => !state)}>
          {!defaultExpanded ? 'Expand All' : 'Collapse All'}
        </Anchor>
      </FlexBlock>

      {selectedReportItems.map(item => {
        return (
          <Content
            key={item.id}
            reportItem={item}
            suggestionData={suggestionData.find(
              suggestion => suggestion.reportItemId === item.id,
            )}
            isExpanded={defaultExpanded}
          />
        );
      })}
    </>
  ) : (
    <FlexBlock
      padding="64px 24px"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <SpinningAnimation>
        <RegenerateIcon fill="#1D76DE" size="64px" />
      </SpinningAnimation>

      <Typography.Text
        $lineHeight={20}
        $colorName="steel"
        maxWidth="360px"
        textAlign="center"
        $padding="36px"
      >
        Sit tight, we need a few minutes to personalize this pitch that would
        have taken you way longer to draft on your own
      </Typography.Text>
    </FlexBlock>
  );
};
