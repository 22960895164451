import ArrowRightIcon from 'app/assets/icons/ArrowRightIcon';
import Typography from 'app/components/Typography';
import { Anchor } from 'app/components/UI/Anchor';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import { isPitchContentValid } from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitch.helpers';
import {
  PitchStateActions,
  usePitch,
  usePitchDispatch,
} from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/PitchContext';
import { RouteConstants } from 'app/routes';
import { FC, ReactNode, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { theme } from 'styles/theme';

interface IHeaderActionsProps {
  headerNotice?: ReactNode | undefined;
  firstPitch?: boolean;
}

export const Actions: FC<IHeaderActionsProps> = ({
  headerNotice,
  firstPitch,
}) => {
  const history = useHistory();

  const pitchState = usePitch();
  const dispatch = usePitchDispatch();

  const hasGeneratedContent = useMemo(() => {
    return Boolean(
      pitchState.pitchContents.find(item => item.contentName === 'generated'),
    );
  }, [pitchState.pitchContents]);

  const canRunPrediction = useMemo(() => {
    if (hasGeneratedContent && !pitchState.selectedPitchContent) return false;
    return true;
  }, [hasGeneratedContent, pitchState.selectedPitchContent]);

  const params: {
    tenantId: string;
    projectId: string;
  } = useParams();

  const [tenantId, projectId] = useMemo(
    () => [Number(params.tenantId), Number(params.projectId)],
    [params],
  );

  const getSelectedPitchContent = () => {
    if (pitchState.selectedPitchContent) {
      return pitchState.pitchContents.find(
        pc => pc.contentName === pitchState.selectedPitchContent,
      );
    }
    return pitchState.pitchContents.find(pc => pc.contentName === 'original');
  };

  const handleCancel = () => {
    history.push(RouteConstants.projects.makeContentUrl(tenantId, projectId));
  };

  const toggleSettings = () => {
    // Validate selected content before opening Predict panel
    const isValid = isPitchContentValid(getSelectedPitchContent() || null);

    if (!isValid) return;

    dispatch({
      type: PitchStateActions.TOGGLE_SETTINGS,
      payload: !pitchState.settingsOpen,
    });
  };

  if (firstPitch)
    return (
      <FlexBlock flexDirection="row" alignItems="center" margin="0 0 0 auto">
        <Anchor onClick={toggleSettings}>
          <Typography.Text
            $dmSans
            $bold
            $size={18}
            $lineHeight={24}
            $colorName="orbit"
          >
            Ready to Predict
          </Typography.Text>
        </Anchor>
        <Gap size={8} />
        <ArrowRightIcon color={theme.colors.orbit} />
      </FlexBlock>
    );

  return (
    <FlexBlock flexDirection="row" alignItems="center">
      {headerNotice && (
        <>
          {headerNotice}
          <Gap size={12} />
        </>
      )}
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Gap size={12} />
      <Button onClick={toggleSettings} disabled={!canRunPrediction}>
        Predict Interest
      </Button>
    </FlexBlock>
  );
};
