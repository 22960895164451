import MainGrid from 'app/components/UI/MainGrid/MainGrid';
import { FC, useEffect, useState } from 'react';
import LoginForm from '../../parts/LoginForm/LoginForm';
import * as S from './LoginPage.styles';
import ShowIfDesktop from '../../../../../utils/responsiveQuery/ShowIfDesktop';
import useQuery from '../../../../../utils/hooks/useQuery';
import MfaForm from '../../parts/MfaFrom/MfaFrom';
import LogoAndCopyright from '../../parts/LogoAndCopyright/LogoAndCopyright';
import { FEATURE_FLAG_ENABLE_SSO } from 'utils/constants';
import SSOForm from '../../parts/LoginForm/SSOForm';
import { localStorageEnum } from 'types';
import Analytics from 'utils/analytics';
import { useAppDispatch } from 'store/hooks';
import { globalActions } from 'app/containers/Global/slice';
import { RouteConstants } from 'app/routes';

interface IProps {}

const LoginPage: FC<IProps> = props => {
  const [mfaToken, setMfaToken] = useState<string | null>(null);
  const query = useQuery();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (query?.mfaToken) {
      setMfaToken(query.mfaToken);
    } else if (query?.accessToken) {
      localStorage.setItem(localStorageEnum.token, query.accessToken);
      Analytics.track.login();

      dispatch(
        globalActions.authorizeUser({
          redirectUrl: RouteConstants.pitch.createFirstPitch,
        }),
      );
    } else {
      setMfaToken(null);
    }
  }, [query, dispatch]);

  const getLoginComponent = () => {
    if (query?.email) {
      return <LoginForm email={query.email} />;
    }

    if (FEATURE_FLAG_ENABLE_SSO) {
      return <SSOForm />;
    }

    return <LoginForm />;
  };

  return (
    <>
      <S.AuthBackgroundGlobalStyle />
      <LogoAndCopyright />
      <MainGrid fullHeight>
        <S.LoginFormContainer>
          {mfaToken ? <MfaForm mfaToken={mfaToken} /> : getLoginComponent()}
        </S.LoginFormContainer>
      </MainGrid>
      <ShowIfDesktop>
        <S.BackgroundContainer>
          <S.LeftPart />
          <S.RightPart />
        </S.BackgroundContainer>
      </ShowIfDesktop>
    </>
  );
};

export default LoginPage;
