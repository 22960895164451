import { FC, useState } from 'react';
import * as S from './MonitorAlertsPage.styles';
import PageLoader from '../UI/PageLoader';
import { useMonitorCookies } from './useMonitorCookie';
import { MONITOR_URL } from 'utils/constants';

interface IProps {}

const MonitorAlertsPage: FC<IProps> = () => {
  const { isMonitorCookieSet } = useMonitorCookies();

  const [iframeLoaded, setIframeLoaded] = useState(false);

  if (!isMonitorCookieSet) return <></>;

  return (
    <S.Wrapper>
      {!iframeLoaded && <PageLoader absolute />}
      <S.IFrame
        src={MONITOR_URL}
        onLoad={() => setIframeLoaded(true)}
        $loaded={iframeLoaded}
      />
    </S.Wrapper>
  );
};

export default MonitorAlertsPage;
