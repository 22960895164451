import Typography from 'app/components/Typography';
import Gap from 'app/components/UI/Gap';
import { FC, useMemo } from 'react';
import { getWordCount } from 'utils/helpers';

interface ISocialInputValidationMessageProps {
  input?: string;
  hidden?: boolean;
  requiredWords?: number;
}

const SocialInputValidationMessage: FC<ISocialInputValidationMessageProps> = ({
  input,
  hidden = false,
  requiredWords = 1,
}) => {
  const wordsCountLabel = useMemo(
    () => (requiredWords === 1 ? 'word' : 'words'),
    [requiredWords],
  );

  const isInputValid = useMemo(() => {
    if (!input) return false;

    const wordCount = getWordCount(input);
    return wordCount >= requiredWords;
  }, [input, requiredWords]);

  if (hidden) return <Gap size={24} />;

  return (
    <Typography.Text
      $colorName={isInputValid ? 'kermit' : 'steel'}
      $margin="8px 0 0 0"
      $size={14}
      $lineHeight={16}
      $letterSpacing="0.004em"
    >
      At least {requiredWords} {wordsCountLabel} is required.
    </Typography.Text>
  );
};

export default SocialInputValidationMessage;
