import * as S from './MultiSelect.styles';
import { MouseEventHandler, ReactNode, useMemo, useRef, useState } from 'react';
import CarrotDownIcon from '../../../assets/icons/CarrotDownIcon';
import ClickAwayListener from 'react-click-away-listener';
import Pill from '../Pill';
import FlexBlock from '../FlexBlock';
import Typography from 'app/components/Typography';
import { Spinner } from '../Spinner';

export interface IMultiSelectOption {
  code: string;
  displayName: string;
  availableOptions?: number;
}

interface IProps {
  options: IMultiSelectOption[];
  selectedOptions: IMultiSelectOption[];
  onUpdate: (newOptions: IMultiSelectOption[]) => void;
  icon?: ReactNode;
  hideCarrot?: boolean;
  height?: number;
  maxMenuHeight?: number;
  placeholder?: string;
  zIndex?: number;
  searchable?: boolean;
  isFetchingOptions?: boolean;
}

const MultiSelect = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const menuSearchRef = useRef<HTMLInputElement | null>(null);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleOptionAdd = (option: IMultiSelectOption) => () => {
    if (optionFilter) setOptionFilter('');
    props.onUpdate([...props.selectedOptions, option]);
  };

  const handleOptionRemove: (
    option: IMultiSelectOption,
  ) => MouseEventHandler<HTMLDivElement> = option => event => {
    event.stopPropagation();

    props.onUpdate(
      props.selectedOptions.filter(
        selectedOption => selectedOption.code !== option.code,
      ),
    );
  };

  const [optionFilter, setOptionFilter] = useState<string>('');

  const optionsFiltered: IMultiSelectOption[] = useMemo(() => {
    const availableOptions = props.options
      .filter(
        option =>
          !props.selectedOptions.find(
            selectedOption => selectedOption.code === option.code,
          ),
      )
      .sort((a, b) => a.displayName.localeCompare(b.displayName));

    if (!optionFilter) return availableOptions;

    return availableOptions.filter(o =>
      String(o.displayName).toLowerCase().includes(optionFilter.toLowerCase()),
    );
  }, [props.options, props.selectedOptions, optionFilter]);

  const handleOptionSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOpen) setIsOpen(true);
    // This makes input element width dynamic
    e.target.style.width = '20px';
    e.target.style.width = `${e.target.scrollWidth}px`;
    setOptionFilter(e.target.value);
  };

  const handleSelectionDeleteKeyUpEvent = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (props.selectedOptions.length < 1) return;

    if (!optionFilter && (e.key === 'Backspace' || e.key === 'Delete')) {
      props.onUpdate(props.selectedOptions.slice(0, -1));
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <S.Wrapper>
        <S.Input
          onClick={() => {
            setIsOpen(open => !open);
            menuSearchRef.current?.focus();
          }}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
        >
          {props.icon || null}
          <S.Tags>
            {props.placeholder &&
              !isSearchFocused &&
              props.selectedOptions.length < 1 && (
                <FlexBlock alignItems="center">
                  <Typography.Text
                    $size={14}
                    $lineHeight={16}
                    $colorName="onyx"
                  >
                    {props.placeholder}
                  </Typography.Text>
                </FlexBlock>
              )}
            {props.selectedOptions.map(option => (
              <Pill
                inline
                variant={'light'}
                key={option.displayName}
                showCloseIcon
                onClick={handleOptionRemove(option)}
              >
                {option.displayName}
              </Pill>
            ))}
            {props.searchable && (
              <FlexBlock alignItems="center">
                <S.MenuSearch
                  ref={menuSearchRef}
                  value={optionFilter}
                  onChange={handleOptionSearch}
                  onKeyUp={handleSelectionDeleteKeyUpEvent}
                />
              </FlexBlock>
            )}
          </S.Tags>

          {!props.hideCarrot && !props.isFetchingOptions && <CarrotDownIcon />}
          {props.isFetchingOptions && (
            <FlexBlock alignItems="center">
              <Spinner $size="14px" $isBlue />
            </FlexBlock>
          )}
        </S.Input>
        {isOpen && (
          <S.Menu $maxMenuHeight={props.maxMenuHeight} $zIndex={props.zIndex}>
            {optionsFiltered.length < 1 ? (
              <FlexBlock alignItems="center" justifyContent="center">
                <Typography.Text>No available options</Typography.Text>
              </FlexBlock>
            ) : (
              optionsFiltered.map(option => (
                <S.MenuOption
                  onClick={handleOptionAdd(option)}
                  disabled={
                    option.availableOptions === 0 || props.isFetchingOptions
                  }
                  key={option.code}
                >
                  <FlexBlock
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div>{option.displayName}</div>
                    <div>{option.availableOptions}</div>
                  </FlexBlock>
                </S.MenuOption>
              ))
            )}
          </S.Menu>
        )}
      </S.Wrapper>
    </ClickAwayListener>
  );
};

export default MultiSelect;
