import styled, { css } from 'styled-components';

interface ITableTabsProps<T> {
  options: { label: string; key: T }[];
  activeOption: { label: string; key: T };
  setActiveOption: (option: { label: string; key: T }) => void;
  isLoading?: boolean;
}

export const TabContentWrapper = styled.div<{ isActive: boolean }>`
  padding: 0.3rem;
  padding-bottom: 0px;
  opacity: ${props => (props.isActive ? 1 : 0)};
  visibility: ${props => (props.isActive ? 'visible' : 'hidden')};
  transition: opacity 0.3s, visibility 0.3s;
  overflow: hidden;
  max-height: ${props => (props.isActive ? '20rem' : '0')};
  transition: max-height 0.5s ease-in-out;
`;

export const TabsContainer = styled.div`
  background-color: white;
  padding-top: 0.5rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  padding-bottom: 0.2rem;
  border: solid 1px #e4e4e9;
  border-bottom: none;
  z-index: 1;
`;

export const selectedItemStyles = css`
  background-color: #e7f1fc;
  color: #287de0;
  border-radius: 0.7rem;
  transition: opacity 0.3s, visibility 0.3s;
`;

export const itemStyles = css`
  color: black;
  background-color: white;
  transition: opacity 0.3s, visibility 0.3s;
`;

export const TabItem = styled.div<{
  label: string;
  isActive: boolean;
}>`
  transition: background-color 0.3s, color 0.3s;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: bold;
  ${props => (props.isActive ? selectedItemStyles : itemStyles)};

  cursor: pointer;
  font-weight: bold;
  /* opacity: ${props => (props.isActive !== false ? 1 : 0.25)}; */
`;

function TableTabs<T>({
  options,
  activeOption,
  setActiveOption,
  isLoading,
}: ITableTabsProps<T>) {
  return (
    <TabsContainer>
      {options.map((option, index) => (
        <TabItem
          key={index}
          onClick={() => setActiveOption(option)}
          isActive={activeOption.label === option.label}
          label={option.label}
        >
          {option.label}
        </TabItem>
      ))}
    </TabsContainer>
  );
}

export default TableTabs;
