import { FC, SVGProps } from 'react';

const RegenerateIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_326_5895)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666667 2C1.03486 2 1.33333 2.29848 1.33333 2.66667V6H4.66667C5.03486 6 5.33333 6.29848 5.33333 6.66667C5.33333 7.03486 5.03486 7.33333 4.66667 7.33333H0.666667C0.298477 7.33333 0 7.03486 0 6.66667V2.66667C0 2.29848 0.298477 2 0.666667 2Z"
          fill="#3F3F42"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6665 9.33366C10.6665 8.96547 10.965 8.66699 11.3332 8.66699H15.3332C15.7014 8.66699 15.9998 8.96547 15.9998 9.33366V13.3337C15.9998 13.7018 15.7014 14.0003 15.3332 14.0003C14.965 14.0003 14.6665 13.7018 14.6665 13.3337V10.0003H11.3332C10.965 10.0003 10.6665 9.70185 10.6665 9.33366Z"
          fill="#3F3F42"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.1513 1.59749C7.23311 1.28457 8.37655 1.2508 9.47493 1.49933C10.5733 1.74785 11.5908 2.27057 12.4326 3.0187C13.2743 3.76684 13.9128 4.71601 14.2885 5.77765C14.4113 6.12475 14.2295 6.5057 13.8824 6.62853C13.5353 6.75135 13.1544 6.56955 13.0315 6.22245C12.731 5.37314 12.2202 4.6138 11.5468 4.01529C10.8734 3.41678 10.0594 2.99861 9.18069 2.79979C8.30198 2.60097 7.38723 2.62798 6.52178 2.87831C5.65634 3.12864 4.86841 3.59413 4.23152 4.23134C4.2266 4.23626 4.2216 4.24112 4.21652 4.24589L1.12319 7.15255C0.854867 7.40468 0.432961 7.39155 0.180833 7.12324C-0.0712944 6.85492 -0.0581685 6.43301 0.210151 6.18088L3.29607 3.28118C4.09084 2.48856 5.07293 1.9094 6.1513 1.59749ZM15.8192 8.87687C16.0713 9.14519 16.0582 9.56709 15.7899 9.81922L12.7039 12.7189C11.9092 13.5115 10.9271 14.0907 9.8487 14.4026C8.7669 14.7155 7.62346 14.7493 6.52507 14.5008C5.42668 14.2523 4.40915 13.7295 3.56743 12.9814C2.7257 12.2333 2.0872 11.2841 1.71152 10.2224C1.5887 9.87535 1.77051 9.4944 2.1176 9.37157C2.4647 9.24875 2.84565 9.43055 2.96848 9.77765C3.26902 10.627 3.77982 11.3863 4.4532 11.9848C5.12658 12.5833 5.94061 13.0015 6.81932 13.2003C7.69802 13.3991 8.61278 13.3721 9.47822 13.1218C10.3437 12.8715 11.1316 12.406 11.7685 11.7688C11.7714 11.7658 11.7744 11.7629 11.7774 11.76C11.7794 11.758 11.7815 11.7561 11.7835 11.7542L14.8768 8.84755C15.1451 8.59542 15.567 8.60855 15.8192 8.87687Z"
          fill="#3F3F42"
        />
      </g>
      <defs>
        <clipPath id="clip0_326_5895">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RegenerateIcon;
