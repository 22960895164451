import Input from 'app/components/UI/Input/Input';
import { ContentInputWrapper } from 'app/containers/Blog/pages/CreateOrEditBlog/CreateOrEditBlogPage.styles';
import { FC } from 'react';
import { PressReleaseInputsGroup } from './PressReleaseInputsGroup';

interface IPressReleaseFieldProps {
  fieldTitle: string;
  fieldSubtitle?: string;
  fieldIndex: number;
  placeholder?: string;
  value?: string;
  valueKey?: string;
  onChange?: (key: string, value: string) => void;
}

const PressReleaseField: FC<IPressReleaseFieldProps> = ({
  fieldIndex,
  fieldTitle,
  fieldSubtitle = '',
  value = '',
  valueKey,
  onChange,
  placeholder,
  children,
}) => {
  return (
    <PressReleaseInputsGroup
      index={fieldIndex}
      title={fieldTitle}
      compact
      subtitle={fieldSubtitle}
      noInline
    >
      <ContentInputWrapper>
        {children ? (
          children
        ) : (
          <Input
            value={value}
            onChange={e => onChange && onChange(valueKey || '', e.target.value)}
            placeholder={placeholder}
            variant="contentInput"
          />
        )}
      </ContentInputWrapper>
    </PressReleaseInputsGroup>
  );
};

export default PressReleaseField;
