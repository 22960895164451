import styled, { css } from 'styled-components/macro';

interface ICloseIconProps {
  size?: number;
  orbit?: boolean;
}

export const CloseIcon = styled.div<ICloseIconProps>(
  ({ theme, size = 14, orbit = false }) => css`
    width: ${size}px;
    height: ${size}px;
    background-color: transparent;
    cursor: pointer;
    position: relative;

    &:before,
    &:after {
      width: 100%;
      height: 2px;
      border-radius: 1px;
      content: '';
      background-color: ${orbit ? theme.colors.orbit : theme.colors.nero};
      top: 50%;
      left: 0;
      position: absolute;
    }

    &:before {
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  `,
);
