import { useState } from 'react';
import { SearchJournalistsByKeys } from '../parts/Header/JournalistsHeader';
import {
  defaultSearchByPropertyKey,
  journalistSearchDebounceTimeMs,
} from '../JournalistsListPage.constants';
import useDebounce from 'utils/hooks/useDebounce';

export const useJournalistsSearch = () => {
  const [searchParameter, setSearchParameter] = useState('');
  const [searchByJournalistProperty, setSearchByJournalistProperty] =
    useState<SearchJournalistsByKeys>(defaultSearchByPropertyKey);

  const debouncedSearchParamater = useDebounce(
    searchParameter,
    journalistSearchDebounceTimeMs,
  );

  const onSearchChange = (e: string) => {
    setSearchParameter(e);
  };

  const onSearchByChange = (e: SearchJournalistsByKeys) => {
    setSearchByJournalistProperty(e);
  };

  return {
    debouncedSearchParamater,
    searchParameter,
    searchByJournalistProperty,
    onSearchChange,
    onSearchByChange,
  };
};
