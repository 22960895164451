import styled, { css } from 'styled-components/macro';

const Tabs = {
  Bar: styled.div<{ $padding?: string }>(
    ({ theme, $padding }) => css`
      display: flex;
      border-bottom: 1px solid ${theme.colors.smoke};
      width: 100%;
      grid-column-start: 1;
      grid-column-end: -1;

      ${$padding &&
      css`
        padding: ${$padding};
      `}
    `,
  ),
  Tab: styled.button<{ active?: boolean }>(
    ({ theme, active }) => css`
      padding: 16px 32px;
      border: none;
      border-bottom: 4px solid ${active ? theme.colors.orbit : 'transparent'};
      background: transparent;
      transition: 0.12s all ease-in-out;
      font-family: ${theme.fonts.inter};
      font-size: 14px;
      font-weight: bold;
      color: ${theme.colors.nero};
      cursor: pointer;
      white-space: nowrap;
    `,
  ),
};

export default Tabs;
