import { RootState } from './RootState';

export type { RootState };

export enum localStorageEnum {
  token = 'prophet.app.auth.token',
  tableViewTypePrefix = 'prophet.app.table.view_mode_for_',
  avatarCacheKey = 'prophet.app.common.avatar-image-cache',
  clientViewHistoryPrefix = 'prophet.app.clients.user_view_history:user',
  lastPitchAnimation = 'prophet.app.pitch.last_animation_index',
  digDeeperSimilarArticlesScore = 'prophet.app.digdeeper.show_similar_articles_score',
  __devTools__savedAdminToken = 'prophet.app.devtools.adminToken',
}

export enum sessionStorageEnum {
  tempMfaEmail = 'prophet.app.login.temp_email',
}
