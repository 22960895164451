import { FC } from 'react';
import { TooltipProps } from 'react-tooltip';
import * as S from './Gauge.styles';
import { getGaugeColor } from './Gauge.helpers';
import FlexBlock from '../UI/FlexBlock';
import InfoIcon from 'app/assets/icons/InfoIcon';

interface IProps {
  value: number;
  size?: number;
  fontSize?: number;
  strokeWidth?: number;
  animate?: boolean;
  withTooltip?: TooltipProps;
  measurementSuffix?: string;
}

const Gauge: FC<IProps> = ({
  value,
  size = 96,
  fontSize = 24,
  strokeWidth = 8,
  withTooltip = undefined,
  measurementSuffix = '',
}) => {
  return (
    <FlexBlock flexDirection="row" {...withTooltip}>
      <S.Wrapper>
        <S.Gradient value={value} size={size} color={getGaugeColor(value)} />
        <S.Score
          borderWidth={strokeWidth}
          size={size - strokeWidth * 2}
          fontSize={fontSize}
        >
          {`${value}${measurementSuffix}`}
        </S.Score>
      </S.Wrapper>
      <InfoIcon offset={0} />
    </FlexBlock>
  );
};

export default Gauge;
