import FlexBlock from 'app/components/UI/FlexBlock';
import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from 'react';
import TaylorSection from './parts/TaylorSection';
import Controls from './parts/Controls';
import { Actions } from './parts/Actions';

import { ContentTabs, PredictedContentTab } from './parts/Tabs';
import { Version } from './parts/Version';

import { useParams } from 'react-router-dom';
import VersionDrawer from 'app/containers/Pitch/pages/PitchPage/parts/VersionDrawer/VersionDrawer';
import Gap from 'app/components/UI/Gap';

interface IContentTabsHeaderProps {
  activeTab: PredictedContentTab;
  setActiveTab: Dispatch<SetStateAction<PredictedContentTab>>;
  hasSocialPosts: boolean;
  contentType?: PredictedContentTab.PITCH | PredictedContentTab.PRESS_RELEASE;
}

interface IContentPageHeaderProps {
  handleGenerate: () => Promise<void>;
  headerNotice: ReactNode | undefined;
  hasContentHistory: boolean;
  contentGenerationControls?: ReactNode;
}

export const ContentTabsHeader: FC<IContentTabsHeaderProps> = ({
  activeTab,
  setActiveTab,
  hasSocialPosts,
  contentType = PredictedContentTab.PITCH,
}) => {
  const params: {
    versionId: string;
  } = useParams();

  const [versionId] = useMemo(() => [Number(params.versionId)], [params]);

  return (
    <ContentTabs
      tab={activeTab}
      setTab={setActiveTab}
      hasSocialPosts={hasSocialPosts}
      context={contentType}
    >
      {contentType === PredictedContentTab.PITCH && (
        <Version version={versionId} />
      )}
    </ContentTabs>
  );
};

const ContentPageHeader: FC<IContentPageHeaderProps> = ({
  handleGenerate,
  headerNotice,
  hasContentHistory,
  contentGenerationControls,
  children,
}) => {
  return (
    <FlexBlock justifyContent="space-between" minWidth="100%">
      <FlexBlock alignItems="center">
        <TaylorSection />
        {children}
        <Gap size={12} />
        {contentGenerationControls ? (
          contentGenerationControls
        ) : (
          <Controls
            handleRegenerate={handleGenerate}
            hasContentHistory={hasContentHistory}
          />
        )}
      </FlexBlock>
      <Actions headerNotice={headerNotice} />
      <VersionDrawer />
    </FlexBlock>
  );
};

export default ContentPageHeader;
