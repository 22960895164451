import styled, { css } from 'styled-components';
import DropDownCarrotDown from '../../../assets/icons/DropDownCarrotDownIcon';

export const StyledSelectContainer = styled.div<{ disabled?: boolean }>(
  ({ disabled }) => css`
    position: relative;
    //width: 100%;
    opacity: ${disabled ? '0.7' : '1'};
    pointer-events: ${disabled ? 'none' : 'auto'};
  `,
);

export const StyledDropdownArrow = styled(DropDownCarrotDown)<{
  $isFocused?: boolean;
}>`
  padding: 12px;
  box-sizing: content-box;
  transition: transform 0.3s;
  transform: ${({ $isFocused }) => ($isFocused ? 'rotate(180deg)' : 'unset')};
`;
