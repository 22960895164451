import styled, { css } from 'styled-components/macro';

interface IGaugeProps {
  value: number;
  size?: number;
  color?: string;
}

const calculateClipPath = (percentageValue: number) => {
  const value = (percentageValue as number) / 100;

  const startPoint = '50% 0%';
  const centerPoint = '50% 50%';

  const corner45 = '100% 0%';
  const corner135 = '100% 100%';
  const corner225 = '0% 100%';
  const corner315 = '0% 0%';

  const points = [startPoint];

  if (value >= 7 / 8) {
    points.push(corner45, corner135, corner225, corner315);
    const residualValue = value - 7 / 8;
    points.push(`${(residualValue * 100) / 0.25}% 0%`);
  }

  if (value >= 5 / 8 && value < 7 / 8) {
    points.push(corner45, corner135, corner225);
    const residualValue = value - 5 / 8;
    points.push(`0% ${100 - (residualValue * 100) / 0.25}%`);
  }

  if (value >= 3 / 8 && value < 5 / 8) {
    points.push(corner45, corner135);
    const residualValue = value - 3 / 8;
    points.push(`${100 - (residualValue * 100) / 0.25}% 100%`);
  }

  if (value >= 1 / 8 && value < 3 / 8) {
    points.push(corner45);
    const residualValue = value - 1 / 8;
    points.push(`100% ${(residualValue * 100) / 0.25}%`);
  }

  if (value < 1 / 8) {
    const residualValue = value;
    points.push(`${50 + (residualValue * 100) / 0.25}% 0%`);
  }

  return [...points, centerPoint].join(', ');
};

export const Gradient = styled.div<IGaugeProps>(
  ({ theme, value, size = 96, color }) => css`
    width: ${size}px;
    height: ${size}px;
    background: ${color};
    border-radius: 50%;
    transform: rotate(0);
    clip-path: polygon(${calculateClipPath(value)});
  `,
);

export const Wrapper = styled.div`
  position: relative;
  background-color: #f5f5f9;
  border-radius: 50%;
`;

export const Score = styled.div<{
  borderWidth: number;
  size: number;
  fontSize: number;
}>(
  ({ borderWidth, size, fontSize, theme }) => css`
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    height: ${size}px;
    width: ${size}px;
    border: ${borderWidth}px solid #fff;
    background-color: #f5f5f9;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${theme.fonts.dmSans};
    color: ${theme.colors.onyx};
    font-weight: bold;
    font-size: ${fontSize}px;
  `,
);
