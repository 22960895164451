import { Anchor } from 'app/components/UI/Anchor';
import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import { RouteConstants } from 'app/routes';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export const MonitorNavigationLink: FC = ({ children }) => {
  const hasMonitorAccess = useHasPermission(
    userPermissionEnum.SMC_MONITOR_ACCESS,
  );

  if (hasMonitorAccess)
    return <NavLink to={RouteConstants.misc.monitor}>{children}</NavLink>;

  return (
    <Anchor
      href="https://www.peakmetrics.com/prophet"
      target="_blank"
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Anchor>
  );
};
