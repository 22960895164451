import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import * as S from './SocialPostsInput.styles';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';

import { Anchor } from 'app/components/UI/Anchor';
import Button from 'app/components/UI/Button/Button';

import CheckBox from 'app/components/UI/CheckBox/CheckBox';

import { useClickOutsideListener } from 'app/containers/Projects/pages/ContentList/parts/ContentTypeSelector/hooks/useClickOutsideListener.hook';
import { useSocialProviderSelector } from './hooks/useSocialProviderSelector.hook';
interface IProps {
  menuTitle: string;
  options: ISocialProviderSelectorOption[];
  setSelectedOptions: Dispatch<SetStateAction<string[] | undefined>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  compactView?: boolean;
}

interface ISocialProviderSelectorOption {
  key: string;
  displayName: string;
  avatar?: ReactNode;
  checked?: boolean;
}

const SocialProviderSelectorMenu: FC<IProps> = ({
  menuTitle,
  options,
  setSelectedOptions,
  open,
  setOpen,
  compactView = false,
}) => {
  const [menuOptions, setMenuOptions] = useState(options);

  const { actions, conditions } = useSocialProviderSelector(
    menuOptions,
    setMenuOptions,
  );

  const ref = useClickOutsideListener(open, setOpen);

  const handleOptionSelection = () => {
    const updatedContentTypeSelection = menuOptions
      .filter(option => option.checked)
      .map(option => option.key);

    setSelectedOptions(updatedContentTypeSelection);
    setOpen(false);
  };

  return (
    <S.Menu
      onClick={e => e.stopPropagation()}
      ref={ref}
      $height={compactView ? 450 : undefined}
    >
      <FlexBlock flexDirection="column" padding="15.5px 17px">
        {/* <Typography.Text
          $colorName="onyx"
          $size={16}
          $lineHeight={24}
          $bold
          $dmSans
          $margin="0 0 8px 0"
        >
          {menuTitle}
        </Typography.Text> */}
        {menuOptions.map(option => (
          <FlexBlock
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            minWidth="100%"
            padding="8px 0"
            maxHeight="56px"
            key={option.displayName}
          >
            <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
              <CheckBox
                checked={Boolean(option.checked)}
                onChange={(e: boolean) => {
                  actions.handleOptionCheck(option.key, e);
                }}
              />
              {option.avatar && option.avatar}
              <Typography.Text
                $size={16}
                $lineHeight={24}
                $colorName="onyx"
                $dmSans
                $bold
              >
                {option.displayName}
              </Typography.Text>
            </FlexBlock>
          </FlexBlock>
        ))}
      </FlexBlock>
      <S.MenuFooter>
        <Typography.Text $size={16} $lineHeight={24}>
          <Anchor
            style={{ fontSize: '16px' }}
            onClick={() =>
              actions.handleAllOptionsCheck(conditions.isNotAllSelected)
            }
          >
            {conditions.isNotAllSelected ? 'Select All' : 'Remove All'}
          </Anchor>
        </Typography.Text>
        <S.MenuActionsWrapper>
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleOptionSelection()}
            disabled={!conditions.isOneOrMoreSelected}
          >
            Apply
          </Button>
        </S.MenuActionsWrapper>
      </S.MenuFooter>
    </S.Menu>
  );
};

export default SocialProviderSelectorMenu;
