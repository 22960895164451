import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import { CloseIcon } from 'app/components/UI/CloseIcon';
import FlexBlock from 'app/components/UI/FlexBlock';
import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';
import { Dispatch, SetStateAction, FC, useState, useMemo } from 'react';
import FiltersPanel, { IFilterOptions } from './FiltersPanel';
import FilterIcon from 'app/assets/icons/FilterIcon';
import styled, { css } from 'styled-components';
import { defaultJournalistsFilters } from '../../JournalistsListPage.constants';
import { filtersEqual } from './FiltersPanel.helpers';
import { FilterActionsWrapper } from '../../JournalistsList.styles';

interface IFilterPanelProps {
  setFilters: Dispatch<SetStateAction<IFilterOptions>>;
  activeFilters: IFilterOptions;
}

const FilterButton = ({ isCountryFilterChanged, ...props }: any) => {
  return (
    <StyledButton isCountryFilterChanged={isCountryFilterChanged} {...props}>
      <FilterIcon />
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  background-color: white !important;
  transition: box-shadow 0.3s ease; /* Add a transition for a smooth effect */

  &:hover {
    box-shadow: 0px 4px 16px 0px #1d76de66;
  }

  /* Conditional styles based on isCountryFilterChanged prop */
  ${(props: any) =>
    props.isCountryFilterChanged &&
    css`
      box-shadow: 0px 4px 16px 0px #1d76de66 !important;
    `}
`;

const FiltersSideDrawer: FC<IFilterPanelProps> = ({
  setFilters,
  activeFilters,
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const filtersEqualDefault = useMemo(() => {
    return filtersEqual(activeFilters, defaultJournalistsFilters);
  }, [activeFilters]);

  return (
    <>
      <FilterButton
        hasNotifications={!filtersEqualDefault}
        variant="gray-smoke"
        boxShadow="0px 4px 16px 0px #1d76de66"
        onClick={() => setIsPanelOpen(panelState => !panelState)}
      />
      <SideDrawer open={isPanelOpen} compactMode>
        <FlexBlock
          flexDirection="column"
          style={{ overflowY: 'hidden', height: '100%' }}
        >
          <FlexBlock
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            minWidth="100%"
            padding="20px"
          >
            <Typography.Text $dmSans $bold $size={18}>
              Journalists Filter
            </Typography.Text>
            <Button
              variant="icon-button-border"
              onClick={() => setIsPanelOpen(false)}
            >
              <CloseIcon />
            </Button>
          </FlexBlock>

          <FiltersPanel
            activeFilters={activeFilters}
            setFilters={setFilters}
            handleClose={() => setIsPanelOpen(false)}
            open={isPanelOpen}
          />
        </FlexBlock>
      </SideDrawer>
    </>
  );
};

export default FiltersSideDrawer;
