import * as React from 'react';

const GenerateIconBlue = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        x="-1.0%"
        y="-26.2%"
        width="102.0%"
        height="147.5%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset
          dx="0"
          dy="-2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="6"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0.0480762252   0 0 0 0 0.0480491123   0 0 0 0 0.0500554624  0 0 0 0.0472130409 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g id="Pitch" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Results-1.0.0" transform="translate(-1616, -1032)" fill="#1D76DE">
        <g id="Bottom" filter="url(#filter-1)" transform="translate(0, 1000)">
          <g id="right" transform="translate(1600, 12)">
            <g id="content" transform="translate(16, 16)">
              <g id="Atom/Icon/Filter" transform="translate(0, 4)">
                <g id="asset" transform="translate(0, 0)">
                  <path
                    d="M0.4,10.7652317 C0.4,10.7652317 0.4,10.7652317 0.4,10.7652317 L0.4,14.2761569 C0.4,15.2282627 1.27039716,16 2.34410008,16 L13.6558999,16 C14.7296331,16 15.6,15.2282627 15.6,14.2761569 L15.6,10.7652317 C15.6,10.7652317 15.6,10.7652317 15.6,10.7652317 C15.6,10.2629305 15.1953869,9.85437622 14.693109,9.84953356 L14.3446124,9.84615539 L14.3446124,9.84615539 L10.8278164,9.84615539 C10.1190943,9.84615539 9.67185193,10.3534383 9.50081451,10.769731 C9.28493469,11.2954698 8.69895352,11.6808146 7.99995737,11.6808146 C7.30096122,11.6808146 6.71498005,11.2954698 6.49910023,10.769731 C6.32807796,10.3534383 5.88082041,9.84615539 5.17209831,9.84615539 L1.65530234,9.84615539 L1.30676634,9.84956125 C0.80454622,9.8544689 0.4,10.2629876 0.4,10.7652317 Z M8.31791392,4.01249054e-06 C8.20681838,-0.000269676834 8.10388116,0.0541937569 8.04762665,0.143067109 L4.87878078,5.10585273 C4.84142702,5.1648502 4.82786523,5.23414916 4.84039111,5.30152636 C4.85299022,5.36890181 4.89086269,5.42995994 4.94733538,5.47398431 C5.00380998,5.51794011 5.07532815,5.54197865 5.14907373,5.54184151 L7.51726522,5.54184151 L6.4286219,9.48344599 L6.4286219,9.48337731 C6.40534979,9.56606872 6.4226187,9.65404889 6.47583205,9.72376279 C6.52904521,9.7935435 6.61279328,9.83791073 6.70462568,9.84512124 C6.79652638,9.8523328 6.8870914,9.82156444 6.95224038,9.76105665 L13.6175933,3.59722276 C13.6759196,3.54282658 13.7085302,3.46927094 13.7083827,3.39254697 C13.708234,3.31589861 13.6752537,3.24240979 13.6167054,3.18822283 C13.558157,3.13403412 13.4787822,3.10360792 13.3960701,3.10353935 L10.3681925,3.10353935 L12.1481496,0.442279407 C12.1875771,0.383350504 12.2029186,0.313297267 12.1913565,0.244829955 C12.1797948,0.17635561 12.1420707,0.11399288 12.0850782,0.0690753269 C12.0281596,0.0241577743 11.9556776,-0.000360770834 11.8808924,4.01249054e-06 L8.31791392,4.01249054e-06 Z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GenerateIconBlue;
