import DotsHorizontalIcon from 'app/assets/icons/DotsHorizontal';
import Typography from 'app/components/Typography';
import ContextMenu, {
  ContextMenuOption,
} from 'app/components/UI/ContextMenu/ContextMenu';
import DoublePill from 'app/components/UI/DoublePill';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import Tabs from 'app/components/UI/Tabs';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { formatDate } from 'utils/helpers';
import {
  ButtonWrapper,
  CenteredContentFlex,
  IconButton,
  WandAvatar,
} from '../BylinePage.styles';
import { useDeleteBylineMutation } from 'app/api';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import WandIcon from 'app/assets/icons/WandIcon';
import Button from 'app/components/UI/Button/Button';
import { theme } from 'styles/theme';
import GearIcon from 'app/assets/icons/GearIcon';
import RegenerateIcon from 'app/assets/icons/RegenerateIcon';
import { useHistory, useParams } from 'react-router-dom';
import { RouteConstants } from 'app/routes';
import InfoTip from 'app/components/UI/InfoTip/InfoTip';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import { TAYLOR_TOOLTIP } from '../BylinePage.constants';
import { ToneSelectDropDown } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import { IToneSelectOption } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';
import TimerIcon from 'app/assets/icons/TimerIcon';

interface ITaylorHeader {
  regenerateContent: (tone: string) => Promise<void>;
  toggleSettings: Dispatch<SetStateAction<boolean>>;
  toggleVersionsDrawer: Dispatch<SetStateAction<boolean>>;
  isSaveLoading: boolean;
  tone: IToneSelectOption;
  setTone?: Dispatch<SetStateAction<IToneSelectOption>>;
}

interface IHeader {
  saveByline: () => Promise<void>;
  isContentModified: boolean;
  isSaveLoading: boolean;
  isDraft: boolean;
}

interface IContentControls {
  toggleVersionsDrawer: Dispatch<SetStateAction<boolean>>;
  version: number;
  bylineId: number;
}

export const BylinePageTabs: FC = ({ children }) => {
  return (
    <Tabs.Bar>
      <CenteredContentFlex>
        <Tabs.Tab active={true} onClick={() => {}}>
          <Typography.Text
            $padding="5px 0"
            $bold
            $size={14}
            $lineHeight={24}
            $colorName="onyx"
          >
            Byline
          </Typography.Text>
        </Tabs.Tab>
        {children}
      </CenteredContentFlex>
    </Tabs.Bar>
  );
};

const TaylorHeaderSection: FC<ITaylorHeader> = ({
  toggleSettings,
  toggleVersionsDrawer,
  regenerateContent,
  tone,
  setTone,
}) => {
  return (
    <FlexBlock flexDirection="row" justifyContent="center" alignItems="center">
      <WandAvatar>
        <WandIcon />
      </WandAvatar>
      <Gap size={16} />
      <FlexBlock flexDirection="column">
        <FlexBlock flexDirection="row" alignItems="center">
          <Typography.Text
            $colorName="orbit"
            $size={18}
            $lineHeight={24}
            $dmSans
            $bold
            $padding="2px 0"
          >
            Taylor
          </Typography.Text>
          <InfoTip
            tooltipPosition="right"
            tooltipText={TAYLOR_TOOLTIP}
            padding="0 0 0 4px"
          />
        </FlexBlock>

        <Typography.Text $colorName="steel" $size={14} $lineHeight={16}>
          Generate Content
        </Typography.Text>
      </FlexBlock>
      <Gap size={16} />
      <ToneSelectDropDown
        value={tone}
        onChange={setTone!}
        selectComponentWidth="243px"
        height="56px"
      />
      <Gap size={8} />
      <ButtonWrapper>
        <Button
          variant="icon-button-border"
          onClick={() => toggleSettings(prev => !prev)}
        >
          <GearIcon fill={theme.colors.haze} />
        </Button>
      </ButtonWrapper>
      <Gap size={8} />
      <ButtonWrapper>
        <Button
          variant="icon-button-border"
          onClick={() => toggleVersionsDrawer(prev => !prev)}
          tooltipText="View previous content generations"
        >
          <TimerIcon />
        </Button>
      </ButtonWrapper>
      <Gap size={8} />
      <ButtonWrapper $borderColor={theme.colors.orbit}>
        <Button
          variant="icon-button-border"
          onClick={() => regenerateContent(tone.value)}
          tooltipText="Regenerate content"
        >
          <RegenerateIcon fill={theme.colors.orbit} />
        </Button>
      </ButtonWrapper>
    </FlexBlock>
  );
};

const HeaderActions: FC<IHeader> = ({
  isContentModified,
  isSaveLoading,
  saveByline,
  isDraft,
}) => {
  const history = useHistory();

  const params: {
    tenantId: string;
    projectId: string;
  } = useParams();

  const [tenantId, projectId] = useMemo(
    () => [Number(params.tenantId), Number(params.projectId)],
    [params],
  );

  return (
    <FlexBlock flexDirection="row" alignItems="center">
      <Button
        variant="secondary"
        onClick={() =>
          history.push(
            RouteConstants.projects.makeContentUrl(tenantId, projectId),
          )
        }
      >
        Cancel
      </Button>
      <Gap size={12} />
      <Button
        disabled={(!isContentModified && !isDraft) || isSaveLoading}
        onClick={saveByline}
        isLoading={isSaveLoading}
        variant="secondary-blue"
      >
        Save
      </Button>
    </FlexBlock>
  );
};

export const TopHeader: FC<ITaylorHeader & IHeader> = ({
  saveByline,
  isSaveLoading,
  isContentModified,
  regenerateContent,
  toggleSettings,
  toggleVersionsDrawer,
  isDraft,
  tone,
}) => {
  const [headerTone, setHeaderTone] = useState(tone);

  useEffect(() => {
    setHeaderTone(tone);
  }, [tone]);

  return (
    <FlexBlock
      justifyContent="space-between"
      alignItems="center"
      minWidth="100%"
    >
      <TaylorHeaderSection
        toggleVersionsDrawer={toggleVersionsDrawer}
        isSaveLoading={isSaveLoading}
        regenerateContent={regenerateContent}
        toggleSettings={toggleSettings}
        tone={headerTone}
        setTone={setHeaderTone}
      />
      <HeaderActions
        saveByline={saveByline}
        isContentModified={isContentModified}
        isSaveLoading={isSaveLoading}
        isDraft={isDraft}
      />
    </FlexBlock>
  );
};

export const ContentControls: FC<IContentControls> = ({
  toggleVersionsDrawer,
  version,
  bylineId,
}) => {
  const { createModal } = usePromptModalContext();

  const history = useHistory();

  const params: {
    tenantId: string;
    projectId: string;
  } = useParams();

  const [tenantId, projectId] = useMemo(
    () => [Number(params.tenantId), Number(params.projectId)],
    [params],
  );

  const [deleteByline] = useDeleteBylineMutation();

  const handleBylineDelete = async (bylineId: number) => {
    if (bylineId) {
      try {
        await deleteByline({ bylineId });
        showSuccessToast('Byline Deleted.');

        history.push(
          RouteConstants.projects.makeContentUrl(tenantId, projectId),
        );
      } catch (error) {
        showErrorToast(
          getErrorText(error as FetchBaseQueryError),
          'Could not delete Byline',
        );
      }
    }
  };

  const deleteBylineClick = async () => {
    createModal({
      title: `Delete Byline`,
      description: `Are you sure you want to delete this byline?`,
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      okButtonDanger: true,
      okButtonCallback: async () => {
        await handleBylineDelete(bylineId);
      },
    });
  };

  return (
    <FlexBlock flexDirection="row" alignItems="center">
      <FlexBlock flexDirection="column" alignItems="flex-end">
        <Typography.Text $lineHeight={16} $colorName="steel">
          Last Edited
        </Typography.Text>
        <Typography.Text $lineHeight={16}>
          {formatDate(new Date().toISOString())}
        </Typography.Text>
      </FlexBlock>
      <Gap size={16} />
      <DoublePill
        leftContent="Byline"
        rightContent={`V${version}`}
        onClick={() => toggleVersionsDrawer(prev => !prev)}
        compact
      />

      <ContextMenu
        renderButtonTrigger={args => (
          <IconButton onClick={args.onClick}>
            <DotsHorizontalIcon />
          </IconButton>
        )}
        buttonText={''}
        menuOptions={{ align: 'right' }}
      >
        <ContextMenuOption onClick={deleteBylineClick}>
          <Typography.Text $colorName="redOrange">
            Delete Byline
          </Typography.Text>
        </ContextMenuOption>
      </ContextMenu>
    </FlexBlock>
  );
};
