import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.button<{ checked?: boolean }>(
  ({ theme, checked }) => css`
    height: 20px;
    width: 20px;
    border: 1px solid ${theme.colors.nero};
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    padding: 0;
    svg {
      opacity: ${checked ? 1 : 0};
      transition: 0.12s all ease-in-out;
      width: 12px;
      height: 12px;
    }
  `,
);
