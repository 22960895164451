import Typography from 'app/components/Typography';
import { FC } from 'react';
import { InputErrorType } from 'types/commonTypes';

const LoginErrorComponent: FC<{ errors?: InputErrorType[] }> = ({ errors }) => (
  <div style={{ margin: '4px 0 0 0', minHeight: '24px' }}>
    {errors &&
      errors.length > 0 &&
      errors.map(error => {
        return (
          <Typography.Text $size={14} $colorName="volatileRed">
            {error}
          </Typography.Text>
        );
      })}
  </div>
);

export default LoginErrorComponent;
