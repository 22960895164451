import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import { getToneObjectByValue } from 'app/containers/Blog/pages/BlogPage/BlogPage.helpers';
import { FC } from 'react';

const SocialPostTonePill: FC<{ tone: string }> = ({ tone }) => {
  const toneObject = getToneObjectByValue(tone);

  if (!toneObject) return <></>;

  return (
    <FlexBlock
      flexDirection="row"
      alignItems="center"
      style={{ background: '#F5F5F9', borderRadius: '4px' }}
      columnGap="8px"
      padding="8px"
    >
      <img src={toneObject.icon} alt={toneObject.name} width={16} height={16} />
      <Typography.Text
        style={{ color: '#3F3F42' }}
        $size={14}
        $lineHeight={20}
        $dmSans
        $bold
      >
        {toneObject.name}
      </Typography.Text>
    </FlexBlock>
  );
};

export default SocialPostTonePill;
