import Axios, { AxiosRequestConfig } from 'axios';
import { BASE_API_URL } from './constants';
import { localStorageEnum } from '../types';

// API url can be imported from cnonstants file or env config
const baseURL = BASE_API_URL;

const client = Axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
});

client.interceptors.request.use(
  config => {
    const token = localStorage.getItem(localStorageEnum.token) || '';
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return config;
  },
  e => Promise.reject(e),
);

export const request = function (options: AxiosRequestConfig) {
  const onSuccess = function (response: any) {
    return response.data;
  };

  const onError = function (error: any) {
    if (error?.response?.data.status_code !== 404) {
      // Request was made but server responded with something
      // other than 2xx OR 404
    } else {
      // Something else happened while setting up the request
      // triggered the error
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};
