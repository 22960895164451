import React from 'react';

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#E1306C" fillRule="nonzero" transform="translate(-930 -800)">
          <g transform="translate(260 72)">
            <g transform="translate(646 224)">
              <g transform="translate(0 480)">
                <g transform="translate(16 16)">
                  <g transform="translate(8 8)">
                    <path d="M13.16 0c1.454.003 2.152.011 2.77.03l.324.012.694.03c1.277.058 2.15.261 2.912.558.79.307 1.459.717 2.126 1.384A5.882 5.882 0 0123.37 4.14c.297.763.5 1.635.558 2.912l.03.694.011.324c.02.618.028 1.316.03 2.77l.001.843V13.16a92.814 92.814 0 01-.03 2.77l-.012.324-.03.694c-.058 1.277-.261 2.15-.558 2.912a5.883 5.883 0 01-1.384 2.126 5.883 5.883 0 01-2.126 1.384c-.763.297-1.635.5-2.912.558l-.694.03-.324.011c-.618.02-1.316.028-2.77.03l-.843.001H10.84a92.817 92.817 0 01-2.77-.03l-.324-.012-.694-.03c-1.277-.059-2.15-.261-2.912-.558a5.882 5.882 0 01-2.126-1.384A5.883 5.883 0 01.63 19.86c-.297-.763-.5-1.635-.558-2.912a100.39 100.39 0 01-.03-.694l-.011-.324A92.856 92.856 0 010 13.16v-2.32c.002-1.454.01-2.152.03-2.77l.011-.324.03-.694C.131 5.775.333 4.902.63 4.14a5.881 5.881 0 011.384-2.126A5.882 5.882 0 014.14.63C4.903.333 5.775.13 7.052.072l.694-.03.324-.011C8.688.01 9.386.003 10.84 0zm-.02 2.163h-2.28c-1.594.002-2.253.011-2.92.036l-.213.008-.576.025c-1.17.053-1.805.249-2.228.413-.56.218-.96.478-1.38.898-.42.42-.68.82-.898 1.38-.164.423-.36 1.058-.413 2.228l-.025.576-.008.212c-.025.668-.034 1.327-.036 2.921v2.28c.002 1.594.011 2.253.036 2.92l.008.213.025.576c.054 1.17.249 1.805.413 2.228.218.56.478.96.898 1.38.42.42.82.68 1.38.898.423.164 1.058.36 2.228.413 1.096.05 1.528.066 3.71.07h2.279c2.181-.004 2.613-.02 3.709-.07 1.17-.053 1.805-.249 2.228-.413.56-.218.96-.478 1.38-.898.42-.42.68-.82.898-1.38.164-.423.36-1.058.413-2.228l.025-.576.008-.212c.025-.668.034-1.327.036-2.921v-2.28c-.002-1.594-.011-2.253-.036-2.92l-.008-.213a114.19 114.19 0 00-.025-.576c-.053-1.17-.249-1.805-.413-2.228a3.718 3.718 0 00-.898-1.38c-.42-.42-.82-.68-1.38-.898-.423-.164-1.058-.36-2.228-.413l-.576-.025-.212-.008c-.668-.025-1.327-.034-2.921-.036zM12 5.838a6.162 6.162 0 110 12.324 6.162 6.162 0 010-12.324zM12 8a4 4 0 100 8 4 4 0 000-8zm6.406-3.846a1.44 1.44 0 110 2.88 1.44 1.44 0 010-2.88z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InstagramIcon;
