interface IProps {
  size?: number;
}

const HelpIcon = ({ size = 24 }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#0C0C0D" transform="translate(-1069 -161)">
        <g transform="translate(837 117)">
          <g transform="translate(212 24)">
            <g transform="translate(20 20)">
              <path d="M40.258 39.967c8.723-8.858 8.723-23.076 0-31.934a1.622 1.622 0 00-.29-.291c-8.859-8.723-23.077-8.723-31.935 0a1.622 1.622 0 00-.291.29c-8.723 8.859-8.723 23.077 0 31.935a1.776 1.776 0 00.29.291c8.859 8.723 23.077 8.723 31.935 0a1.758 1.758 0 00.291-.29zM16.985 24A7.015 7.015 0 1124 31.015 7.023 7.023 0 0116.985 24zM36.338 9.181l-6.246 6.247a10.488 10.488 0 00-12.184 0L11.662 9.18a19.253 19.253 0 0124.676 0zM11.662 38.82l6.246-6.247c3.645 2.601 8.54 2.601 12.184 0l6.246 6.247a19.253 19.253 0 01-24.676 0z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HelpIcon;
