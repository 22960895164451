import WandIcon from 'app/assets/icons/WandIcon';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import InfoTip from 'app/components/UI/InfoTip/InfoTip';
import { FC } from 'react';
import { WandAvatar } from '../ContentHeader.styles';
import { PROPHET_ORACLE_TOOLTIP } from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitch.constants';

const TaylorSection: FC<{ taylorTooltip?: string }> = ({
  children,
  taylorTooltip,
}) => {
  return (
    <FlexBlock flexDirection="row" justifyContent="center" alignItems="center">
      <WandAvatar>
        <WandIcon />
      </WandAvatar>
      <Gap size={16} />
      <FlexBlock flexDirection="column">
        <FlexBlock flexDirection="row" alignItems="center">
          <Typography.Text
            $colorName="orbit"
            $size={18}
            $lineHeight={24}
            $dmSans
            $bold
            $padding="2px 0"
          >
            Taylor
          </Typography.Text>
          <InfoTip
            tooltipPosition="right"
            tooltipText={taylorTooltip || PROPHET_ORACLE_TOOLTIP}
            padding="0 0 0 4px"
          />
        </FlexBlock>
        <Typography.Text $colorName="steel" $size={14} $lineHeight={16}>
          Generate Content
        </Typography.Text>
      </FlexBlock>
      <Gap size={16} />
      {children}
    </FlexBlock>
  );
};

export default TaylorSection;
