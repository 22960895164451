import EditPitchButton from './EditPitchButton';
import ExportReportButton from './ExportReportButton';
import MultiPitchGenerationButton from './MultiPitchGenerationButton';
import CancelButton from './CancelButton';
import RegenerateButton from './RegenerateButton';
import GenerateButton from './GenerateButton';

const FooterButtons = {
  EditPitchButton,
  ExportReportButton,
  MultiPitchGenerationButton,
  CancelButton,
  RegenerateButton,
  GenerateButton,
};

export default FooterButtons;
