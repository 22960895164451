import { IPreparedReportItem } from '../MediaReportCard/MediaReportCard.helpers';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import * as S from './MediaReportTable.styles';
import { useTable, useRowSelect, useMountedLayoutEffect } from 'react-table';
import { getMediaTableColumnConfig } from './MediaReportTable.config';
import CarrotDownIcon from '../../../../../../assets/icons/CarrotDownIcon';
import { ISort } from '../../../../../../../types/commonTypes';
import IfHasPermission from '../../../../../Global/permissions/IfHasPermission';
import { userPermissionEnum } from '../../../../../Global/permissions/userPermissions.enum';
import SortArrow from './parts/SortArrow';
import { useHasPermissionResolver } from '../../../../../Global/permissions/useHasPermission.hook';
import { PitchPageContext } from '../../PitchPage.context';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
} from '../../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';
import ReactTooltip from 'react-tooltip';
import { SkeletonDiv } from 'app/components/UI/LoadingSkeletons/Skeletons.styles';

export interface IMediaReportTableProps {
  data: IPreparedReportItem[];
  onRowClick: (item: IPreparedReportItem) => void;
  groupBy: 'score' | 'outlet';
  onSortColumnClick: (col: string) => void;
  sort: ISort;
  interestKeys: string[];
  invisibleHeader?: boolean;
  reportId?: number;
  isFetchingReport?: boolean;
  isFetchingMoreItems?: boolean;
  isFetchingAllItems?: boolean;
}

const MediaReportTable: FC<IMediaReportTableProps> = props => {
  const {
    isRapidGenerationView,
    isRapidGenerationForAll,
    setAllSelectedItems,
    updateContentLengthOptionByID,
    updateContentToneOptionByID,
    setCurrentItemsCount,
    currentItemsCount,
  } = useContext(PitchPageContext);

  const [hideInterestSubColumns, setHideInterestSubColumns] = useState(true);

  const isPodcast = useMemo(() => {
    return props.data.some(i => Boolean(i.podcastId));
  }, [props.data]);

  const hasPermission = useHasPermissionResolver();

  const [selectedIndividualTones, setSelectedIndividualTones] = useState<
    Record<number, IToneSelectOption>
  >([]);

  const [
    selectedIndividualContentLength,
    setSelectedIndividualTonesContentLength,
  ] = useState<Record<number, IContentLengthSelectOption>>({});

  useEffect(() => {
    if (
      props.reportId &&
      updateContentLengthOptionByID &&
      Object.keys(selectedIndividualContentLength).length > 0
    ) {
      updateContentLengthOptionByID(
        props.reportId,
        selectedIndividualContentLength,
      );
    }
  }, [selectedIndividualContentLength]);

  useEffect(() => {
    if (
      props.reportId &&
      updateContentToneOptionByID &&
      Object.keys(selectedIndividualTones).length > 0
    ) {
      updateContentToneOptionByID(props.reportId, selectedIndividualTones);
    }
  }, [selectedIndividualTones]);

  const columns = useMemo(() => {
    return getMediaTableColumnConfig({
      hideInterestSubColumns,
      groupBy: props.groupBy,
      interestKeys: props.interestKeys,
      isPodcast: isPodcast,
      isRapidGenerationActive: isRapidGenerationView,
      isRapidGenerationForAll,
      individualContentLengthSelector: setSelectedIndividualTonesContentLength,
      individualToneSelector: setSelectedIndividualTones,
      individualTones: selectedIndividualTones,
      individualLengths: selectedIndividualContentLength,
      selectedItemsCount: currentItemsCount,
    }).map(column => {
      return {
        ...column,
        columns: column.columns.filter(c => {
          // @ts-ignore
          if (c.requirePermission) return hasPermission(c.requirePermission);
          return true;
        }),
      };
    });
  }, [
    hideInterestSubColumns,
    props.groupBy,
    isPodcast,
    isRapidGenerationView,
    isRapidGenerationForAll,
    currentItemsCount,
  ]);

  useEffect(() => {
    if (props.data.length > 25) {
      ReactTooltip.rebuild();
    }
  }, [props.data]);

  const tableInstance = useTable(
    {
      columns,
      data: props.data,
      autoResetSelectedRows: false,
    },
    useRowSelect,
  );

  useMountedLayoutEffect(() => {
    if (
      isRapidGenerationView &&
      tableInstance.selectedFlatRows &&
      props.reportId
    ) {
      setAllSelectedItems(state => {
        const newState = {
          ...state,
          [Number(props.reportId)]: tableInstance.selectedFlatRows.map(row =>
            Number(row.original.id),
          ),
        };

        const currentCount = Object.values(newState)
          .map(selection => selection.length)
          .reduce((acc, currentValue) => acc + currentValue, 0);

        setCurrentItemsCount(currentCount);

        return newState;
      });
    }
  }, [setAllSelectedItems, tableInstance.selectedFlatRows]);

  return (
    <S.Table
      {...tableInstance.getTableProps()}
      hideHeader={props.invisibleHeader}
    >
      <S.THead>
        {tableInstance.headerGroups.map((headerGroup, hgIndex) => (
          <S.Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <S.Th
                {...header.getHeaderProps()}
                even={Boolean(hgIndex % 2)}
                // @ts-ignore
                centerContent={header.centerContent || false}
                onClick={() => {
                  // @ts-ignore
                  if (header.disableSort) return;
                  props.onSortColumnClick(header.id);
                }}
                columnWidth={header.width}
                // @ts-ignore
                padding={header.padding}
                // @ts-ignore
                showBorderLeft={header.showBorderLeft}
                // @ts-ignore
                showCursor={!header.disableSort}
                key={`${headerGroup.id}_${header.id}`}
              >
                <S.TdInner>
                  {header.render('Header')}
                  {/*@ts-ignore*/}
                  {header.collapsable && Boolean(props.interestKeys.length) && (
                    <IfHasPermission
                      require={userPermissionEnum.SYSTEM_MANAGEMENT}
                    >
                      <S.ExpandButton
                        onClick={e => {
                          e.stopPropagation();
                          setHideInterestSubColumns(!hideInterestSubColumns);
                        }}
                        collapsed={hideInterestSubColumns}
                      >
                        <CarrotDownIcon />
                      </S.ExpandButton>
                    </IfHasPermission>
                  )}
                  {/*// @ts-ignore*/}
                  {!header.disableSort && (
                    <SortArrow
                      active={header.id === props.sort.property}
                      asc={props.sort.ascending}
                    />
                  )}
                  {/*// @ts-ignore*/}
                  {header.tooltip && header.tooltip()}
                </S.TdInner>
              </S.Th>
            ))}
          </S.Tr>
        ))}
      </S.THead>
      <S.TBody>
        {tableInstance.rows.map((row, rowIndex) => {
          tableInstance.prepareRow(row);

          if (props.isFetchingReport && props.isFetchingAllItems)
            return (
              <tr key={`skeleton-replaced-row-${rowIndex}`}>
                <td style={{ padding: '10px' }} colSpan={20}>
                  <SkeletonDiv
                    height="56px"
                    width="100%"
                    borderRadius="8px"
                    shimmerAnimation
                  />
                </td>
              </tr>
            );

          return (
            <S.Tr
              onClick={() =>
                isRapidGenerationView ? {} : props.onRowClick(row.original)
              }
              titleRow={row.original.titleRow}
              key={row.original.id}
            >
              {row.cells.map((cell, cellIndex) => (
                <S.Td
                  key={`${row.id}-${cell.row.original.id}-${cell.column.id}`}
                  even={row.original.isEven}
                  // @ts-ignore
                  centerContent={cell.column.centerContent || false}
                  // @ts-ignore
                  noVerticalPadding={cell.column.noVerticalPadding || false}
                  onClick={e =>
                    isRapidGenerationView &&
                    cellIndex === 0 &&
                    e.stopPropagation()
                  }
                >
                  {cell.render('Cell')}
                </S.Td>
              ))}
            </S.Tr>
          );
        })}
      </S.TBody>
      {props.isFetchingReport && props.isFetchingMoreItems && (
        <>
          {Array.from({ length: 10 }).map((_, idx) => (
            <tr key={`skeleton-row-${idx}`}>
              <td style={{ padding: '10px' }} colSpan={20}>
                <SkeletonDiv
                  height="56px"
                  width="100%"
                  borderRadius="8px"
                  shimmerAnimation
                />
              </td>
            </tr>
          ))}
        </>
      )}
    </S.Table>
  );
};

export default MediaReportTable;
export const TableScrollWrapper = S.Wrapper;
