export const PRO_FEATURES = [
  'Journalist Contact Information',
  'Generate Content up to 12 times/month (Taylor)',
  'Up to 2 Users',
];

// Pro (Legacy) to Enterprise upgrade
export const ENTERPRISE_LEGACY_FEATURES = [
  'Unlimited Users',
  'Unlimited Content Generation',
];

// Pro (V2) to Enterprise upgrade
export const ENTERPRISE_FEATURES = [
  'Exports',
  'Global Reach',
  'Unlimited Users',
  'Unlimited Content Generation (Taylor)',
];
