import { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import ProjectsList from './pages/ProjectsList/ProjectsList';
import ContentList from './pages/ContentList/ContentList';
import ProtectedRoute from '../Global/permissions/ProtectedRoute';
import { userPermissionEnum } from '../Global/permissions/userPermissions.enum';

interface IProps {}

const Projects: FC<IProps> = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={RouteConstants.projects.listByTenant}
        component={ProjectsList}
        requirePermission={userPermissionEnum.READ_PROJECTS}
      />
      <ProtectedRoute
        path={RouteConstants.projects.list}
        component={ProjectsList}
        requirePermission={userPermissionEnum.READ_PROJECTS}
      />
      <ProtectedRoute
        path={RouteConstants.projects.contentList}
        component={ContentList}
        requirePermission={userPermissionEnum.READ_PITCH}
      />
      <Redirect
        from={RouteConstants.projects.index}
        to={RouteConstants.projects.list}
      />
    </Switch>
  );
};

export default Projects;
