import styled, { css } from 'styled-components/macro';

interface IComponentLoaderProps {
  minWidth?: string;
  minHeight?: string;
}

const ComponentLoader = styled.div<IComponentLoaderProps>(
  ({ theme, minWidth = '200px', minHeight = '30px' }) => css`
    @keyframes slide {
      0% {
        transform: translateX(-200%);
      }
      100% {
        transform: translateX(calc(${minWidth} + 200%));
      }
    }

    background-color: ${theme.colors.aluminum};
    min-width: ${minWidth};
    min-height: ${minHeight};
    height: 100%;
    border-radius: 8px;
    opacity: 0.3;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: #fff;
      top: 0;
      left: 0;

      animation: 1s slide infinite;
      animation-timing-function: linear;
    }
  `,
);

export default ComponentLoader;
