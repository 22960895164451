import {
  FC,
  KeyboardEventHandler,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { InputErrorType, InputHtmlType } from 'types/commonTypes';
import FieldErrors from '../FieldErrors/FieldErrors';
import * as S from './Input.styles';
import { Spinner } from '../Spinner';
import styled from 'styled-components';
import RadioGroup from './RadioGroup';
export interface IInputProps {
  variant?: 'default' | 'searchBar' | 'searchBar-autoWidth';
  iconVariant?: 'right';
  name?: string;
  type?: InputHtmlType;
  placeholder?: string;
  margin?: string;
  icon?: JSX.Element;
  errors?: InputErrorType[];
  borderRadius?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<Element>;
  value?: string | ReadonlyArray<string> | number;
  defaultValue?: string | ReadonlyArray<string> | number | null;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  fontSize?: number;
  readOnly?: boolean;
  disabled?: boolean;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  required?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  showLoadingIndicator?: boolean;
  textIndent?: string;
  width?: string | number;
  searchByHandler?: any;
  selectedSearchType?: string;
  options?: any;
}

const HoverTip = styled.div`
  margin-top: 0.2rem;
  position: absolute;
  z-index: 2;
  background-color: white;
  border-radius: 5px;
  color: black;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px rgba(45, 45, 45, 0.2),
    0px 4px 8px rgba(45, 45, 45, 0.16), 0px 8px 16px rgba(45, 45, 45, 0.12);
`;

export const HoverSection = ({ children, options }: any) => {
  return <HoverTip>{children}</HoverTip>;
};

const InputWithRadio: FC<IInputProps> = ({
  variant = 'default',
  iconVariant,
  name,
  type = 'text',
  placeholder,
  margin,
  icon,
  errors,
  borderRadius,
  fontSize,
  onFocus,
  defaultValue,
  onBlur,
  inputRef,
  disabled,
  showLoadingIndicator,
  textIndent,
  width,
  options,
  searchByHandler,
  selectedSearchType,
  ...restProps
}) => {
  const isIcon = Boolean(icon);
  const isError = Boolean(errors?.length);
  const isPassword = type === 'password';

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onShowPasswordClick = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const onShowOptionsClick = (value: boolean) => {
    setIsOptionsVisible(value);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <S.StyledInputContainer $margin={margin} $width="30rem" ref={dropdownRef}>
      <S.StyledInputAndIconContainer>
        {isIcon && (
          <S.StyledIconContainer variant={iconVariant || variant}>
            {icon}
          </S.StyledIconContainer>
        )}
        {isPassword && (
          <S.StyledEyeIconButton
            $isPasswordVisible={isPasswordVisible}
            onClick={onShowPasswordClick}
          >
            <S.StyledEyeIcon />
          </S.StyledEyeIconButton>
        )}
        <S.StyledInput
          autoComplete="off"
          onFocus={onFocus}
          $isError={isError}
          $withIcon={isIcon}
          $borderRadius={borderRadius}
          $textIndent={textIndent}
          name={name}
          $fontSize={fontSize}
          type={isPassword && isPasswordVisible ? 'text' : type}
          placeholder={placeholder}
          defaultValue={defaultValue || undefined}
          onBlur={onBlur || undefined}
          variant={variant}
          ref={inputRef}
          disabled={disabled}
          style={{ height: '48px' }}
          onClick={() => {
            onShowOptionsClick(true);
          }}
          {...restProps}
        />
        {showLoadingIndicator && (
          <S.StyledIconContainer variant="right">
            <Spinner $isStratos $size="18px" $margin="5px 0 0 0" />
          </S.StyledIconContainer>
        )}
        {isOptionsVisible && options && options.length > 0 && (
          <HoverSection
            options={options}
            onBlur={() => onShowOptionsClick(false)}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                width: '28rem',
                padding: '0.5rem',
                gap: '0.2rem',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Search by: </span>
              <RadioGroup
                options={options}
                searchByHandler={searchByHandler}
                selectedSearchType={selectedSearchType}
              />
            </div>
          </HoverSection>
        )}
      </S.StyledInputAndIconContainer>
      {isError && <FieldErrors errors={errors} />}
    </S.StyledInputContainer>
  );
};

export default InputWithRadio;
