import styled from 'styled-components';

export const SocialPostsHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.smoke};
  padding: 8px 16px;
`;

export const SocialPostsHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SocialPostsTab = styled.div`
  background-color: #e7f1fc;
  color: #287de0;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;
