import { useGetProjectByIdQuery } from 'app/api';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { SkeletonDiv } from '../UI/LoadingSkeletons/Skeletons.styles';
import Avatar from '../UI/Avatar';

interface IProjectAvatarProps {
  size?: string;
}

const ProjectAvatar: FC<IProjectAvatarProps> = ({ size = '40px' }) => {
  const { projectId } = useParams<{ projectId: string }>();

  const {
    data: project,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
    isError: isProjectError,
  } = useGetProjectByIdQuery({ projectId: projectId }, { skip: !projectId });

  if (isProjectLoading || isProjectFetching) {
    return (
      <SkeletonDiv
        shimmerAnimation
        width={size}
        height={size}
        borderRadius="50%"
      />
    );
  }

  if (isProjectError) {
    return <Avatar size={size} />;
  }

  return <Avatar size={size} name={project?.name || ''} url={project?.logo} />;
};

export default ProjectAvatar;
