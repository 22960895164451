import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ itemsPerView: number }>(
  ({ itemsPerView }) => css`
    max-height: calc(${itemsPerView} * 50px);
    transition: 0.25s all ease-in-out;
    overflow: auto;
  `,
);

export const Table = styled.table<{ hideHeader?: boolean }>(
  ({ theme, hideHeader }) => css`
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    color: ${theme.colors.onyx};
    ${hideHeader
      ? css`
          ${THead} {
            display: none;
          }
        `
      : ''}
  `,
);

export const THead = styled.thead(({ theme }) => css``);

export const Tr = styled.tr<{ titleRow?: boolean }>(
  ({ theme, titleRow }) => css`
    &:hover {
      ${Td}:not(${Th}) {
        background-color: ${theme.colors.lightBlue};
        cursor: pointer;
      }
    }

    ${titleRow
      ? css`
          border-top: 1px solid ${theme.colors.smoke};
          border-bottom: 1px solid ${theme.colors.smoke};

          ${Td} {
            padding-top: 4px;
            padding-bottom: 4px;
            background-color: transparent !important;
          }
        `
      : ''}
  `,
);

interface ITdProps {
  even?: boolean;
  centerContent?: boolean;
  noVerticalPadding?: boolean;
  padding?: string;
  bold?: boolean;
}
export const Td = styled.td<ITdProps>(
  ({ theme, even, centerContent, noVerticalPadding, padding, bold }) => css`
    padding: 16px 16px 16px 32px;
    font-family: ${theme.fonts.inter};
    text-align: left;
    white-space: nowrap;
    //max-width: 200px;
    //min-width: 100px;
    //overflow: hidden;

    //&:first-child {
    //  font-weight: bold;
    //}

    ${even
      ? css`
          background-color: ${theme.colors.haze};
        `
      : ''}

    ${centerContent
      ? css`
          padding: 16px 0;
          text-align: center;
        `
      : ''}
    
    ${noVerticalPadding
      ? css`
          padding-top: 0;
          padding-bottom: 0;
        `
      : ''}

    ${padding
      ? css`
          padding: ${padding};
        `
      : ''}

    ${bold
      ? css`
          font-weight: bold;
        `
      : ''}
  `,
);

export const Th = styled(Td)<
  ITdProps & {
    columnWidth?: string | number;
    showBorderLeft?: boolean;
    showCursor?: boolean;
  }
>(
  ({
    theme,
    even,
    centerContent,
    columnWidth,
    padding,
    showBorderLeft,
    showCursor,
  }) => css`
    padding: 11px 16px 11px 32px;
    border-bottom: 1px solid ${theme.colors.smoke};

    font-family: ${theme.fonts.dmSans};
    font-weight: bold;
    &:first-child {
      border-left: none;
    }

    background-color: ${theme.colors.haze};

    ${even
      ? css`
          padding: 9px 32px;
          background-color: ${theme.colors.white};
          font-weight: normal;
          font-size: 12px;
          font-family: unset;
        `
      : ''}

    ${centerContent
      ? css`
          padding: 9px 0;
          text-align: center;
        `
      : ''}
    
    ${columnWidth
      ? css`
          width: ${columnWidth};
        `
      : ''}

    ${padding
      ? css`
          padding: ${padding};
        `
      : ''}

    ${showBorderLeft
      ? css`
          border-left: 1px solid ${theme.colors.smoke};
        `
      : ''}

    ${showCursor
      ? css`
          cursor: pointer;
        `
      : ''}
  `,
);

export const TdInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding-right: 4px;
`;

export const TBody = styled.tbody(({ theme }) => css``);

export const ExpandButton = styled.button<{ collapsed?: boolean }>(
  ({ collapsed, theme }) => css`
    padding: 0;
    border: none;
    background: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 12px;
    margin: 0 4px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    svg {
      path {
        fill: ${theme.colors.onyx};
      }
      transform: translateY(2px) rotate(90deg);
    }

    ${collapsed
      ? css`
          svg {
            transform: translateY(2px) rotate(-90deg);
          }
        `
      : ''}
  `,
);
