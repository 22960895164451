import Typography from 'app/components/Typography';
import { FC, useCallback } from 'react';
import {
  SocialPostsHeaderWrapper,
  SocialPostsTab,
  SocialPostsHeader as Header,
} from './SocialPosts.styles';
import DotsHorizontalIcon from 'app/assets/icons/DotsHorizontal';
import Button from 'app/components/UI/Button/Button';
import ContextMenu, {
  ContextMenuOption,
} from 'app/components/UI/ContextMenu/ContextMenu';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import { useDeleteSocialPostMutation } from 'app/api';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useNavigateToContent } from '../../CreateOrEditSocialPosts/hooks/useNavigateToContent';

interface ISocialPostsHeaderProps {
  title?: string;
  socialPostId?: number;
}

const SocialPostsHeader: FC<ISocialPostsHeaderProps> = ({
  title = 'Social Posts',
  socialPostId,
}) => {
  const { createModal } = usePromptModalContext();
  const [deleteSocialPost] = useDeleteSocialPostMutation();

  const { goToProjectContentPage } = useNavigateToContent();

  const handleDeleteSocialPost = useCallback(() => {
    createModal({
      title: `Delete Social Post`,
      description: `Are you sure you want to delete all social posts?`,
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      okButtonDanger: true,
      okButtonCallback: async () => {
        if (!socialPostId) {
          showErrorToast(
            'Could not delete social post. Social post ID not found.',
          );
          return;
        }

        try {
          await deleteSocialPost(socialPostId);
          showSuccessToast('Social Post deleted.');
          goToProjectContentPage();
        } catch (error) {
          showErrorToast(
            'Could not delete social post. ',
            getErrorText(error as FetchBaseQueryError),
          );
        }
      },
    });
  }, [createModal, deleteSocialPost, socialPostId, goToProjectContentPage]);

  return (
    <Header>
      <SocialPostsHeaderWrapper>
        <SocialPostsTab>
          <Typography.Text
            $colorName="orbit"
            $size={16}
            $lineHeight={24}
            $letterSpacing="0.0125em"
            $bold
          >
            {title}
          </Typography.Text>
        </SocialPostsTab>
        <ContextMenu
          renderButtonTrigger={args => (
            <Button variant="transparent-square-compact" onClick={args.onClick}>
              <DotsHorizontalIcon />
            </Button>
          )}
          buttonText={<></>}
          menuOptions={{ align: 'right' }}
        >
          <ContextMenuOption
            onClick={handleDeleteSocialPost}
            $padding="4px"
            $justifyContent="center"
          >
            <Typography.Text $colorName="redOrange">
              Delete Social Post
            </Typography.Text>
          </ContextMenuOption>
        </ContextMenu>
      </SocialPostsHeaderWrapper>
    </Header>
  );
};

export default SocialPostsHeader;
