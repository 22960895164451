import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    apple: '#3DB54A',
    kermit: '#00d377',
    black: '#090909',
    white: '#ffffff',
    whiteDimmed: '#f2f2f2',
    smoke: '#e4e4e9',
    nero: '#3f3f42',
    ocean: '#0058c0',
    redOrange: '#ED2029',
    volatileRed: '#CA3023',
    outrageous: '#ff6400',
    positive: '#00D377',
    aluminum: '#BCBCC1',
    bottleGreen: '#03543A',
    steel: '#737377',
    sky: '#00CAFF',
    lightBlue: '#E7F2FF',
    orbit: '#1D76DE',
    stellar: '#1addfc',
    onyx: '#0c0c0d',
    stratos: '#040329',
    haze: '#f5f5f9',
  },
  layout: {
    width: '95%',
    maxWidth: '1580px',
    tabletMaxWidth: '1024px',
    smallDesktopMaxWidth: '1280.1px',
    headerHeight: '72px',
  },
  fonts: {
    inter: "'Inter', sans-serif",
    rift: "'Rift', sans-serif",
    dmSans: "'DM Sans', sans-serif",
    lato: '"Lato", sans-serif',
  },
};
