import styled, { css } from 'styled-components';

interface IFlexibleContainerProps {
  padding?: string;
  width?: string;
  justifyContent?: string;
}

export const FlexibleContainer = styled.div<IFlexibleContainerProps>`
  ${({ padding = '0px', width = '100%', justifyContent = 'flex-start' }) => css`
    display: flex;
    justify-content: flex-start;
    min-width: fit-content;
    gap: 2rem;
    padding: ${padding};
    width: ${width};
    justify-content: ${justifyContent};
    flex-wrap: wrap;
  `}
`;

export const FlexibleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const WordCountPill = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.haze};
    border-radius: 20px;
    font-family: ${theme.fonts.inter};
    color: ${theme.colors.onyx};
    font-size: 14px;
    line-height: 16px;
    padding: 10px 12px;

    margin: 16px 16px;
    align-self: flex-start;
    opacity: 1;
    transform: translateY(-12px);
    transition: 0.125s ease-in-out;
    z-index: 1;
  `,
);
