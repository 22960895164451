import { IMediaOutlet } from 'app/api.types';
import { Anchor } from 'app/components/UI/Anchor';
import React from 'react';
import { FC } from 'react';

interface IMediaOutletLinkProps {
  outlets: IMediaOutlet[];
}

export const MediaOutletLink: FC<IMediaOutletLinkProps> = ({ outlets }) => {
  return (
    <>
      {outlets.map((outlet, id) => (
        <React.Fragment key={outlet.id}>
          {id !== 0 && <span>/</span>}
          <Anchor href={outlet.articleUrl} target="_blank">
            {outlet.name}
          </Anchor>
        </React.Fragment>
      ))}
    </>
  );
};
