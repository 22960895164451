import styled, { css } from 'styled-components/macro';

interface IBackDropProps {
  open?: boolean;
}

export const BackDrop = styled.div<IBackDropProps>(
  ({ open }) => css`
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(4, 3, 41, 0.6);
    z-index: 50;

    opacity: ${open ? 1 : 0};
    clip-path: inset(0% 0 0 0);
    transition: 0.25s all ease-in-out;
    animation: 0.3s fadeIn;

    justify-content: center;
    align-items: center;

    //backdrop-filter: blur(3px);
  `,
);

interface IModalProps {
  open?: boolean;
  maxWidth?: string;
  minWidth?: string;
  $overflow?: boolean;
}

export const ModalWrapper = styled.div<IModalProps>(
  ({
    maxWidth = 'auto',
    theme: {
      layout: { headerHeight },
    },
  }) => css`
    display: flex;
    position: fixed;
    top: ${headerHeight};
    left: 0;
    width: 100vw;
    height: calc(100vh - ${headerHeight});
    justify-content: center;
    align-items: center;
    z-index: 50;
    max-width: ${maxWidth};
  `,
);

export const Modal = styled.div<IModalProps>(
  ({
    open,
    minWidth = '512px',
    $overflow = false,
    theme: {
      layout: { headerHeight },
    },
  }) => css`
    @keyframes openAnim {
      0% {
        opacity: 0.1;
      }
      100% {
        opacity: 1;
      }
    }

    background-color: #fdfcfc;
    border-radius: 16px;
    box-shadow: 0 0 16px rgba(16, 16, 17, 0.2);
    min-width: ${minWidth};
    max-height: calc(100vh - ${headerHeight});
    overflow: ${$overflow ? 'visible' : 'hidden'};
    display: flex;
    flex-direction: column;

    animation: 0.4s openAnim;
    transition: 0.4s all ease-in-out;

    ${!open
      ? css`
          opacity: 0;
        `
      : ``}
  `,
);

export const ModalInner = styled.div<{ $overflow?: boolean }>(
  ({ $overflow }) => css`
    overflow-y: ${!$overflow ? 'auto' : 'visible'};
    height: 100%;
  `,
);
