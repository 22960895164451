import { FC, useEffect, useMemo, useState } from 'react';
import BackButton from '../../../../../../../components/UI/BackButton/BackButton';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import { usePitchSlice } from '../../../../../slice';
import {
  selectContentGenerationDrawerOpen,
  selectContentGenerationTone,
  selectContentGenerationToneLength,
  selectDigDeeperReportItem,
} from '../../../../../slice/selectors';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import { IPitchContent } from '../../../CreateOrEditPitch.types';
import PitchContentEditor from '../../PitchContentEditor/PitchContentEditor';
import BottomDrawer from '../../../../../../../components/UI/BottomDrawer/BottomDrawer';
import SideDrawer from '../../../../../../../components/UI/SideDrawer/SideDrawer';
import { SpinningAnimation } from '../../../../../../../components/UI/SpinningAnimation';
import RegenerateIcon from '../../../../../../../assets/icons/RegenerateIcon';
import {
  ContentLengthDropDown,
  ToneSelectDropDown,
} from '../../ToneSelectDropDown/ToneSelectDropDown';
import Button from '../../../../../../../components/UI/Button/Button';
import Typography from '../../../../../../../components/Typography';
import Gap from '../../../../../../../components/UI/Gap';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
} from '../../ToneSelectDropDown/ToneSelectDropDown.types';
import { createEMailToLink } from '../../../../../../../../utils/helpers';
import SendIcon from '../../../../../../../assets/icons/SendIcon';
import { IAuthorResponse } from '../../../../../../../api.types';
import { joinTextLines } from '../../../CreateOrEditPitch.helpers';
import ReasonsAccordion from '../../PitchContentEditor/parts/ReasonsAccordion';
import { DEFAULT_GENERATED_CONTENT_STATE } from '../../../CreateOrEditPitch.constants';
import useGeneratedEmailContent from '../hooks/useGeneratedEmailContent';
import { useGenerationLimits } from '../../../hooks/useGenerationLimits';
import useLimitExceededPopup from '../hooks/useLimitExceededPopup';
import {
  CONTENT_LENGTH_SELECT_OPTIONS,
  TONE_SELECT_OPTIONS,
} from '../../ToneSelectDropDown/ToneSelectDropDown.config';
import Tooltip, {
  upgradePlanTooltipProps,
} from 'app/components/UI/Tooltip/Tooltip';
import useOutOfLimitsMessages from '../../../hooks/useOutOfLimitsMessages';
import { PredictedContentTab } from 'app/components/ContentGeneration/parts/header/parts/Tabs';

interface IProps {
  authorEmail: string;
  creatorId: number | string;
  isPodcast: boolean;
  skipGenerateOnOpen?: boolean;
}

const ContentGenerationDrawer: FC<IProps> = ({
  authorEmail,
  creatorId,
  isPodcast,
}) => {
  const dispatch = useAppDispatch();
  const { actions } = usePitchSlice();

  const selectedTone = useAppSelector(selectContentGenerationTone);
  const selectedToneLength = useAppSelector(selectContentGenerationToneLength);
  const isDrawerOpen = useAppSelector(selectContentGenerationDrawerOpen);
  const reportItem = useAppSelector(selectDigDeeperReportItem);

  const [localSelectedTone, setLocalSelectedTone] = useState<IToneSelectOption>(
    TONE_SELECT_OPTIONS[0],
  );

  const [localSelectedContentLength, setLocalSelectedContentLength] =
    useState<IContentLengthSelectOption>(CONTENT_LENGTH_SELECT_OPTIONS[1]);

  const generatedContent = useGeneratedEmailContent({
    isPodcast,
    targetMediaType: reportItem?.targetMediaType,
    authorId: String(creatorId),
  });

  const generationLimits = useGenerationLimits();
  const limitExceededPopup = useLimitExceededPopup();

  const [content, setContent] = useState<IPitchContent>(
    DEFAULT_GENERATED_CONTENT_STATE,
  );

  const [reasons, setReasons] = useState<string[]>([]);

  const closeDrawer = () => {
    dispatch(actions.toggleContentGenerationDrawer(false));
  };

  useEffect(() => {
    if (isDrawerOpen) closeDrawer();
  }, [creatorId]);

  useEffect(() => {
    if (generatedContent.data) {
      const content: IPitchContent = {
        headline: joinTextLines(generatedContent.data.subject, false),
        contentName: 'generated',
        body: joinTextLines(generatedContent.data.pitch, true),
      };

      setContent(content);
      setReasons(generatedContent.data.reasons);
    } else {
      setContent(DEFAULT_GENERATED_CONTENT_STATE);
      setReasons([]);
    }
  }, [generatedContent.data]);

  const getSendEmailUrl = () => {
    return createEMailToLink(authorEmail, content.headline, content.body);
  };

  const onRegenerateClick = () => {
    if (generationLimits.authorEmailSuggestionsLeft > 1) {
      generatedContent.trigger(
        shouldBeRegenerated,
        localSelectedTone,
        localSelectedContentLength,
      );
    } else {
      limitExceededPopup.show();
    }
  };

  useEffect(() => {
    if (selectedTone.value !== localSelectedTone.value) {
      setLocalSelectedTone(selectedTone);
    }

    if (selectedToneLength.value !== localSelectedContentLength.value) {
      setLocalSelectedContentLength(selectedToneLength);
    }
  }, [isDrawerOpen, selectedTone, selectedToneLength]);

  const limitMessages = useOutOfLimitsMessages();

  const shouldBeRegenerated = useMemo(() => {
    if (localSelectedTone.value === selectedTone.value) return true;
    if (localSelectedContentLength.value === selectedToneLength.value)
      return true;

    return !(
      reportItem?.generatedContentStatus &&
      reportItem?.generatedContentStatus[localSelectedTone.value] &&
      reportItem.generatedContentStatus[localSelectedTone.value]
        .authorEmailLengthType === localSelectedContentLength.value
    );
  }, [
    localSelectedTone,
    selectedTone,
    localSelectedContentLength,
    selectedToneLength,
    reportItem,
  ]);

  const isAuthorObscured = reportItem?.obscured;

  return (
    <>
      <SideDrawer open={isDrawerOpen}>
        <FlexBlock
          padding="0 0 75px 0"
          flexDirection="column"
          alignItems="stretch"
          minHeight="100%"
        >
          <FlexBlock padding="16px 24px 8px 24px">
            <BackButton onClick={closeDrawer}>Back to Profile</BackButton>
          </FlexBlock>

          {generatedContent.loading ? (
            <FlexBlock
              padding="64px 24px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <SpinningAnimation>
                <RegenerateIcon fill="#1D76DE" size="64px" />
              </SpinningAnimation>

              <Typography.Text
                $lineHeight={20}
                $colorName="steel"
                maxWidth="360px"
                textAlign="center"
                $padding="36px"
              >
                Sit tight, we need a few minutes to personalize this pitch that
                would have taken you way longer to draft on your own
              </Typography.Text>
            </FlexBlock>
          ) : (
            <>
              <FlexBlock
                padding="0px 24px"
                flexDirection="column"
                alignItems="stretch"
              >
                <ReasonsAccordion reasons={reasons} />
              </FlexBlock>
              <PitchContentEditor
                pitchData={content}
                onChange={setContent}
                hideSubtitle
                screenEstatePercentage={0.6}
                padding="16px 24px"
                tab={PredictedContentTab.PITCH}
                digDeeperView
              />
            </>
          )}
        </FlexBlock>
      </SideDrawer>
      <BottomDrawer
        open={!generatedContent.loading && isDrawerOpen}
        $extendedHeight={isAuthorObscured}
      >
        <FlexBlock
          alignItems="center"
          columnGap="8px"
          minWidth="550px"
          maxWidth="550px"
          padding="12px 16px"
          borderLeft
          flexWrap="wrap"
        >
          <ToneSelectDropDown
            selectComponentWidth="216px"
            value={localSelectedTone}
            onChange={tone => setLocalSelectedTone(tone)}
            menuPlacement="top"
          />
          <ContentLengthDropDown
            selectComponentWidth="180px"
            value={localSelectedContentLength}
            onChange={tone => setLocalSelectedContentLength(tone)}
            menuPlacement="top"
          />
          <Button
            variant="gray-smoke-square"
            onClick={onRegenerateClick}
            disabled={generatedContent.loading}
          >
            <RegenerateIcon size="24" fill="#0C0C0D" />
          </Button>
          <FlexBlock flex={1} />
          <div style={{ width: '100%' }}>
            <a
              style={{ display: 'grid' }}
              href={getSendEmailUrl()}
              {...(isAuthorObscured && upgradePlanTooltipProps)}
            >
              <Button disabled={isAuthorObscured}>
                <SendIcon />
                <Gap size={12} />
                Draft Email
              </Button>
            </a>
          </div>
          {isAuthorObscured && (
            <>
              <FlexBlock
                minWidth="100%"
                alignItems="center"
                justifyContent="center"
                padding="4px"
              >
                <Typography.Text $lineHeight={20} $colorName="steel">
                  Upgrade to our{' '}
                  <a onClick={limitMessages.goToBilling}>
                    {limitMessages.planToUpgrade}
                  </a>
                </Typography.Text>
              </FlexBlock>
              <Tooltip
                id="upgradePlanTooltip"
                getContent={content => {
                  return (
                    <Typography.Text $colorName="nero" $size={14}>
                      {content}
                    </Typography.Text>
                  );
                }}
              />
            </>
          )}
        </FlexBlock>
      </BottomDrawer>
    </>
  );
};

export default ContentGenerationDrawer;
