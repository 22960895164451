import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import Input from 'app/components/UI/Input/Input';
import { FC } from 'react';
import * as S from '../CreateOrEditSocialPostsPage.styles';
import Button from 'app/components/UI/Button/Button';
import ContentGenerationModal from '../components/Modals/SocialPostsGeneratingModal';
import { ToneSelectDropDown } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import SocialProviderSelector from '../components/Inputs/SocialProviderSelector';
import {
  GenerateStartCallback,
  useSocialPostForm,
} from '../hooks/useSocialPostForm';
import FormSection from './FormSection';
import SocialInputValidationMessage from './SocialInputValidationMessage';
import {
  MIN_SOCIAL_POST_TITLE_WORDS,
  MIN_SOCIAL_POSTS_TOPIC_WORDS,
} from '../CreateOrEditSocialPosts.constants';

const CreateSocialPostsForm: FC<{
  onGenerateStart: GenerateStartCallback;
  isGenerating: boolean;
}> = ({ onGenerateStart, isGenerating }) => {
  const {
    state: { title, topic, tone, socialProviders },
    handleGenerate,
    handleFieldChange,
    handleFormReset,
    isResetAvailable,
    isFormValid,
  } = useSocialPostForm(onGenerateStart);

  return (
    <>
      <Typography.Text
        $colorName="nero"
        $size={14}
        $lineHeight={20}
        $letterSpacing="0.0025em"
        $margin="0 0 24px 0"
      >
        Required fields*
      </Typography.Text>
      <FlexBlock flexDirection="row" columnGap="32px">
        <FlexBlock flexDirection="column" flex={1}>
          <FormSection
            index={1}
            title="Title*"
            subtitle="What’s your post about?"
            compact={true}
          >
            <Input
              variant="textFieldWhite"
              disabled={isGenerating}
              value={title}
              onChange={e =>
                handleFieldChange({
                  type: 'SET_TITLE',
                  payload: e.target.value,
                })
              }
              placeholder="Title will not be used for content generation."
              borderRadius="8px"
            />
            <SocialInputValidationMessage
              input={title}
              requiredWords={MIN_SOCIAL_POST_TITLE_WORDS}
            />
          </FormSection>
          <Gap size={30} />
          <FormSection
            index={3}
            title="Topic*"
            subtitle="Provide the topic or sentences that describe what the post should be about."
            isSubtitleBold={false}
            compact={true}
          >
            <S.ContentInputWrapper>
              <S.SocialContentInput
                style={{ margin: 0 }}
                value={topic}
                onChange={e =>
                  handleFieldChange({
                    type: 'SET_TOPIC',
                    payload: e.target.value,
                  })
                }
                minRows={7}
                maxRows={20}
                placeholder="Content added here will be used to generate your social post. It could be an overview of a topic, phrases, bullet points, etc."
                $variant="bordered"
                disabled={isGenerating}
              />
              <SocialInputValidationMessage
                input={topic}
                requiredWords={MIN_SOCIAL_POSTS_TOPIC_WORDS}
              />
            </S.ContentInputWrapper>
          </FormSection>
        </FlexBlock>
        <FlexBlock flexDirection="column" flex={1}>
          <FormSection
            index={2}
            title="Social Posts*"
            subtitle="What posts do you want to generate?"
            compact={true}
          >
            <SocialProviderSelector
              inputPlaceholder="Choose social posts"
              socialProviders={socialProviders}
              handleSocialProvidersChange={options =>
                handleFieldChange({
                  type: 'SET_SOCIAL_PROVIDERS',
                  payload: options,
                })
              }
              // disabled={isGenerating}
            />
            <SocialInputValidationMessage hidden />
          </FormSection>
          <Gap size={30} />
          <FormSection
            index={4}
            title="Tone*"
            subtitle="What would you like your Social Posts tone to be?"
            isSubtitleBold={false}
            compact={true}
          >
            <S.FullWidth>
              <ToneSelectDropDown
                value={tone}
                onChange={toneOption =>
                  handleFieldChange({
                    type: 'SET_TONE',
                    payload: toneOption,
                  })
                }
                selectComponentWidth="100%"
                selectorHeight={48}
                selectorRadius={8}
                // disabled={isGenerating}
              />
            </S.FullWidth>
          </FormSection>
        </FlexBlock>
      </FlexBlock>
      <Gap size={24} />
      <FlexBlock
        flexDirection="row"
        alignItems="center"
        columnGap="8px"
        style={{ alignSelf: 'flex-end' }}
      >
        <Button
          onClick={handleFormReset}
          disabled={!isResetAvailable || isGenerating}
          variant="secondary"
        >
          <Typography.Text $dmSans $bold $size={16} $lineHeight={20}>
            Reset
          </Typography.Text>
        </Button>
        <Button
          onClick={handleGenerate}
          disabled={isGenerating || !isFormValid}
        >
          <Typography.Text
            $dmSans
            $bold
            $size={16}
            $lineHeight={20}
            $colorName="white"
          >
            Generate
          </Typography.Text>
        </Button>
      </FlexBlock>
      <ContentGenerationModal isOpen={isGenerating} />
    </>
  );
};

export default CreateSocialPostsForm;
