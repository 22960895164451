import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ closed?: boolean }>(
  ({ closed, theme }) => css`
    width: ${closed ? '88px' : '418px'};
    border-left: 1px solid ${theme.colors.smoke};
    transition: 0.4s all ease-in-out;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    display: flex;
    flex-direction: column;

    .settings-bar-header {
      opacity: ${closed ? '0' : '1'};
      transition: 0.4s all ease-in-out;
    }
  `,
);

export const Content = styled.div<{
  isHidden?: boolean;
  absolute?: boolean;
  scrollable?: boolean;
}>(
  ({ isHidden, absolute, scrollable = false }) => css`
    ${absolute || isHidden
      ? css`
          position: absolute;
        `
      : ''}
    transition: .4s all ease-in-out;
    opacity: ${isHidden ? '0' : '1'};
    //height: calc();
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    width: 100%;
    flex: 1;
    ${scrollable
      ? css`
          overflow-y: scroll;
        `
      : ''}

    &:nth-child(2) {
      transform: translateX(${isHidden ? '-250px' : '0'});
    }

    &:last-child {
      transform: translateX(${isHidden ? '250px' : '0'});
      width: 418px;
    }
  `,
);

export const TonePill = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    padding: 6px 8px;
    background-color: ${theme.colors.haze};
    margin-bottom: 8px;
  `,
);

export const ContentItem = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid ${theme.colors.smoke};
  `,
);

export const FlexItemAligned = styled.div`
  align-self: flex-start;
`;

export const ContentVersionChangeWarningWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${theme.colors.haze};
    width: 88px;
    height: 88px;
  `,
);
