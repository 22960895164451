export const getGaugeColor = (value: number) => {
  // percent of 60 or over should show a Green outline
  // percent of 59-31 should show an Orange outline
  // percent of 30 or less should show a Red outline

  if (value >= 60) {
    return 'green';
  } else if (value >= 31) {
    return 'orange';
  }
  return 'red';
};
