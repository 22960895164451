import styled, { css } from 'styled-components';
import { IMenuProps, OptionHoverAnimationType } from './ContextMenu';
import Typography from '../../Typography';
// import { CommonStyleProps } from '../../../types/commonTypes';

export const Wrapper = styled.div`
  position: relative;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  background-color: black;
`;

export const Menu = styled.div<IMenuProps>(
  ({
    theme,
    minWidth = '200px',
    maxWidth = 'auto',
    align = 'left',
    distanceFromTrigger = 8,
    portalEnabled,
    offsetX = 0,
    offsetY = 0,
    width = 0,
    height = 0,
    positionOffsetX = 0,
    maxHeight = '50vh',
    visibleOverFlow = false,
  }) => css`
    position: absolute;
    background-color: ${theme.colors.white};
    min-width: ${minWidth};
    max-width: ${maxWidth};
    padding: 8px 0;
    border: 1px solid ${theme.colors.aluminum};
    border-radius: 8px;
    top: calc(100% + ${distanceFromTrigger}px);
    max-height: ${maxHeight};
    overflow: ${visibleOverFlow ? 'visible' : 'auto'};
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    ${align === 'left'
      ? css`
          left: ${0 + positionOffsetX}px;
        `
      : css`
          right: ${0 + positionOffsetX}px;
        `}
    z-index: 1000;

    ${portalEnabled
      ? css`
          position: absolute;
          top: ${offsetY + height + distanceFromTrigger}px;
          // left: ${offsetX}px;

          ${align === 'left'
            ? css`
                left: ${offsetX}px;
              `
            : css`
                right: unset;
                left: ${offsetX - 150}px;
              `}
        `
      : ''}
  `,
);

export const OptionText = styled.div<{}>(
  ({ theme }) => css`
    color: black;
    &:nth-child(2) {
      margin-left: 8px;
    }
  `,
);

export const OptionWrapper = styled.div<{
  hoverAnimation: OptionHoverAnimationType;
  padding?: string;
  justifyContent?: string;
}>(
  ({
    theme,
    hoverAnimation,
    padding = '8px 16px',
    justifyContent = false,
  }) => css`
    display: flex;

    padding: ${padding};
    cursor: pointer;
    transition: 0.25s all ease-in-out;
    ${justifyContent && `justify-content: ${justifyContent};`}
    ${hoverAnimation === 'default'
      ? css`
          &:hover {
            background-color: ${theme.colors.haze};
          }
        `
      : ''}

    ${hoverAnimation === 'blue-text'
      ? css`
          &:hover {
            ${OptionText}, ${Typography.Text} {
              color: ${theme.colors.orbit} !important;
            }
          }
        `
      : ''}
  `,
);
