import { FC } from 'react';
import { InputErrorType } from 'types/commonTypes';
import * as S from './FieldErrors.styles';

interface IProps {
  errors?: InputErrorType[];
  noPadding?: boolean;
}

const FieldErrors: FC<IProps> = ({ errors, noPadding = false }) => {
  return (
    <S.StyledErrorsContainer $noPadding={noPadding}>
      {errors?.map((error, idx) => {
        return <div key={idx}>{error}</div>;
      })}
    </S.StyledErrorsContainer>
  );
};

export default FieldErrors;
