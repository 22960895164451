import { selectUser } from 'app/containers/Global/slice/selectors';
import { useAppSelector } from 'store/hooks';
import { localStorageEnum } from 'types';
import { MONITOR_ALERTS_COOKIE_KEY } from './MonitorAlertsPage.constants';
import { MONITOR_COOKIE_DOMAIN } from 'utils/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteConstants } from 'app/routes';
import { showErrorToast } from 'utils/toast';
import { logger } from 'utils/apm/logger';

interface SetCookiePayload {
  key: string;
  cookie: string;
  domain: string;
  exp: number | string;
}

export const useMonitorCookies = () => {
  const user = useAppSelector(selectUser);
  const monitorHash = user?.monitorHash;

  const history = useHistory();

  const token = localStorage.getItem(localStorageEnum.token) || '';

  const generateCookieValue = useCallback(
    (expiration: number) => {
      const value = {
        user: {
          email: user?.email,
          exp: expiration,
          role: user?.role.code,
          status: user?.statusType.code,
        },
        hash: user?.monitorHash,
      };
      return JSON.stringify(value);
    },
    [user],
  );

  const getCookie = (key: string) => {
    let cookie = document.cookie
      .split(';')
      .find(cookie => cookie.trim().startsWith(`${key}=`));
    if (cookie) return true;

    return undefined;
  };

  const setBrowserCookie = ({ key, cookie, domain, exp }: SetCookiePayload) => {
    const expirationDate = new Date(exp).toUTCString();
    const generatedCookie = `${key}=${cookie};expires=${expirationDate};path=/;domain=${domain}`;
    document.cookie = generatedCookie;
  };

  const [isMonitorCookieSet, setIsMonitorCookieSet] = useState<
    undefined | boolean
  >(getCookie(MONITOR_ALERTS_COOKIE_KEY));

  const decodeJwt = useCallback((token: string) => {
    if (!token) return undefined;

    const tokenBase64 = token.split('.');

    if (!tokenBase64 || !tokenBase64[1]) return undefined;

    const base64 = tokenBase64[1].replace('-', '+').replace('_', '/');

    try {
      const parsedToken = JSON.parse(window.atob(base64));
      return parsedToken;
    } catch (e) {
      return undefined;
    }
  }, []);

  const decodedToken = useMemo(() => decodeJwt(token), [decodeJwt, token]);

  useEffect(() => {
    if (isMonitorCookieSet === undefined && !monitorHash) {
      showErrorToast('Cannot access the page.');
      logger.logError('Monitor - Load monitor page - Cookie or Hash missing');
      history.push(RouteConstants.projects.list);
    }

    if (
      isMonitorCookieSet === undefined &&
      monitorHash &&
      decodedToken &&
      decodedToken.exp
    ) {
      const timestampInMs = decodedToken.exp * 1000;
      const cookiePayload = {
        key: MONITOR_ALERTS_COOKIE_KEY,
        cookie: generateCookieValue(timestampInMs),
        domain: MONITOR_COOKIE_DOMAIN,
        exp: timestampInMs,
      };

      setBrowserCookie(cookiePayload);
      setIsMonitorCookieSet(true);
    }

    return () => {
      if (isMonitorCookieSet) {
        const cookiePayload = {
          key: MONITOR_ALERTS_COOKIE_KEY,
          cookie: '',
          domain: MONITOR_COOKIE_DOMAIN,
          exp: new Date(0).toUTCString(),
        };

        setBrowserCookie(cookiePayload);
      }
    };
  }, [
    isMonitorCookieSet,
    decodedToken,
    monitorHash,
    history,
    generateCookieValue,
  ]);

  return {
    isMonitorCookieSet,
  };
};
