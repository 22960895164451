import { FC, useCallback, useState } from 'react';
import Dropzone, { Accept } from 'react-dropzone';
import * as S from './FileDropzone.style';
import Typography from '../Typography';
import { showErrorToast } from '../../../utils/toast';
import UploadCloudIcon from 'app/assets/icons/UploadCloudIcon';
import FlexBlock from '../UI/FlexBlock';
import Button from '../UI/Button/Button';

export type FileLoadHandler = (
  file: File,
  fileBuffer: ArrayBuffer | string,
) => void;

interface IFileDropzoneProps {
  label?: string;
  onFileLoad?: FileLoadHandler;
  accept?: Accept;
}

const FileDropzone: FC<IFileDropzoneProps> = ({
  label,
  onFileLoad,
  accept,
  children,
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const onDrop = useCallback(acceptedFiles => {
    setIsDraggingOver(false);
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();

      reader.onabort = () => showErrorToast('File reading was aborted');
      reader.onerror = () => showErrorToast('File reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        if (onFileLoad && binaryStr) {
          onFileLoad(file, binaryStr);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  return (
    <S.Wrapper>
      {label && (
        <S.Label>
          <Typography.Text $colorName="steel" $lineHeight={16}>
            {label}
          </Typography.Text>
        </S.Label>
      )}
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        onDropRejected={() => {
          showErrorToast(
            'File format is not supported. Please use .csv file format.',
            'Invalid file',
          );
          setIsDraggingOver(false);
        }}
        onDragEnter={() => setIsDraggingOver(true)}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        {({ getRootProps, getInputProps }) => (
          <S.Root {...getRootProps()} $hover={isDraggingOver}>
            <S.Input {...getInputProps} />
            {!children ? (
              <FlexBlock
                flexDirection="column"
                alignItems="center"
                rowGap="5px"
              >
                <UploadCloudIcon
                  width={41}
                  height={32}
                  hoverMode={isDraggingOver}
                />
                {!isDraggingOver && (
                  <>
                    <Typography.Text
                      $size={14}
                      $lineHeight={20}
                      $colorName="steel"
                      textAlign="center"
                    >
                      Drag & Drop
                    </Typography.Text>
                    <Typography.Text $size={14} $lineHeight={20}>
                      or
                    </Typography.Text>
                    <Button variant="primary" compact>
                      Browse Files
                    </Button>
                  </>
                )}
              </FlexBlock>
            ) : (
              children
            )}
          </S.Root>
        )}
      </Dropzone>
    </S.Wrapper>
  );
};

export default FileDropzone;
