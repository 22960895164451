import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  useCreateProjectMutation,
  useCreateNewPitchMutation,
  useGetGeneratedContentMutation,
} from 'app/api';
import { IProject, IGeneratedContentResponse } from 'app/api.types';
import { globalActions } from 'app/containers/Global/slice';
import { RouteConstants } from 'app/routes';
import { Dispatch, SetStateAction } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getErrorText } from 'utils/error-messages';
import { showErrorToast } from 'utils/toast';

const useCreatePitchAndGenerateContent = () => {
  const [generateContent, { isLoading }] = useGetGeneratedContentMutation();
  const [createProject] = useCreateProjectMutation();
  const [createPitch] = useCreateNewPitchMutation();

  const dispatch = useAppDispatch();

  const isGeneratingContent = isLoading;

  const handleContentGeneration = async (body: string, tone: string) => {
    try {
      const generatedContent = await generateContent({
        title: '',
        bodyCopy: body,
        tone,
      }).unwrap();

      return generatedContent;
    } catch (error) {
      showErrorToast('Could not generate content.');
      return undefined;
    }
  };

  const handleCreateProject = async (
    projectTitle: string,
    tenantId: number,
    projectLogo?: string,
    setNameError?: Dispatch<SetStateAction<string | null>>,
  ) => {
    try {
      if (!tenantId) return { project: null, error: null };
      const project = await createProject({
        tenantId: tenantId,
        name: projectTitle,
        logo: projectLogo || undefined,
        country: 'CA',
      }).unwrap();

      return { project, error: null };
    } catch (e) {
      if (e?.data?.errorCode === 'BrandAlreadyExistsException') {
        showErrorToast(
          `Project name "${projectTitle}" already exists`,
          'Error',
        );
        if (setNameError) {
          setNameError(`Project name "${projectTitle}" already exists`);
        }
      } else {
        showErrorToast(getErrorText(e as FetchBaseQueryError), 'Error');
      }
      return { brand: null, error: e };
    }
  };

  const handlePitchCreation = async (
    content: IGeneratedContentResponse,
    project: IProject,
    tenantId: number,
  ) => {
    try {
      if (!project || !tenantId) return;

      const newPitch = await createPitch({
        body: content.articleByline,
        headline: content.generatedTitle,
        publishDate: '',
        status: 'ACTIVE',
        projectId: project.id,
      });

      return {
        pitch: newPitch,
        routeParamaters: {
          projectId: project.id,
          tenantId: tenantId,
        },
      };
    } catch (error) {
      dispatch(
        globalActions.authorizeUser({
          redirectUrl: RouteConstants.projects.list,
        }),
      );
      return undefined;
    }
  };

  return {
    generateContent: handleContentGeneration,
    isGeneratingContent: isGeneratingContent,
    createProject: handleCreateProject,
    createNewPitch: handlePitchCreation,
  };
};

export default useCreatePitchAndGenerateContent;
