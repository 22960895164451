import { useAppSelector } from 'store/hooks';
import { selectUser } from '../slice/selectors';
import { UserRoleEnum } from '../slice/types';
import { useRouteMatch } from 'react-router-dom';
import { RouteConstants } from 'app/routes';

export const useMonitorUserRouteGuard = () => {
  const user = useAppSelector(selectUser);

  const isMonitorUser = user?.role.code === UserRoleEnum.CLIENT_MONITOR_USER;

  const isProfilePage = useRouteMatch(
    RouteConstants.userProfile.indexWithParams,
  );

  const isMonitorPage = useRouteMatch(RouteConstants.misc.monitor);

  return isMonitorUser && !isProfilePage && !isMonitorPage;
};
