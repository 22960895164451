import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.global || initialState;

export const selectGlobal = createSelector([selectSlice], state => state);

export const selectRedirectTo = createSelector(
  [selectGlobal],
  state => state.redirectTo,
);

export const selectInitialized = createSelector(
  [selectGlobal],
  state => state.initialized,
);

export const selectAuthorized = createSelector(
  [selectGlobal],
  state => state.authorized,
);

export const selectActiveTenantId = createSelector(
  [selectGlobal],
  state => state.activeTenantId,
);

export const selectActiveTenantObject = createSelector(
  [selectGlobal],
  state => state.activeTenantObject,
);

export const selectUser = createSelector([selectGlobal], state => state.user);

export const selectUserPermissions = createSelector(
  [selectGlobal],
  state => state.permissions,
);

export const selectNeedHelpModalOpen = createSelector(
  [selectGlobal],
  state => state.needHelpModalOpen,
);

export const selectStripeModalOpen = createSelector(
  [selectGlobal],
  state => state.stripeModalOpen,
);
