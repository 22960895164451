import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  border-radius: 0 0 8px 8px;
  overflow: hidden !important;
  border-top: 1px solid ${p => p.theme.colors.smoke};
  width: 100%;
`;

export const BlurredContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
