import { useMemo } from 'react';
import { useWindowSize } from '../hooks/useWindowSize';
import { breakpoints, ResponsiveBreakpoints } from './breakpoints';

const getBreakpoints = ({
  width,
}: {
  width: number;
}): ResponsiveBreakpoints => {
  return {
    smallMobile:
      breakpoints.smallMobile.from <= width &&
      width < breakpoints.smallMobile.to,
    mobile: breakpoints.mobile.from <= width && width < breakpoints.mobile.to,
    tablet: breakpoints.tablet.from <= width && width < breakpoints.tablet.to,
    desktop:
      breakpoints.desktop.from <= width && width < breakpoints.desktop.to,
    largeDesktop:
      breakpoints.largeDesktop.from <= width &&
      width < breakpoints.largeDesktop.to,
  };
};

export const useResponsiveQuery = () => {
  const { width } = useWindowSize();
  return useMemo(() => {
    return getBreakpoints({ width });
  }, [width]);
};
