import { ReactNode } from 'react';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import TwitterIcon from '../../assets/icons/TwitterIcon';
import InstagramIcon from '../../assets/icons/InstagramIcon';
import LinkedinIcon from '../../assets/icons/LinkedinIcon';

export type SocialType = 'facebook' | 'instagram' | 'twitter' | 'linkedin';

interface SocialConfig {
  type: SocialType;
  icon: ReactNode;
  color: string;
}

const SOCIAL_CONFIG: SocialConfig[] = [
  {
    type: 'facebook',
    icon: <FacebookIcon />,
    color: 'rgba(25,119,243,0.1)',
  },
  {
    type: 'twitter',
    icon: <TwitterIcon />,
    color: 'rgba(42, 169, 224, 0.1)',
  },
  {
    type: 'instagram',
    icon: <InstagramIcon />,
    color: 'rgba(225,48,108,0.1)',
  },
  {
    type: 'linkedin',
    icon: <LinkedinIcon />,
    color: 'rgba(67,157,231,0.1)',
  },
];

export const getSocialConfig = (type: SocialType): SocialConfig => {
  const cfg = SOCIAL_CONFIG.find(c => c.type === type);
  if (cfg) return cfg;
  return SOCIAL_CONFIG[0];
};
