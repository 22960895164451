import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import RoundedPill from 'app/components/UI/RoundedPill';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as S from '../CreateFirstPitch.styles';
import { ToneSelectDropDown } from '../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import Input from 'app/components/UI/Input/Input';
import { Anchor } from 'app/components/UI/Anchor';
import Button from 'app/components/UI/Button/Button';
import {
  showSuccessToast,
  showErrorToast,
  createPermanentToast,
} from 'utils/toast';
import { IFirstPitchFormState } from '../CreateFirstPitch';
import useCreatePitchAndGenerateContent from '../hooks/useCreatePitchAndGenerateContent';
import FormHeader from './FormHeader';
import { getWordCount, uploadLogoResource } from 'utils/helpers';
import useDebounce from 'utils/hooks/useDebounce';
import Avatar from 'app/components/UI/Avatar';
import { GENERATE_LOADING_TOOLTIP_CONTENT } from '../../CreateOrEditPitch/CreateOrEditPitch.constants';
import { getOriginalContentAsObject } from '../ CreateFirstPitch.helpers';
import { IToneSelectOption } from '../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';

interface IProps {
  setData: Dispatch<SetStateAction<IFirstPitchFormState>>;
  tenantId: number;
  tone: IToneSelectOption;
  setTone: Dispatch<SetStateAction<IToneSelectOption>>;
}

const ContentForm: FC<IProps> = ({ setData, tenantId, tone, setTone }) => {
  const [projectTitle, setBrandTitle] = useState('');
  const [content, setContent] = useState('');
  const [brandImage, setBrandImage] = useState<string | undefined>(undefined);

  const debouncedAvatarUrl = useDebounce(brandImage, 300);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [nameError, setNameError] = useState<string | null>(null);
  const [contentLengthError, setContentLengthError] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (projectTitle && nameError) setNameError(null);
  }, [projectTitle]);

  const [isGenerating, setIsGenerating] = useState(false);

  const { generateContent, createProject } = useCreatePitchAndGenerateContent();

  const canGenerateContent =
    projectTitle &&
    !nameError &&
    contentLengthError === null &&
    getWordCount(content) > 29 &&
    !isGenerating;

  const handleGenerateContentClick = async () => {
    const loader = createPermanentToast(GENERATE_LOADING_TOOLTIP_CONTENT);
    try {
      if (!tenantId || !projectTitle || !content) return;

      setIsGenerating(true);

      const { project } = await createProject(
        projectTitle,
        tenantId,
        brandImage || undefined,
        setNameError,
      );

      if (!project || !project.id) return;

      const generatedContent = await generateContent(content, tone.value);

      if (generatedContent) {
        setData({
          content: {
            generatedContent,
            originalContent: getOriginalContentAsObject(content),
            showGenerated: true,
          },
          project,
        });
        showSuccessToast('Content Generated.');
      }
    } catch (error) {
      showErrorToast('Could not Generate Content.');
    } finally {
      loader.close();
      setIsGenerating(false);
    }
  };

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> =
    async event => {
      if (event.target.files && event.target.files[0]) {
        if (!event.target.files[0].type.match('image')) {
          return showErrorToast(
            'Please use .png, .jpg or .jpeg',
            'Invalid file',
          );
        }
        try {
          const fileUrl = await uploadLogoResource(event.target.files[0]);
          setBrandImage(fileUrl);
        } catch (e) {
          // To Do: Handle file select error
        }
      }
    };

  const handleContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
    if (contentLengthError !== null) setContentLengthError(null);
  };

  const validateContentLength = () => {
    const wordCount = getWordCount(content);
    if (wordCount < 30) {
      setContentLengthError(30 - wordCount);
    }
  };

  return (
    <>
      <FormHeader />
      <FlexBlock flexDirection="column" margin="40px auto">
        <FlexBlock flexDirection="column" alignItems="left" minWidth="710px">
          <Typography.Text $size={40} $lineHeight={48} $colorName="onyx">
            Get Started
          </Typography.Text>
          <Typography.Text $size={16} $lineHeight={24} $colorName="steel">
            To get you going with PRophet, let's generate your first piece of
            content!
          </Typography.Text>
        </FlexBlock>
        <Gap size={30} />
        <FlexBlock flexDirection="column" minWidth="100%">
          <FlexBlock flexDirection="row" alignItems="center" columnGap="12px">
            <RoundedPill variant="orbit">
              <Typography.Text $colorName="orbit" $bold $dmSans>
                1
              </Typography.Text>
            </RoundedPill>
            <Typography.Text
              $colorName="onyx"
              $size={18}
              $lineHeight={24}
              $dmSans
              $bold
            >
              Topic
            </Typography.Text>
          </FlexBlock>
          <Gap size={18} />
          <FlexBlock
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding="0 16px 8px 16px"
            minWidth="100%"
          >
            <Typography.Text $colorName="nero" $size={16} $lineHeight={24}>
              What is your content about?
            </Typography.Text>
            {contentLengthError !== null && (
              <Typography.Text
                $colorName="redOrange"
                $size={16}
                $lineHeight={24}
              >
                Please enter{' '}
                <Typography.Text $bold $colorName="redOrange">
                  {contentLengthError}
                </Typography.Text>{' '}
                more word{contentLengthError > 1 && 's'}
              </Typography.Text>
            )}
          </FlexBlock>

          <S.BodyWrapper>
            <S.NativeInputBox
              placeholder="Please enter at least 30 words…"
              value={content}
              onChange={handleContentChange}
              onBlur={validateContentLength}
              style={contentLengthError ? { border: '1px solid #CA3023' } : {}}
              minRows={10}
              maxRows={20}
              disabled={isGenerating}
            />
          </S.BodyWrapper>
        </FlexBlock>
        <Gap size={30} />
        <FlexBlock flexDirection="column" minWidth="100%">
          <FlexBlock flexDirection="row" alignItems="center" columnGap="12px">
            <RoundedPill variant="orbit">
              <Typography.Text $colorName="orbit" $bold $dmSans>
                2
              </Typography.Text>
            </RoundedPill>
            <Typography.Text
              $colorName="onyx"
              $size={18}
              $lineHeight={24}
              $dmSans
              $bold
            >
              Tone
            </Typography.Text>
          </FlexBlock>
          <Gap size={18} />
          <Typography.Text
            $colorName="nero"
            $size={16}
            $lineHeight={24}
            $padding="0 0 8px 16px"
          >
            What would you like your content’s tone to be?
          </Typography.Text>
          <S.FullWidth>
            <ToneSelectDropDown
              value={tone}
              onChange={setTone}
              selectComponentWidth="100%"
              height="64px"
            />
          </S.FullWidth>
        </FlexBlock>
        <Gap size={30} />
        <FlexBlock flexDirection="column" minWidth="100%">
          <FlexBlock flexDirection="row" alignItems="center" columnGap="12px">
            <RoundedPill variant="orbit">
              <Typography.Text $colorName="orbit" $bold $dmSans>
                3
              </Typography.Text>
            </RoundedPill>
            <Typography.Text
              $colorName="onyx"
              $size={18}
              $lineHeight={24}
              $dmSans
              $bold
            >
              Project
            </Typography.Text>
          </FlexBlock>
          <Gap size={18} />
          <Typography.Text
            $colorName="nero"
            $size={16}
            $lineHeight={24}
            $padding="0 0 8px 16px"
          >
            Project Folder will be created with this same name. It can be edited
            if needed.
          </Typography.Text>
          <Input
            textIndent={debouncedAvatarUrl ? '20px' : undefined}
            placeholder="e.g M&M's"
            value={projectTitle}
            errors={nameError ? [nameError] : undefined}
            disabled={isGenerating}
            icon={
              debouncedAvatarUrl ? (
                <Avatar
                  size="40"
                  url={debouncedAvatarUrl}
                  name={projectTitle}
                  shadow
                />
              ) : undefined
            }
            onChange={e => setBrandTitle(e.target.value)}
          />
          <input
            type="file"
            hidden
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/png, image/jpg, image/jpeg"
          />
          <Anchor
            $margin="16px 16px 24px"
            $disabled={isGenerating}
            onClick={() => {
              if (!debouncedAvatarUrl) {
                fileInputRef?.current?.click();
              } else {
                setBrandImage(undefined);
              }
            }}
          >
            {!debouncedAvatarUrl ? 'Add Project Logo' : 'Remove Logo'}
          </Anchor>
        </FlexBlock>
        <S.FullWidth>
          <Button
            wide
            onClick={handleGenerateContentClick}
            disabled={!canGenerateContent}
            isLoading={isGenerating}
          >
            Generate Content
          </Button>
        </S.FullWidth>
      </FlexBlock>
    </>
  );
};

export default ContentForm;
