import styled, { css } from 'styled-components';
import { CommonStyleProps } from 'types/commonTypes';
import { ButtonVariantType } from './Button';
import Typography from '../../Typography';
import { CloseIcon } from '../CloseIcon';
import { hexToRGBA } from 'utils/helpers';

export const StyledBtn = styled.button<
  CommonStyleProps & {
    $isLoading?: boolean;
    $variant?: ButtonVariantType;
    $compact?: boolean;
    $baseColor?: string;
    disabled?: boolean;
    $hoverMode?: 'black';
    active?: boolean;
    wide?: boolean;
    boxShadow?: string;
    hasNotifications?: boolean;
  }
>(
  ({
    theme,
    $margin,
    $padding,
    $isLoading,
    $variant,
    $compact,
    $baseColor,
    disabled,
    $hoverMode,
    active,
    wide,
    boxShadow,
    hasNotifications,
  }) => css`
    white-space: nowrap;
    display: flex;
    color: ${$variant === 'primary' ? theme.colors.white : theme.colors.black};
    margin: ${$margin};
    font-family: ${theme.fonts.dmSans};
    font-size: ${$compact ? 14 : 16}px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: ${$compact ? '14px' : '24px'};
    text-align: center;
    text-transform: capitalize;
    padding: ${$compact ? '12px 16px' : '16px 24px'};
    ${$padding ? `padding: ${$padding};` : ''}
    border-radius:  ${$compact ? '8px' : '12px'};
    background-color: ${$variant === 'primary'
      ? theme.colors.orbit
      : 'transparent'};
    border: 1px solid
      ${$variant === 'primary' ? theme.colors.orbit : theme.colors.aluminum};
    cursor: ${$isLoading ? 'not-allowed' : 'pointer'};
    box-shadow: 0 2px 16px 0 transparent;

    transition: 0.3s box-shadow, 0.3s background-color, 0.3s color,
      0.3s outline-color;
    box-sizing: border-box;
    outline: transparent solid 3px;

    &:active {
      outline: ${theme.colors.orbit} solid 1px;
    }

    ${wide
      ? css`
          min-width: 220px;
        `
      : ''}

    ${$variant === 'gray'
      ? css`
          background-color: ${theme.colors.haze};
          border: 1px solid ${theme.colors.haze};
          transition: 0.3s border, 0.3s background-color, 0.3s color;
          padding: ${$compact ? '11px 16px' : '16px 24px'};
          &:hover {
            border: 1px solid ${theme.colors.smoke};
          }
        `
      : ''}

    ${$variant === 'danger'
      ? css`
          background-color: ${theme.colors.redOrange};
          border: none;
          color: #fff;
        `
      : ''}

    ${$variant === 'transparent-square'
      ? css`
          background-color: transparent;
          border: 1px solid rgba(188, 188, 193, 1);
          color: rgba(63, 63, 66, 1);
          height: 56px;
          width: 56px;
          border-radius: 8px;
          padding: 0;
          margin: 0;
        `
      : ''}

    ${$variant === 'transparent-square-compact'
      ? css`
          background-color: transparent;
          border: 1px solid rgba(188, 188, 193, 1);
          color: rgba(63, 63, 66, 1);
          height: 40px;
          width: 40px;
          border-radius: 8px;
          padding: 0;
          margin: 0;
        `
      : ''}

    ${$variant === 'transparent'
      ? css`
          background-color: transparent;
          border: 1px solid rgba(188, 188, 193, 1);
          color: rgba(63, 63, 66, 1);
          height: 56px;
          border-radius: 8px;
          padding: 8px 14.5px;
          margin: 0;
        `
      : ''}

    ${$variant === 'transparent-compact'
      ? css`
          background-color: transparent;
          border: 1px solid rgba(188, 188, 193, 1);
          color: rgba(63, 63, 66, 1);
          height: 40px;
          border-radius: 8px;
          padding: 8px 14.5px;
          margin: 0;
        `
      : ''} 

    ${$variant === 'primary-blue-v2'
      ? css`
          background-color: ${theme.colors.orbit};
          color: white;
          height: 56px;
          border-radius: 8px;
          padding: 5px 16px;
          margin: 0;
          border: none;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.005em;
          text-align: center;
        `
      : ''}

      ${$variant === 'primary-blue-v2-compact'
      ? css`
          background-color: ${theme.colors.orbit};
          color: white;
          height: 40px;
          border-radius: 8px;
          padding: 5px 16px;
          margin: 0;
          border: none;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.005em;
          text-align: center;
        `
      : ''}

    ${$variant === 'danger-outline'
      ? css`
          border: 1px solid ${theme.colors.redOrange};
          color: ${theme.colors.redOrange};

          &:hover {
            color: #fff;
            background-color: ${theme.colors.redOrange};
          }
        `
      : ''}

    ${$variant === 'gray-smoke'
      ? css`
          background-color: ${theme.colors.smoke};
          border: none;
        `
      : ''}

    ${$variant === 'gray-smoke-hover-blue'
      ? css`
          background-color: ${theme.colors.smoke};
          border: none;
          transition: 0.3s all ease-in-out;
          &:hover {
            background: rgba(235, 242, 251, 1);
            box-shadow: 0px 4px 16px 0px rgba(29, 118, 222, 0.4);
            svg path {
              fill: ${theme.colors.orbit};
            }
          }
        `
      : ''}

    ${$variant === 'gray-smoke-hover-danger'
      ? css`
          background-color: ${theme.colors.smoke};
          border: none;
          transition: 0.3s all ease-in-out;
          &:hover {
            background: rgba(252, 227, 210, 1);
            box-shadow: 0px 4px 16px 0px rgba(202, 48, 35, 0.15);
            svg path {
              fill: ${theme.colors.volatileRed};
            }
          }
        `
      : ''}

    ${$variant === 'gray-smoke-square'
      ? css`
          background-color: ${theme.colors.smoke};
          border-color: ${theme.colors.smoke};
          height: 56px;
          width: 56px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin: 0;
            justify-content: center;
          }
        `
      : ''}

    ${$variant === 'black-square'
      ? css`
          background-color: ${theme.colors.black};
          height: 56px;
          width: 56px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin: 0;
            justify-content: center;
          }
        `
      : ''}
    
    ${$variant === 'blue-square'
      ? css`
          background-color: ${theme.colors.orbit};
          border-color: ${theme.colors.orbit};
          height: 56px;
          width: 56px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin: 0;
            justify-content: center;
          }
        `
      : ''}

    ${$variant === 'secondary-blue'
      ? css`
          background-color: transparent;
          border: 1px solid ${theme.colors.orbit};
          color: ${theme.colors.orbit};
        `
      : ''}

    &:hover {
      ${$variant === 'primary'
        ? css`
            box-shadow: 0 2px 16px 0 rgba(0, 88, 192, 0.4);
          `
        : ``}
      ${$variant === 'secondary'
        ? css`
            background-color: ${theme.colors.black};
            color: ${theme.colors.white};
            svg path {
              fill: ${theme.colors.white};
            }
            ${Typography.Text} {
              color: white;
            }
          `
        : ''}
    }
    ${$variant === 'black'
      ? css`
          background-color: ${theme.colors.black};
          color: white;
        `
      : ''}

    ${$variant === 'white'
      ? css`
          color: ${theme.colors.onyx};
          background-color: ${theme.colors.white};
          border: 1px solid ${theme.colors.aluminum};
          transition: 0.3s border, 0.3s background-color, 0.3s color;
          padding: ${$compact ? '11px 16px' : '16px 24px'};
          &:hover {
            border: 1px solid ${theme.colors.smoke};
          }
        `
      : ''}

      
    ${$variant === 'icon-button'
      ? css`
          background-color: transparent;
          color: black;
          border: 1px solid transparent;
          transition: 0.25s all ease-in-out;

          width: 40px;
          height: 40px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            cursor: pointer !important;
          }

          &:hover {
            box-shadow: unset;
            background-color: transparent;
            opacity: 0.7;
            //border: 1px solid black;
            svg path {
              fill: ${theme.colors.black};
            }
          }
        `
      : ''}


    ${$variant === 'icon-button-no-padding'
      ? css`
          background-color: transparent;
          color: black;
          border: none;
          border: 1px solid transparent;
          transition: 0.25s all ease-in-out;

          //width: 40px;
          //height: 40px;
          padding: 0;
          justify-content: center;
          align-items: center;

          &:hover {
            box-shadow: unset;
            background-color: transparent;
            opacity: 0.7;
            //border: 1px solid black;
            svg path {
              fill: ${theme.colors.black};
            }
          }
        `
      : ''}
    
    
    ${$variant === 'icon-button-border' ||
    $variant === 'icon-button-border-active'
      ? css`
          background-color: transparent;
          color: black;
          border: none;
          border: 1px solid ${theme.colors.smoke};
          transition: 0.25s all ease-in-out;
          padding: 0;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          ${$variant === 'icon-button-border-active'
            ? css`
                border: 1px solid ${theme.colors.onyx};
                svg path {
                  fill: ${theme.colors.onyx};
                }
              `
            : ``}

          &:hover {
            ${$hoverMode === 'black'
              ? css`
                  background-color: ${theme.colors.black};
                  path {
                    fill: ${theme.colors.white};
                  }

                  ${CloseIcon} {
                    &:before,
                    &:after {
                      background-color: ${theme.colors.white};
                    }
                  }
                `
              : css`
                  box-shadow: unset;
                  background-color: transparent;
                  border: 1px solid ${theme.colors.onyx};
                `}
          }
        `
      : ''}
    ${$variant === 'icon-round'
      ? css`
          background-color: ${theme.colors.haze};
          color: black;
          border: 1px solid transparent;
          transition: 0.25s all ease-in-out;
          padding: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;

          &:hover {
            border: 1px solid ${theme.colors.smoke};
            background-color: ${theme.colors.haze};
            color: black;
            svg {
              path {
                fill: #000;
              }
            }
          }
        `
      : ''}
    ${$variant === 'icon-round-social'
      ? css`
          background-color: rgba(12, 12, 13, 0.05);
          color: black;
          border: 1px solid transparent;
          transition: 0.25s all ease-in-out;
          padding: 0;
          width: 32px;
          height: 32px;
          border-radius: 50%;

          &:hover {
            border: 1px solid ${theme.colors.smoke};
            background-color: ${theme.colors.haze};
            color: black;
            svg {
              path {
                fill: #000;
              }
            }
          }
        `
      : ''}
    ${$variant === 'icon-round-social-no-hover'
      ? css`
          background-color: ${theme.colors.haze};
          color: black;
          border: 1px solid transparent;
          transition: 0.25s all ease-in-out;
          padding: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;

          &:hover {
            border: 1px solid ${theme.colors.smoke};
            background-color: ${theme.colors.haze};
            color: black;
          }
        `
      : ''}
    
    ${$variant === 'anchor'
      ? css`
          border: none;
          padding: 0;
          font-weight: normal;
          font-size: ${$compact ? 12 : 14}px;
          line-height: 16px;
          color: ${theme.colors.orbit};
          font-family: ${theme.fonts.inter};
          margin: ${$margin ? $margin : 'none'};
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            background: none;
            color: ${theme.colors.orbit};
          }
        `
      : ''}

    ${$variant === 'danger-anchor'
      ? css`
          border: none;
          padding: 0;
          font-weight: normal;
          font-size: ${$compact ? 12 : 14}px;
          line-height: 16px;
          color: ${theme.colors.redOrange};
          font-family: ${theme.fonts.inter};
          margin: ${$margin ? $margin : 'none'};
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            background: none;
            color: ${theme.colors.redOrange};
          }
        `
      : ''}

    ${$variant === 'anchor-hover'
      ? css`
          background-color: transparent;
          border: none;
          transition: 0.25s all ease-in-out;
          color: ${$baseColor ?? theme.colors.orbit};

          svg path {
            fill: ${$baseColor ?? theme.colors.orbit};
          }

          &:hover {
            background-color: ${hexToRGBA(
              $baseColor ?? theme.colors.orbit,
              0.1,
            )};
          }
        `
      : ''}
    
    ${disabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
        `
      : ''}
    
    ${active
      ? css`
          svg path {
            fill: ${theme.colors.orbit};
          }
        `
      : ''}

      &:hover {
      ${boxShadow ? `box-shadow: ${boxShadow};` : ' 0 2px 16px 0 transparent;'};
    }

    ${hasNotifications ? `background: #1D76DE1A;` : ''}
  `,
);

export const StyledBtnContent = styled.span`
  margin: auto;
  text-decoration: none;
  display: flex;
  align-items: center;
`;
