import { Dispatch, FC, SetStateAction, useContext } from 'react';
import * as S from './PitchFooter.styles';
import { IPitch, IPressRelease } from 'app/api.types';
import { PitchPageContext } from '../../PitchPage.context';
import FooterMenu from './parts/Sections/FooterMenu';
import GenerationSelectionMenu from './parts/Sections/GenerationSelectionMenu';

interface IProps {
  pitch: IPitch | IPressRelease;
  hasReports: number;
  setExportModalOpen: Dispatch<SetStateAction<boolean>>;
  height?: number | undefined;
}

const PitchFooter: FC<IProps> = ({
  pitch,
  setExportModalOpen,
  height,
  hasReports,
}) => {
  const {
    isRapidGenerationView,
    setIsRapidGenerationView,
    selectedItemsCount,
    isGenerating,
  } = useContext(PitchPageContext);

  const hasSelectedItems = selectedItemsCount > 0;

  if (isGenerating) return <></>;

  return (
    <S.FooterContainer $spaceAround={isRapidGenerationView} $height={height}>
      <S.Content $spaceAround={isRapidGenerationView}>
        {isRapidGenerationView ? (
          <GenerationSelectionMenu hasSelectedItems={hasSelectedItems} />
        ) : (
          <FooterMenu
            hasReports={hasReports > 0}
            setIsRapidGenerationView={setIsRapidGenerationView}
            pitchStatus={pitch.status}
          />
        )}
      </S.Content>
    </S.FooterContainer>
  );
};

export default PitchFooter;
