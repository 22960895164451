import { SVGProps } from 'react';

const ThumbsUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#737377" transform="translate(-1227 -517)">
        <g transform="translate(260 72)">
          <g transform="translate(40 176)">
            <g transform="translate(0 217)">
              <g transform="translate(927 52)">
                <path d="M15.188 5.56a1.65 1.65 0 00-1.232-.552h-3.691V3.92a2.733 2.733 0 00-2.74-2.72.548.548 0 00-.49.3l-2.59 5.14H1.497C.891 6.64.401 7.127.4 7.728v5.984c0 .6.491 1.087 1.096 1.088h11.638a1.643 1.643 0 001.631-1.43l.822-6.528a1.625 1.625 0 00-.399-1.282zM1.496 7.728h2.74v5.984h-2.74V7.728z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ThumbsUpIcon;
