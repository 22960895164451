import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Modal from 'app/components/UI/Modal/Modal';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import ImageDropzone, {
  ImageFile,
  ImageURL,
} from 'app/components/FileDropzone/ImageDropzone';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import ClickAwayListener from 'react-click-away-listener';
import {
  AddLogoAnchor,
  Header,
  SectionFlexEnd,
} from 'app/containers/Projects/pages/ProjectsList/parts/AddNewProjectModal.styles';

import { Section, TextInput } from '../UserModal/UserModal.styles';

import { CloseIcon } from 'app/components/UI/CloseIcon';
import Button from 'app/components/UI/Button/Button';
import { clearBitLogo, uploadLogoResource } from 'utils/helpers';
import Gap from 'app/components/UI/Gap';
import {
  useCreateTenantMutation,
  useGetTenantByIdQuery,
  useUpdateTenantMutation,
} from 'app/api';
import { validateUrlField } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/parts/Socials/Socials.helpers';
import { theme } from 'styles/theme';

interface IProps {
  open: boolean | number;
  onClose: () => void;
}

const TenantModal: FC<IProps> = props => {
  const isEditMode = Number.isInteger(props.open);

  const {
    data: tenant,
    isLoading,
    isError,
  } = useGetTenantByIdQuery(props.open as number, {
    skip: !isEditMode,
  });

  const [createTenant, { isLoading: isCreatingTeanant }] =
    useCreateTenantMutation();

  const [updateTenant, { isLoading: isUpdatingTenant }] =
    useUpdateTenantMutation();

  const [imageResource, setImageResource] = useState<
    ImageURL | ImageFile | null
  >(null);

  const [tenantName, setTenantName] = useState<string>('');
  const [tenantUrl, setTenantUrl] = useState<string>('');
  const [tenantSsoDomain, setTenantSsoDomain] = useState<string>('');
  const [tenantSsoRealm, setTenantSsoRealm] = useState<string>('');

  const [isUploadingLogo, setIsUploadingLogo] = useState<boolean>(false);

  const isEditingSsoTenant = useMemo(() => {
    if (isEditMode && tenant && tenant.sso) {
      return true;
    }
  }, [isEditMode, tenant]);

  // Fields validation
  const [tenantNameError, setTenantNameError] = useState<string | null>(null);
  const [tenantUrlError, setTenantUrlError] = useState<string | null>(null);
  const [tenantSsoDomainError, setTenantSsoDomainError] = useState<
    string | null
  >(null);
  const [tenantSsoRealmError, setTenantSsoRealmError] = useState<string | null>(
    null,
  );

  const validateTenantName = useCallback(() => {
    const name = tenantName ? tenantName.trim() : '';
    if (!name) {
      setTenantNameError('Tenant name is required');
    } else if (name.length < 2 || name.length > 50) {
      setTenantNameError('Tenant name must be between 2 and 50 characters');
    } else {
      setTenantNameError(null);
    }
  }, [tenantName]);

  const validateTenantUrl = useCallback(() => {
    if (!tenantUrl) return;
    const isUrlValid = validateUrlField(tenantUrl.trim());
    if (!isUrlValid) {
      setTenantUrlError('Invalid URL');
    } else {
      setTenantUrlError(null);
    }
  }, [tenantUrl]);

  const validateTenantSsoDomain = useCallback(() => {
    if (!tenantSsoDomain) return;
    const isSsoDomainValid = tenantSsoDomain.trim().length > 2;
    if (!isSsoDomainValid) {
      setTenantSsoDomainError('Invalid SSO Domain');
    } else {
      setTenantSsoDomainError(null);
    }
  }, [tenantSsoDomain]);

  const validateTenantSsoRealm = useCallback(() => {
    if (!tenantSsoRealm) return;
    const isRealmValid = /^[a-zA-Z0-9-]*$/.test(tenantSsoRealm.trim());
    if (!isRealmValid) {
      setTenantSsoRealmError('Invalid SSO Realm');
    } else {
      setTenantSsoRealmError(null);
    }
  }, [tenantSsoRealm]);

  useEffect(() => {
    if (props.open === false) {
      setImageResource(null);
      setTenantName('');
      setTenantUrl('');
      setTenantSsoDomain('');
      setTenantSsoRealm('');
    }
  }, [props.open]);

  useEffect(() => {
    if (tenant && tenant.id) {
      setTenantName(tenant.name);
      setTenantUrl(tenant.url || '');

      if (tenant.logo) {
        setImageResource({ type: 'URL', content: tenant.logo });
      }

      if (tenant.sso) {
        setTenantSsoDomain(tenant.domain || '');
        setTenantSsoRealm(tenant.realm || '');
      }
    }
  }, [tenant]);

  useEffect(() => {
    if (isError)
      showErrorToast('Failed to load tenant data', 'Error', {
        toastId: 'tenant-modal-error',
      });
  }, [isError]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleProjectLogoFromUrl = () => {
    if (tenantUrl) {
      const logo = clearBitLogo(tenantUrl);
      if (logo) {
        setImageResource({ type: 'URL', content: logo });
      }
    }
  };

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> =
    async event => {
      if (event.target.files && event.target.files[0]) {
        if (!event.target.files[0].type.match('image')) {
          return showErrorToast(
            'Please use .png, .jpg or .jpeg',
            'Invalid file',
          );
        }

        setImageResource({ type: 'File', content: event.target.files[0] });
      }
    };

  const handleImageFileLoad = useCallback((file: ImageFile | null) => {
    setImageResource(file);
  }, []);

  const canResetFields = useMemo(() => {
    if (isEditMode) {
      const isLogoChanged =
        imageResource?.type === 'File' ||
        (imageResource?.type === 'URL' &&
          imageResource.content !== tenant?.logo);

      return (
        tenantName !== tenant?.name ||
        tenantUrl !== tenant?.url ||
        isLogoChanged
      );
    }

    if (!isEditMode) {
      return (
        tenantName !== '' ||
        tenantUrl !== '' ||
        imageResource !== null ||
        tenantSsoDomain !== '' ||
        tenantSsoRealm !== ''
      );
    }
  }, [
    isEditMode,
    imageResource,
    tenant,
    tenantName,
    tenantUrl,
    tenantSsoDomain,
    tenantSsoRealm,
  ]);

  const cannnotSubmit = useMemo(() => {
    if (isCreatingTeanant || isUpdatingTenant || isUploadingLogo || isLoading)
      return true;

    if (
      Boolean(tenantNameError) ||
      Boolean(tenantUrlError) ||
      Boolean(tenantSsoRealmError)
    )
      return true;

    if (isEditMode) {
      if (!tenant) return true;

      if (
        tenant.name === tenantName &&
        tenant.url === tenantUrl &&
        tenant.logo === imageResource?.content &&
        tenant.domain === tenantSsoDomain &&
        tenant.realm === tenantSsoRealm
      ) {
        return true;
      }

      return false;
    } else {
      if (tenantName === '') return true;
    }
  }, [
    isCreatingTeanant,
    isUpdatingTenant,
    isUploadingLogo,
    isLoading,
    tenantNameError,
    tenantUrlError,
    imageResource?.content,
    isEditMode,
    tenant,
    tenantName,
    tenantUrl,
    tenantSsoDomain,
    tenantSsoRealm,
    tenantSsoRealmError,
  ]);

  const handleReset = useCallback(() => {
    if (isEditMode && tenant) {
      setTenantName(tenant.name);
      setTenantUrl(tenant.url || '');
      if (tenant.logo) {
        setImageResource({ type: 'URL', content: tenant.logo });
      }

      if (tenant.sso) {
        setTenantSsoDomain(tenant.domain || '');
        setTenantSsoRealm(tenant.realm || '');
      }
    } else {
      setImageResource(null);
      setTenantName('');
      setTenantUrl('');
      setTenantSsoDomain('');
      setTenantSsoRealm('');
    }

    setTenantNameError(null);
    setTenantUrlError(null);
    setTenantSsoDomainError(null);
    setTenantSsoRealmError(null);
  }, [isEditMode, tenant]);

  const createTenantHandler = async () => {
    if (isCreatingTeanant) return;

    if (tenantNameError || tenantUrlError) {
      showErrorToast('Please fill all required fields', 'Error');
      return;
    }

    if (
      (tenantSsoDomain && !tenantSsoRealm) ||
      (tenantSsoRealm && !tenantSsoDomain)
    ) {
      showErrorToast(
        'To create SSO tenant, please provide Domain and Realm',
        'Error',
      );
      return;
    }

    let uploadedImage = null;

    if (imageResource && imageResource.type === 'File') {
      try {
        setIsUploadingLogo(true);
        uploadedImage = await uploadLogoResource(imageResource.content);
      } catch (error) {
        // To Do: Show additional error message if image was not uploaded
      } finally {
        setIsUploadingLogo(false);
      }
    }

    try {
      await createTenant({
        name: tenantName,
        url: tenantUrl,
        logo:
          uploadedImage !== null
            ? uploadedImage
            : imageResource?.type === 'URL'
            ? imageResource.content
            : undefined,
        description: '',
        status: 'ACTIVE',
        tenantType: 'ENTERPRISE',
        domain: tenantSsoDomain,
        realm: tenantSsoRealm,
      }).unwrap();
      showSuccessToast('The Tenant was successfully created');
      props.onClose();
    } catch (error) {
      //@ts-ignore
      const errorMessage = (error as FetchBaseQueryError).data?.message;
      showErrorToast(errorMessage || 'Could not create tenant.', 'Error');
    }
  };

  const updateTenantHandler = async () => {
    if (isUpdatingTenant) return;

    if (!tenant) return;

    if (tenantNameError || tenantUrlError) {
      showErrorToast('Please fill all required fields', 'Error');
      return;
    }

    let uploadedImage = null;

    if (imageResource && imageResource.type === 'File') {
      try {
        setIsUploadingLogo(true);
        uploadedImage = await uploadLogoResource(imageResource.content);
      } catch (error) {
        // To Do: Show additional error message if image was not uploaded
      } finally {
        setIsUploadingLogo(false);
      }
    }

    try {
      await updateTenant({
        ...tenant,
        status: tenant.status.code,
        tenantType: tenant.tenantType.code,
        description: '',
        name: tenantName,
        url: tenantUrl,
        logo:
          uploadedImage || (imageResource?.content as string) || tenant.logo,
        domain: tenantSsoDomain || undefined,
        realm: tenantSsoRealm || undefined,
      }).unwrap();
      showSuccessToast('Tenant was successfully updated');
      props.onClose();
    } catch (error) {
      //@ts-ignore
      const errorMessage = (error as FetchBaseQueryError).data?.message;
      showErrorToast(errorMessage || 'Could not update tenant.', 'Error');
    }
  };

  return (
    <Modal open={Boolean(props.open)}>
      <ClickAwayListener onClickAway={() => props.onClose()}>
        <FlexBlock
          flexDirection="column"
          alignItems="center"
          style={{ background: 'white' }}
        >
          <Header>
            <Typography.Text
              $colorName="onyx"
              $size={18}
              $lineHeight={32}
              $dmSans
              $bold
            >
              {isEditMode ? 'Edit' : 'Add'} Tenant
            </Typography.Text>
            <FlexBlock style={{ alignSelf: 'flex-start', paddingTop: '16px' }}>
              <CloseIcon size={16} onClick={() => props.onClose()} />
            </FlexBlock>
          </Header>
          <FlexBlock flexDirection="column" minWidth="100%" padding="16px">
            <Typography.Text
              $colorName="onyx"
              $dmSans
              $bold
              $size={16}
              $lineHeight={24}
            >
              Upload Tenant Logo
            </Typography.Text>
            <Typography.Text $colorName="steel" $size={14} $lineHeight={20}>
              Max. 512MB. Supported formats: .png, .jpg or .jpeg
            </Typography.Text>
            <Section>
              <ImageDropzone
                image={imageResource}
                handleImageLoad={handleImageFileLoad}
              />
              <Typography.Text $size={14} $lineHeight={20} $colorName="steel">
                or
              </Typography.Text>
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                hidden
                ref={fileInputRef}
                onChange={handleFileSelect}
              />
              <Button
                type="button"
                variant="secondary"
                compact
                onClick={() => {
                  fileInputRef?.current?.click();
                }}
              >
                Browse file
              </Button>
              <Typography.Text $size={14} $lineHeight={20} $colorName="steel">
                or
              </Typography.Text>
              <AddLogoAnchor
                onClick={handleProjectLogoFromUrl}
                $color={theme.colors.orbit}
                $disabled={tenantUrl === '' || imageResource?.type === 'URL'}
              >
                {imageResource?.type === 'URL'
                  ? "Keep using URL's logo"
                  : "Use URL's Logo"}
              </AddLogoAnchor>
            </Section>
            <Section flexDirection="column" noBottomBorder>
              <FlexBlock
                flexDirection="row"
                justifyContent="left"
                minWidth="100%"
              >
                <Typography.Text
                  $colorName="onyx"
                  $dmSans
                  $bold
                  $size={16}
                  $lineHeight={24}
                >
                  Basic Information
                </Typography.Text>
              </FlexBlock>
              <FlexBlock
                flexDirection="row"
                justifyContent="space-around"
                columnGap="10px"
                minWidth="100%"
              >
                <FlexBlock flexDirection="column" maxWidth="350px">
                  <Typography.Text
                    $colorName="nero"
                    $size={14}
                    $lineHeight={20}
                    $padding="16px 0 8px 0"
                  >
                    Tenant Name
                  </Typography.Text>
                  <TextInput
                    placeholder="Enter tenant's name"
                    onChange={e => setTenantName(e.target.value)}
                    value={tenantName}
                    onBlur={validateTenantName}
                    onClick={() => setTenantNameError(null)}
                    notValid={!!tenantNameError}
                  />
                  {tenantNameError && (
                    <Typography.Text
                      $colorName="volatileRed"
                      $size={14}
                      $lineHeight={20}
                      $padding="6px 0 8px 0"
                    >
                      {tenantNameError}
                    </Typography.Text>
                  )}
                </FlexBlock>
                <FlexBlock flexDirection="column" maxWidth="350px">
                  <Typography.Text
                    $colorName="nero"
                    $size={14}
                    $lineHeight={20}
                    $padding="16px 0 8px 0"
                  >
                    Company URL
                  </Typography.Text>
                  <TextInput
                    placeholder="Enter company URL"
                    onChange={e => setTenantUrl(e.target.value)}
                    value={tenantUrl}
                    onBlur={validateTenantUrl}
                    onClick={() => setTenantUrlError(null)}
                    notValid={!!tenantUrlError}
                  />
                  {tenantUrlError && (
                    <Typography.Text
                      $colorName="volatileRed"
                      $size={14}
                      $lineHeight={20}
                      $padding="6px 0 8px 0"
                    >
                      {tenantUrlError}
                    </Typography.Text>
                  )}
                </FlexBlock>
              </FlexBlock>
              <FlexBlock
                flexDirection="row"
                justifyContent="space-around"
                columnGap="10px"
                minWidth="100%"
              >
                <FlexBlock flexDirection="column" maxWidth="350px">
                  <Typography.Text
                    $colorName="nero"
                    $size={14}
                    $lineHeight={20}
                    $padding="16px 0 8px 0"
                  >
                    Domain for SSO (if applicable)
                  </Typography.Text>
                  <TextInput
                    placeholder="pearson.com, allisonpr.com, etc."
                    onChange={e => setTenantSsoDomain(e.target.value)}
                    value={tenantSsoDomain}
                    onBlur={validateTenantSsoDomain}
                    onClick={() => setTenantSsoDomainError(null)}
                    notValid={!!tenantSsoDomainError}
                    disabled={isEditingSsoTenant}
                  />
                  {tenantSsoDomainError && (
                    <Typography.Text
                      $colorName="volatileRed"
                      $size={14}
                      $lineHeight={20}
                      $padding="6px 0 8px 0"
                    >
                      {tenantSsoDomainError}
                    </Typography.Text>
                  )}
                </FlexBlock>
                <FlexBlock flexDirection="column" maxWidth="350px">
                  <Typography.Text
                    $colorName="nero"
                    $size={14}
                    $lineHeight={20}
                    $padding="16px 0 8px 0"
                  >
                    Realm (required for SSO domain)
                  </Typography.Text>
                  <TextInput
                    placeholder="Enter SSO Realm"
                    onChange={e => setTenantSsoRealm(e.target.value)}
                    value={tenantSsoRealm}
                    onBlur={validateTenantSsoRealm}
                    onClick={() => setTenantSsoRealmError(null)}
                    notValid={!!tenantSsoRealmError}
                    disabled={isEditingSsoTenant}
                  />
                  {tenantSsoRealmError && (
                    <Typography.Text
                      $colorName="volatileRed"
                      $size={14}
                      $lineHeight={20}
                      $padding="6px 0 8px 0"
                    >
                      {tenantSsoRealmError}
                    </Typography.Text>
                  )}
                </FlexBlock>
              </FlexBlock>
            </Section>
          </FlexBlock>

          <SectionFlexEnd>
            <Button
              variant="secondary"
              compact
              disabled={!canResetFields}
              onClick={handleReset}
            >
              Reset
            </Button>
            <Gap size={10} />
            <Button
              disabled={cannnotSubmit}
              compact
              onClick={isEditMode ? updateTenantHandler : createTenantHandler}
              variant="primary"
              isLoading={
                isCreatingTeanant || isUpdatingTenant || isUploadingLogo
              }
            >
              {isEditMode ? 'Save' : 'Add'} Tenant
            </Button>
          </SectionFlexEnd>
        </FlexBlock>
      </ClickAwayListener>
    </Modal>
  );
};

export default TenantModal;
