import * as S from './CheckboxPill.style';
import { FC } from 'react';
import Typography from '../../Typography';
import CheckIcon from '../../../assets/icons/CheckIcon';

interface IProps {
  checked: boolean;
  onChange: (changedValue: boolean) => void;
  text: string;
  disabled?: boolean;
}

export const CheckboxPill: FC<IProps> = props => {
  const handleClick = () => {
    if (props.disabled) return;
    props.onChange(!props.checked);
  };

  return (
    <S.PillWrapper
      checked={props.checked}
      onClick={handleClick}
      disabled={props.disabled}
    >
      <S.Checkmark>
        <CheckIcon color="green" />
      </S.Checkmark>
      <Typography.Text inheritColor $size={14} $lineHeight={16}>
        {props.text}
      </Typography.Text>
    </S.PillWrapper>
  );
};
