import styled, { css } from 'styled-components/macro';

export const FooterContainer = styled.div<{
  $spaceAround?: boolean;
  $height?: number;
}>(
  ({ $spaceAround = false, $height }) => css`
    width: 100%;
    height: ${$height ? `${$height}px` : '80px'};
    position: fixed;
    bottom: 0;
    background: white;
    box-shadow: 0 -2px 12px 0 rgba(12, 12, 13, 0.05);
    display: flex;
    flex-flow: row;
    justify-content: ${$spaceAround ? 'space-between' : 'flex-end'};
    ${!$spaceAround &&
    css`
      padding-right: 40px;
    `}
    align-items: center;
  `,
);

export const Content = styled.div<{ $spaceAround?: boolean }>(
  ({ $spaceAround = false }) => css`
    display: flex;
    flex-flow: row;
    gap: 12px;
    ${$spaceAround
      ? css`
          align-items: center;
          width: 100%;
          justify-content: space-between;
        `
      : ''}
  `,
);
