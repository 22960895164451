import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import ArrowUpIcon from '../../../../../../../assets/icons/ArrowUpIcon';

interface IProps {
  active: boolean;
  asc: boolean;
}

const SortArrowWrapper = styled.div<IProps>(
  props => css`
    display: inline-block;
    margin: 0;

    svg {
      transform: rotate(${props.active && props.asc ? '180deg' : '0deg'});

      path {
        fill: ${props.active ? props.theme.colors.orbit : '#737377'};
      }
    }
  `,
);

const SortArrow: FC<IProps> = props => {
  return (
    <SortArrowWrapper {...props}>
      <ArrowUpIcon />
    </SortArrowWrapper>
  );
};

export default SortArrow;
