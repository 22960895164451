import { ApmBase, init as initApm } from '@elastic/apm-rum';
import { version } from '../../../package.json';
import { IS_ENV_LOCAL } from 'utils/constants';

const envName = process.env.REACT_APP_ENVIRONMENT || 'unknown';

let apmInstance: undefined | ApmBase;

if ((envName === 'production' || envName === 'staging') && !IS_ENV_LOCAL) {
  apmInstance = initApm({
    serviceName: `Prophet-UI`,
    serviceVersion: version || 'unknown',
    serverUrl: 'https://rey3ihxwwj.execute-api.us-east-1.amazonaws.com/',
    environment: envName,
  });
}

class APMLogger {
  private apmInstance: undefined | ApmBase;

  constructor(apmInstance: undefined | ApmBase) {
    this.apmInstance = apmInstance;
  }

  logError(error: string | Error) {
    if (!error || (typeof error !== 'string' && !(error instanceof Error)))
      return;

    if (this.apmInstance) {
      this.apmInstance.captureError(error);
    } else {
      if (envName !== 'production') {
        const errorMessage = error instanceof Error ? error.message : error;
        console.error(
          '%cAPM Logger: ',
          'background: black; color: red',
          errorMessage,
        );
      }
    }
  }
}

const logger = new APMLogger(apmInstance);

export { logger };
