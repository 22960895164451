import { request } from 'utils/request';
import { IBrandMediaMember, IProjectMin } from 'app/api.types';
import { ICreator } from 'app/containers/Global/slice/types';

export const getJournalistsIdsFromLegacyMediaMembers = (
  ids: { authorId: string; mediaOutletId?: string }[],
) => {
  if (!ids || !ids.length) return undefined;

  return ids
    .map(mediaItem => Number(mediaItem.authorId))
    .filter(Number.isInteger);
};

export interface IImportTargetMediaResponse {
  status: number;
  data: {
    id: number;
    name: string;
    type: string;
    totalMembers: number;
    totalMembersByCountries: {
      USA: number;
    };
    enabled: any;
    brand: IProjectMin;
    members: IBrandMediaMember;
    creator: ICreator;
    createdAt: string;
    updatedAt: string;
  };
  error: any;
  totalData: number;
  succeedData: number;
  failedAuthorNames: {
    [key: string]: {
      author: string;
      outlet: string;
      reason: string;
      domain: string;
    };
  };
}

export interface IImportFailedReasons {
  reason: string;
  authors: {
    name: string;
    outlet: string;
  }[];
}

export const importTargetMediaList: (
  file: ArrayBuffer | string,
  fileName: string,
  tenantId?: number,
) => Promise<IImportTargetMediaResponse> = async (file, fileName, tenantId) => {
  const formData = new FormData();
  formData.append('file', new Blob([file]), fileName);

  const targetMedia: IImportTargetMediaResponse = await request({
    method: 'POST',
    url: `media-list/custom/import`,
    params: {
      tenantId: tenantId ? tenantId : undefined,
    },
    data: formData,
  });

  return targetMedia;
};
