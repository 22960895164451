import { IBioContentMin, IBioSuggestionContentPayload } from 'app/api.types';
import CopyIcon from 'app/assets/icons/CopyIcon';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import { FC } from 'react';
import { showSuccessToast, showErrorToast } from 'utils/toast';
import * as S from '../../../../Pitch/pages/CreateFirstPitch/CreateFirstPitch.styles';
import { GridContainer } from '../BioPage.styles';
import RegenerateIcon from 'app/assets/icons/RegenerateIcon';
import { theme } from 'styles/theme';

interface IContentSectionProps {
  content: IBioContentMin;
  handleContentChange: (type: 'long' | 'short', content: string) => void;
  regenerate: (
    type: 'long' | 'short' | 'all',
    payload?: IBioSuggestionContentPayload,
  ) => void;
}

const BioPageContent: FC<IContentSectionProps> = ({
  content,
  handleContentChange,
  regenerate,
}) => {
  const onTextCopy = async (contentType: 'short' | 'long') => {
    try {
      await window.navigator.clipboard.writeText(
        contentType === 'long' ? content.longBio : content.shortBio,
      );
      showSuccessToast('Text copied to clipboard!');
    } catch (e) {
      showErrorToast(
        'Your browser does not support this function.',
        'Failed to copy text.',
      );
    }
  };

  return (
    <GridContainer>
      <S.GridContent $borderOrbit={false}>
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <S.ContentPill>
            <Typography.Text $colorName="orbit" $size={14} $lineHeight={16}>
              Short Bio
            </Typography.Text>
          </S.ContentPill>
          <FlexBlock flexDirection="row">
            <Button compact variant="gray" onClick={() => onTextCopy('short')}>
              <CopyIcon />
              <Gap size={8} />
              Copy
            </Button>
            <Gap size={8} />
            <Button
              compact
              variant="gray"
              onClick={() => regenerate('short')}
              tooltipText="Regenerate content"
            >
              <RegenerateIcon fill={theme.colors.nero} />
            </Button>
          </FlexBlock>
        </FlexBlock>

        <Gap size={16} />
        <FlexBlock flexDirection="column" justifyContent="left" padding="16px">
          <S.InputBox
            $noBorder
            placeholder="Short bio..."
            value={content.shortBio}
            onChange={e => handleContentChange('short', e.target.value)}
            textStyle="body"
            minRows={1}
            disabled={false}
          />
        </FlexBlock>
      </S.GridContent>
      <S.GridContent $borderOrbit={false}>
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <S.ContentPill>
            <Typography.Text $colorName="orbit" $size={14} $lineHeight={16}>
              Long Bio
            </Typography.Text>
          </S.ContentPill>
          <FlexBlock flexDirection="row">
            <Button compact variant="gray" onClick={() => onTextCopy('long')}>
              <CopyIcon />
              <Gap size={8} />
              Copy
            </Button>
            <Gap size={8} />
            <Button
              compact
              variant="gray"
              onClick={() => regenerate('long')}
              tooltipText="Regenerate content"
            >
              <RegenerateIcon fill={theme.colors.nero} />
            </Button>
          </FlexBlock>
        </FlexBlock>

        <Gap size={16} />
        <FlexBlock flexDirection="column" justifyContent="left" padding="16px">
          <S.InputBox
            $noBorder
            placeholder="Long bio..."
            value={content.longBio}
            onChange={e => handleContentChange('long', e.target.value)}
            textStyle="body"
            minRows={1}
            disabled={false}
          />
        </FlexBlock>
      </S.GridContent>
    </GridContainer>
  );
};

export default BioPageContent;
