import React from 'react';

function WandIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="49.968%"
          x2="49.968%"
          y1="-2.316%"
          y2="100.704%"
        >
          <stop offset="0%" stopColor="#FFF"></stop>
          <stop offset="100%" stopColor="#ADD2FF"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-308 -100)">
          <g transform="translate(260 72)">
            <g transform="translate(32 12)">
              <g transform="translate(16 16)">
                <path
                  fill="#1D76DE"
                  d="M13.395 3.03c-1.97-1.06-1.818-.909-3.03-3.03-1.06 2.121-1.06 1.97-3.03 3.03 2.121 1.212 1.97 1.06 3.03 3.03 1.06-1.97.91-1.818 3.03-3.03zM20.97 18.18c1.06-2.12 1.06-1.97 3.03-3.03-2.12-1.06-1.97-1.06-3.03-3.03-1.06 2.121-1.06 1.97-3.03 3.03 2.121 1.06 1.97 1.06 3.03 3.03z"
                ></path>
                <path
                  fill="url(#linearGradient-1)"
                  d="M18.743.41a.579.579 0 01.818 0l4.03 4.029a.579.579 0 010 .818L5.018 23.831l-.08.066a.579.579 0 01-.739-.066L.17 19.8a.579.579 0 010-.819zm.586 2.205a.579.579 0 00-.738.067L15.32 5.954a.579.579 0 000 .818l1.909 1.909.08.067a.579.579 0 00.738-.067l3.272-3.272a.579.579 0 000-.819L19.41 2.682z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WandIcon;
