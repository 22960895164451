import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { ArticlesTab } from './ArticlesSection';

interface IArticleTabsProps {
  activeTab: ArticlesTab;
  onClick: (tab: ArticlesTab) => void;
  hasRelevantArticles?: boolean;
}

export const TabItem = styled.div<{ isActive: boolean }>`
  ${({ isActive, theme }) => css`
    transition: background-color 0.3s, color 0.3s;
    padding: 10px;
    cursor: pointer;
    font-family: ${theme.fonts.inter};
    font-weight: bold;
    font-size: 14px;

    cursor: pointer;

    ${isActive
      ? css`
          background-color: #e7f1fc;
          color: #287de0;
          border-radius: 0.7rem;
        `
      : css`
          color: black;
          background-color: transparent;
        `};
  `}
`;

const ArticleTabs: FC<IArticleTabsProps> = ({
  activeTab,
  onClick,
  hasRelevantArticles = false,
}) => {
  const handleTabChange = (tab: ArticlesTab) => {
    if (activeTab !== tab) {
      onClick(tab);
    }
  };

  return (
    <FlexBlock
      flexDirection="row"
      justifyContent="left"
      alignItems="center"
      columnGap="5px"
    >
      {hasRelevantArticles && (
        <TabItem
          isActive={activeTab === ArticlesTab.RELEVANT}
          onClick={e => {
            e.stopPropagation();
            handleTabChange(ArticlesTab.RELEVANT);
          }}
        >
          Most Relevant
        </TabItem>
      )}
      <TabItem
        isActive={activeTab === ArticlesTab.RECENT}
        onClick={e => {
          e.stopPropagation();
          handleTabChange(ArticlesTab.RECENT);
        }}
      >
        Most Recent
      </TabItem>
    </FlexBlock>
  );
};

export default ArticleTabs;
