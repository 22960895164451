import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div(
  ({ theme }) => css`
    background-color: transparent;
    padding: 16.5px 16.5px 24px;
    margin-bottom: 16px;
    border-radius: 10px;
    border: 1px solid transparent;

    transition: 0.125s all ease-in-out;

    &:hover {
      box-shadow: 0 0 4px rgba(12, 12, 13, 0.05);
      background-color: #fff;
      border: 1px solid ${theme.colors.smoke};

      ${ButtonRow} {
        opacity: 1;
      }
    }
  `,
);

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  opacity: 0;
  transition: 0.125s all ease-in-out;
`;

export const PostContent = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fonts.inter};
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.nero};
    margin-top: 24px;

    .hash {
      display: inline;
      color: ${theme.colors.orbit};
    }
  `,
);

export const IconWrapper = styled.div<{ color?: string }>(
  ({ color = 'rgba(42, 169, 224, 0.1)' }) => css`
    height: 40px;
    width: 40px;
    background-color: ${color};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  `,
);
