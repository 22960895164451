import { FC } from 'react';
import styled, { css } from 'styled-components';
import Typography from '../Typography';

interface IProps {
  leftContent: string;
  rightContent: string;
  onClick: () => void;
  compact?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
`;

const LeftContent = styled.div<{
  compact?: boolean;
}>(
  ({ compact = false }) => css`
    background-color: rgba(0, 211, 119, 0.2);
    border-radius: 12px 0 0 12px;
    ${compact
      ? css`
          padding: 8px 12px;
        `
      : css`
          height: 56px;
          padding: 16px 12px;
        `}
  `,
);

const RightContent = styled.div<{
  compact?: boolean;
}>(
  ({ compact = false }) => css`
    background-color: #00d377;
    border-radius: 0 12px 12px 0;
    ${compact
      ? css`
          padding: 8px 12px;
        `
      : css`
          height: 56px;
          padding: 16px 12px;
        `}
  `,
);

const DoublePill: FC<IProps> = ({
  leftContent,
  rightContent,
  onClick,
  compact = false,
}) => {
  return (
    <Container onClick={onClick}>
      <LeftContent compact={compact}>
        <Typography.Text
          $colorName="onyx"
          $size={compact ? 14 : 16}
          $lineHeight={24}
        >
          {leftContent}
        </Typography.Text>
      </LeftContent>
      <RightContent compact={compact}>
        <Typography.Text
          $dmSans
          $bold
          $size={compact ? 14 : 16}
          $lineHeight={compact ? 16 : 24}
          $colorName="white"
        >
          {rightContent}
        </Typography.Text>
      </RightContent>
    </Container>
  );
};

export default DoublePill;
