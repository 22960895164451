import Typography from 'app/components/Typography';
import { MAX_ITEMS_TO_MULTI_GENERATE } from 'app/containers/Pitch/pages/PitchPage/PitchPage.constants';
import { FC } from 'react';

interface IProps {
  selectedItemsCount: number;
  currentPublicationsCount: number;
}

const ActiveSelectionText: FC<IProps> = ({
  selectedItemsCount,
  currentPublicationsCount,
}) => {
  if (selectedItemsCount === MAX_ITEMS_TO_MULTI_GENERATE)
    return (
      <Typography.Text $colorName="onyx" $lineHeight={16} $size={14}>
        Head’s up, you’ve selected the maximum number of Journalists
      </Typography.Text>
    );

  return (
    <Typography.Text $colorName="onyx" $lineHeight={16} $size={14}>
      You’ve selected{' '}
      <Typography.Text
        $bold
      >{`${selectedItemsCount} / ${MAX_ITEMS_TO_MULTI_GENERATE}`}</Typography.Text>{' '}
      across {currentPublicationsCount} Outlet
      {`${selectedItemsCount !== 1 ? 's' : ''}`}
    </Typography.Text>
  );
};

export default ActiveSelectionText;
