import * as S from './ContextMenu.styles';
import Button, { IButtonProps } from '../Button/Button';
import React, {
  FC,
  ReactNode,
  useState,
  MouseEvent,
  useMemo,
  useImperativeHandle,
  Ref,
  useRef,
  useCallback,
} from 'react';
import usePortal from '../../../../utils/hooks/usePortal';

interface IContextMenuProps {
  buttonProps?: IButtonProps;
  buttonText: ReactNode;
  preventDefaultMenuButtonAction?: boolean;
  children?: ReactNode;
  renderButtonTrigger?: (arg1: IButtonTriggerProps) => ReactNode;
  menuOptions?: IMenuProps;
  usePortal?: boolean;
  ref?: Ref<{ menuOpen?: boolean }>;
  // keepMenuInDOM?: boolean; // TODO::FEATURE: if enabled, keeps menu rendered in DOM (still hides it if not open). This will avoid avatar flickering
}

export type OptionHoverAnimationType = 'default' | 'blue-text';

interface IContextMenuOptionProps {
  icon?: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
  hoverAnimation?: OptionHoverAnimationType;
  $padding?: string;
  $justifyContent?: string;
}

export interface IButtonTriggerProps {
  open?: boolean;
  onClick: (event: MouseEvent) => void;
}

export interface IMenuProps {
  minWidth?: string;
  maxWidth?: string;
  align?: 'left' | 'right';
  distanceFromTrigger?: number;
  portalEnabled?: boolean;
  positionOffsetX?: number;
  offsetX?: number; // only for portal
  offsetY?: number; // only for portal
  width?: number; // only for portal
  height?: number; // only for portal
  maxHeight?: string;
  visibleOverFlow?: boolean;
}

const ContextMenu: FC<IContextMenuProps> = props => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggleMenu = (event: MouseEvent) => {
    if (props.preventDefaultMenuButtonAction) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpen(!open);
  };

  const renderMenu: (node: ReactNode) => ReactNode = node => {
    if (props.usePortal) return portal.renderInPortal(node);
    return node;
  };

  const portal = usePortal('ctx-menu-actions');

  const menuOptions = useMemo(() => {
    return props.menuOptions || {};
  }, [props.menuOptions]);

  useImperativeHandle(props.ref, () => ({
    menuOpen: open,
  }));

  const getMenuPortalProps = useCallback(() => {
    const rect = wrapperRef.current?.getBoundingClientRect();
    return {
      portalEnabled: props.usePortal,
      offsetX: rect?.x || 0,
      offsetY: rect?.y || 0,
      width: wrapperRef.current?.offsetWidth || 200,
      height: rect?.height || 0,
    };
  }, [props.usePortal]);

  return (
    <S.Wrapper ref={wrapperRef}>
      {props.renderButtonTrigger ? (
        props.renderButtonTrigger({ onClick: toggleMenu, open })
      ) : (
        <Button
          {...(props.buttonProps || {})}
          onClick={toggleMenu}
          $active={open}
        >
          {props.buttonText}
        </Button>
      )}
      {open && <S.Backdrop onClick={toggleMenu} />}
      {open && (
        // <ClickAwayListener onClickAway={handleClickAway}>
        <>
          {renderMenu(
            <S.Menu
              onClick={toggleMenu}
              {...menuOptions}
              {...getMenuPortalProps()}
            >
              {props.children}
            </S.Menu>,
          )}
        </>
        // </ClickAwayListener>
      )}
    </S.Wrapper>
  );
};

export const ContextMenuOption: FC<IContextMenuOptionProps> = ({
  hoverAnimation = 'default',
  ...props
}) => {
  return (
    <S.OptionWrapper
      onClick={props.onClick}
      hoverAnimation={hoverAnimation}
      padding={props.$padding}
      justifyContent={props.$justifyContent}
    >
      {props.icon || null}
      <S.OptionText>{props.children}</S.OptionText>
    </S.OptionWrapper>
  );
};

export default ContextMenu;
