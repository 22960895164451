import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';
import { FC, Fragment } from 'react';
import {
  usePressRelease,
  usePressReleaseDispatch,
} from '../../context/PressReleaseContext';
import PageLoader from 'app/components/UI/PageLoader';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';
import { usePressReleaseContentHistory } from '../../hooks/usePressReleaseContentHistory.hook';
import Button from 'app/components/UI/Button/Button';
import RemoveIcon from 'app/assets/icons/RemoveIcon';
import { PitchVersionPill } from 'app/containers/Projects/pages/PItchList/PitchList.styles';
import { formatDate } from 'utils/helpers';
import { Divider, VerticalDivider } from 'app/components/UI/Divider';
import { PressReleaseStateActions } from '../../CreateOrEditPressRelease.constants';

const PressReleaseContentVersionsDrawer: FC = () => {
  const { versionsOpen } = usePressRelease();
  const { contentVersions, handleContentVersionSelect } =
    usePressReleaseContentHistory();
  const dispatch = usePressReleaseDispatch();

  const handleDrawerClose = () => {
    dispatch({
      type: PressReleaseStateActions.TOGGLE_VERSIONS,
    });
  };

  const isFetching = false;

  return (
    <SideDrawer open={versionsOpen}>
      {isFetching ? (
        <PageLoader />
      ) : (
        <>
          <FlexBlock padding="24px">
            <FlexBlock flexDirection="column" rowGap="4px" flex={1}>
              <Typography.Text $dmSans $bold $size={20}>
                Content Versions
              </Typography.Text>
              <Typography.Text $colorName="steel">
                See content versions
              </Typography.Text>
            </FlexBlock>
            <FlexBlock>
              <Button
                variant="icon-button-border"
                hoverMode="black"
                onClick={handleDrawerClose}
              >
                <RemoveIcon />
              </Button>
            </FlexBlock>
          </FlexBlock>

          {contentVersions?.map((contentVersion, index, arr) => {
            const isActive = false;

            return (
              <Fragment key={contentVersion.id}>
                <FlexBlock padding="24px" columnGap="16px" alignItems="center">
                  <PitchVersionPill status={'ACTIVE'} borderRadius="6px">
                    V{contentVersion.version}
                  </PitchVersionPill>
                  <FlexBlock flexDirection="column" flex={1}>
                    <Typography.Text>
                      By: {contentVersion.creator?.firstName || ''}{' '}
                      {contentVersion.creator?.lastName || ''}{' '}
                      {isActive ? (
                        <Typography.Text $colorName="kermit">
                          (Active)
                        </Typography.Text>
                      ) : null}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDate(contentVersion.createdAt)}
                    </Typography.Text>
                  </FlexBlock>
                  <FlexBlock columnGap="8px" alignItems="stretch">
                    <Button
                      variant="anchor"
                      compact
                      onClick={() =>
                        handleContentVersionSelect(contentVersion.id)
                      }
                    >
                      Review
                    </Button>
                    <VerticalDivider />
                  </FlexBlock>
                </FlexBlock>
                {Boolean(index + 1 !== arr.length) && <Divider />}
              </Fragment>
            );
          })}
        </>
      )}
    </SideDrawer>
  );
};

export default PressReleaseContentVersionsDrawer;
