import { FC } from 'react';
import * as StyledForm from '../Form';
import Typography from '../../Typography';
import { IFormRowProps } from '../Form';
import { TextColorName } from '../../Typography/Text';

interface IProps {
  label: string;
  rowProps?: IFormRowProps;
  labelTextColor?: TextColorName;
}

const FormLabelRow: FC<IProps> = ({
  label,
  children,
  rowProps = {},
  labelTextColor = 'steel',
}) => {
  return (
    <StyledForm.Row {...rowProps}>
      <StyledForm.Section flex={1} padding="16px 0 0 0">
        <Typography.Text
          $margin="0 8px"
          $colorName={labelTextColor}
          $lineHeight={16}
        >
          {label}
        </Typography.Text>
      </StyledForm.Section>
      <StyledForm.Section flex={3} padding="16px 0 0 0">
        {children}
      </StyledForm.Section>
    </StyledForm.Row>
  );
};

export default FormLabelRow;
