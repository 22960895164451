import { useGetBioContentVersionQuery, useGetBioQuery } from 'app/api';
import {
  IBioSuggestionContentPayload,
  IBioSuggestionContentResponse,
} from 'app/api.types';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getTitleFromLinkedInURL } from '../BioPage.helpers';

interface IBioDraft {
  generatedResponse: IBioSuggestionContentResponse;
  generationPayload: IBioSuggestionContentPayload;
}

export const useBioData = () => {
  const params: {
    tenantId: string;
    projectId: string;
    bioId: string;
    versionId: string;
  } = useParams();

  const [tenantId, projectId, bioId, versionId] = useMemo(
    () => [
      Number(params.tenantId),
      Number(params.projectId),
      Number(params.bioId),
      Number(params.versionId),
    ],
    [params],
  );

  const location = useLocation<IBioDraft | undefined>();
  const draft = location.state;
  const isDraft = Boolean(draft);

  const {
    data: bioData,
    isError: isBioError,
    isLoading: isBioLoading,
  } = useGetBioQuery({ bioId }, { skip: !bioId });

  const bioContentId = useMemo(() => {
    if (!bioData) return undefined;
    if (bioData.bioContents) {
      return bioData.bioContents.find(content => content.version === versionId)
        ?.id;
    }
  }, [bioData, versionId]);

  const bio = useMemo(() => {
    if (bioData) return bioData;
    return undefined;
  }, [bioData]);

  const {
    data: contentData,
    isError: isContentError,
    isLoading: isContentLoading,
  } = useGetBioContentVersionQuery(
    { bioContentId },
    { skip: !bioData || !bioContentId },
  );

  const bioTitle = useMemo(() => {
    if (draft) {
      if (draft.generatedResponse && draft.generatedResponse.title) {
        return `${draft.generatedResponse.title} Bio`;
      }

      if (draft.generationPayload && draft.generationPayload.linkedInURL) {
        return getTitleFromLinkedInURL(draft.generationPayload.linkedInURL);
      }
    }

    return undefined;
  }, [draft]);

  const payload = useMemo(() => {
    if (draft && draft.generationPayload) {
      const emphasizeRole =
        draft.generatedResponse.emphasizeRole !== undefined
          ? draft.generatedResponse.emphasizeRole
          : draft.generationPayload.emphasizeRole;
      const emphasizeEducation =
        draft.generatedResponse.emphasizeEducation !== undefined
          ? draft.generatedResponse.emphasizeEducation
          : draft.generationPayload.emphasizeEducation;
      const emphasizeInterests =
        draft.generatedResponse.emphasizeInterests !== undefined
          ? draft.generatedResponse.emphasizeInterests
          : draft.generationPayload.emphasizeInterests;

      const payloadFromDraft = {
        eventBrief:
          draft.generatedResponse.eventBrief ||
          draft.generationPayload.eventBrief,
        linkedInURL: draft.generationPayload.linkedInURL,
        emphasizeEducation,
        emphasizeInterests,
        emphasizeRole,
      };

      return payloadFromDraft;
    }

    if (contentData && bioData) {
      return {
        eventBrief: contentData.eventBrief,
        linkedInURL: bioData.linkedInUrl,
        emphasizeEducation: contentData.emphasizeEducation,
        emphasizeInterests: contentData.emphasizeInterests,
        emphasizeRole: contentData.emphasizeRole,
      };
    }

    return undefined;
  }, [draft, contentData, bioData]);

  const content = useMemo(() => {
    if (draft) {
      return {
        shortBio: draft?.generatedResponse.shortBio,
        longBio: draft?.generatedResponse.longBio,
      };
    }

    if (contentData) {
      return {
        shortBio: contentData.shortBio,
        longBio: contentData.longBio,
      };
    }

    return undefined;
  }, [draft, contentData]);

  return {
    bioId,
    tenantId,
    bioContentId,
    versionId,
    projectId,
    isDraft,
    bioTitle,
    payload,
    content,
    bio,
    isContentLoading,
    isBioLoading,
    isError: isBioError || isContentError,
  };
};
