import { getWordCount } from '../../../../../utils/helpers';
import {
  GENERATE_CONTENT_MAX_WORD_COUNT,
  GENERATE_CONTENT_MIN_WORD_COUNT,
} from './CreateOrEditPitch.constants';
import { showErrorToast } from '../../../../../utils/toast';
import { IPitchContent, IPitchState } from './CreateOrEditPitch.types';
import { PITCH_BODY_MIN_WORD_COUNT } from '../../../../../utils/constants';

export const joinTextLines = (
  lines: string[],
  asParagraph?: boolean,
): string => {
  const joiner = asParagraph
    ? `
    
`
    : `
`;

  return lines.join(joiner);
};

export const validateContentForGenerate = (content: IPitchContent | null) => {
  if (content) {
    const bodyWordCount = getWordCount(content.body);
    const titleWordCount = getWordCount(content.headline);

    if (titleWordCount > GENERATE_CONTENT_MAX_WORD_COUNT) {
      showErrorToast(
        `Maximum word count is ${GENERATE_CONTENT_MAX_WORD_COUNT}. Your headline has ${titleWordCount}`,
        'Cannot generate content',
      );
      return false;
    }

    if (bodyWordCount < GENERATE_CONTENT_MIN_WORD_COUNT) {
      showErrorToast(
        `Body must contain at least ${GENERATE_CONTENT_MIN_WORD_COUNT} words`,
        'Cannot generate content',
      );
      return false;
    }
    if (bodyWordCount > GENERATE_CONTENT_MAX_WORD_COUNT) {
      showErrorToast(
        `Maximum word count is ${GENERATE_CONTENT_MAX_WORD_COUNT}. Your content has ${bodyWordCount}`,
        'Cannot generate content',
      );
      return false;
    }
    return true;
  }

  showErrorToast('No content found');

  return false;
};

export const isPitchContentValid = (
  pitchContent: IPitchContent | null,
): boolean => {
  const errors: string[] = [];

  if (!pitchContent) {
    showErrorToast('Please enter or select the content', 'Content not found');
    return false;
  }

  const wordCount = getWordCount(pitchContent.body);

  if (wordCount < PITCH_BODY_MIN_WORD_COUNT) {
    errors.push('Pitch content too short. Please type at least 30 words');
  }

  if (!pitchContent.headline) {
    errors.push('Please add headline');
  }
  if (errors.length) {
    showErrorToast(
      `
        <ul style="list-style: none;">
            ${errors.map(error => `<li>${error}</li>`).join('')}
        </ul>
      `,
      'Cannot run a prediction',
      {
        autoClose: 1e4,
      },
    );
    return false;
  }
  return true;
};

export const isPitchFormValid = (
  pitchState: IPitchState,
  pitchContent: IPitchContent | null,
): boolean => {
  const errors: string[] = [];

  // const pitchContent = getSelectedPitchContent();

  if (!pitchContent) {
    showErrorToast('Content not found', 'Configuration error');
    return false;
  }

  const wordCount = getWordCount(pitchContent.body);

  if (wordCount < PITCH_BODY_MIN_WORD_COUNT) {
    errors.push('Pitch content too short. Please type at least 30 words');
  }

  if (!pitchContent.headline) {
    errors.push('Please add headline');
  }
  if (errors.length) {
    showErrorToast(
      `
        <ul style="list-style: none;">
            ${errors.map(error => `<li>${error}</li>`).join('')}
        </ul>
      `,
      'Pitch cannot be created',
      {
        autoClose: 1e4,
      },
    );
    return false;
  }
  return true;
};
