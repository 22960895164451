import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
`;

export const PickerContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    padding: 6px;
    background: #fff;
    border: 1px solid ${theme.colors.smoke};
    border-radius: 6px;
    top: calc(100% + 6px);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.07);
    z-index: 10;
  `,
);
