import { useUpdateAuthorSocialMutation } from 'app/api';
import { AuthorSocialType, CreatorType, IAuthorSocial } from 'app/api.types';
import CheckmarkIcon from 'app/assets/icons/CheckmarkIcon';

import Typography from 'app/components/Typography';
import { Anchor } from 'app/components/UI/Anchor';
import Button from 'app/components/UI/Button/Button';
import CheckBox from 'app/components/UI/CheckBox/CheckBox';
import { CloseIcon } from 'app/components/UI/CloseIcon';
import FlexBlock from 'app/components/UI/FlexBlock';
import { InfoTipAdvanced } from 'app/components/UI/InfoTip/InfoTip';

import {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { SOCIAL_FIELDS_CONFIG } from './Socials.config';
import { theme } from 'styles/theme';
import PencilIcon from 'app/assets/icons/PencilIcon';
import { generateSocialAnchorHref } from './Socials.helpers';

import {
  SocialFieldInput,
  SocialFieldsGroupWrapper,
  SocialIconWrapper,
} from './CreatorSocials.styles';
import NotVisibleIcon from 'app/assets/icons/NotVisibleIcon';

interface IProps {
  authorId: string;
  isEditMode: boolean;
  creatorType: CreatorType;
  socials?: IAuthorSocial[];
}

interface IEditableSocialFieldProps {
  field: IAuthorSocial;
  editMode: boolean;
  creatorType: CreatorType;
}

interface ISocialFieldProps {
  field: IAuthorSocial;
  displayIcon?: boolean;
  showHiddenIcon?: boolean;
}

const SocialField: FC<ISocialFieldProps> = ({
  field,
  displayIcon = true,
  showHiddenIcon = false,
}) => {
  const socialConfig = useMemo(() => {
    return SOCIAL_FIELDS_CONFIG.find(fieldConfigEntry => {
      return fieldConfigEntry.value === field.socialType;
    });
  }, [field.socialType]);

  return (
    <FlexBlock
      flexDirection="row"
      alignItems="center"
      columnGap="10px"
      margin="10px 0"
    >
      {displayIcon && socialConfig && socialConfig.icon && (
        <SocialIconWrapper>{socialConfig.icon}</SocialIconWrapper>
      )}
      <Anchor
        key={field.creatorSocialId}
        href={
          field.socialType !== 'PHONE_NUMBER'
            ? generateSocialAnchorHref(field.url, field.socialType)
            : undefined
        }
        display="block"
        target="_blank"
      >
        <Typography.Text
          $colorName="steel"
          style={{ textDecoration: 'underline' }}
          $lineHeight={16}
          $size={14}
        >
          {field.url}
        </Typography.Text>
      </Anchor>
      {showHiddenIcon && <NotVisibleIcon />}
    </FlexBlock>
  );
};

const EditableSocialField: FC<IEditableSocialFieldProps> = ({
  field,
  editMode,
  creatorType,
}) => {
  const [editField, setEditField] = useState(false);

  const [socialHandleForEdit, setSocialHandleForEdit] = useState(
    field.handler || '',
  );

  const [isFieldHidden, setIsFieldHidden] = useState(field.hidden || false);

  const socialFieldInputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [updateSocial] = useUpdateAuthorSocialMutation();

  const socialConfig = useMemo(() => {
    return SOCIAL_FIELDS_CONFIG.find(fieldConfigEntry => {
      return fieldConfigEntry.value === field.socialType;
    });
  }, [field.socialType]);

  const handleFieldUpdate = async () => {
    if (socialHandleForEdit === field.handler) {
      return;
    }

    if (!socialConfig) {
      showErrorToast('Something went wrong. Cannot update social field.');
      return;
    }

    const isFieldValid = socialConfig.validateField(socialHandleForEdit);

    if (!isFieldValid) {
      showErrorToast('Invalid field value.');
      return;
    }

    const fieldId = field.enrichmentId || field.creatorSocialId;

    if (!fieldId) {
      showErrorToast('Cannot update field. Missing social field ID.');
      return;
    }

    try {
      await updateSocial({
        id: fieldId,
        handle: socialHandleForEdit,
        socialType: field.socialType,
        creatorType: creatorType,
        hidden: isFieldHidden,
        disabled: field.disabled || false,
      }).unwrap();

      showSuccessToast('Social field updated.');
    } catch (error) {
      showErrorToast('Could not update Social field.');
    } finally {
      setEditField(false);
    }
  };

  useEffect(() => {
    if (editField) {
      socialFieldInputRef.current?.focus();
    }
  }, [editField]);

  return (
    <FlexBlock
      flexDirection="row"
      alignItems="center"
      columnGap="10px"
      margin="10px 0"
    >
      {socialConfig && socialConfig.icon && (
        <SocialIconWrapper>{socialConfig.icon}</SocialIconWrapper>
      )}

      {editField ? (
        <FlexBlock flexDirection="row" alignItems="center" flexWrap="wrap">
          <SocialFieldInput
            value={socialHandleForEdit}
            onChange={e => setSocialHandleForEdit(e.target.value)}
            ref={socialFieldInputRef}
            placeholder={field.handler}
          />

          <FlexBlock
            columnGap="20px"
            padding="8px 0 16px"
            alignItems="center"
            minWidth="350px"
            justifyContent="space-between"
          >
            <FlexBlock
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              margin="0 10px 0 0"
            >
              <CheckBox
                checked={!isFieldHidden}
                onChange={() => setIsFieldHidden(c => !c)}
              />
              <Typography.Text $margin="0 0 0 10px">Display</Typography.Text>
              <InfoTipAdvanced tooltipText="" padding="0 10px">
                <Typography.Text $colorName="onyx">
                  Check this box to hide this field from the UI
                </Typography.Text>
              </InfoTipAdvanced>
            </FlexBlock>

            <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
              <Button
                variant="icon-round"
                onClick={handleFieldUpdate}
                disabled={
                  (socialHandleForEdit === field.handler &&
                    field.disabled === isFieldHidden) ||
                  !editField
                }
              >
                <CheckmarkIcon color="#3f3f42" />
              </Button>
              <Button variant="icon-round" onClick={() => setEditField(false)}>
                <CloseIcon />
              </Button>
            </FlexBlock>
          </FlexBlock>
        </FlexBlock>
      ) : (
        <Anchor
          key={field.creatorSocialId}
          href={generateSocialAnchorHref(field.url, field.socialType)}
          display="block"
          target="_blank"
        >
          <Typography.Text
            $colorName="steel"
            style={{ textDecoration: 'underline' }}
            $lineHeight={16}
            $size={14}
          >
            {field.url}
          </Typography.Text>
        </Anchor>
      )}

      {editMode && !editField && (
        <Button
          variant="icon-round"
          onClick={() => {
            setEditField(em => !em);
          }}
        >
          <PencilIcon fill={theme.colors.aluminum} />
        </Button>
      )}
    </FlexBlock>
  );
};

const CreatorSocials: FC<IProps> = ({ creatorType, isEditMode, socials }) => {
  const groupedSocialsByType = useMemo(() => {
    if (!socials) return undefined;

    return socials.reduce((acc, socialField) => {
      let socialType = socialField.socialType;

      // Group Primary and Secondary emails into a single group
      if (socialField.socialType.includes('EMAIL')) {
        socialType = 'EMAIL';
      }

      if (!acc[socialType]) {
        acc[socialType] = [];
      }
      acc[socialType].push(socialField);
      return acc;
    }, {} as Record<AuthorSocialType, IAuthorSocial[]>);
  }, [socials]);

  if (!socials || socials.length === 0) {
    return <></>;
  }

  return (
    <>
      <FlexBlock flexDirection="column" minWidth="100%">
        {isEditMode
          ? socials
              .filter(field => field.enrichmentId)
              .map(field => {
                return (
                  <EditableSocialField
                    field={field}
                    editMode={isEditMode}
                    creatorType={creatorType}
                  />
                );
              })
          : groupedSocialsByType && (
              <>
                {Object.values(groupedSocialsByType).map((fieldGroup, idx) => {
                  const fields = fieldGroup.map(field => (
                    <>
                      <SocialField
                        field={field}
                        displayIcon={false}
                        showHiddenIcon={field.hidden === true}
                      />
                    </>
                  ));

                  const isLastGroup =
                    idx === Object.values(groupedSocialsByType).length - 1;

                  return (
                    <SocialFieldsGroupWrapper $isLastGroup={isLastGroup}>
                      {fields}
                    </SocialFieldsGroupWrapper>
                  );
                })}
              </>
            )}
      </FlexBlock>
    </>
  );
};

export default CreatorSocials;
