import { useGetTaylorGeneratorSettingsQuery } from 'app/api';
import { useMemo } from 'react';
import { IOption } from 'types/commonTypes';

export const useTaylorSettingsOptions = () => {
  const { data: generatorSettings, isFetching } =
    useGetTaylorGeneratorSettingsQuery();

  const options = useMemo(() => {
    if (!generatorSettings) return null;

    const authorEmailOptions = generatorSettings.authorEmailOptions.map(
      setting => {
        return { label: setting.displayName, value: setting.code };
      },
    );
    const pitchContentOptions = generatorSettings.pitchContentOptions.map(
      setting => {
        return { label: setting.displayName, value: setting.code };
      },
    );

    return { authorEmailOptions, pitchContentOptions };
  }, [generatorSettings]);

  const defaultOptions = useMemo(() => {
    if (!generatorSettings) return null;

    const authorEmailGeneratorDefault = generatorSettings.authorEmail;
    const pitchContentGeneratorDefault = generatorSettings.pitchContent;

    const defaultOptions: {
      pitchContent: null | IOption<string>;
      authorEmail: null | IOption<string>;
    } = {
      pitchContent: null,
      authorEmail: null,
    };

    if (authorEmailGeneratorDefault) {
      const optionFound = generatorSettings.authorEmailOptions.find(
        option => option.code === authorEmailGeneratorDefault,
      );

      if (optionFound) {
        defaultOptions.authorEmail = {
          label: optionFound.displayName,
          value: optionFound.code,
        };
      }
    }

    if (pitchContentGeneratorDefault) {
      const optionFound = generatorSettings.pitchContentOptions.find(
        option => option.code === pitchContentGeneratorDefault,
      );

      if (optionFound) {
        defaultOptions.pitchContent = {
          label: optionFound.displayName,
          value: optionFound.code,
        };
      }
    }

    return defaultOptions;
  }, [generatorSettings]);

  return { defaultOptions, options, isFetching };
};
