import { useLocation } from 'react-router';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../app/containers/Global/slice/selectors';
import Analytics from '../analytics';
import { useEffect } from 'react';
import { IS_ENV_LOCAL } from 'utils/constants';

export function useRouteChangeTracking(): void {
  const location = useLocation();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    if (IS_ENV_LOCAL) {
      // Routes debug information for local environment
      console.log('Route change event', location.pathname);
    }

    if (user) {
      Analytics.identify(user);
    }
  }, [location]);
}
