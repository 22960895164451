import {
  IApiResponse,
  ICustomTargetMediaBase,
  IMedia,
  IPaginationRequestPart,
} from 'app/api.types';
import { TablePagination } from 'app/components/Table/parts/Pagination';
import PageLoader from 'app/components/UI/PageLoader';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { scrollMainContentToTop } from 'utils/helpers';
import * as S from 'app/components/Table/Table.styles';
import FlexBlock from 'app/components/UI/FlexBlock';
import CheckBox from 'app/components/UI/CheckBox/CheckBox';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';

import UploadMediaListModal from './parts/Modals/UploadMediaListModal';
import MediaListItem from './parts/MediaListItem/MediaListItem';

import { useJournalistMediaListsActions } from '../../hooks/useJournalistRowActions';
import EmptyDataNotice, {
  IEmptyDataNoticeProps,
} from 'app/components/EmptyDataNotice/EmptyDataNotice';
import { useMediaListRowsSelect } from './hooks/useMediaListRowsSelect';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';

interface IMediaListTableProps {
  data?: IApiResponse<ICustomTargetMediaBase[]>;
  isDataLoading?: boolean;
  renderListItem?: (item: IMedia) => JSX.Element;
  onPaginationChange: Dispatch<SetStateAction<IPaginationRequestPart>>;
  pagination: IPaginationRequestPart;
}

export const MediaListTable: FC<IMediaListTableProps> = props => {
  const handlePageNumberChange = (page: number) => {
    props.onPaginationChange({
      ...props.pagination,
      page: {
        ...props.pagination.page,
        page,
      },
    });
    scrollMainContentToTop();
  };

  const {
    allRowsSelectedInCurrentPage,
    toggleSelectedRowsInCurrentPage,
    handleSelectedRowsReset,
    selectedMediaListIds,
    handleRowSelect,
  } = useMediaListRowsSelect(props.data);

  const onSuccessDelete = useCallback(() => {
    handleSelectedRowsReset();
  }, [handleSelectedRowsReset]);

  const { handleDelete, openCreateMediaListModal, openUploadMediaListModal } =
    useJournalistMediaListsActions(onSuccessDelete);

  const handleMediaListDelete = (row: ICustomTargetMediaBase) => {
    handleDelete([row.id]);
  };

  const emptyNoticeProps: IEmptyDataNoticeProps = useMemo(() => {
    return {
      title: `No Media Lists found`,
      description: 'Create a new Media List or upload a file to get started',
    };
  }, []);

  return (
    <>
      <S.Wrapper noTopBorder>
        <S.TableScrollWrapper>
          <FlexBlock
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding="8px 24px"
          >
            <FlexBlock flexDirection="row" alignItems="center" columnGap="10px">
              <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
                <CheckBox
                  checked={allRowsSelectedInCurrentPage}
                  onChange={checked => {
                    toggleSelectedRowsInCurrentPage(checked);
                  }}
                />
                <Typography.Text $size={16} $lineHeight={24} $dmSans $bold>
                  Select All
                </Typography.Text>
              </IfHasPermission>
            </FlexBlock>

            <FlexBlock flexDirection="row" columnGap="10px">
              <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
                {selectedMediaListIds.length > 0 ? (
                  <Button
                    compact
                    padding="10px 8px"
                    margin="0"
                    variant="danger-outline"
                    onClick={() => {
                      handleDelete(selectedMediaListIds);
                    }}
                  >
                    Delete{' '}
                    {`${selectedMediaListIds.length} ${
                      selectedMediaListIds.length > 1
                        ? 'Media Lists'
                        : 'Media List'
                    }`}
                  </Button>
                ) : (
                  <>
                    <Button
                      compact
                      padding="10px 8px"
                      margin="0"
                      variant="secondary"
                      onClick={() => {
                        openUploadMediaListModal();
                      }}
                    >
                      Upload Media List
                    </Button>
                    <Button
                      compact
                      padding="10px 8px"
                      margin="0"
                      variant="primary"
                      onClick={() => {
                        openCreateMediaListModal();
                      }}
                    >
                      Create Media List
                    </Button>
                  </>
                )}
              </IfHasPermission>
            </FlexBlock>
          </FlexBlock>
          <FlexBlock flexDirection="column" padding="8px" rowGap="8px">
            {props.isDataLoading && <PageLoader />}
            {props.data?.content.map(item => (
              <MediaListItem
                key={`${item.id}-${item.name}`}
                item={item}
                onSelect={handleRowSelect}
                isSelected={Boolean(selectedMediaListIds.includes(item.id))}
                onDelete={handleMediaListDelete}
              />
            ))}
            {(!props.data || !Boolean(props.data.content?.length)) &&
              !props.isDataLoading && (
                <FlexBlock
                  justifyContent="center"
                  alignItems="center"
                  margin="auto"
                >
                  <EmptyDataNotice {...emptyNoticeProps} />
                </FlexBlock>
              )}
          </FlexBlock>
        </S.TableScrollWrapper>
        {props.data && (
          <TablePagination
            pageInfo={props.data.pageable}
            onPageNumberChange={handlePageNumberChange}
            totalElements={props.data.totalElements}
          />
        )}
      </S.Wrapper>
      <UploadMediaListModal />
    </>
  );
};
