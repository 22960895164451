import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.journalists || initialState;

export const selectUploadMediaListModalOpen = createSelector(
  [selectSlice],
  state => state.uploadListModalOpen,
);

export const selectCreateMediaListModalOpen = createSelector(
  [selectSlice],
  state => ({
    modalOpen: state.createListModalOpen,
    ids: state.journalistsToAdd,
  }),
);

export const selectAddToMediaListModalOpen = createSelector(
  [selectSlice],
  state => ({
    modalOpen: state.addToMediaListModalOpen,
    ids: state.journalistsToAdd,
  }),
);

export const selectMoveMediaListEntriesModalOpen = createSelector(
  [selectSlice],
  state => state.moveMediaListEntriesModalOpen,
);

export const selectCopyMediaListEntriesModalOpen = createSelector(
  [selectSlice],
  state => state.copyMediaListEntriesModalOpen,
);

export const selectJournalistsToCopy = createSelector([selectSlice], state => ({
  modalOpen: state.copyMediaListEntriesModalOpen,
  sourceObject: state.mediaListSourceObject,
  ids: state.journalistsToCopyIds,
}));

export const selectJournalistsToMove = createSelector([selectSlice], state => ({
  modalOpen: state.moveMediaListEntriesModalOpen,
  sourceObject: state.mediaListSourceObject,
  ids: state.journalistsToMoveIds,
}));
