const ListViewIcon = ({ size = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#3F3F42" transform="translate(-383 -233)">
        <g transform="translate(260 72)">
          <g transform="translate(64 145)">
            <g transform="translate(0 4)">
              <g transform="translate(47)">
                <g transform="translate(12 12)">
                  <path d="M2 2.4h12A.8.8 0 1114 4H2a.8.8 0 110-1.6zm0 3.2h12a.8.8 0 110 1.6H2a.8.8 0 110-1.6zm0 3.2h12a.8.8 0 110 1.6H2a.8.8 0 110-1.6zM2 12h12a.8.8 0 110 1.6H2A.8.8 0 112 12z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ListViewIcon;
