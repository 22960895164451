import styled, { css } from 'styled-components';

export const Input = styled.div<{
  $isActive?: boolean;
  $isCompact?: boolean;
  $compactInputStyles?: boolean;
}>(
  ({
    theme,
    $isActive,
    $isCompact = false,
    $compactInputStyles = false,
  }) => css`
    position: relative;
    min-height: ${$isCompact ? '43px' : '48px'};
    border: 1px solid ${!$isActive ? theme.colors.smoke : theme.colors.orbit};
    border-radius: 10px;
    background-color: ${$compactInputStyles
      ? 'rgba(29, 118, 222, 0.1)'
      : theme.colors.white};
    display: flex;
    align-items: center;
    padding: 4px 16px;
    column-gap: 8px;
    width: 178px;

    ${$compactInputStyles &&
    css`
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background: ${theme.colors.orbit};
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
      }
    `}

    ${$compactInputStyles &&
    !$isActive &&
    css`
      border: none;
    `}
  `,
);

export const Menu = styled.div<{ $height?: number; $zIndex?: number }>(
  ({ $height, $zIndex }) => css`
    position: absolute;
    box-sizing: border-box;
    border: 1px solid #bcbcc1;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 16px 0 rgba(12, 12, 13, 0.1);

    width: 432px;
    top: calc(100% + 8px);
    left: 0;
    z-index: ${$zIndex ? $zIndex : 10};
    ${$height
      ? css`
          max-height: ${$height}px;
          overflow-y: scroll;
        `
      : ''}
  `,
);

export const MenuFooter = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 71px;
    padding: 17px;
    position: sticky;
    width: 100%;
    bottom: 0;
    border-top: 1px solid ${theme.colors.smoke};
    background: ${theme.colors.white};
    border-radius: 0 0 8px 8px;
  `,
);

export const ContentPill = styled.div<{ $bgColor?: string }>(
  ({ $bgColor = 'rgba(29, 118, 222, 0.1)' }) => css`
    width: fit-content;
    border-radius: 20px;
    background-color: ${$bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
  `,
);

export const MenuActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  height: 40px;
  > button {
    height: 100%;
    align-items: center;
    padding: 0 24px;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const ActiveFilterButton = styled.div(
  ({ theme }) => css`
    border-radius: 8px;
    background-color: rgba(29, 118, 222, 0.1);
    padding: 16px;
    color: ${theme.colors.orbit};
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 12px;
  `,
);
