import TrashIcon from 'app/assets/icons/TrashIcon';
import { UploadFilesThinIcon } from 'app/assets/icons/UploadFilesIcon';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import { forwardRef } from 'react';
import { theme } from 'styles/theme';
import { useHover } from 'utils/hooks/useHover';

interface IMediaListActionsProps {
  onDelete: () => void;
  onExport: () => void;
  hasEntries: boolean;
  expanded: boolean;
}

const MediaListActions = forwardRef<HTMLDivElement, IMediaListActionsProps>(
  ({ onDelete, onExport, hasEntries, expanded }, ref) => {
    const isHover = useHover(ref);

    if (!isHover && !expanded) {
      return <></>;
    }

    return (
      <FlexBlock columnGap="5px" margin="0 10px 0 0" alignItems="center">
        {hasEntries ? (
          <IfHasPermission require={userPermissionEnum.READ_TARGET_MEDIA}>
            <Button
              compact
              variant="anchor-hover"
              padding="10px 8px"
              onClick={e => {
                e.stopPropagation();
                onExport();
              }}
            >
              <FlexBlock
                flexDirection="row"
                alignItems="center"
                columnGap="3px"
              >
                <UploadFilesThinIcon width={15} height={15} />
                Export
              </FlexBlock>
            </Button>
          </IfHasPermission>
        ) : (
          <Typography.Text $colorName={'steel'}>
            This list is empty.
          </Typography.Text>
        )}
        <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
          <Button
            compact
            variant="anchor-hover"
            baseColor={theme.colors.volatileRed}
            padding="10px 8px"
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <FlexBlock flexDirection="row" alignItems="center" columnGap="3px">
              <TrashIcon width={15} height={15} />
              Delete
            </FlexBlock>
          </Button>
        </IfHasPermission>
      </FlexBlock>
    );
  },
);

export default MediaListActions;
