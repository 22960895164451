// Retrieved from `GET /v2/profile/permissions`

export enum userPermissionEnum {
  // Tenants
  CREATE_TENANTS = 'CREATE_TENANTS',
  MANAGE_TENANTS = 'MANAGE_TENANTS',
  READ_TENANTS = 'READ_TENANTS',
  // Users
  CREATE_USERS = 'CREATE_USERS',
  CREATE_FREE_USERS = 'CREATE_FREE_USERS',
  READ_USERS = 'READ_USERS',
  MANAGE_USERS = 'MANAGE_USERS',
  USER_PROFILE_ACCESS = 'USER_PROFILE_ACCESS',
  // Profile
  PROFILE_EDIT = 'PROFILE_EDIT',
  // Projects permissions
  READ_PROJECTS = 'READ_PROJECTS',
  MANAGE_PROJECTS = 'MANAGE_PROJECTS',
  CREATE_PROJECTS = 'CREATE_PROJECTS',
  // Pitch permissions
  CREATE_PITCH = 'CREATE_PITCH',
  READ_PITCH = 'READ_PITCH',
  MANAGE_PITCH = 'MANAGE_PITCH',
  READ_ALL_PREDICTION_RESULTS = 'READ_ALL_PREDICTION_RESULTS',
  MEDIA_TRAFFIC_ACCESS = 'MEDIA_TRAFFIC_ACCESS',
  REPORT_EXPORT_ACCESS = 'REPORT_EXPORT_ACCESS',
  PITCH_COUNTRIES_ACCESS = 'PITCH_COUNTRIES_ACCESS',
  PITCH_PODCASTS_ACCESS = 'PITCH_PODCASTS_ACCESS',
  JOURNALIST_EMAIL_INFO_ACCESS = 'JOURNALIST_EMAIL_INFO_ACCESS',
  JOURNALIST_INFORMATION_ACCESS = 'JOURNALIST_INFORMATION_ACCESS',
  JOURNALIST_EMAIL_INFO_OBSCURED = 'JOURNALIST_EMAIL_INFO_OBSCURED',
  // Press Release permissions
  CREATE_PRESS_RELEASE = 'CREATE_PRESS_RELEASE',
  READ_PRESS_RELEASE = 'READ_PRESS_RELEASE',
  MANAGE_PRESS_RELEASE = 'MANAGE_PRESS_RELEASE',
  CREATE_PRESS_RELEASE_CONTENT = 'CREATE_PRESS_RELEASE_CONTENT',
  READ_PRESS_RELEASE_CONTENT = 'READ_PRESS_RELEASE_CONTENT',
  MANAGE_PRESS_RELEASE_CONTENT = 'MANAGE_PRESS_RELEASE_CONTENT',
  GENERATE_PRESS_RELEASE_CONTENT = 'GENERATE_PRESS_RELEASE_CONTENT',
  // Target Media permissions
  CREATE_TARGET_MEDIA = 'CREATE_TARGET_MEDIA',
  READ_TARGET_MEDIA = 'READ_TARGET_MEDIA',
  MANAGE_TARGET_MEDIA = 'MANAGE_TARGET_MEDIA',
  // Super Admin permissions
  SYSTEM_MANAGEMENT = 'SYSTEM_MANAGEMENT',
  RESET_GENERATION_LIMITS = 'RESET_GENERATION_LIMITS',
  // Monitor permissions
  SMC_MONITOR_ACCESS = 'SMC_MONITOR_ACCESS',
  // Content generation permissions
  GENERATE_AUTHOR_EMAIL_CONTENT = 'GENERATE_AUTHOR_EMAIL_CONTENT',
  GENERATE_PITCH_CONTENT = 'GENERATE_PITCH_CONTENT',
  GENERATE_CONTENT_LIMIT = 'GENERATE_CONTENT_LIMIT',
  // Filter permissions
  SOURCE_2_ACCESS = 'SOURCE_2_ACCESS',
  SOURCE_4_ACCESS = 'SOURCE_4_ACCESS',
  IS_ENTERPRISE_USER = 'IS_ENTERPRISE_USER',
  // Bio permissions
  CREATE_BIO = 'CREATE_BIO',
  READ_BIO = 'READ_BIO',
  MANAGE_BIO = 'MANAGE_BIO',
  // Blog permissions
  CREATE_BLOG = 'CREATE_BLOG',
  READ_BLOG = 'READ_BLOG',
  MANAGE_BLOG = 'MANAGE_BLOG',
  // Byline permissions
  CREATE_BYLINE = 'CREATE_BYLINE',
  READ_BYLINE = 'READ_BYLINE',
  MANAGE_BYLINE = 'MANAGE_BYLINE',
  // Social Post permissions
  CREATE_SOCIAL_POST = 'CREATE_SOCIAL_POST',
  READ_SOCIAL_POST = 'READ_SOCIAL_POST',
  MANAGE_SOCIAL_POST = 'MANAGE_SOCIAL_POST',
  CREATE_SOCIAL_POST_CONTENT = 'CREATE_SOCIAL_POST_CONTENT',
  READ_SOCIAL_POST_CONTENT = 'READ_SOCIAL_POST_CONTENT',
  MANAGE_SOCIAL_POST_CONTENT = 'MANAGE_SOCIAL_POST_CONTENT',
  GENERATE_SOCIAL_POST_CONTENT = 'GENERATE_SOCIAL_POST_CONTENT',
  // To Do: Remove unused permissions for Free and Premium Users
  IS_FREE_USER = 'IS_FREE_USER',
  IS_PREMIUM_USER = 'IS_PREMIUM_USER',
  // To Do: Remove unused permission for subscriptions management
  SUBSCRIPTION_MANAGEMENT = 'SUBSCRIPTION_MANAGEMENT',
}
