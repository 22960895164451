import Typography from 'app/components/Typography';
import styled from 'styled-components';

export const TenantOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: ${({ theme }) => theme.colors.smoke};
  }
`;

export const SSOLabel = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 2px 10px;
  background: rgba(29, 118, 222, 0.1);
  border-radius: 40px;
  margin-right: 5px;
`;

export const EllipsisText = styled(Typography.Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;
