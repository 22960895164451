function _defaultOnSessionEndFunction() {
  console.warn('Session ended, but proper handler have not been initiated.');
}

class SessionTimer {
  delay: number;
  timerId: ReturnType<typeof setTimeout> | null;
  lastTimerReset: Date | null;
  onSessionEnd: () => void = _defaultOnSessionEndFunction;

  constructor(delayMs: number) {
    this.timerId = null;
    this.delay = delayMs;
    this.lastTimerReset = null;
  }

  startTimer() {
    this.timerId = setTimeout(() => {
      this.onSessionEnd();
      this.timerId = null;
    }, this.delay);
    this.lastTimerReset = new Date();
  }

  stopTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
      this.lastTimerReset = null;
    }
  }

  resetTimer(onSessionEnd?: () => void) {
    if (onSessionEnd) this.onSessionEnd = onSessionEnd;
    this.stopTimer();
    this.startTimer();
  }
}

export default SessionTimer;
