import { defaultMediaListPagination } from 'app/containers/Journalists/pages/JournalistsListPage.constants';
import JournalistsTable from '../../../Table/JournalistsTable';
import { getJournalistsMediaListTableConfig } from '../../MediaListTable.constants';
import { FC, memo, useCallback, useMemo } from 'react';
import { ExpandedMediaListItemContainer } from '../../MediaListTable.styles';
import { useGetCustomTargetMediaQuery } from 'app/api';
import { useCreatorPanel } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/hooks/useCreatorPanel';
import { ICustomMediaMember } from 'app/api.types';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import ArrowRightIcon from 'app/assets/icons/ArrowRightIcon';
import TrashIcon from 'app/assets/icons/TrashIcon';
import CopyIcon from 'app/assets/icons/CopyIcon';
import { theme } from 'styles/theme';
import { useJournalistRowActions } from 'app/containers/Journalists/pages/hooks/useJournalistRowActions';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import Typography from 'app/components/Typography';

interface IMediaListEntriesTableProps {
  mediaListId: number;
  isParentListSelected: boolean;
  onSelectedRowIdsChange: (
    selectedMemberIds: number[],
    selectedAuthorIds: string[],
  ) => void;
}

const MediaListItemsTable: FC<IMediaListEntriesTableProps> = ({
  mediaListId,
  isParentListSelected,
  onSelectedRowIdsChange,
}) => {
  const {
    data: mediaList,
    isLoading: isMediaListLoading,
    isFetching: isMediaListFetching,
    isError: isMediaListError,
  } = useGetCustomTargetMediaQuery(mediaListId);

  const columns = useMemo(
    () => (mediaList ? getJournalistsMediaListTableConfig(mediaList) : []),
    [mediaList],
  );

  const { openCreatorPanel } = useCreatorPanel();

  const { handleMove, handleCopy, handleDelete } = useJournalistRowActions();

  const handleRowClick = (row: ICustomMediaMember) => {
    if (row.journalist && row.journalist.id) {
      openCreatorPanel(row.journalist.id, row.journalist);
    }
  };

  const handleSelectedRowsChange = useCallback(
    (selectedIds: string[]) => {
      if (!mediaList) return;
      const authorIds = selectedIds
        .map(
          id =>
            mediaList?.members.find(member => Number(member.id) === Number(id))
              ?.journalist.id,
        )
        .filter(Boolean) as string[];
      onSelectedRowIdsChange(selectedIds as unknown as number[], authorIds);
    },
    [mediaList, onSelectedRowIdsChange],
  );

  const renderRowActions = useCallback(
    (row: ICustomMediaMember) => {
      if (!mediaList) return <></>;

      return (
        <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
          <Button
            compact
            variant="anchor-hover"
            padding="10px 8px"
            onClick={e => {
              e.stopPropagation();
              if (row && row.id) {
                handleMove([row.id], mediaList);
              }
            }}
          >
            <FlexBlock flexDirection="row" alignItems="center" columnGap="3px">
              <ArrowRightIcon />
              Move
            </FlexBlock>
          </Button>
          <Button
            compact
            variant="anchor-hover"
            padding="10px 8px"
            onClick={e => {
              e.stopPropagation();
              if (row && row.id) {
                handleCopy([row.id], mediaList);
              }
            }}
          >
            <FlexBlock flexDirection="row" alignItems="center" columnGap="3px">
              <CopyIcon />
              Copy
            </FlexBlock>
          </Button>
          <Button
            compact
            variant="anchor-hover"
            baseColor={theme.colors.volatileRed}
            padding="10px 8px"
            onClick={e => {
              e.stopPropagation();
              if (row && row.id) {
                handleDelete([row.id], mediaList.id);
              }
            }}
          >
            <FlexBlock flexDirection="row" alignItems="center" columnGap="3px">
              <TrashIcon width={15} height={15} />
              Delete
            </FlexBlock>
          </Button>
        </IfHasPermission>
      );
    },
    [handleCopy, handleDelete, handleMove, mediaList],
  );

  if (isMediaListError) {
    return (
      <FlexBlock
        minWidth="100%"
        margin="30px"
        justifyContent="center"
        alignItems="center"
      >
        <Typography.Text $size={16}>
          Could not load media list entries.
        </Typography.Text>
      </FlexBlock>
    );
  }

  return (
    <ExpandedMediaListItemContainer onClick={e => e.stopPropagation()}>
      <JournalistsTable
        showHeaderOnEmptyData
        showNoResources
        isDataLoading={isMediaListLoading || isMediaListFetching}
        data={mediaList?.members}
        columns={columns}
        dataTypeName="Media List Entries"
        pagination={defaultMediaListPagination}
        onSelectedRowIdsChange={handleSelectedRowsChange}
        onRowClick={row => handleRowClick(row)}
        renderRowActions={renderRowActions}
        noHeader
        isParentListSelected={isParentListSelected}
      />
    </ExpandedMediaListItemContainer>
  );
};

export default memo(MediaListItemsTable);
