import styled, { css } from 'styled-components/macro';

export const PillWrapper = styled.button<{
  checked: boolean;
  disabled?: boolean;
}>(
  props => css`
    padding: 10px 16px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    transition: 0.125s all ease-in-out;

    background-color: ${props.checked
      ? props.theme.colors.orbit
      : 'rgba(29,118,222,0.1)'};

    color: ${props.checked
      ? props.theme.colors.white
      : props.theme.colors.orbit};

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.7;
    }

    ${Checkmark} {
      background-color: ${props.checked ? 'white' : 'transparent'};
      border-color: ${props.checked ? 'white' : props.theme.colors.orbit};
      svg {
        opacity: ${props.checked ? '1' : '0'};
      }
    }

    ${props.disabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
        `
      : ''}
  `,
);

export const Checkmark = styled.div(
  props => css`
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin-right: 8px;
    border: 1px solid ${props.theme.colors.steel};
    transition: 0.125s all ease-in-out;
  `,
);
