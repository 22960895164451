import { IAuthor, IAuthorSearchResponse } from 'app/api.types';
import { usePitchSlice } from 'app/containers/Pitch/slice';
import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';

export const useCreatorPanel = () => {
  const dispatch = useAppDispatch();
  const { actions } = usePitchSlice();

  // Reset creator state when component mounts
  useEffect(() => {
    dispatch(actions.setAuthor(null));
  }, [dispatch, actions]);

  const handleCloseCreatorPanel = useCallback(() => {
    dispatch(actions.setAuthor(null));
  }, [dispatch, actions]);

  const handleOpenCreatorPanel = useCallback(
    (creatorId: string, creator: IAuthorSearchResponse | IAuthor) => {
      dispatch(
        actions.setAuthor({
          id: creatorId,
          interest: '',
          sentiment: 0,
          podcastId: null,
          reportItem: null,
          targetMedia: null,
        }),
      );
      dispatch(
        actions.setCreator({
          id: creatorId,
          //@ts-ignore
          name: creator?.name || '',
          outlets: [],
          //@ts-ignore
          socials: creator?.socials || [],
          //@ts-ignore
          email: creator?.email || '',
          //@ts-ignore
          avatar: creator?.avatar || '',
          //@ts-ignore
          bio: creator?.bio || '',
          //@ts-ignore
          locations: creator?.locations || [],
          //@ts-ignore
          title: creator?.title || '',
          favorite: creator?.favorite || undefined,
          restricted: creator?.restricted || undefined,
          obscured: creator?.obscured || undefined,
          //@ts-ignore
          contributor: creator?.contributor || undefined,
        }),
      );
    },
    [dispatch, actions],
  );

  return {
    openCreatorPanel: handleOpenCreatorPanel,
    closeCreatorPanel: handleCloseCreatorPanel,
  };
};
