import { Dispatch, FC, SetStateAction, useState } from 'react';
import * as S from './ContentTypeSelector.styles';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';

import { Anchor } from 'app/components/UI/Anchor';
import Button from 'app/components/UI/Button/Button';
import { theme } from 'styles/theme';
import CheckBox from 'app/components/UI/CheckBox/CheckBox';
import { useClickOutsideListener } from './hooks/useClickOutsideListener.hook';
import { useContentTypeSelectorMenu } from './hooks/useContentTypeSelectorMenu.hook';

interface IProps {
  menuTitle: string;
  options: {
    key: string;
    displayName: string;
    count: number;
    checked?: boolean;
  }[];
  setSelectedOptions: Dispatch<SetStateAction<string[] | undefined>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  compactView?: boolean;
}

const ContentSelectorMenu: FC<IProps> = ({
  menuTitle,
  options,
  setSelectedOptions,
  open,
  setOpen,
  compactView = false,
}) => {
  const [menuOptions, setMenuOptions] = useState(options);

  const { actions, conditions } = useContentTypeSelectorMenu(
    menuOptions,
    setMenuOptions,
  );

  const ref = useClickOutsideListener(open, setOpen);

  const handleOptionSelection = () => {
    const updatedContentTypeSelection = menuOptions
      .filter(option => option.checked)
      .map(option => option.key);

    setSelectedOptions(updatedContentTypeSelection);
    setOpen(false);
  };

  return (
    <S.Menu
      onClick={e => e.stopPropagation()}
      ref={ref}
      $height={compactView ? 450 : undefined}
    >
      <FlexBlock flexDirection="column" padding="15.5px 17px">
        <Typography.Text
          $colorName="onyx"
          $size={16}
          $lineHeight={24}
          $bold
          $dmSans
          $margin="0 0 8px 0"
        >
          {menuTitle}
        </Typography.Text>
        {menuOptions.map(option => (
          <FlexBlock
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            minWidth="100%"
            padding="8px 0"
            maxHeight="56px"
            key={option.displayName}
          >
            <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
              <CheckBox
                checked={Boolean(option.checked)}
                onChange={(e: boolean) => {
                  if (option.count > 0) {
                    actions.handleOptionCheck(option.key, e);
                  }
                }}
              />
              <Typography.Text $size={16} $lineHeight={24} $colorName="onyx">
                {option.displayName}
              </Typography.Text>
            </FlexBlock>
            <S.ContentPill
              $bgColor={option.count < 1 ? theme.colors.haze : undefined}
            >
              <Typography.Text
                $colorName={option.count > 0 ? 'orbit' : 'aluminum'}
              >
                {option.count}
              </Typography.Text>
            </S.ContentPill>
          </FlexBlock>
        ))}
      </FlexBlock>
      <S.MenuFooter>
        <Typography.Text $size={16} $lineHeight={24}>
          <Anchor
            style={{ fontSize: '16px' }}
            onClick={() =>
              actions.handleAllOptionsCheck(conditions.isNotAllSelected)
            }
          >
            {conditions.isNotAllSelected ? 'Show All' : 'Remove All'}
          </Anchor>
        </Typography.Text>
        <S.MenuActionsWrapper>
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleOptionSelection()}
            disabled={!conditions.isOneOrMoreSelected}
          >
            Apply
          </Button>
        </S.MenuActionsWrapper>
      </S.MenuFooter>
    </S.Menu>
  );
};

export default ContentSelectorMenu;
