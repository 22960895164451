import {
  ContentHeader,
  ContentLayout,
} from 'app/components/UI/ContentLayout/ContentLayout';
import { FC, useEffect, useMemo, useState } from 'react';

import { BioPageTabs, ContentControls, TopHeader } from './parts/BioPageHeader';
import MainGrid from 'app/components/UI/MainGrid/MainGrid';
import { ContentWrapper, FullWidth, StickToTop } from './BioPage.styles';
import BioPageContent from './parts/BioPageContent';
import BioVersionDrawer from './parts/BioVersionDrawer';
import BioSettingsDrawer from './parts/BioSettingsDrawer';
import {
  IBio,
  IBioRegeneratedSuggestion,
  IBioSuggestionContentPayload,
  IBioSuggestionContentResponse,
} from 'app/api.types';
import {
  useCreateNewBioMutation,
  useGetGeneratedBioMutation,
  useSaveBioContentMutation,
} from 'app/api';

import { showCustomToast, showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import PageLoader from 'app/components/UI/PageLoader';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useBioData } from './hooks/useBioData';
import CreateBioForm from '../CreateOrEditBio/parts/CreateBioForm';
import FlexBlock from 'app/components/UI/FlexBlock';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';
import { validateCreateBioPayload } from './BioPage.helpers';
import ContentRegenerationModal from './parts/RegenerationModal';
import { RouteConstants } from 'app/routes';
import { useHistory } from 'react-router-dom';
import { useBioGenerationLimits } from './hooks/useBioGenerationsLimits';
import useLimitExceededPopup from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/hooks/useLimitExceededPopup';
import { BioGenerationSuccessToast } from './parts/BioGenerationSuccessToast';
import { logger } from 'utils/apm/logger';

interface IRegeneratedContentPayload {
  bioId: number;
  body: {
    longBio: string;
    shortBio: string;
    eventBrief: string;
    emphasizeEducation: boolean | undefined;
    emphasizeInterests: boolean | undefined;
    emphasizeRole: boolean | undefined;
  };
}

const BioPage: FC = () => {
  const [isVersionDrawerOpen, setIsVersionDrawerOpen] = useState(false);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);

  const history = useHistory();

  const {
    isBioLoading,
    isContentLoading,
    content,
    payload,
    bio: bioData,
    isDraft,
    bioId,
    bioTitle,
    versionId,
    projectId,
    tenantId,
    isError,
  } = useBioData();

  const [generateBio, { isLoading: isGenerateLoading }] =
    useGetGeneratedBioMutation();

  const [saveGeneratedBio, { isLoading: isSavingBio }] =
    useSaveBioContentMutation();

  const [createBio] = useCreateNewBioMutation();

  const [bio, setBio] = useState({
    shortBio: content?.shortBio || '',
    longBio: content?.longBio || '',
  });

  const { createModal } = usePromptModalContext();

  const limitExceededPopup = useLimitExceededPopup();

  const { bioGenerationsLeft, refresh: refreshLimits } =
    useBioGenerationLimits();

  useEffect(() => {
    setBio({
      shortBio: content?.shortBio || '',
      longBio: content?.longBio || '',
    });
  }, [content]);

  const isBioModified = useMemo(() => {
    // Validation: Cannot save empty modified Bio's
    if (!bio.longBio || !bio.shortBio) return false;

    // Validation: Both Bio's are the same as local copies
    if (content?.longBio !== bio.longBio || content.shortBio !== bio.shortBio) {
      return true;
    }

    return false;
  }, [content?.longBio, content?.shortBio, bio.longBio, bio.shortBio]);

  const handleContentChange = (type: 'short' | 'long', content: string) => {
    if (type === 'short') {
      setBio(currentBio => ({ ...currentBio, shortBio: content }));
    }

    if (type === 'long') {
      setBio(currentBio => ({ ...currentBio, longBio: content }));
    }
  };

  const redirectToContentVersion = (bioId: number, versionId: number) => {
    const routeToNewBio = RouteConstants.bio.makeBioUrl(
      projectId,
      tenantId,
      bioId,
      versionId,
    );
    history.push(routeToNewBio);
  };

  const handleCreateBio = async (payload: IBioSuggestionContentPayload) => {
    try {
      const title = bioTitle || 'Untitled Bio';

      const createBioPayload = {
        projectId,
        title,
        linkedInURL: payload.linkedInURL,
        bioContentCreateBody: {
          ...bio,
          eventBrief: payload.eventBrief,
          emphasizeEducation: payload.emphasizeEducation,
          emphasizeInterests: payload.emphasizeInterests,
          emphasizeRole: payload.emphasizeRole,
        },
      };

      if (!validateCreateBioPayload(createBioPayload)) {
        showErrorToast('Could not Save Bio.');
        logger.logError('Bio - Save - Invalid bio payload');
        return undefined;
      }

      const createdBio = createBio(createBioPayload).unwrap();
      return createdBio;
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Could not Save Bio.',
      );
      return undefined;
    }
  };

  const handleSaveBioContent = async (
    regeneratedContentPayload?: IRegeneratedContentPayload,
  ) => {
    // Save current Bio Content - Creates a new Content Version
    try {
      if (!payload) return;
      const contentPayload = {
        bioId,
        body: {
          ...bio,
          eventBrief: payload?.eventBrief,
          emphasizeEducation: payload?.emphasizeEducation,
          emphasizeInterests: payload?.emphasizeInterests,
          emphasizeRole: payload?.emphasizeRole,
        },
      };
      const savedContent = await saveGeneratedBio(
        regeneratedContentPayload || contentPayload,
      ).unwrap();

      return savedContent;
    } catch (error) {
      throw error;
    }
  };

  const handleRegenerate = async (
    type: 'short' | 'long' | 'all',
    payloadParam?: IBioSuggestionContentPayload,
  ) => {
    if (bioGenerationsLeft === 0) {
      limitExceededPopup.show();
      return;
    }

    setIsSettingsDrawerOpen(false);
    if (!payload) return;

    let createdBio: IBio | undefined;
    let latestSavedContent: IBioRegeneratedSuggestion | undefined;
    let currentContentVersion = versionId;

    try {
      // Persist draft or modified content as a new Bio or Bio's content version before regenerating content
      if (isBioModified || isDraft) {
        if (!bioId) {
          createdBio = await handleCreateBio(payload);
          if (createdBio && createdBio.latestVersion) {
            currentContentVersion = createdBio.latestVersion;
          }
        } else {
          latestSavedContent = await handleSaveBioContent();
          if (latestSavedContent) {
            currentContentVersion = versionId + 1;
          }
        }
      }

      const regenerationPayload = payloadParam || payload;

      if (regenerationPayload) {
        let regeneratedContent: IBioSuggestionContentResponse;
        regeneratedContent = await generateBio(regenerationPayload).unwrap();

        const currentBioId = bioId || createdBio?.id;

        // Must save newly generated content as a new content version
        if (!currentBioId) {
          showErrorToast('Could not save generated bio.');
          logger.logError('Bio - Save Generated Bio - Bio ID is missing');
          return;
        }

        const regeneratedContentPayloadToSave = {
          bioId: currentBioId,
          body: {
            eventBrief: regeneratedContent?.eventBrief || '',
            emphasizeEducation: regeneratedContent?.emphasizeEducation,
            emphasizeInterests: regeneratedContent?.emphasizeInterests,
            emphasizeRole: regeneratedContent?.emphasizeRole,
          },
        };

        if (type === 'short') {
          latestSavedContent = await handleSaveBioContent({
            ...regeneratedContentPayloadToSave,
            body: {
              ...regeneratedContentPayloadToSave.body,
              shortBio: regeneratedContent.shortBio,
              longBio: bio.longBio,
            },
          });
        }

        if (type === 'long') {
          latestSavedContent = await handleSaveBioContent({
            ...regeneratedContentPayloadToSave,
            body: {
              ...regeneratedContentPayloadToSave.body,
              shortBio: bio.shortBio,
              longBio: regeneratedContent.longBio,
            },
          });
        }

        if (type === 'all') {
          latestSavedContent = await handleSaveBioContent({
            ...regeneratedContentPayloadToSave,
            body: {
              ...regeneratedContentPayloadToSave.body,
              shortBio: regeneratedContent.shortBio,
              longBio: regeneratedContent.longBio,
            },
          });
        }

        if (latestSavedContent) {
          // Redirect to the newest content version
          showCustomToast(
            <BioGenerationSuccessToast
              currentLimit={bioGenerationsLeft - 1}
              message="Bio Regenerated"
            />,
            'toast-violet',
          );

          refreshLimits();
          redirectToContentVersion(currentBioId, currentContentVersion + 1);
        }
      }
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Could not regenerate Bio.',
      );
    }
  };

  const handleSave = async () => {
    if (!payload) return;
    if (bioId) {
      const savedContent = await handleSaveBioContent();
      if (savedContent) {
        showSuccessToast('Bio Content Saved.');
        redirectToContentVersion(savedContent.bioId, versionId + 1);
      }
    } else {
      const savedBio = await handleCreateBio(payload);
      if (savedBio) {
        showSuccessToast('Bio Saved.');
        redirectToContentVersion(savedBio.id, savedBio.latestVersion || 1);
      }
    }
  };

  const regenerateContentClick = async () => {
    if (bioGenerationsLeft === 0) {
      limitExceededPopup.show();
    } else {
      createModal({
        title: `Regenerate All`,
        description: `This will regenerate all of your Bio’s content, overwritting current content. You'll need to revert to a previous version to restore any overwritten content.`,
        okButtonText: 'Regenerate',
        cancelButtonText: 'Cancel',
        okButtonDanger: false,
        okButtonCallback: async () => {
          await handleRegenerate('all');
        },
      });
    }
  };

  if (isBioLoading) return <PageLoader />;

  if (isError)
    return (
      <FlexBlock>
        <EmptyDataNotice
          title="Could not load Bio"
          description="Something went wrong."
        />
      </FlexBlock>
    );

  return (
    <ContentLayout>
      <StickToTop>
        <ContentHeader>
          <TopHeader
            toggleVersionsDrawer={setIsVersionDrawerOpen}
            regenerateContent={regenerateContentClick}
            toggleSettings={setIsSettingsDrawerOpen}
            saveBio={handleSave}
            isContentModified={isBioModified}
            isSaveLoading={isSavingBio}
            isDraft={isDraft}
          />
        </ContentHeader>

        <BioPageTabs>
          {!isDraft && bioId && (
            <ContentControls
              toggleVersionsDrawer={setIsVersionDrawerOpen}
              version={versionId || 1}
              bioId={bioId}
            />
          )}
        </BioPageTabs>
      </StickToTop>
      <MainGrid minContent>
        <ContentWrapper>
          <FullWidth>
            {isContentLoading ? (
              <PageLoader />
            ) : (
              <BioPageContent
                content={bio}
                handleContentChange={handleContentChange}
                regenerate={handleRegenerate}
              />
            )}
          </FullWidth>
        </ContentWrapper>
      </MainGrid>
      {bioData && (
        <BioVersionDrawer
          isOpen={isVersionDrawerOpen}
          setIsOpen={setIsVersionDrawerOpen}
          bio={bioData}
        />
      )}
      {isSettingsDrawerOpen && (
        <BioSettingsDrawer
          isOpen={isSettingsDrawerOpen}
          setIsOpen={setIsSettingsDrawerOpen}
        >
          <CreateBioForm
            compact
            payload={payload}
            isDraft={isDraft}
            regenerate={handleRegenerate}
          />
        </BioSettingsDrawer>
      )}
      <ContentRegenerationModal isOpen={isGenerateLoading} />
    </ContentLayout>
  );
};

export default BioPage;
