import { FC, useEffect, useMemo, useState } from 'react';
import { Section } from '../../DigDeeperSlideout.styles';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Accordion from 'app/components/Accordion/Accordion';
import ArticleTabs from './ArticleTabs';
import ArticleListItem from '../ArticleListItem';
import { IEpisode, ILatestArticle } from 'app/api.types';
import EpisodeListItem from '../EpisodeListItem';
import { localStorageEnum } from 'types';
import ArticlesSkeleton from 'app/components/UI/LoadingSkeletons/DigDeeper/ArticlesSkeleton';

interface IArticlesSectionProps {
  articles:
    | IEpisode[]
    | {
        latestArticles: ILatestArticle[];
        relevantArticles: ILatestArticle[];
      }
    | undefined;
  isArticlesFetching: boolean;
  isPodcast: boolean;
  openExploreArticles: (exploreRelevantArticles: boolean) => void;
  hasPrediction?: boolean;
}

export enum ArticlesTab {
  RECENT = 'Most Recent',
  RELEVANT = 'Most Relevant',
}

const ArticlesSection: FC<IArticlesSectionProps> = ({
  isPodcast,
  openExploreArticles,
  articles,
  hasPrediction,
  isArticlesFetching,
}) => {
  const hasRelevantArticles = useMemo(() => {
    if (
      !isPodcast &&
      (articles as { relevantArticles: ILatestArticle[] }).relevantArticles &&
      (articles as { relevantArticles: ILatestArticle[] }).relevantArticles
        .length > 0
    ) {
      return true;
    }

    return false;
  }, [isPodcast, articles]);

  const showRelevantArticles = useMemo(() => {
    return hasPrediction && hasRelevantArticles;
  }, [hasRelevantArticles, hasPrediction]);

  const [articlesTab, setArticlesTab] = useState<ArticlesTab>(
    showRelevantArticles ? ArticlesTab.RELEVANT : ArticlesTab.RECENT,
  );

  useEffect(() => {
    if (showRelevantArticles) {
      setArticlesTab(ArticlesTab.RELEVANT);
    }
  }, [showRelevantArticles]);

  const handleTabChange = (articleTab: ArticlesTab) => {
    setArticlesTab(articleTab);
  };

  const shouldDisplayRelevancyScores = useMemo(() => {
    return (
      localStorage.getItem(localStorageEnum.digDeeperSimilarArticlesScore) ===
      'true'
    );
  }, []);

  const renderedArticles = useMemo(() => {
    if (isPodcast) return (articles as IEpisode[]).slice(0, 10);

    if (articlesTab === ArticlesTab.RELEVANT) {
      return ((
        articles as {
          relevantArticles: ILatestArticle[];
        }
      )?.relevantArticles).slice(0, 10);
    }

    return (
      articles as { latestArticles: ILatestArticle[] }
    ).latestArticles.slice(0, 10);
  }, [isPodcast, articles, articlesTab]);

  const sectionTitle = isArticlesFetching
    ? isPodcast
      ? 'Episodes'
      : 'Articles'
    : '';

  if (isArticlesFetching) {
    return <ArticlesSkeleton numberOfArticles={2} />;
  }

  return (
    <Section>
      <Accordion
        title={sectionTitle}
        renderTitle={
          Boolean(sectionTitle)
            ? undefined
            : () => {
                if (isPodcast && isArticlesFetching) return undefined;
                if (!isPodcast && isArticlesFetching) return undefined;
                return (
                  <ArticleTabs
                    activeTab={articlesTab}
                    onClick={handleTabChange}
                    hasRelevantArticles={hasRelevantArticles}
                  />
                );
              }
        }
        defaultExpanded
      >
        {isPodcast
          ? (renderedArticles as IEpisode[]).map(episode => (
              <EpisodeListItem episode={episode} key={episode.title} />
            ))
          : (renderedArticles as ILatestArticle[])
              .slice(0, 10)
              .map(article => (
                <ArticleListItem
                  article={article}
                  key={article.id}
                  borderBottom
                  showRelevancyScore={shouldDisplayRelevancyScores}
                />
              ))}
        <FlexBlock
          justifyContent="center"
          alignItems="center"
          margin="20px 0 0 0"
        >
          <Button
            variant="secondary"
            compact
            onClick={() =>
              openExploreArticles(articlesTab === ArticlesTab.RELEVANT)
            }
          >
            Explore {isPodcast ? 'Episodes' : 'Articles'}
          </Button>
        </FlexBlock>
      </Accordion>
    </Section>
  );
};

export default ArticlesSection;
