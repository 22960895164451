import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ open: boolean; $isCompact: boolean }>(
  ({ theme, open, $isCompact }) => css`
    position: fixed;
    top: ${$isCompact ? '149px' : '72px'};
    width: ${$isCompact ? '390px' : '550px'};
    height: calc(100vh - ${$isCompact ? '149px' : '72px'});
    right: 0;
    background: #fff;
    z-index: 50;
    border-left: 1px solid ${theme.colors.smoke};
    transition: 0.12s all ease-in-out;
    transform: translateX(${open ? '0%' : '100%'});
    overflow-y: scroll;
    transition-delay: ${open ? 0 : 0.1}s;
    ${{ $isCompact } &&
    css`
      box-shadow: -2px 0px 24px 0px rgba(0, 0, 0, 0.15);
    `}
  `,
);

export const SideBarFooter = styled.div`
  background-color: ${props => props.theme.colors.haze};
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  align-items: center;
  padding: 14px 24px;
`;
