import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import Input from 'app/components/UI/Input/Input';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import * as S from 'app/containers/Byline/pages/CreateOrEditByline/CreateOrEditBylinePage.styles';

import Button from 'app/components/UI/Button/Button';
import { ToneSelectDropDown } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import { TONE_SELECT_OPTIONS } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import { getWordCount } from 'utils/helpers';
import { useGetGeneratedBylineMutation } from 'app/api';
import useLimitExceededPopup from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/hooks/useLimitExceededPopup';
import { IBylineGenerationPayload } from 'app/api.types';
import { showCustomToast, showErrorToast } from 'utils/toast';
import { MIN_BYLINE_CONTENT_WORD_LENGTH } from '../CreateOrEditBylinePage.constants';
import { BylineGenerationSuccessToast } from '../../BylinePage/parts/BylineGenerationSuccessToast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { useHistory, useParams } from 'react-router-dom';
import { getToneObjectByValue } from '../../BylinePage/BylinePage.helpers';
import { useBylineGenerationLimits } from '../../BylinePage/hooks/useBylineGenerationLimits';
import FormSection from './FormSection';
import { RouteConstants } from 'app/routes';
import BylineRegenerationModal from '../../BylinePage/parts/BylineRegenerationModal';

interface ICreateBioForm {
  compact?: boolean;
  payload?: IBylineGenerationPayload;

  regenerate?: ({
    modifiedPayload,
    tone,
  }: {
    modifiedPayload?: IBylineGenerationPayload;
    tone?: string;
  }) => void;
}

const CreateBylineForm: FC<ICreateBioForm> = ({
  compact = false,
  payload,
  regenerate,
}) => {
  const params: {
    tenantId: string;
    projectId: string;
  } = useParams();

  const [tenantId, projectId] = useMemo(
    () => [Number(params.tenantId), Number(params.projectId)],
    [params],
  );

  const history = useHistory();

  const [bylineContent, setBylineContent] = useState(
    payload?.bylineTopic || '',
  );
  const [tone, setTone] = useState(
    payload?.tone ? getToneObjectByValue(payload.tone) : TONE_SELECT_OPTIONS[0],
  );
  const [keywords, setKeywords] = useState(payload?.productKeywords || '');

  const [isValidBylineContentLength, setIsValidBylineContentLength] = useState<
    number | null
  >(null);

  const validateBylineContentLength = () => {
    const wordCount = getWordCount(bylineContent || '');
    if (wordCount < MIN_BYLINE_CONTENT_WORD_LENGTH) {
      setIsValidBylineContentLength(MIN_BYLINE_CONTENT_WORD_LENGTH - wordCount);
    }
  };

  const handleBylineContentChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setBylineContent(event.target.value);
    if (isValidBylineContentLength !== null)
      setIsValidBylineContentLength(null);
  };

  const [generateByline, { isLoading: isGenerating }] =
    useGetGeneratedBylineMutation();

  const limitExceededPopup = useLimitExceededPopup();

  const { bylineGenerationsLeft, refresh: refreshLimits } =
    useBylineGenerationLimits();

  const canGenerate =
    !isGenerating && isValidBylineContentLength === null && bylineContent;

  const handleGenerate = async () => {
    if (!bylineContent) return;

    if (bylineGenerationsLeft === 0) {
      limitExceededPopup.show();
      return;
    }

    const generationPayload: IBylineGenerationPayload = {
      bylineTopic: bylineContent,
      productKeywords: keywords || undefined,
      tone: tone.value,
    };

    try {
      const generatedByline = await generateByline(generationPayload).unwrap();

      showCustomToast(
        <BylineGenerationSuccessToast
          currentLimit={bylineGenerationsLeft - 1}
          message="Byline Generated"
        />,
        'toast-violet',
      );

      refreshLimits();

      history.push({
        pathname: RouteConstants.byline.makeCreateDraftBylineUrl(
          tenantId,
          projectId,
        ),
        state: {
          generatedResponse: generatedByline,
          generationPayload: generationPayload,
        },
      });
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Failed to generate Byline',
      );
    }
  };

  const handleRegenerate = () => {
    if (!bylineContent || !regenerate) return;

    const regenerationPayload: IBylineGenerationPayload = {
      bylineTopic: bylineContent,
      productKeywords: keywords || undefined,
      tone: tone.value,
    };

    regenerate({ modifiedPayload: regenerationPayload });
  };

  return (
    <>
      <FormSection
        index={1}
        title="Topic"
        subtitle="What is your byline about?"
        compact={compact}
      >
        <S.ContentInputWrapper>
          <S.NativeInputBox
            placeholder={`Please enter at least ${MIN_BYLINE_CONTENT_WORD_LENGTH} words…`}
            value={bylineContent}
            onChange={handleBylineContentChange}
            onBlur={() => validateBylineContentLength()}
            style={
              isValidBylineContentLength !== null
                ? { border: `1px solid red` }
                : undefined
            }
            minRows={10}
            maxRows={20}
          />
          {isValidBylineContentLength !== null && (
            <FlexBlock justifyContent="left" padding="8px">
              <Typography.Text
                $colorName="redOrange"
                $size={16}
                $lineHeight={24}
              >
                Please enter{' '}
                <Typography.Text $bold $colorName="redOrange">
                  {isValidBylineContentLength}
                </Typography.Text>{' '}
                more word{isValidBylineContentLength > 1 && 's'}
              </Typography.Text>
            </FlexBlock>
          )}
        </S.ContentInputWrapper>
      </FormSection>
      <Gap size={30} />
      <FormSection
        index={2}
        title="Tone"
        subtitle="What would you like your byline’s tone to be?"
        compact={compact}
      >
        <S.FullWidth>
          <ToneSelectDropDown
            value={tone}
            onChange={setTone}
            selectComponentWidth="100%"
            height="56px"
          />
        </S.FullWidth>
      </FormSection>
      <Gap size={30} />
      <FormSection
        index={3}
        title="Optional Details"
        subtitle="What do you want to highlight?"
        compact={compact}
      >
        <Input
          value={keywords}
          onChange={e => setKeywords(e.target.value)}
          placeholder="e.g. Statistic, Product, Keywords, etc."
        />
      </FormSection>
      <Gap size={24} />
      <S.FullWidth>
        <Button
          onClick={compact ? handleRegenerate : handleGenerate}
          disabled={!canGenerate}
        >
          <Typography.Text
            $dmSans
            $bold
            $size={18}
            $lineHeight={24}
            $colorName="white"
          >
            {compact ? 'Save & Regenerate' : 'Generate Byline'}
          </Typography.Text>
        </Button>
      </S.FullWidth>
      {!compact && (
        <BylineRegenerationModal isOpen={isGenerating} regenerating={false} />
      )}
    </>
  );
};

export default CreateBylineForm;
