import { createContext, FC } from 'react';

interface IActionPasserContext {
  action: (arg?: any) => any;
}

const ActionPasserContext = createContext<IActionPasserContext>({
  action: arg => {},
});

interface IProviderProps extends IActionPasserContext {}

export const ActionPasserProvider: FC<IProviderProps> = ({
  children,
  action,
}) => {
  return (
    <ActionPasserContext.Provider value={{ action }}>
      {children}
    </ActionPasserContext.Provider>
  );
};

export const ActionPasserConsumer = ActionPasserContext.Consumer;
