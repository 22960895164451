import { FC } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import styled from 'styled-components';

export const commonAuthorTooltipProps = {
  'data-place': 'top',
  'data-type': 'light',
  'data-effect': 'solid',
  'data-padding': '16px',
  'data-class': 'top-light-bordered-tooltip',
  'data-border-color': '#BCBCC1',
  'data-border': 'true',
  'data-z-index': '100',
};

const orbitTopTooltipProps = {
  'data-class': 'orbit-tooltip',
  'data-place': 'top',
  'data-effect': 'solid',
  'data-background-color': '#ebf2fb',
  'data-padding': '16px',
};

export const getOrbitTooltipProps = (dataTip: string) => {
  return {
    'data-tip': dataTip,
    ...orbitTopTooltipProps,
  } as TooltipProps;
};

export const getAveragePercentInterestOfResultsTooltipProps = (
  categoryName: string,
) => {
  return {
    'data-tip': categoryName,
    'data-for': 'averagePercentInterestOfResultsTooltip',
    ...orbitTopTooltipProps,
  } as TooltipProps;
};

export const upgradePlanTooltipProps = {
  'data-tip': 'Upgrade to our Pro Plan for access to email addresses',
  'data-for': 'upgradePlanTooltip',
  ...commonAuthorTooltipProps,
} as TooltipProps;

const StyledTooltip = styled.div`
  .top-light-bordered-tooltip {
    z-index: 1000;
    border-radius: 8px !important;
    box-shadow: 0 2px 16px 0 rgba(12, 12, 13, 0.1) !important;
    &:after,
    &:before {
      display: none !important;
    }
    &.show {
      opacity: 1 !important;
    }
  }

  .orbit-tooltip {
    z-index: 100 !important;
    box-shadow: 4px 4px 4px 0px #00000026 !important;
    border-radius: 4px !important;
    color: ${({ theme }) => theme.colors.orbit};
    &.show {
      opacity: 1 !important;
    }
  }
`;

interface IProps extends TooltipProps {}

const Tooltip: FC<IProps> = props => {
  return (
    <StyledTooltip>
      <ReactTooltip clickable={true} {...props} />
    </StyledTooltip>
  );
};

export default Tooltip;
