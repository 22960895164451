import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  flex: 1;
  max-height: 100%;
`;

export const PitchContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  //padding: 32px 40px;
  overflow-y: auto;
  height: auto;
`;

export const Header = styled.div<{
  padding?: string;
}>(
  ({ theme, padding = '12px 200px' }) => css`
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${padding};
    border-bottom: 1px solid ${theme.colors.smoke};
    grid-column-start: 1;
    grid-column-end: -1;
    height: auto;
    z-index: 49;
    background: white;
  `,
);

export const InputBox = styled(TextareaAutosize)<{
  textStyle?: 'title' | 'subTitle' | 'body';
  $noBorder?: boolean;
}>(
  ({ theme, textStyle = 'body', disabled, $noBorder }) => css`
    font-size: 16px;
    font-family: ${theme.fonts.inter};
    line-height: 24px;
    color: ${theme.colors.nero};

    ${textStyle === 'title'
      ? css`
          font-family: ${theme.fonts.dmSans};
          font-size: 22px;
          line-height: 24px;
          color: ${theme.colors.onyx};
          font-weight: bold;
        `
      : ''}

    ${textStyle === 'subTitle'
      ? css`
          font-family: ${theme.fonts.dmSans};
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: ${theme.colors.nero};
        `
      : ''}

    ${$noBorder
      ? css`
          box-sizing: border-box;
          border: 1px solid transparent;
        `
      : css`
          border: 1px solid #dadee1;
        `}
      
    resize: none;

    padding: 16.5px;
    border-radius: 10px;
    margin: 4px 0;
    transition: 0.25s border ease-in-out, 0.25s box-shadow ease-in-out;
    min-height: 56px;
    width: 100%;
    background-color: transparent;

    ${!disabled
      ? css`
          &:hover {
            background-color: #fff;
            border: 1px solid ${theme.colors.smoke};
            box-shadow: 0 0 4px rgba(12, 12, 13, 0.05);
          }

          &:focus {
            background-color: #fff;
            border: 1px solid ${theme.colors.orbit};
            outline: none;
            box-shadow: 0 0 4px rgba(12, 12, 13, 0.05);
          }
        `
      : css`
          cursor: default !important;
        `}
  `,
);

export const NativeInputBox = styled(InputBox).attrs({
  as: 'textarea',
})`
  flex: 1;
  z-index: 2;
`;

export const WordCountPill = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.haze};
    border-radius: 20px;
    font-family: ${theme.fonts.inter};
    color: ${theme.colors.onyx};
    font-size: 14px;
    line-height: 16px;
    padding: 10px 12px;

    margin: 16px 0;
    align-self: flex-start;
    opacity: 0;
    transform: translateY(-12px);
    transition: 0.125s ease-in-out;
    z-index: 1;
  `,
);

export const BodyWrapper = styled.div`
  //height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  &:focus-within {
    ${WordCountPill} {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 11px;
  grid-row-gap: 0px;
  margin: 24px auto 104px auto;
  width: 100%;
  padding: 0 50px;
`;

export const GridContent = styled.div<{
  $borderOrbit?: boolean;
}>(
  ({ theme, $borderOrbit }) => css`
    display: flex;
    flex-direction: column;
    border: 1px solid ${$borderOrbit ? theme.colors.orbit : theme.colors.smoke};
    border-radius: 8px;
    background-color: #ffffff;
    padding: 16px;
  `,
);

export const ContentPill = styled.div`
  height: 40px;
  width: fit-content;
  border-radius: 20px;
  background-color: rgba(29, 118, 222, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const FullWidth = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`;
