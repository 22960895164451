import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import RoundedPill from 'app/components/UI/RoundedPill';
import { FC } from 'react';

interface IProps {
  displayName: string;
  count: number;
}

export const ContentCount: FC<IProps> = ({ displayName, count }) => {
  return (
    <FlexBlock
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography.Text hideOverflow overflowWidth="200px" title={'Pitch'}>
        {displayName}
      </Typography.Text>
      <RoundedPill variant={count < 1 ? 'haze' : 'orbit'}>
        <Typography.Text
          $colorName={count < 1 ? 'aluminum' : 'orbit'}
          $size={14}
          $lineHeight={16}
        >
          {count}
        </Typography.Text>
      </RoundedPill>
    </FlexBlock>
  );
};
