import { SVGProps } from 'react';

const GearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#3F3F42" transform="translate(-1868 -188)">
        <g transform="translate(260 72)">
          <g transform="translate(1572 80)">
            <g transform="translate(24 24)">
              <g transform="translate(12 12)">
                <path d="M15.425 6.404a.539.539 0 00-.267-.357l-1.613-.887a6.21 6.21 0 00-.291-.502l.03-1.83a.536.536 0 00-.177-.407A7.555 7.555 0 0010.318.827a.547.547 0 00-.444.05l-1.582.942a6.29 6.29 0 00-.584 0L6.124.876A.547.547 0 005.68.826a7.556 7.556 0 00-2.787 1.597.536.536 0 00-.178.407l.031 1.829c-.105.162-.203.33-.292.501L.84 6.05a.538.538 0 00-.267.356 7.411 7.411 0 00.002 3.191c.033.152.13.281.267.357l1.613.887c.089.172.187.34.291.502l-.03 1.83a.536.536 0 00.177.407 7.555 7.555 0 002.789 1.594c.149.047.31.029.444-.05l1.582-.942c.195.01.39.01.584 0l1.584.942c.134.08.296.099.444.051a7.556 7.556 0 002.787-1.597.536.536 0 00.178-.407l-.031-1.829c.105-.162.203-.33.292-.501l1.614-.889a.538.538 0 00.267-.356 7.411 7.411 0 00-.002-3.191zM10.985 8c0 1.634-1.337 2.96-2.985 2.96S5.015 9.633 5.015 8 6.352 5.04 8 5.04c1.648.003 2.983 1.326 2.985 2.96z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GearIcon;
