import styled from 'styled-components';

export const URLInput = styled.input`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  padding: 16.5px 16px;

  &:focus {
    border: 1px solid black;
    outline: none;
  }
`;

export const CollapsableWrapper = styled.div<{ $isHidden: boolean }>`
  display: ${({ $isHidden }) => ($isHidden ? 'flex' : 'none')};
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.smoke};
  margin-bottom: 2em;
`;
