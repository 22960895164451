import UploadCloudIcon from 'app/assets/icons/UploadCloudIcon';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  Container,
  ImageContainer,
  LoadImageContainer,
  LoadedImage,
} from './ImageDropzone.style';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import Typography from '../Typography';
import LoadingProgressCircle from './LoadingProgressCircle';

interface IImageDropzoneProps {
  image: ImageURL | ImageFile | null;
  handleImageLoad: (image: ImageFile | null) => void;
}

export type ImageURL = { type: 'URL'; content: string };
export type ImageFile = { type: 'File'; content: File };

const ImageDropzone: FC<IImageDropzoneProps> = ({ image, handleImageLoad }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const [imageBuffer, setImageBuffer] = useState<
    string | ArrayBuffer | undefined
  >(undefined);

  const readImageFile = useCallback((file: File) => {
    const onReadFinished = (errorMessage?: string) => {
      if (errorMessage) {
        showErrorToast(errorMessage);
      } else {
        showSuccessToast('Image loaded.');
      }

      setIsLoadingImage(false);
      setIsDraggingOver(false);
      setLoadingProgress(0);
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onabort = () => onReadFinished('Image reading was aborted');
    reader.onerror = () => onReadFinished('Image reading has failed');
    reader.onprogress = (progress: ProgressEvent<FileReader>) => {
      setLoadingProgress((progress.loaded / progress.total) * 100);
    };
    reader.onloadstart = () => setIsLoadingImage(true);
    reader.onload = () => {
      const imageBase64 = reader.result;
      if (imageBase64) {
        setImageBuffer(imageBase64);
      }
      onReadFinished();
    };
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) => {
        handleImageLoad({ type: 'File', content: file });
      });
    },
    [handleImageLoad],
  );

  useEffect(() => {
    if (image !== null) {
      if (image.type === 'File') {
        readImageFile(image.content);
      }

      if (image.type === 'URL') {
        setImageBuffer(image.content);
      }
    }
  }, [image, readImageFile]);

  const loadedImage = useMemo(() => {
    const handleMissingImage = () => {
      setImageBuffer(undefined);
      showErrorToast('Image not found');
    };

    if (imageBuffer) {
      return (
        <LoadedImage
          src={imageBuffer as string}
          onError={handleMissingImage}
          onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
            e.stopPropagation();
            setImageBuffer(undefined);
            handleImageLoad(null);
          }}
          alt="Project Logo"
        />
      );
    }
  }, [imageBuffer, handleImageLoad]);

  return (
    <Dropzone
      multiple={false}
      accept={{
        'image/png': ['.png', '.jpg', '.jpeg'],
        'image/jpg': ['.png', '.jpg', '.jpeg'],
        'image/jpeg': ['.png', '.jpg', '.jpeg'],
      }}
      onDrop={onDrop}
      onDropRejected={() => {
        showErrorToast(
          'Image format is not supported. Please use .png, .jpg or .jpeg format',
          'Invalid file',
        );
        setIsDraggingOver(false);
      }}
      onDragEnter={() => setIsDraggingOver(true)}
      onDragLeave={() => setIsDraggingOver(false)}
    >
      {({ getRootProps, getInputProps }) => (
        <Container
          {...getRootProps()}
          $hoverMode={isDraggingOver || isLoadingImage}
        >
          <input {...getInputProps()} />

          {loadedImage && !isLoadingImage ? (
            <ImageContainer>{loadedImage}</ImageContainer>
          ) : (
            <LoadImageContainer $active={isLoadingImage}>
              {isLoadingImage ? (
                <LoadingProgressCircle
                  radius={42}
                  strokeWidth={10}
                  progress={loadingProgress}
                  stroke={'#1847AD'}
                  activeStroke={'#1D76DE'}
                />
              ) : (
                <UploadCloudIcon hoverMode={isDraggingOver} />
              )}

              {!isDraggingOver && !isLoadingImage && (
                <Typography.Text
                  $bold
                  $size={14}
                  $lineHeight={20}
                  $colorName="steel"
                  textAlign="center"
                  $padding="4px"
                >
                  Drag & Drop
                </Typography.Text>
              )}
            </LoadImageContainer>
          )}
        </Container>
      )}
    </Dropzone>
  );
};

export default ImageDropzone;
