import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';

const BylineHeader = () => {
  return (
    <FlexBlock flexDirection="column" alignItems="left" minWidth="100%">
      <Typography.Text
        $dmSans
        $bold
        $size={32}
        $lineHeight={40}
        $colorName="onyx"
      >
        Byline
      </Typography.Text>
      <Typography.Text $colorName="steel" $size={16} $lineHeight={24}>
        Fill in the required fields below and Taylor will generate your content.
      </Typography.Text>
    </FlexBlock>
  );
};

export default BylineHeader;
