import React from 'react';

function CoverIcon({ fill }: { fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill || '#0C0C0D'} transform="translate(-1392 -300)">
          <g transform="translate(260 72)">
            <g transform="translate(40 176)">
              <g transform="translate(24 24)">
                <g transform="translate(1052 16)">
                  <g transform="translate(16 12.857)">
                    <path d="M5.75.4C3.68.4 2 1.761 2 3.44v9.12c0 1.679 1.679 3.04 3.75 3.04h7.5c2.071 0 3.75-1.361 3.75-3.04V3.44C17 1.761 15.321.4 13.25.4h-7.5zm7.5 6.08c0 .315-.315.57-.703.57H6.453c-.388 0-.703-.255-.703-.57 0-.315.315-.57.703-.57h6.094c.388 0 .703.255.703.57zm0 2.74c0 .315-.315.57-.703.57H6.453c-.388 0-.703-.255-.703-.57 0-.315.315-.57.703-.57h6.094c.388 0 .703.255.703.57zM6.453 4.2c.389 0 .703-.255.703-.57 0-.315-.314-.57-.703-.57-.388 0-.703.255-.703.57 0 .315.315.57.703.57z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CoverIcon;
