import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  useDeleteMediaListMembersMutation,
  useDeleteCustomTargetMediaMutation,
} from 'app/api';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getErrorText } from 'utils/error-messages';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { useJournalistsSlice } from '../../slice';
import { handleMediaListExport } from '../parts/Table/JournalistsTable.helpers';
import { ICustomTargetMediaBase } from 'app/api.types';
import {
  JOURNALIST_ROW_ACTIONS_DISPLAY_VALUES,
  MEDIA_LIST_ACTIONS_DISPLAY_VALUES,
} from '../parts/MediaListTable/MediaListTable.constants';

export const useJournalistMediaListsActions = (onDeleteSuccess: () => void) => {
  const prompt = usePromptModalContext();
  const dispatch = useAppDispatch();
  const { actions } = useJournalistsSlice();

  const openUploadMediaListModal = () => {
    dispatch(actions.toggleUploadMediaListModal(true));
  };

  const openCreateMediaListModal = () => {
    dispatch(actions.toggleCreateMediaListModal(true));
  };

  const [deleteTargetMediaLists, { isLoading: isDeletingTargetMedias }] =
    useDeleteCustomTargetMediaMutation();

  const handleMediaListsDelete = useCallback(
    (rows: number[]) => {
      if (!rows || !rows.length) {
        showErrorToast('No media lists selected');
        return;
      }

      const isPlural = rows.length > 1;

      const displayValues =
        MEDIA_LIST_ACTIONS_DISPLAY_VALUES.DELETE[
          isPlural ? 'PLURAL' : 'SINGULAR'
        ];

      return prompt.createModal({
        title: displayValues.title,
        description: displayValues.description,
        okButtonText: displayValues.confirmText,
        cancelButtonText: displayValues.cancelText,
        okButtonDanger: true,
        okButtonCallback: async () => {
          try {
            await deleteTargetMediaLists({
              mediaListsIds: rows,
            }).unwrap();
            onDeleteSuccess();
            showSuccessToast(displayValues.successMessage);
          } catch (e) {
            showErrorToast(
              getErrorText(e as FetchBaseQueryError),
              displayValues.errorMessage,
            );
          }
        },
      });
    },
    [prompt, deleteTargetMediaLists, onDeleteSuccess],
  );

  return {
    handleDelete: handleMediaListsDelete,
    isDeleting: isDeletingTargetMedias,
    openCreateMediaListModal,
    openUploadMediaListModal,
  };
};

export const useJournalistRowActions = () => {
  const dispatch = useAppDispatch();
  const { actions } = useJournalistsSlice();
  const prompt = usePromptModalContext();

  const [
    deleteMediaEntries,
    { isLoading: isDeletingRows, isError: isDeleteRowsError },
  ] = useDeleteMediaListMembersMutation();

  const handleMediaListEntriesDelete = useCallback(
    (rows: number[], mediaListId: number) => {
      const isPlural = rows.length > 1;

      const displayValues =
        JOURNALIST_ROW_ACTIONS_DISPLAY_VALUES.DELETE[
          isPlural ? 'PLURAL' : 'SINGULAR'
        ];

      return prompt.createModal({
        title: displayValues.title,
        description: displayValues.description,
        okButtonText: displayValues.confirmText,
        cancelButtonText: displayValues.cancelText,
        okButtonDanger: true,
        okButtonCallback: async () => {
          try {
            deleteMediaEntries({
              mediaListId: mediaListId,
              memberIds: rows,
            }).unwrap();
            showSuccessToast(displayValues.successMessage);
          } catch (error) {
            showErrorToast(
              getErrorText(error as FetchBaseQueryError),
              displayValues.errorMessage,
            );
          }
        },
      });
    },
    [deleteMediaEntries, prompt],
  );

  const handleMediaListEntriesCopy = useCallback(
    (rowIds: number[], source: ICustomTargetMediaBase) => {
      dispatch(
        actions.toggleCopyMediaListEntriesModal({
          ids: rowIds,
          source,
        }),
      );
    },
    [dispatch, actions],
  );

  const handleMediaListEntriesMove = useCallback(
    (rowIds: number[], source: ICustomTargetMediaBase) => {
      dispatch(
        actions.toggleMoveMediaListEntriesModal({
          ids: rowIds,
          source,
        }),
      );
    },
    [dispatch, actions],
  );

  const handleMediaListEntriesExport = useCallback((rowIds: string[]) => {
    handleMediaListExport(rowIds);
  }, []);

  return {
    handleDelete: handleMediaListEntriesDelete,
    handleMove: handleMediaListEntriesMove,
    handleCopy: handleMediaListEntriesCopy,
    handleExport: handleMediaListEntriesExport,
    isDeletingRows,
    isDeleteRowsError,
  };
};
