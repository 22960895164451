import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Modal from 'app/components/UI/Modal/Modal';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';
import { CloseIcon } from 'app/components/UI/CloseIcon';
import ImageDropzone, {
  ImageFile,
  ImageURL,
} from 'app/components/FileDropzone/ImageDropzone';
import {
  ProjectInput,
  Section,
  SectionFlexEnd,
  Header,
  AddLogoAnchor,
} from './AddNewProjectModal.styles';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { clearBitLogo, uploadLogoResource } from 'utils/helpers';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectActiveTenantId } from 'app/containers/Global/slice/selectors';
import {
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useUpdateProjectMutation,
} from 'app/api';
import { RouteConstants } from 'app/routes';
import { getErrorText } from 'utils/error-messages';
import ClickAwayListener from 'react-click-away-listener';
import { Spinner } from 'app/components/UI/Spinner';

const isValidUrl = (url: string) => {
  let urlToValidate = url;

  try {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      urlToValidate = `https://${url}`;
    }

    const newUrl = new URL(urlToValidate);
    return (
      (newUrl.protocol === 'http:' || newUrl.protocol === 'https:') &&
      newUrl.hostname.includes('.')
    );
  } catch {
    return false;
  }
};

interface IProps {
  isOpen: boolean | number;
  setIsOpen: Dispatch<SetStateAction<boolean | number>>;
}

const AddNewProjectModal: FC<IProps> = ({ isOpen, setIsOpen }) => {
  const projectId = typeof isOpen === 'boolean' ? undefined : isOpen;

  const { data: project, isLoading: isProjectLoading } = useGetProjectByIdQuery(
    {
      projectId,
    },
    {
      skip: !projectId,
    },
  );

  const [imageResource, setImageResource] = useState<
    ImageURL | ImageFile | null
  >(null);

  const [projectName, setProjectName] = useState<string>('');
  const [projectUrl, setProjectUrl] = useState<string>('');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const activeTenantId = useAppSelector(selectActiveTenantId);
  const history = useHistory();

  const [createProject, { isLoading: isCreateLoading }] =
    useCreateProjectMutation();

  const [updateProject, { isLoading: isUpdateLoading }] =
    useUpdateProjectMutation();

  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);

  const isProjectNameValid = useMemo(() => {
    if (projectName.length < 3) {
      return false;
    }

    return true;
  }, [projectName]);

  const isProjectUrlValid = useMemo(() => {
    if (projectUrl) {
      const validUrl = isValidUrl(projectUrl);
      if (!validUrl) {
        return false;
      }
    }

    return true;
  }, [projectUrl]);

  const [projectNameInputError, setProjectNameInputError] = useState<
    string[] | undefined
  >(undefined);

  const [projectUrlInputError, setProjectUrlInputError] = useState<
    string[] | undefined
  >(undefined);

  const canSubmit = useMemo(() => {
    const hasRequiredFields = projectName !== '';
    const hasFieldErrors =
      projectNameInputError !== undefined || projectUrlInputError !== undefined;

    if (!projectId) {
      return hasRequiredFields && !hasFieldErrors;
    }

    if (projectId && project) {
      const hasUpdatedFields = projectName !== project.name;
      const hasUpdatedLogo =
        imageResource?.type === 'File' ||
        (imageResource?.type === 'URL' &&
          imageResource.content !== project.logo);

      return (
        hasRequiredFields &&
        !hasFieldErrors &&
        (hasUpdatedFields || hasUpdatedLogo)
      );
    }
  }, [
    projectId,
    project,
    projectName,
    projectNameInputError,
    projectUrlInputError,
    imageResource,
  ]);

  // Load existing project data if edit mode
  useEffect(() => {
    if (project) {
      if (project.logo) {
        setImageResource({ type: 'URL', content: project.logo });
      }

      if (project.name) {
        setProjectName(project.name);
      }
    }
  }, [project]);

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> =
    async event => {
      if (event.target.files && event.target.files[0]) {
        if (!event.target.files[0].type.match('image')) {
          return showErrorToast(
            'Please use .png, .jpg or .jpeg',
            'Invalid file',
          );
        }

        setImageResource({ type: 'File', content: event.target.files[0] });
      }
    };

  const handleImageFileLoad = useCallback((file: ImageFile | null) => {
    setImageResource(file);
  }, []);

  const handleSubmit = async () => {
    if (!projectName) {
      showErrorToast('Project name is required', 'Error');
      return;
    }

    if (projectUrl) {
      const validUrl = isValidUrl(projectUrl);
      if (!validUrl) {
        showErrorToast(
          'URL is not valid. Use http://your_domain.com format',
          'Error',
        );
        return;
      }
    }

    let uploadedImage = null;

    if (imageResource && imageResource.type === 'File') {
      try {
        setIsUploadingImage(true);
        uploadedImage = await uploadLogoResource(imageResource.content);
      } catch (error) {
        // To Do: Show additional error message if image was not uploaded
      } finally {
        setIsUploadingImage(false);
      }
    }

    try {
      if (!activeTenantId) return;

      if (projectId) {
        await updateProject({
          id: projectId,
          tenantId: activeTenantId,
          name: projectName,
          logo:
            uploadedImage !== null
              ? uploadedImage
              : imageResource?.type === 'URL'
              ? imageResource.content
              : undefined,
          country: 'CA',
        })
          .unwrap()
          .then(project => {
            showSuccessToast('Project Updated');
            history.push(
              RouteConstants.projects.makeContentUrl(
                activeTenantId,
                project.id,
              ),
            );
          })
          .catch(e => {
            showErrorToast(getErrorText(e), 'Error');
          });
      } else {
        await createProject({
          tenantId: activeTenantId,
          name: projectName,
          logo:
            uploadedImage !== null
              ? uploadedImage
              : imageResource?.type === 'URL'
              ? imageResource.content
              : undefined,
          country: 'CA',
        })
          .unwrap()
          .then(project => {
            showSuccessToast('Project Created');
            history.push(
              RouteConstants.projects.makeContentUrl(
                activeTenantId,
                project.id,
              ),
            );
          })
          .catch(e => {
            if (e?.data?.message?.includes('Project already exists')) {
              showErrorToast(
                `Project name "${projectName}" already exists`,
                'Error',
              );
            } else {
              showErrorToast(getErrorText(e), 'Error');
            }
          });
      }
    } catch (error) {
      showErrorToast(
        `Something went wrong. Could not ${
          projectId ? 'edit' : 'create'
        } project.`,
      );
    }
  };

  const handleProjectLogoFromUrl = () => {
    if (projectUrl) {
      const logo = clearBitLogo(projectUrl);
      if (logo) {
        setImageResource({ type: 'URL', content: logo });
      }
    }
  };

  const handleProjectUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProjectUrl(e.target.value);

    if (e.target.value === '') {
      setProjectUrlInputError(undefined);
    }
  };

  const handleProjectNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);

    if (e.target.value === '') {
      setProjectNameInputError(undefined);
    }
  };

  useEffect(() => {
    const handleEscapeKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setIsOpen(false);
    };

    window.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [setIsOpen]);

  return (
    <Modal open={Boolean(isOpen)} $maxWidth="512px">
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <FlexBlock
          flexDirection="column"
          alignItems="center"
          style={{ background: 'white' }}
        >
          <Header>
            <Typography.Text
              $colorName="onyx"
              $size={18}
              $lineHeight={32}
              $dmSans
              $bold
            >
              {projectId ? 'Edit' : 'Create'} a Project Folder
              {isProjectLoading && (
                <Spinner $size="16px" $isGray $margin="0 10px" />
              )}
            </Typography.Text>
            <FlexBlock style={{ alignSelf: 'flex-start', paddingTop: '16px' }}>
              <CloseIcon size={16} onClick={() => setIsOpen(false)} />
            </FlexBlock>
          </Header>
          <FlexBlock flexDirection="column" minWidth="100%" padding="16px">
            <Typography.Text
              $colorName="onyx"
              $dmSans
              $bold
              $size={16}
              $lineHeight={24}
            >
              Upload a Logotype
            </Typography.Text>
            <Typography.Text $colorName="steel" $size={14} $lineHeight={20}>
              Max. 512MB. Supported formats: .png, .jpg or .jpeg
            </Typography.Text>
            <Section>
              <ImageDropzone
                image={imageResource}
                handleImageLoad={handleImageFileLoad}
              />
              <Typography.Text $size={14} $lineHeight={20} $colorName="steel">
                or
              </Typography.Text>
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                hidden
                ref={fileInputRef}
                onChange={handleFileSelect}
              />
              <Button
                type="button"
                variant="secondary"
                compact
                onClick={() => {
                  fileInputRef?.current?.click();
                }}
              >
                Browse file
              </Button>
              <Typography.Text $size={14} $lineHeight={20} $colorName="steel">
                or
              </Typography.Text>
              <AddLogoAnchor
                onClick={handleProjectLogoFromUrl}
                $disabled={
                  !isProjectUrlValid ||
                  projectUrl === '' ||
                  imageResource?.type === 'URL'
                }
              >
                {imageResource?.type === 'URL'
                  ? "Keep using URL's logo"
                  : "Use URL's Logo"}
              </AddLogoAnchor>
            </Section>
            <Typography.Text
              $colorName="onyx"
              $dmSans
              $bold
              $size={16}
              $lineHeight={24}
            >
              Basic Information
            </Typography.Text>
            <Typography.Text
              $colorName="nero"
              $size={14}
              $lineHeight={20}
              $padding="16px 0 8px 0"
            >
              Project Name
            </Typography.Text>
            <ProjectInput
              placeholder="Enter project's name"
              onChange={handleProjectNameChange}
              value={projectName}
              onBlur={() => {
                isProjectNameValid || projectName === ''
                  ? setProjectNameInputError(undefined)
                  : setProjectNameInputError([
                      'Project name must be at least 3 characters long',
                    ]);
              }}
              errors={projectNameInputError}
              disabled={isCreateLoading || isUpdateLoading}
            />
            <Typography.Text
              $colorName="nero"
              $size={14}
              $lineHeight={20}
              $padding="16px 0 8px 0"
            >
              Project URL
            </Typography.Text>
            <ProjectInput
              placeholder="Enter project's URL"
              onChange={handleProjectUrlChange}
              value={projectUrl}
              onBlur={() => {
                isProjectUrlValid || projectUrl === ''
                  ? setProjectUrlInputError(undefined)
                  : setProjectUrlInputError(['Project URL is not valid.']);
              }}
              errors={projectUrlInputError}
              disabled={isCreateLoading || isUpdateLoading}
            />
          </FlexBlock>
          <SectionFlexEnd>
            <Button
              onClick={handleSubmit}
              variant="primary"
              compact
              isLoading={isCreateLoading || isUpdateLoading || isUploadingImage}
              disabled={
                !canSubmit ||
                isCreateLoading ||
                isUpdateLoading ||
                isUploadingImage
              }
            >
              {projectId ? 'Save' : 'Create'}
            </Button>
          </SectionFlexEnd>
        </FlexBlock>
      </ClickAwayListener>
    </Modal>
  );
};

export default AddNewProjectModal;
