import styled, { css } from 'styled-components';

export const PageWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    background-color: ${theme.colors.stratos};
    height: 100%;
    overflow: hidden;
    align-items: stretch;
  `,
);
export const HeaderContainer = styled.div`
  min-height: ${p => p.theme.layout.headerHeight};
`;
export const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const SideBarContainer = styled.div`
  min-width: 260px;
`;

export const ContentContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    /* Switched border-radius to a clip-path for content page borders because it causes a render bug on Chrome + Mac Apple Silicon devices */
    clip-path: inset(0 round 16px 16px 0 0);
  `,
);
