import styled, { css } from 'styled-components/macro';

export const PitchVersionPill = styled.div<{
  status?: 'ACTIVE' | 'ARCHIVED';
  borderRadius?: string;
}>(
  ({ status, theme, borderRadius = '14px' }) => css`
    padding: 6px 16px;
    border-radius: ${borderRadius};
    background-color: rgba(188, 188, 193, 0.15);
    font-family: ${theme.fonts.inter};
    font-size: 14px;
    color: ${theme.colors.onyx};
    display: inline;
    ${status === 'ARCHIVED'
      ? css`
          background-color: rgba(188, 188, 193, 0.15);
        `
      : ''}
    ${status === 'ACTIVE'
      ? css`
          background-color: rgba(0, 211, 119, 0.2);
        `
      : ''}
  `,
);

export const PitchStatusDot = styled.div<{
  status?: 'ACTIVE' | 'ARCHIVED' | 'DISABLED';
}>(
  ({ status, theme }) => css`
    height: 10px;
    width: 10px;
    border-radius: 5px;

    ${status === 'ARCHIVED' || status === 'DISABLED'
      ? css`
          background-color: ${theme.colors.aluminum};
        `
      : ''}
    ${status === 'ACTIVE'
      ? css`
          background-color: ${theme.colors.kermit};
        `
      : ''}
  `,
);
