import styled from 'styled-components';

export const MediaOutletAnchor = styled.button<{ $disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.orbit};
  padding: 0;
  background: none;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  margin: 3px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  &:hover {
    color: aqua;
  }
`;
