import { SVGProps } from 'react';

const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? '12'}
    height={props.height ?? '12'}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3C1 2.72386 1.22386 2.5 1.5 2.5H10.5C10.7761 2.5 11 2.72386 11 3C11 3.27614 10.7761 3.5 10.5 3.5H1.5C1.22386 3.5 1 3.27614 1 3Z"
      fill={props.fill ?? '#CA3023'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 1.5C4.86739 1.5 4.74022 1.55268 4.64645 1.64645C4.55268 1.74021 4.5 1.86739 4.5 2V3C4.5 3.27614 4.27614 3.5 4 3.5C3.72386 3.5 3.5 3.27614 3.5 3V2C3.5 1.60217 3.65804 1.22064 3.93934 0.93934C4.22064 0.658035 4.60218 0.5 5 0.5H7C7.39782 0.5 7.77936 0.658035 8.06066 0.93934C8.34196 1.22064 8.5 1.60217 8.5 2V3C8.5 3.27614 8.27614 3.5 8 3.5C7.72386 3.5 7.5 3.27614 7.5 3V2C7.5 1.86739 7.44732 1.74021 7.35355 1.64645C7.25979 1.55268 7.13261 1.5 7 1.5H5ZM2.5 2.5C2.77614 2.5 3 2.72386 3 3V10C3 10.1326 3.05268 10.2598 3.14645 10.3536C3.24021 10.4473 3.36739 10.5 3.5 10.5H8.5C8.63261 10.5 8.75978 10.4473 8.85355 10.3536C8.94732 10.2598 9 10.1326 9 10V3C9 2.72386 9.22386 2.5 9.5 2.5C9.77614 2.5 10 2.72386 10 3V10C10 10.3978 9.84196 10.7794 9.56066 11.0607C9.27936 11.342 8.89783 11.5 8.5 11.5H3.5C3.10217 11.5 2.72064 11.342 2.43934 11.0607C2.15804 10.7794 2 10.3978 2 10V3C2 2.72386 2.22386 2.5 2.5 2.5Z"
      fill={props.fill ?? '#CA3023'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5C5.27614 5 5.5 5.22386 5.5 5.5V8.5C5.5 8.77614 5.27614 9 5 9C4.72386 9 4.5 8.77614 4.5 8.5V5.5C4.5 5.22386 4.72386 5 5 5Z"
      fill={props.fill ?? '#CA3023'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5C7.27614 5 7.5 5.22386 7.5 5.5V8.5C7.5 8.77614 7.27614 9 7 9C6.72386 9 6.5 8.77614 6.5 8.5V5.5C6.5 5.22386 6.72386 5 7 5Z"
      fill={props.fill ?? '#CA3023'}
    />
  </svg>
);

export default TrashIcon;
