import { IUser } from 'app/api.types';

enum AnalyticsEventsEnum {
  LOGIN = 'User Login',
}

const Analytics = {
  identify(user: IUser) {
    const {
      firstName,
      lastName,
      email,
      id: userId,
      role: userRole,
      createdAt: accountCreatedAt,
    } = user;
    const fullName = `${firstName} ${lastName}`;

    // @ts-ignore
    analytics.identify(`prophet_${String(userId)}`, {
      databaseUserId: String(userId),
      email,
      fullName,
      userRole: userRole.code,
      accountCreatedAt,
    });
  },
  group(tenantId: string, tenantName: string) {
    // @ts-ignore
    analytics.group(tenantId, {
      name: tenantName,
    });
  },
  track: {
    login() {
      // @ts-ignore
      analytics.track(AnalyticsEventsEnum.LOGIN);
    },
  },
};

export default Analytics;
