import FlexBlock from 'app/components/UI/FlexBlock';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Toggle, ToggleCheckbox, ToggleSwitch } from '../../PeopleList.styles';
import Typography from 'app/components/Typography';
import { UserStatusEnum } from 'app/containers/Global/slice/types';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { IUser } from 'app/api.types';
import { useUpdateUserMutation } from 'app/api';
import { useAppSelector } from 'store/hooks';
import { selectUser } from 'app/containers/Global/slice/selectors';
import Tooltip, {
  getOrbitTooltipProps,
} from 'app/components/UI/Tooltip/Tooltip';

interface IUserStatusToggleProps {
  persistedUser?: IUser;
}

const UserStatusToggle: FC<IUserStatusToggleProps> = ({ persistedUser }) => {
  const [userStatus, setUserStatus] = useState<boolean | null>(null);

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const currentUser = useAppSelector(selectUser);

  const currentUserId = currentUser?.id;

  const isInspectingOwnProfile = useMemo(() => {
    if (!currentUserId || !persistedUser?.id) {
      return false;
    }

    return currentUserId === persistedUser.id;
  }, [currentUserId, persistedUser?.id]);

  const handleUserStatusChange = useCallback(async () => {
    if (persistedUser && persistedUser.id) {
      try {
        const updatedUser = await updateUser({
          id: persistedUser.id,
          status:
            persistedUser.statusType.code === UserStatusEnum.ACTIVE
              ? UserStatusEnum.DISABLED
              : UserStatusEnum.ACTIVE,
          avatar: persistedUser.avatar,
          email: persistedUser.email,
          mobilePhone: persistedUser.mobilePhone,
          firstName: persistedUser.firstName,
          lastName: persistedUser.lastName,
          role: persistedUser.role.code,
          tenantId: persistedUser.tenant?.id || undefined,
        }).unwrap();

        showSuccessToast(
          updatedUser.statusType?.code === UserStatusEnum.ACTIVE
            ? 'User activated.'
            : 'User de-activated.',
        );
      } catch (error) {
        showErrorToast(
          getErrorText(error as FetchBaseQueryError),
          'Could not update user status.',
        );
      }
    } else {
      showErrorToast('Could not update user status.');
      return;
    }
  }, [persistedUser, updateUser]);

  useEffect(() => {
    if (
      persistedUser &&
      persistedUser.statusType &&
      persistedUser.statusType.code
    ) {
      setUserStatus(persistedUser.statusType.code === UserStatusEnum.ACTIVE);
    }
  }, [persistedUser]);

  const tooltip = useMemo(() => {
    if (isInspectingOwnProfile) {
      return getOrbitTooltipProps('Cannot disable your own account');
    }

    return undefined;
  }, [isInspectingOwnProfile]);

  if (userStatus === null) {
    return <></>;
  }

  return (
    <FlexBlock flexDirection="row" alignItems="center" columnGap="10px">
      <Toggle
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ToggleCheckbox
          checked={userStatus}
          onChange={() => {
            if (isInspectingOwnProfile) return;
            if (isLoading) return;
            handleUserStatusChange();
          }}
          disabled={isInspectingOwnProfile}
        />
        <ToggleSwitch {...tooltip} />
      </Toggle>
      <Typography.Text $size={14} $lineHeight={16}>
        {userStatus ? 'Active' : 'Inactive'} User
      </Typography.Text>
      <Tooltip />
    </FlexBlock>
  );
};

export default UserStatusToggle;
