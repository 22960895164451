import { ICreateBio } from 'app/api.types';

export const getTitleFromLinkedInURL = (linkedInUrl: string) => {
  const profilePart = linkedInUrl.split('/').pop();

  return `${profilePart || 'Generated'} Bio`;
};

export const validateCreateBioPayload = (payload: ICreateBio) => {
  if (!payload.title || !payload.linkedInURL || !payload.projectId)
    return false;

  if (
    !payload.bioContentCreateBody ||
    !payload.bioContentCreateBody.longBio ||
    !payload.bioContentCreateBody.shortBio
  )
    return false;

  return true;
};
