import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const ContentInputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FullWidth = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`;

export const InputBox = styled(TextareaAutosize)<{
  textStyle?: 'title' | 'subTitle' | 'body';
  $noBorder?: boolean;
}>(
  ({ theme, textStyle = 'body', disabled, $noBorder }) => css`
    font-size: 16px;
    font-family: ${theme.fonts.inter};
    line-height: 24px;
    color: ${theme.colors.nero};

    ${textStyle === 'title'
      ? css`
          font-family: ${theme.fonts.dmSans};
          font-size: 22px;
          line-height: 24px;
          color: ${theme.colors.onyx};
          font-weight: bold;
        `
      : ''}

    ${textStyle === 'subTitle'
      ? css`
          font-family: ${theme.fonts.dmSans};
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: ${theme.colors.nero};
        `
      : ''}
  
      ${$noBorder
      ? css`
          box-sizing: border-box;
          border: 1px solid transparent;
        `
      : css`
          border: 1px solid #dadee1;
        `}
        
      resize: none;

    padding: 16.5px;
    border-radius: 10px;
    margin: 4px 0;
    transition: 0.25s border ease-in-out, 0.25s box-shadow ease-in-out;
    min-height: 56px;
    width: 100%;
    background-color: transparent;

    ${!disabled
      ? css`
          &:hover {
            background-color: #fff;
            border: 1px solid ${theme.colors.smoke};
            box-shadow: 0 0 4px rgba(12, 12, 13, 0.05);
          }

          &:focus {
            background-color: #fff;
            border: 1px solid ${theme.colors.orbit};
            outline: none;
            box-shadow: 0 0 4px rgba(12, 12, 13, 0.05);
          }
        `
      : css`
          cursor: default !important;
        `}
  `,
);

export const NativeInputBox = styled(InputBox).attrs({
  as: 'textarea',
})`
  flex: 1;
  z-index: 2;
  min-height: 195px;
`;
