import { FC, SVGProps } from 'react';

interface IProps {
  size?: number;
  color?: string;
}

const PageIconThin: FC = (props: IProps & SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 12}
    height={props.size || 12}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || '#3F3F42'}
      fillRule="evenodd"
      d="M1.94.94A1.5 1.5 0 0 1 3 .5h4a.5.5 0 0 1 .354.146l3 3A.5.5 0 0 1 10.5 4v6A1.5 1.5 0 0 1 9 11.5H3A1.5 1.5 0 0 1 1.5 10V2c0-.398.158-.78.44-1.06ZM3 1.5a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V4.207L6.793 1.5H3Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || '#3F3F42'}
      fillRule="evenodd"
      d="M7 .5a.5.5 0 0 1 .5.5v2.5H10a.5.5 0 0 1 0 1H7a.5.5 0 0 1-.5-.5V1A.5.5 0 0 1 7 .5ZM4 7.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z"
      clipRule="evenodd"
    />
  </svg>
);

export default PageIconThin;
