import {
  IApiResponse,
  IAuthorResponse,
  IEpisode,
} from '../../../../../../../api.types';
import { FC, MouseEventHandler, useMemo, useState } from 'react';
import * as S from '../DigDeeperSlideout.styles';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import Button from '../../../../../../../components/UI/Button/Button';
import ArrowLeftIcon from '../../../../../../../assets/icons/ArrowLeftIcon';
import Typography from '../../../../../../../components/Typography';
import Gap from '../../../../../../../components/UI/Gap';
import AutoComplete, {
  AutoCompleteOptionRenderFunction,
} from '../../../../../../../components/AutoComplete/AutoComplete';
import { VerticalDivider } from '../../../../../../../components/UI/Divider';
import Pill from '../../../../../../../components/UI/Pill';
import PageLoader from '../../../../../../../components/UI/PageLoader';
import ArticleListItem from './ArticleListItem';
import EpisodeListItem from './EpisodeListItem';
import { useAppSelector } from 'store/hooks';
import {
  selectDigDeepeTargetMedia,
  selectDigDeeperReportItem,
} from 'app/containers/Pitch/slice/selectors';
import { localStorageEnum } from 'types';
import { useArticlesSearch } from '../hooks/useArticlesSearch';

interface IProps {
  creatorId: string;
  creatorName: string;
  episodes?: IApiResponse<IEpisode[]>;
  onClose: () => void;
  open: boolean;
  isPodcast?: boolean;
  isRelevantArticles?: boolean;
}

interface IOption {
  name: string;
  id: number;
}

const ExploreArticles: FC<IProps> = ({
  creatorId,
  creatorName,
  episodes,
  onClose,
  open,
  isPodcast,
  isRelevantArticles = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [inclusions, setInclusions] = useState<string[]>([]);
  const [exclusions, setExclusions] = useState<string[]>([]);

  const reportItem = useAppSelector(selectDigDeeperReportItem);
  const reportTargetMedia = useAppSelector(selectDigDeepeTargetMedia);

  const { articles, suggestions } = useArticlesSearch({
    searchTerm,
    inclusions,
    exclusions,
    authorId: creatorId ? String(creatorId) : '',
    targetMediaId: reportTargetMedia?.id || '',
    reportItemId: reportItem?.id || '',
    open,
    isPodcast,
    isRelevant: isRelevantArticles,
  });

  const addToInclusions = (text: string) => {
    if (!inclusions.includes(text)) setInclusions(state => [...state, text]);
  };

  const addToExclusions = (text: string) => {
    if (!exclusions.includes(text)) setExclusions(state => [...state, text]);
  };

  const onInclusionRemove: (text: string) => MouseEventHandler =
    (text: string) => ev => {
      ev.stopPropagation();
      ev.preventDefault();
      setInclusions(state => state.filter(i => i !== text));
    };

  const onExclusionRemove: (text: string) => MouseEventHandler =
    (text: string) => ev => {
      ev.stopPropagation();
      ev.preventDefault();
      setExclusions(state => state.filter(e => e !== text));
    };

  const options: IOption[] = useMemo(() => {
    if (!suggestions.data) return [];
    return suggestions.data
      .filter(opt => !inclusions.includes(opt) && !exclusions.includes(opt))
      .map((name, id) => ({ name, id }));
  }, [suggestions, inclusions, exclusions]);

  const shouldDisplayRelevancyScores = useMemo(() => {
    return (
      localStorage.getItem(localStorageEnum.digDeeperSimilarArticlesScore) ===
      'true'
    );
  }, []);

  const articlesToRender = useMemo(() => {
    return articles?.data?.content || [];
  }, [articles]);

  const renderAutocompleteOption: AutoCompleteOptionRenderFunction<IOption> =
    item => {
      return (
        <FlexBlock justifyContent="space-between" padding="12px">
          <Typography.Text
            dangerouslySetInnerHTML={{
              __html: `${item.name.replace(
                searchTerm,
                `<strong>${searchTerm}</strong>`,
              )}`,
            }}
          ></Typography.Text>
          <FlexBlock columnGap="8px" alignItems="stretch">
            <Button
              variant="anchor"
              compact
              onClick={() => addToInclusions(item.name)}
            >
              Include
            </Button>
            <VerticalDivider />
            <Button
              variant="anchor"
              compact
              onClick={() => addToExclusions(item.name)}
            >
              Exclude
            </Button>
          </FlexBlock>
        </FlexBlock>
      );
    };

  const renderPills = () => {
    return (
      <FlexBlock flexWrap="wrap" columnGap="8px" rowGap="8px">
        {inclusions.map(inclusion => (
          <Pill
            showCloseIcon
            key={inclusion}
            variant="kermit-default"
            onClick={onInclusionRemove(inclusion)}
          >
            {inclusion}
          </Pill>
        ))}
        {exclusions.map(exclusion => (
          <Pill
            showCloseIcon
            key={exclusion}
            variant="red-orange"
            onClick={onExclusionRemove(exclusion)}
          >
            {exclusion}
          </Pill>
        ))}
      </FlexBlock>
    );
  };

  return (
    <>
      <S.Section noBorder padding="24px">
        <FlexBlock columnGap="24px">
          <Button variant="icon-button-border" onClick={onClose}>
            <ArrowLeftIcon size={16} />
          </Button>
          <FlexBlock flexDirection="column">
            <Typography.Text $dmSans $bold $size="20">
              {isPodcast ? 'Episodes' : 'Articles'}
            </Typography.Text>
            <Typography.Text $colorName="steel">
              Explore and search for {isPodcast ? 'episodes' : 'articles'} by{' '}
              <Typography.Text $bold>{creatorName}</Typography.Text>
            </Typography.Text>
          </FlexBlock>
        </FlexBlock>

        {!isPodcast && (
          <>
            <Gap size={24} />
            <AutoComplete<IOption>
              placeholder="Search article title and description by Keyword"
              options={options}
              displayAccessor="name"
              onOptionSelect={() => {}}
              searchValue={searchTerm}
              onSearchValueChange={setSearchTerm}
              doNotUsePortal
              optionRenderFunction={renderAutocompleteOption}
              loading={suggestions.isFetching}
              renderSectionAboveInput={renderPills}
            />
          </>
        )}
      </S.Section>
      {isPodcast &&
        episodes?.content.map(episode => (
          <S.Section key={episode.title}>
            <EpisodeListItem episode={episode} key={episode.title} />
          </S.Section>
        ))}
      {!Boolean(articles.isFetching) ? (
        articlesToRender &&
        articlesToRender.map(article => (
          <S.Section key={article.id}>
            <ArticleListItem
              article={article}
              showRelevancyScore={shouldDisplayRelevancyScores}
            />
          </S.Section>
        ))
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default ExploreArticles;
