import { useCallback, useMemo } from 'react';
import {
  PressReleaseContentType,
  PressReleaseStateActions,
} from '../CreateOrEditPressRelease.constants';
import {
  usePressRelease,
  usePressReleaseDispatch,
} from '../context/PressReleaseContext';

export const usePressReleaseContent = (
  contentType?: PressReleaseContentType,
) => {
  const { pressReleaseContents, selectedPressReleaseContent } =
    usePressRelease();
  const dispatch = usePressReleaseDispatch();

  const getContentByType = useCallback(
    (contentType: PressReleaseContentType) => {
      return pressReleaseContents.find(
        content => content.contentType === contentType,
      );
    },
    [pressReleaseContents],
  );

  /**
   * Content is determined by the content type passed as an argument to this hook.
   * This value is utilized by all handlers to automatically interact with the intended content.
   * If no content type is provided, the function returns undefined.
   */
  const content = useMemo(() => {
    if (!contentType) return undefined;
    return getContentByType(contentType);
  }, [contentType, getContentByType]);

  const contentSelected = useMemo(() => {
    return selectedPressReleaseContent === contentType;
  }, [contentType, selectedPressReleaseContent]);

  /**
   * Read only values for original and generated content.
   * These values cannot be modified directly from this hook.
   */
  const originalContentReadOnly = useMemo(() => {
    return getContentByType(PressReleaseContentType.ORIGINAL);
  }, [getContentByType]);

  const generatedContentReadOnly = useMemo(() => {
    return getContentByType(PressReleaseContentType.GENERATED);
  }, [getContentByType]);

  const handleContentSelect = useCallback(() => {
    if (!contentType) return;
    dispatch({
      type: PressReleaseStateActions.SET_SELECTED_PRESS_RELEASE_CONTENT,
      payload: contentType,
    });
  }, [contentType, dispatch]);

  const handlePressReleaseInputChange = useCallback(
    (key: string, value: string) => {
      if (!content) return;

      const updatedContent = {
        ...content,
        content: { ...content.content, [key]: value },
      };

      dispatch({
        type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT,
        payload: updatedContent,
      });
    },
    [content, dispatch],
  );

  const handlePressReleaseQuotesChange = (
    quoteIndex: number,
    value: string,
  ) => {
    if (!content) return;

    const updatedContent = {
      ...content,
      content: {
        ...content.content,
        quotes: content.content.quotes?.map((quote, index) =>
          index === quoteIndex ? value : quote,
        ),
      },
    };

    dispatch({
      type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT,
      payload: updatedContent,
    });
  };

  const handleQuoteAdd = () => {
    if (!content) return;
    const updatedContent = {
      ...content,
      content: {
        ...content.content,
        quotes: [...(content.content.quotes || []), ''],
      },
    };

    dispatch({
      type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT,
      payload: updatedContent,
    });
  };

  const handleQuoteRemove = () => {
    if (!content) return;

    const updatedContent = {
      ...content,
      content: {
        ...content.content,
        quotes: content.content.quotes?.slice(0, -1),
      },
    };

    dispatch({
      type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT,
      payload: updatedContent,
    });
  };

  return {
    content: content,
    contentSelected,
    originalContentReadOnly,
    generatedContentReadOnly,
    handleContentSelect,
    handlePressReleaseInputChange,
    handlePressReleaseQuotesChange,
    handleQuoteAdd,
    handleQuoteRemove,
  };
};
