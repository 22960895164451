import { FC, useContext } from 'react';
import FooterButtons from '../Buttons';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import { PitchPageContext } from '../../../../PitchPage.context';
import DefaultSelectionText from './parts/DefaultSelectionText';
import ActiveSelectionText from './parts/ActiveSelectionText';
import {
  GlobalToneAndLengthSelectors,
  SelectionTypeToggle,
} from './parts/SelectionTypeToggle';

interface IProps {
  hasSelectedItems: boolean;
}

const GenerationSelectionMenu: FC<IProps> = ({ hasSelectedItems }) => {
  const {
    isRapidGenerationForAll,
    isRapidGenerationResultsView,
    selectedItemsCount,
    currentPublicationsCount,
  } = useContext(PitchPageContext);

  return (
    <FlexBlock
      flexDirection="row"
      columnGap="10px"
      alignItems="center"
      justifyContent="space-between"
      minWidth="100%"
      padding="0 20px"
    >
      <FlexBlock flexDirection="row" alignItems="center" columnGap="10px">
        <FooterButtons.CancelButton />
        {hasSelectedItems && !isRapidGenerationResultsView ? (
          <ActiveSelectionText
            currentPublicationsCount={currentPublicationsCount}
            selectedItemsCount={selectedItemsCount}
          />
        ) : (
          !isRapidGenerationResultsView && <DefaultSelectionText />
        )}
      </FlexBlock>
      <FlexBlock flexDirection="row" alignItems="center" columnGap="12px">
        <FlexBlock flexDirection="row" alignItems="center">
          <SelectionTypeToggle />
          <Gap size={24} />
          {isRapidGenerationForAll && <GlobalToneAndLengthSelectors />}
        </FlexBlock>

        {isRapidGenerationResultsView ? (
          <FooterButtons.RegenerateButton />
        ) : (
          <FooterButtons.GenerateButton disabled={!hasSelectedItems} />
        )}
      </FlexBlock>
    </FlexBlock>
  );
};

export default GenerationSelectionMenu;
