import { formatNumber, generateObscuredContent } from 'utils/helpers';
import { IDataTableColumnGroup } from '../../../../../../components/Table/Table.types';
import { IPreparedReportItem } from '../MediaReportCard/MediaReportCard.helpers';
import { Cell } from 'react-table';
import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import FlexBlock from '../../../../../../components/UI/FlexBlock';
import Avatar from '../../../../../../components/UI/Avatar';
import Typography from '../../../../../../components/Typography';
import ThumbsUpIcon from '../../../../../../assets/icons/ThumbsUpIcon';
import ThumbsDownIcon from 'app/assets/icons/ThumbsDownIcon';
import SocialsCell from './parts/SocialsCell';
import EmailCell from './parts/EmailCell';
import SentimentCell from './parts/SentimentCell';
import Pill from '../../../../../../components/UI/Pill';
import {
  FormattedTooltip,
  InfoTipAdvanced,
} from '../../../../../../components/UI/InfoTip/InfoTip';
import { userPermissionEnum } from '../../../../../Global/permissions/userPermissions.enum';
import ContactsTh from './parts/ContactsTh';
import CheckBoxCell from './parts/CheckBoxCell';
import {
  ContentLengthDropDown,
  ToneSelectDropDown,
} from '../../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import {
  CONTENT_LENGTH_SELECT_OPTIONS,
  TONE_SELECT_OPTIONS,
} from '../../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
} from '../../../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';
import { MAX_ITEMS_TO_MULTI_GENERATE } from '../../PitchPage.constants';
import { commonAuthorTooltipProps } from 'app/components/UI/Tooltip/Tooltip';

import ClassifiedCategories from './parts/ClassifiedCategories';

type GetMediaTableColumnConfigFunction = (options: {
  hideInterestSubColumns: boolean;
  groupBy: 'score' | 'outlet';
  interestKeys: string[];
  isPodcast?: boolean;
  isRapidGenerationActive?: boolean;
  isRapidGenerationForAll?: boolean;
  individualContentLengthSelector?: Dispatch<
    SetStateAction<Record<number, IContentLengthSelectOption>>
  >;
  individualTones?: Record<number, IToneSelectOption>;
  individualLengths?: Record<number, IContentLengthSelectOption>;
  individualToneSelector?: Dispatch<
    SetStateAction<Record<number, IToneSelectOption>>
  >;
  selectedItemsCount: number;
}) => IDataTableColumnGroup<IPreparedReportItem>[];

type CellType = Cell<IPreparedReportItem>;

enum columnWidth {
  wide = '20%',
  standard = '15%',
  compact = '5%',
}

export const getMediaTableColumnConfig: GetMediaTableColumnConfigFunction = ({
  hideInterestSubColumns,
  groupBy,
  interestKeys,
  isPodcast,
  isRapidGenerationActive,
  isRapidGenerationForAll,
  individualContentLengthSelector,
  individualToneSelector,
  individualTones,
  individualLengths,
  selectedItemsCount,
}) => {
  const renderCell = (cell: CellType, node: ReactNode) => {
    if (groupBy === 'outlet' && cell.row.original.titleRow) return () => <></>;
    return node;
  };

  const isGroupCell = (cell: CellType) =>
    groupBy === 'outlet' && cell.row.original.titleRow;

  const selectorColumns = [
    {
      id: 'tone-selector',
      Cell: (cell: CellType) => {
        const [value, setValue] = useState<IToneSelectOption>(
          individualTones &&
            individualTones[Number(cell.row.original.id)] !== undefined
            ? individualTones[Number(cell.row.original.id)]
            : TONE_SELECT_OPTIONS[0],
        );
        return isGroupCell(cell) ? (
          <></>
        ) : (
          <ToneSelectDropDown
            isCompact
            value={value}
            onChange={val => {
              if (individualToneSelector) {
                individualToneSelector(prev => ({
                  ...prev,
                  [cell.row.original.id]: val,
                }));
                setValue(val);
              }
            }}
            height="34px"
          />
        );
      },
      disableSort: true,
    },
    {
      id: 'content-length-selector',
      Cell: (cell: CellType) => {
        const [value, setValue] = useState<IContentLengthSelectOption>(
          individualLengths &&
            individualLengths[Number(cell.row.original.id)] !== undefined
            ? individualLengths[Number(cell.row.original.id)]
            : CONTENT_LENGTH_SELECT_OPTIONS[0],
        );

        return isGroupCell(cell) ? (
          <></>
        ) : (
          <ContentLengthDropDown
            isCompact
            value={value}
            onChange={val => {
              if (individualContentLengthSelector) {
                individualContentLengthSelector(prev => ({
                  ...prev,
                  [cell.row.original.id]: val,
                }));
                setValue(val);
              }
            }}
            height="34px"
          />
        );
      },
      disableSort: true,
    },
  ];

  // @ts-ignore
  const columns: IDataTableColumnGroup<IPreparedReportItem>[] = [
    {
      id: 'select_row',
      Header: <div>Multi-Pitch Generation</div>,
      showBorderLeft: true,
      columns: isRapidGenerationActive
        ? [
            {
              id: 'selection',
              Cell: (cell: CellType) => {
                return isGroupCell(cell) ? (
                  <></>
                ) : (
                  <CheckBoxCell
                    isDisabled={
                      selectedItemsCount + 1 > MAX_ITEMS_TO_MULTI_GENERATE
                    }
                    {...cell.row.getToggleRowSelectedProps()}
                  />
                );
              },
              disableSort: true,
            },
            ...(!isRapidGenerationForAll ? selectorColumns : []),
          ]
        : [],
    },
    {
      id: 'parent_source',
      Header: 'Source',
      columns: [
        {
          id: 'reportItem.mediaOutlet.name',
          accessor: 'sourceName',
          Header: () => (
            <Typography.Text $lineHeight={12} $size={12} $bold>
              {isPodcast ? 'Podcast' : 'Primary Outlet'}
            </Typography.Text>
          ),
          hideForOutlet: true,
          Cell: (cell: CellType) => (
            <Typography.Text
              $lineHeight={14}
              $bold={groupBy === 'score'}
              title={cell.row.original.sourceName}
            >
              {cell.row.original.sourceName}
            </Typography.Text>
          ),
        },
        {
          id: 'reportItem.author.name',
          accessor: 'authorName',
          Header: 'Journalist',
          Cell: (cell: CellType) =>
            cell.row.original.titleRow ? (
              <FlexBlock alignItems="center" columnGap="8px">
                <Avatar
                  name={cell.row.original.sourceName}
                  url={cell.row.original.sourceLogo}
                  margin="0"
                />
                <Typography.Text $bold $colorName="orbit">
                  {cell.row.original.sourceName}
                </Typography.Text>
                <InfoTipAdvanced
                  tooltipText=""
                  padding="0"
                  customTrigger={
                    <Pill padding="4px 12px" variant="blue" fontWeight="normal">
                      <Typography.Text $size={12} $lineHeight={12}>
                        {cell.row.original.totalJournalistsPerOutlet || 0}
                      </Typography.Text>
                    </Pill>
                  }
                >
                  <FlexBlock flexDirection="column" rowGap="9px">
                    <Typography.Text $bold $colorName="onyx" $size="14px">
                      Number of Journalist
                    </Typography.Text>
                    <Typography.Text
                      $colorName="steel"
                      $size="12px"
                      whiteSpace="normal"
                    >
                      Total number of journalists for this outlet.
                    </Typography.Text>
                  </FlexBlock>
                </InfoTipAdvanced>
              </FlexBlock>
            ) : (
              <FlexBlock
                {...commonAuthorTooltipProps}
                data-tip={cell.row.original.restricted && 'Enterprise Account'}
                data-for={
                  cell.row.original.restricted && 'upgradeAccountTooltip'
                }
                blur={
                  cell.row.original.authorName?.includes('*') ? 5 : undefined
                }
              >
                <Typography.Text
                  $lineHeight={14}
                  hideOverflow
                  overflowWidth="180px"
                  title={
                    !cell.row.original.authorName?.includes('*')
                      ? cell.row.original.authorName
                      : ''
                  }
                >
                  {cell.row.original.authorName?.includes('*')
                    ? generateObscuredContent(cell.row.original.authorName)
                    : cell.row.original.authorName}
                  {cell.row.original.contributor ? ' (Contributor)' : ''}
                </Typography.Text>
              </FlexBlock>
            ),
        },
        {
          id: 'classifiedCategories',
          accessor: 'classifiedCategories',
          Header: 'Beat',
          hideOnPodcasts: true,
          Cell: (cell: CellType) => (
            <ClassifiedCategories categories={cell.row.original.categories} />
          ),
          disableSort: true,
        },
        {
          id: 'reportItem.mediaOutlet.traffic',
          Cell: (cell: CellType) =>
            renderCell(
              cell,
              <Typography.Text textAlign="left">
                {cell.row.original.traffic === 0
                  ? '-'
                  : formatNumber(cell.row.original.traffic, '-')}
              </Typography.Text>,
            ),
          Header: 'UVM',
          doNotCollapse: true,
          requirePermission: userPermissionEnum.MEDIA_TRAFFIC_ACCESS,
          disableSort: false,
          hideOnPodcasts: true,
          width: columnWidth.standard,
        },
      ],
    },
    {
      id: 'parent_contacts',
      Header: <ContactsTh />,
      showBorderLeft: true,
      columns: [
        {
          id: 'reportItem.author.email',
          showBorderLeft: true,
          accessor: 'authorEmail',
          Header: 'Email',
          width: columnWidth.standard,
          Cell: (cell: CellType) =>
            renderCell(cell, <EmailCell row={cell.row.original} />),
        },
        {
          id: 'social',
          Header: 'Social',
          noVerticalPadding: true,
          disableSort: true,
          width: columnWidth.compact,
          Cell: (cell: CellType) =>
            renderCell(cell, <SocialsCell row={cell.row.original} />),
        },
      ],
    },
    {
      id: 'parent_interest',
      Header: 'Interest',
      collapsable: true,
      showBorderLeft: true,
      columns: [
        {
          id: 'reportItem.certainty',
          showBorderLeft: true,
          width: columnWidth.compact,
          Cell: (cell: CellType) =>
            renderCell(
              cell,
              <Typography.Text $colorName="orbit" $lineHeight="14">
                {cell.row.original.interest}
              </Typography.Text>,
            ),
          Header: () => (
            <Typography.Text $lineHeight={12} $size={12} $bold>
              Overall
            </Typography.Text>
          ),
          doNotCollapse: true,
          // centerContent: true,
          padding: '0 14px 0 32px',
        },

        ...interestKeys.map(key => ({
          id: `interest__${key}`,
          width: columnWidth.compact,
          Cell: (cell: CellType) =>
            renderCell(
              cell,
              <>
                {cell.row.original.interestExplain
                  ? cell.row.original.interestExplain[key]
                  : ''}
              </>,
            ),
          Header: key,
          centerContent: true,
        })),
        {
          id: 'reportItem.sentimentType',
          width: columnWidth.compact,
          Cell: (cell: CellType) =>
            renderCell(cell, <SentimentCell row={cell.row.original} />),
          Header: 'Sentiment',
          doNotCollapse: true,
          centerContent: true,
          hideOnPodcasts: true,
          tooltip: () => (
            <FormattedTooltip message="Sentiment of a journalist’s articles. Positive, negative, or neutral." />
          ),
        },
      ],
    },
    {
      id: 'parent_feedback',
      Header: 'Feedback',
      showBorderLeft: true,
      columns: [
        {
          id: 'preference',
          showBorderLeft: true,
          Cell: (cell: CellType) =>
            renderCell(
              cell,
              <>
                {cell.row.original.preference === null ||
                cell.row.original.preference === undefined ? null : cell.row
                    .original.preference ? (
                  <ThumbsUpIcon />
                ) : (
                  <ThumbsDownIcon />
                )}
              </>,
            ),
          Header: 'Feedback',
          disableSort: true,
          tooltip: () => (
            <FormattedTooltip message="Indicate relevant journalist suggestions." />
          ),
        },
      ],
    },
  ]
    .map(item => ({
      ...item,
      disableSort: true,
    }))
    .map(item => {
      if (!hideInterestSubColumns || !item.collapsable) return item;
      return {
        ...item,
        columns: item.columns.filter(
          // @ts-ignore
          sc => sc.doNotCollapse,
        ),
      };
    })
    .map(item => {
      if (groupBy === 'score') return item;
      return {
        ...item,
        // @ts-ignore
        columns: item.columns.filter(sc => !sc.hideForOutlet),
      };
    })
    .map(item => {
      if (!isPodcast) return item;

      return {
        ...item,
        columns: item.columns.filter((sc: any) => !sc.hideOnPodcasts),
      };
    });

  return columns;
};
