import { FC, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import * as S from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitchPage.styles';
import { ContentContainer } from 'app/components/ContentGeneration/ContentGeneration.styles';
import FlexBlock from 'app/components/UI/FlexBlock';
import { ContentTabsHeader } from 'app/components/ContentGeneration/parts/header/ContentHeader';
import { ContentHeader } from 'app/components/UI/ContentLayout/ContentLayout';
import { ToneSelectDropDown } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import { PredictedContentTab } from 'app/components/ContentGeneration/parts/header/parts/Tabs';
import {
  PressReleaseProvider,
  usePressRelease,
  usePressReleaseDispatch,
} from './context/PressReleaseContext';
import {
  PressReleaseContentType,
  PressReleasePredictionStatus,
  PressReleaseStateActions,
} from './CreateOrEditPressRelease.constants';
import PressReleaseOriginalContentEditor from './parts/Editor/PressReleaseOriginalContentEditor';
import { usePressReleaseContent } from './hooks/usePressReleaseContent.hook';
import PressReleaseGeneratedContentEditor from './parts/Editor/PressReleaseGeneratedContentEditor';
import PressReleaseHeader from './parts/Header/PressReleaseHeader';
import PitchModal from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/PitchProgressModal/PitchingModal';
import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';
import PressReleasePredictPanel from './parts/Prediction/PressReleasePredictPanel';
import { usePressReleaseData } from './hooks/usePressReleaseData.hook';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { RouteConstants } from 'app/routes';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  useCreatePressReleaseContentMutation,
  useCreatePressReleaseMutation,
  useRunPredictionMutation,
} from 'app/api';
import moment from 'moment';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import SocialPostCard from 'app/components/SocialPostCard/SocialPostCard';

interface ICreateOrEditPressReleasePageProps {}

export interface ICreateOrEditPressReleasePageParams {
  tenantId: string;
  projectId: string;
  pressReleaseId?: string;
  versionId?: string;
}

const CreateOrEditPressRelease: FC<ICreateOrEditPressReleasePageProps> = () => {
  const history = useHistory();

  const createPressReleasePage = useRouteMatch<{
    tenantId: string;
    projectId: string;
  }>(RouteConstants.pressRelease.createPressRelease);

  const editPressReleasePage = useRouteMatch<{
    tenantId: string;
    projectId: string;
    pressReleaseId: string;
    versionId: string;
  }>(RouteConstants.pressRelease.editPressRelease);

  const params: ICreateOrEditPressReleasePageParams | null = useMemo(() => {
    if (createPressReleasePage) return createPressReleasePage.params;
    if (editPressReleasePage) return editPressReleasePage.params;
    return null;
  }, [createPressReleasePage, editPressReleasePage]);

  const pressReleaseState = usePressRelease();

  const {
    generatedContentReadOnly: generatedContent,
    originalContentReadOnly: originalContent,
  } = usePressReleaseContent();

  const isMultipleContent = useMemo(() => {
    return Boolean(generatedContent) && Boolean(originalContent);
  }, [generatedContent, originalContent]);

  const dispatch = usePressReleaseDispatch();

  const { pageTitle, pressRelease } = usePressReleaseData();

  const [createPressRelease] = useCreatePressReleaseMutation();

  const [createPressReleaseContent] = useCreatePressReleaseContentMutation();

  const getSelectedPressReleaseContent = useCallback(() => {
    const originalContent = pressReleaseState.pressReleaseContents.find(
      pc => pc.contentType === PressReleaseContentType.ORIGINAL,
    );

    if (pressReleaseState.selectedPressReleaseContent) {
      const generatedContent = pressReleaseState.pressReleaseContents.find(
        pc => pc.contentType === pressReleaseState.selectedPressReleaseContent,
      );

      const persistableContent = {
        ...originalContent?.content,
        ...generatedContent?.content,
      };
      return { ...generatedContent, content: persistableContent };
    }
    return pressReleaseState.pressReleaseContents.find(
      pc => pc.contentType === PressReleaseContentType.ORIGINAL,
    );
  }, [
    pressReleaseState.pressReleaseContents,
    pressReleaseState.selectedPressReleaseContent,
  ]);

  const socialPosts = useMemo(() => {
    if (generatedContent) return generatedContent.socialPosts;

    if (originalContent) return originalContent.socialPosts;

    return undefined;
  }, [generatedContent, originalContent]);

  const handleCreate = async () => {
    if (!params?.projectId) return;

    const pressReleaseContent = getSelectedPressReleaseContent();

    if (!pressReleaseContent?.content) return;

    try {
      const pressRelease = await createPressRelease({
        projectId: params.projectId,
        pressReleaseContentCreateBody: {
          ...pressReleaseContent.content,
          title: pressReleaseContent.content.title || '',
          pressReleaseCopy: pressReleaseContent.content.pressReleaseCopy || '',
          date: pressReleaseContent.content.date
            ? moment(
                pressReleaseContent.content.date,
                'YYYY-MM-DD HH:mm:ss',
              ).format('YYYY-MM-DDTHH:mm:ss')
            : undefined,
          tone: pressReleaseState.tone.value || undefined,
          ...pressReleaseContent?.socialPosts,
        },
      }).unwrap();
      return pressRelease.id;
    } catch (error) {
      showErrorToast(
        'Could not create press release.',
        getErrorText(error as FetchBaseQueryError),
      );
      return undefined;
    }
  };

  const createPressReleaseOrSaveContent = async () => {
    if (params?.pressReleaseId) {
      try {
        const pressReleaseContent = getSelectedPressReleaseContent();

        if (!pressReleaseContent || !pressReleaseContent.content) return;

        await createPressReleaseContent({
          pressReleaseId: Number(params.pressReleaseId),
          ...pressReleaseContent.content,
          title: pressReleaseContent.content.title || '',
          pressReleaseCopy: pressReleaseContent.content.pressReleaseCopy || '',
          date: pressReleaseContent.content.date
            ? moment(
                pressReleaseContent.content.date,
                'YYYY-MM-DD HH:mm:ss',
              ).format('YYYY-MM-DDTHH:mm:ss')
            : undefined,
          tone: pressReleaseState.tone.value || undefined,
          ...pressReleaseContent?.socialPosts,
        });

        return Number(params.pressReleaseId);
      } catch (error) {
        showErrorToast(
          'Could not create a new content version for Press Release.',
        );
        return undefined;
      }
    } else {
      try {
        const pressReleaseId = await handleCreate();
        return pressReleaseId;
      } catch (error) {
        return undefined;
      }
    }
  };

  const [predict] = useRunPredictionMutation();

  const updatePressReleaseProcessingState = (
    status: PressReleasePredictionStatus | null,
  ) => {
    dispatch({
      type: PressReleaseStateActions.SET_PRESS_RELEASE_PROCESS_STATUS,
      payload: status,
    });
  };

  const handlePredict = async () => {
    const pressReleaseId = await createPressReleaseOrSaveContent();

    if (!pressReleaseId) return;

    updatePressReleaseProcessingState(
      PressReleasePredictionStatus.PREDICTION_RUNNING,
    );

    const predictionResponse = await predict({
      pitchId: pressReleaseId,
      pitchType: 'PRESS_RELEASE',
      runAgainstPodcastSelected:
        pressReleaseState.pressRelease.runAgainstPodcasts,
      countries:
        pressReleaseState.pressRelease.countries &&
        pressReleaseState.pressRelease.countries.length > 0
          ? pressReleaseState.pressRelease.countries.map(
              country => country.code,
            )
          : ['US'],
      states:
        pressReleaseState.pressRelease.states &&
        pressReleaseState.pressRelease.states.length > 0
          ? pressReleaseState.pressRelease.states.map(state => state.code)
          : [],
      contentTypes:
        pressReleaseState.pressRelease.contentTypes &&
        pressReleaseState.pressRelease.contentTypes.length > 0
          ? pressReleaseState.pressRelease.contentTypes.map(
              contentType => contentType.code,
            )
          : undefined,
      currentVersionNumber: pressRelease?.latestVersion || 0,
      customMediaListIds: pressReleaseState.selectedCustomMediaLists.map(
        mediaList => mediaList.id,
      ),
    });

    if ('data' in predictionResponse) {
      updatePressReleaseProcessingState(
        PressReleasePredictionStatus.PREDICTION_COMPLETE,
      );
      dispatch({
        type: PressReleaseStateActions.SET_PREDICTION,
        payload: predictionResponse.data,
      });

      const hasResults =
        predictionResponse.data.reports &&
        predictionResponse.data.reports.some(
          reports => reports.reportItemsPage.content?.length > 0,
        );

      const hasEmptyResults =
        predictionResponse.data.reports &&
        predictionResponse.data.reports.find(
          report => report.reportItemsPage.content?.length < 1,
        );

      if (!hasResults) {
        showErrorToast(
          'Consider removing some of the filters to see more results.',
          'No Results',
        );
      } else {
        if (hasEmptyResults) {
          showSuccessToast(
            'Consider removing some of the filters to see more results.',
            'Results Generated',
          );
        } else {
          showSuccessToast('Results Generated');
        }
      }

      if (params)
        history.push(
          RouteConstants.pressRelease.makePressReleaseUrl(
            Number(params.tenantId),
            Number(params.projectId),
            predictionResponse.data.pitch.id,
            predictionResponse.data.version,
          ),
        );
    }

    if ('error' in predictionResponse) {
      showErrorToast('Something went wrong.');
      updatePressReleaseProcessingState(null);
    }
  };

  const handlePredictionSettingsClose = () => {
    dispatch({ type: PressReleaseStateActions.TOGGLE_SETTINGS });
  };

  const [activeTab, setActiveTab] = useState<PredictedContentTab>(
    PredictedContentTab.PRESS_RELEASE,
  );

  return (
    <>
      <Helmet title={pageTitle} />
      <S.PageWrapper>
        <S.ContentWrapper>
          <S.PitchContent>
            <ContentHeader>
              <PressReleaseHeader>
                <ToneSelectDropDown
                  value={pressReleaseState.tone}
                  onChange={option =>
                    dispatch({
                      type: PressReleaseStateActions.SET_TONE,
                      payload: option,
                    })
                  }
                />
              </PressReleaseHeader>
            </ContentHeader>
            <ContentTabsHeader
              activeTab={activeTab}
              setActiveTab={tab => setActiveTab(tab)}
              hasSocialPosts={Boolean(socialPosts)}
              contentType={PredictedContentTab.PRESS_RELEASE}
            />
            <ContentContainer>
              {activeTab === PredictedContentTab.SOCIALS ? (
                <S.SocialPostsWrapper style={{ padding: '1em' }}>
                  <SocialPostCard
                    social="twitter"
                    content={socialPosts?.twitterPost || ''}
                  />

                  <SocialPostCard
                    social="facebook"
                    content={socialPosts?.facebookPost || ''}
                  />

                  <SocialPostCard
                    social="instagram"
                    content={socialPosts?.instagramPost || ''}
                  />

                  <SocialPostCard
                    social="linkedin"
                    content={socialPosts?.linkedinPost || ''}
                  />
                </S.SocialPostsWrapper>
              ) : (
                <FlexBlock
                  flexDirection="row"
                  flex={1}
                  alignItems="stretch"
                  minHeight="100%"
                  padding={isMultipleContent ? '1em' : undefined}
                >
                  <PressReleaseOriginalContentEditor
                    multipleContentView={isMultipleContent}
                  />
                  {generatedContent && (
                    <PressReleaseGeneratedContentEditor
                      activeTab={activeTab}
                      multipleContentView={isMultipleContent}
                    />
                  )}
                </FlexBlock>
              )}
            </ContentContainer>
          </S.PitchContent>
          <SideDrawer open={pressReleaseState.settingsOpen}>
            <PressReleasePredictPanel
              onPredict={handlePredict}
              pressRelease={pressRelease}
            />
          </SideDrawer>
        </S.ContentWrapper>
        {pressReleaseState.settingsOpen &&
          !Boolean(pressReleaseState.pressReleaseProcessStatus) && (
            <S.PageBackdrop onClick={handlePredictionSettingsClose} />
          )}
      </S.PageWrapper>
      <PitchModal
        isOpen={Boolean(pressReleaseState.pressReleaseProcessStatus)}
        contentDisplayName="press release"
      />
    </>
  );
};

const CreateOrEditPressReleasePage: FC = () => {
  return (
    <PressReleaseProvider>
      <CreateOrEditPressRelease />
    </PressReleaseProvider>
  );
};

export default CreateOrEditPressReleasePage;
