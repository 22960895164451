import Button from 'app/components/UI/Button/Button';
import Input from 'app/components/UI/Input/Input';
import { Anchor } from '../../../../components/UI/Anchor';
import { RouteConstants } from 'app/routes';
import React, { FC, useState } from 'react';
import * as S from './LoginForm.styles';
import auth0 from 'auth0-js';
import {
  BASE_API_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  IS_ENV_LOCAL,
} from 'utils/constants';
import { InputErrorType } from 'types/commonTypes';
import Typography from '../../../../components/Typography';
import { localStorageEnum, sessionStorageEnum } from '../../../../../types';
import { showErrorToast } from '../../../../../utils/toast';
import { NavLink } from 'react-router-dom';
import { LOGIN_ERROR_MESSAGE_HTML } from './LoginForm.constants';
import { __localLogin } from '../../api';
import LoginErrorComponent from './LoginErrorComponent';

interface IProps {
  email?: string;
}

enum LoginEnum {
  email = 'email',
  password = 'password',
}
const LoginFormId = 'LoginForm';

interface LoginFormElements extends HTMLFormControlsCollection {
  [LoginEnum.email]: HTMLInputElement;
  [LoginEnum.password]: HTMLInputElement;
}

interface LoginFormElement extends HTMLFormElement {
  readonly elements: LoginFormElements;
}

export interface ILoginOptions {
  redirectUri?: string;
  responseType?: string;
  responseMode?: 'query' | 'fragment';
  scope: string;
  connection: string;
  username: string;
  password: string;
}

const LoginForm: FC<IProps> = props => {
  const [errors, setErrors] = useState<{
    [LoginEnum.email]: InputErrorType[];
    [LoginEnum.password]: InputErrorType[];
  }>({ email: [], password: [] });

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const onSubmit = (event: React.FormEvent<LoginFormElement>) => {
    event.preventDefault();

    if (IS_ENV_LOCAL) return onLocalLoginSubmit(event);

    if (isSubmitLoading) {
      return;
    }

    setIsSubmitLoading(true);
    setErrors({ email: [], password: [] });

    const email = event.currentTarget.elements[LoginEnum.email].value;
    const password = event.currentTarget.elements[LoginEnum.password].value;

    const webAuth = new auth0.WebAuth({
      domain: AUTH0_DOMAIN as string,
      clientID: AUTH0_CLIENT_ID as string,
    });

    let redirectUri = `${BASE_API_URL}authentication/authenticate-local`;
    if (!IS_ENV_LOCAL) {
      // TODO: Enable production url change
      redirectUri = `${BASE_API_URL}authentication/authenticate`;
    }

    const options: ILoginOptions = {
      connection: 'Username-Password-Authentication',
      username: email,
      password,
      scope: 'openid email',
      redirectUri,
      responseType: 'code',
      responseMode: 'query',
    };

    sessionStorage.setItem(sessionStorageEnum.tempMfaEmail, email);

    webAuth.redirect.loginWithCredentials(options, (error: any) => {
      setIsSubmitLoading(false);
      if (error?.error_description === 'Wrong email or password.') {
        showErrorToast('Username or Password is incorrect, please try again.');
      } else if (error?.description) {
        showErrorToast(LOGIN_ERROR_MESSAGE_HTML);
      }
    });
  };

  const onLocalLoginSubmit = (event: React.FormEvent<LoginFormElement>) => {
    setIsSubmitLoading(true);
    const email = event.currentTarget.elements[LoginEnum.email].value;
    const password = event.currentTarget.elements[LoginEnum.password].value;
    __localLogin(email, password)
      .then(({ accessToken }) => {
        localStorage.setItem(localStorageEnum.token, accessToken);
        window.location.reload();
      })
      .catch(e => {
        setIsSubmitLoading(false);
      });
  };

  return (
    <div style={{ width: '400px' }}>
      <Typography.Title margin="16px 0 24px 0">Login</Typography.Title>

      <form onSubmit={onSubmit} id={LoginFormId}>
        <Typography.Text
          $colorName="steel"
          $size={14}
          $lineHeight={20}
          $margin="0 0 8px 0"
        >
          Enter your email address
        </Typography.Text>
        <Input
          width={'400px'}
          name={LoginEnum.email}
          defaultValue={props.email || undefined}
          placeholder="Email address"
          variant="loginTextField"
          border={
            errors.email && errors.email.length > 0
              ? '1px solid #CA3023'
              : undefined
          }
          customErrorComponent={<LoginErrorComponent errors={errors.email} />}
          fontSize={14}
        />
        <Typography.Text
          $colorName="steel"
          $size={14}
          $lineHeight={20}
          $margin="0 0 8px 0"
        >
          Enter your password
        </Typography.Text>
        <Input
          width={'400px'}
          type="password"
          name={LoginEnum.password}
          placeholder="Password"
          variant="loginTextField"
          customErrorComponent={
            <LoginErrorComponent errors={errors.password} />
          }
          border={
            errors.password && errors.password.length > 0
              ? '1px solid #CA3023'
              : undefined
          }
          fontSize={14}
        />
      </form>

      <S.ForgotPasswordRow>
        <NavLink to={RouteConstants.authorization.forgotPassword}>
          <Anchor>Forgot Password</Anchor>
        </NavLink>

        <Button
          type="submit"
          form={LoginFormId}
          isLoading={isSubmitLoading}
          padding="11px 28.5px"
        >
          Submit
        </Button>
      </S.ForgotPasswordRow>
    </div>
  );
};

export default LoginForm;
