import { Dispatch, FC, SetStateAction } from 'react';
import Tab from './JournalistTab';
import { TabsContainer } from './JournalistTab.styles';

export enum TableView {
  AllJournalists = 'All Journalists',
  FavoriteJournalists = 'Favorite Journalists',
  MediaList = 'Media Lists',
}

export interface IJournalistTabProps {
  label: TableView;
  amount?: number;
  isActive: boolean;
  isFetching?: boolean;
  onClickLogic?: () => void;
}

interface IJournalistTabsProps {
  tabData: IJournalistTabProps[];
}

export interface IJournalistTabViewProps {
  tableView: string;
  setTableView: Dispatch<SetStateAction<TableView>>;
  isFetching: boolean;
}

const Tabs: FC<IJournalistTabsProps & IJournalistTabViewProps> = ({
  tableView,
  setTableView,
  tabData,
  isFetching,
}) => {
  return (
    <TabsContainer>
      {tabData?.map(tab => {
        return (
          <Tab
            key={tab.label}
            tableView={tableView}
            setTableView={setTableView}
            label={tab.label}
            amount={tab.amount}
            isActive={tab.isActive}
            onClickLogic={tab?.onClickLogic}
            isFetching={isFetching}
          />
        );
      })}
    </TabsContainer>
  );
};

export default Tabs;
