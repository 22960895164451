import { ContentFlexContainer } from 'app/components/ContentGeneration/ContentGeneration.styles';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Tabs from 'app/components/UI/Tabs';
import { Dispatch, FC, SetStateAction } from 'react';

export enum PredictedContentTab {
  PITCH = 'PITCH',
  PRESS_RELEASE = 'PRESS_RELEASE',
  SOCIALS = 'SOCIALS',
}

interface IContentTabsProps {
  tab: PredictedContentTab;
  setTab: Dispatch<SetStateAction<PredictedContentTab>>;
  hasSocialPosts: boolean;
  context?: PredictedContentTab.PITCH | PredictedContentTab.PRESS_RELEASE;
}

export const ContentTabs: FC<IContentTabsProps> = ({
  children,
  tab,
  setTab,
  hasSocialPosts,
  context = PredictedContentTab.PITCH,
}) => {
  return (
    <Tabs.Bar>
      <ContentFlexContainer>
        <FlexBlock alignItems="center">
          {context === PredictedContentTab.PITCH ? (
            <Tabs.Tab
              active={tab === PredictedContentTab.PITCH}
              onClick={() => setTab(PredictedContentTab.PITCH)}
            >
              <Typography.Text
                $padding="5px 0"
                $bold
                $size={14}
                $lineHeight={24}
                $colorName="onyx"
              >
                Pitch
              </Typography.Text>
            </Tabs.Tab>
          ) : (
            <Tabs.Tab
              active={tab === PredictedContentTab.PRESS_RELEASE}
              onClick={() => setTab(PredictedContentTab.PRESS_RELEASE)}
            >
              <Typography.Text
                $padding="5px 0"
                $bold
                $size={14}
                $lineHeight={24}
                $colorName="onyx"
              >
                Press Release
              </Typography.Text>
            </Tabs.Tab>
          )}

          {hasSocialPosts && (
            <Tabs.Tab
              active={tab === PredictedContentTab.SOCIALS}
              onClick={() => setTab(PredictedContentTab.SOCIALS)}
            >
              <Typography.Text
                $padding="5px 0"
                $bold
                $size={14}
                $lineHeight={24}
                $colorName="onyx"
              >
                Social Posts
              </Typography.Text>
            </Tabs.Tab>
          )}
        </FlexBlock>
        {children}
      </ContentFlexContainer>
    </Tabs.Bar>
  );
};
