import { ISort } from 'types/commonTypes';
import {
  GeneratedContentStatusByTone,
  GetPredictionReportByIdPayload,
  IAuthorLocation,
  IAuthorSocial,
  IJournalistReportItemV31,
  IOutlet,
  IPodcast,
  IPodcasterReportItemV31,
  IReportExplainComponent,
  IReportItem,
  IReportItemV31,
  IReportV31,
  SentimentTypeEnum,
} from '../../../../../../api.types';
import { clearBitLogo, toPercentage } from '../../../../../../../utils/helpers';
import { api } from 'app/api';
import { AnyAction, Store } from '@reduxjs/toolkit';

export interface IPreparedExplainItem {
  [key: string]: string;
}

export interface IPreparedReportItem {
  id: number | string;
  sourceName: string;
  totalJournalistsPerOutlet?: number;
  sourceLogo?: string;
  titleRow?: boolean;
  outlets?: IOutlet[];
  authorCount?: number;
  authorName?: string;
  authorId?: string;
  authorEmail?: string;
  authorSocials?: IAuthorSocial[];
  authorAvatar?: string;
  authorBio?: string;
  authorTitle?: string;
  authorLocation?: IAuthorLocation[];
  interest?: string;
  sentimentType?: SentimentTypeEnum;
  feedback?: string;
  podcastId?: string;
  podcast?: IPodcast;
  preference?: boolean | null;
  interestOverall?: string;
  interestCategory?: string;
  interestProductivity?: string;
  interestFocus?: string;
  interestKeyword?: string;
  interestSentiment?: string;
  isEven?: boolean;
  interestExplain?: IPreparedExplainItem;
  mediaOutletId?: string;
  generatedContentStatus?: GeneratedContentStatusByTone | null;
  targetMediaType?: string;
  traffic?: number;
  restricted?: boolean;
  obscured?: boolean;
  contributor?: boolean;
  categories: string[];
}

export const prepareReportItems: (
  report?: IReportV31,
  sortByOutlet?: boolean,
  printMode?: boolean,
  printModePage?: number,
) => [IPreparedReportItem[], string[]] = (
  report,
  sortByOutlet,
  printMode,
  printModePage,
) => {
  if (!report || !report.reportItemsPage || !report.reportItemsPage.content)
    return [[], []];

  let sliceFrom = 0;
  let sliceTo = 0;

  if (printMode && printModePage !== undefined) {
    sliceFrom = printModePage * 10;
    sliceTo = sliceFrom + 10;
  }

  const interestKeysSet = new Set<string>([]);

  const allReportItems: IPreparedReportItem[] =
    //@ts-ignore
    report.reportItemsPage.content.map((item, index, allItems) => {
      const { mappedItem, explainKeys } = reportItemMapper(
        item,
        allItems,
        report.mediaList?.type.code,
        report.creatorType,
      );
      explainKeys.forEach(key => interestKeysSet.add(key));
      return mappedItem;
    });

  const reportItems =
    printMode && printModePage
      ? allReportItems.slice(sliceFrom, sliceTo).map(markEvenItems)
      : allReportItems.map(markEvenItems);

  const uniqueInterestKeys = Array.from(interestKeysSet);

  if (sortByOutlet) {
    const groupedReportItems: IPreparedReportItem[] = [];
    const groups: {
      outletName: string;
      logo: string | undefined;
      totalJournalistsPerOutlet: number;
      reportItems: IPreparedReportItem[];
    }[] = [];

    reportItems.forEach(item => {
      const existingGroup = groups.find(g => g.outletName === item.sourceName);

      if (existingGroup) {
        existingGroup.reportItems.push(item);
      } else {
        groups.push({
          reportItems: [item],
          outletName: item.sourceName,
          logo: item.sourceLogo,
          totalJournalistsPerOutlet: item.totalJournalistsPerOutlet || 0,
        });
      }
    });

    let phantomIndex = 0;

    groups.forEach(group => {
      groupedReportItems.push(
        {
          id: `outlet-${group.outletName}`,
          titleRow: true,
          sourceName: group.outletName,
          authorCount: group.reportItems.length,
          sourceLogo: group.logo,
          totalJournalistsPerOutlet: group.totalJournalistsPerOutlet,
          categories: [],
        },
        ...group.reportItems.map((item, index) =>
          markEvenItems(item, phantomIndex + index),
        ),
      );

      phantomIndex = phantomIndex + group.reportItems.length;
    });

    return [groupedReportItems, uniqueInterestKeys];
  }

  return [reportItems, uniqueInterestKeys];
};

function findReportItemExplainComponents(
  component: IReportExplainComponent | null | undefined,
): { explainComponent: IPreparedExplainItem; keys: string[] } {
  if (!component) return { explainComponent: {}, keys: [] };

  const keys: string[] = [];
  const explainComponent: IPreparedExplainItem = {};
  component.composites?.forEach(composite => {
    if (composite.comment === 'Sentiment') return;
    keys.push(composite.comment);
    explainComponent[composite.comment] = toPercentage(composite.value);
  });
  return { explainComponent, keys };
}

function reportItemFilter(item: IReportItem) {
  return item.preference !== false;
}

function countJournalistsPerOutlet(
  list: IReportItemV31[],
  primaryOutletId?: string,
): number {
  if (!primaryOutletId) return 0;
  return list.reduce((total, item) => {
    const primaryOutlet =
      item && item.outlets && item.outlets.length > 0 && item.outlets[0];
    if (!primaryOutlet) return total;
    if (primaryOutlet?.id === primaryOutletId) return total + 1;
    return total;
  }, 0);
}

function reportItemMapper(
  reportItem: IJournalistReportItemV31 | IPodcasterReportItemV31,
  allItems: IReportItemV31[],
  mediaType: string,
  creatorType?: string,
): {
  mappedItem: IPreparedReportItem;
  explainKeys: string[];
} {
  const { explainComponent, keys: explainKeys } =
    findReportItemExplainComponents(reportItem.explain);

  const isPodcast = creatorType === 'PODCASTER';

  const podcast = isPodcast
    ? (reportItem as IPodcasterReportItemV31).podcast
    : undefined;

  const podcaster = (reportItem as IPodcasterReportItemV31).podcaster;
  const journalist = (reportItem as IJournalistReportItemV31).journalist;

  const primaryOutlet =
    reportItem.outlets && reportItem.outlets.length > 0
      ? reportItem.outlets[0]
      : undefined;

  const mappedCreator = {
    sourceName: isPodcast ? podcast?.title : primaryOutlet?.name,
    sourceId: isPodcast
      ? podcast && podcast.id
        ? String(podcast?.id)
        : undefined
      : primaryOutlet?.id,
    sourceLogo: isPodcast
      ? undefined
      : primaryOutlet?.logo || clearBitLogo(primaryOutlet?.url) || undefined,
    socials: isPodcast ? podcaster.socials : journalist.socials,
    creatorId: isPodcast ? podcaster.id : journalist.id,
    creatorName: isPodcast ? podcaster.name : journalist.name,
    creatorEmail: isPodcast ? podcaster.email : journalist.email,
    creatorAvatar: isPodcast ? '' : journalist.avatar,
    creatorBio: isPodcast ? podcaster.bio : journalist.bio,
    creatorTitle: isPodcast ? '' : journalist.title,
    creatorLocation: isPodcast ? undefined : journalist.locations,
    restricted: isPodcast ? podcaster.restricted : journalist.restricted,
    obscured: isPodcast ? podcaster.obscured : journalist.obscured,
    contributor: !isPodcast ? journalist.contributor : undefined,
  };

  const mappedItem = {
    id: reportItem.id,
    sourceName: mappedCreator.sourceName || '',
    totalJournalistsPerOutlet: countJournalistsPerOutlet(
      allItems,
      mappedCreator.sourceId,
    ),
    sourceLogo: mappedCreator.sourceLogo,
    authorName: mappedCreator.creatorName || '',
    authorId: mappedCreator.creatorId,
    authorEmail: mappedCreator.creatorEmail || '',
    authorSocials: mappedCreator.socials || [],
    authorAvatar: mappedCreator.creatorAvatar || '',
    authorBio: mappedCreator.creatorBio || '',
    authorTitle: mappedCreator.creatorTitle || '',
    authorLocation: mappedCreator.creatorLocation,
    outlets: reportItem.outlets,
    interest: `${(reportItem.certainty * 100).toFixed(0)}%`,
    sentimentType: reportItem.sentimentType,
    podcastId: isPodcast && podcast?.id ? String(podcast.id) : undefined,
    podcast: isPodcast ? podcast : undefined,
    preference: reportItem.relevant,
    interestExplain: explainComponent,
    mediaOutletId: primaryOutlet?.id,
    generatedContentStatus: reportItem.creatorEmailToneStatuses,
    targetMediaType: mediaType,
    traffic: primaryOutlet?.traffic || 0,
    restricted: mappedCreator.restricted || false,
    obscured: mappedCreator.obscured || false,
    contributor: mappedCreator.contributor || false,
    categories: reportItem.classificationCategories
      ? reportItem.classificationCategories.map(cat => cat.name)
      : [],
  };

  return { mappedItem, explainKeys };
}

function stringifyValue(value: any) {
  if (typeof value === 'boolean') return value ? 'A' : 'B';
  if (value === null || value === '') return 'ZZZ';
  return String(value);
}

function isNumeric(value: any) {
  return isFinite(Number(String(value).replace('%', '')));
}

function markEvenItems(item: IPreparedReportItem, index: number) {
  return { ...item, isEven: Boolean(index % 2) };
}

export function formatClassifiedCategories(categories: string[]) {
  let truncatedCategoriesCount = 0;
  if (categories.length > 1) {
    truncatedCategoriesCount = categories.length - 1;
    let slicedCategories = categories
      .slice(0, 1)
      .join(', ')
      .replaceAll('/', '');

    return {
      formattedCategories: slicedCategories,
      truncatedCategoriesCount,
    };
  }

  return {
    formattedCategories: categories.join(', ').replaceAll('/', ''),
    truncatedCategoriesCount,
  };
}

function getValue(
  item: IPreparedReportItem,
  property: keyof IPreparedReportItem,
): any {
  if (property.match('interest__')) {
    const key = property.replace('interest__', '');
    return item.interestExplain ? item.interestExplain[key] : '';
  }
  return item[property] as any;
}

function compareNumbersWithUndefinedHandling(
  value_1: number | undefined,
  value_2: number | undefined,
  sortAscending: boolean,
  fallbackSortFn: () => number,
) {
  if (value_1 === undefined && value_2 === undefined) return fallbackSortFn();
  if (value_1 === undefined) return 1;
  if (value_2 === undefined) return -1;
  return sortAscending ? value_1 - value_2 : value_2 - value_1;
}

function compareValues(
  value_1: string,
  value_2: string,
  numeric: boolean,
  ascending: boolean,
) {
  const sortResult = String(value_1)
    .toLowerCase()
    .localeCompare(String(value_2).toLowerCase(), undefined, {
      numeric,
    });

  if (ascending) return sortResult;
  return 0 - sortResult;
}

function createSorter(sort: ISort | undefined, viewByOutletEnabled: boolean) {
  return (a: IPreparedReportItem, b: IPreparedReportItem) => {
    if (!sort) return 0; // Safe check

    let property = sort.property as keyof IPreparedReportItem;

    // View by outlet
    if (
      viewByOutletEnabled &&
      property === 'authorName' &&
      a.sourceName !== b.sourceName
    ) {
      return compareValues(a.sourceName, b.sourceName, false, sort.ascending);
    } else if (
      viewByOutletEnabled &&
      property === 'authorName' &&
      a.sourceName === b.sourceName
    ) {
      property = 'interest';
    }

    // Custom sort for traffic (UVM):
    // Sort by `traffic` property. If `traffic` is not available, use fallback sort by `authorName` or `sourceName`
    if (property === 'traffic') {
      return compareNumbersWithUndefinedHandling(
        a.traffic,
        b.traffic,
        sort.ascending,
        () => {
          if (viewByOutletEnabled) {
            return String(a.sourceName).localeCompare(String(b.sourceName));
          } else {
            return String(a.authorName).localeCompare(String(b.authorName));
          }
        },
      );
    }

    if (
      a.preference === true &&
      (b.preference === null || b.preference === undefined)
    )
      return -1;
    if (
      b.preference === true &&
      (a.preference === null || a.preference === undefined)
    )
      return 1;

    if (a.preference === true && b.preference === true)
      return String(a.authorName).localeCompare(String(b.authorName));

    const value_1 = stringifyValue(getValue(a, property));
    const value_2 = stringifyValue(getValue(b, property));

    if (value_1 === value_2) {
      return String(a.authorName).localeCompare(String(b.authorName));
    }

    // if (!a[property] && !b[property]) return -1;

    const numeric = isNumeric(value_1) || isNumeric(value_2);

    return compareValues(value_1, value_2, numeric, sort.ascending);
  };
}

// TODO: type this function arguments
export const isGetPredictionReportByIdCached = (
  store: Store<any, AnyAction>,
  id: GetPredictionReportByIdPayload,
) => {
  const selectGetItemById = api.endpoints.getPredictionReportById.select(id);
  const itemCache = selectGetItemById(store.getState());
  return itemCache.status === 'fulfilled' && itemCache.data != null;
};

export const splitCountToPages = ({
  countToLoad,
  loadedCount,
}: {
  loadedCount: number;
  countToLoad: number;
}): { page: number; size: number }[] => {
  const pagesToLoad: { page: number; size: number }[] = [];

  while (loadedCount < countToLoad) {
    const size =
      loadedCount === 0
        ? 25
        : loadedCount % 100 === 0
        ? 100
        : loadedCount % 50 === 0
        ? 50
        : 25;
    const page = loadedCount / size;

    pagesToLoad.push({ page, size });
    loadedCount += size;
  }
  return pagesToLoad;
};
export const getBestPageSize = (count: number): number => {
  return count % 100 === 0 ? 100 : count % 50 === 0 ? 50 : 25;
};
