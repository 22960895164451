// TODO: Change ToneValue to ToneValueEnum in ToneSelectOption

export type ToneValue =
  | 'professional'
  | 'friendly'
  | 'luxury'
  | 'persuasive'
  | 'relaxed'
  | 'bold'
  | 'adventurous';

export enum ToneValueEnum {
  professional = 'professional',
  friendly = 'friendly',
  apologetic = 'apologetic',
  luxury = 'luxury',
  technical = 'technical',
  persuasive = 'persuasive',
  contrite = 'contrite',
  relaxed = 'relaxed',
  emotive = 'emotive',
  bold = 'bold',
  exuberant = 'exuberant',
  adventurous = 'adventurous',
  tragic = 'tragic',
}

export enum ContentLengthValueEnum {
  toThePoint = 'SHORT',
  elaborative = 'LONG',
}

export interface IToneSelectOption {
  name: string;
  value: ToneValueEnum;
  icon: string;
}

export interface IContentLengthSelectOption {
  name: string;
  subtitle: string;
  value: ContentLengthValueEnum;
}
