import styled from 'styled-components/macro';

export const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 26px 40px;
`;

export const CopyrightWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px 32px;
`;
