import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';

import { useParams } from 'react-router-dom';
import PageLoader from 'app/components/UI/PageLoader';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import RemoveIcon from 'app/assets/icons/RemoveIcon';
import { Divider } from 'app/components/UI/Divider';
import { PitchVersionPill } from 'app/containers/Projects/pages/PItchList/PitchList.styles';
import { formatDate } from 'utils/helpers';

import { VerticalDivider } from 'app/components/UI/Divider';
import { NavLink } from 'react-router-dom';
import { RouteConstants } from 'app/routes';
import { IBio } from 'app/api.types';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  bio: IBio;
}

const BioVersionDrawer: FC<IProps> = ({ isOpen, setIsOpen, bio }) => {
  const { bioId, versionId, tenantId, projectId } = useParams<{
    bioId: string;
    versionId: string;
    tenantId: string;
    projectId: string;
  }>();

  const createBioUrl = (version: number) =>
    RouteConstants.bio.makeBioUrl(
      Number(tenantId),
      Number(projectId),
      Number(bioId),
      version,
    );

  const isFetching = false;

  return (
    <SideDrawer open={isOpen}>
      {isFetching ? (
        <PageLoader />
      ) : (
        <>
          <FlexBlock padding="24px">
            <FlexBlock flexDirection="column" rowGap="4px" flex={1}>
              <Typography.Text $dmSans $bold $size={20}>
                Versions
              </Typography.Text>
              <Typography.Text $colorName="steel">
                See all Past, Draft and Current Bio's for{' '}
                <Typography.Text>{bio.title}</Typography.Text>
              </Typography.Text>
            </FlexBlock>
            <FlexBlock>
              <Button
                variant="icon-button-border"
                hoverMode="black"
                onClick={() => setIsOpen(false)}
              >
                <RemoveIcon />
              </Button>
            </FlexBlock>
          </FlexBlock>

          {bio.bioContents.map((contentVersion, index, arr) => {
            const isActive = Boolean(
              Number(versionId) === contentVersion.version,
            );

            return (
              <Fragment key={contentVersion.id}>
                <FlexBlock padding="24px" columnGap="16px" alignItems="center">
                  <PitchVersionPill
                    status={isActive ? 'ACTIVE' : 'ARCHIVED'}
                    borderRadius="6px"
                  >
                    V{index + 1}
                  </PitchVersionPill>
                  <FlexBlock flexDirection="column" flex={1}>
                    <Typography.Text>
                      By: {contentVersion.creator?.firstName || ''}{' '}
                      {contentVersion.creator?.lastName || ''}{' '}
                      {isActive ? (
                        <Typography.Text $colorName="kermit">
                          (Active)
                        </Typography.Text>
                      ) : null}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDate(contentVersion.createdAt)}
                    </Typography.Text>
                  </FlexBlock>
                  <FlexBlock columnGap="8px" alignItems="stretch">
                    <NavLink to={createBioUrl(contentVersion.version)}>
                      <Button variant="anchor" compact>
                        Review
                      </Button>
                    </NavLink>
                    <VerticalDivider />
                  </FlexBlock>
                </FlexBlock>
                {Boolean(index + 1 !== arr.length) && <Divider />}
              </Fragment>
            );
          })}
        </>
      )}
    </SideDrawer>
  );
};

export default BioVersionDrawer;
