import { useGetTenantsQuery, useSearchUsersQuery } from 'app/api';
import { IPaginationRequestPart } from 'app/api.types';
import { selectActiveTenantId } from 'app/containers/Global/slice/selectors';
import { UserStatusEnum } from 'app/containers/Global/slice/types';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

export enum TenantsView {
  TENANTS = 'TENANTS',
  USERS = 'USERS',
  DEACTIVATED_USERS = 'DEACTIVATED_USERS',
}

interface ITenantsDataHookArgs {
  pagination: IPaginationRequestPart;
  setPagination: Dispatch<SetStateAction<IPaginationRequestPart>>;
  searchTerm: string;
  currentView: TenantsView;
}

const useUsersData = (
  searchTerm: string,
  pagination: IPaginationRequestPart,
  skip: boolean,
) => {
  const params = useParams<{ tenantId?: string }>();

  const { data, isFetching, isLoading, isError } = useSearchUsersQuery(
    {
      search: searchTerm || undefined,
      tenantId: params.tenantId || undefined,
      pagination: pagination,
    },
    { skip },
  );

  return { data, isFetching, isLoading, isError };
};

const useDeactivatedUsersData = (
  searchTerm: string,
  pagination: IPaginationRequestPart,
  skip: boolean,
) => {
  const params = useParams<{ tenantId?: string }>();

  const { data, isFetching, isLoading, isError } = useSearchUsersQuery(
    {
      search: searchTerm || undefined,
      pagination: pagination,
      tenantId: params.tenantId || undefined,
      status: [UserStatusEnum.DISABLED],
    },
    { skip },
  );

  return { data, isFetching, isLoading, isError };
};

const useTenantsData = (
  searchTerm: string,
  pagination: IPaginationRequestPart,
  skip: boolean,
) => {
  const { data, isFetching, isLoading, isError } = useGetTenantsQuery(
    {
      name: searchTerm || undefined,
      pagination: pagination,
    },
    { skip },
  );

  return { data, isFetching, isLoading, isError };
};

export const useData = ({
  pagination,
  setPagination,
  searchTerm,
  currentView,
}: ITenantsDataHookArgs) => {
  const usersData = useUsersData(
    searchTerm,
    pagination,
    currentView !== TenantsView.USERS,
  );
  const deactivatedUsersData = useDeactivatedUsersData(
    searchTerm,
    pagination,
    currentView !== TenantsView.DEACTIVATED_USERS,
  );
  const tenantsData = useTenantsData(
    searchTerm,
    pagination,
    currentView !== TenantsView.TENANTS,
  );

  const resetPage = useCallback(() => {
    setPagination(pagination => ({
      ...pagination,
      page: {
        ...pagination.page,
        page: 0,
      },
    }));
  }, [setPagination]);

  useEffect(() => {
    resetPage();
  }, [searchTerm, resetPage]);

  switch (currentView) {
    case TenantsView.TENANTS:
      return tenantsData;

    case TenantsView.USERS:
      return usersData;

    case TenantsView.DEACTIVATED_USERS:
      return deactivatedUsersData;

    default:
      return {
        data: [],
        isLoading: false,
        isFetching: false,
        isError: false,
      };
  }
};
