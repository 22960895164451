import Button from 'app/components/UI/Button/Button';
import { FC, useCallback, useState } from 'react';
import HistoryIcon from '../../icons/HistoryIcon';
import SocialPostHistoryDrawer from '../History/SocialPostHistoryDrawer';
import {
  ISocialPostContentVersion,
  SocialPostType,
} from '../../../types/SocialPost.types';
import React from 'react';

interface ISocialPostsPageVersionsProps {
  postContentVersions?: ISocialPostContentVersion[];
  onVersionLoad: (postContentId: number, postType: SocialPostType) => void;
}

const SocialPostsPageVersions: FC<ISocialPostsPageVersionsProps> = ({
  postContentVersions,
  onVersionLoad,
}) => {
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(
    () => setHistoryDrawerOpen(open => !open),
    [],
  );

  return (
    <>
      <Button variant="transparent-square" onClick={toggleDrawer}>
        <HistoryIcon />
      </Button>
      {postContentVersions && historyDrawerOpen && (
        <SocialPostHistoryDrawer
          open={historyDrawerOpen}
          toggleDrawer={toggleDrawer}
          contentVersions={postContentVersions}
          onPostContentVersionLoad={onVersionLoad}
        />
      )}
    </>
  );
};

export default React.memo(SocialPostsPageVersions);
