import {
  IToneSelectOption,
  ToneValueEnum,
} from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';
import { useReducer, useMemo, useCallback } from 'react';
import {
  IGenerateSocialPostContentPayload,
  ISocialPost,
  ISocialPostContentBase,
  SocialPostType,
} from '../../SocialPostsPage/types/SocialPost.types';
import { TONE_SELECT_OPTIONS } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import { getWordCount } from 'utils/helpers';
import {
  MIN_SOCIAL_POST_TITLE_WORDS,
  MIN_SOCIAL_POSTS_TOPIC_WORDS,
} from '../CreateOrEditSocialPosts.constants';
import { useLocation } from 'react-router-dom';

export type GenerateSuccessCallback = (content: ISocialPostContentBase) => void;
export type GenerateStartCallback = (
  content: IGenerateSocialPostContentPayload & { title: string },
) => void;

interface State {
  title: string;
  topic: string;
  tone: IToneSelectOption;
  socialProviders: SocialPostType[];
}

const initialState: State = {
  title: '',
  topic: '',
  tone: TONE_SELECT_OPTIONS[0],
  socialProviders: [],
};

const getInitialState = (socialPost?: ISocialPost): State => {
  if (socialPost) {
    return {
      title: socialPost.title,
      topic: socialPost.topic,
      tone: TONE_SELECT_OPTIONS[0],
      socialProviders: [],
    };
  }

  return initialState;
};

type Action =
  | { type: 'SET_TITLE'; payload: string }
  | { type: 'SET_TOPIC'; payload: string }
  | { type: 'SET_TONE'; payload: IToneSelectOption }
  | { type: 'SET_IS_GENERATING'; payload: boolean }
  | { type: 'SET_SOCIAL_PROVIDERS'; payload: SocialPostType[] }
  | { type: 'RESET_FORM' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_TITLE':
      return { ...state, title: action.payload };
    case 'SET_TOPIC':
      return { ...state, topic: action.payload };
    case 'SET_TONE':
      return { ...state, tone: action.payload };
    case 'SET_SOCIAL_PROVIDERS':
      return { ...state, socialProviders: action.payload };
    case 'RESET_FORM':
      return initialState;
    default:
      return state;
  }
};

export const useSocialPostForm = (
  onGenerateStart?: (suggestionPayload: any) => void,
) => {
  const { state: existingSocialPost } = useLocation<ISocialPost | undefined>();
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(existingSocialPost),
  );

  const handleFieldChange = (action: Action) => {
    dispatch(action);
  };

  const handleFormReset = useCallback(() => {
    dispatch({ type: 'RESET_FORM' });
  }, []);

  const handleGenerate = useCallback(async () => {
    const contentSuggestionPayload = {
      topic: state.topic,
      tone: state.tone.value,
      socialPostTypes: state.socialProviders,
      title: state.title,
    };
    onGenerateStart?.(contentSuggestionPayload);
  }, [
    state.tone,
    state.topic,
    state.socialProviders,
    state.title,
    onGenerateStart,
  ]);

  const isResetAvailable = useMemo(() => {
    return (
      state.title ||
      state.topic ||
      state.tone.value !== ToneValueEnum.professional ||
      state.socialProviders.length
    );
  }, [state.title, state.socialProviders, state.topic, state.tone]);

  const isFormValid = useMemo(() => {
    const isTitleValid =
      getWordCount(state.title) >= MIN_SOCIAL_POST_TITLE_WORDS;

    const isTopicValid =
      getWordCount(state.topic) >= MIN_SOCIAL_POSTS_TOPIC_WORDS;

    return (
      isTitleValid && isTopicValid && state.socialProviders.length && state.tone
    );
  }, [state.title, state.socialProviders, state.topic, state.tone]);

  return {
    state,
    handleFieldChange,
    handleFormReset,
    isResetAvailable,
    isFormValid,
    handleGenerate,
  };
};
