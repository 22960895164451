import { FC } from 'react';
import FlexBlock from '../../FlexBlock';
import { SkeletonDiv } from '../Skeletons.styles';

interface ITablePaginationSkeletonProps {}

const TablePaginationSkeleton: FC<ITablePaginationSkeletonProps> = () => {
  return (
    <FlexBlock
      justifyContent="center"
      alignItems="center"
      maxWidth="100%"
      margin="24px 0 0 0"
      columnGap="8px"
    >
      <SkeletonDiv
        shimmerAnimation
        width="32px"
        height="32px"
        borderRadius="8px"
      />
      <SkeletonDiv
        shimmerAnimation
        width="32px"
        height="32px"
        borderRadius="8px"
      />
      <SkeletonDiv
        shimmerAnimation
        width="32px"
        height="32px"
        borderRadius="8px"
      />
    </FlexBlock>
  );
};

export default TablePaginationSkeleton;
