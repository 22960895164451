import { Dispatch, FC, SetStateAction } from 'react';
import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import RemoveIcon from 'app/assets/icons/RemoveIcon';
import Gap from 'app/components/UI/Gap';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const BioSettingsDrawer: FC<IProps> = ({ isOpen, setIsOpen, children }) => {
  return (
    <SideDrawer open={isOpen}>
      <FlexBlock padding="24px" flexDirection="column">
        <FlexBlock flexDirection="column" rowGap="4px" flex={1}>
          <FlexBlock
            flexDirection="row"
            justifyContent="space-between"
            minWidth="100%"
          >
            <Typography.Text
              $dmSans
              $bold
              $size={24}
              $lineHeight={32}
              $colorName="onyx"
            >
              Blog Inputs
            </Typography.Text>
            <FlexBlock>
              <Button
                variant="icon-button-border"
                hoverMode="black"
                onClick={() => setIsOpen(false)}
              >
                <RemoveIcon />
              </Button>
            </FlexBlock>
          </FlexBlock>

          <Typography.Text $colorName="steel" maxWidth="60%">
            Adjust all your base content that Taylor will use to generate your
            content.
          </Typography.Text>
        </FlexBlock>
        <Gap size={24} />
        {children}
      </FlexBlock>
    </SideDrawer>
  );
};

export default BioSettingsDrawer;
