import { api } from 'app/api';
import { ITenant } from 'app/api.types';
import ArrowRightIcon from 'app/assets/icons/ArrowRightIcon';
import CarrotUpIcon from 'app/assets/icons/CarrotTopIcon';
import RemoveIcon from 'app/assets/icons/RemoveIcon';
import WarningIcon from 'app/assets/icons/WarningIcon';
import AutoComplete from 'app/components/AutoComplete/AutoComplete';
import FileDropzone, {
  FileLoadHandler,
} from 'app/components/FileDropzone/FileDropzone';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import FormLabelRow from 'app/components/UI/FormParts/FormLabelRow';
import Gap from 'app/components/UI/Gap';
import Modal from 'app/components/UI/Modal/Modal';
import RoundIconWrapper from 'app/components/UI/RoundIconWrapper';
import SideDrawer, {
  SidebarFooter,
} from 'app/components/UI/SideDrawer/SideDrawer';
import {
  IImportTargetMediaResponse,
  importTargetMediaList,
  IImportFailedReasons,
} from './MediaListEntries.helpers';
import { EXAMPLE_FILE_CONTENT } from './MediaList.constants';
import {
  FailedAuthorsButton,
  ExpandableSection,
} from './MediaListModal.styles';
import { FC, MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getErrorText } from 'utils/error-messages';
import { downloadFile } from 'utils/helpers';
import { showErrorToast } from 'utils/toast';

import * as TableStyle from 'app/components/Table/Table.styles';
import { UploadFilesIcon } from 'app/assets/icons/UploadFilesIcon';
import { Anchor } from 'app/components/UI/Anchor';
import { useJournalistsSlice } from 'app/containers/Journalists/slice';
import { selectUploadMediaListModalOpen } from 'app/containers/Journalists/slice/selectors';
import { useTenantSelector } from 'app/containers/Journalists/pages/hooks/useTenantSelector';
import UploadCloudIcon from 'app/assets/icons/UploadCloudIcon';
import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';

const UploadMediaListModal: FC = () => {
  const dispatch = useAppDispatch();
  const { actions } = useJournalistsSlice();
  const modalOpen = useAppSelector(selectUploadMediaListModalOpen);

  const canManageTenants = useHasPermission(userPermissionEnum.CREATE_TENANTS);

  const {
    selectedTenant,
    availableTenantsOptions,
    handleTenantRemove,
    handleTenantSelect,
    tenantSearchPhrase,
    setTenantSearchPhrase,
  } = useTenantSelector();

  const [file, setFile] = useState<File | null>(null);
  const [fileBuffer, setFileBuffer] = useState<ArrayBuffer | string | null>(
    null,
  );
  const [importResponse, setImportResponse] =
    useState<IImportTargetMediaResponse | null>(null);
  const [importLoading, setImportLoading] = useState(false);

  const [detailsSidebarOpen, setDetailsSidebarOpen] = useState(false);
  const [expandedReasons, setExpandedReasons] = useState<string[]>([]);

  const toggleReasonExpand = (reason: string) => () => {
    if (expandedReasons.includes(reason)) {
      setExpandedReasons(s => s.filter(r => r !== reason));
    } else {
      setExpandedReasons(s => [...s, reason]);
    }
  };

  const [tenantSelectorError, setTenantSelectorError] = useState<
    string | undefined
  >(undefined);

  const validateSelectedTenant = () => {
    if (!canManageTenants) return;

    if (canManageTenants && !selectedTenant) {
      setTenantSelectorError('Tenant is required.');
    }

    setTenantSelectorError(undefined);
  };

  const handleModalClose = () => {
    dispatch(actions.toggleUploadMediaListModal(false));
    setDetailsSidebarOpen(false);
    setImportResponse(null);
    setFile(null);
    setExpandedReasons([]);
    handleTenantRemove();
  };

  const handleFileLoad: FileLoadHandler = (file, buffer) => {
    setFile(file);
    setFileBuffer(buffer);
  };

  const handleFileRemove: MouseEventHandler = event => {
    event.preventDefault();
    setFile(null);
    setFileBuffer(null);
  };

  const handleSubmit = async () => {
    if (fileBuffer && file) {
      try {
        setImportLoading(true);
        const response: IImportTargetMediaResponse =
          await importTargetMediaList(
            fileBuffer,
            file.name,
            canManageTenants && selectedTenant ? selectedTenant.id : undefined,
          );
        dispatch(api.util.invalidateTags(['custom-target-medias']));
        setImportResponse(response);
        setImportLoading(false);
      } catch (e) {
        showErrorToast(getErrorText(e), 'Failed to upload media list');
        setImportLoading(false);
      }
    } else {
      showErrorToast('Please select at least one Project and upload a file');
    }
  };

  const failedAuthorsCount = useMemo(() => {
    if (!importResponse) return 0;
    return Object.keys(importResponse.failedAuthorNames).length;
  }, [importResponse]);

  useEffect(() => {
    if (failedAuthorsCount > 0) {
      showErrorToast('Not all journalists were found');
    }
  }, [failedAuthorsCount]);

  //TODO: refactor and move to helpers
  const failedAuthorsByReason = useMemo(() => {
    const reasons: IImportFailedReasons[] = [];
    if (!importResponse) return reasons;

    Object.keys(importResponse.failedAuthorNames).forEach(key => {
      const existingReason: IImportFailedReasons | undefined = reasons.find(
        item => item.reason === importResponse.failedAuthorNames[key].reason,
      );
      if (existingReason) {
        existingReason.authors.push({
          name: importResponse.failedAuthorNames[key].author,
          outlet: importResponse.failedAuthorNames[key].outlet,
        });
      } else {
        reasons.push({
          reason: importResponse.failedAuthorNames[key].reason,
          authors: [
            {
              name: importResponse.failedAuthorNames[key].author,
              outlet: importResponse.failedAuthorNames[key].outlet,
            },
          ],
        });
      }
    });

    return reasons;
  }, [importResponse]);

  const uploadAgain = () => {
    dispatch(actions.toggleUploadMediaListModal(true));
    setImportResponse(null);
    handleTenantRemove();
    setDetailsSidebarOpen(false);
    setFile(null);
  };

  const openDetailsSidebar = () => {
    dispatch(actions.toggleUploadMediaListModal(false));
    setDetailsSidebarOpen(true);
  };

  const onDownloadExampleFileClick = () => {
    downloadFile(
      'Prophet Media List - Sample Template.xlsx',
      EXAMPLE_FILE_CONTENT,
    );
  };

  const isTenantSelected = useMemo(() => {
    if (canManageTenants) {
      return Boolean(selectedTenant);
    }

    return true;
  }, [canManageTenants, selectedTenant]);

  const ErrorsComponent: FC<{ error?: string }> = ({ error }) => (
    <div style={{ margin: '4px 18px', minHeight: '24px' }}>
      {error && (
        <Typography.Text $size={14} $colorName="redOrange">
          {error}
        </Typography.Text>
      )}
    </div>
  );

  return (
    <>
      <Modal open={modalOpen} $maxWidth="512px">
        {!importResponse ? (
          <>
            <FlexBlock
              padding="24px"
              justifyContent="center"
              alignItems="center"
            >
              <RoundIconWrapper size={88} style={{ background: '#EBF2FB' }}>
                <UploadFilesIcon />
              </RoundIconWrapper>
            </FlexBlock>

            <FlexBlock
              padding="0 40px 8px 40px"
              rowGap="8px"
              flexDirection="column"
              alignItems="center"
              maxWidth="500px"
              margin="auto"
            >
              <Typography.Text $dmSans $bold $size={24}>
                Upload Media List
              </Typography.Text>
              <Typography.Text style={{ textAlign: 'center' }}>
                Upload or drag and drop your Media List in the space below.
                Click “Submit” to build your list based on journalists that are
                in the PRophet dataset. File must be in a CSV format.{' '}
                <Anchor onClick={onDownloadExampleFileClick}>
                  Download the example file.
                </Anchor>
              </Typography.Text>
            </FlexBlock>
            {canManageTenants && (
              <FormLabelRow
                label="Tenant"
                rowProps={{ vertical: true, padding: '0 40px' }}
              >
                <AutoComplete<ITenant>
                  options={availableTenantsOptions || []}
                  displayAccessor="name"
                  onOptionSelect={handleTenantSelect}
                  searchValue={tenantSearchPhrase}
                  onSearchValueChange={setTenantSearchPhrase}
                  $maxWidth="512px"
                  maxMenuHeight={350}
                  placeholder={
                    selectedTenant ? selectedTenant.name : 'Search for Tenants'
                  }
                  onMenuBlur={validateSelectedTenant}
                  wrapperBorderColor={
                    Boolean(tenantSelectorError) ? 'red' : undefined
                  }
                  closeMenuAfterOptionSelect
                />
                <ErrorsComponent error={tenantSelectorError} />
              </FormLabelRow>
            )}

            <FlexBlock>
              <FileDropzone
                label="Upload a File (CSV)"
                onFileLoad={handleFileLoad}
                accept={{
                  'text/csv': [],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    [],
                }}
              >
                {file && (
                  <FlexBlock
                    flexDirection="column"
                    alignItems="center"
                    rowGap="10px"
                  >
                    <UploadCloudIcon />
                    <Typography.Text $colorName="steel">
                      Loaded File {file.name}
                    </Typography.Text>
                    <Typography.Text $colorName="steel">
                      Drag & Drop or
                    </Typography.Text>
                    <Button
                      variant="secondary"
                      compact
                      onClick={handleFileRemove}
                    >
                      Replace
                    </Button>
                  </FlexBlock>
                )}
              </FileDropzone>
            </FlexBlock>

            <FlexBlock
              padding="24px 40px 40px"
              justifyContent="center"
              columnGap="12px"
            >
              <Button variant="secondary" compact onClick={handleModalClose}>
                Cancel
              </Button>
              <Button
                compact
                onClick={handleSubmit}
                isLoading={importLoading}
                disabled={!file || !isTenantSelected || importLoading}
              >
                Submit
              </Button>
            </FlexBlock>
          </>
        ) : (
          <>
            <FlexBlock padding="24px" justifyContent="center">
              <RoundIconWrapper size={88} style={{ background: '#EBF2FB' }}>
                <WarningIcon fill="#000" size={40} />
              </RoundIconWrapper>
            </FlexBlock>

            <FlexBlock
              padding="0 40px"
              rowGap="8px"
              flexDirection="column"
              alignItems="center"
              maxWidth="512px"
            >
              <Typography.Text $dmSans $bold $size={24}>
                Media List Uploaded
              </Typography.Text>
              {failedAuthorsCount > 0 && (
                <Typography.Text textAlign="center">
                  A total of{' '}
                  <Typography.Text $colorName="redOrange">
                    {failedAuthorsCount} journalists
                  </Typography.Text>{' '}
                  were not found. The rest of the journalists have been added to
                  your Media List.
                </Typography.Text>
              )}
            </FlexBlock>

            <FlexBlock
              padding="40px 40px"
              rowGap="8px"
              flexDirection="column"
              alignItems="stretch"
              maxWidth="512px"
            >
              <FailedAuthorsButton onClick={openDetailsSidebar}>
                <Typography.Text style={{ textTransform: 'none' }}>
                  <Typography.Text $bold>
                    {failedAuthorsCount} journalists
                  </Typography.Text>{' '}
                  were not added
                </Typography.Text>

                <FlexBlock columnGap="8px" alignItems="center">
                  <Typography.Text>View Details</Typography.Text>
                  <ArrowRightIcon />
                </FlexBlock>
              </FailedAuthorsButton>
            </FlexBlock>

            <FlexBlock
              padding="0 40px 24px 0"
              columnGap="8px"
              justifyContent="center"
              maxWidth="512px"
            >
              <Button variant="secondary" compact onClick={uploadAgain}>
                Upload again
              </Button>
              <Button compact onClick={handleModalClose}>
                Done
              </Button>
            </FlexBlock>
          </>
        )}
      </Modal>
      <SideDrawer open={detailsSidebarOpen}>
        <FlexBlock
          flexDirection="column"
          justifyContent="space-between"
          minHeight="100%"
          alignItems="stretch"
        >
          <FlexBlock padding="24px">
            <FlexBlock flexDirection="column" padding="10px">
              <FlexBlock flexDirection="column" justifyContent="space-between">
                <Typography.Text $dmSans $bold $size={20}>
                  Overview
                </Typography.Text>
              </FlexBlock>
              <Gap size={32} />
              <Typography.Text>
                Your Media List has been uploaded. Below are the details of the
                journalists that couldn’t be found.
              </Typography.Text>
            </FlexBlock>
            <FlexBlock>
              <Button
                variant="icon-button-border"
                hoverMode="black"
                onClick={handleModalClose}
              >
                <RemoveIcon />
              </Button>
            </FlexBlock>
          </FlexBlock>
          <FlexBlock
            flex={1}
            flexDirection="column"
            maxWidth="95%"
            margin="auto"
          >
            {failedAuthorsByReason.map(item => (
              <ExpandableSection.Wrapper key={item.reason}>
                <ExpandableSection.Toggle
                  onClick={toggleReasonExpand(item.reason)}
                >
                  <ExpandableSection.ToggleIconWrapper
                    expanded={expandedReasons.includes(item.reason)}
                  >
                    <CarrotUpIcon fill="#1d76de" />
                  </ExpandableSection.ToggleIconWrapper>
                  <FlexBlock flex={1}>
                    <Typography.Text
                      $dmSans
                      $bold
                      $size={16}
                      $colorName="orbit"
                    >
                      {item.reason}
                    </Typography.Text>
                  </FlexBlock>
                  <ExpandableSection.CountPill>
                    {item.authors.length}
                  </ExpandableSection.CountPill>
                </ExpandableSection.Toggle>
                {expandedReasons.includes(item.reason) && (
                  <TableStyle.Wrapper
                    itemsPerView={5}
                    noWhitespace
                    noBackground
                  >
                    <TableStyle.Table>
                      <TableStyle.THead>
                        <TableStyle.Tr>
                          <TableStyle.Th>Journalist Name</TableStyle.Th>
                          <TableStyle.Th>Outlet</TableStyle.Th>
                        </TableStyle.Tr>
                      </TableStyle.THead>
                      <TableStyle.TBody>
                        {item.authors.map((author, index) => (
                          <TableStyle.Tr
                            key={author.name}
                            isEven={Boolean(index % 2)}
                          >
                            <TableStyle.Td>{author.name}</TableStyle.Td>
                            <TableStyle.Td>{author.outlet}</TableStyle.Td>
                          </TableStyle.Tr>
                        ))}
                      </TableStyle.TBody>
                    </TableStyle.Table>
                  </TableStyle.Wrapper>
                )}
              </ExpandableSection.Wrapper>
            ))}
          </FlexBlock>
          <SidebarFooter style={{ background: 'white' }}>
            <Button variant="secondary" onClick={handleModalClose}>
              Done
            </Button>

            <Button onClick={uploadAgain}>Re-Upload</Button>
          </SidebarFooter>
        </FlexBlock>
      </SideDrawer>
    </>
  );
};

export default UploadMediaListModal;
