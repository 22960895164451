import React from 'react';

function DotsHorizontalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#3F3F42" transform="translate(-1820 -108)">
          <g transform="translate(1368 72)">
            <g transform="translate(440 24)">
              <g transform="translate(12 12)">
                <path
                  d="M10 8c0 1.088-.895 1.97-2 1.97S6 9.088 6 8s.895-1.97 2-1.97c1.104 0 1.999.882 2 1.97zM8 4.34c1.105 0 2-.881 2-1.97C10 1.282 9.105.4 8 .4s-2 .882-2 1.97c.001 1.088.896 1.97 2 1.97zm0 7.32c-1.105 0-2 .881-2 1.97 0 1.088.895 1.97 2 1.97s2-.882 2-1.97c-.001-1.088-.896-1.97-2-1.97z"
                  transform="rotate(90 8 8)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DotsHorizontalIcon;
