import { FC } from 'react';
import { Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import PitchPage from '../Pitch/pages/PitchPage/PitchPage';
import CreateOrEditPressRelease from './pages/CreateOrEditPressRelease/CreateOrEditPressReleasePage';
import ProtectedRoute from '../Global/permissions/ProtectedRoute';
import { userPermissionEnum } from '../Global/permissions/userPermissions.enum';

interface IProps {}

const PressRelease: FC<IProps> = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={RouteConstants.pressRelease.pressRelease}
        component={PitchPage}
        requirePermission={userPermissionEnum.READ_PRESS_RELEASE}
      />
      <ProtectedRoute
        path={RouteConstants.pressRelease.createPressRelease}
        component={CreateOrEditPressRelease}
        requirePermission={userPermissionEnum.CREATE_PRESS_RELEASE}
      />
      <ProtectedRoute
        path={RouteConstants.pressRelease.editPressRelease}
        component={CreateOrEditPressRelease}
        requirePermission={userPermissionEnum.MANAGE_PRESS_RELEASE}
      />
    </Switch>
  );
};

export default PressRelease;

// To Do:

// Check for Press Release Permissions - Done
// Implement Create or Edit Press Release Page
// Find a solution for state management for Press Release ( Pitch is using Context, which might not be ideal scenario in a shared context )
