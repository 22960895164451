import FlexBlock from 'app/components/UI/FlexBlock';
import styled, { css } from 'styled-components';

interface TextInputProps {
  width?: string;
  notValid?: boolean;
}

interface SectionProps {
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  flexDirection?: 'row' | 'column';
  noBottomBorder?: boolean;
}

export const TextInput = styled.input<TextInputProps>`
  ${({ theme, width, notValid }) => css`
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    padding: 20px 16px;
    padding-right: 16px;
    border: 1px solid ${notValid ? theme.colors.volatileRed : '#dadee1'};
    background-color: #ffffff;
    box-shadow: 0 0 0px 1000px white inset;
    transition: border-color 0.3s, box-shadow 0.3s;
    border-radius: 8px;
    width: ${width || '350px'};

    &:focus {
      outline: 1px solid ${theme.colors.orbit};
    }
  `}
`;

export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  column-gap: 16px;
  width: 100%;
  align-items: center;
  border-bottom: ${({ theme, noBottomBorder }) =>
    noBottomBorder ? 'none' : `${theme.colors.smoke} 1px solid`};
  margin-top: 24px;
  padding-bottom: 16px;
`;

export const UserModalContainer = styled(FlexBlock)`
  background: white;
  border-radius: 8px;
  position: relative;
`;

export const UserModalLoadingOverlay = styled.div(
  ({ theme }) => css`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: ${theme.colors.aluminum};
    opacity: 0.5;
    border-radius: 8px;
  `,
);
