import {
  ActionType,
  Column,
  HeaderProps,
  Hooks,
  TableInstance,
  TableState,
  TableToggleCommonProps,
} from 'react-table';
import { MAX_JOURNALISTS_TO_EXPORT } from './JournalistsTable.constants';
import { IndeterminateCheckbox } from './parts/IndeterminateCheckbox';
import FlexBlock from 'app/components/UI/FlexBlock';
import { useCallback, useEffect, useRef } from 'react';
import { getFavoriteJournalistTooltipProps } from '../../JournalistList.helpers';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import { showErrorToast } from 'utils/toast';

export const selectedRowsReducer = (
  newState: TableState<{}>,
  action: ActionType,
) => {
  switch (action.type) {
    case 'resetAllSelectedRows':
      return {
        ...newState,
        selectedRowIds: {},
      };
    case 'toggleSelectedRowsInCurrentPage':
      const shouldSelect = action.isParentSelected;
      const currentRows = action.currentRows;

      if (!currentRows) return newState;

      const isSelectLimitExceeds =
        currentRows.length > MAX_JOURNALISTS_TO_EXPORT;

      if (isSelectLimitExceeds && shouldSelect) {
        showErrorToast(
          `Cannot select media list entries. Maximum limit is ${MAX_JOURNALISTS_TO_EXPORT} entries.`,
        );
        return newState;
      }

      const selectedRowIds = shouldSelect
        ? currentRows.reduce(
            //@ts-ignore
            (acc, row) => ({
              ...acc,
              [row.id]: true,
            }),
            {},
          )
        : {};
      return {
        ...newState,
        selectedRowIds,
      };
    default:
      return newState;
  }
};

export const useCheckboxes = (hooks: Hooks) => {
  hooks.visibleColumns.push((columns: Column[]) => [
    {
      id: 'selection',
      Header: ({
        getToggleAllRowsSelectedProps,
        state,
        data,
        ...rest
      }: HeaderProps<{}>) => {
        const selectedRowsCount = Object.keys(state.selectedRowIds).length;
        const rowsInView = rest.rows.length;
        const selectedRowsInCurrentPage = rest.rows.filter(
          row => row.isSelected,
        ).length;

        const isLimitReached =
          selectedRowsCount + (rowsInView - selectedRowsInCurrentPage) >
          MAX_JOURNALISTS_TO_EXPORT;

        const toggleAllRowsSelectedProps = getToggleAllRowsSelectedProps();

        return (
          <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
            <FlexBlock
              alignItems="center"
              justifyContent="center"
              {...getFavoriteJournalistTooltipProps(
                toggleAllRowsSelectedProps.checked
                  ? 'De-Select All'
                  : 'Select all',
              )}
            >
              <IndeterminateCheckbox
                {...toggleAllRowsSelectedProps}
                limited={isLimitReached}
              />
            </FlexBlock>
          </IfHasPermission>
        );
      },
      Cell: ({
        row,
        state,
      }: {
        row: { getToggleRowSelectedProps: () => TableToggleCommonProps };
        state: { selectedRowIds: { [key: string]: boolean } };
      }) => {
        const isSelectedLimitReached =
          Object.keys(state.selectedRowIds).length >= MAX_JOURNALISTS_TO_EXPORT;

        return (
          <IfHasPermission require={userPermissionEnum.MANAGE_TARGET_MEDIA}>
            <FlexBlock alignItems="center" justifyContent="center">
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                limited={isSelectedLimitReached}
              />
            </FlexBlock>
          </IfHasPermission>
        );
      },
      disableSort: true,
    },
    ...columns,
  ]);
};

export const useSelectedFlatRows = <DataItem,>(
  tableInstance: TableInstance<object>,
) => {
  const rowsRef = useRef<Record<string, DataItem>>({});

  const resetSelectedRowsRef = useCallback(() => {
    if (rowsRef && rowsRef.current) {
      rowsRef.current = {};
    }
  }, [rowsRef]);

  useEffect(() => {
    if (rowsRef && rowsRef.current && tableInstance?.selectedFlatRows) {
      const selectedRowsInCurrentSlice = tableInstance.selectedFlatRows.reduce(
        (acc, row) => ({
          ...acc,
          [row.id]: row.original as unknown as DataItem,
        }),
        {} as Record<string, DataItem>,
      );

      // Add newly selected rows
      rowsRef.current = {
        ...rowsRef.current,
        ...selectedRowsInCurrentSlice,
      };

      // Remove deselected rows
      const currentViewRowIds = tableInstance.rows.map(row => row.id);
      rowsRef.current = Object.keys(rowsRef.current)
        .filter(
          id =>
            !currentViewRowIds.includes(id) || id in selectedRowsInCurrentSlice,
        )
        .reduce((acc, id) => ({ ...acc, [id]: rowsRef.current[id] }), {});
    }
  }, [tableInstance?.selectedFlatRows, tableInstance?.rows, rowsRef]);

  return { selectedRowsRef: rowsRef, resetSelectedRowsRef };
};
