import CarrotTopIcon from 'app/assets/icons/CarrotTopIcon';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import SocialProviderSelectorMenu from './SocialProviderSelectorMenu';
import { CloseIcon } from 'app/components/UI/CloseIcon';
import FacebookIcon from 'app/assets/icons/social-providers/FacebookIcon';
import InstagramIcon from 'app/assets/icons/social-providers/InstagramIcon';
import LinkedIcon from 'app/assets/icons/social-providers/LinkedIcon';
import XIcon from 'app/assets/icons/social-providers/XIcon';
import { Input, ActiveFilterButton } from './SocialPostsInput.styles';
import { SocialPostType } from '../../../SocialPostsPage/types/SocialPost.types';

interface ISocialProviderSelectorProps {
  inputPlaceholder: string;
  socialProviders: SocialPostType[];
  handleSocialProvidersChange: (providers: SocialPostType[]) => void;
}

const SOCIAL_PROVIDER_OPTIONS = [
  {
    displayName: 'Facebook',
    key: SocialPostType.FACEBOOK,
    avatar: <FacebookIcon width={32} height={32} />,
  },
  {
    displayName: 'Instagram',
    key: SocialPostType.INSTAGRAM,
    avatar: <InstagramIcon width={32} height={32} />,
  },
  {
    displayName: 'LinkedIn',
    key: SocialPostType.LINKEDIN,
    avatar: <LinkedIcon width={32} height={32} />,
  },
  {
    displayName: 'X/Threads',
    key: SocialPostType.TWITTER,
    avatar: <XIcon width={32} height={32} />,
  },
];

const SocialProviderSelector: FC<ISocialProviderSelectorProps> = ({
  inputPlaceholder,
  socialProviders,
  handleSocialProvidersChange,
}) => {
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);

  const selectedDisplayNames = useMemo(() => {
    return SOCIAL_PROVIDER_OPTIONS.filter(option =>
      socialProviders?.includes(option.key),
    );
  }, [socialProviders]);

  const selectorOptions = useMemo(() => {
    return SOCIAL_PROVIDER_OPTIONS.map(option => ({
      ...option,
      checked: socialProviders.includes(option.key),
    }));
  }, [socialProviders]);

  const handleOptionRemove = (key: string) => {
    handleSocialProvidersChange(
      socialProviders.filter(provider => provider !== key),
    );
  };

  return (
    <FlexBlock
      alignItems="center"
      justifyContent="space-between"
      columnGap="16px"
      minWidth="100%"
    >
      <>
        <Input
          onClick={() => setOptionsMenuOpen(prev => !prev)}
          $isActive={optionsMenuOpen}
          style={{
            width: '100%',
            borderRadius: '8px',
            border: '1px solid #dadee1',
          }}
        >
          <FlexBlock
            alignItems="center"
            justifyContent="space-between"
            minWidth="100%"
          >
            <FlexBlock alignItems="center" columnGap="8px">
              {selectedDisplayNames.length ? (
                selectedDisplayNames.map(option => (
                  <ActiveFilterButton key={option.key}>
                    <Typography.Text
                      $colorName="orbit"
                      $size={14}
                      $lineHeight={16}
                    >
                      {option.displayName}
                    </Typography.Text>
                    <CloseIcon
                      orbit
                      onClick={e => {
                        e.stopPropagation();
                        handleOptionRemove(option.key);
                      }}
                    />
                  </ActiveFilterButton>
                ))
              ) : (
                <Typography.Text $colorName="onyx" $size={14} $lineHeight={20}>
                  {inputPlaceholder}
                </Typography.Text>
              )}
            </FlexBlock>
            <CarrotTopIcon rotateDown={true} fill={theme.colors.orbit} />
          </FlexBlock>

          {optionsMenuOpen && (
            <SocialProviderSelectorMenu
              options={selectorOptions}
              setSelectedOptions={options =>
                handleSocialProvidersChange((options || []) as SocialPostType[])
              }
              menuTitle="Social Types"
              open={optionsMenuOpen}
              setOpen={setOptionsMenuOpen}
              compactView={false}
            />
          )}
        </Input>
      </>
    </FlexBlock>
  );
};

export default SocialProviderSelector;
