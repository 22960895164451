import Button from 'app/components/UI/Button/Button';
import { ToneSelectDropDown } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import { FC, useState } from 'react';
import RegenerateIcon from '../../icons/RegenerateIcon';
import { TONE_SELECT_OPTIONS } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ToneValue } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';
import { getToneObjectByValue } from 'app/containers/Blog/pages/BlogPage/BlogPage.helpers';

interface ISocialPostsPageRegenerateProps {
  onRegenerate: (tone: string) => void;
}

const SocialPostsPageRegenerate: FC<ISocialPostsPageRegenerateProps> = ({
  onRegenerate,
}) => {
  const location = useLocation<{ tone?: ToneValue }>();
  const initialGenerationTone = location.state;

  const [regenerationTone, setRegenerationTone] = useState(
    initialGenerationTone?.tone
      ? getToneObjectByValue(initialGenerationTone.tone)
      : TONE_SELECT_OPTIONS[0],
  );

  return (
    <>
      <ToneSelectDropDown
        value={regenerationTone}
        onChange={setRegenerationTone}
        isCompact
        selectorHeight={56}
        selectorRadius={8}
      />
      <Button
        variant="transparent-square"
        onClick={() => onRegenerate(regenerationTone.value)}
      >
        <RegenerateIcon />
      </Button>
    </>
  );
};

export default React.memo(SocialPostsPageRegenerate);
