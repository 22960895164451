import { FC } from 'react';
import Modal from 'app/components/UI/Modal/Modal';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';
import { Spinner } from 'app/components/UI/Spinner';

interface IProps {
  isOpen: boolean;
  regenerating?: boolean;
}

const BylineRegenerationModal: FC<IProps> = ({
  isOpen,
  regenerating = true,
}) => {
  return (
    <Modal open={Boolean(isOpen)}>
      <FlexBlock padding="24px" justifyContent="center">
        <Spinner $size="60px" $isBlue $margin="20px" />
      </FlexBlock>

      <FlexBlock
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="0 40px 24px"
        rowGap="8px"
      >
        <Typography.Text $dmSans $bold $size={24}>
          {regenerating ? 'Regenerating' : 'Generating'} Byline...
        </Typography.Text>
        <Typography.Text maxWidth="425px" textAlign="center">
          We’re {regenerating ? 'regenerating' : 'generating'} your byline, sit
          tight!
        </Typography.Text>
      </FlexBlock>
    </Modal>
  );
};

export default BylineRegenerationModal;
