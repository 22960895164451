import FlexBlock from 'app/components/UI/FlexBlock';
import { TableToggleAllRowsSelectedProps } from 'react-table';
import styled from 'styled-components';
import { showCustomToast } from 'utils/toast';
import { SelectReportItemsToastError } from './SelectReportItemsToastError';

const StyledInput = styled.input`
  width: 17px;
  height: 17px;
`;

const CheckBox = ({
  onChange: onChangeHandler,
  checked,
  isDisabled,
}: Partial<TableToggleAllRowsSelectedProps> & { isDisabled?: boolean }) => {
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (isDisabled && !checked) return onChangeDisabled();
    if (onChangeHandler)
      return onChangeHandler({ ...e, target: e.currentTarget });
    return undefined;
  };

  const onChangeDisabled = () => {
    showCustomToast(
      <SelectReportItemsToastError />,
      'toast-red',
      'tooManyJournalists',
    );
  };

  return (
    <FlexBlock flexDirection="row" alignItems="center" justifyContent="center">
      <StyledInput
        type="checkbox"
        onChange={onChange}
        checked={checked}
        style={{ width: '17px', height: '17px' }}
      />
    </FlexBlock>
  );
};

export default CheckBox;
