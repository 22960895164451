import styled, { css } from 'styled-components/macro';

interface IPillProps {
  variant?:
    | 'default'
    | 'light'
    | 'blue'
    | 'kermit-compact'
    | 'pitch-version'
    | 'pitch-version-square'
    | 'kermit-default'
    | 'red-orange';
  inline?: boolean;
  showCloseIcon?: boolean;
  pitchVersion?: 'ACTIVE' | 'ARCHIVED' | 'DRAFT';
  padding?: string;
  fontWeight?: string;
}

const Pill = styled.div<IPillProps>(
  ({
    theme,
    variant = 'default',
    inline,
    showCloseIcon,
    pitchVersion,
    padding,
    fontWeight,
  }) => css`
    background-color: ${theme.colors.smoke};
    font-family: ${theme.fonts.dmSans};
    font-size: 14px;
    font-weight: ${fontWeight || 'bold'};
    color: ${theme.colors.onyx};
    padding: ${padding || '12px 16px'};
    border-radius: 8px;
    position: relative;
    ${inline
      ? css`
          display: inline;
        `
      : ''}

    ${variant === 'kermit-default'
      ? css`
          background-color: rgba(0, 211, 119, 0.2);
          font-family: ${theme.fonts.inter};
          font-weight: normal;
          color: #000;
        `
      : ''}

    ${variant === 'red-orange'
      ? css`
          background-color: rgba(237, 32, 41, 0.2);
          font-family: ${theme.fonts.inter};
          font-weight: normal;
          color: #000;
        `
      : ''}

    ${variant === 'light'
      ? css`
          background-color: ${theme.colors.haze};
          font-family: ${theme.fonts.inter};
          font-weight: normal;
        `
      : ''}
    
    ${variant === 'blue'
      ? css`
          background-color: rgba(29, 118, 222, 0.1);
          padding: ${padding || '12px 8px'};
          font-family: ${theme.fonts.inter};
          font-weight: ${fontWeight || 'bold'};
        `
      : ''}
    
    ${variant === 'kermit-compact'
      ? css`
          background-color: ${theme.colors.kermit};
          padding: 6px 16px;
          font-family: ${theme.fonts.inter};
          font-weight: bold;
          color: #fff;
        `
      : ''}
    
    ${variant === 'pitch-version'
      ? css`
          background-color: ${theme.colors.kermit};
          padding: 6px 16px;
          font-family: ${theme.fonts.inter};
          font-weight: bold;
          color: #fff;
          ${pitchVersion === 'DRAFT'
            ? css`
                background-color: ${theme.colors.outrageous};
              `
            : pitchVersion === 'ARCHIVED'
            ? css`
                background-color: ${theme.colors.steel};
              `
            : css`
                background-color: ${theme.colors.kermit};
              `}
        `
      : ''}

    ${variant === 'pitch-version-square'
      ? css`
          background-color: ${theme.colors.kermit};
          padding: 6px 16px;
          font-family: ${theme.fonts.inter};
          font-weight: bold;
          color: #fff;
          height: 56px;
          min-width: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          ${pitchVersion === 'DRAFT'
            ? css`
                background-color: ${theme.colors.outrageous};
              `
            : pitchVersion === 'ARCHIVED'
            ? css`
                background-color: ${theme.colors.steel};
              `
            : css`
                background-color: ${theme.colors.kermit};
              `}
        `
      : ''}
    
    ${showCloseIcon
      ? css`
          padding-right: 36px;

          &:before,
          &:after {
            content: '';
            height: 2px;
            width: 14px;
            background: rgb(154, 156, 158);
            position: absolute;
            top: 50%;
            right: 10px;
            border-radius: 1px;
            transition: 0.12s all ease-in-out;
          }

          &:before {
            transform: translateY(-50%) rotate(45deg);
          }

          &:after {
            transform: translateY(-50%) rotate(-45deg);
          }

          &:hover {
            cursor: pointer;

            &:before,
            &:after {
              background: rgb(103, 101, 101);
            }
          }
        `
      : ''}
  `,
);

export default Pill;
