import CopyIcon from 'app/assets/icons/CopyIcon';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import { Dispatch, SetStateAction, FC } from 'react';
import { showSuccessToast, showErrorToast } from 'utils/toast';
import * as S from '../../../../Pitch/pages/CreateFirstPitch/CreateFirstPitch.styles';
import { GridContainer } from '../BlogPage.styles';
import RegenerateIcon from 'app/assets/icons/RegenerateIcon';
import { theme } from 'styles/theme';

type BlogContent = {
  title: string;
  body: string;
};

interface IContentSectionProps {
  content: BlogContent;
  handleContentChange: Dispatch<SetStateAction<BlogContent>>;
  regenerateContentClick: (tone?: string) => Promise<void>;
}

const BlogPageContent: FC<IContentSectionProps> = ({
  content,
  handleContentChange,
  regenerateContentClick,
}) => {
  const onTextCopy = async () => {
    try {
      await window.navigator.clipboard.writeText(
        content.title + '\n\n' + content.body,
      );
      showSuccessToast('Text copied to clipboard!');
    } catch (e) {
      showErrorToast(
        'Your browser does not support this function.',
        'Failed to copy text.',
      );
    }
  };

  return (
    <GridContainer>
      <S.GridContent $borderOrbit={true}>
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <S.ContentPill>
            <Typography.Text $colorName="orbit" $size={14} $lineHeight={16}>
              Generated Content
            </Typography.Text>
          </S.ContentPill>

          <FlexBlock flexDirection="row">
            <Button compact variant="gray" onClick={onTextCopy}>
              <CopyIcon />
              <Gap size={8} />
              Copy
            </Button>
            <Gap size={8} />
            <Button
              compact
              variant="gray"
              onClick={() => regenerateContentClick()}
              tooltipText="Regenerate content"
            >
              <RegenerateIcon fill={theme.colors.nero} />
            </Button>
          </FlexBlock>
        </FlexBlock>

        <Gap size={16} />
        <FlexBlock flexDirection="column" justifyContent="left" padding="16px">
          <S.InputBox
            $noBorder
            placeholder="Blog Title..."
            value={content.title}
            onChange={e =>
              handleContentChange(prev => ({
                ...prev,
                title: e.target.value,
              }))
            }
            textStyle="title"
            minRows={1}
            disabled={false}
          />
          <S.InputBox
            $noBorder
            placeholder="Blog Content..."
            value={content.body}
            onChange={e =>
              handleContentChange(prev => ({
                ...prev,
                body: e.target.value,
              }))
            }
            textStyle="body"
            minRows={1}
            disabled={false}
          />
        </FlexBlock>
      </S.GridContent>
    </GridContainer>
  );
};

export default BlogPageContent;
