import { FC } from 'react';
import usePortal from '../../../../utils/hooks/usePortal';
import * as S from './BottomDrawer.styles';

interface IProps {
  open: boolean;
  portalNodeId?: string;
  $extendedHeight?: boolean;
}

const BottomDrawer: FC<IProps> = ({
  open,
  portalNodeId = 'side-drawer-root',
  children,
  $extendedHeight = false,
}) => {
  const { renderInPortal } = usePortal(portalNodeId);

  return (
    <S.Wrapper open={open} $height={$extendedHeight}>
      {children}
    </S.Wrapper>
  );
};

export default BottomDrawer;
