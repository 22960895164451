import React, { ButtonHTMLAttributes, FC } from 'react';
import { Spinner } from '../Spinner';
import * as S from './Button.styles';
import styled from 'styled-components';

export type ButtonVariantType =
  | 'primary'
  | 'secondary'
  | 'secondary-blue'
  | 'black'
  | 'white'
  | 'icon-button'
  | 'icon-button-no-padding'
  | 'icon-button-border'
  | 'icon-button-border-active'
  | 'gray'
  | 'gray-smoke'
  | 'gray-smoke-square'
  | 'gray-smoke-hover-blue'
  | 'gray-smoke-hover-danger'
  | 'blue-square'
  | 'black-square'
  | 'icon-round'
  | 'icon-round-social'
  | 'icon-round-social-no-hover'
  | 'anchor'
  | 'danger-anchor'
  | 'anchor-hover'
  | 'danger'
  | 'danger-outline'
  | 'transparent-square'
  | 'transparent'
  | 'transparent-square-compact'
  | 'transparent-compact'
  | 'primary-blue-v2'
  | 'primary-blue-v2-compact';
const Notification = styled.span`
  top: -20;
  right: -27;
  width: 13px;
  height: 13px;
  background: #1d76de;
  color: #1d76de;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const NotificationWrapper = styled.span`
  position: absolute;
  top: -20px;
  right: -27px;
`;

const ButtonContent = styled.div`
  display: flex;
  position: relative;
`;

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name?: string;
  type?: 'submit' | 'reset' | 'button';
  placeholder?: string;
  form?: string;
  margin?: string;
  isLoading?: boolean;
  padding?: string;
  variant?: ButtonVariantType;
  onClick?: (event: React.MouseEvent) => void;
  compact?: boolean;
  disabled?: boolean;
  hoverMode?: 'black';
  baseColor?: string;
  $active?: boolean;
  wide?: boolean;
  tooltipText?: string;
  boxShadow?: string;
  hasNotifications?: boolean;
}

const Button: FC<IButtonProps> = ({
  name,
  type,
  children,
  form,
  margin,
  isLoading,
  padding,
  variant = 'primary',
  onClick,
  compact,
  disabled,
  hoverMode,
  baseColor,
  $active,
  wide,
  tooltipText,
  boxShadow,
  hasNotifications,
  style,
}) => {
  return (
    <>
      <S.StyledBtn
        title={tooltipText}
        $hoverMode={hoverMode}
        name={name}
        form={form}
        type={type}
        $padding={padding}
        $margin={margin}
        $isLoading={isLoading}
        onClick={onClick}
        $variant={variant}
        $compact={compact}
        $baseColor={baseColor}
        disabled={disabled}
        active={$active}
        wide={wide}
        boxShadow={boxShadow}
        hasNotifications={hasNotifications}
        style={style}
      >
        {isLoading && <Spinner $margin="auto 10px auto -5px" />}
        <S.StyledBtnContent>
          <ButtonContent>
            {children}
            {hasNotifications && (
              <NotificationWrapper>
                <Notification />
              </NotificationWrapper>
            )}
          </ButtonContent>
        </S.StyledBtnContent>
      </S.StyledBtn>
    </>
  );
};

export default Button;
