import Button from 'app/components/UI/Button/Button';
import { FC, useContext } from 'react';
import { useSuggestionsData } from '../../../MultiGenerationResults/hooks/useSuggestionsData';
import { PitchPageContext } from '../../../../PitchPage.context';

const CancelButton: FC = () => {
  const { clearResponseState } = useSuggestionsData();

  const {
    isRapidGenerationResultsView,
    resetSelectedItems,
    setIsRapidGenerationResultsView,
    setIsRapidGenerationView,
  } = useContext(PitchPageContext);

  const handleCloseClick = () => {
    if (isRapidGenerationResultsView) {
      clearResponseState();
      resetSelectedItems();
      setIsRapidGenerationResultsView(false);
    } else {
      setIsRapidGenerationView(false);
    }
  };

  return (
    <Button variant="gray-smoke" onClick={handleCloseClick}>
      Cancel
    </Button>
  );
};

export default CancelButton;
