import GenerateIconBlue from 'app/assets/icons/GenerateIconBlue';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import Gap from 'app/components/UI/Gap';
import { Dispatch, FC, SetStateAction } from 'react';

interface IProps {
  setIsGenerationView: Dispatch<SetStateAction<boolean>>;
}

const MultiPitchGenerationButton: FC<IProps> = ({ setIsGenerationView }) => {
  return (
    <Button variant="secondary-blue" onClick={() => setIsGenerationView(true)}>
      <GenerateIconBlue />
      <Gap size={8} />
      <Typography.Text
        $dmSans
        $bold
        $size={16}
        $lineHeight={24}
        $colorName="orbit"
      >
        Multi-Pitch
      </Typography.Text>
    </Button>
  );
};

export default MultiPitchGenerationButton;
