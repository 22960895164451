import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ $height?: boolean; open: boolean }>(
  ({ theme, open, $height }) => css`
    position: fixed;
    bottom: 0;
    /* left: 260px; */
    right: 0;
    width: 550px;
    height: ${$height ? '180px' : '160px'};
    background: #fff;
    z-index: 51;
    border-top: 1px solid ${theme.colors.smoke};
    transition: 0.12s all ease-in-out;
    transform: translateY(${open ? '0%' : '100%'});
    //overflow-y: scroll;
    transition-delay: ${open ? 0 : 0.1}s;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    //overflow: hidden;
  `,
);
