import { SVGProps } from 'react';

const CheckCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 48}
    height={props.height || 48}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10 .5Zm4.524 7.837-5.359 5.115a.733.733 0 0 1-1.01 0l-2.68-2.558a.731.731 0 0 1 1.011-1.057l2.174 2.076 4.854-4.634a.731.731 0 0 1 1.01 1.058Z"
      fill={props.fill || '#000'}
      fillRule="evenodd"
    />
  </svg>
);

export default CheckCircleIcon;
