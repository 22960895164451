import { AuthorSocialType, IPaginationRequestPart } from 'app/api.types';
import Avatar from 'app/components/UI/Avatar';
import { FC, ReactNode, useMemo } from 'react';

import {
  FacebookIcon,
  LinkedInIcon,
  MediumIcon,
  TwitchIcon,
  TwitterIcon,
  PinterestIcon,
  EmailIcon,
  YouTubeIcon,
  GooglePlusIcon,
  TikTokIcon,
  SnapChatIcon,
  InstagramIcon,
} from 'app/assets/icons/social-icons';
import {
  validateEmailField,
  validatePhoneField,
  validateSocialHandleField,
  validateUrlField,
} from './Socials.helpers';

interface IDigDeeperSocialIconProps {
  socialType: string;
  size?: string;
}

export interface ISocialTypeOption {
  value: string;
  label: string;
  icon?: ReactNode;
  validateField?: (value: string) => boolean;
  maxFieldsOfType: number;
  hiddenInDigDeeperHeader: boolean;
}

export const defaultSocialFieldsPaginationOptions: IPaginationRequestPart = {
  page: { page: 0, size: 0 },
  sort: [],
};

export const DigDeeperSocialIcon: FC<IDigDeeperSocialIconProps> = ({
  socialType,
  size = '32',
}) => {
  const icon = useMemo(() => {
    const avatarProps = { size, margin: '0' };

    switch (socialType) {
      case 'TWITTER':
        return <Avatar name={'X Link'} url={TwitterIcon} {...avatarProps} />;
      case 'FACEBOOK':
        return (
          <Avatar name={'Facebook Link'} url={FacebookIcon} {...avatarProps} />
        );
      case 'EMAIL':
        return <Avatar name={'Email'} url={EmailIcon} {...avatarProps} />;
      case 'PRIMARY_EMAIL':
        return (
          <Avatar name={'Primary Email'} url={EmailIcon} {...avatarProps} />
        );
      case 'SECONDARY_EMAIL':
        return (
          <Avatar name={'Secondary Email'} url={EmailIcon} {...avatarProps} />
        );
      case 'MEDIUM':
        return (
          <Avatar name={'Medium Link'} url={MediumIcon} {...avatarProps} />
        );
      case 'INSTAGRAM':
        return (
          <Avatar
            name={'Instagram Link'}
            url={InstagramIcon}
            {...avatarProps}
          />
        );
      case 'LINKEDIN':
        return (
          <Avatar name={'LinkedIn Link'} url={LinkedInIcon} {...avatarProps} />
        );
      case 'SNAPCHAT':
        return (
          <Avatar name={'SnapChat Link'} url={SnapChatIcon} {...avatarProps} />
        );
      case 'YOUTUBE':
        return (
          <Avatar name={'YouTube Link'} url={YouTubeIcon} {...avatarProps} />
        );
      case 'PINTEREST':
        return (
          <Avatar
            name={'Pinterest Link'}
            url={PinterestIcon}
            {...avatarProps}
          />
        );
      case 'TWITCH':
        return (
          <Avatar name={'Twitch Link'} url={TwitchIcon} {...avatarProps} />
        );
      case 'GOOGLE_PLUS':
        return (
          <Avatar name={'Google+ Link'} url={GooglePlusIcon} {...avatarProps} />
        );
      case 'TIK_TOK':
        return (
          <Avatar name={'Tik Tok Link'} url={TikTokIcon} {...avatarProps} />
        );
      case 'PHONE_NUMBER':
        return <Avatar name={'Phone Number'} {...avatarProps} />;
      default:
        return <Avatar name={socialType} {...avatarProps} />;
    }
  }, [socialType, size]);

  return <>{icon}</>;
};

export const SOCIAL_FIELDS_CONFIG = [
  {
    value: 'PRIMARY_EMAIL',
    label: 'Primary Email',
    icon: <DigDeeperSocialIcon socialType="EMAIL" />,
    validateField: validateEmailField,
    maxFieldsOfType: 1,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'SECONDARY_EMAIL',
    label: 'Secondary Email',
    icon: <DigDeeperSocialIcon socialType="EMAIL" />,
    validateField: validateEmailField,
    maxFieldsOfType: 9,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'PHONE_NUMBER',
    label: 'Phone Number',
    icon: <DigDeeperSocialIcon socialType="PHONE_NUMBER" />,
    validateField: validatePhoneField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: true,
  },
  {
    value: 'FACEBOOK',
    label: 'Facebook',
    icon: <DigDeeperSocialIcon socialType="FACEBOOK" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'TWITTER',
    label: 'X',
    icon: <DigDeeperSocialIcon socialType="TWITTER" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'INSTAGRAM',
    label: 'Instagram',
    icon: <DigDeeperSocialIcon socialType="INSTAGRAM" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'MEDIUM',
    label: 'Medium',
    icon: <DigDeeperSocialIcon socialType="MEDIUM" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'LINKEDIN',
    label: 'LinkedIn',
    icon: <DigDeeperSocialIcon socialType="LINKEDIN" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'SNAPCHAT',
    label: 'SnapChat',
    icon: <DigDeeperSocialIcon socialType="SNAPCHAT" />,
    validateField: validateSocialHandleField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'PINTEREST',
    label: 'Pinterest',
    icon: <DigDeeperSocialIcon socialType="PINTEREST" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'YOUTUBE',
    label: 'YouTube',
    icon: <DigDeeperSocialIcon socialType="YOUTUBE" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'TWITCH',
    label: 'Twitch',
    icon: <DigDeeperSocialIcon socialType="TWITCH" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
  {
    value: 'GOOGLE_PLUS',
    label: 'Google Plus',
    icon: <DigDeeperSocialIcon socialType="GOOGLE_PLUS" />,
    validateField: validateUrlField,
    maxFieldsOfType: 10,
    hiddenInDigDeeperHeader: false,
  },
];
