// Validator functions for social fields
export const validateUrlField = (url: string) => {
  try {
    const newUrl = new URL(url);
    return (
      (newUrl.protocol === 'http:' || newUrl.protocol === 'https:') &&
      newUrl.hostname.includes('.')
    );
  } catch {
    return false;
  }
};

export const validateEmailField = (email: string) => {
  const emailValidationRegexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return Boolean(emailValidationRegexp.test(String(email).toLowerCase()));
};

export const validatePhoneField = (phone: string) => {
  const phoneNumberRegExp = new RegExp('^[0-9 ()+-]*$', 'g');
  return phoneNumberRegExp.test(phone);
};

export const validateSocialHandleField = (handle: string) => {
  // To Do: add validation for non standart social handles
  return true;
};

// Link generators for social fields
export const generateSocialAnchorHref = (handle: string, type: string) => {
  if (type === 'PRIMARY_EMAIL' || type === 'SECONDARY_EMAIL') {
    return `mailto:${handle}`;
  }

  if (type === 'PHONE_NUMBER') {
    return `tel:${handle}`;
  }

  return handle;
};
