import {
  IPodcastDemographics,
  PodcastDemographicsDataEntry,
} from 'app/api.types';
import { useMemo } from 'react';
import { demographicsConfig } from '../PodcastDemographic.config';

export const useDemographicInformation = (
  demographicsData: IPodcastDemographics,
) => {
  const demographics = useMemo(() => {
    if (!demographicsData) return null;

    const breakdown = (
      Object.keys(demographicsData) as (keyof typeof demographicsData)[]
    )
      .filter(
        key =>
          demographicsData[key] &&
          demographicsConfig.availableBreakdownKeys.includes(key),
      )
      .map(key => {
        return {
          data: demographicsData[key] as PodcastDemographicsDataEntry | number,
          meta: demographicsConfig.breakdowns[
            key as keyof typeof demographicsConfig.breakdowns
          ],
        };
      });

    const segments = (
      Object.keys(demographicsData) as (keyof typeof demographicsData)[]
    )
      .filter(
        key =>
          demographicsData[key] &&
          demographicsConfig.availableSegmentsKeys.includes(key),
      )
      .map(key => {
        return {
          data: demographicsData[key] as PodcastDemographicsDataEntry[],
          meta: demographicsConfig.segments[
            key as keyof typeof demographicsConfig.segments
          ],
        };
      });

    const geographies = (
      Object.keys(demographicsData) as (keyof typeof demographicsData)[]
    )
      .filter(
        key =>
          demographicsData[key] &&
          demographicsConfig.availableGeographiesKeys.includes(key),
      )
      .map(key => {
        return {
          data: demographicsData[key] as PodcastDemographicsDataEntry[],
          meta: demographicsConfig.geographies[
            key as keyof typeof demographicsConfig.geographies
          ],
        };
      });

    return {
      breakdown,
      segments,
      geographies,
    };
  }, [demographicsData]);

  return demographics;
};
