import { FC, useContext } from 'react';
import { ToolbarTitlePortalContext } from './ToolbarTitlePortal.context';
import ArrowLeftIcon from '../../../../assets/icons/ArrowLeftIcon';
import { BackButton } from '../Toolbar/Toolbar.styles';
import { useHistory } from 'react-router-dom';
import Typography from '../../../Typography';
import FlexBlock from '../../../UI/FlexBlock';

interface IProps {}

const ToolbarTitlePortalConsumer: FC<IProps> = () => {
  const history = useHistory();
  const context = useContext(ToolbarTitlePortalContext);

  const handleBackButtonClick = () => {
    history.push(context.backButtonRoute || '');
  };

  const handleGoBackButtonClick = () => {
    history.goBack();
  };

  return (
    <FlexBlock alignItems="center">
      {context.goBackButton && (
        <BackButton onClick={handleGoBackButtonClick}>
          <ArrowLeftIcon />
        </BackButton>
      )}

      {context.backButtonRoute && (
        <BackButton onClick={handleBackButtonClick}>
          <ArrowLeftIcon />
        </BackButton>
      )}

      {context.title && (
        <>
          <Typography.Text $dmSans $bold $size={24} $colorName="white">
            {context.title}
          </Typography.Text>
        </>
      )}

      {context.node && <>{context.node}</>}
    </FlexBlock>
  );
};

export default ToolbarTitlePortalConsumer;
