import styled, { css, keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  0% { background-position: 100% 0 }
  100% { background-position: -100% 0 }
`;

interface SkeletonDivProps {
  borderRadius?: string;
  height?: string;
  width?: string;
  shimmerAnimation?: boolean;
  backgroundColor?: string;
}

export const SkeletonDiv = styled.div<SkeletonDivProps>`
  border-radius: ${props => props.borderRadius};
  height: ${props => props.height || '20px'};
  width: ${props => props.width || '100%'};
  background-color: ${props =>
    props.backgroundColor || props.theme.colors.haze};

  ${props =>
    props.shimmerAnimation &&
    css`
      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: ${shimmerAnimation};
      animation-timing-function: linear;
      background: white;
      background: linear-gradient(
        to right,
        #f6f7f8 0%,
        white 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
      );
      background-size: 200% 100%;
      position: relative;
    `}
`;
