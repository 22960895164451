import { SVGProps } from 'react';

const MoneyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Pitch" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SXSW-2.0.0.4" transform="translate(-1408.000000, -712.000000)">
        <g id="Panel" transform="translate(1368.000000, -338.000000)">
          <g id="demo" transform="translate(0.000000, 726.000000)">
            <g id="metric" transform="translate(24.000000, 304.000000)">
              <g id="money-fill" transform="translate(16.000000, 20.000000)">
                <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                <path
                  d="M21,16 C21,18.7614237 18.7614237,21 16,21 C13.2385763,21 11,18.7614237 11,16 C11,13.2385763 13.2385763,11 16,11 C18.7614237,11 21,13.2385763 21,16 Z M31,8 L31,24 C31,24.5522847 30.5522847,25 30,25 L2,25 C1.44771525,25 1,24.5522847 1,24 L1,8 C1,7.44771525 1.44771525,7 2,7 L30,7 C30.5522847,7 31,7.44771525 31,8 Z M29,13.79375 C26.6928877,13.1115829 24.8884171,11.3071123 24.20625,9 L7.79375,9 C7.11158286,11.3071123 5.30711233,13.1115829 3,13.79375 L3,18.20625 C5.30711233,18.8884171 7.11158286,20.6928877 7.79375,23 L24.20625,23 C24.8884171,20.6928877 26.6928877,18.8884171 29,18.20625 L29,13.79375 Z"
                  id="Shape"
                  fill="#3F3F42"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MoneyIcon;
