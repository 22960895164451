import { IS_ENV_LOCAL } from 'utils/constants';
import { userPermissionEnum } from './userPermissions.enum';

type SatisfiesPermissionFn = (
  required: userPermissionEnum,
  userPermissions: userPermissionEnum[],
) => boolean;

export const satisfiesPermission: SatisfiesPermissionFn = (
  requiredPermission,
  userPermissions,
) => {
  const satisfies = userPermissions.includes(requiredPermission);
  if (!satisfies && IS_ENV_LOCAL) {
    console.info(
      `User does not have required permission: "${requiredPermission}"`,
    );
  }
  return satisfies;
};
