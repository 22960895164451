import {
  IPitch,
  IPitchPrediction,
  IPressRelease,
} from '../../../../../../../api.types';
import { FC, useMemo } from 'react';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import Typography from '../../../../../../../components/Typography';
import { formatDate } from '../../../../../../../../utils/helpers';
import Pill from '../../../../../../../components/UI/Pill';
import * as S from '../PdfExport.styles';
import ProphetDarkLogoIcon from '../../../../../../../assets/icons/ProphetDarkLogo';

interface IProps {
  pitch: IPitch | IPressRelease;
  prediction: IPitchPrediction;
  contentType: 'PITCH' | 'PRESS_RELEASE';
}

const PdfPageHeader: FC<IProps> = ({ pitch, prediction, contentType }) => {
  const { headline, publishDate } = useMemo(() => {
    if (contentType === 'PRESS_RELEASE') {
      return {
        headline: (pitch as IPressRelease).title,
        publishDate: formatDate(pitch.createdAt),
      };
    }

    return {
      headline: (pitch as IPitch).headline,
      publishDate: formatDate((pitch as IPitch).publishDate),
    };
  }, [contentType, pitch]);

  return (
    <S.PageHeader>
      <ProphetDarkLogoIcon />
      <FlexBlock flex={1} justifyContent="center">
        <Typography.Text $dmSans $bold $size="24">
          {headline}
        </Typography.Text>
      </FlexBlock>
      <FlexBlock justifyContent="flex-end" alignItems="center" columnGap="16px">
        <Typography.Text $colorName="steel">{publishDate}</Typography.Text>
        <Pill variant="pitch-version" pitchVersion={pitch.status}>
          V{prediction.version}
        </Pill>
      </FlexBlock>
    </S.PageHeader>
  );
};

export default PdfPageHeader;
