import styled, { css } from 'styled-components/macro';

export const Button = styled.button(
  ({ theme }) => css`
    width: 100%;
    padding: 12px 16px;
    background-color: ${theme.colors.haze};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 12px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.125s all ease-in-out;

    &:hover {
      border: 1px solid ${theme.colors.smoke};
    }
  `,
);
