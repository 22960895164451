import CheckIcon from 'app/assets/icons/CheckIcon';
import { forwardRef, useEffect, useRef } from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';
import { showErrorToast } from 'utils/toast';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

type CheckBoxProps = {
  indeterminate?: boolean;
  limited: boolean;
};

// Override inherited styles from the React Table's Header (applies to SVG's inside header cells by default)
const OVERRIDEN_ICON_STYLES = {
  transform: 'none',
  marginLeft: '0px',
};

export const CheckBoxContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 16px;
  width: 16px;
  border: 1px solid black;
  border-radius: 2px;
  cursor: pointer;
`;

export const HiddenCheckBox = styled.input`
  position: absolute;
  display: none;
  height: 0;
  width: 0;
`;

export const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  CheckBoxProps
>(
  (
    { indeterminate, ...rest }: Partial<TableToggleAllRowsSelectedProps>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const defaultRef = useRef(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (typeof resolvedRef === 'object' && resolvedRef.current) {
        resolvedRef.current.indeterminate = Boolean(indeterminate);
      }
    }, [resolvedRef, indeterminate]);

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
      //@ts-ignore
      if (!rest.checked && rest.limited) {
        showErrorToast('Cannot select more journalists');
      } else {
        if (rest.onChange) {
          rest.onChange({ ...e, target: e.currentTarget });
          ReactTooltip.hide();
        }
      }
    };

    return (
      <CheckBoxContainer onClick={e => e.stopPropagation()}>
        <HiddenCheckBox
          type="checkbox"
          onChange={onChange}
          checked={rest.checked}
          ref={resolvedRef}
        />
        {indeterminate && <div>-</div>}

        {rest.checked && (
          <div>
            <CheckIcon style={OVERRIDEN_ICON_STYLES} />
          </div>
        )}
      </CheckBoxContainer>
    );
  },
);
