import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import {
  ContentHeader,
  ContentLayout,
  ContentFooter,
} from 'app/components/UI/ContentLayout/ContentLayout';
import FlexBlock from 'app/components/UI/FlexBlock';
import MainGrid from 'app/components/UI/MainGrid/MainGrid';
import { FC } from 'react';
import BlogHeader from './parts/BlogHeader';
import Gap from 'app/components/UI/Gap';
import { useHistory } from 'react-router-dom';

import CreateBlogForm from './parts/CreateBlogForm';
import ProjectAvatar from 'app/components/ProjectAvatar/ProjectAvatar';

const CreateOrEditBlogPage: FC = () => {
  const history = useHistory();

  const handleCancelClick = () => {
    history.goBack();
  };

  return (
    <ContentLayout>
      <ContentHeader>
        <FlexBlock
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          minWidth="100%"
        >
          <FlexBlock flexDirection="row" alignItems="center">
            <ProjectAvatar size="56px" />
            <Typography.Text
              $dmSans
              $bold
              $colorName="orbit"
              $size={20}
              $lineHeight={24}
            >
              Create Content
            </Typography.Text>
          </FlexBlock>
          <Button variant="gray" onClick={handleCancelClick}>
            Cancel
          </Button>
        </FlexBlock>
      </ContentHeader>
      <MainGrid minContent>
        <FlexBlock
          gridColumnStart="1"
          gridColumnEnd="-1"
          alignItems="center"
          margin="64px auto"
          flexDirection="column"
          minWidth="715px"
        >
          <BlogHeader />
          <Gap size={30} />
          <CreateBlogForm />
        </FlexBlock>
      </MainGrid>
      <ContentFooter />
    </ContentLayout>
  );
};

export default CreateOrEditBlogPage;
