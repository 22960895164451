import DownloadAlternativeIcon from 'app/assets/icons/DownloadAlternativeIcon';
import Button from 'app/components/UI/Button/Button';
import { Dispatch, FC, SetStateAction } from 'react';

interface IProps {
  setExportModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ExportReportButton: FC<IProps> = ({ setExportModalOpen }) => {
  return (
    <Button
      variant="gray-smoke-square"
      onClick={() => setExportModalOpen(prev => !prev)}
    >
      <DownloadAlternativeIcon />
    </Button>
  );
};

export default ExportReportButton;
