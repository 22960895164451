import { SVGProps } from 'react';

const CreateListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="45"
    viewBox="0 0 49 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48.3866 17.1128C48.2317 17.4644 48.1228 17.8454 47.9135 18.1594C47.3985 18.9339 46.6491 19.3275 45.7155 19.34C44.7107 19.3526 43.7101 19.3442 42.6551 19.3442C42.6551 20.3448 42.6509 21.2994 42.6551 22.2581C42.6593 22.9154 42.5002 23.5099 42.0899 24.0333C41.3991 24.9208 40.2477 25.2809 39.176 24.9376C38.0874 24.5901 37.338 23.6188 37.3171 22.4758C37.3004 21.4543 37.3129 20.4369 37.3129 19.3735C37.1538 19.361 37.0199 19.3442 36.8901 19.34C36.0234 19.3359 35.1568 19.3442 34.2902 19.3359C32.8081 19.3191 31.6484 18.2138 31.5772 16.7611C31.5102 15.3502 32.5695 14.1277 34.039 14.0105C34.8847 13.9393 35.7429 13.9853 36.5928 13.9812C36.8063 13.9812 37.024 13.9812 37.3087 13.9812C37.3087 13.7969 37.3087 13.6504 37.3087 13.5039C37.3087 12.6372 37.2962 11.7706 37.3129 10.904C37.338 9.42609 38.4894 8.28314 39.9463 8.27476C41.4158 8.26221 42.5839 9.35911 42.6341 10.8454C42.6676 11.8627 42.6425 12.8843 42.6425 13.9435C42.8142 13.9602 42.9481 13.9853 43.0779 13.9853C43.7436 13.9895 44.4093 13.9853 45.0749 13.9853C47.0092 13.9853 47.6539 14.4124 48.3782 16.1833C48.3866 16.4931 48.3866 16.8029 48.3866 17.1128Z"
      fill="#040329"
    />
    <path
      d="M18.4982 44.0788C14.5502 44.0788 10.6022 44.0788 6.65422 44.0788C3.2128 44.0788 0.797113 41.6757 0.809673 38.2426C0.81386 36.5889 0.771993 34.9226 0.905965 33.2773C1.32044 28.1445 5.27262 23.4345 10.2463 22.032C11.5651 21.6594 12.9049 21.471 14.2781 21.4752C17.125 21.4835 19.9761 21.471 22.8272 21.4794C29.2495 21.5003 34.5707 25.8041 35.8727 32.0883C36.0737 33.0638 36.0988 34.0853 36.1365 35.0859C36.1784 36.1996 36.1533 37.3132 36.1449 38.431C36.1156 41.6422 33.6999 44.0621 30.4887 44.0704C26.4863 44.0872 22.4923 44.0788 18.4982 44.0788Z"
      fill="#040329"
    />
    <path
      d="M18.4476 19.0135C13.2059 18.9842 9.04018 14.7641 9.06949 9.51404C9.0988 4.31005 13.3608 0.156911 18.6276 0.19459C23.7353 0.23227 27.9135 4.48171 27.8926 9.6229C27.8675 14.8436 23.6432 19.0428 18.4476 19.0135Z"
      fill="#040329"
    />
  </svg>
);

export default CreateListIcon;
