import { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';

import ProtectedRoute from '../Global/permissions/ProtectedRoute';
import { userPermissionEnum } from '../Global/permissions/userPermissions.enum';
import JournalistsListPage from './pages/JournalistsListPage';

interface IProps {}

const Journalists: FC<IProps> = () => {
  return (
    <>
      <Switch>
        <ProtectedRoute
          path={RouteConstants.journalists.listWithParams}
          component={JournalistsListPage}
          requirePermission={userPermissionEnum.JOURNALIST_INFORMATION_ACCESS}
        />
        <ProtectedRoute
          path={RouteConstants.journalists.list}
          component={JournalistsListPage}
          requirePermission={userPermissionEnum.JOURNALIST_INFORMATION_ACCESS}
        />
        <Redirect
          from={RouteConstants.journalists.index}
          to={RouteConstants.journalists.list}
        />
      </Switch>
    </>
  );
};

export default Journalists;
