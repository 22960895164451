import { IContentItem, IPaginationRequestPart } from '../../../../api.types';
import { formatDate } from '../../../../../utils/helpers';
import { IEmptyDataNoticeProps } from '../../../../components/EmptyDataNotice/EmptyDataNotice';
import { IDataTableColumn } from '../../../../components/Table/Table.types';
import { TextEllipsis } from '../../../../components/Table/Table.styles';

export const defaultPaginationSettings: IPaginationRequestPart = {
  page: { page: 0, size: 10 },
  sort: [{ ascending: false, property: 'createdAt' }],
};

export const getTableColumnConfig: (
  tenantId: number,
) => IDataTableColumn<IContentItem>[] = tenantId => [
  {
    Header: 'Type',
    id: 'contentType',
    Cell: (props: any) => {
      if (!props.row.original.contentType) return <></>;

      const capitalizeContentType = (contentType: string) => {
        if (!contentType) return '';
        if (contentType.length < 2) return contentType.toUpperCase();
        return (
          contentType.charAt(0).toUpperCase() +
          contentType.slice(1).toLowerCase()
        );
      };

      const contentType = props.row.original.contentType
        .split('_')
        .map(capitalizeContentType)
        .join(' ');

      return <b>{contentType}</b>;
    },
    disableSort: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    id: 'title',
    Cell: props => (
      <TextEllipsis maxWidth="800px">{props.row.original.title}</TextEllipsis>
    ),
    disableSort: false,
  },
  {
    Header: 'Creator',
    accessor: 'creator',
    id: 'creator.firstName',
    Cell: ({ row }) => (
      <>
        {`${row.original.creator?.firstName || ''} ${
          row.original.creator?.lastName || ''
        }`}
      </>
    ),
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    id: 'createdAt',
    Cell: ({ row }) => <>{formatDate(row.original.createdAt)}</>,
    disableSort: false,
  },
  {
    Header: 'Last Edited',
    id: 'updatedAt',
    accessor: 'updatedAt',
    Cell: ({ row }) => <>{formatDate(row.original.updatedAt)}</>,
    disableSort: false,
  },
];

export const noDataNoticeConfig: IEmptyDataNoticeProps = {
  title: 'No Content',
  description: "It looks like content hasn't been added yet, let's get to it!",
};
