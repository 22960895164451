import styled, { css } from 'styled-components/macro';

export const Divider = styled.div(
  ({ theme }) => css`
    height: 1px;
    width: 100%;
    background-color: ${theme.colors.smoke};
    grid-column-start: 1;
    grid-column-end: -1;
    margin: 40px 16px;
  `,
);
