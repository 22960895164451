import styled, { css } from 'styled-components';

export const Spinner = styled.div<{
  $margin?: string;
  $isGray?: boolean;
  $isBlue?: boolean;
  $isStratos?: boolean;
  $size?: string;
}>`
  margin: ${({ $margin }) => $margin || 'auto'};
  display: inline-block;
  width: ${({ $size }) => $size || '15px'};
  height: ${({ $size }) => $size || '15px'};
  border: 3px solid
    ${({ $isGray }) =>
      $isGray ? 'rgb(209 209 209 / 30%)' : 'rgba(255, 255, 255, 0.3)'};

  border-radius: 50%;
  border-top-color: ${({ $isGray }) => ($isGray ? '#d5d5d5' : '#fff')};
  ${({ $isBlue, theme }) =>
    $isBlue
      ? css`
          border-top-color: ${theme.colors.orbit};
        `
      : ''}
  animation: spin 1s ease-in-out infinite;

  ${({ $isStratos, theme }) =>
    $isStratos
      ? css`
          border-color: ${theme.colors.aluminum};
          border-top-color: ${theme.colors.stratos};
        `
      : ''}

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;
