import { Dispatch, ReactNode, SetStateAction, useMemo } from 'react';

interface ISocialProviderSelectorOption {
  key: string;
  checked?: boolean;
  displayName: string;
  avatar?: ReactNode;
}

export const useSocialProviderSelector = (
  options: ISocialProviderSelectorOption[],
  setOptions: Dispatch<SetStateAction<ISocialProviderSelectorOption[]>>,
) => {
  const isNotAllSelected = useMemo(() => {
    return options.some(option => !option.checked);
  }, [options]);

  const isOneOrMoreSelected = useMemo(() => {
    return options.some(option => option.checked);
  }, [options]);

  const handleOptionCheck = (key: string, checked: boolean) => {
    setOptions(prevState =>
      prevState.map(option =>
        option.key === key ? { ...option, checked: checked } : option,
      ),
    );
  };

  const handleAllOptionsCheck = (checked: boolean) => {
    setOptions(prevState =>
      prevState.map(option => {
        return { ...option, checked: checked };
      }),
    );
  };

  return {
    conditions: { isNotAllSelected, isOneOrMoreSelected },
    actions: { handleOptionCheck, handleAllOptionsCheck },
  };
};
