import { FC, FormEventHandler, useEffect, useState } from 'react';
import * as S from './ForgotPasswordPage.styles';
import { NavLink } from 'react-router-dom';
import Typography from '../../../../components/Typography';
import Input from '../../../../components/UI/Input/Input';
import EnvelopeIcon from '../../../../assets/icons/EnvelopeIcon';
import { Anchor } from '../../../../components/UI/Anchor';
import { RouteConstants } from '../../../../routes';
import Button from '../../../../components/UI/Button/Button';
import { validateEmail } from '../../../../../utils/helpers';
import LogoAndCopyright from '../../parts/LogoAndCopyright/LogoAndCopyright';
import { submitForgotPassword } from '../../api';
import { showErrorToast } from '../../../../../utils/toast';
import emailIcon from 'app/assets/images/icon-forgotpassword.png';
import emailSentIcon from 'app/assets/images/icon-emailsuccess.png';

interface IProps {}

const ForgotPasswordPage: FC<IProps> = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit: FormEventHandler = async event => {
    event.preventDefault();
    if (email && validateEmail(email)) {
      try {
        await submitForgotPassword(email);
        setEmailSent(true);
      } catch (e) {
        if (e.data.errorCode === 'UserDisabledDuringPasswordResetException') {
          setEmailSent(true);
        } else {
          showErrorToast('Something went wrong.');
        }
      }
    } else {
      setError('Please enter valid email address');
    }
  };

  useEffect(() => {
    if (error) setError(undefined);
  }, [email]);

  return (
    <S.Wrapper>
      <LogoAndCopyright />
      {!emailSent ? (
        <S.Form onSubmit={handleSubmit}>
          <S.IconWrapper>
            <img src={emailIcon} alt="Email Icon" />
          </S.IconWrapper>
          <Typography.Title margin="0">Forgot Password?</Typography.Title>
          <Typography.Text>
            No worries, happens to the best of us! Just enter your email address
            and we’ll send instructions on how to reset.
          </Typography.Text>
          <Input
            icon={<EnvelopeIcon />}
            type="email"
            placeholder="Email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            errors={error ? [error] : undefined}
            fontSize={16}
          />
          <div className="submit-row">
            <NavLink to={RouteConstants.authorization.login}>
              <Anchor>Back to Login</Anchor>
            </NavLink>
            <Button type="submit">Submit</Button>
          </div>
        </S.Form>
      ) : (
        <S.Form>
          <S.IconWrapper>
            <img src={emailSentIcon} alt="Email Sent Icon" />
          </S.IconWrapper>
          <Typography.Title margin="0">Email Sent!</Typography.Title>
          <Typography.Text>
            If you have a PRophet account an email will be sent to you. If you
            have an account and didn't receive an email, please contact support.
          </Typography.Text>
          <div className="center-row">
            <NavLink to={RouteConstants.authorization.login}>
              <Button type="button">Login</Button>
            </NavLink>
          </div>
        </S.Form>
      )}
    </S.Wrapper>
  );
};

export default ForgotPasswordPage;
