import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ margin?: string; padding?: string }>(
  ({ margin = '0', padding = 0 }) => css`
    margin: ${margin};
    padding: ${padding};
  `,
);

export const Content = styled.div<{ expanded?: boolean }>(
  ({ expanded, theme }) => css`
    //max-height: 600px;
    //overflow: hidden;
    position: relative;
    .text {
      font-size: 14px;
      color: ${theme.colors.nero};
    }
    .text {
      padding-bottom: 90px;
    }
    ${expanded
      ? css`
          .text {
            padding-bottom: 135px;
          }
        `
      : ''}
  `,
);

export const ShowMoreOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 84px 0 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background: linear-gradient(
    180deg,
    rgba(242, 242, 242, 0) 0%,
    rgba(255, 255, 255, 1) 45%
  );
  width: 100%;
`;

export const TextWrapper = styled.div<{ $expanded: boolean; lineCap?: number }>(
  ({ $expanded, lineCap = 10, theme }) => css`
    ${!$expanded &&
    css`
      color: ${theme.colors.nero};
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${lineCap}; /* number of lines to show */
      line-clamp: ${lineCap};
      -webkit-box-orient: vertical;
    `}
  `,
);
