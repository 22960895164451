import { ImageFile, ImageURL } from 'app/components/FileDropzone/ImageDropzone';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { showErrorToast } from 'utils/toast';

export const useUserAvatar = () => {
  const [imageResource, setImageResource] = useState<
    ImageURL | ImageFile | null
  >(null);

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> = useCallback(
    async event => {
      if (event.target.files && event.target.files[0]) {
        if (!event.target.files[0].type.match('image')) {
          return showErrorToast(
            'Please use .png, .jpg or .jpeg',
            'Invalid file',
          );
        }

        setImageResource({ type: 'File', content: event.target.files[0] });
      }
    },
    [],
  );

  const handleImageFileLoad = useCallback(
    (file: ImageFile | ImageURL | null) => {
      setImageResource(file);
    },
    [],
  );

  return {
    image: imageResource,
    handleFileSelect,
    handleImageFileLoad,
    setImageResource,
  };
};
