import RemoveIcon from 'app/assets/icons/RemoveIcon';
import styled, { css } from 'styled-components/macro';

export const Image = styled.div<{ src?: string; clickable?: boolean }>(
  ({ theme, src, clickable }) => css`
    height: 120px;
    width: 120px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.aluminum};
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s all ease-in-out;

    ${clickable
      ? css`
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        `
      : ''}

    ${src
      ? css`
          background-image: url('${src}');
          background-size: auto 100%;
        `
      : css`
          &:before {
            @keyframes spin {
              0% {
                transform: translate(-50%, -50%) rotate(0deg);
              }
              100% {
                transform: translate(-50%, -50%) rotate(360deg);
              }
            }
            content: ${src === undefined ? 'none' : '""'};
            position: absolute;
            border: 5px solid ${theme.colors.aluminum};
            border-left: 5px solid ${theme.colors.smoke};
            border-top: 5px solid ${theme.colors.smoke};
            width: 24px;
            height: 24px;
            border-radius: 50px;
            top: 50%;
            left: 50%;
            animation: 1s spin infinite;
            animation-timing-function: linear;
          }
        `}
  `,
);

export const ImageContainer = styled.div`
  position: relative;
`;

export const RemoveButtonContainer = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4), transparent);
  justify-content: flex-end;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s opacity, 0.3s visibility;
  ${ImageContainer}:hover & {
    opacity: 1 !important;
    visibility: visible !important;
  }
`;

export const RemoveButton = styled(RemoveIcon)`
  cursor: pointer;
  padding: 10px;
  box-sizing: content-box;
  opacity: 0.8;
  transition: 0.3s opacity;
  width: 12px;
  height: 12px;
  &:hover {
    opacity: 1;
  }

  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;
