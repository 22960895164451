import {
  IContentLengthSelectOption,
  IToneSelectOption,
  ContentLengthValueEnum,
  ToneValueEnum,
} from './ToneSelectDropDown.types';
import { PitchContentName } from '../../CreateOrEditPitch.types';

import {
  ToneProfessionalIcon,
  ToneFriendlyIcon,
  ToneLuxuryIcon,
  TonePersuasiveIcon,
  ToneBoldIcon,
  ToneAdventurousIcon,
  ToneApologeticIcon,
  ToneTechnicalIcon,
  ToneContriteIcon,
  ToneEmotiveIcon,
  ToneExuberantIcon,
} from '../../../../../../assets/icons/tone-icons';

export const TONE_SELECT_OPTIONS: IToneSelectOption[] = [
  {
    name: 'Professional',
    value: ToneValueEnum.professional,
    icon: ToneProfessionalIcon,
  },
  {
    name: 'Friendly',
    value: ToneValueEnum.friendly,
    icon: ToneFriendlyIcon,
  },
  {
    name: 'Luxury',
    value: ToneValueEnum.luxury,
    icon: ToneLuxuryIcon,
  },
  {
    name: 'Persuasive',
    value: ToneValueEnum.persuasive,
    icon: TonePersuasiveIcon,
  },
  {
    name: 'Bold',
    value: ToneValueEnum.bold,
    icon: ToneBoldIcon,
  },
  {
    name: 'Adventurous',
    value: ToneValueEnum.adventurous,
    icon: ToneAdventurousIcon,
  },
  {
    name: 'Technical',
    value: ToneValueEnum.technical,
    icon: ToneTechnicalIcon,
  },
  {
    name: 'Apologetic',
    value: ToneValueEnum.apologetic,
    icon: ToneApologeticIcon,
  },
  {
    name: 'Contrite',
    value: ToneValueEnum.contrite,
    icon: ToneContriteIcon,
  },
  {
    name: 'Emotive',
    value: ToneValueEnum.emotive,
    icon: ToneEmotiveIcon,
  },
  {
    name: 'Exuberant',
    value: ToneValueEnum.exuberant,
    icon: ToneExuberantIcon,
  },
];

export const CONTENT_LENGTH_SELECT_OPTIONS: IContentLengthSelectOption[] = [
  {
    name: 'To The Point',
    value: ContentLengthValueEnum.toThePoint,
    subtitle: 'Short, and to the point, 150 words or less',
  },
  {
    name: 'Elaborative',
    value: ContentLengthValueEnum.elaborative,
    subtitle: 'Thought provoking, at least 250 words',
  },
];

export const getContentTypeString = (contentType: PitchContentName) => {
  if (contentType === 'original') return 'Original Content';
  if (contentType === 'generated') return 'Generated Content';
  return '';
};
