import { SVGProps } from 'react';

const CakeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Pitch" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SXSW-2.0.0.4" transform="translate(-1408.000000, -632.000000)">
        <g id="Panel" transform="translate(1368.000000, -338.000000)">
          <g id="demo" transform="translate(0.000000, 726.000000)">
            <g id="metric" transform="translate(24.000000, 224.000000)">
              <g id="cake-fill" transform="translate(16.000000, 20.000000)">
                <rect
                  id="Rectangle"
                  x="0"
                  y="0.000649639693"
                  width="32"
                  height="31.9993504"
                ></rect>
                <path
                  d="M26,11.0004263 L17,11.0004263 L17,9.87544916 C18.7652632,9.4169392 19.9982756,7.82433044 20,6.00052783 C20,2.50059889 16.695,0.261894336 16.555,0.168146239 C16.2189631,-0.0560487464 15.7810369,-0.0560487464 15.445,0.168146239 C15.305,0.261894336 12,2.50059889 12,6.00052783 C12.0017244,7.82433044 13.2347368,9.4169392 15,9.87544916 L15,11.0004263 L6,11.0004263 C4.34314575,11.0004263 3,12.3435448 3,14.0003654 L3,16.9165562 C3.00102599,18.0074374 3.35148362,19.0693024 4,19.9464947 L4,25.0001421 C4,26.6569627 5.34314575,28.0000812 7,28.0000812 L25,28.0000812 C26.6568542,28.0000812 28,26.6569627 28,25.0001421 L28,19.9464947 C28.6485164,19.0693024 28.998974,18.0074374 29,16.9165562 L29,14.0003654 C29,12.3435448 27.6568542,11.0004263 26,11.0004263 Z M14,6.00052783 C14,4.30431227 15.25,2.9430899 16,2.27685343 C16.75,2.9430899 18,4.30431227 18,6.00052783 C18,7.10507491 17.1045695,8.00048723 16,8.00048723 C14.8954305,8.00048723 14,7.10507491 14,6.00052783 Z M27,16.9165562 C27,18.5727726 25.6925,19.9552445 24.085,19.9989936 C23.2749442,20.021954 22.4900555,19.7162276 21.908964,19.1513936 C21.3278725,18.5865597 21.0000123,17.8106692 21,17.0003045 C21,16.448031 20.5522847,16.0003248 20,16.0003248 C19.4477153,16.0003248 19,16.448031 19,17.0003045 C19,18.6571251 17.6568542,20.0002436 16,20.0002436 C14.3431458,20.0002436 13,18.6571251 13,17.0003045 C13,16.448031 12.5522847,16.0003248 12,16.0003248 C11.4477153,16.0003248 11,16.448031 11,17.0003045 C11.0003254,17.8108861 10.6726136,18.5871018 10.0914843,19.152208 C9.51035497,19.7173142 8.72527266,20.0232102 7.915,20.0002436 C6.3075,19.9552445 5,18.5727726 5,16.9165562 L5,14.0003654 C5,13.4480919 5.44771525,13.0003857 6,13.0003857 L26,13.0003857 C26.5522847,13.0003857 27,13.4480919 27,14.0003654 L27,16.9165562 Z"
                  id="Shape"
                  fill="#3F3F42"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CakeIcon;
