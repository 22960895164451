import {
  IPaginationRequestPart,
  ProSubscriptionPeriod,
} from '../app/api.types';

// export const BASE_API_URL =
//   `${process.env.REACT_APP_BASE_URL}api/` || 'http://localhost:3000/api/';
export const BASE_API_URL =
  process.env.REACT_APP_REST_API_BASE || 'http://localhost:3000/api/';

export const YOODLI_LOGIN_LINK = 'https://prprophet.yoodli.ai/login';

export const NEWS_WIRE_LINK =
  'https://app.eznewswire.com/landing_pages/prophet';

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const IS_ENV_LOCAL = Boolean(process.env.REACT_APP_IS_LOCAL_ENV);

export const FEATURE_FLAG_ENABLE_SSO =
  process.env.REACT_APP_FEATURE_FLAG_ENABLE_SSO === undefined
    ? true
    : process.env.REACT_APP_FEATURE_FLAG_ENABLE_SSO === 'true';

export const IS_ENV_PROD = Boolean(
  process.env.REACT_APP_ENVIRONMENT === 'production',
);

export const MONITOR_URL = IS_ENV_PROD
  ? 'https://monitor.prprophet.ai/alerts'
  : 'https://prophetm.stgtchinfra.com/alerts';

export const MONITOR_COOKIE_DOMAIN = IS_ENV_PROD
  ? '.prprophet.ai'
  : '.stgtchinfra.com';

export const STRIPE_PK_KEY =
  process.env.REACT_APP_STRIPE_KEY ||
  'pk_test_51J7LGwErgp0Gdhy3UR9rHSLM28QL2fmLKsidXl3yK5jsjbkeIvPS1yD9D95oIu2BohKPyHDnQAb6fxAS1kMee9gI00XigizISH';
export const STRIPE_PRO_PRODUCT_ID =
  process.env.REACT_APP_STRIPE_UPGRADE_PRO_PRODUCT_ID || 'prod_NXjGMzjmbeaqEL';

export const STRIPE_UPGRADE_PRO_MONTHLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_UPGRADE_PRO_MONTHLY_PRICE_ID;
export const STRIPE_UPGRADE_PRO_YEARLY_PRICE_ID =
  process.env.REACT_APP_STRIPE_UPGRADE_PRO_YEARLY_PRICE_ID;

export const STRIPE_UPGRADE_PRO_PRICE_IDS = {
  [ProSubscriptionPeriod.MONTHLY]: STRIPE_UPGRADE_PRO_MONTHLY_PRICE_ID,
  [ProSubscriptionPeriod.YEARLY]: STRIPE_UPGRADE_PRO_YEARLY_PRICE_ID,
};

export const PITCH_PAGE_HEADER_HEADLINE_TEXT_LENGTH_LIMIT = 108;
export const SESSION_LOGOUT_DELAY = 5.4e6; // 90min
// export const SESSION_LOGOUT_DELAY = 10 * 1000; // 10s
export const TENANT_HISTORY_ITEMS_SAVED = 4;

export const PITCH_BODY_MIN_WORD_COUNT = 30;

export const BASIC_AUTOCOMPLETE_PAGINATION: IPaginationRequestPart = {
  page: {
    page: 0,
    size: 5,
  },
  sort: [{ property: 'name', ascending: true }],
};

export const BASIC_100_PAGINATION: IPaginationRequestPart = {
  page: {
    page: 0,
    size: 100,
  },
  sort: [{ property: 'name', ascending: true }],
};
