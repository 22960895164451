import styled, { css } from 'styled-components/macro';
import { alignItemsType } from '../../../types/commonTypes';

export const Form = styled.form`
  grid-column-start: 1;
  grid-column-end: 7;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: min-content;
  //border: 1px solid red;

  @media (max-width: ${({ theme }) => theme.layout.smallDesktopMaxWidth}) {
    grid-column-start: 1;
    grid-column-end: -1;
  }
`;

export const Wrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: -1;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: min-content;
  overflow-y: auto;
  height: 100%;
  //border: 1px solid red;
`;

export interface IFormRowProps {
  alignItems?: alignItemsType;
  padding?: string;
  columnEnd?: string | number;
  columnStart?: string | number;
  vertical?: boolean;
}

export const Row = styled.div<IFormRowProps>(
  ({
    alignItems = 'flex-start',
    padding = '0 0 0 25px',
    columnEnd = 8,
    columnStart = 1,
    vertical,
  }) => css`
    padding: ${padding};
    display: flex;
    grid-column-start: ${columnStart};
    grid-column-end: ${columnEnd};
    justify-content: ${alignItems};

    ${vertical
      ? css`
          flex-direction: column;
          //row-gap: 16px;
        `
      : ''}
  `,
);

export const Section = styled.div<{
  flex?: string | number;
  alignItems?: alignItemsType;
  padding?: string;
}>(
  ({ flex, alignItems = 'flex-start', padding = '12px 0 0 0' }) => css`
    ${flex
      ? css`
          flex: ${flex};
        `
      : ''}

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${alignItems};
    padding: ${padding};
  `,
);

export const Divider = styled.div(
  ({ theme }) => css`
    height: 1px;
    background-color: ${theme.colors.smoke};
    grid-column-start: 1;
    grid-column-end: -1;
    margin: 40px 16px;
  `,
);

export const Menu = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${p => p.theme.colors.smoke};
  padding: 14px 8px;
  gap: 2px;
  height: 100%;
`;

export const MenuButton = styled.button<{ active?: boolean }>(
  ({ active, theme }) => css`
    padding: 14px 16px;
    color: ${theme.colors.onyx};
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 14px;
    font-family: ${theme.fonts.inter};
    border-radius: 8px;
    transition: 0.125s ease-in-out;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #737377;
      }
    }

    &:hover {
      background-color: ${theme.colors.smoke};
    }

    ${active
      ? css`
          background-color: rgba(29, 118, 222, 0.1);
          &:hover {
            background-color: rgba(29, 118, 222, 0.1);
          }
          svg path {
            fill: ${theme.colors.onyx};
          }
        `
      : ''}
  `,
);
