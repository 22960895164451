import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import {
  ITenant,
  IPaginationRequestPart,
  ITableData,
  IUser,
} from '../../../../api.types';
import {
  defaultGroupsPaginationSettings,
  defaultUsersPaginationSettings,
  groupsTableColumnConfig,
  noDataNoticeConfig,
  usersTableColumnConfig,
} from './PeopleList.constants';
import MainGrid from '../../../../components/UI/MainGrid/MainGrid';
import Input from '../../../../components/UI/Input/Input';
import SearchGrayIcon from '../../../../assets/icons/SearchGrayIcon';
import Button from '../../../../components/UI/Button/Button';
import FlexBlock from '../../../../components/UI/FlexBlock';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';
import PageLoader from '../../../../components/UI/PageLoader';
import IfHasPermission from '../../../Global/permissions/IfHasPermission';
import { userPermissionEnum } from '../../../Global/permissions/userPermissions.enum';
import useDebounce from 'utils/hooks/useDebounce';
import { TenantsView, useData } from './PeopleList.hooks';
import TenantsTable from './parts/TenantsTable';
import TableTabs from 'app/components/Table/parts/TableTabs/TableTabs';
import TenantModal from './parts/TenantModal/TenantModal';
import UserModal from './parts/UserModal/UserModal';
import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import { ContentLayout } from './PeopleList.styles';
import { ContentHeader } from 'app/components/UI/ContentLayout/ContentLayout';
import { theme } from 'styles/theme';
import Typography from 'app/components/Typography';
import Tooltip from 'app/components/UI/Tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';
import { useAppDispatch } from 'store/hooks';
import { globalActions } from 'app/containers/Global/slice';
import { useParams } from 'react-router-dom';

interface IProps {}

const PeopleList: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ tenantId?: string }>();

  const routeTenantId = useMemo<number | null>(() => {
    return params?.tenantId ? Number(params.tenantId) : null;
  }, [params]);

  useEffect(() => {
    if (routeTenantId) {
      dispatch(globalActions.setActiveTenantId(routeTenantId));
      setUsersPagination(defaultUsersPaginationSettings);
    }
  }, [routeTenantId, dispatch]);

  const canCreateTenant = useHasPermission(userPermissionEnum.CREATE_TENANTS);

  const tabOptions = useMemo(() => {
    const options = [
      { label: 'Active Users', key: TenantsView.USERS },
      { label: 'Deactivated Users', key: TenantsView.DEACTIVATED_USERS },
    ];

    if (canCreateTenant) {
      options.push({ label: 'Tenants', key: TenantsView.TENANTS });
    }

    return options;
  }, [canCreateTenant]);

  const [tenantsView, setTenantsView] = useState<{
    label: string;
    key: TenantsView;
  }>(tabOptions[0]);

  const [searchPhrase, setSearchPhrase] = useState('');
  const [usersPagination, setUsersPagination] =
    useState<IPaginationRequestPart>(defaultUsersPaginationSettings);
  const [groupsPagination, setGroupsPagination] =
    useState<IPaginationRequestPart>(defaultGroupsPaginationSettings);

  const [tenantModalOpen, setTenantModalOpen] = useState<boolean | number>(
    false,
  );
  const [userModalOpen, setUserModalOpen] = useState<boolean | number>(false);

  const debouncedSearchPhrase = useDebounce(searchPhrase, 500);

  const { data, isFetching, isLoading, isError } = useData({
    pagination:
      tenantsView.key === TenantsView.TENANTS
        ? groupsPagination
        : usersPagination,
    setPagination:
      tenantsView.key === TenantsView.TENANTS
        ? setGroupsPagination
        : setUsersPagination,
    searchTerm: debouncedSearchPhrase,
    currentView: tenantsView.key,
  });

  useEffect(() => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }, [data]);

  const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> =
    event => {
      setSearchPhrase(event.target.value);
    };

  const handleUserClick = (user: IUser) => {
    if (user && user.id) {
      setUserModalOpen(user.id);
    }
  };

  const handleGroupClick = (group: ITenant) => {
    if (group && group.id) {
      setTenantModalOpen(group.id);
    }
  };

  const renderTableTabs = () => {
    return (
      <TableTabs<TenantsView>
        options={tabOptions}
        activeOption={tenantsView}
        setActiveOption={setTenantsView}
      />
    );
  };

  const renderTableActions = () => {
    return (
      <FlexBlock flexDirection="row" alignItems="center" columnGap="15px">
        <IfHasPermission require={userPermissionEnum.CREATE_TENANTS}>
          <Button
            compact
            variant="secondary-blue"
            padding="16px"
            onClick={() => setTenantModalOpen(true)}
          >
            Add Tenant
          </Button>
        </IfHasPermission>
        <Button compact padding="16px" onClick={() => setUserModalOpen(true)}>
          Add User
        </Button>
      </FlexBlock>
    );
  };

  return (
    <>
      <ContentLayout>
        <ContentHeader>
          <div
            style={{
              margin: 'auto',
              width: '95%',
              maxWidth: theme.layout.maxWidth,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography.Text $dmSans $bold $size={20} $lineHeight={24}>
              {tenantsView.key === TenantsView.TENANTS ? 'Tenants' : 'Users'}
            </Typography.Text>
            <div style={{ maxWidth: '400px' }}>
              <Input
                icon={<SearchGrayIcon />}
                width={'400px'}
                variant="textFieldWhite"
                placeholder={
                  canCreateTenant
                    ? 'Search for Users or Tenants'
                    : 'Search for Users'
                }
                onChange={handleSearchInputChange}
                showLoadingIndicator={isLoading || isFetching}
              />
            </div>
          </div>
        </ContentHeader>
        <MainGrid minContent>
          {isLoading && <PageLoader />}

          {isError ? (
            <EmptyDataNotice title={'Unexpected error happened.'} />
          ) : (
            <>
              {tenantsView.key === TenantsView.USERS && data && (
                <TenantsTable<IUser>
                  dataTypeName="active users"
                  data={data as ITableData<IUser[]>}
                  columns={usersTableColumnConfig}
                  pagination={usersPagination}
                  onPaginationChange={setUsersPagination}
                  onRowClick={handleUserClick}
                  renderContainerHeader={renderTableTabs}
                  renderAfterContainerHeader={renderTableActions}
                  showHeaderOnEmptyData
                  emptyNoticeConfig={{
                    ...noDataNoticeConfig,
                    buttonLinkTo: '',
                  }}
                />
              )}

              {tenantsView.key === TenantsView.DEACTIVATED_USERS && data && (
                <TenantsTable<IUser>
                  dataTypeName="deactivated users"
                  data={data as ITableData<IUser[]>}
                  columns={usersTableColumnConfig}
                  pagination={usersPagination}
                  onPaginationChange={setUsersPagination}
                  onRowClick={handleUserClick}
                  renderContainerHeader={renderTableTabs}
                  renderAfterContainerHeader={renderTableActions}
                  showHeaderOnEmptyData
                  emptyNoticeConfig={{
                    ...noDataNoticeConfig,
                    buttonLinkTo: '',
                  }}
                />
              )}

              {tenantsView.key === TenantsView.TENANTS && data && (
                <TenantsTable<ITenant>
                  dataTypeName="tenants"
                  data={data as ITableData<ITenant[]>}
                  columns={groupsTableColumnConfig}
                  pagination={groupsPagination}
                  onPaginationChange={setGroupsPagination}
                  onRowClick={handleGroupClick}
                  renderContainerHeader={renderTableTabs}
                  renderAfterContainerHeader={renderTableActions}
                  showHeaderOnEmptyData
                  emptyNoticeConfig={{
                    ...noDataNoticeConfig,
                    buttonLinkTo: '',
                  }}
                />
              )}
            </>
          )}
        </MainGrid>
        <Tooltip />
        {tenantModalOpen && (
          <TenantModal
            key={
              Number.isInteger(tenantModalOpen)
                ? (tenantModalOpen as number)
                : 'add-tenant'
            }
            open={tenantModalOpen}
            onClose={() => setTenantModalOpen(false)}
          />
        )}
        {userModalOpen && (
          <UserModal
            key={
              Number.isInteger(userModalOpen)
                ? (userModalOpen as number)
                : 'add-user'
            }
            open={userModalOpen}
            onClose={() => setUserModalOpen(false)}
          />
        )}
      </ContentLayout>
    </>
  );
};

export default PeopleList;
