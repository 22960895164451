import {
  useGetAuthorSearchQuery,
  useSearchCustomTargetMediaQuery,
} from 'app/api';
import { IPaginationRequestPart } from 'app/api.types';
import { useEffect, useMemo, useState } from 'react';
import { TableView } from '../parts/Tabs/JournalistTabs';
import { defaultMediaListPagination } from '../JournalistsListPage.constants';
import { useAppSelector } from 'store/hooks';
import { selectActiveTenantId } from 'app/containers/Global/slice/selectors';
import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import { defaultJournalistsFilters } from '../JournalistsListPage.constants';
import { mapFilterOptionsToPayload } from '../JournalistList.helpers';
import { IFilterOptions } from '../parts/Filters/FiltersPanel';

interface IJournalistsDataHookArgs {
  pagination: IPaginationRequestPart;
  searchByProperty: string;
  currentTab: TableView;
  debouncedSearchTerm?: string;
}

interface IMediaListDataHookArgs {
  pagination: IPaginationRequestPart;
  debouncedSearchTerm: string;
  currentTab: TableView;
}

export const useJournalistsData = ({
  searchByProperty,
  debouncedSearchTerm,
  pagination,
  currentTab,
}: IJournalistsDataHookArgs) => {
  const [filters, setFilters] = useState<IFilterOptions>({
    ...defaultJournalistsFilters,
    [searchByProperty]: debouncedSearchTerm,
    favorite: null,
  });

  const filtersPayload = useMemo(() => {
    return mapFilterOptionsToPayload(filters);
  }, [filters]);

  const skipJournalistsQuery = useMemo(() => {
    const isJournalistsTab =
      currentTab === TableView.AllJournalists ||
      currentTab === TableView.FavoriteJournalists;

    return !isJournalistsTab || !searchByProperty || !pagination;
  }, [currentTab, searchByProperty, pagination]);

  const {
    data: journalists,
    isError,
    isLoading,
    isFetching,
  } = useGetAuthorSearchQuery(
    {
      pagination,
      ...filtersPayload,
    },
    { skip: skipJournalistsQuery },
  );

  useEffect(() => {
    setFilters(currentFilters => ({
      ...currentFilters,
      email: undefined,
      name: undefined,
      publicationName: undefined,
      primaryPublicationName: undefined,
      title: undefined,
      [searchByProperty]: debouncedSearchTerm,
    }));
  }, [searchByProperty, debouncedSearchTerm]);

  useEffect(() => {
    if (currentTab === TableView.AllJournalists) {
      setFilters(currentFilters => ({
        ...currentFilters,
        ...defaultJournalistsFilters,
        favorite: null,
      }));
    }

    if (currentTab === TableView.FavoriteJournalists) {
      setFilters(currentFilters => ({
        ...currentFilters,
        ...defaultJournalistsFilters,
        favorite: true,
      }));
    }
  }, [currentTab]);

  return {
    data: journalists,
    isError,
    isLoading,
    isFetching,
    filters,
    setFilters,
  };
};

export const useMediaListData = ({
  pagination,
  debouncedSearchTerm,
  currentTab,
}: IMediaListDataHookArgs) => {
  const activeTenantId = useAppSelector(selectActiveTenantId);

  const canManageTenants = useHasPermission(userPermissionEnum.MANAGE_TENANTS);

  const mediaListPagination = useMemo(() => {
    if (!pagination) return defaultMediaListPagination;

    return {
      page: {
        page: pagination.page.page,
        size: pagination.page.size,
      },
      sort: [{ property: 'name', ascending: true }],
    };
  }, [pagination]);

  const mediaListQuery = useSearchCustomTargetMediaQuery(
    {
      name: debouncedSearchTerm,
      pagination: mediaListPagination,
      tenantId: canManageTenants && activeTenantId ? activeTenantId : undefined,
    },
    { skip: currentTab !== TableView.MediaList },
  );

  return mediaListQuery;
};
