import styled, { css } from 'styled-components';

export const GridContainer = styled.main<{
  $minContent?: boolean;
  $fullHeight?: boolean;
  gridColumnStart?: number;
  gridColumnEnd?: number;
  fullWidth?: boolean;
  noGrid?: boolean;
  $marginBottom?: number;
}>`
  ${props => !props.noGrid && 'display: grid;'}
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  width: ${({ theme }) => theme.layout.width};
  max-width: ${({ theme }) => theme.layout.maxWidth};
  background: transparent;

  grid-column-gap: 24px;
  ${({ $minContent }) => {
    if ($minContent) {
      return css`
        grid-template-rows: min-content;
      `;
    }
  }}

  ${({ $fullHeight }) => {
    if ($fullHeight) {
      return css`
        height: calc(100% - 72px);
      `;
    }
  }}

  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        width: 100%;
      `;
    }
  }}
  
  ${({ gridColumnStart, gridColumnEnd }) => {
    if (gridColumnStart || gridColumnEnd) {
      return css`
        grid-column-start: ${gridColumnStart || 0};
        grid-column-end: ${gridColumnEnd || -1};
      `;
    }
  }}

${({ $marginBottom }) => {
    if ($marginBottom) {
      return css`
        margin-bottom: ${$marginBottom}px;
      `;
    }
  }}
`;

export const MainGridSection = styled.div<{
  start: string;
  end: string;
  fullWidthOnTablet?: boolean;
  color?: string;
}>(
  ({ start, end, fullWidthOnTablet, color }) => css`
    grid-column-start: ${start};
    grid-column-end: ${end};

    ${color ? `background-color: ${color};` : ''}
    ${fullWidthOnTablet
      ? css`
          @media (max-width: ${({ theme }) =>
              theme.layout.smallDesktopMaxWidth}) {
            grid-column-start: 1;
            grid-column-end: -1;
          }
        `
      : ''};
  `,
);
