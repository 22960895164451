import { FC, useEffect, useLayoutEffect, useMemo } from 'react';
import * as S from './PdfExport.styles';
import usePortal from '../../../../../../../utils/hooks/usePortal';
import {
  IPitch,
  IPitchPrediction,
  IPressRelease,
  IReportV31,
} from '../../../../../../api.types';
import FlexBlock from '../../../../../../components/UI/FlexBlock';
import Pill from '../../../../../../components/UI/Pill';
import PdfCover from './parts/PdfCover';
import MediaReportCard from '../MediaReportCard/MediaReportCard';
import PdfPageHeader from './parts/PdfPageHeader';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../store/hooks';
import { selectPdfPrint } from '../../../../slice/selectors';
import {
  useGetPitchQuery,
  useGetPredictionQuery,
  useGetPressReleaseQuery,
} from '../../../../../../api';
import PageLoader from '../../../../../../components/UI/PageLoader';
import { usePitchSlice } from '../../../../slice';
import { showErrorToast } from '../../../../../../../utils/toast';
import { asyncDelay } from '../../../../../../../utils/helpers';

interface IProps {
  pitch: IPitch | IPressRelease;
  prediction: IPitchPrediction;
}

const PdfExport: FC<IProps> = ({ prediction: initialPrediction }) => {
  const { renderInPortal } = usePortal('print-root');

  const dispatch = useAppDispatch();
  const pitchToPrint = useAppSelector(selectPdfPrint);

  const { actions: pitchActions } = usePitchSlice();

  const {
    data: pitchData,
    isLoading: isPitchLoading,
    isError: isPitchError,
  } = useGetPitchQuery(
    {
      pitchId: pitchToPrint?.pitchId,
    },
    {
      skip:
        !Boolean(pitchToPrint?.pitchId) ||
        pitchToPrint?.contentType === 'PRESS_RELEASE',
    },
  );

  const {
    data: pressReleaseData,
    isLoading: isPressReleaseLoading,
    isError: isPressReleaseError,
  } = useGetPressReleaseQuery(
    {
      id: pitchToPrint?.pitchId || 0,
    },
    {
      skip:
        !Boolean(pitchToPrint?.pitchId) ||
        pitchToPrint?.contentType !== 'PRESS_RELEASE',
    },
  );

  const content = useMemo(() => {
    if (!pitchToPrint) return undefined;

    if (pitchToPrint.contentType === 'PITCH') {
      return {
        data: pitchData,
        isLoading: isPitchLoading,
        isError: isPitchError,
      };
    }

    if (pitchToPrint.contentType === 'PRESS_RELEASE') {
      return {
        data: pressReleaseData,
        isLoading: isPressReleaseLoading,
        isError: isPressReleaseError,
      };
    }
  }, [
    pitchToPrint,
    pitchData,
    pressReleaseData,
    isPressReleaseLoading,
    isPitchLoading,
    isPitchError,
    isPressReleaseError,
  ]);

  const maxItemsInPrediction = useMemo<number | null>(() => {
    if (
      !initialPrediction ||
      !initialPrediction.reports ||
      initialPrediction.reports.length < 1
    )
      return null;

    return Math.max(
      ...initialPrediction.reports.map(
        report => report.reportItemsPage.totalElements,
      ),
    );
  }, [initialPrediction]);

  const {
    data: predictionData,
    isLoading: isPredictionLoading,
    isError: isPredictionError,
  } = useGetPredictionQuery(
    {
      pitchId: pitchToPrint?.pitchId,
      versionId: pitchToPrint?.versionId,
      predictionType: pitchToPrint?.contentType || 'PITCH',
      pageSize: maxItemsInPrediction || 0,
    },
    {
      skip: !Boolean(pitchToPrint) || maxItemsInPrediction === null,
    },
  );

  const loading = useMemo(() => {
    return content?.isLoading || isPredictionLoading;
  }, [content?.isLoading, isPredictionLoading]);

  const error = useMemo(() => {
    return content?.isError || isPredictionError;
  }, [content?.isError, isPredictionError]);

  const {
    pitch,
    prediction,
  }: {
    pitch: IPitch | IPressRelease | null;
    prediction: IPitchPrediction | null;
  } = useMemo(() => {
    if (!loading && content && content.data && predictionData) {
      return { pitch: content.data, prediction: predictionData };
    } else {
      return { pitch: null, prediction: null };
    }
  }, [content, predictionData, loading]);

  useLayoutEffect(() => {
    if (pitchToPrint && !loading && !error) {
      (async function () {
        await asyncDelay(1750);
        await window.print();
        dispatch(pitchActions.setPdfPrint(null));
      })();
    }
  }, [pitchToPrint, loading]);

  useEffect(() => {
    if (error) {
      showErrorToast('Unknown Error');
      dispatch(pitchActions.setPdfPrint(null));
    }
  }, [error]);

  const pages = useMemo(() => {
    const paged: { index: number; report: IReportV31 }[] = [];
    if (!prediction) return paged;
    prediction.reports.forEach(report => {
      for (
        let page = 0;
        page * 10 < report.reportItemsPage.totalElements;
        page++
      ) {
        paged.push({
          index: page,
          report: report,
        });
      }
    });
    return paged;
  }, [prediction]);

  if (!pitchToPrint) return null;

  return renderInPortal(
    <>
      <S.GlobalPrintStyles />
      <S.BackDrop>
        {!loading && pitch && prediction ? (
          <S.ContentWrapper>
            <PdfCover
              pitch={pitch}
              prediction={prediction}
              contentType={pitchToPrint.contentType}
            />
            {pages.map((page, index) => (
              <S.Page key={`${page.report.id}-${page.index}`}>
                <S.PageContent>
                  <PdfPageHeader
                    pitch={pitch}
                    prediction={prediction}
                    contentType={pitchToPrint.contentType}
                  />
                  <MediaReportCard
                    pitchId={0}
                    reportId={0}
                    predictionId={0}
                    isReportExpanded={true}
                    isPredictionFetching={isPredictionLoading}
                    initialReport={page.report}
                    printMode
                    printModePage={page.index}
                    topRightText={page.index ? `Continued` : undefined}
                  />
                  <FlexBlock
                    justifyContent="center"
                    flex={1}
                    alignItems="flex-end"
                    padding="0 0 8px"
                  >
                    <Pill padding="6px 15px">{index + 2}</Pill>
                  </FlexBlock>
                </S.PageContent>
              </S.Page>
            ))}
          </S.ContentWrapper>
        ) : (
          <PageLoader />
        )}
      </S.BackDrop>
    </>,
  );
};

export default PdfExport;
export const HideOnPrint = S.HideOnPrint;
