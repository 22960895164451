import { FC } from 'react';
import PredictPanelHeader, {
  IPredictPanelHeaderProps,
} from './PredictPanelHeader';
import FlexBlock from 'app/components/UI/FlexBlock';
import Button from 'app/components/UI/Button/Button';
import PredictPanelFooter from './PredictPanelFooter';
import PressReleasePredictionSettings from './PressReleasePredictionSettings';

const PressReleasePredictPanel: FC<
  IPredictPanelHeaderProps & { onPredict: () => void }
> = props => {
  return (
    <FlexBlock
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100%"
    >
      <FlexBlock flexDirection="column" flex={1} minWidth="100%">
        {props.pressRelease && (
          <PredictPanelHeader pressRelease={props.pressRelease} />
        )}
        <PressReleasePredictionSettings />
      </FlexBlock>
      <PredictPanelFooter>
        <Button onClick={props.onPredict}>Predict Interest</Button>
      </PredictPanelFooter>
    </FlexBlock>
  );
};

export default PressReleasePredictPanel;
