import styled, { css } from 'styled-components';
import { cssMediaBreakpoints } from 'utils/responsiveQuery/breakpoints';

export const Wrapper = styled.div<{
  noBackground?: boolean;
  noWhitespace?: boolean;
  noBorderRadius?: boolean;
  itemsPerView?: string | number;
  allowHorizontalScrolling?: boolean;
  borderTopLeft?: number;
  noTopBorder?: boolean;
  borderRadius?: string;
}>(
  ({
    theme,
    noBackground,
    noTopBorder = false,
    noBorderRadius = false,
    borderRadius,
    noWhitespace,
    itemsPerView,
    allowHorizontalScrolling,
    borderTopLeft,
  }) => css`
    ${borderTopLeft !== undefined
      ? `border-top-left-radius: ${borderTopLeft} !important;`
      : ''}
    grid-column-start: 1;
    grid-column-end: -1;
    background-color: ${!noBackground ? '#fff' : 'transparent'};
    padding: ${noWhitespace ? '0' : '25px 0 32px'};
    margin: ${noWhitespace ? '0' : '0 0 40px'};
    margin-top: -2px;
    border-radius: ${!noBorderRadius
      ? '0'
      : borderRadius
      ? borderRadius
      : '12px'};
    transition: 0.15s all ease-in-out;
    border: ${noWhitespace ? 0 : 1}px solid
      ${!noBackground ? theme.colors.smoke : 'transparent'};
    ${noTopBorder &&
    css`
      border-top: unset;
      border-left: unset;
      border-right: unset;
      border-bottom: unset;
      border-top-left-radius: 0;
    `}
    flex: 1;
    position: relative;
    overflow: hidden;
    ${itemsPerView !== undefined
      ? css`
          overflow-y: scroll;
          max-height: calc(72.5px * ${itemsPerView} + 32.5px);

          ${itemsPerView === 0
            ? css`
                opacity: 0;
                max-height: 0;
              `
            : ''}
        `
      : ''}

    ${allowHorizontalScrolling
      ? css`
          overflow-x: auto;
        `
      : ''}
  `,
);

export const TableScrollWrapper = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table<{
  fixedWidth?: boolean;
  fixedFirstColumn?: boolean;
  firstHeaderCellPadding?: string;
}>(
  ({ fixedWidth, fixedFirstColumn, firstHeaderCellPadding }) => css`
    ${fixedWidth
      ? css`
          width: 100%;

          ${Td} {
            white-space: normal;
          }
        `
      : css`
          min-width: 100%;
        `}

    ${firstHeaderCellPadding
      ? css`
          ${Th} {
            &:first-child {
              padding: ${firstHeaderCellPadding};
            }
          }
        `
      : ''}


    ${fixedFirstColumn
      ? css`
          position: static;
          border-collapse: separate;
          border-spacing: 0;
          ${Td} {
            height: 80px;
          }
          ${Th}, ${Td} {
            &:first-child {
              position: sticky;
              left: 0;
              top: auto;
              z-index: 10;
              //background-color: red;
            }
            &:nth-child(2) {
              //padding-left: calc(215px + 32px);
            }
          }

          ${Th} {
            &:first-child {
              z-index: 11;
            }
          }
        `
      : ''}
  `,
);

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Th = styled.th<{
  isSorted?: boolean;
  isSortedAsc?: boolean;
  columnWidth?: string | number;
}>(
  ({ theme, isSorted, isSortedAsc, columnWidth }) => css`
    font-family: ${theme.fonts.dmSans};
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    padding: 16px 4px;
    color: ${theme.colors.onyx};
    border-bottom: 1px solid ${theme.colors.smoke};
    white-space: nowrap;
    background-color: #fff;

    ${columnWidth
      ? css`
          width: ${columnWidth};
        `
      : ''}

    svg {
      margin-left: 8px;
      transform: rotate(180deg);
      transition: 0.25s all ease-in-out;

      ${isSorted
        ? css`
            path {
              fill: ${theme.colors.orbit};
            }
          `
        : ''}

      ${isSortedAsc
        ? css`
            transform: rotate(0deg);
          `
        : ''}
    }
  `,
);

export const ThInner = styled.div`
  display: flex;
  align-items: center;
`;

export const Tr = styled.tr<{
  isEven?: boolean;
  isSelected?: boolean;
  hoverEffect?: boolean;
}>(
  ({ isEven, isSelected, theme, hoverEffect }) => css`
    transition: 0.15s all ease-in-out;
    border-bottom: 1px solid ${theme.colors.smoke};
    height: 46px;
    position: relative;
    ${isEven
      ? css`
          ${Td} {
            background-color: ${theme.colors.haze};
          } ;
        `
      : css`
          ${Td} {
            background-color: #fff;
          }
        `}

    ${isSelected
      ? css`
          ${Td} {
            background-color: ${theme.colors.lightBlue};
          } ;
        `
      : ''}

    ${hoverEffect
      ? css`
          &:hover {
            cursor: pointer;
            ${Td} {
              background-color: #d5e3f9;
            }
          }
        `
      : ''}

    .journalist-row-actions {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s all;
      column-gap: 5px;
      align-items: center;
      padding: 4px 4px 4px 80px;
      position: absolute;
      right: 0;
      background: linear-gradient(
        270deg,
        #d5e3f9 65%,
        rgba(235, 242, 251, 0) 100%
      );
      height: 100%;
    }

    &:hover .journalist-row-actions {
      opacity: 1;
      visibility: visible;
    }

    .row-hover-visible {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s opacity, 0.3s visibility;
    }

    &:hover .row-hover-visible {
      opacity: 1;
      visibility: visible;
    }
  `,
);

export const Td = styled.td<{ isEven?: boolean; noMinWidth?: boolean }>(
  ({ theme, colSpan, noMinWidth }) => css`
    font-family: ${theme.fonts.dmSans};
    font-size: 14px;
    text-align: left;
    /* padding: 12px 32px; */
    color: ${theme.colors.onyx};

    white-space: nowrap;
    text-overflow: ellipsis;

    height: 36px;

    &:first-child {
      padding: 0 15px;
    }
  `,
);

export const PaginationWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 0px;
  // CHANGED
  padding: 24px 0 0 0;
  column-gap: 8px;
`;

export const PaginationButton = styled.button<{
  active?: boolean;
  disabled?: boolean;
}>(
  ({ active, disabled, theme }) => css`
    height: 32px;
    min-width: 32px;
    border: 1px solid ${theme.colors.smoke};
    font-size: 14px;
    font-family: ${theme.fonts.dmSans};
    color: ${theme.colors.steel};
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.15s all ease-in-out;

    &:hover {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    }

    ${disabled
      ? css`
          pointer-events: none;
        `
      : ''}

    ${active
      ? css`
          background: ${theme.colors.smoke};
          &:hover {
            box-shadow: none;
          }
        `
      : ''}
  `,
);

export const ViewSwitchButton = styled.button<{ active?: boolean }>(
  ({ theme, active }) => css`
    svg {
      path {
        fill: #3f3f42;
        transition: 0.15s all ease-in-out;
      }
    }

    padding: 12px;
    background: transparent;
    border-radius: 8px;
    border: 1px solid #3f3f42;
    margin-right: 7px;
    transition: 0.15s all ease-in-out;
    cursor: pointer;

    ${active
      ? css`
          background: #3f3f42;
          svg path {
            fill: #fff;
          }
        `
      : ''}
  `,
);

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto;
  grid-column-gap: 24px;

  ${cssMediaBreakpoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${cssMediaBreakpoints.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${cssMediaBreakpoints.largeDesktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const CountPill = styled.div<{ active?: boolean }>(
  ({ active = true, theme }) => css`
    background-color: transparent;
    padding: 8px 12px;
    color: #fff;
    font-size: 16px;
    min-width: 40px;
    text-align: center;
    font-family: ${theme.fonts.dmSans};
    border-radius: 8px;
    margin: 0 12px;
    border: 1px solid ${theme.colors.nero};
    color: ${theme.colors.nero};

    ${active
      ? css`
          background-color: ${theme.colors.nero};
          color: #fff;
        `
      : ''}
  `,
);

export const TextEllipsis = styled.div`
  display: inline-block;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
  width: 100%;
  font-family: ${props => props.theme.fonts.lato};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
`;
