import { ContentHeader } from 'app/components/UI/ContentLayout/ContentLayout';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';
import SocialPostTaylorHeaderSection from './SocialPostTaylorHeaderSection';
import SocialPostsPageVersions from './SocialPostsPageVersions';
import SocialPostsPageRegenerate from './SocialPostsPageRegenerate';
import { useNavigateToContent } from 'app/containers/SocialPosts/pages/CreateOrEditSocialPosts/hooks/useNavigateToContent';
import Button from 'app/components/UI/Button/Button';
import { ISocialPost, SocialPostType } from '../../../types/SocialPost.types';
import React from 'react';

interface ISocialPostsPageHeaderProps {
  socialPost?: ISocialPost;
  onSave: () => void;
  onRegenerate: (tone: string) => void;
  onPostContentVersionLoad: (
    postContentId: number,
    postType: SocialPostType,
  ) => void;
  canSave: boolean;
  isSaving: boolean;
  isError?: boolean;
}

const SocialPostsPageHeader: FC<ISocialPostsPageHeaderProps> = ({
  socialPost,
  canSave,
  isSaving,
  isError,
  onSave,
  onRegenerate,
  onPostContentVersionLoad,
}) => {
  const { goToProjectContentPage } = useNavigateToContent();

  return (
    <ContentHeader autoHeight padding="16px 0" contentLayoutMaxWidth>
      <FlexBlock
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        minWidth="100%"
      >
        <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
          <SocialPostTaylorHeaderSection />
          {socialPost && !isError && (
            <>
              <SocialPostsPageRegenerate onRegenerate={onRegenerate} />
              <SocialPostsPageVersions
                postContentVersions={socialPost.socialPostContents}
                onVersionLoad={onPostContentVersionLoad}
              />
            </>
          )}
        </FlexBlock>

        <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
          <Button variant="transparent" onClick={goToProjectContentPage}>
            Cancel
          </Button>
          {socialPost && !isError && (
            <Button
              variant="primary-blue-v2"
              onClick={onSave}
              disabled={!canSave}
              isLoading={isSaving}
            >
              Save
            </Button>
          )}
        </FlexBlock>
      </FlexBlock>
    </ContentHeader>
  );
};

export default React.memo(SocialPostsPageHeader);
