import RegenerateIcon from 'app/assets/icons/RegenerateIcon';
import Button from 'app/components/UI/Button/Button';
import Gap from 'app/components/UI/Gap';
import { FC, useContext } from 'react';
import { PitchPageContext } from '../../../../PitchPage.context';
import { useSuggestionsData } from '../../../MultiGenerationResults/hooks/useSuggestionsData';
import { IAuthorEmailSuggestionsPayload } from 'app/api.types';

const RegenerateButton: FC = () => {
  const { payload, toggleGeneration } = useSuggestionsData();
  const {
    isRapidGenerationForAll,
    globalTone,
    globalLength,
    localTonesForRegeneration,
    localLengthsForRegeneration,
    setLocalLengthsForRegeneration,
    setLocalTonesForRegeneration,
  } = useContext(PitchPageContext);

  const handleRegenerateForIndividualItems = (
    payload: IAuthorEmailSuggestionsPayload,
  ) => {
    const requestBody = {
      ...payload,
      details: payload.details.map(item => {
        const localRegenerationTone =
          localTonesForRegeneration &&
          localTonesForRegeneration[item.reportItemId]
            ? localTonesForRegeneration[item.reportItemId].value
            : undefined;

        const localRegenerationLength =
          localLengthsForRegeneration &&
          localLengthsForRegeneration[item.reportItemId]
            ? localLengthsForRegeneration[item.reportItemId].value
            : undefined;

        return {
          ...item,
          regenerated: undefined,
          tone: localRegenerationTone,
          authorEmailLength: localRegenerationLength,
        };
      }),
      tone: globalTone.value,
      length: globalLength.value,
    };
    setLocalLengthsForRegeneration({});
    setLocalTonesForRegeneration({});
    toggleGeneration(requestBody, true);
  };

  const handleRegenerateAllItems = (
    payload: IAuthorEmailSuggestionsPayload,
  ) => {
    const requestBody = {
      ...payload,
      details: payload.details.map(item => {
        return {
          ...item,
          regenerated: undefined,
          tone: undefined,
          authorEmailLength: undefined,
        };
      }),
      tone: globalTone.value,
      length: globalLength.value,
    };
    toggleGeneration(requestBody, true);
  };

  const handleGenerationClick = () => {
    if (!payload) return toggleGeneration(undefined, true);
    if (isRapidGenerationForAll) {
      // Regenerate All - Global Selectors active
      handleRegenerateAllItems(payload);
    } else {
      // Regenerate All - Individual Selectors active
      handleRegenerateForIndividualItems(payload);
    }
  };

  return (
    <Button onClick={handleGenerationClick}>
      <RegenerateIcon />
      <Gap size={10} />
      Regenerate All
    </Button>
  );
};

export default RegenerateButton;
