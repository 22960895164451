import { SVGProps } from 'react';

const ThumbsDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#737377" transform="translate(-1868 -273)">
        <g transform="translate(1368 72)">
          <g transform="translate(0 156)">
            <g transform="translate(488 33)">
              <g transform="translate(12 12)">
                <path d="M15.587 9.158l-.822-6.528a1.643 1.643 0 00-1.631-1.43H1.496C.891 1.2.401 1.687.4 2.288v5.984c0 .6.491 1.087 1.096 1.088h2.95l2.589 5.14c.092.184.282.3.49.3a2.733 2.733 0 002.74-2.72v-1.088h3.69c.472 0 .921-.201 1.233-.552.313-.35.458-.818.4-1.282zm-11.35-.886h-2.74V2.288h2.74v5.984z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ThumbsDownIcon;
