import * as S from './BackButton.style';
import { ButtonHTMLAttributes, FC } from 'react';
import Typography from '../../Typography';
import SmallBackArrowIcon from '../../../assets/icons/SmallBackArrowIcon';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const BackButton: FC<IProps> = ({ children, ...buttonProps }) => {
  return (
    <S.Button {...buttonProps}>
      <SmallBackArrowIcon />
      <Typography.Text $dmSans $bold $colorName="onyx" $size={16}>
        {children}
      </Typography.Text>
    </S.Button>
  );
};

export default BackButton;
