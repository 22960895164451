import { ISocialPostContent, SocialPostType } from '../types/SocialPost.types';

export interface SocialPostsState {
  loading: Partial<Record<SocialPostType, boolean>>;
  socialPostContents: Partial<Record<SocialPostType, ISocialPostContent>>;
  editedContent: Partial<Record<SocialPostType, string>>;
  editedVideoDescription: Partial<Record<SocialPostType, string>>;
}

type Action =
  | { type: 'SET_LOADING'; postType: SocialPostType; loading: boolean }
  | {
      type: 'SET_MULTIPLE_LOADING';
      postTypes: SocialPostType[];
      loading: boolean;
    }
  | {
      type: 'SET_CONTENT';
      postType: SocialPostType;
      content: ISocialPostContent;
    }
  | {
      type: 'SET_EDITED_CONTENT';
      postType: SocialPostType;
      editedContent: string;
    }
  | {
      type: 'SET_EDITED_VIDEO_DESCRIPTION';
      postType: SocialPostType;
      editedVideoDescription: string;
    };

const initialState: SocialPostsState = {
  loading: {},
  socialPostContents: {},
  editedContent: {},
  editedVideoDescription: {},
};

function reducer(state: SocialPostsState, action: Action): SocialPostsState {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.postType]: action.loading,
        },
      };
    case 'SET_MULTIPLE_LOADING':
      const newLoadingState = { ...state.loading };
      action.postTypes.forEach(postType => {
        newLoadingState[postType] = action.loading;
      });
      return {
        ...state,
        loading: newLoadingState,
      };
    case 'SET_CONTENT':
      return {
        ...state,
        socialPostContents: {
          ...state.socialPostContents,
          [action.postType]: action.content,
        },
        editedContent: {
          ...state.editedContent,
          [action.postType]: action.content.post,
        },
        editedVideoDescription: {
          ...state.editedVideoDescription,
          [action.postType]: action.content.videoDescription,
        },
      };
    case 'SET_EDITED_CONTENT':
      return {
        ...state,
        editedContent: {
          ...state.editedContent,
          [action.postType]: action.editedContent,
        },
      };
    case 'SET_EDITED_VIDEO_DESCRIPTION':
      return {
        ...state,
        editedVideoDescription: {
          ...state.editedVideoDescription,
          [action.postType]: action.editedVideoDescription,
        },
      };
    default:
      return state;
  }
}

export { initialState, reducer };
