import SearchGrayIcon from 'app/assets/icons/SearchGrayIcon';
import Typography from 'app/components/Typography';
import { ContentHeader } from 'app/components/UI/ContentLayout/ContentLayout';
import PageHeader from 'app/components/UI/PageHeader';
import InputWithRadio from 'app/components/UI/InputWithRadio/Input';
import { FC } from 'react';
import { TableView } from '../Tabs/JournalistTabs';

const SEARCH_BY_KEY_OPTIONS = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Outlet', value: 'publicationName' },
  { label: 'Primary Outlet', value: 'primaryPublicationName' },
  { label: 'Journalist Title', value: 'title' },
] as const;

export type SearchJournalistsByKeys =
  typeof SEARCH_BY_KEY_OPTIONS[number]['value'];

export interface IJournalistHeaderProps {
  isLoading: boolean;
  searchTerm: string;
  searchHandler: (searchTerm: string) => void;
  searchByHandler: (searchType: SearchJournalistsByKeys) => void;
  selectedSearchType: string;
  currentTab: TableView;
}

const JournalistHeader: FC<IJournalistHeaderProps> = ({
  isLoading,
  searchTerm,
  searchHandler,
  searchByHandler,
  selectedSearchType,
  currentTab,
}) => {
  const handleSearchInputChange = (e: string) => {
    searchHandler(e);
  };

  return (
    <ContentHeader>
      <PageHeader.Section>
        <Typography.Text
          $size={20}
          $lineHeight={24}
          $dmSans
          $bold
          $colorName="onyx"
          $padding="1rem"
        >
          Journalist Database
        </Typography.Text>
      </PageHeader.Section>
      <PageHeader.Section justifyContent="flex-end" gridColumnGap="12px">
        <InputWithRadio
          icon={<SearchGrayIcon />}
          variant="default"
          placeholder="Search..."
          onChange={e => handleSearchInputChange(e.target.value)}
          searchByHandler={searchByHandler}
          value={searchTerm}
          showLoadingIndicator={isLoading}
          options={
            currentTab !== TableView.MediaList
              ? SEARCH_BY_KEY_OPTIONS
              : undefined
          }
          selectedSearchType={selectedSearchType}
        />
      </PageHeader.Section>
    </ContentHeader>
  );
};

export default JournalistHeader;
