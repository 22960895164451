import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
import { FullPageCard } from '../../../../components/UI/FullPageCard';
import * as StyledForm from '../../../../components/UI/Form';
import Avatar from '../../../../components/UI/Avatar';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/UI/Button/Button';
import { useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../slice/selectors';
import PageLoader from '../../../../components/UI/PageLoader';
import { useUpdateUserProfileMutation } from '../../../../api';
import { showErrorToast } from '../../../../../utils/toast';
import FormLabelRow from '../../../../components/UI/FormParts/FormLabelRow';
import Input from '../../../../components/UI/Input/Input';
import { IUserProfileRequest } from '../../../People/types';
import { getErrorText } from '../../../../../utils/error-messages';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ToolbarTitlePortal from '../../../../components/AuthorizedUIWrapper/parts/ToolbarTitlePortal/ToolbarTitlePortal';
import { useAvatarUploadHandler } from '../../../../../utils/hooks/useAvatarUpload';
import UserFilledIcon from '../../../../assets/icons/UserFilledIcon';
import FlexBlock from '../../../../components/UI/FlexBlock';
import WandIcon from 'app/assets/icons/WandIcon';
import { userPermissionEnum } from '../../permissions/userPermissions.enum';
import IfHasPermission from '../../permissions/IfHasPermission';
import useHasPermission from '../../permissions/useHasPermission.hook';
import TaylorAdminSettings from './parts/TaylorSettings/TaylorSettings';
import GearIcon from 'app/assets/icons/GearIcon';
import LocalSettings from './parts/LocalSettings/LocalSettings';

interface IProps {}

const UserProfilePage: FC<IProps> = () => {
  const params = useParams<{ page?: string }>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState('');
  const user = useAppSelector(selectUser);
  const [updateUser, { isLoading }] = useUpdateUserProfileMutation();
  const [activeMenu, setActiveMenu] = useState<
    'profile' | 'billing' | 'taylor' | 'localSettings'
  >('profile');

  const [userState, setUserState] = useState<IUserProfileRequest>({});

  const isSystemAdmin = useHasPermission(userPermissionEnum.SYSTEM_MANAGEMENT);

  const canEditOwnProfile = useHasPermission(userPermissionEnum.PROFILE_EDIT);

  useEffect(() => {
    if (user)
      setUserState({
        avatar: user.avatar,
        firstName: user.firstName,
        lastName: user.lastName,
        mobilePhone: user.mobilePhone,
      });
  }, [user]);

  useEffect(() => {
    if (params && params.page && params.page === 'plan') {
      setActiveMenu('billing');
    }
  }, [params]);

  const isContactDataChanged =
    userState.mobilePhone?.trim() !== user?.mobilePhone?.trim();

  const isPersonalDataChanged =
    userState.firstName?.trim() !== user?.firstName?.trim() ||
    userState.lastName?.trim() !== user?.lastName?.trim();

  const handleChange: (
    key: keyof IUserProfileRequest,
  ) => ChangeEventHandler<HTMLInputElement> = key => e => {
    setUserState(state => ({ ...state, [key]: e.target.value }));
  };

  const handleUpdateClick = (type: 'contact' | 'personal') => () => {
    if (type === 'contact') {
      saveUserProfile({ mobilePhone: userState.mobilePhone });
    }
    if (type === 'personal') {
      saveUserProfile({
        firstName: userState.firstName,
        lastName: userState.lastName,
      });
    }
  };

  const saveUserProfile: (payload: IUserProfileRequest) => void =
    async payload => {
      if (user) {
        await updateUser({
          avatar: user.avatar,
          firstName: user.firstName,
          lastName: user.lastName,
          mobilePhone: user.mobilePhone,
          ...payload,
        })
          .unwrap()
          .catch(e => {
            showErrorToast(getErrorText(e));
          });
      }
    };

  const handleFileSelect = useAvatarUploadHandler({
    onFileUploaded: url => {
      setFile(url);
      if (url) {
        saveUserProfile({ avatar: url });
      }
    },
  });

  return (
    <>
      <Helmet title="My Profile" />
      <ToolbarTitlePortal title="My Profile" />
      {user && !isLoading ? (
        <FullPageCard margin="40px" padding="0">
          <StyledForm.Menu>
            <StyledForm.MenuButton
              active={activeMenu === 'profile'}
              onClick={() => setActiveMenu('profile')}
            >
              <UserFilledIcon />
              Profile
            </StyledForm.MenuButton>
            <IfHasPermission require={userPermissionEnum.SYSTEM_MANAGEMENT}>
              <StyledForm.MenuButton
                active={activeMenu === 'taylor'}
                onClick={() => setActiveMenu('taylor')}
              >
                <WandIcon />
                Taylor
              </StyledForm.MenuButton>
            </IfHasPermission>
            <IfHasPermission require={userPermissionEnum.SYSTEM_MANAGEMENT}>
              <StyledForm.MenuButton
                active={activeMenu === 'localSettings'}
                onClick={() => setActiveMenu('localSettings')}
              >
                <GearIcon />
                Settings
              </StyledForm.MenuButton>
            </IfHasPermission>
          </StyledForm.Menu>
          {activeMenu === 'profile' && (
            <StyledForm.Wrapper>
              <StyledForm.Row padding="12px 0 0 25px">
                <StyledForm.Section>
                  <Avatar
                    size="96"
                    url={user.avatar}
                    name={`${userState.firstName} ${userState.lastName}`}
                    shadow
                  />
                </StyledForm.Section>
                <StyledForm.Section padding="0 38px 0 0">
                  <Typography.Text $dmSans $size={16} $bold>
                    Change Avatar
                  </Typography.Text>
                  <Typography.Text $colorName="steel">
                    Max 512MB.
                  </Typography.Text>
                </StyledForm.Section>
                <StyledForm.Section alignItems="flex-start">
                  <input
                    type="file"
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                  <Button
                    type="button"
                    variant="secondary"
                    compact
                    onClick={() => {
                      fileInputRef?.current?.click();
                    }}
                  >
                    Upload
                  </Button>
                </StyledForm.Section>
              </StyledForm.Row>
              <StyledForm.Divider />
              <StyledForm.Row padding="8px 25px">
                <Typography.Text $dmSans $bold $size={16}>
                  Personal Information
                </Typography.Text>
              </StyledForm.Row>
              <FormLabelRow
                label={'First Name'}
                rowProps={{ columnEnd: 5, vertical: true }}
              >
                <Input
                  value={userState.firstName}
                  onChange={handleChange('firstName')}
                  disabled={!canEditOwnProfile}
                />
              </FormLabelRow>
              <FormLabelRow
                label={'Last Name'}
                rowProps={{ columnStart: 5, columnEnd: -1, vertical: true }}
              >
                <Input
                  value={userState.lastName}
                  onChange={handleChange('lastName')}
                  disabled={!canEditOwnProfile}
                />
              </FormLabelRow>
              <StyledForm.Row padding="16px 25px 0px">
                <Button
                  type="button"
                  variant="secondary"
                  compact
                  disabled={!isPersonalDataChanged || !canEditOwnProfile}
                  onClick={handleUpdateClick('personal')}
                >
                  Update
                </Button>
              </StyledForm.Row>
              <StyledForm.Divider />
              <StyledForm.Row padding="8px 25px">
                <Typography.Text $dmSans $bold $size={16}>
                  Contact Information
                </Typography.Text>
              </StyledForm.Row>
              <FormLabelRow
                label={'Email Address'}
                rowProps={{ columnEnd: 5, vertical: true }}
              >
                <Input value={user.email} disabled readOnly />
              </FormLabelRow>
              <FormLabelRow
                label={'Phone Number'}
                rowProps={{ columnStart: 5, columnEnd: -1, vertical: true }}
              >
                <Input
                  value={userState.mobilePhone}
                  onChange={handleChange('mobilePhone')}
                  disabled={!canEditOwnProfile}
                />
              </FormLabelRow>
              <StyledForm.Row padding="16px 25px 0px">
                <Button
                  type="button"
                  variant="secondary"
                  compact
                  disabled={!isContactDataChanged || !canEditOwnProfile}
                  onClick={handleUpdateClick('contact')}
                >
                  Update
                </Button>
              </StyledForm.Row>
            </StyledForm.Wrapper>
          )}
          {activeMenu === 'taylor' && isSystemAdmin && (
            <FlexBlock
              gridColumnEnd="-1"
              gridColumnStart="3"
              overflowYAuto
              maxHeight="100%"
              flexDirection="column"
            >
              <TaylorAdminSettings />
            </FlexBlock>
          )}
          {activeMenu === 'localSettings' && isSystemAdmin && (
            <FlexBlock
              gridColumnEnd="-1"
              gridColumnStart="3"
              overflowYAuto
              maxHeight="100%"
              flexDirection="column"
            >
              <LocalSettings />
            </FlexBlock>
          )}
        </FullPageCard>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default UserProfilePage;
