import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SerializedError } from '@reduxjs/toolkit';

const STATUS_CODES: { [key: number]: string } = {
  403: 'Permission Error: Forbidden',
  500: 'Internal Server Error. Please try again later.',
};

interface IMockError {
  data: {
    status: number;
    message: string;
  };
}

export function getErrorText(
  error: FetchBaseQueryError | SerializedError | undefined | IMockError,
) {
  if (error && 'status' in error) {
    // @ts-ignore
    const text: string | undefined = STATUS_CODES[error.status];
    if (text) {
      return text;
    }
    return `Unknown Error (code: ${error.status})`;
  }

  if (error && typeof error === 'object' && 'data' in error) {
    const text: string | undefined = STATUS_CODES[error.data?.status];

    if (error.data?.message) {
      return error.data.message;
    }

    return text;
  }

  return 'Unknown Error.';
}
