import { SVGProps } from 'react';

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={12} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.43 3.894 4.997 9.96a.887.887 0 0 1-1.213 0L.571 6.927a.86.86 0 0 1-.028-1.225.885.885 0 0 1 1.24-.03l2.609 2.463 5.825-5.496a.885.885 0 0 1 1.24.03.86.86 0 0 1-.028 1.225Z"
      fill="#3DB54A"
      fillRule="evenodd"
    />
  </svg>
);

export default CheckIcon;
