import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { api, useCreateCustomTargetMediaMutation } from 'app/api';
import { ITenant } from 'app/api.types';
import CreateListIcon from 'app/assets/icons/CreateListIcon';
import AutoComplete from 'app/components/AutoComplete/AutoComplete';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import FormLabelRow from 'app/components/UI/FormParts/FormLabelRow';
import Input from 'app/components/UI/Input/Input';
import Modal from 'app/components/UI/Modal/Modal';
import RoundIconWrapper from 'app/components/UI/RoundIconWrapper';
import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import { useTenantSelector } from 'app/containers/Journalists/pages/hooks/useTenantSelector';
import { useJournalistsSlice } from 'app/containers/Journalists/slice';
import { selectCreateMediaListModalOpen } from 'app/containers/Journalists/slice/selectors';
import { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getErrorText } from 'utils/error-messages';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getJournalistsIdsFromLegacyMediaMembers } from './MediaListEntries.helpers';

const CreateMediaListModal: FC = () => {
  const dispatch = useAppDispatch();
  const { actions } = useJournalistsSlice();
  const { modalOpen, ids } = useAppSelector(selectCreateMediaListModalOpen);

  const canManageTenants = useHasPermission(userPermissionEnum.CREATE_TENANTS);

  const [mediaListName, setMediaListName] = useState('');

  const [mediaListNameError, setMediaListNameError] = useState<
    string | undefined
  >(undefined);

  const [tenantSelectorError, setTenantSelectorError] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (mediaListName && mediaListNameError) setMediaListNameError(undefined);
  }, [mediaListName]);

  const {
    selectedTenant,
    availableTenantsOptions,
    handleTenantRemove,
    handleTenantSelect,
    tenantSearchPhrase,
    setTenantSearchPhrase,
  } = useTenantSelector();

  const [createMediaList, { isLoading: isCreatingMediaList }] =
    useCreateCustomTargetMediaMutation();

  const handleModalClose = () => {
    dispatch(actions.toggleCreateMediaListModal(false));
    setMediaListNameError(undefined);
    setTenantSelectorError(undefined);
    setMediaListName('');
    handleTenantRemove();
  };

  const handleSubmit = async () => {
    if (mediaListName) {
      try {
        await createMediaList({
          tenantId:
            canManageTenants && selectedTenant ? selectedTenant.id : undefined,
          name: mediaListName,
          journalistsIds: getJournalistsIdsFromLegacyMediaMembers(ids),
        }).unwrap();

        dispatch(api.util.invalidateTags(['custom-target-medias']));
        showSuccessToast('New Media List has been created');
        handleModalClose();
      } catch (e) {
        showErrorToast(
          getErrorText(e as FetchBaseQueryError),
          'Failed to create media list',
        );
      }
    } else {
      showErrorToast('Media List Title is required');
    }
  };

  const validateSelectedTenant = () => {
    if (!canManageTenants) return;

    if (canManageTenants && !selectedTenant) {
      setTenantSelectorError('Tenant is required.');
    }

    setTenantSelectorError(undefined);
  };

  const isTenantSelected = useMemo(() => {
    if (canManageTenants) {
      return Boolean(selectedTenant);
    }

    return true;
  }, [canManageTenants, selectedTenant]);

  const ErrorsComponent: FC<{ error?: string }> = ({ error }) => (
    <div style={{ margin: '4px 18px', minHeight: '24px' }}>
      {error && (
        <Typography.Text $size={14} $colorName="redOrange">
          {error}
        </Typography.Text>
      )}
    </div>
  );

  return (
    <Modal open={modalOpen} $maxWidth="512px">
      <FlexBlock padding="24px" justifyContent="center" alignItems="center">
        <RoundIconWrapper size={88}>
          <CreateListIcon />
        </RoundIconWrapper>
      </FlexBlock>

      <FlexBlock
        padding="0 40px 8px 40px"
        rowGap="8px"
        flexDirection="column"
        alignItems="center"
        maxWidth="500px"
        margin="auto"
      >
        <Typography.Text $dmSans $bold $size={24}>
          Create New Media List
        </Typography.Text>
        <Typography.Text style={{ textAlign: 'center' }}>
          Create a Media List and add Journalists to it. After a Media List has
          been created, export it or run a pitch against it.
        </Typography.Text>
      </FlexBlock>
      {canManageTenants && (
        <FormLabelRow
          label="Tenant"
          rowProps={{ vertical: true, padding: '0 40px' }}
        >
          <AutoComplete<ITenant>
            options={availableTenantsOptions || []}
            displayAccessor="name"
            onOptionSelect={handleTenantSelect}
            searchValue={tenantSearchPhrase}
            onSearchValueChange={setTenantSearchPhrase}
            $maxWidth="512px"
            maxMenuHeight={350}
            placeholder={
              selectedTenant ? selectedTenant.name : 'Search for Tenants'
            }
            onMenuBlur={validateSelectedTenant}
            wrapperBorderColor={
              Boolean(tenantSelectorError) ? 'red' : undefined
            }
            closeMenuAfterOptionSelect
          />
          <ErrorsComponent error={tenantSelectorError} />
        </FormLabelRow>
      )}

      <FormLabelRow
        label="Media List Title"
        rowProps={{ vertical: true, padding: '0 40px' }}
      >
        <Input
          variant="textField"
          placeholder="Add a title here..."
          onChange={e => setMediaListName(e.target.value)}
          value={mediaListName}
          onFocus={() => setMediaListNameError(undefined)}
          onBlur={() => {
            if (mediaListName.length < 3)
              setMediaListNameError('Enter at least 3 characters');

            if (mediaListName.length > 50)
              setMediaListNameError('Max 50 characters allowed');
          }}
          errors={mediaListNameError ? [mediaListNameError] : []}
          customErrorComponent={<ErrorsComponent error={mediaListNameError} />}
        />
      </FormLabelRow>
      <FlexBlock
        padding="24px 40px 40px"
        justifyContent="center"
        columnGap="12px"
      >
        <Button variant="secondary" compact onClick={handleModalClose}>
          Cancel
        </Button>
        <Button
          compact
          onClick={handleSubmit}
          isLoading={isCreatingMediaList}
          disabled={
            isCreatingMediaList ||
            !Boolean(mediaListName) ||
            Boolean(mediaListNameError) ||
            Boolean(tenantSelectorError) ||
            !isTenantSelected
          }
        >
          Create
        </Button>
      </FlexBlock>
    </Modal>
  );
};

export default CreateMediaListModal;
