import { FC, useMemo, useRef } from 'react';
import { formatDate, getScreenSize } from 'utils/helpers';
import {
  EditorContainer,
  SocialPostsWrapper,
  BodyWrapper,
} from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitchPage.styles';
import { PredictedContentTab } from 'app/components/ContentGeneration/parts/header/parts/Tabs';
import SocialPostCard from 'app/components/SocialPostCard/SocialPostCard';
import {
  InputBox,
  NativeInputBox,
} from 'app/containers/Pitch/pages/CreateFirstPitch/CreateFirstPitch.styles';
import Gap from 'app/components/UI/Gap';
import { usePressReleaseContent } from '../../hooks/usePressReleaseContent.hook';
import { PressReleaseContentType } from '../../CreateOrEditPressRelease.constants';
import styled from 'styled-components';
import FlexBlock from 'app/components/UI/FlexBlock';
import { CheckboxPill } from 'app/components/UI/CheckboxPill/CheckboxPill';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import CopyIcon from 'app/assets/icons/CopyIcon';
import { useHover } from 'utils/hooks/useHover';
import { showErrorToast, showSuccessToast } from 'utils/toast';

interface IPressReleaseGeneratedContentEditorProps {
  screenEstatePercentage?: number;
  activeTab: PredictedContentTab;
  multipleContentView: boolean;
}

interface ContentWrapperProps {
  selected?: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  flex: 1;
  max-height: 100%;
  padding: 1em;
  border: ${({ selected, theme }) =>
    selected ? `1px solid ${theme.colors.orbit}` : 'none'};
  border-radius: 8px;
  position: relative;
`;

const PressReleaseGeneratedContentEditor: FC<IPressReleaseGeneratedContentEditorProps> =
  ({ activeTab, screenEstatePercentage = 0.5, multipleContentView }) => {
    const {
      content,
      handlePressReleaseInputChange,
      contentSelected,
      handleContentSelect,
      originalContentReadOnly: originalContent,
    } = usePressReleaseContent(PressReleaseContentType.GENERATED);

    const maxLines = useMemo(() => {
      const screenSize = getScreenSize();
      return Math.floor((screenSize.h * screenEstatePercentage) / 24);
    }, [screenEstatePercentage]);

    const contentWrapperRef = useRef<HTMLDivElement | null>(null);
    const hoveringOverContent = useHover(contentWrapperRef);

    const onTextCopy = async () => {
      const textToCopy = [
        originalContent?.content?.date &&
          formatDate(originalContent.content.date),
        originalContent?.content?.city,
        content?.content?.title,
        content?.content?.pressReleaseCopy,
        originalContent?.content?.boilerPlate,
      ]
        .filter(Boolean)
        .join('\n\n');
      try {
        await window.navigator.clipboard.writeText(textToCopy);
        showSuccessToast('Text copied to clipboard!');
      } catch (e) {
        showErrorToast(
          'Your browser does not support this function.',
          'Failed to copy text.',
        );
      }
    };

    if (!content) return <></>;

    return (
      <EditorContainer>
        <ContentWrapper selected={contentSelected} ref={contentWrapperRef}>
          {multipleContentView && (
            <FlexBlock padding="0 0 12px 0">
              <CheckboxPill
                checked={contentSelected}
                onChange={handleContentSelect}
                text={'Generated Content'}
                disabled={false}
              />
            </FlexBlock>
          )}

          {hoveringOverContent && (
            <div style={{ position: 'absolute', right: '1em', top: '1em' }}>
              <Button compact variant="gray" onClick={onTextCopy}>
                <CopyIcon />
                <Gap size={8} />
                Copy
              </Button>
            </div>
          )}

          {activeTab === PredictedContentTab.PRESS_RELEASE && (
            <>
              {originalContent &&
                originalContent.content &&
                originalContent.content.date && (
                  <Typography.Text $size={15} $padding="15px 0 0 15px">
                    {formatDate(originalContent.content.date)}
                  </Typography.Text>
                )}
              {originalContent &&
                originalContent.content &&
                originalContent.content.city && (
                  <Typography.Text $size={15} $padding="15px 0 15px 15px">
                    {originalContent.content.city}
                  </Typography.Text>
                )}
              <InputBox
                $noBorder
                placeholder="Headline/Title"
                value={content.content.title}
                onChange={e =>
                  handlePressReleaseInputChange('title', e.target.value)
                }
                textStyle="title"
                minRows={1}
              />
              <Gap size={40} />
              <BodyWrapper>
                <NativeInputBox
                  $noBorder
                  placeholder="Press Release Copy"
                  value={content.content.pressReleaseCopy}
                  onChange={e =>
                    handlePressReleaseInputChange(
                      'pressReleaseCopy',
                      e.target.value,
                    )
                  }
                  minRows={10}
                  maxRows={maxLines}
                />
              </BodyWrapper>
              {originalContent &&
                originalContent.content &&
                originalContent.content.boilerPlate && (
                  <Typography.Text $size={15} $padding="15px 0 15px 15px">
                    {originalContent.content.boilerPlate}
                  </Typography.Text>
                )}
            </>
          )}

          {activeTab === PredictedContentTab.SOCIALS && (
            <SocialPostsWrapper>
              <SocialPostCard
                social="twitter"
                content={content.socialPosts?.twitterPost || ''}
              />

              <SocialPostCard
                social="facebook"
                content={content.socialPosts?.facebookPost || ''}
              />

              <SocialPostCard
                social="instagram"
                content={content.socialPosts?.instagramPost || ''}
              />

              <SocialPostCard
                social="linkedin"
                content={content.socialPosts?.linkedinPost || ''}
              />
            </SocialPostsWrapper>
          )}
        </ContentWrapper>
      </EditorContainer>
    );
  };

export default PressReleaseGeneratedContentEditor;
