import { IReportItemV31 } from 'app/api.types';
import { useGetPredictionQuery } from '../../../../../../../api';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
} from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';

interface IHookProps {
  reportItems: IReportItemV31[];
  tones?: Record<number, Record<number, IToneSelectOption>>;
  lengths?: Record<number, Record<number, IContentLengthSelectOption>>;
  tone: IToneSelectOption;
  length: IContentLengthSelectOption;
}

const useEmailSuggestionsRequestBody = (props: IHookProps) => {
  const params =
    useParams<{ pitchId: string; pressReleaseId: string; versionId: string }>();

  const contentParams: {
    contentId: number;
    contentType: 'PRESS_RELEASE' | 'PITCH';
  } = useMemo(() => {
    if (params.pressReleaseId) {
      return {
        contentId: Number(params.pressReleaseId),
        contentType: 'PRESS_RELEASE',
      };
    }

    return {
      contentId: Number(params.pitchId),
      contentType: 'PITCH',
    };
  }, [params]);

  const { data: pitchPredictionData } = useGetPredictionQuery({
    pitchId: contentParams.contentId,
    versionId: Number(params.versionId),
    predictionType: contentParams.contentType,
  });

  const getAvailableContentLengthValue = (reportId: number) => {
    if (!props.lengths) return undefined;
    const found = Object.values(props.lengths).find(
      element => element[reportId],
    );

    if (found) {
      return found[reportId].value;
    } else {
      return undefined;
    }
  };

  const getAvailableToneValue = (reportId: number) => {
    if (!props.tones) return undefined;

    const found = Object.values(props.tones).find(element => element[reportId]);

    if (found) {
      return found[reportId].value;
    } else {
      return undefined;
    }
  };

  const payload = useMemo(() => {
    if (!pitchPredictionData)
      return {
        authorId: null,
        reportItemId: null,
        bodyCopy: '',
        details: [],
        title: '',
      };

    const findTargetMediaForReport = (id: number) => {
      const report = pitchPredictionData.reports.find(report => {
        if (Array.isArray(report.reportItemsPage.content)) {
          return report.reportItemsPage.content.some(
            (reportItem: IReportItemV31) => reportItem.id === id,
          );
        }
        return false;
      });
      if (report && report.mediaList && report.mediaList.type) {
        return report.mediaList.type.code;
      } else {
        return 'BRAND';
      }
    };

    const details = props.reportItems.map(reportItem => {
      //@ts-ignore
      const creator = reportItem.journalist || reportItem.podcaster;
      //@ts-ignore
      const podcast = reportItem.podcast;

      return {
        tone: getAvailableToneValue(reportItem.id),
        authorId: creator.id,
        podcastId: podcast ? podcast.id : undefined,
        reportItemId: reportItem.id,
        bodyCopy: pitchPredictionData.body,
        title: pitchPredictionData.headline,
        targetMediaType:
          podcast && podcast.id
            ? 'PODCAST'
            : findTargetMediaForReport(reportItem.id),
        authorEmailLength: getAvailableContentLengthValue(reportItem.id),
        regenerated: false,
      };
    });

    const requestBody = {
      bodyCopy: pitchPredictionData.body,
      title: pitchPredictionData.headline,
      details: details,
      tone: props.tone.value,
      authorEmailLength: props.length.value,
    };

    return requestBody;
  }, [
    props.reportItems,
    pitchPredictionData,
    props.length.value,
    props.tone.value,
  ]);

  return payload;
};

export default useEmailSuggestionsRequestBody;
