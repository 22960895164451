import { UserField, useUserValidation } from './useUserValidation';

import { useUpdateUser } from './useUpdateUser';
import { useCreateUser } from './useCreateUser';
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUser } from 'app/containers/Global/slice/selectors';
import {
  IClassifiedProperty,
  ICreateUserPayload,
  ITenantBase,
} from 'app/api.types';
import { USER_PAYLOAD } from '../UserModal/UserModal.constants';
import { UserRoleEnum } from 'app/containers/Global/slice/types';
import { useGetUserQuery } from 'app/api';
import { showErrorToast } from 'utils/toast';
import {
  getUserPayload,
  isSelectedTenantValid,
  userFieldsModified,
  userFieldsPopulated,
} from '../UserModal/UserModal.helpers';
import { useUserAvatar } from './useUserAvatar';

interface IUseUserDataProps {
  userId: boolean | number;
  onClose: () => void;
}

export const useUserData = ({ userId, onClose }: IUseUserDataProps) => {
  const memoizedUserId = useMemo(() => userId, [userId]);
  const editExistingUser = useMemo(() => Number.isInteger(userId), [userId]);

  const currentUser = useAppSelector(selectUser);

  const [user, setUser] = useState<ICreateUserPayload>(USER_PAYLOAD);
  const [userTenant, setUserTenant] = useState<ITenantBase | null>(null);
  const [userRole, setUserRole] =
    useState<IClassifiedProperty<UserRoleEnum> | null>(null);

  const { image, handleFileSelect, handleImageFileLoad } = useUserAvatar();

  const {
    data: userData,
    isError,
    isLoading: isLoadingUserData,
  } = useGetUserQuery(userId as number, {
    skip: !Number.isInteger(memoizedUserId),
  });

  const {
    fieldsValidation,
    validateUserField,
    resetFieldValidation,
    resetAllValidationFields,
  } = useUserValidation(user);

  const { updateUser, isLoading: isUpdatingUser } = useUpdateUser({
    userId,
    user,
    userData,
    currentUser,
    userRole,
    userTenant,
    avatar: image,
    onClose,
  });

  const { createUser, isLoading: isCreatingUser } = useCreateUser({
    user,
    currentUser,
    userRole,
    userTenant,
    avatar: image,
    onClose,
  });

  useEffect(() => {
    if (isError)
      showErrorToast('Failed to load user data', 'Error', {
        toastId: 'user-modal-error',
      });
  }, [isError]);

  useEffect(() => {
    if (!editExistingUser && currentUser && currentUser.tenant) {
      setUserTenant(currentUser.tenant);
    }
  }, [currentUser, editExistingUser]);

  const loadPersistedUser = useCallback(() => {
    if (userData && userData.id) {
      setUser(getUserPayload(userData));
    }

    if (userData?.tenant) {
      setUserTenant(userData.tenant);
    }

    if (userData?.role) {
      setUserRole(userData.role);
    }
  }, [userData]);

  const loadPersistedAvatar = useCallback(() => {
    handleImageFileLoad(
      userData?.avatar
        ? { type: 'URL', content: userData.avatar }
        : { type: 'URL', content: '' },
    );
  }, [userData, handleImageFileLoad]);

  const loadDefaultUser = useCallback(() => {
    setUser(USER_PAYLOAD);
    setUserRole(null);
    if (currentUser && !currentUser.tenant) {
      setUserTenant(null);
    }
  }, [currentUser]);

  const loadDefaultAvatar = useCallback(() => {
    handleImageFileLoad({ type: 'URL', content: '' });
  }, [handleImageFileLoad]);

  const onUserValueChange: (
    key: UserField,
  ) => ChangeEventHandler<HTMLInputElement> = key => ev => {
    setUser(user => ({ ...user, [key]: ev.target.value }));
  };

  const onUserRoleChange = (role: IClassifiedProperty<UserRoleEnum>) => {
    setUserRole(role);
  };

  const onTenantChange = (tenant: ITenantBase | null) => {
    setUserTenant(tenant);
  };

  const resetUser = useCallback(() => {
    if (editExistingUser) {
      loadPersistedUser();
      loadPersistedAvatar();
    }

    if (!editExistingUser) {
      loadDefaultUser();
      loadDefaultAvatar();
    }

    resetAllValidationFields();
  }, [
    editExistingUser,
    loadPersistedUser,
    loadPersistedAvatar,
    loadDefaultUser,
    loadDefaultAvatar,
    resetAllValidationFields,
  ]);

  useEffect(() => {
    if (editExistingUser) {
      loadPersistedUser();
      loadPersistedAvatar();
    }
  }, [editExistingUser, loadPersistedUser, loadPersistedAvatar]);

  const canResetFields = useMemo(() => {
    const isImageModified = () => {
      if (userData) {
        if (image?.type === 'URL') {
          if (
            Boolean(userData?.avatar) !== Boolean(image.content) &&
            userData?.avatar !== image.content
          ) {
            return true;
          }
        }
        if (image?.type === 'File') {
          return true;
        }

        return false;
      }

      return false;
    };

    const isImagePopulated = () => {
      if (image === null || image.content === '') return false;
      return true;
    };

    if (editExistingUser && user && userData) {
      return (
        userFieldsModified(user, userData) ||
        userRole?.code !== userData?.role?.code ||
        userTenant?.id !== userData?.tenant?.id ||
        isImageModified()
      );
    }

    if (!editExistingUser) {
      return (
        userFieldsPopulated(user) ||
        userRole !== null ||
        userTenant !== null ||
        isImagePopulated()
      );
    }
    return false;
  }, [userData, user, userRole, userTenant, editExistingUser, image]);

  const canSubmit = useMemo(() => {
    if (isCreatingUser || isUpdatingUser) return false;

    const allRequiredFieldsPopulated = () => {
      // Setting default values as empty strings to avoid undefined fields
      const emailField = user.email || '';
      const firstNameField = user.firstName || '';
      const lastNameField = user.lastName || '';
      const mobilePhoneField = user.mobilePhone || '';

      return Boolean(
        emailField.trim() &&
          firstNameField.trim() &&
          lastNameField.trim() &&
          mobilePhoneField.trim(),
      );
    };

    const isTenantValid = isSelectedTenantValid({
      currentUser,
      userRole,
      userTenant,
    });

    const existingFieldsModified = () => {
      if (!userData) return false;

      return (
        userFieldsModified(user, userData) ||
        userRole?.code !== userData?.role?.code ||
        userTenant?.id !== userData?.tenant?.id ||
        image?.type === 'File' ||
        (image?.type === 'URL' &&
          Boolean(userData?.avatar) !== Boolean(image.content) &&
          userData?.avatar !== image.content)
      );
    };

    if (editExistingUser) {
      if (!userData) return false;
      return Boolean(
        allRequiredFieldsPopulated() &&
          existingFieldsModified() &&
          Object.values(fieldsValidation).every(v => !v) &&
          isTenantValid &&
          userRole !== null,
      );
    }

    if (!editExistingUser) {
      return Boolean(
        allRequiredFieldsPopulated() &&
          Object.values(fieldsValidation).every(v => !v) &&
          isTenantValid &&
          userRole !== null,
      );
    }

    return true;
  }, [
    fieldsValidation,
    isCreatingUser,
    isUpdatingUser,
    user,
    currentUser,
    userRole,
    userTenant,
    editExistingUser,
    userData,
    image,
  ]);

  return {
    avatar: { image, handleFileSelect, handleImageFileLoad },
    user: {
      userFields: user,
      persistedUser: userData,
      userRole,
      userTenant,
      onUserValueChange,
      onUserRoleChange,
      onTenantChange,
      resetUser,
      createUser,
      updateUser,
      isLoading: isCreatingUser || isUpdatingUser,
      isLoadingUserData,
    },
    validation: {
      fieldsValidation,
      validateUserField,
      resetFieldValidation,
      canSubmit,
      canResetFields,
    },
  };
};
