import { Spinner } from 'app/components/UI/Spinner';
import { getFavoriteJournalistTooltipProps } from '../../JournalistList.helpers';
import { FC, useEffect, useMemo, useState } from 'react';
import { useUpdateFavoriteCreatorMutation } from 'app/api';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import { StyledIconButton } from './Favorite.styles';
import FavoriteIcon from 'app/assets/icons/tone-icons/FavoriteIcon';

interface FavoriteJournalistButtonProps {
  journalistId?: string | number;
  isFavorite?: boolean;
  noMargin?: boolean;
}

const FavoriteJournalistButton: FC<FavoriteJournalistButtonProps> = ({
  journalistId,
  isFavorite,
  noMargin,
}) => {
  const [updateFavoriteAuthor, { isLoading }] =
    useUpdateFavoriteCreatorMutation();
  const [isFavoriteChanged, setIsFavoriteChanged] = useState(false);

  const handleFavoriteJournalistClick = async (
    id: string | number,
    isFavorite: boolean,
  ) => {
    if (!isLoading) {
      await updateFavoriteAuthor({
        creatorId: Number(id),
        favorite: isFavorite,
      })
        .unwrap()
        .then(() => {
          showSuccessToast(
            `Journalist ${isFavorite ? 'added to' : 'removed from'} favorites`,
            'Success',
          );
        })
        .then(() => {
          setIsFavoriteChanged(true);
        })
        .catch(e =>
          showErrorToast(
            getErrorText(e),
            `Failed to ${isFavorite ? 'add to' : 'remove from'} favorites`,
          ),
        );
    }
  };

  useEffect(() => {
    setIsFavoriteChanged(false);
  }, [isFavorite]);

  const getFavoriteJournalistTooltipText = useMemo(() => {
    return isFavoriteChanged
      ? isFavorite
        ? 'Removed!'
        : 'Added!'
      : isFavorite
      ? 'Remove journalist from favorites'
      : 'Add journalist to favorites';
  }, [isFavorite, isFavoriteChanged]);

  if (!journalistId) return <></>;

  return (
    <StyledIconButton
      $noMargin={noMargin}
      disabled={isLoading}
      onClick={e => {
        e.stopPropagation();
        handleFavoriteJournalistClick(journalistId, !isFavorite);
      }}
      {...getFavoriteJournalistTooltipProps(
        isLoading
          ? 'Adding to Favorite Journalists'
          : getFavoriteJournalistTooltipText,
      )}
    >
      {isLoading ? (
        <Spinner $isBlue $size="20px" $margin={noMargin ? '10px' : undefined} />
      ) : (
        <FavoriteIcon
          width={20}
          height={20}
          color={
            (isFavoriteChanged ? !isFavorite : isFavorite)
              ? '#1D76DE'
              : '#1D76DE1A'
          }
        />
      )}
    </StyledIconButton>
  );
};

export default FavoriteJournalistButton;
