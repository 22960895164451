import { IOutlet } from '../../../../../../../api.types';
import { FC, useState } from 'react';
import * as S from '../DigDeeperSlideout.styles';
import Typography from '../../../../../../../components/Typography';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import Button from '../../../../../../../components/UI/Button/Button';
import useHasPermission from '../../../../../../Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from '../../../../../../Global/permissions/userPermissions.enum';
import { isNumber } from '../../../../../../../../utils/helpers';

interface IProps {
  outlets: IOutlet[];
}

const SeenInGraph: FC<IProps> = ({ outlets }) => {
  const [showMore, setShowMore] = useState(false);
  const hasMediaTrafficPermission = useHasPermission(
    userPermissionEnum.MEDIA_TRAFFIC_ACCESS,
  );

  const formatUmv = (umv: IOutlet['traffic']) => {
    if (isNumber(umv)) {
      return Number(umv).toLocaleString();
    }
    return '-';
  };

  return (
    <>
      {outlets.slice(0, showMore ? 100 : 5).map(outlet => (
        <S.SeenInBar
          percentage={outlet.authorArticlePercentage * 100}
          key={outlet.id}
        >
          <FlexBlock columnGap="8px">
            {hasMediaTrafficPermission ? (
              <Typography.Text $bold>
                UVM: {formatUmv(outlet.traffic)}
              </Typography.Text>
            ) : (
              <Typography.Text $bold>{outlet.name}</Typography.Text>
            )}
          </FlexBlock>
          <Typography.Text $colorName="steel">{outlet.url}</Typography.Text>
        </S.SeenInBar>
      ))}
      {!showMore && outlets.length > 5 && (
        <FlexBlock justifyContent="center" alignItems="center">
          <Button variant="secondary" compact onClick={() => setShowMore(true)}>
            See More
          </Button>
        </FlexBlock>
      )}
    </>
  );
};

export default SeenInGraph;
