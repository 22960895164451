import FlexBlock from 'app/components/UI/FlexBlock';
import styled, { css } from 'styled-components/macro';

export const FlexBlockWithBorder = styled(FlexBlock)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.colors.smoke};
    border-bottom: 1px solid ${theme.colors.smoke};
    margin-bottom: 24px;
    width: 100%;
  `,
);
