import { FC, useMemo } from 'react';
import MediaReportTable, {
  IMediaReportTableProps,
} from '../MediaReportTable/MediaReportTable';
import * as S from './BlurredTable.style';
import Typography from '../../../../../../components/Typography';
import Button from '../../../../../../components/UI/Button/Button';
import useOutOfLimitsMessages from '../../../CreateOrEditPitch/hooks/useOutOfLimitsMessages';

interface IProps extends IMediaReportTableProps {}

const BlurredTable: FC<IProps> = props => {
  const dataItems = useMemo(() => {
    return new Array(5).fill(0).map(() => props.data[0]);
  }, [props.data]);

  const messages = useOutOfLimitsMessages();

  return (
    <S.Wrapper>
      <MediaReportTable {...props} invisibleHeader data={dataItems} />
      <S.BlurredContainer>
        <Typography.Text $dmSans $bold $size={20}>
          PRophet Pro
        </Typography.Text>
        <Typography.Text $padding="8px 0 0 0">
          Gain access to all <strong>100 Journalist</strong> results.
        </Typography.Text>
        <Button
          variant="black"
          compact
          margin="24px 0 0 0"
          onClick={messages.goToBilling}
        >
          Upgrade to Pro
        </Button>
      </S.BlurredContainer>
    </S.Wrapper>
  );
};

export default BlurredTable;
