/**
 * Data Table.
 *
 * Table component which handles default api response IApiResponse as data. Handles pagination as well.
 *
 * @param {data}  data  table data.
 * @param {columns} columns table column configuration. array of ITableColumn type.
 * @param {pagination} pagination type of IPaginationRequestPart.
 * @param {onPaginationChange} onPaginationChange change callback.
 * @param {dataTypeName} dataTypeName how you want your data to be called in this table. Mainly is shown on empty data notice text and is used to save user preferences on LS.
 * @param {renderListItem} renderListItem grid item render function. Enables grid view option.
 * @param {onRowClick} onRowClick table item click callback
 * @param {showCount} showCount enables count in table header
 * @param {emptyNoticeConfig} emptyNoticeConfig configuration of no-data notice
 * @param {renderContainerHeader} renderContainerHeader render function for table header.
 */

import { ReactNode, useEffect, useMemo } from 'react';
import { IPaginationRequestPart, ITableData } from 'app/api.types';
import { Row, useTable } from 'react-table';
import * as S from 'app/containers/Journalists/pages/parts/Table/JournalistsTable.styles';
import { TablePagination } from 'app/components/Table/parts/Pagination';
import PageHeader from 'app/components/UI/PageHeader';
import ArrowUpIcon from 'app/assets/icons/ArrowUpIcon';
import Select from 'app/components/UI/Select/Select';
import { pageSizeOptions } from 'app/components/Table/Table.constants';
import Typography from 'app/components/Typography';
import { IOption } from 'types/commonTypes';
import PageLoader from 'app/components/UI/PageLoader';

import { scrollMainContentToTop } from 'utils/helpers';
import EmptyDataNotice, {
  IEmptyDataNoticeProps,
} from 'app/components/EmptyDataNotice/EmptyDataNotice';
import { IDataTableColumn } from 'app/components/Table/Table.types';
import FlexBlock from 'app/components/UI/FlexBlock';

interface IProps<DataItemType> {
  dataTypeName: string;
  data?: ITableData<DataItemType[]>;
  columns: any[];
  pagination: IPaginationRequestPart;
  onPaginationChange: (arg1: IPaginationRequestPart) => void;
  isDataLoading?: boolean;
  renderListItem?: (item: DataItemType) => ReactNode;
  onRowClick?: (item: DataItemType) => void;
  showCount?: boolean;
  emptyNoticeConfig?: IEmptyDataNoticeProps;
  showHeaderOnEmptyData?: boolean;
  renderContainerHeader?: () => ReactNode;
  renderAfterContainerHeader?: () => ReactNode;
}

const TenantsTable = <DataItem,>(props: IProps<DataItem>) => {
  useEffect(() => {
    handlePageNumberChange(0);
  }, [props.pagination.page.size]);

  const emptyNoticeProps: IEmptyDataNoticeProps = useMemo(() => {
    if (props.emptyNoticeConfig) return props.emptyNoticeConfig;
    return {
      title: `No ${props.dataTypeName}`,
    };
  }, [props.dataTypeName, props.emptyNoticeConfig]);

  const columns = useMemo(() => props.columns, [props.columns]);

  const tableInstance = useTable({
    columns,
    getRowId: row => {
      //@ts-ignore
      return row?.id;
    },
    // @ts-ignore
    data: props.data?.content || [],
  });

  const handlePageNumberChange = (page: number) => {
    props.onPaginationChange({
      ...props.pagination,
      page: {
        ...props.pagination.page,
        page,
      },
    });
    scrollMainContentToTop();
  };

  const handleItemsPerPageChange = (value: unknown) => {
    const option = value as IOption<number>;
    if (option.value) {
      props.onPaginationChange({
        ...props.pagination,
        page: {
          ...props.pagination.page,
          size: option.value,
        },
      });
    }
  };

  const handleSortClick = (column: IDataTableColumn<DataItem>) => () => {
    if (column.disableSort || !column?.id) return;
    const { isSortedAsc } = getTdSortProps(column.id);
    props.onPaginationChange({
      ...props.pagination,
      sort: [{ property: column.id, ascending: !isSortedAsc }],
    });
  };

  const getTdSortProps: (arg1: string) => {
    isSorted: boolean;
    isSortedAsc: boolean;
  } = (propName: string) => {
    const columnSort = props.pagination.sort.find(s => s.property === propName);
    if (columnSort) {
      return { isSorted: true, isSortedAsc: columnSort.ascending };
    }
    return { isSorted: false, isSortedAsc: false };
  };

  const activePageSizeOption = useMemo(() => {
    return (
      pageSizeOptions.find(pso => pso.value === props.pagination.page.size) ||
      null
    );
  }, [props.pagination]);

  const handleRowClick = (row: Row) => () => {
    if (props.onRowClick) {
      // @ts-ignore
      props.onRowClick(row.original);
    }
  };

  const isSortEnabled = (columnId: string) => {
    const column = props.columns.find(c => c.id === columnId);
    return !column?.disableSort;
  };

  if (
    props.data?.content.length === 0 &&
    !props.isDataLoading &&
    !props.showHeaderOnEmptyData
  ) {
    return <EmptyDataNotice {...emptyNoticeProps} />;
  }

  const commonSelectStyleProps = {
    minWidth: '178px',
    background: 'transparent',
    fontSize: 14,
    controlPadding: '10.5px 12px',
  };

  return (
    <>
      <PageHeader.Wrapper noBorder padding="0">
        <PageHeader.Section $padding="24px 0 0 0">
          {props.renderContainerHeader && props.renderContainerHeader()}
          {props.showCount && (
            <>
              <Typography.Text $dmSans $bold $size={16}>
                {props.dataTypeName}
              </Typography.Text>{' '}
              <S.CountPill>{props.data?.totalElements}</S.CountPill>
            </>
          )}
        </PageHeader.Section>
        <PageHeader.Section
          $padding={props.renderAfterContainerHeader ? '0' : '0 24px'}
          justifyContent="flex-end"
          gridColumnGap="16px"
        >
          <Typography.Text
            $colorName="steel"
            $margin="0 0 0 16px"
            style={{ wordBreak: 'keep-all' }}
          >
            View:
          </Typography.Text>
          <Select<IOption<number>>
            {...commonSelectStyleProps}
            value={activePageSizeOption || undefined}
            options={pageSizeOptions}
            onChange={handleItemsPerPageChange}
          />
          {props.renderAfterContainerHeader &&
            props.renderAfterContainerHeader()}
        </PageHeader.Section>
      </PageHeader.Wrapper>

      <S.Wrapper borderRadius="0 12px 12px" noBorderRadius>
        <S.TableScrollWrapper>
          {props.isDataLoading && <PageLoader absolute showLoaderAtTop />}

          <S.Table
            {...tableInstance.getTableProps()}
            firstHeaderCellPadding="0 0 0 11px"
          >
            <S.THead>
              <S.Tr>
                {tableInstance.headers.map(column => (
                  <S.Th
                    {...getTdSortProps(column.id)}
                    key={column.id}
                    onClick={handleSortClick(column)}
                  >
                    {column.render('Header')}
                    {isSortEnabled(column.id) && <ArrowUpIcon />}
                  </S.Th>
                ))}
              </S.Tr>
            </S.THead>
            <S.TBody {...tableInstance.getTableBodyProps()}>
              {props.data?.content.length ? (
                tableInstance.rows.map(row => {
                  tableInstance.prepareRow(row);
                  return (
                    <S.Tr
                      isEven={!Boolean(row.index % 2)}
                      hoverEffect
                      onClick={handleRowClick(row)}
                    >
                      {row.cells.map(cell => (
                        <S.Td
                          key={`${cell.column.id}_${cell.row.id}_${cell.row.index}`}
                        >
                          {cell.render('Cell')}
                        </S.Td>
                      ))}
                    </S.Tr>
                  );
                })
              ) : (
                <S.Tr>
                  <S.Td colSpan={props.columns.length}>
                    <FlexBlock justifyContent="center" minWidth="100%">
                      <Typography.Text>
                        No {props.dataTypeName || 'data'} found
                      </Typography.Text>
                    </FlexBlock>
                  </S.Td>
                </S.Tr>
              )}
            </S.TBody>
          </S.Table>
        </S.TableScrollWrapper>
        {props.data && (
          <TablePagination
            pageInfo={props.data.pageable}
            onPageNumberChange={handlePageNumberChange}
            totalElements={props.data.totalElements}
          />
        )}
      </S.Wrapper>
    </>
  );
};

export default TenantsTable;
