import { FC } from 'react';
import FlexBlock from '../../FlexBlock';
import { SkeletonDiv } from '../Skeletons.styles';
import Gap from '../../Gap';

interface IPredictionReportsSkeletonProps {}

const PredictionReportsSkeleton: FC<IPredictionReportsSkeletonProps> = () => {
  return (
    <FlexBlock
      style={{ gridColumn: '1 / -1', marginTop: '40px' }}
      minWidth="100%"
      justifyContent="center"
      alignItems="stretch"
      flexDirection="column"
    >
      <SkeletonDiv
        shimmerAnimation
        height="91px"
        width="100%"
        borderRadius="12px"
      />
      <Gap size={53} />
      <SkeletonDiv
        shimmerAnimation
        height="48px"
        width="100%"
        borderRadius="12px"
      />
      <Gap size={7} />
      <SkeletonDiv
        shimmerAnimation
        height="84px"
        width="100%"
        borderRadius="12px"
      />
      <Gap size={94} />
      <SkeletonDiv
        shimmerAnimation
        height="84px"
        width="100%"
        borderRadius="12px"
      />
      <Gap size={94} />
      <SkeletonDiv
        shimmerAnimation
        height="84px"
        width="100%"
        borderRadius="12px"
      />
    </FlexBlock>
  );
};

export default PredictionReportsSkeleton;
