import { useGetPredictionReportFiltersByIdQuery } from 'app/api';
import { PredictionReportActiveFilters } from 'app/api.types';
import { useEffect, useMemo, useState } from 'react';
import { showErrorToast } from 'utils/toast';

export const useReportFilters = ({
  pitchId,
  predictionId,
  reportId,
  shouldSkipFilters,
}: {
  pitchId: number;
  predictionId: number;
  reportId: number;
  shouldSkipFilters: boolean;
}) => {
  const [activeFilters, setActiveFilters] =
    useState<PredictionReportActiveFilters>();

  const {
    data: reportFilters,
    isError: isReportFiltersError,
    isFetching: isFetchingReportFilters,
  } = useGetPredictionReportFiltersByIdQuery(
    {
      pitchId,
      predictionId,
      reportId,
      ...activeFilters,
    },
    { skip: shouldSkipFilters, refetchOnMountOrArgChange: false },
  );

  useEffect(() => {
    if (isReportFiltersError) {
      showErrorToast('Could not load report filters. Please try again.');
    }
  }, [isReportFiltersError]);

  const filterOptions = useMemo(() => {
    if (!reportFilters) {
      return {
        outletScopes: [],
        categories: [],
      };
    }

    const isNoFiltersActive =
      !activeFilters?.outletScopes?.length &&
      !activeFilters?.categoriesIds?.length;

    const outletScopes = reportFilters.outletScopeTypes
      ? reportFilters.outletScopeTypes.map(filter =>
          mapFilterToOption(
            filter,
            isNoFiltersActive
              ? isNoFiltersActive
              : activeFilters?.outletScopes?.includes(filter.code),
          ),
        )
      : [];

    const categories = reportFilters.categories
      ? reportFilters.categories.map(filter =>
          mapFilterToOption(
            filter,
            isNoFiltersActive
              ? isNoFiltersActive
              : activeFilters?.categoriesIds?.includes(Number(filter.code)),
          ),
        )
      : [];

    return {
      outletScopes,
      categories,
    };
  }, [
    reportFilters,
    activeFilters?.categoriesIds,
    activeFilters?.outletScopes,
  ]);

  return {
    appliedFilters: activeFilters,
    filterOptions,
    setActiveFilters,
    isFetchingReportFilters,
  };
};

const mapFilterToOption = (
  filter: {
    code: string;
    displayName: string;
    availableOptions: number;
  },
  isFilterActive?: boolean,
) => {
  return {
    key: filter.code,
    displayName: filter.displayName ? filter.displayName.replace('/', '') : '',
    count: filter.availableOptions,
    checked: Boolean(isFilterActive),
  };
};
