import Typography from 'app/components/Typography';
import { ChangeEventHandler, FC } from 'react';
import { TextInput } from './UserModal.styles';
import { UserField } from '../hooks/useUserValidation';
import FlexBlock from 'app/components/UI/FlexBlock';

interface IUserFieldInputProps {
  placeholder: string;
  inputTitle: string;
  value: string;
  fieldKey: UserField;
  onValueChange: (key: UserField) => ChangeEventHandler<HTMLInputElement>;
  validationMessage: string;
  resetValidation: (key: UserField) => void;
  validateField: (key: UserField) => void;
  titlePadding?: string;
  disabled?: boolean;
}

const UserFieldInput: FC<IUserFieldInputProps> = ({
  placeholder,
  inputTitle,
  value,
  fieldKey,
  onValueChange,
  validationMessage,
  resetValidation,
  validateField,
  titlePadding,
  disabled,
}) => {
  return (
    <FlexBlock
      flexDirection="column"
      style={{ position: 'relative', paddingBottom: '28px' }}
    >
      <Typography.Text
        $colorName="nero"
        $size={14}
        $lineHeight={20}
        $padding={titlePadding || '16px 0 8px 0'}
      >
        {inputTitle}
      </Typography.Text>
      <TextInput
        placeholder={placeholder}
        onChange={onValueChange(fieldKey)}
        value={value}
        onBlur={() => validateField(fieldKey)}
        onFocus={() => resetValidation(fieldKey)}
        notValid={Boolean(validationMessage)}
        disabled={disabled}
      />
      {validationMessage && (
        <Typography.Text
          $colorName="volatileRed"
          $size={13}
          $lineHeight={16}
          $padding="6px 0 8px 0"
          style={{ position: 'absolute', bottom: 0 }}
        >
          {validationMessage}
        </Typography.Text>
      )}
    </FlexBlock>
  );
};

export default UserFieldInput;
