import { tableViewModeEnum } from 'app/components/Table/Table.helpers';
import { FC } from 'react';
import { ColumnInstance } from 'react-table';
import { Tr, Td } from 'app/components/Table/Table.styles';
import FlexBlock from '../../FlexBlock';
import { SkeletonDiv } from '../Skeletons.styles';
import ProjectCardsSkeleton from '../Projects/ProjectCardsSkeleton';

interface ITableSkeletonProps {
  columns: ColumnInstance<object>[];
  viewMode: tableViewModeEnum;
}

const TableSkeleton: FC<ITableSkeletonProps> = ({
  columns,
  viewMode = tableViewModeEnum.table,
}) => {
  const mockedRows = Array.from({ length: 10 });
  const mockedColumns = Array.from({ length: columns.length });

  return (
    <>
      {viewMode === tableViewModeEnum.table ? (
        mockedRows.map((_, rowIndex) => (
          <Tr key={rowIndex}>
            {mockedColumns.map((item, columnIndex) => {
              if (typeof item !== 'object') {
                return (
                  <Td key={columnIndex} width="25%">
                    <SkeletonDiv
                      shimmerAnimation
                      borderRadius="8px"
                      width="50%"
                    />
                  </Td>
                );
              }

              const column = item as ColumnInstance;

              if (column.id === 'selection')
                return <Td key={columnIndex} width={'1%'} />;

              return (
                <Td
                  key={columnIndex}
                  width={
                    //@ts-ignore
                    column.originalWidth ? column.originalWidth : undefined
                  }
                >
                  <SkeletonDiv
                    shimmerAnimation
                    borderRadius="8px"
                    width="50%"
                  />
                </Td>
              );
            })}
          </Tr>
        ))
      ) : (
        <ProjectCardsSkeleton />
      )}
    </>
  );
};

export default TableSkeleton;
