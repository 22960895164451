import { HeaderButtonWrapper } from 'app/components/ContentGeneration/parts/header/ContentHeader.styles';

import { theme } from 'styles/theme';
import RegenerateIcon from 'app/assets/icons/RegenerateIcon';
import TimerIcon from 'app/assets/icons/TimerIcon';
import Gap from 'app/components/UI/Gap';
import { FC, useMemo } from 'react';
import Button from 'app/components/UI/Button/Button';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import {
  PressReleaseContentType,
  PressReleaseStateActions,
} from '../CreateOrEditPressRelease.constants';
import {
  usePressRelease,
  usePressReleaseDispatch,
} from '../context/PressReleaseContext';
import { usePressReleaseContent } from '../hooks/usePressReleaseContent.hook';
import { useGetPressReleaseContentSuggestionMutation } from 'app/api';
import {
  createPermanentToast,
  showCustomToast,
  showErrorToast,
} from 'utils/toast';
import {
  IPressReleaseContent,
  IPressReleaseContentSuggestionResponse,
} from '../CreateOrEditPressRelease.types';
import {
  GENERATE_LOADING_TOOLTIP_CONTENT,
  GENERATE_SUCCESS_TOAST_CONTENT_VOILET,
} from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitch.constants';
import { getWordCount } from 'utils/helpers';
import { logger } from 'utils/apm/logger';

const getPressReleaseContentFromGenerationResult = (
  generatedContent: IPressReleaseContentSuggestionResponse,
): IPressReleaseContent => {
  const content = {
    title: generatedContent.generatedTitle,
    pressReleaseCopy: generatedContent.expandPressReleaseCopy,
  };

  const { facebookPost, instagramPost, linkedinPost, twitterPost } =
    generatedContent;

  const socialPosts = {
    twitterPost,
    facebookPost,
    instagramPost,
    linkedinPost,
  };

  return {
    content,
    socialPosts,
    contentType: PressReleaseContentType.GENERATED,
  };
};

const PressReleaseGenerationControls: FC = () => {
  const {
    originalContentReadOnly: content,
    generatedContentReadOnly: generatedContent,
  } = usePressReleaseContent();

  const { tone, contentVersions } = usePressRelease();
  const dispatch = usePressReleaseDispatch();

  const { createModal } = usePromptModalContext();

  const [generatePressReleaseContent, { isLoading: isGeneratingContent }] =
    useGetPressReleaseContentSuggestionMutation();

  const toggleVersions = () => {
    dispatch({
      type: PressReleaseStateActions.TOGGLE_VERSIONS,
    });
  };

  const handleGenerateContent = async () => {
    if (!content || !tone || !tone.value) return;
    const loader = createPermanentToast(GENERATE_LOADING_TOOLTIP_CONTENT);
    try {
      const contentSuggestionPayload = {
        title: content.content.title,
        pressReleaseCopy: content.content.pressReleaseCopy,
        quotes: content.content.quotes,
        tone: tone.value,
      };
      const generatedContent = await generatePressReleaseContent(
        contentSuggestionPayload,
      );

      if ('data' in generatedContent) {
        const content = getPressReleaseContentFromGenerationResult(
          generatedContent.data,
        );
        dispatch({
          type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT,
          payload: content,
        });

        showCustomToast(
          <GENERATE_SUCCESS_TOAST_CONTENT_VOILET />,
          'toast-violet',
        );
      } else {
        showErrorToast('Could not generate content. Please try again.');
        logger.logError(
          'Press Release - Generate Content - Missing data from the response',
        );
      }
    } catch (error) {
      showErrorToast('Could not generate content. Please try again.');
    } finally {
      loader.close();
    }
  };

  const hasGeneratedContent = useMemo(() => {
    return Boolean(generatedContent);
  }, [generatedContent]);

  const hasContentHistory = useMemo(() => {
    return contentVersions.length > 1;
  }, [contentVersions]);

  const onRegenerateClick = async () => {
    if (
      !content ||
      !content.content.pressReleaseCopy ||
      getWordCount(content.content.pressReleaseCopy) < 49
    ) {
      showErrorToast(
        'Cannot regenerate content. Press Release content too short. Please type at least 50 words',
      );
      return;
    }

    if (!content || !content.content.title) {
      showErrorToast(
        'Cannot regenerate content. Please provide a title for the Press Release.',
      );
      return;
    }

    createModal({
      title: `Regenerate All`,
      description: `This will regenerate all of your Press Releases' content, overwritting current content. You'll need to revert to a previous version to restore any overwritten content.`,
      okButtonText: 'Regenerate',
      cancelButtonText: 'Cancel',
      okButtonDanger: false,
      okButtonCallback: async () => {
        await handleGenerateContent();
      },
    });
  };

  const onGenerateClick = async () => {
    if (
      !content ||
      !content.content.pressReleaseCopy ||
      getWordCount(content.content.pressReleaseCopy) < 49
    ) {
      showErrorToast(
        'Cannot generate content. Press Release content too short. Please type at least 50 words',
      );
      return;
    }

    if (!content || !content.content.title) {
      showErrorToast(
        'Cannot generate content. Please provide a title for the Press Release.',
      );
      return;
    }

    await handleGenerateContent();
  };

  const canGenerateContent = useHasPermission(
    userPermissionEnum.GENERATE_PRESS_RELEASE_CONTENT,
  );

  return (
    <>
      {hasContentHistory && (
        <HeaderButtonWrapper>
          <Button
            variant="icon-button-border"
            onClick={toggleVersions}
            tooltipText="View previous content generations"
          >
            <TimerIcon />
          </Button>
        </HeaderButtonWrapper>
      )}
      <Gap size={8} />
      {!hasGeneratedContent ? (
        <Button
          variant="black"
          padding="15px 24px"
          onClick={onGenerateClick}
          disabled={!canGenerateContent || isGeneratingContent}
          isLoading={isGeneratingContent}
        >
          Generate
        </Button>
      ) : (
        <HeaderButtonWrapper $borderColor={theme.colors.orbit}>
          <Button
            variant="icon-button-border"
            onClick={onRegenerateClick}
            tooltipText="Regenerate content"
            disabled={!canGenerateContent || isGeneratingContent}
          >
            <RegenerateIcon fill={theme.colors.orbit} />
          </Button>
        </HeaderButtonWrapper>
      )}
    </>
  );
};

export default PressReleaseGenerationControls;
