import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import PeopleList from './pages/PeopleList/PeopleList';

interface IProps {}

// TODO Refactor container name from People to Users;
const People: FC<IProps> = () => {
  return (
    <Switch>
      <Route path={RouteConstants.people.list} component={PeopleList} />
      <Redirect
        from={RouteConstants.people.index}
        to={RouteConstants.people.makeListUrl({ view: 'users' })}
      />
    </Switch>
  );
};

export default People;
