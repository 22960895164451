import styled, { createGlobalStyle } from 'styled-components';
import coverImg from 'app/assets/images/login-cover.jpg';
import { cssMediaBreakpoints } from 'utils/responsiveQuery/breakpoints';
import BgDotImage from 'app/assets/images/bg-dot.png';

export const AuthBackgroundGlobalStyle = createGlobalStyle`
  body {
  }
`;

export const LoginFormContainer = styled.div`
  align-self: center;
  grid-column: 1 / span 4;
  ${cssMediaBreakpoints.tablet} {
    grid-column: 3 / span 8;
  }
  ${cssMediaBreakpoints.mobile} {
    grid-column: 2 / span 10;
  }
`;

export const BackgroundContainer = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background: transparent;
`;

export const RightPart = styled.div`
  //z-index: 10;
  width: 57%;
  background-color: #798fc5;
  display: flex;
  background: url(${coverImg});
  background-size: cover;
  background-position: center;
`;

export const RightPartText = styled.div`
  padding: 64px 88px;
  margin-left: auto;
  margin-top: auto;
  max-width: calc(510px + 88px * 2);
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.rift};
  font-size: 160px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 120px;
`;

export const AuthBackgroundWrapper = styled.div`
  //background-image: url('${BgDotImage}');
  background-color: #f5f5f9;
  background-size: 32px 32px;
  background-position: -50% -50%;
`;

export const LeftPart = styled(AuthBackgroundWrapper)`
  width: 44.3%;
`;
