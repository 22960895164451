import { IPitchContent, IPitchState } from './CreateOrEditPitch.types';
import FlexBlock from '../../../../components/UI/FlexBlock';
import Typography from '../../../../components/Typography';
import { FC, ReactNode } from 'react';
import WandIcon from '../../../../assets/icons/WandIcon';
import { createEMailToLink } from '../../../../../utils/helpers';
import { ToastMessageStyle } from '../../../../../utils/toast';
import useOutOfLimitsMessages from './hooks/useOutOfLimitsMessages';

export const DEFAULT_NEW_PITCH_STATE: IPitchState = {
  timingDate: null,
  countries: [{ code: 'US', displayName: 'United States' }],
  runAgainstPodcasts: false,
  states: [],
  mediaSourceFilter: { inclusions: [], exclusions: [] },
  contentTypes: [],
};

export const DEFAULT_PITCH_CONTENT_STATE: IPitchContent = {
  contentName: 'original',
  headline: '',
  body: '',
};

export const TIMING_TOOLTIP = 'Select timing date.';
export const GEOGRAPHY_TOOLTIP = 'Select countries to run your pitch against';
export const SOURCE_TOOLTIP =
  'Journalist results can include any source that is selected.';
export const CUSTOM_MEDIA_LIST_TOOLTIP =
  'Pitch a Custom Media List that is available within your Project Folder';
export const MEDIA_TYPE_TOOLTIP = 'Select and filter by media type';
export const NO_CATEGORY_MESSAGE =
  'Based on your pitch content, suggested categories couldn’t be identified. Please edit your pitch or use the search field to select a category.';
export const CATEGORY_COUNT_LIMIT = 12;
export const GENERATE_CONTENT_MIN_WORD_COUNT = 30;
export const GENERATE_CONTENT_MAX_WORD_COUNT = 1500;
export const PROPHET_ORACLE_TOOLTIP =
  'Let Taylor help you generate or regenerate your pitch copy here.';

export const PROPHET_ORACLE_TOOLTIP_PRESS_RELEASE =
  'Let Taylor help you generate or regenerate your press release copy here';

export const GENERATE_LOADING_TOOLTIP_CONTENT: ReactNode = (
  <>
    <WandIcon />
    <FlexBlock justifyContent="space-between" flex={1} alignItems="center">
      <Typography.Text $dmSans $bold $size={16} $colorName="white" flex="1">
        Generating Content
      </Typography.Text>
      <Typography.Text $colorName="white" flex="1" textAlign="right">
        Standby, it would take a human a few hours to do what we’re about to do
        in 1-2 minutes.
      </Typography.Text>
    </FlexBlock>
  </>
);

export const GENERATE_SUCCESS_TOAST_CONTENT: ReactNode = (
  <>
    <WandIcon />
    <FlexBlock justifyContent="left" flex={1} flexDirection="row">
      <Typography.Text $dmSans $bold $size={16} $colorName="white">
        Content Generated
      </Typography.Text>
      <Typography.Text $colorName="white" />
    </FlexBlock>
  </>
);

export const GENERATE_SUCCESS_TOAST_CONTENT_VOILET: FC = () => {
  return (
    <ToastMessageStyle.VioletToastWrapper>
      <WandIcon />
      <FlexBlock justifyContent="space-between" flex={1}>
        <Typography.Text $dmSans $bold $size={16} $colorName="white">
          Content Generated.
        </Typography.Text>
      </FlexBlock>
    </ToastMessageStyle.VioletToastWrapper>
  );
};

export const GenerateSuccessToastContent: FC<{ limit: number }> = ({
  limit,
}) => {
  const messages = useOutOfLimitsMessages();

  return (
    <ToastMessageStyle.VioletToastWrapper>
      <WandIcon />
      <FlexBlock justifyContent="space-between" flex={1}>
        <Typography.Text $dmSans $bold $size={16} $colorName="white">
          Content Generated.
        </Typography.Text>
        <Typography.Text $dmSans $size={16} $colorName="white">
          {limit < 4 && (
            <>
              <strong>{limit} Content Generations</strong> remaining.{' '}
            </>
          )}
          {limit === 1 && (
            <>
              <a onClick={messages.goToBilling}>
                Upgrade to our {messages.planToUpgrade}
              </a>{' '}
              for more usage
            </>
          )}
        </Typography.Text>
      </FlexBlock>
    </ToastMessageStyle.VioletToastWrapper>
  );
};

export const DEFAULT_GENERATED_CONTENT_STATE: IPitchContent = {
  headline: '',
  contentName: 'generated',
  body: '',
};

export const UPGRADE_EMAIL_HREF = createEMailToLink(
  'sales@prprophet.ai',
  'Almost out of content generations - Enterprise Upgrade Inquiry',
);

// Country codes for which we show states in Predict filters panel
export const COUNTRY_CODES_FOR_STATES = ['US', 'AU', 'CA', 'GB'];
