import {
  UserRoleEnum,
  IAgencyBase,
  ICreator,
  IUserProfileBase,
  UserStatusEnum,
} from './containers/Global/slice/types';

import {
  ContentLengthValueEnum,
  ToneValueEnum,
} from './containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';

export interface ICatFact {
  fact: string;
  length: number;
}

export interface IVerifyMfaResponse {
  accessToken: string;
}

export interface IClassifiedProperty<T = string> {
  code: T;
  displayName: string;
}

export interface IClientMin {
  id: number;
  name: string;
  logo?: any;
}

export interface IClient extends IClientMin {
  description?: any;
  url?: any;
  status: string;
  company?: string;
  agency: IAgencyBase;
  creator: ICreator;
  createdAt: string;
  updatedAt: string;
  userCount: number;
  projectCount: number;
  primaryContactId?: number | null;
}

export interface ITenantBase {
  id: number;
  name: string;
  logo?: string;
  domain?: string;
  realm?: string;
  sso?: boolean;
}

export interface ITenant extends ITenantBase {
  url?: string;
  limit: number;
  status: IClassifiedProperty<'ACTIVE' | 'SUSPENDED' | 'DISABLED'>;
  tenantType: IClassifiedProperty<'ENTERPRISE' | 'PREMIUM' | 'ENTERPRISE_SSO'>;
  projectCount: number;
  updatedAt: string;
  createdAt: string;
  description?: string;
}

export interface ICreateTenantPayload {
  name: string;
  url?: string;
  logo?: string;
  description: string;
  status: 'ACTIVE' | 'SUSPENDED' | 'DISABLED';
  tenantType: 'ENTERPRISE' | 'PREMIUM' | 'ENTERPRISE_SSO';
  domain?: string;
  realm?: string;
}

export interface IUpdateTenantPayload {
  id: number;
  name: string;
  url?: string;
  logo?: string;
  description: string;
  status: 'ACTIVE' | 'SUSPENDED' | 'DISABLED';
  tenantType: 'ENTERPRISE' | 'PREMIUM' | 'ENTERPRISE_SSO';
  domain?: string;
  realm?: string;
}

interface ISortPart {
  ascending: boolean;
  property: string;
}

export interface IPaginationRequestPart {
  page: { page: number; size: number };
  sort: ISortPart[];
}

export interface IApiResponsePageInfo {
  curPage: number;
  pageSize: number;
  total: number;
}

export interface ILegacyApiResponse<T> {
  data: T;
  error: any;
  status: number;
  pageInfo: IApiResponsePageInfo;
}

export interface IApiResponseSingle<T> {
  data: T;
}

export interface IMedia {
  id: number;
  name: string;
  type?: { code: string; displayName: string };
  totalMembers?: number;
  totalMembersByCountries?: {
    [key: string]: string | number;
  };
  enabled?: boolean | null;
}

export interface IBrandMediaMin extends IMedia {
  brand: IProjectMin;
  creator: ICreator;
  createdAt?: string;
  updatedAt?: string;
}

export interface IBrandMedia extends IBrandMediaMin {
  createdAt: string;
  updatedAt: string;
  creator: ICreator;
  members: IBrandMediaMember[];
  brands: IProjectMin[];
}

export interface IBrandMediaMember {
  author: IAuthor;
  createdAt: string;
  id: number;
  outlet: IOutlet;
}

export interface ICustomMediaMember {
  id: number;
  createdAt: string;
  journalist: IAuthor;
  outlet: IOutlet;
}

export interface IProjectMin {
  id: number;
  name: string;
  logo?: string;
}

export interface IProjectContentMetaDataItem {
  displayName: string;
  key: 'BIO' | 'BLOG' | 'PITCH' | 'BYLINE' | 'PRESS_RELEASE' | 'SOCIAL_POST';
  count: number;
  checked?: boolean;
}

export interface IProject extends IProjectMin {
  country: string;
  status: string;
  client: IClient;
  contentMeta?: IProjectContentMetaDataItem[];
  mediaList: IMedia[];
  creator: ICreator;
  createdAt: Date;
  updatedAt: Date;
  totalRuns: number;
}

export interface IPitchMin {
  headline: string;
  id: number;
  totalRuns: number;
}

export interface IWeights {
  spokesperson: number;
  editor: number;
  breaknews: number;
}

export interface IPitch extends IPitchMin {
  body: string;
  publishDate: string;
  status: 'ACTIVE' | 'ARCHIVED';
  project: IProjectMin;
  latestPredictionVersion: number;
  creator: ICreator;
  createdAt: string;
  updatedAt: string;
}

export interface IPitchPredictionBase {
  id: number;
  version: number;
  creator: ICreator;
  createdAt: string;
}

export interface IPitchPrediction extends IPitchPredictionBase {
  pitch: IPitchMin;
  headline: string;
  body: string;
  publishDate: string;
  keywords: string[];
  weights: IWeights;
  reports: IReportV31[];
  totalSamples: number;
  countries: IPitchMediaFilter[];
  states: IPitchMediaFilter[];
  mediaSourceFilter: {
    inclusions: IPitchMediaFilter[];
    exclusions: IPitchMediaFilter[];
  };
  contentTypes: IPitchMediaFilter[];
  deprecated?: boolean;
}

export interface IPressReleaseBase {
  title: string;
  subTitle?: string;
  date?: string;
  city?: string;
  pressReleaseCopy: string;
  quotes?: string[];
  boilerPlate?: string;
  tone?: string;
  twitterPost?: string;
  facebookPost?: string;
  instagramPost?: string;
  linkedinPost?: string;
}

export interface IPressReleaseContent {
  id: number;
  pressReleaseId: number;
  creator: ICreator;
  version: number;
  status: 'ACTIVE' | 'ARCHIVED';
  createdAt: string;
  updatedAt: string;
}

export interface IPersistedPressReleaseContent extends IPressReleaseBase {
  id: number;
  userId: number | string;
  pressReleaseId: number;
  createdAt: string;
  updatedAt: string;
}

export interface IPressRelease extends IPressReleaseBase {
  id: number;
  project: IProjectMin;
  creator: ICreator;
  status: 'ACTIVE' | 'ARCHIVED';
  createdAt: string;
  updatedAt: string;
  latestVersion: number;
  pressReleaseContents: IPressReleaseContent[];
}

export interface ICreatePressReleasePayload {
  projectId: number | string;
  pressReleaseContentCreateBody: IPressReleaseBase;
}

export interface IReport {
  id: number;
  targetMedia: IMedia;
  reportItems: IReportItem[];
  overall: number;
  totalSamples?: number;
}

// To Do: Upgraded Report Item types for V31

export interface IPodcast {
  id: number;
  podcaster: ICreator;
  title: string;
  description: string;
  episodeCount: number;
  rating: number;
  categories: ICategory[];
  obscured?: boolean;
  restricted?: boolean;
}
export interface IMediaV31 {
  id: number;
  name: string;
  status: IClassifiedProperty;
  type: IClassifiedProperty;
  totalMembers: number;
}

export interface IReportItemV31 {
  id: number;
  classificationCategories: IReportItemClassificationCategory[];
  creatorEmailToneStatuses?: GeneratedContentStatusByTone;
  favorite?: boolean;
  relevant?: boolean;
  certainty: number;
  explain?: IReportExplainComponent;
  sentimentType: SentimentTypeEnum;
  certaintyGrade: string;
  outlets: IOutlet[];
}

export interface IJournalistReportItemV31 extends IReportItemV31 {
  journalist: IAuthor;
}

export interface IPodcasterReportItemV31 extends IReportItemV31 {
  podcast: IPodcast;
  podcaster: IPodcastCreator;
}

export interface IReportV31 {
  creatorType: 'JOURNALIST' | 'PODCASTER';
  id: number;
  mediaList: IMediaV31;
  reportItemsPage: IApiResponse<
    IJournalistReportItemV31[] | IPodcasterReportItemV31[]
  >;
  totalSamples: number;
  overall: number;
}
export type GetPredictionReportByIdPayload = {
  pitchId: number;
  predictionId: number;
  reportId: number;
  outletScopes?: string[];
  categoriesIds?: number[];
  pagination: IPaginationRequestPart;
};

export type PredictionReportFilters = {
  outletScopeTypes?: IPitchAvailableMediaFilter[];
  categories?: IPitchAvailableMediaFilter[];
};
export type PredictionReportActiveFilters = {
  outletScopes?: string[];
  categoriesIds?: number[];
};

export enum SentimentTypeEnum {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
  MIXED = 'MIXED',
  NEUTRAL = 'NEUTRAL',
}

export enum ContentLengthParameter {
  LONG = 'LONG',
  SHORT = 'SHORT',
}

export type GeneratedContentStatus = 'PENDING' | 'PROCESSED' | 'FAILED' | null;

export type GeneratedContentStatusObject = {
  syncLogStatusType: GeneratedContentStatus;
  authorEmailLengthType: ContentLengthValueEnum;
};

export type GeneratedContentStatusByTone = {
  [key in ToneValueEnum]: GeneratedContentStatusObject;
};

export interface IReportItemClassificationCategory {
  id: number;
  path: string;
  name: string;
  level: number;
}

export interface IReportItem {
  id: number;
  author: IAuthor;
  mediaOutlet: IMediaOutlet;
  certainty: number;
  ss: number;
  rfx: number;
  certaintyGrade: string;
  podcastId?: string | null;
  preference?: boolean | null;
  explain?: IReportExplainComponent | null;
  sentimentType?: SentimentTypeEnum;
  // generatedContentStatusType: GeneratedContentStatus;
  authorEmailToneStatuses?: GeneratedContentStatusByTone | null;
  classificationCategories?: IReportItemClassificationCategory[];
}

export interface IReportExplainComponent {
  actualValue: number;
  comment: string;
  composites?: IReportExplainComponent[] | null;
  lowerBound: number;
  upperBound?: number | null;
  value: number;
}

export interface IMediaOutlet {
  id: string;
  name: string;
  logo: any;
  country: string;
  url?: string;
  articleUrl?: string;
  traffic?: number;
}

export type CreatorType = 'JOURNALIST' | 'PODCASTER';

export interface ICreateAuthorSocialPayload {
  creatorId: number;
  socialType: AuthorSocialType;
  creatorType: CreatorType;
  handle: string;
  disabled: boolean;
  hidden: boolean;
}

export interface IAuthorSocialResponse {
  id: number;
  creatorId: number;
  creatorSocialId: number;
  socialType: AuthorSocialType;
  handle: string;
  creatorEmail: string;
  createdAt: string;
  updatedAt: string;
  hidden: boolean;
  disabled: boolean;
}

export type AuthorSocialType =
  | 'FACEBOOK'
  | 'TWITTER'
  | 'INSTAGRAM'
  | 'MEDIUM'
  | 'LINKEDIN'
  | 'EMAIL'
  | 'PRIMARY_EMAIL'
  | 'SECONDARY_EMAIL'
  | 'WIKIPEDIA'
  | 'PHONE_NUMBER'
  | 'TIK_TOK'
  | 'TWITCH'
  | 'PINTEREST'
  | 'YOUTUBE'
  | 'SNAPCHAT'
  | 'GOOGLE_PLUS'
  | 'OTHER';

export interface IAuthorSocial {
  socialType: AuthorSocialType;
  url: string;
  handler?: string;
  enrichmentId?: number;
  creatorSocialId?: number;
  hidden?: boolean;
  disabled?: boolean;
}

export interface IAuthorLocation {
  country?: string;
  state?: string;
  city?: string;
}

export interface IAuthor {
  id: string;
  name: string;
  email?: string;
  twitterProfileUrl?: string;
  outlets: IOutlet[];
  socials: IAuthorSocial[];
  restricted?: boolean;
  obscured?: boolean;
  title?: string;
  bio?: string;
  avatar?: string;
  location?: IAuthorLocation;
  locations?: IAuthorLocation[];
  favorite?: boolean | null;
  contributor?: boolean;
}

export interface IPodcastAuthor {
  id?: string;
  name: string;
  email?: string;
  outlets?: IOutlet[];
  socials?: string[] | null;
}

export interface IEpisode {
  createdAt?: string | null;
  description: string;
  id?: string | null;
  lengthInSeconds: number;
  title: string;
  url: string;
}

export interface IPodcastResponse {
  categories: unknown[]; // TODO: unknown
  creator?: IPodcastCreator;
  description: string;
  episodeCount: number;
  episodes?: IApiResponse<IEpisode[]>;
  demographics?: IPodcastDemographics | null;
  rating?: number | null;
  title?: string;
  id?: string;
}

export interface IPodcastDemographics {
  medianAge: number;
  ageRanges: PodcastDemographicEntryRange[];
  medianIncome: number;
  incomeRange: PodcastDemographicEntryRange[];
  genderBias: PodcastDemographicsDataEntry;
  countries: PodcastDemographicsDataEntry[];
  cities: PodcastDemographicsDataEntry[];
  occupations: PodcastDemographicsDataEntry[];
  interests: PodcastDemographicsDataEntry[];
  brands: PodcastDemographicsDataEntry[];
  influencers: PodcastDemographicsDataEntry[];
  employers: PodcastDemographicsDataEntry[];
  ethnicities: PodcastDemographicsDataEntry[];
}

export interface PodcastDemographicEntryRange {
  to: string;
  from: string;
  count: number;
}

export interface PodcastDemographicsDataEntry {
  title: string;
  percentage: number;
}

export interface IPodcastCreator {
  bio: any;
  email: string;
  id: string;
  name: string;
  socials: IAuthorSocial[];
  obscured: boolean;
  restricted: boolean;
}

export interface IAuthorSearchPayload {
  pagination: IPaginationRequestPart;
  name?: string;
  email?: string;
  publication?: string;
  countries?: string[];
  states?: string[];
  outletScopeFilter?: {
    inclusions: string[];
    exclusions: string[];
  };
  contentTypes?: string[];
  favorite?: boolean | null;
  relevant?: boolean | null;
}

export interface IAuthorSearchResponse {
  isLast: boolean;
  id: number | string;
  name: string;
  socials: IAuthorSocial[];
  restricted: boolean;
  obscured: boolean;
  favorite?: boolean;
  primaryOutlet?: IMediaOutlet;
  contributor?: boolean;
}

export interface IAuthorResponse {
  author: IAuthor;
  latestArticles: ILatestArticle[];
  relevantArticles: ILatestArticle[];
  podcast?: IPodcastResponse | null;
  note: any;
  sentimentStatistics?: ICategorySentimentStatistics;
}

export interface IOutlet {
  id: string;
  name: string;
  logo: any;
  country: string;
  authorArticlePercentage: number;
  url: string;
  traffic?: number | null;
}

export interface ILatestArticle {
  id: number;
  title: string;
  description: string;
  articleUrl?: string;
  url: string;
  publishedAt: string;
  mediaOutlet: IMediaOutlet;
  outlets: IMediaOutlet[];
  restricted?: boolean;
  obscured?: boolean;
  similarityScore?: number;
}

export interface IAuthorSuggestEditPayload {
  comments: string;
  email: string;
  name: string;
  notes: string;
  // twitterHandle: string;
  socials: IAuthorSocial[];
}

export interface IApiResponseSortPart {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface IApiResponsePageablePart {
  sort: IApiResponseSortPart;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface IApiResponse<T> {
  content: T;
  pageable: IApiResponsePageablePart;
  last: boolean;
  totalElements: number;
  totalPages: number;
  sort: IApiResponseSortPart;
  number: number;
  first: boolean;
  numberOfElements: number;
  size: number;
  empty: boolean;
}

export interface ITableData<T> extends IApiResponse<T> {}

export interface INewPitchPayload {
  body: string;
  headline: string;
  publishDate: string;
  status: 'ACTIVE' | 'DRAFT';
  projectId: number;
}

export interface IPredictionPayload {
  brandTargetMediaIds: number[];
  categoriesIds: number[];
  countries: string[];
  states: string[];
  mediaSourceFilter: IPitchMediaFilterSources | undefined;
  contentTypes: string[] | undefined;
  runAgainstPodcastSelected: boolean;
  sentiment: string;
}

export interface IPredictionPayloadNoEvalPayload {
  countries: string[];
  pitchType?: 'PITCH' | 'PRESS_RELEASE';
  pitchVersion?: number;
  states: string[];
  mediaSourceFilter: IPitchMediaFilterSources | undefined;
  contentTypes: string[] | undefined;
  brandTargetMediaIds: number[];
  runAgainstPodcastSelected: boolean;
}

export interface IPredictBody {
  countries: string[];
  pitchType?: 'PITCH' | 'PRESS_RELEASE';
  pitchVersion?: number;
  states: string[];
  contentTypes: string[] | undefined;
  customMediaListIds: number[];
  runAgainstPodcastSelected: boolean;
  // outletScopeFilter: IPitchMediaFilterSources | undefined;
}

export interface ICategory {
  id: number;
  level: number;
  name: string;
  path: string;
}

export interface IPitchEvaluation {
  categories: ICategory[];
  pitchId: number;
  pitchPredictionId: number;
  sentiment: string;
}

export type IPitchMediaFiltersBody = {
  contentTypes?: IPitchMediaFilter[];
  sourceTypes?: IPitchMediaFilter[];
  stateTypes?: IPitchMediaFilter[];
  countryTypes?: IPitchMediaFilter[];
};

export type IPitchMediaFilter = {
  code: string;
  displayName: string;
};

export type IPitchAvailableMediaFilter = {
  code: string;
  displayName: string;
  availableOptions: number;
};

export type IPitchMediaFilterSources = {
  inclusions: string[];
  exclusions: string[];
};

export type IPitchMediaFiltersResponse = IPitchMediaFiltersBody;

export type IPitchMediaFilterResponse = IPitchMediaFilter[];

export enum PitchMediaAvailableFiltersEnum {
  STATES = 'media-states',
  COUNTRIES = 'media-countries',
  SOURCES = 'media-sources',
  CONTENT_TYPES = 'content-types',
}

export type IPitchMediaFilterPayload = {
  filter: PitchMediaAvailableFiltersEnum;
};

export type IPitchAvailableMediaFiltersPayload = {
  countries: string[];
  states: string[];
  mediaSourceFilter?: IPitchMediaFilterSources;
  contentTypes?: string[];
  categoriesIds?: number[];
  favorite?: boolean | null;
};

export type IJournalistAvailableMediaFiltersPayload = {
  countries?: string[];
  states?: string[];
  mediaSourceFilter?: IPitchMediaFilterSources;
  contentTypes?: string[];
  favorite?: boolean | null;
  relevant?: boolean | null;
  name?: string;
  email?: string;
  publicationName?: string;
  outletScopeFilter?: {
    inclusions: string[];
    exclusions: string[];
  };
};

export type IPitchAvailableMediaFiltersResponse = {
  contentTypes?: IPitchAvailableMediaFilter[];
  sourceTypes?: IPitchAvailableMediaFilter[];
  stateTypes?: IPitchAvailableMediaFilter[];
  countryTypes?: IPitchAvailableMediaFilter[];
  podcastsCountryTypes?: IPitchAvailableMediaFilter[];
};

export interface IAgency extends IAgencyBase {
  createdAt: string;
  description?: string | null;
  status: string;
  updatedAt: string;
  url?: string | null;
  limit: number;
}

export interface IUserRole {
  code: UserRoleEnum;
  displayName: string;
}

export interface IUserBase {
  avatar?: string;
  email: string;
  mobilePhone: string;
  firstName: string;
  lastName: string;
}

export interface ICreateUserPayload extends IUserBase {
  tenantId?: number | string;
  status: string;
  role: UserRoleEnum;
}

export interface IUser extends IUserBase {
  id: number;
  tenant: ITenant;
  statusType: IClassifiedProperty<UserStatusEnum>;
  role: IClassifiedProperty<UserRoleEnum>;
  createdBy: ICreator;
  createdAt: string;
  updatedAt: string;
  firstTimeLogin: boolean;
  lastLoginTime: string;
  monitorHash: string;
}

export interface IUpdateUserPayload extends ICreateUserPayload {
  id: number | string;
}

export interface IClientUser extends IUserProfileBase {
  // roles: IRoleObject[];
  role: UserRoleEnum;
}

export interface IUpdateAuthorStatusPayload {
  id: number | string;
  isActive: boolean;
}

export interface IUpdateAuthorFavoritePayload {
  id: number | string;
  isFavorite: boolean;
}

export interface IUpdateFavoriteCreatorPayload {
  creatorType?: 'JOURNALIST' | 'PODCASTER';
  scope?: 'OUTLET' | 'CREATOR' | 'PITCH';
  pitchType?: 'PITCH' | 'PRESS_RELEASE';
  outletId?: number;
  creatorId: number;
  pitchId?: number;
  favorite: boolean;
}

export interface IGetGeneratedContentPayload {
  bodyCopy: string;
  title: string;
  tone: string;
}

export interface IGetGeneratedContentByPitchIDPayload {
  body: IGetGeneratedContentPayload;
  pitchId: number;
}

export interface ISocialPostsContent {
  twitterPost: string;
  facebookPost: string;
  instagramPost: string;
  linkedinPost: string;
}

export interface IGeneratedContentResponse extends ISocialPostsContent {
  articleByline: string;
  generatedSubTitle: string;
  generatedTitle: string;
}

export interface IGeneratedContentHistoryResponse {
  createdAt: string;
  id: number;
  tone: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    avatar?: string;
  };
}

export interface IAuthorEmailSuggestionResponse {
  pitch: string[];
  reasons: string[];
  subject: string[];
  authorEmailLength: string;
}

export interface IAuthorEmailSuggestionPayload {
  bodyCopy: string;
  title: string;
  tone: string;
  authorEmailLength: string;
  authorId: string;
  targetMediaType: string;
  podcastId?: string | number;
  reportItemId: number;
  regenerate?: boolean;
}

export interface IAuthorEmailSuggestionsResponse {
  reportItemId: number;
  pitch?: string[];
  reasons?: string[];
  subject?: string[];
  authorEmailLength: string;
  tone: string;
}

export interface IAuthorEmailSuggestionsPayload {
  bodyCopy: string;
  title: string;
  tone?: string;
  details: EmailSuggestionItem[];
  authorEmailLength?: string;
}

export interface EmailSuggestionItem {
  authorId: string;
  targetMediaType: string;
  podcastId?: string | number | undefined;
  reportItemId: number;
  regenerate?: boolean;
}

export interface ICategorySentimetnStatisticsItem {
  total: number;
  totalPercentage: number;
}

export interface ICategorySentimentStatistics {
  negative?: ICategorySentimetnStatisticsItem | null;
  positive?: ICategorySentimetnStatisticsItem | null;
  mixed?: ICategorySentimetnStatisticsItem | null;
  neutral?: ICategorySentimetnStatisticsItem | null;
  total: number;
}

export interface IAuthorSummaryResponse {
  categorySentimentStatistic?: ICategorySentimentStatistics | null;
  latestArticles: IApiResponse<ILatestArticle[]>;
  havingSimilarityArticles?: boolean;
  author: IAuthor;
  note?: { value: string } | null;
}

export interface IContentGenerationLimits {
  authorEmailSuggestionsLeft: number;
  contentSuggestionsLeft: number;
  pitchPredictionLeft: number;
  resetTimestamp: string;
}

export interface ICreateFreeUserPayload {
  description: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  firstName: string;
  lastName: string;
}

export interface IAddress {
  city: string;
  country: string;
  line1: string;
  postalCode: string;
  state: string;
}

export enum ProSubscriptionPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface IProSubscriptionUpdatePayload {
  subscriptionId: string;
  defaultPaymentMethod: string;
}

export interface IProSubscriptionPayload {
  priceId: string;
  email: string;
  name: string;
  shipping: IAddress;
  address: IAddress;
}

export interface IProSubscriptionResponse {
  clientSecret: string;
  subscriptionId: string;
}

export interface ISubscriptionProduct {
  name: string;
}

export interface ICardDetails {
  lastDigits: string;
  expYear: number;
  expMonth: number;
  brand: string;
}

export interface ISubscriptionDetails {
  subscriptionId: string;
  status: string;
  products: ISubscriptionProduct[];
  card: ICardDetails;
  cancelAtPeriodEnd: boolean;
  currentPeriodEnd: string;
}

export enum LimitTypesEnum {
  PITCH_GENERATIONS = 'PITCH_GENERATIONS',
  BIO_GENERATIONS = 'BIO_GENERATIONS',
  BLOG_GENERATIONS = 'BLOG_GENERATIONS',
  PITCH_PREDICTIONS = 'PITCH_PREDICTIONS',
  AUTHOR_EMAIL = 'AUTHOR_EMAIL',
}

export interface IClientLimitsByIdPayload {
  userId?: string | number;
  limitTypes: LimitTypesEnum[];
}

export interface IClientLimits {
  resetTimestamp: string;
  userLimitDetails?: {
    [K in LimitTypesEnum]?: {
      detailType: string;
      total: number;
      left: number;
      used: number;
    };
  };
}

interface ITaylorGeneratorOption {
  displayName: string;
  code: string;
}

export interface ITaylorGeneratorSettingsResponse {
  pitchContent: string;
  authorEmail: string;
  pitchContentOptions: ITaylorGeneratorOption[];
  authorEmailOptions: ITaylorGeneratorOption[];
}

export type ITaylorGeneratorSettingsEndpoint = 'author-email' | 'pitch-content';

export interface ITaylorGeneratorSettingsPayload {
  endpoint: ITaylorGeneratorSettingsEndpoint;
  generatorType: string;
}

// Custom Target Media Types

export enum ITargetMediaTypes {
  CUSTOM = 'CUSTOM',
  TARGET = 'TARGET',
  PODCAST = 'PODCAST',
  SUGGESTION = 'SUGGESTION',
  INFLUENCER = 'INFLUENCER',
  JOURNALIST = 'JOURNALIST',
}

export enum ITargetMediaStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export interface ICustomTargetMediaBase {
  id: number;
  name: string;
  type: IClassifiedProperty<ITargetMediaTypes>;
  status: IClassifiedProperty<ITargetMediaStatus>;
  totalMembers: number;
}

export interface ICustomTargetMediaDetail extends ICustomTargetMediaBase {
  createdAt: string;
  updatedAt: string;
  createdBy: ICreator;
  members: ICustomMediaMember[];
}

// Target Media Types

export interface ITargetMediaResponse {
  id: number;
  name: string;
  type: string;
  totalMembers: number;
  totalMembersByCountries: {
    USA: number;
  };
  enabled: any;
  brand: IProjectMin;
  members: IBrandMediaMember;
  creator: ICreator;
  createdAt: string;
  updatedAt: string;
}

export interface ICreateCustomTargetMediaPayload {
  name: string;
  members?: { journalistId: number; outletId: number }[];
  journalistsIds?: number[];
}

export interface ITargetMediaPayload {
  name: string;
  members: ITargetMediaMember[];
}

export interface ITargetMediaMember {
  mediaOutletId: number;
  authorId: string | number;
}

// Content Types

export interface IContentItem {
  contentId: number;
  contentType:
    | 'BIO'
    | 'BLOG'
    | 'PITCH'
    | 'PRESS_RELEASE'
    | 'BYLINE'
    | 'SOCIAL_POST';
  createdAt: string;
  updatedAt: string;
  creator: ICreator;
  predicted: boolean;
  title: string;
  latestVersion: number;
}

// Bio Types

export interface IBioSuggestionContentResponse {
  id: number;
  shortBio: string;
  longBio: string;
  userId: number;
  bioId: number;
  workflowId: string;
  createdAt: string;
  updatedAt: string;
  title?: string;
  eventBrief?: string;
  emphasizeEducation?: boolean;
  emphasizeInterests?: boolean;
  emphasizeRole?: boolean;
}

export interface IBioSuggestionContentPayload {
  eventBrief?: string;
  linkedInURL: string;
  emphasizeEducation?: boolean;
  emphasizeInterests?: boolean;
  emphasizeRole?: boolean;
}

export interface IBioRegenerateSuggestionPayload {
  eventBrief?: string;
  shortBio?: string;
  longBio?: string;
  emphasizeEducation?: boolean;
  emphasizeInterests?: boolean;
  emphasizeRole?: boolean;
}

export interface IBioRegeneratedSuggestion {
  id: number;
  shortBio: string;
  longBio: string;
  userId: number;
  bioId: number;
  createdAt: string;
  updatedAt: string;
}

export interface IBio {
  id: number;
  project: IProjectMin;
  creator: ICreator;
  title: string;
  linkedInUrl: string;
  status: 'ACTIVE' | 'DRAFT' | 'ARCHIVED';
  createdAt: string;
  updatedAt: string;
  shortBio: string;
  longBio: string;
  latestVersion: number;
  bioContents: [
    {
      id: number;
      bioId: number;
      creator: ICreator;
      status: 'ACTIVE' | 'ARCHIVED';
      createdAt: string;
      updatedAt: string;
      version: number;
    },
  ];
}

export interface IBioContentMin {
  shortBio: string;
  longBio: string;
}

export interface IBioContent {
  id: number;
  shortBio: string;
  longBio: string;
  eventBrief?: string;
  emphasizeEducation?: boolean;
  emphasizeInterests?: boolean;
  emphasizeRole?: boolean;
  userId: number;
  bioId: number;
  createdAt: string;
  updatedAt: string;
}

export interface ICreateBio {
  projectId: number;
  title: string;
  linkedInURL: string;
  bioContentCreateBody: {
    shortBio: string;
    longBio: string;
    eventBrief?: string;
    emphasizeEducation?: boolean;
    emphasizeInterests?: boolean;
    emphasizeRole?: boolean;
  };
}

export interface IContentResponse {
  contents: IContentItem[];
}

export interface IBlogContentPayload {
  title: string;
  body: string;
  blogTopic: string;
  productKeywords?: string;
  tone: string;
}

export interface IBlogContentResponse {
  id: number;
  title: string;
  body: string;
  blogTopic: string;
  productKeywords?: string;
  tone: string;
  userId: number;
  blogId: number;
  createdAt: string;
  updatedAt: string;
}

export interface IBlogGenerationPayload {
  blogTopic: string;
  productKeywords?: string;
  tone: string;
}

export interface IBlogGenerationResponse {
  id: number;
  generatedTitle: string;
  generatedBody: string;
  workflowId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IBlogContentVersions {
  id: number;
  blogId: number;
  creator: ICreator;
  version: number;
  status: 'ACTIVE' | 'ARCHIVED';
  createdAt: string;
  updatedAt: string;
}

export interface IBlog {
  id: number;
  project: IProjectMin;
  creator: ICreator;
  status: 'ACTIVE' | 'ARCHIVED';
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
  latestVersion: number;
  blogContents?: IBlogContentVersions[];
}

export interface ICreateBlogPayload {
  projectId: number;
  blogContentCreateBody: {
    title: string;
    body: string;
    blogTopic: string;
    productKeywords?: string;
    tone: string;
  };
}

// Byline Types

export interface IBylineContentPayload {
  title: string;
  body: string;
  bylineTopic: string;
  productKeywords?: string;
  tone: string;
}

export interface IBylineContentResponse {
  id: number;
  title: string;
  body: string;
  bylineTopic: string;
  productKeywords?: string;
  tone: string;
  userId: number;
  bylineId: number;
  createdAt: string;
  updatedAt: string;
}

export interface IBylineGenerationPayload {
  bylineTopic: string;
  productKeywords?: string;
  tone: string;
}

export interface IBylineGenerationResponse {
  id: number;
  generatedTitle: string;
  generatedBody: string;
  workflowId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IBylineContentVersions {
  id: number;
  bylineId: number;
  creator: ICreator;
  version: number;
  status: 'ACTIVE' | 'ARCHIVED';
  createdAt: string;
  updatedAt: string;
}

export interface IByline {
  id: number;
  project: IProjectMin;
  creator: ICreator;
  status: 'ACTIVE' | 'ARCHIVED';
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
  latestVersion: number;
  bylineContents?: IBylineContentVersions[];
}

export interface ICreateBylinePayload {
  projectId: number;
  bylineContentCreateBody: {
    title: string;
    body: string;
    bylineTopic: string;
    productKeywords?: string;
    tone: string;
  };
}
