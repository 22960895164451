import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

// Create Social Posts Page Components
interface SocialPostsFormWrapperProps {
  padding?: string | number | undefined | null | false;
}

export const SocialPostsContainer = styled.div<SocialPostsFormWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding || '24px'};
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e4e4e9;
  grid-column-start: 1;
  grid-column-end: -1;
`;

export const SocialPostsWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.haze};
    height: 100%;
    overflow-y: auto;
  `,
);

export const DoublePillButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const Pill = styled.button<{
  $selected: boolean;
  $width?: string;
  $height?: string;
  variant: 'left' | 'right';
}>(
  ({ theme, $selected, $width = '115px', $height = '56px', variant }) => css`
    box-sizing: border-box;
    height: ${$height};
    width: ${$width};
    border: 1px solid ${theme.colors.steel};
    border-radius: ${variant === 'left' ? '10px 0 0 10px' : ' 0 10px 10px 0'};
    background-color: ${$selected ? theme.colors.steel : theme.colors.white};
    cursor: pointer;
  `,
);

// To Do: Refactor this and make it reusable for other Content types

export const ContentInputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FullWidth = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`;

interface SocialContentInputProps {
  $variant?: 'default' | 'bordered';
}

export const SocialContentInput = styled(
  TextareaAutosize,
)<SocialContentInputProps>(
  ({ theme, $variant = 'default' }) => css`
    font-size: 14px;
    font-family: ${theme.fonts.inter};
    line-height: 20px;
    color: ${theme.colors.nero};
    box-sizing: border-box;
    border: 1px solid transparent;
    resize: none;
    width: 100%;
    background-color: transparent;
    outline: none;
    padding: 8px;

    &:focus {
      border: 1px solid ${theme.colors.orbit};
      border-radius: 8px;
      outline: none;
    }

    ${$variant === 'bordered' &&
    css`
      margin: 0px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #dadee1;
    `}
  `,
);
