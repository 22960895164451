import { FC, useEffect } from 'react';
import PageLoader from '../../../../components/UI/PageLoader';
import FlexBlock from '../../../../components/UI/FlexBlock';
import Typography from '../../../../components/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../../store/hooks';
import { useGlobalSlice } from '../../../Global/slice';
import { localStorageEnum } from '../../../../../types';
import { asyncDelay } from '../../../../../utils/helpers';
import { RouteConstants } from '../../../../routes';
import { showErrorToast } from '../../../../../utils/toast';

interface IProps {}

const LogoutPage: FC<IProps> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { actions } = useGlobalSlice();
  const route = useLocation();

  const clearUserData: () => void = async () => {
    localStorage.removeItem(localStorageEnum.token);
    await asyncDelay(500);
    dispatch(actions.setUser({ user: null }));
    showToast();
    history.push(RouteConstants.authorization.login);
  };

  const showToast = () => {
    if (route.search.match('session-expire')) {
      showErrorToast('Please login again.', 'Session expired');
    }
  };

  useEffect(() => {
    clearUserData();
  }, []);

  return (
    <>
      <FlexBlock padding="24px" justifyContent="center">
        <Typography.Text>Logging you out... Please wait.</Typography.Text>
      </FlexBlock>
      <PageLoader />
    </>
  );
};

export default LogoutPage;
