import AutoComplete from 'app/components/AutoComplete/AutoComplete';
import Typography from 'app/components/Typography';

import FlexBlock from 'app/components/UI/FlexBlock';
import Pill from 'app/components/UI/Pill';
import { FC, useMemo, useState } from 'react';

import { ICustomTargetMediaBase } from 'app/api.types';
import { showErrorToast } from 'utils/toast';
import { useSearchCustomTargetMediaQuery } from 'app/api';
import InfoTip from 'app/components/UI/InfoTip/InfoTip';
import { SettingsSection } from '../../CreateOrEditPitchPage.styles';
import { PitchStateActions, usePitch, usePitchDispatch } from '../PitchContext';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import { CUSTOM_MEDIA_LIST_TOOLTIP } from '../../CreateOrEditPitch.constants';
import useDebounce from 'utils/hooks/useDebounce';
import { useParams } from 'react-router-dom';

const CustomMediaListsSelector: FC = () => {
  const params: {
    tenantId: string;
  } = useParams();

  const [mediaListSearchPhrase, setMediaListSearchPhrase] = useState('');

  const debouncedTargetMediaSearch = useDebounce(mediaListSearchPhrase, 500);

  const { selectedBrandMediaList } = usePitch();
  const dispatch = usePitchDispatch();

  const toggleBrandMediaItem = (item: ICustomTargetMediaBase) => {
    if (selectedBrandMediaList.some(m => m.id === item.id)) {
      dispatch({
        type: PitchStateActions.SET_SELECTED_BRAND_MEDIA,
        payload: selectedBrandMediaList.filter(si => si.id !== item.id),
      });
    } else {
      // Temporary limit till BE fix performance issues
      if (selectedBrandMediaList.length >= 7) {
        showErrorToast('Category limit of 7 has been reached');
        return;
      }

      dispatch({
        type: PitchStateActions.SET_SELECTED_BRAND_MEDIA,
        payload: [...selectedBrandMediaList, item],
      });
    }
  };

  const {
    data: targetMediasData,
    isLoading,
    isError,
  } = useSearchCustomTargetMediaQuery({
    name: debouncedTargetMediaSearch,
    tenantId: params.tenantId || undefined,
    pagination: {
      page: { page: 0, size: 50 },
      sort: [{ property: 'name', ascending: true }],
    },
  });

  const mediaList = useMemo(() => {
    if (
      targetMediasData &&
      targetMediasData.content &&
      targetMediasData.content.length > 0
    ) {
      return targetMediasData.content.filter(
        mediaList =>
          !selectedBrandMediaList.some(
            selectedMedia => selectedMedia.id === mediaList.id,
          ),
      );
    }
    return [];
  }, [targetMediasData, selectedBrandMediaList]);

  const renderSelectedBrandMedias = () => {
    return (
      <FlexBlock flexWrap="wrap" columnGap="8px" rowGap="8px" maxWidth="200px">
        {selectedBrandMediaList.map(item => (
          <Pill
            showCloseIcon
            onClick={() => toggleBrandMediaItem(item)}
            variant="light"
          >
            {item.name}
          </Pill>
        ))}
      </FlexBlock>
    );
  };

  if (isLoading) return <></>;

  // Note: Don't render the component if media list is empty and search phrase is not set
  if (
    (!mediaList || (mediaList.length < 1 && !selectedBrandMediaList)) &&
    mediaListSearchPhrase === '' &&
    debouncedTargetMediaSearch === ''
  )
    return <></>;

  return (
    <IfHasPermission require={userPermissionEnum.READ_TARGET_MEDIA}>
      <SettingsSection>
        <InfoTip tooltipText={CUSTOM_MEDIA_LIST_TOOLTIP}>
          <Typography.Text $colorName="orbit" $dmSans $bold $size={16}>
            Media List
          </Typography.Text>
        </InfoTip>
        <Typography.Text
          $colorName="steel"
          $size={14}
          $lineHeight={16}
          $margin="0 0 12px 8px"
        >
          Select custom target media list
        </Typography.Text>
        <AutoComplete
          options={mediaList}
          displayAccessor={'name'}
          onOptionSelect={toggleBrandMediaItem}
          searchValue={mediaListSearchPhrase}
          onSearchValueChange={value => setMediaListSearchPhrase(value)}
          renderSectionAboveInput={renderSelectedBrandMedias}
          noOptionsText={
            isError
              ? 'Could not load target media lists.'
              : 'No target media lists found.'
          }
          placeholder="Search for Custom Target Media Lists..."
          doNotUsePortal
          selectorHeight={48}
          maxMenuHeight={200}
        />
      </SettingsSection>
    </IfHasPermission>
  );
};

export default CustomMediaListsSelector;
