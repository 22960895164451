import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { IPreparedReportItem } from '../pages/PitchPage/parts/MediaReportCard/MediaReportCard.helpers';
import { pitchSaga } from './saga';
import { IDigDeeperCreator, IPdfPrintStatePart, PitchState } from './types';
import {
  CONTENT_LENGTH_SELECT_OPTIONS,
  TONE_SELECT_OPTIONS,
} from '../pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
} from '../pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';
import { IGeneratedPitchContent } from '../pages/CreateOrEditPitch/CreateOrEditPitch.types';
import {
  IAuthor,
  IAuthorEmailSuggestionsPayload,
  IAuthorEmailSuggestionsResponse,
  IAuthorSocial,
  IMedia,
} from 'app/api.types';

export const initialState: PitchState = {
  digDeeperAuthorId: null,
  digDeeperSentiment: null,
  digDeeperInterest: null,
  digDeeperReportItem: null,
  versionDrawerOpen: false,
  pdfPrint: null,
  digDeeperPodcastId: null,
  digDeeperTargetMedia: null,
  digDeeperCreator: null,
  contentGenerationDrawerOpen: false,
  contentGenerationTone: TONE_SELECT_OPTIONS[0],
  contentGenerationToneLength: CONTENT_LENGTH_SELECT_OPTIONS[1],
  generatedContent: [],
};

const slice = createSlice({
  name: 'pitch',
  initialState,
  reducers: {
    setAuthor(
      state,
      action: PayloadAction<{
        id: string;
        interest: string;
        sentiment: number;
        podcastId: string | null;
        reportItem: IPreparedReportItem | null;
        targetMedia: IMedia | null;
      } | null>,
    ) {
      if (!action.payload) {
        state.digDeeperAuthorId = null;
        state.digDeeperInterest = null;
        state.digDeeperSentiment = null;
        state.digDeeperPodcastId = null;
        state.digDeeperReportItem = null;
        state.digDeeperTargetMedia = null;
        state.digDeeperCreator = null;
      } else {
        state.digDeeperAuthorId = action.payload.id;
        state.digDeeperInterest = action.payload.interest;
        state.digDeeperSentiment = action.payload.sentiment;
        state.digDeeperPodcastId = action.payload.podcastId;
        state.digDeeperReportItem = action.payload.reportItem;
        state.digDeeperTargetMedia = action.payload.targetMedia;
      }
    },
    setCreator(state, action: PayloadAction<IDigDeeperCreator | null>) {
      if (!action.payload) {
        state.digDeeperCreator = null;
      } else {
        state.digDeeperCreator = action.payload;
      }
    },
    updateCreatorsSocials(state, action: PayloadAction<IAuthorSocial>) {
      if (state.digDeeperCreator && state.digDeeperCreator.socials) {
        state.digDeeperCreator.socials.push(action.payload);
      }
    },
    toggleVersionDrawer(state, action: PayloadAction<boolean>) {
      state.versionDrawerOpen = action.payload;
    },
    setPdfPrint(state, action: PayloadAction<IPdfPrintStatePart | null>) {
      state.pdfPrint = action.payload;
    },
    updateCachedPreference(state, action: PayloadAction<boolean | null>) {
      if (state.digDeeperReportItem) {
        state.digDeeperReportItem.preference = action.payload;
      }
    },

    setContentGenerationTone(state, action: PayloadAction<IToneSelectOption>) {
      state.contentGenerationTone = action.payload;
    },

    setContentGenerationLengthTone(
      state,
      action: PayloadAction<IContentLengthSelectOption>,
    ) {
      state.contentGenerationToneLength = action.payload;
    },

    toggleContentGenerationDrawer(state, action: PayloadAction<boolean>) {
      state.contentGenerationDrawerOpen = action.payload;
    },

    // addGeneratedContentToReportItem(
    //   state,
    //   action: PayloadAction<IToneSelectOption>,
    // ) {
    //   if (state.digDeeperReportItem) {
    //     if (state.digDeeperReportItem.generatedContentStatus) {
    //       state.digDeeperReportItem.generatedContentStatus[
    //         action.payload.value
    //       ] = 'PROCESSED';
    //     }
    //   }
    // },

    addToGeneratedContent(
      state,
      action: PayloadAction<IGeneratedPitchContent>,
    ) {
      const map = [
        ...state.generatedContent.map(i => `${i.authorId}-${i.tone.value}`),
      ];
      const indexOfExistingItem = map.indexOf(
        `${action.payload.authorId}-${action.payload.tone.value}`,
      );

      if (indexOfExistingItem > -1) {
        state.generatedContent[indexOfExistingItem] = action.payload;
      } else {
        state.generatedContent.push(action.payload);
      }
    },

    setForceRegenerateEmailContent(state, action: PayloadAction<boolean>) {
      state.forceRegenerateEmailContent = action.payload;
    },
    toggleEmailSuggestions(
      state,
      action: PayloadAction<{
        payload?: IAuthorEmailSuggestionsPayload;
        regenerate: boolean;
      }>,
    ) {
      if (action.payload.payload) {
        state.emailSuggestionsPayload = action.payload.payload;
      }
      state.isEmailSuggestionsLoading = true;
    },
    setEmailSuggestionsResponse(
      state,
      action: PayloadAction<IAuthorEmailSuggestionsResponse[] | undefined>,
    ) {
      state.isEmailSuggestionsLoading = false;

      if (action.payload !== undefined) {
        state.emailSuggestionsResponse = action.payload;
      }
    },
    clearEmailSuggestionsResponse(state) {
      state.isEmailSuggestionsLoading = false;
      state.emailSuggestionsResponse = undefined;
    },
    setEmailSuggestionsPayload(
      state,
      action: PayloadAction<{
        payload: IAuthorEmailSuggestionsPayload;
      }>,
    ) {
      state.emailSuggestionsPayload = action.payload.payload;
    },
  },
});

export const { actions: pitchActions } = slice;

export const usePitchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: pitchSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePitchSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
