import styled, { css } from 'styled-components/macro';

interface ICardWrapperProps {
  clickable?: boolean;
  padding?: string;
  minHeight?: string;
  margin?: string;
  overflowVisible?: boolean;
}

const Card = {
  Wrapper: styled.div<ICardWrapperProps>(
    ({
      theme,
      clickable,
      padding = '12px',
      minHeight = '375px',
      margin = '0 0 24px',
      overflowVisible = false,
    }) => css`
      //grid-column-end: span 3;
      background-color: ${theme.colors.white};
      padding: ${padding};
      border-radius: 16px;
      border: 1px solid ${theme.colors.smoke};
      margin: ${margin};

      min-height: ${minHeight};
      display: flex;
      flex-direction: column;
      transition: 0.15s all ease-in-out;
      user-select: none;

      ${overflowVisible
        ? css`
            overflow-y: visible;
          `
        : css`
            overflow: hidden;
          `}

      ${clickable
        ? css`
            cursor: pointer;
            &:hover {
              box-shadow: 0 0 16px rgba(12, 12, 13, 0.15);
              border: 1px solid ${theme.colors.onyx};
            }

            &:active {
              box-shadow: 0 0 18px rgba(12, 12, 13, 0.25);
            }
          `
        : ''}

      .hover-visible {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s opacity, 0.3s visibility;
      }

      &:hover .hover-visible {
        opacity: 1;
        visibility: visible;
      }
    `,
  ),
  Header: styled.div<{ padding?: string; noBorder?: boolean }>(
    ({ theme, padding = '12px', noBorder }) => css`
      display: flex;
      padding: ${padding};
      border-bottom: ${noBorder ? 0 : 1}px solid ${theme.colors.smoke};
      transition: 0.3s border-bottom-width;
    `,
  ),
  HeaderSection: styled.div<{
    alignRight?: boolean;
    flex?: string;
    alignTop?: boolean;
  }>(
    ({ alignRight, flex = 'auto', alignTop }) => css`
      flex: ${flex};
      display: flex;
      flex-direction: column;
      justify-content: ${alignTop ? 'flex-start' : 'center'};
      align-items: ${alignRight ? 'flex-end' : 'flex-start'};
    `,
  ),
  Content: styled.div<{ padding?: string; borderBottom?: boolean }>(
    ({ padding = '12px 12px', theme, borderBottom }) => css`
      padding: ${padding};
      flex: 1;
      ${borderBottom ? `border-bottom: 1px solid ${theme.colors.smoke}` : ''};
    `,
  ),
  ListItem: styled.div(
    () => css`
      display: flex;
      justify-content: space-between;
      margin: 4px 0;
    `,
  ),
  Footer: styled.div<{ padding?: string }>(
    ({ padding = '12px' }) => css`
      padding: ${padding};
      display: flex;
    `,
  ),
};

export default Card;
