import styled, { css } from 'styled-components';

export const Anchor = styled.a<{
  $margin?: string;
  $disabled?: boolean;
  display?: string;
  maxWidth?: string;
  variant?: 'default' | 'black';
}>(
  ({
    theme,
    $margin,
    $disabled,
    display = 'inline',
    variant = 'default',
    maxWidth,
  }) => css`
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.orbit};
    font-family: ${theme.fonts.inter};
    margin: ${$margin ? $margin : 'none'};
    text-decoration: underline;
    cursor: pointer;
    display: ${display};
    ${$disabled
      ? css`
          color: ${theme.colors.nero};
          opacity: 0.7;
          pointer-events: none;
        `
      : ''}

    ${variant === 'black'
      ? css`
          color: ${theme.colors.onyx};
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        `
      : ''}
    
    ${maxWidth
      ? css`
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: ${maxWidth};
          overflow: hidden;
        `
      : ''}
  `,
);
