import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  IJournalistsState,
  IMutateMediaListEntriesPayload,
  IAddMediaListEntriesPayload,
} from './types';

export const initialState: IJournalistsState = {
  createListModalOpen: false,
  uploadListModalOpen: false,
  copyMediaListEntriesModalOpen: false,
  addToMediaListModalOpen: false,
  moveMediaListEntriesModalOpen: false,
  mediaListSourceObject: null,
  journalistsToCopyIds: [],
  journalistsToMoveIds: [],
  journalistsToAdd: [],
};

const slice = createSlice({
  name: 'journalists',
  initialState,
  reducers: {
    toggleUploadMediaListModal(state, action: PayloadAction<boolean>) {
      state.uploadListModalOpen = action.payload;
    },
    toggleCreateMediaListModal(state, action: PayloadAction<boolean>) {
      if (typeof action.payload === 'boolean' && action.payload === false) {
        state.journalistsToAdd = [];
      }
      state.createListModalOpen = action.payload;
    },
    transitionFromAddToCreateMediaListModal: state => {
      state.createListModalOpen = true;
      state.addToMediaListModalOpen = false;
    },
    toggleAddToMediaListModal: (
      state,
      action: PayloadAction<boolean | IAddMediaListEntriesPayload>,
    ) => {
      if (typeof action.payload === 'boolean' && action.payload === false) {
        state.addToMediaListModalOpen = false;
        state.journalistsToAdd = [];
      } else if (
        typeof action.payload === 'object' &&
        action.payload !== null
      ) {
        const { rows } = action.payload;
        if (Array.isArray(rows)) {
          state.journalistsToAdd = rows;
          state.addToMediaListModalOpen = true;
        }
      }
    },
    toggleCopyMediaListEntriesModal: (
      state,
      action: PayloadAction<boolean | IMutateMediaListEntriesPayload>,
    ) => {
      if (typeof action.payload === 'boolean' && action.payload === false) {
        state.copyMediaListEntriesModalOpen = false;
        state.mediaListSourceObject = null;
        state.journalistsToCopyIds = [];
      } else if (
        typeof action.payload === 'object' &&
        action.payload !== null
      ) {
        const { ids, source } = action.payload;
        if (Array.isArray(ids)) {
          state.journalistsToCopyIds = ids;
          state.copyMediaListEntriesModalOpen = true;
          state.mediaListSourceObject = source;
        }
      }
    },
    toggleMoveMediaListEntriesModal: (
      state,
      action: PayloadAction<boolean | IMutateMediaListEntriesPayload>,
    ) => {
      if (typeof action.payload === 'boolean' && action.payload === false) {
        state.moveMediaListEntriesModalOpen = false;
        state.mediaListSourceObject = null;
        state.journalistsToMoveIds = [];
      } else if (
        typeof action.payload === 'object' &&
        action.payload !== null
      ) {
        const { ids, source } = action.payload;
        if (Array.isArray(ids)) {
          state.journalistsToMoveIds = ids;
          state.moveMediaListEntriesModalOpen = true;
          state.mediaListSourceObject = source;
        }
      }
    },
  },
});

export const { actions: journalistsActions } = slice;

export const useJournalistsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
