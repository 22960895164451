import styled, { css } from 'styled-components/macro';

export const TextArea = styled.textarea(
  ({ theme }) => css`
    box-sizing: border-box;
    font-size: 16px;
    flex: 1;
    min-height: 128px;
    padding: 20px 16px;
    width: 100%;
    border: 1px solid ${theme.colors.smoke};
    border-radius: 12px;
    background-color: ${theme.colors.white};
    box-shadow: 0 0 0px 1000px white inset;
    transition: border-color 0.3s, box-shadow 0.3s;
    font-family: ${theme.fonts.inter};
    &:focus {
      outline: none;
      border: 1px solid ${theme.colors.black};
      box-shadow: 0 0 0px 1000px white inset, 0 2px 8px 0 rgba(9, 9, 9, 0.11);
    }
  `,
);
