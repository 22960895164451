import { FC } from 'react';
import styled, { css } from 'styled-components/macro';

const SVGWrapper = styled.span<{ rotateDown?: boolean }>(
  ({ rotateDown }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.12s all ease-in-out;
    transform: rotate(0deg);
    cursor: pointer;
    ${rotateDown
      ? css`
          transform: rotate(180deg);
        `
      : ''}
  `,
);

const CarrotUpIcon: FC<{ rotateDown?: boolean; fill?: string }> = ({
  rotateDown,
  fill,
}) => (
  <SVGWrapper rotateDown={rotateDown}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill || '#737377'} transform="translate(-1293 -308)">
          <g transform="translate(260 72)">
            <g transform="translate(40 176)">
              <g transform="translate(24 20)">
                <g transform="translate(957 28)">
                  <g transform="translate(12 12)">
                    <path
                      d="M1.32 4a.93.93 0 00-.652.266.905.905 0 000 1.285l6.676 6.583c.173.17.416.266.652.266a.917.917 0 00.652-.266l6.667-6.574a.901.901 0 00.022-1.294.937.937 0 00-1.304 0l-6.025 5.952L1.97 4.266A.93.93 0 001.319 4z"
                      transform="matrix(1 0 0 -1 0 16.4)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </SVGWrapper>
);

export default CarrotUpIcon;
