import { ICreateBlogPayload } from 'app/api.types';
import { TONE_SELECT_OPTIONS } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';

export const getToneObjectByValue = (toneValue: string) => {
  const toneObject = TONE_SELECT_OPTIONS.find(tone => tone.value === toneValue);
  if (!toneObject) return TONE_SELECT_OPTIONS[0];
  return toneObject;
};

export const validateCreateBlogPayload = (payload: ICreateBlogPayload) => {
  if (!payload.projectId) return false;

  if (
    !payload.blogContentCreateBody ||
    !payload.blogContentCreateBody.blogTopic ||
    !payload.blogContentCreateBody.body ||
    !payload.blogContentCreateBody.title ||
    !payload.blogContentCreateBody.tone
  )
    return false;

  return true;
};
