function RegenerateIcon({ size = '16', fill = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} transform="translate(-806 -104)">
          <g transform="translate(260 72)">
            <g transform="translate(40 12)">
              <g transform="translate(490)">
                <g transform="translate(16 20)">
                  <path d="M2.603 10.142a5.793 5.793 0 01.898-5.81l-.013.89 1.79.038.069-3.948-3.944-.067-.038 1.792.871.012a7.585 7.585 0 00-1.298 7.754 7.573 7.573 0 008.606 4.635l-.366-1.754a5.787 5.787 0 01-6.575-3.541zm12.458-4.946A7.576 7.576 0 006.458.562l.365 1.754a5.789 5.789 0 016.574 3.54 5.795 5.795 0 01-.9 5.815l.012-.898-1.79-.034-.062 3.948 3.944.06.034-1.792-.865-.01a7.585 7.585 0 001.291-7.75z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RegenerateIcon;
