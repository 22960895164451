import { FC } from 'react';
import Select from '../../../../../../components/UI/Select/Select';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
} from './ToneSelectDropDown.types';
import {
  CONTENT_LENGTH_SELECT_OPTIONS,
  TONE_SELECT_OPTIONS,
} from './ToneSelectDropDown.config';
import Typography from '../../../../../../components/Typography';
import FlexBlock from '../../../../../../components/UI/FlexBlock';
import { FormatOptionLabelMeta } from 'react-select/base';

interface IProps {
  value: IToneSelectOption;
  onChange: (opt: IToneSelectOption) => void;
  selectComponentWidth?: string;
  menuPlacement?: 'top' | 'bottom';
  height?: string;
  isCompact?: boolean;
  selectorHeight?: number;
  selectorRadius?: number;
}

interface IContentLengthSelectorProps {
  value: IContentLengthSelectOption;
  onChange: (opt: IContentLengthSelectOption) => void;
  selectComponentWidth?: string;
  menuPlacement?: 'top' | 'bottom';
  height?: string;
  isCompact?: boolean;
}

const OptionLabelFormatter = (
  option: IToneSelectOption,
  meta: FormatOptionLabelMeta<IToneSelectOption>,
) => (
  <FlexBlock flexDirection="row" columnGap="8px">
    <img src={option.icon} alt={option.name} width={21} height={21} />
    <Typography.Text $dmSans $bold $size={16} inheritColor $lineHeight={24}>
      {option.name}
    </Typography.Text>
  </FlexBlock>
);

const ContentLengthOptionLabelFormatter = (
  option: IContentLengthSelectOption,
  meta: FormatOptionLabelMeta<IContentLengthSelectOption>,
) => {
  return (
    <FlexBlock flexDirection="column" columnGap="8px">
      <Typography.Text
        $dmSans
        $bold
        $size={16}
        $colorName="onyx"
        $lineHeight={24}
      >
        {option.name}
      </Typography.Text>
      {meta.context === 'menu' && (
        <Typography.Text $size={14} $colorName="steel" $lineHeight={16}>
          {option.subtitle}
        </Typography.Text>
      )}
    </FlexBlock>
  );
};

export const ToneSelectDropDown: FC<IProps> = props => {
  return (
    <Select<IToneSelectOption>
      options={TONE_SELECT_OPTIONS}
      formatOptionLabel={OptionLabelFormatter}
      value={props.value}
      onChange={opt => props.onChange(opt as IToneSelectOption)}
      width={props.selectComponentWidth || '245px'}
      height={props.height ?? '100%'}
      isCompact={props.isCompact || false}
      menuPlacement={props.menuPlacement || 'bottom'}
      selectorHeight={props.selectorHeight}
      selectorRadius={props.selectorRadius}
    />
  );
};

export const ContentLengthDropDown: FC<IContentLengthSelectorProps> = props => {
  return (
    <Select<IContentLengthSelectOption>
      options={CONTENT_LENGTH_SELECT_OPTIONS}
      formatOptionLabel={ContentLengthOptionLabelFormatter}
      value={props.value}
      onChange={opt => props.onChange(opt as IContentLengthSelectOption)}
      width={props.selectComponentWidth || '245px'}
      height={props.height ?? '100%'}
      menuPlacement={props.menuPlacement || 'bottom'}
      isCompact={props.isCompact || false}
      toneLengthVariant
    />
  );
};
