import { FC, useMemo } from 'react';
import { IPreparedReportItem } from '../../MediaReportCard/MediaReportCard.helpers';
import Button from '../../../../../../../components/UI/Button/Button';
import { commonAuthorTooltipProps } from 'app/components/UI/Tooltip/Tooltip';
import TwitterIcon from '../../../../../../../assets/icons/TwitterIcon';
import { Link } from 'react-router-dom';

interface IProps {
  row: IPreparedReportItem;
}

const SocialsCell: FC<IProps> = props => {
  const twitterData: { url: string; name: string } | null = useMemo(() => {
    const url = props.row.authorSocials?.find(
      social => social.socialType === 'TWITTER',
    )?.url;
    const splittedUrl = url?.split('/');
    if (!url) return null;
    const name = `@${splittedUrl ? splittedUrl[splittedUrl.length - 1] : '-'}`;

    return { url, name };
  }, [props.row.authorSocials]);

  if (!twitterData) {
    return <></>;
  }
  return (
    <div
      {...commonAuthorTooltipProps}
      data-tip={twitterData.name}
      data-for="authorTwitterContactTooltip"
    >
      <Link
        to={{ pathname: twitterData.url }}
        target="_blank"
        onClick={e => e.stopPropagation()}
      >
        <Button variant="icon-round-social" margin="0px 4px">
          <TwitterIcon size={16} />
        </Button>
      </Link>
    </div>
  );
};

export default SocialsCell;
