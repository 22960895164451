import { SVGProps } from 'react';

const LinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 .8C24.395.8 31.2 7.605 31.2 16S24.395 31.2 16 31.2.8 24.395.8 16 7.605.8 16 .8Zm4.421 10.942a3.14 3.14 0 0 0-2.824 1.554h-.04V11.98h-2.862V21.6h2.981V16.84c0-1.254.238-2.47 1.79-2.47 1.531 0 1.551 1.436 1.551 2.552V21.6H24L24 16.323c0-2.59-.557-4.581-3.579-4.581Zm-7.6.24H9.837V21.6h2.984v-9.619ZM11.331 7.2c-.95 0-1.731.782-1.731 1.733 0 .951.781 1.733 1.73 1.733l.149-.006a1.741 1.741 0 0 0 1.581-1.727A1.74 1.74 0 0 0 11.33 7.2Z"
      fill="#090909"
      fillRule="evenodd"
      opacity={0.5}
    />
  </svg>
);

export default LinkedinIcon;
