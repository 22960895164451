import { FC, SVGProps } from 'react';

const HistoryIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_326_5902)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99984 2.00033C4.68613 2.00033 1.99984 4.68662 1.99984 8.00033C1.99984 11.314 4.68613 14.0003 7.99984 14.0003C11.3135 14.0003 13.9998 11.314 13.9998 8.00033C13.9998 4.68662 11.3135 2.00033 7.99984 2.00033ZM0.666504 8.00033C0.666504 3.95024 3.94975 0.666992 7.99984 0.666992C12.0499 0.666992 15.3332 3.95024 15.3332 8.00033C15.3332 12.0504 12.0499 15.3337 7.99984 15.3337C3.94975 15.3337 0.666504 12.0504 0.666504 8.00033Z"
          fill="#3F3F42"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00016 3.33301C8.36835 3.33301 8.66683 3.63148 8.66683 3.99967V7.58765L10.965 8.73672C11.2943 8.90138 11.4278 9.30183 11.2631 9.63115C11.0985 9.96047 10.698 10.094 10.3687 9.92929L7.70202 8.59596C7.47616 8.48303 7.3335 8.25219 7.3335 7.99967V3.99967C7.3335 3.63148 7.63197 3.33301 8.00016 3.33301Z"
          fill="#3F3F42"
        />
      </g>
      <defs>
        <clipPath id="clip0_326_5902">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HistoryIcon;
