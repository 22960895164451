import styled, { css } from 'styled-components/macro';

const RoundIconWrapper = styled.div<{
  size?: number;
  loading?: boolean;
  svgSize?: string | number;
  svgFillBlack?: boolean;
  noBackground?: boolean;
}>(
  ({ theme, size = 56, loading, svgSize, svgFillBlack, noBackground }) => css`
    background: ${theme.colors.haze};
    border-radius: 50%;
    width: ${size}px;
    height: ${size}px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @keyframes riw_ring_spin {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      0% {
        transform: translate(-50%, -50%) rotate(-360deg);
      }
    }

    &:after {
      content: '';
      position: absolute;
      border: 1px solid ${theme.colors.orbit};
      width: calc(${size}px + 30%);
      height: calc(${size}px + 30%);
      background: transparent;
      border-radius: 50%;
      border-top: 1px solid ${theme.colors.haze};
      border-left: 1px solid ${theme.colors.haze};

      border-width: 0px;

      animation: 1.2s riw_ring_spin infinite;
      animation-timing-function: linear;
      opacity: 0;
      visibility: visible;
      transition: 0.25s all ease-in-out;
      transition-delay: 0.25s;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    transform: scale(1);
    transition: 0.5s all ease-in-out;
    transition-delay: 0.5s;

    ${svgSize
      ? css`
          svg {
            height: 48px;
            width: 48px;
          }
        `
      : ''}

    ${svgFillBlack
      ? css`
          svg {
            path {
              fill: ${theme.colors.onyx};
            }
          }
        `
      : ''}

    ${loading
      ? css`
          &:after {
            opacity: 1;
            visibility: visible;
            border-width: 9px;
          }
          transform: scale(0.8);
          transition-delay: 0s;
        `
      : ''}

    ${noBackground
      ? css`
          height: 200px;
          width: 200px;
          background: none;
          border: none;
          border-radius: 0;
          transform: none;
          transition: none;
          &:after {
            content: none;
            transition: none;
          }
          img {
            width: 100%;
            height: 100%;
          }
        `
      : ''}
  `,
);
export default RoundIconWrapper;
