import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div<{ disabled?: boolean; maxWidth?: string }>(
  ({ theme, disabled, maxWidth = 'auto' }) => css`
    position: relative;
    border: 1px solid ${theme.colors.aluminum};
    border-radius: 10px;
    min-height: 56px;
    padding: 18px 16px;
    width: 100%;
    max-width: ${maxWidth};

    &:focus-within {
      border: 1px solid ${theme.colors.orbit};
    }

    ${disabled
      ? css`
          border: 1px solid ${theme.colors.haze};
          background-color: ${theme.colors.haze};
          cursor: default;
          &:focus-within {
            border: 1px solid ${theme.colors.haze};
          }
          input {
            background-color: ${theme.colors.haze};
          }
        `
      : ''}
  `,
);

export const InnerWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  row-gap: 16px;
`;

export const IconContainer = styled.div<{ carrotStyling?: boolean }>(
  ({ carrotStyling }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    ${carrotStyling
      ? css`
          svg {
            width: 16px;
            height: 16px;
            path {
              fill: #000;
            }
          }
        `
      : ''}
  `,
);

export const Input = styled.input<{ readOnly?: boolean }>(
  ({ readOnly, theme }) => css`
    width: 100%;
    border: none;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: #0c0c0d;
      opacity: 1;
      font-size: 14px;
      font-family: ${theme.fonts.inter};
    }
    :-ms-input-placeholder {
      color: #0c0c0d;
      opacity: 1;
      font-size: 14px;
      font-family: ${theme.fonts.inter};
    }

    &:first-child {
      min-height: auto;
    }
    &:focus {
      outline: none;
    }

    ${readOnly
      ? css`
          cursor: default;
        `
      : ''}
  `,
);

export const LoaderContainer = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
`;

export const MenuWrapper = styled.div<{
  top: number;
  left: number;
  width: number;
  height: number;
  portal?: boolean;
  $menuHeight?: number;
  $maxMenuHeight?: number;
  $menuPadding?: string;
}>(
  ({
    theme,
    top,
    left,
    width,
    portal,
    height,
    $menuHeight,
    $maxMenuHeight,
    $menuPadding,
  }) => css`
    position: absolute;
    background: #fff;
    /* padding: ${$menuPadding || '20px 0'}; */
    width: ${width}px;
    z-index: 999;
    top: ${top + height + 8}px;
    left: ${left}px;
    border: 1px solid ${theme.colors.aluminum};
    box-shadow: 0 0 16px rgba(12, 12, 13, 0.1);
    border-radius: 10px;
    overflow: hidden;

    ${$maxMenuHeight
      ? css`
          max-height: ${$maxMenuHeight}px;
        `
      : ''}

    ${!portal
      ? css`
          position: absolute;
          top: ${height + 8}px;

          left: 0;
        `
      : ''}
  `,
);

export const Option = styled.div(
  ({ theme }) => css`
    padding: 8px 20px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.colors.haze};
    }
  `,
);
