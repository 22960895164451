import { useMemo } from 'react';

export const useContentTypeSelectorHeader = (
  options: {
    key: string;
    displayName: string;
    count: number;
    checked?: boolean;
  }[],
  title?: string,
) => {
  const selectedCount = useMemo(() => {
    return options.reduce((acc, item) => (item.checked ? acc + 1 : acc), 0);
  }, [options]);

  const selectedDisplayNames = useMemo(() => {
    return options
      .filter(option => option.checked)
      .map(option => ({ displayName: option.displayName, key: option.key }));
  }, [options]);

  const pluralTitle = title ? `${title}s` : 'Content';

  const selectorTitle =
    selectedCount && selectedCount < options.length
      ? `${selectedCount} ${title ? title : 'Filter'}${
          selectedCount > 1 ? 's' : ''
        }${!title ? ' Selected' : ''}`
      : `All ${pluralTitle}`;

  const optionsHeaderTitle = title ? `${title}s` : 'Content Types';

  return {
    selectedCount,
    selectedDisplayNames,
    selectorTitle,
    optionsHeaderTitle,
  };
};
