import React from 'react';

function UserFilledIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#737377" transform="translate(-324 -138)">
          <g transform="translate(260 72)">
            <g transform="translate(48 40)">
              <g transform="translate(16 26)">
                <path d="M10 .5a9.5 9.5 0 110 19 9.5 9.5 0 010-19zm0 12.893a6.222 6.222 0 00-5.306 2.768 8.095 8.095 0 0010.612 0A6.297 6.297 0 0010 13.393zm0-8.822a3.393 3.393 0 100 6.786 3.393 3.393 0 000-6.786zm0 1.358A2.036 2.036 0 1110 10a2.036 2.036 0 010-4.071z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default UserFilledIcon;
