import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as S from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitchPage.styles';
import { useGetAvailableJournalistFiltersQuery } from 'app/api';
import MultiSelect from 'app/components/UI/MultiSelect/MultiSelect';
import CoverLetterIcon from 'app/assets/icons/CoverLetterIcon';
import InfoTip from 'app/components/UI/InfoTip/InfoTip';
import {
  COUNTRY_CODES_FOR_STATES,
  GEOGRAPHY_TOOLTIP,
  SOURCE_TOOLTIP,
} from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitch.constants';
import LocationIcon from 'app/assets/icons/LocationIcon';
import GlobeIcon from 'app/assets/icons/GlobeIcon';
import Button from 'app/components/UI/Button/Button';
import { IPitchMediaFilter } from 'app/api.types';
import { FilterActionsWrapper } from '../../JournalistsList.styles';
import { defaultJournalistsFilters } from '../../JournalistsListPage.constants';
import { mapFilterOptionsToPayload } from '../../JournalistList.helpers';
import CheckBox from 'app/components/UI/CheckBox/CheckBox';
import { filtersEqual } from './FiltersPanel.helpers';

export interface IFilterOptions {
  countries: IPitchMediaFilter[];
  states: IPitchMediaFilter[];
  outletScopeFilter: {
    inclusions: IPitchMediaFilter[];
    exclusions: IPitchMediaFilter[];
  };
  relevant: boolean;
  favorite: boolean | null;
  email?: string;
  name?: string;
  publicationName?: string;
}

interface IFilterPanelProps {
  activeFilters: IFilterOptions;
  setFilters: Dispatch<SetStateAction<IFilterOptions>>;
  handleClose: () => void;
  open: boolean;
}

const FiltersPanel: FC<IFilterPanelProps> = ({
  activeFilters,
  setFilters,
  handleClose,
  open,
}) => {
  const [selectedFilters, setSelectedFilters] =
    useState<IFilterOptions>(activeFilters);

  const filtersEqualDefault = useMemo(() => {
    return filtersEqual(selectedFilters, defaultJournalistsFilters);
  }, [selectedFilters]);

  const filtersEqualActive = useMemo(() => {
    return filtersEqual(selectedFilters, activeFilters);
  }, [selectedFilters, activeFilters]);

  const availableFiltersRequestBody = useMemo(() => {
    return mapFilterOptionsToPayload(selectedFilters);
  }, [selectedFilters]);

  const displayStates = useMemo(() => {
    return selectedFilters.countries.find(country =>
      COUNTRY_CODES_FOR_STATES.includes(country.code),
    );
  }, [selectedFilters.countries]);

  useEffect(() => {
    setSelectedFilters(activeFilters);
  }, [activeFilters]);

  useEffect(() => {
    if (!open) {
      setSelectedFilters(activeFilters);
    }
  }, [open, activeFilters]);

  const {
    data: availableFilters,
    isLoading: isLoadingAvailableFilters,
    isFetching: isFetchingAvailableFilters,
    isError: isErrorAvailableFilters,
  } = useGetAvailableJournalistFiltersQuery(availableFiltersRequestBody);

  const handleResetFilters = () => {
    setFilters(currentFilters => ({
      ...currentFilters,
      ...defaultJournalistsFilters,
    }));

    setSelectedFilters(selectedFilters => ({
      ...selectedFilters,
      ...defaultJournalistsFilters,
    }));
  };

  const handleApplyFilters = () => {
    setFilters(selectedFilters);
    handleClose();
  };

  if (isErrorAvailableFilters) {
    return (
      <FlexBlock margin="2em" flexDirection="column">
        <Typography.Text $dmSans $bold $size={18}>
          Something went wrong
        </Typography.Text>
        <Typography.Text>Could not load filter options</Typography.Text>
      </FlexBlock>
    );
  }

  return (
    <>
      <FlexBlock
        flexDirection="column"
        minWidth="100%"
        style={{ flexGrow: 1, overflowY: 'auto' }}
      >
        <S.SettingsSection>
          <InfoTip tooltipText={GEOGRAPHY_TOOLTIP}>
            <Typography.Text $colorName="orbit" $dmSans $bold $size={16}>
              Geography
            </Typography.Text>
          </InfoTip>
          <Typography.Text
            $colorName="steel"
            $size={14}
            $lineHeight={16}
            $margin="0 0 12px 8px"
          >
            Country*
          </Typography.Text>

          <MultiSelect
            options={availableFilters?.countryTypes || []}
            selectedOptions={selectedFilters.countries}
            onUpdate={options =>
              setSelectedFilters(filters => ({
                ...filters,
                countries: options,
                states: filters.states?.filter(state => {
                  return options?.some(country =>
                    state.code.startsWith(`${country.code}_`),
                  );
                }),
              }))
            }
            icon={<GlobeIcon />}
            placeholder="Select Countries"
            zIndex={16}
            maxMenuHeight={350}
            isFetchingOptions={
              isFetchingAvailableFilters || isLoadingAvailableFilters
            }
          />

          {displayStates && (
            <>
              <Typography.Text
                $colorName="steel"
                $size={14}
                $lineHeight={16}
                $margin="16px 0 12px 8px"
              >
                States (AUS, CA, UK, and US only)
              </Typography.Text>
              <MultiSelect
                options={availableFilters?.stateTypes || []}
                selectedOptions={selectedFilters.states}
                onUpdate={options =>
                  setSelectedFilters(filters => ({
                    ...filters,
                    states: options,
                  }))
                }
                icon={<LocationIcon />}
                placeholder="Select States"
                zIndex={16}
                maxMenuHeight={350}
                searchable
                isFetchingOptions={
                  isFetchingAvailableFilters || isLoadingAvailableFilters
                }
              />
            </>
          )}
        </S.SettingsSection>

        <S.SettingsSection>
          <InfoTip tooltipText={SOURCE_TOOLTIP}>
            <Typography.Text $colorName="orbit" $dmSans $bold $size={16}>
              Source
            </Typography.Text>
          </InfoTip>
          <Typography.Text
            $colorName="steel"
            $size={14}
            $lineHeight={16}
            $margin="0 0 12px 8px"
          >
            Select and filter by source category
          </Typography.Text>

          <MultiSelect
            options={availableFilters?.sourceTypes || []}
            selectedOptions={selectedFilters.outletScopeFilter.inclusions}
            onUpdate={options =>
              setSelectedFilters(filters => ({
                ...filters,
                outletScopeFilter: { inclusions: options, exclusions: [] },
              }))
            }
            icon={<CoverLetterIcon size="20px" />}
            placeholder="Select Sources"
            zIndex={16}
            maxMenuHeight={350}
            searchable
            isFetchingOptions={
              isFetchingAvailableFilters || isLoadingAvailableFilters
            }
          />
        </S.SettingsSection>
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          columnGap="10px"
          padding="16px 24px 24px"
        >
          <CheckBox
            checked={!selectedFilters.relevant}
            onChange={checked =>
              setSelectedFilters(filters => ({
                ...filters,
                relevant: !checked,
              }))
            }
          />
          <Typography.Text $size={14} $lineHeight={16}>
            Include Journalists without articles?
          </Typography.Text>
        </FlexBlock>
      </FlexBlock>
      <FilterActionsWrapper>
        <Button
          disabled={filtersEqualDefault}
          variant="secondary"
          onClick={handleResetFilters}
        >
          Reset
        </Button>
        <Button
          disabled={filtersEqualActive}
          variant="primary"
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </FilterActionsWrapper>
    </>
  );
};

export default FiltersPanel;
