import { useGetPredictionQuery, useGetPressReleaseQuery } from 'app/api';
import { useCreateOrEditPressReleaseParams } from './useCreateOrEditPressReleaseParams.hook';
import { usePressReleaseDispatch } from '../context/PressReleaseContext';
import {
  PressReleaseContentType,
  PressReleaseStateActions,
} from '../CreateOrEditPressRelease.constants';
import moment from 'moment';
import { useEffect, useMemo } from 'react';

export const usePressReleaseData = () => {
  const { editParams, createParams } = useCreateOrEditPressReleaseParams();

  const pageTitle: string = useMemo(() => {
    if (createParams) {
      return 'New Press Release';
    }
    if (editParams) {
      return 'Edit Press Release';
    }
    return 'Press Release';
  }, [createParams, editParams]);

  const dispatch = usePressReleaseDispatch();

  const { data: pressRelease, isFetching: isPressReleaseFetching } =
    useGetPressReleaseQuery(
      {
        id: Number(editParams?.params.pressReleaseId),
      },
      {
        skip: Boolean(createParams),
      },
    );

  const { data: prediction, isFetching: isPredictionFetching } =
    useGetPredictionQuery(
      {
        pitchId: Number(editParams?.params.pressReleaseId),
        versionId: Number(editParams?.params.versionId),
        predictionType: 'PRESS_RELEASE',
      },
      {
        skip: Boolean(createParams),
      },
    );

  // Populate Press Release data if it exists and is edit mode
  useEffect(() => {
    if (pressRelease) {
      dispatch({
        type: PressReleaseStateActions.UPDATE_PRESS_RELEASE,
        payload: {
          id: pressRelease.id,
          timingDate: moment(pressRelease.createdAt),
        },
      });

      dispatch({
        type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENTS,
        payload: [
          {
            contentType: PressReleaseContentType.ORIGINAL,
            content: {
              title: pressRelease.title,
              pressReleaseCopy: pressRelease.pressReleaseCopy,
              quotes: pressRelease.quotes,
              date: pressRelease.date,
              city: pressRelease.city,
              boilerPlate: pressRelease.boilerPlate,
            },
          },
        ],
      });

      if (pressRelease.pressReleaseContents) {
        dispatch({
          type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT_VERSIONS,
          payload: pressRelease.pressReleaseContents,
        });
      }
    }

    if (prediction) {
      const { countries, states, mediaSourceFilter, contentTypes } = prediction;

      dispatch({
        type: PressReleaseStateActions.UPDATE_PRESS_RELEASE,
        payload: {
          countries: countries && countries.length > 0 ? countries : [],
          runAgainstPodcasts: prediction.reports.some(
            report => report.mediaList.type?.code === 'PODCAST',
          ),
          mediaSourceFilter: mediaSourceFilter ? mediaSourceFilter : undefined,
          contentTypes:
            contentTypes && contentTypes.length > 0 ? contentTypes : undefined,
          states: states && states.length > 0 ? states : undefined,
          selectedMediaLists: [],
        },
      });
    }
  }, [pressRelease, prediction, dispatch]);

  return {
    pageTitle,
    pressRelease,
    prediction,
    isPredictionFetching,
    isPressReleaseFetching,
  };
};
