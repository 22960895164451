import {
  ContentHeader,
  ContentLayout,
} from 'app/components/UI/ContentLayout/ContentLayout';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  BlogPageTabs,
  ContentControls,
  TopHeader,
} from './parts/BlogPageHeader';
import MainGrid from 'app/components/UI/MainGrid/MainGrid';
import BlogPageContent from './parts/BlogPageContent';
import { ContentWrapper, FullWidth, StickToTop } from './BlogPage.styles';
import BlogVersionDrawer from './parts/BlogVersionDrawer';
import BlogSettingsDrawer from './parts/BlogSettingsDrawer';
import { useBlogData } from './hooks/useBlogData';
import FlexBlock from 'app/components/UI/FlexBlock';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';
import PageLoader from 'app/components/UI/PageLoader';
import { RouteConstants } from 'app/routes';
import { showCustomToast, showErrorToast, showSuccessToast } from 'utils/toast';
import useLimitExceededPopup from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/hooks/useLimitExceededPopup';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import { useHistory } from 'react-router-dom';
import CreateBlogForm from '../CreateOrEditBlog/parts/CreateBlogForm';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {
  useCreateNewBlogMutation,
  useGetGeneratedBlogMutation,
  useSaveBlogContentMutation,
} from 'app/api';
import {
  IBlog,
  IBlogContentPayload,
  IBlogContentResponse,
  IBlogGenerationPayload,
  IBlogGenerationResponse,
} from 'app/api.types';
import { useBlogGenerationLimits } from './hooks/useBlogGenerationLimits';
import { validateCreateBlogPayload } from './BlogPage.helpers';
import BlogRegenerationModal from './parts/BlogRegenerationModal';
import { BlogGenerationSuccessToast } from './parts/BlogGenerationSuccessToast';
import { logger } from 'utils/apm/logger';

const BlogPage: FC = () => {
  const [isVersionDrawerOpen, setIsVersionDrawerOpen] = useState(false);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);

  const history = useHistory();

  const {
    isBlogLoading,
    isBlogContentLoading,
    content,
    payload,
    blog: blogData,
    isDraft,
    blogId,
    tone,
    versionId,
    projectId,
    tenantId,
    isError,
  } = useBlogData();

  const [generateBlog, { isLoading: isGenerateLoading }] =
    useGetGeneratedBlogMutation();

  const [saveGeneratedBlog, { isLoading: isBlogSaving }] =
    useSaveBlogContentMutation();

  const [createBlog] = useCreateNewBlogMutation();

  const [currentBlogContent, setCurrentBlogContent] = useState({
    title: content?.title || '',
    body: content?.body || '',
  });

  useEffect(() => {
    setCurrentBlogContent({
      title: content?.title || '',
      body: content?.body || '',
    });
  }, [content]);

  const isBlogModified = useMemo(() => {
    if (!currentBlogContent.title || !currentBlogContent.body) return false;

    if (
      content?.title !== currentBlogContent.title ||
      content.body !== currentBlogContent.body
    ) {
      return true;
    }

    return false;
  }, [
    content?.title,
    content?.body,
    currentBlogContent.title,
    currentBlogContent.body,
  ]);

  const { createModal } = usePromptModalContext();
  const limitExceededPopup = useLimitExceededPopup();

  const { blogGenerationsLeft, refresh: refreshLimits } =
    useBlogGenerationLimits();

  const redirectToContentVersion = (blogId: number, versionId: number) => {
    const blogRoute = RouteConstants.blog.makeBlogUrl(
      tenantId,
      projectId,
      blogId,
      versionId,
    );
    history.push(blogRoute);
  };

  const handleRegenerate = async ({
    modifiedPayload,
    tone,
  }: {
    modifiedPayload?: IBlogGenerationPayload;
    tone?: string;
  }) => {
    if (blogGenerationsLeft === 0) {
      limitExceededPopup.show();
      return;
    }

    setIsSettingsDrawerOpen(false);
    if (!payload) return;

    let createdBlog: IBlog | undefined;
    let latestSavedContent: IBlogContentResponse | undefined;
    let currentContentVersion = versionId;

    try {
      // Persist draft or modified content as a new Blog or Blog's content version before regenerating content
      if (isBlogModified || isDraft) {
        if (!blogId) {
          createdBlog = await createNewBlog(payload);
          if (createdBlog && createdBlog.latestVersion) {
            currentContentVersion = createdBlog.latestVersion;
          }
        } else {
          latestSavedContent = await persistBlogContent();
          if (latestSavedContent) {
            currentContentVersion = versionId + 1;
          }
        }
      }

      const regenerationPayload = modifiedPayload || payload;

      if (regenerationPayload) {
        if (tone) {
          regenerationPayload.tone = tone;
        }

        let regeneratedContent: IBlogGenerationResponse;
        regeneratedContent = await generateBlog(regenerationPayload).unwrap();

        const currentBlogId = blogId || createdBlog?.id;

        // Must save newly generated content as a new content version
        if (!currentBlogId) {
          showErrorToast('Could not save generated Blog.');
          logger.logError('Blog - Save - Missing Blog ID');
          return;
        }

        if (regeneratedContent) {
          const updatedBlogBody = {
            title: regeneratedContent.generatedTitle,
            body: regeneratedContent.generatedBody,
            ...regenerationPayload,
          };

          latestSavedContent = await persistBlogContent(
            updatedBlogBody,
            currentBlogId,
          );
        }

        if (latestSavedContent) {
          // Redirect to the newest content version
          showCustomToast(
            <BlogGenerationSuccessToast
              currentLimit={blogGenerationsLeft - 1}
              message="Blog Regenerated"
            />,
            'toast-violet',
          );

          refreshLimits();
          redirectToContentVersion(currentBlogId, currentContentVersion + 1);
        }
      }
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Could not regenerate Blog.',
      );
    }
  };

  const persistBlogContent = async (
    regeneratedContent?: IBlogContentPayload,
    currentBlogId?: number,
  ) => {
    try {
      const blogPayload = regeneratedContent || {
        ...currentBlogContent,
        blogTopic: payload?.blogTopic || '',
        tone: payload?.tone || '',
        productKeywords: payload?.productKeywords || '',
      };

      const contentPayload = {
        blogId: currentBlogId || blogId,
        body: blogPayload,
      };

      if (
        contentPayload.body &&
        contentPayload.body.blogTopic &&
        contentPayload.body.tone
      ) {
        const savedContent = await saveGeneratedBlog(contentPayload).unwrap();
        return savedContent;
      }
    } catch (error) {
      throw error;
    }
  };

  const createNewBlog = async (payload: IBlogGenerationPayload) => {
    try {
      const createBlogPayload = {
        projectId,
        blogContentCreateBody: {
          ...currentBlogContent,
          blogTopic: payload.blogTopic,
          productKeywords: payload.productKeywords,
          tone: payload.tone,
        },
      };

      if (!validateCreateBlogPayload(createBlogPayload)) {
        showErrorToast('Could not save blog.');
        logger.logError('Blog - Save - Invalid blog payload');
        return undefined;
      }

      const createdBlog = createBlog(createBlogPayload).unwrap();
      return createdBlog;
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Could not save blog.',
      );
      return undefined;
    }
  };

  const handleSaveAction = async () => {
    if (!payload) return;
    if (blogId) {
      const savedContent = await persistBlogContent();
      if (savedContent) {
        showSuccessToast('Blog Content Saved.');
        redirectToContentVersion(savedContent.blogId, versionId + 1);
      }
    } else {
      const savedBlog = await createNewBlog(payload);
      if (savedBlog) {
        showSuccessToast('Blog Saved.');
        redirectToContentVersion(savedBlog.id, savedBlog.latestVersion || 1);
      }
    }
  };

  const regenerateContentClick = async (tone?: string) => {
    if (blogGenerationsLeft === 0) {
      limitExceededPopup.show();
    } else {
      createModal({
        title: `Regenerate Blog`,
        description: `This will regenerate your Blog content, overwritting current content. You'll need to revert to a previous version to restore any overwritten content.`,
        okButtonText: 'Regenerate',
        cancelButtonText: 'Cancel',
        okButtonDanger: false,
        okButtonCallback: async () => {
          await handleRegenerate({ tone });
        },
      });
    }
  };

  if (isBlogLoading) return <PageLoader />;

  if (isError)
    return (
      <FlexBlock>
        <EmptyDataNotice
          title="Could not load Blog"
          description="Something went wrong."
        />
      </FlexBlock>
    );

  return (
    <ContentLayout>
      <StickToTop>
        <ContentHeader>
          {tone && (
            <TopHeader
              toggleVersionsDrawer={setIsVersionDrawerOpen}
              regenerateContent={regenerateContentClick}
              toggleSettings={setIsSettingsDrawerOpen}
              saveBlog={handleSaveAction}
              isContentModified={isBlogModified}
              isSaveLoading={isBlogSaving}
              isDraft={isDraft}
              tone={tone}
            />
          )}
        </ContentHeader>

        <BlogPageTabs>
          {!isDraft && blogId && (
            <ContentControls
              toggleVersionsDrawer={setIsVersionDrawerOpen}
              version={versionId || 1}
              blogId={blogId}
            />
          )}
        </BlogPageTabs>
      </StickToTop>
      <MainGrid minContent>
        <ContentWrapper>
          <FullWidth>
            {isBlogContentLoading ? (
              <PageLoader />
            ) : (
              <BlogPageContent
                content={currentBlogContent}
                handleContentChange={setCurrentBlogContent}
                regenerateContentClick={regenerateContentClick}
              />
            )}
          </FullWidth>
        </ContentWrapper>
      </MainGrid>
      {blogData && (
        <BlogVersionDrawer
          isOpen={isVersionDrawerOpen}
          setIsOpen={setIsVersionDrawerOpen}
          blog={blogData}
        />
      )}
      {isSettingsDrawerOpen && (
        <BlogSettingsDrawer
          isOpen={isSettingsDrawerOpen}
          setIsOpen={setIsSettingsDrawerOpen}
        >
          <CreateBlogForm
            compact
            payload={payload}
            regenerate={handleRegenerate}
          />
        </BlogSettingsDrawer>
      )}
      <BlogRegenerationModal isOpen={isGenerateLoading} />
    </ContentLayout>
  );
};

export default BlogPage;
