import { FC, SVGProps } from 'react';

interface ChevronProps extends SVGProps<SVGSVGElement> {
  rotate?: number;
  duration?: number; // Duration in seconds
}

const LeftChevron: FC<SVGProps<SVGSVGElement> & ChevronProps> = ({
  rotate = 0,
  duration = 0.5,
  ...props
}) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${rotate}deg)`,
        transition: `transform ${duration}s ease-in-out`,
      }}
      {...props}
    >
      <path
        d="M5 1L1 5L5 9"
        stroke="#1D76DE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeftChevron;
