import { request } from '../request';
import { useEffect } from 'react';
import { localStorageEnum } from '../../types';

async function changeIdentity(email: string) {
  try {
    const res = await request({
      url: 'authentication/request-identity',
      params: {
        email: email,
      },
    });

    console.log('Requested Token: ', res);

    localStorage.setItem(
      localStorageEnum.__devTools__savedAdminToken,
      localStorage.getItem(localStorageEnum.token) || '',
    );

    localStorage.setItem(localStorageEnum.token, res.accessToken);
    window.location.reload();
  } catch (e) {
    console.error('Cannot change identity. Are u SU? 🧐');
  }
}

async function restoreAdminSession() {
  try {
    localStorage.setItem(
      localStorageEnum.token,
      localStorage.getItem(localStorageEnum.__devTools__savedAdminToken) || '',
    );
    window.location.reload();
  } catch (e) {
    console.error('Failed to restore session');
  }
}

export default function useDevTools(): void {
  const addMethodsToWindowObject = () => {
    // @ts-ignore
    window.__devTools = {
      changeIdentity: changeIdentity,
      restore: restoreAdminSession,
    };
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      addMethodsToWindowObject();
    }
  }, []);
}
