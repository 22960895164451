import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ApplicationLogoContainer = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 40px;
  padding-right: 24px;
`;

export const DropDownTriggerButton = {
  Wrapper: styled.button<{ flipSvg?: boolean; padding?: string }>(
    ({ theme, flipSvg, padding = '0 24px 0 24px' }) => css`
      color: #fff;
      height: 100%;
      background-color: transparent;
      border: none;
      font-size: 14px;
      font-family: ${theme.fonts.inter};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${padding};
      cursor: pointer;

      svg {
        margin-left: 8px;
        transform: rotate(0deg);
        transition: 0.25s all ease-in-out;
        ${flipSvg
          ? css`
              transform: rotate(180deg);
            `
          : ''}
      }
    `,
  ),
};

export const ClientLogo = styled.div<{
  url?: string;
  size?: number;
  $margin?: string;
}>(
  ({ theme, url, size = 56, $margin = '0 16px 0 0' }) => css`
    height: ${size}px;
    width: ${size}px;
    background-color: rgba(126, 126, 126, 0.38);
    background-size: 100% auto;
    border-radius: 50%;
    margin: ${$margin};
    background-position: center;

    ${url
      ? css`
          background-image: url(${url});
        `
      : ''}
  `,
);

export const ClientItem = styled.div`
  display: flex;
  align-items: center;
`;

export const ToolbarSection = styled.div<{
  justifyContent?: 'flex-start' | 'flex-end' | 'center';
}>(
  ({ justifyContent = 'flex-start' }) => css`
    flex: 1 auto;
    display: flex;
    align-items: center;
    justify-content: ${justifyContent};
  `,
);

export const BackButton = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.nero};
  margin: 0 16px 0 20px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    height: 16px;
    width: 16px;
    path {
      fill: #fff;
    }
  }
  &:hover {
    opacity: 0.75;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  height: 48px;
`;

export const NavigationContextMenuLink = styled.div`
  height: 48px;
  box-sizing: border-box;
  border: 2px solid transparent;
  display: flex;
  padding: 14px 16px;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0);
  transition: 0.15s all ease-in-out;
  line-height: 24px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  &:hover,
  &.active {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

export const NavigationLink = styled(NavLink)<{ tooltipText?: string }>(
  ({ theme, tooltipText }) => css`
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-family: ${theme.fonts.dmSans};
    padding: 14px 16px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0);
    transition: 0.15s all ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 3px 0;
    position: relative;

    height: 48px;

    //transform: scale(1);
    box-sizing: border-box;
    border: 2px solid transparent;

    svg {
      margin-right: 12px;
      height: 20px;
      width: 20px;

      path {
        fill: #bcbcc1;
        transition: 0.25s all ease-in-out;
      }
    }

    &:hover,
    &.active {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:hover {
      &:before,
      &:after {
        display: block;
      }
    }

    &.active {
      svg path {
        fill: #1addfc;
      }
    }

    &:active {
      border: 2px solid rgba(26, 221, 252, 0.1);
    }

    &:after {
      content: ${tooltipText ? `"${tooltipText}"` : 'none'};
      position: absolute;
      background-color: white;
      padding: 8px 12px;
      border-radius: 4px;
      left: calc(100% + 5px);
      white-space: nowrap;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
      color: black;
      font-family: ${theme.fonts.inter};
      font-size: 12px;
      font-weight: normal;
      z-index: 100;

      display: none;
    }

    &:before {
      content: ${tooltipText ? `""` : `none`};
      position: absolute;
      background-color: transparent;
      height: 10px;
      width: 10px;
      left: calc(100% - 3px);
      z-index: 101;
      border: 5px solid transparent;
      border-right: 5px solid white;

      display: none;
    }
  `,
);

export const NavigationItemVerticalDivider = styled.div`
  height: 72px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 8px;
`;

export const NeedHelpButton = styled.button(
  ({ theme }) => css`
    color: ${theme.colors.white};
    background: none;
    border: none;
    margin: 0 8px;
    padding: 0 16px;
    font-size: 14px;
    font-family: ${theme.fonts.inter};
    cursor: pointer;
    text-align: left;
    text-decoration: underline;
  `,
);
