import * as S from './Modal.styles';
import { FC, useEffect, useState } from 'react';
import { asyncDelay } from '../../../../utils/helpers';

interface IProps {
  open: boolean;
  $maxWidth?: string;
  $minWidth?: string;
  onOutsideClick?: () => void;
  overflowVisible?: boolean;
  // onBack
}

const Modal: FC<IProps> = ({
  open,
  onOutsideClick,
  $maxWidth,
  $minWidth,
  children,
  overflowVisible,
}) => {
  const [mounted, setMounted] = useState(false);

  const handleOpenChange: () => void = async () => {
    if (open) {
      setMounted(true);
    } else {
      await asyncDelay(255);
      setMounted(false);
    }
  };

  useEffect(() => {
    handleOpenChange();
  }, [open]);

  const handleBackdropClick = () => {
    if (onOutsideClick) onOutsideClick();
  };

  return mounted ? (
    <>
      <S.BackDrop open={open} onClick={handleBackdropClick} />
      <S.ModalWrapper>
        <S.Modal
          open={open}
          maxWidth={$maxWidth}
          minWidth={$minWidth}
          $overflow={overflowVisible}
        >
          <S.ModalInner $overflow={overflowVisible}>{children}</S.ModalInner>
        </S.Modal>
      </S.ModalWrapper>
    </>
  ) : null;
};

export default Modal;
