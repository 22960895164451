import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import {
  ContentHeader,
  ContentFooter,
} from 'app/components/UI/ContentLayout/ContentLayout';
import FlexBlock from 'app/components/UI/FlexBlock';
import MainGrid from 'app/components/UI/MainGrid/MainGrid';
import { FC, useCallback, useState } from 'react';
import Gap from 'app/components/UI/Gap';
import CreateSocialPostsForm from './parts/SocialPostsForm';
import {
  SocialPostsContainer,
  SocialPostsWrapper,
} from './CreateOrEditSocialPostsPage.styles';
import { useNavigateToContent } from './hooks/useNavigateToContent';
import {
  ICreateSocialPostContentPayload,
  IGenerateSocialPostContentPayload,
  ISocialPostContentBase,
} from '../SocialPostsPage/types/SocialPost.types';
import { WandAvatar } from 'app/containers/Bio/pages/BioPage/BioPage.styles';
import WandIcon from 'app/assets/icons/WandIcon';
import InfoTip from 'app/components/UI/InfoTip/InfoTip';
import { TAYLOR_SOCIAL_POSTS_TOOLTIP } from './CreateOrEditSocialPosts.constants';
import {
  useCreateSocialPostMutation,
  useCreateSuggestedSocialPostContentMutation,
  useGetSuggestedSocialPostContentMutation,
} from 'app/api';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { makeCreateSuggestedContentPayload } from './CreateOrEditSocialPostsPage.helpers';
import { Helmet } from 'react-helmet-async';
import { ToneValue } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';

const CreateOrEditSocialPostsPage: FC = () => {
  const { goToProjectContentPage, goToSocialPostPage, projectId } =
    useNavigateToContent();

  const [suggestSocialPostContent] = useGetSuggestedSocialPostContentMutation();

  const [createSocialPost] = useCreateSocialPostMutation();

  const [createSocialPostContent] =
    useCreateSuggestedSocialPostContentMutation();

  const [isLoading, setIsLoading] = useState(false);

  const onSocialPostSave = useCallback(
    async (
      suggestedContent: ISocialPostContentBase[],
      suggestionPayload: IGenerateSocialPostContentPayload & { title: string },
    ) => {
      if (suggestedContent && suggestionPayload) {
        try {
          const socialPost = await createSocialPost({
            projectId,
            title: suggestionPayload.title,
            tone: suggestionPayload.tone,
            topic: suggestionPayload.topic,
          }).unwrap();

          if (socialPost && socialPost.id && Array.isArray(suggestedContent)) {
            const suggestedContentPromises = (
              suggestedContent as ISocialPostContentBase[]
            )
              .map(content => makeCreateSuggestedContentPayload(content))
              .filter(
                (payload): payload is ICreateSocialPostContentPayload =>
                  payload !== null && payload.content !== undefined,
              )
              .map(content => {
                return createSocialPostContent({
                  socialPostId: socialPost.id,
                  ...content,
                }).unwrap();
              });

            const savedContent = await Promise.all(suggestedContentPromises);
            const isMultiplePostsGenerated = savedContent.length > 1;

            showSuccessToast(
              `Social ${
                isMultiplePostsGenerated ? 'posts have' : 'post has'
              } been successfully generated!`,
            );

            setIsLoading(false);

            goToSocialPostPage(
              socialPost.id,
              suggestionPayload.tone as ToneValue,
            );
          }
        } catch (error) {
          setIsLoading(false);
          showErrorToast(
            'Something went wrong.',
            getErrorText(error as FetchBaseQueryError),
          );
        }
      }
    },
    [createSocialPost, createSocialPostContent, goToSocialPostPage, projectId],
  );

  const onGenerateStart = useCallback(
    async (
      suggestionPayload: IGenerateSocialPostContentPayload & { title: string },
    ) => {
      if (suggestionPayload) {
        try {
          const { title, ...restPayload } = suggestionPayload;
          setIsLoading(true);
          const suggestedContent = await suggestSocialPostContent(
            restPayload,
          ).unwrap();

          await onSocialPostSave(suggestedContent, suggestionPayload);
        } catch (error) {
          showErrorToast(
            'Could not generate social posts.',
            getErrorText(error as FetchBaseQueryError),
          );
        }
      }
    },
    [suggestSocialPostContent, onSocialPostSave],
  );

  return (
    <SocialPostsWrapper>
      <Helmet title={'Create Social Posts'} />
      <ContentHeader autoHeight padding="16px 0" contentLayoutMaxWidth>
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          minWidth="100%"
        >
          <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
            <WandAvatar $size="56px">
              <WandIcon />
            </WandAvatar>
            <FlexBlock flexDirection="column">
              <FlexBlock flexDirection="row" alignItems="center">
                <Typography.Text
                  $colorName="orbit"
                  $size={16}
                  $lineHeight={20}
                  $dmSans
                  $bold
                  $padding="2px 0"
                >
                  Taylor
                </Typography.Text>
                <InfoTip
                  tooltipPosition="right"
                  tooltipText={TAYLOR_SOCIAL_POSTS_TOOLTIP}
                  padding="0 0 0 4px"
                />
              </FlexBlock>
              <Typography.Text $colorName="steel" $size={14} $lineHeight={16}>
                Generate Social Posts
              </Typography.Text>
            </FlexBlock>
          </FlexBlock>

          <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
            <Button variant="transparent" onClick={goToProjectContentPage}>
              Cancel
            </Button>
          </FlexBlock>
        </FlexBlock>
      </ContentHeader>
      <Gap size={24} />
      <MainGrid minContent marginBottom={80}>
        <SocialPostsContainer>
          <CreateSocialPostsForm
            onGenerateStart={onGenerateStart}
            isGenerating={isLoading}
          />
        </SocialPostsContainer>
      </MainGrid>
      <ContentFooter />
    </SocialPostsWrapper>
  );
};

export default CreateOrEditSocialPostsPage;
