import { ILatestArticle } from 'app/api.types';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC, memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MediaOutletAnchor } from './MediaOutlet.style';
import { MediaOutletLink } from './MediaOutletLink';

interface IMediaOutletsProps {
  article: ILatestArticle;
}

const MAX_VISIBLE_OUTLET_ROWS_HEIGHT_IN_PIXELS = 48;
const OUTLETS_PER_PAGE = 50;

const MediaOutlets: FC<IMediaOutletsProps> = ({ article }) => {
  const [showAllOutlets, setShowAllOutlets] = useState(false);
  const [visibleOutletsOffset, setVisibleOutletsOffset] = useState(0);
  const [visibleOutletsPage, setVisibleOutletsPage] = useState(0);

  const linksRef = useRef<HTMLDivElement | null>(null);

  const {
    recentOutlets,
    additionalOutlets,
    hasNextPage,
    nextPageOutletCount,
    remainingOutletsCount,
  } = useMemo(() => {
    const visibleOutletsSliceIndex = 10 - visibleOutletsOffset;
    const recentOutlets = article.outlets.slice(0, visibleOutletsSliceIndex);
    let additionalOutlets = article.outlets.slice(visibleOutletsSliceIndex);

    const remainingOutletsCount = additionalOutlets.length;

    const pagedIndex =
      visibleOutletsPage > 0 ? visibleOutletsPage * OUTLETS_PER_PAGE : 0;

    let pagedOutlets = additionalOutlets.slice(
      pagedIndex,
      pagedIndex + OUTLETS_PER_PAGE,
    );

    const nextOutletsCount =
      additionalOutlets.length - pagedIndex - OUTLETS_PER_PAGE;

    const hasNextPage = nextOutletsCount > 0;

    const nextPageOutletCount = hasNextPage
      ? Math.min(nextOutletsCount, 50)
      : 0;

    return {
      recentOutlets: pagedIndex > 0 ? [] : recentOutlets,
      additionalOutlets: pagedOutlets || additionalOutlets,
      hasNextPage,
      nextPageOutletCount,
      remainingOutletsCount,
    };
  }, [article, visibleOutletsOffset, visibleOutletsPage]);

  useLayoutEffect(() => {
    if (linksRef) {
      const currentHeight = linksRef.current?.clientHeight;
      if (
        !showAllOutlets &&
        currentHeight &&
        currentHeight > MAX_VISIBLE_OUTLET_ROWS_HEIGHT_IN_PIXELS &&
        recentOutlets.length > 2
      ) {
        setVisibleOutletsOffset(prev => prev + 1);
      }
    }
  }, [recentOutlets, showAllOutlets]);

  return (
    <FlexBlock
      flexDirection="row"
      alignItems="center"
      columnGap="5px"
      flexWrap="wrap"
      ref={linksRef}
    >
      <MediaOutletLink outlets={recentOutlets} />
      {!showAllOutlets && additionalOutlets.length > 0 ? (
        <>
          <span>/...</span>
          <MediaOutletAnchor onClick={() => setShowAllOutlets(true)}>
            + {remainingOutletsCount} more
          </MediaOutletAnchor>
        </>
      ) : (
        <>
          {showAllOutlets && (
            <>
              <span>/</span>
              <MediaOutletLink outlets={additionalOutlets} />
              {hasNextPage && (
                <MediaOutletAnchor
                  onClick={() =>
                    setVisibleOutletsPage(currentPage => currentPage + 1)
                  }
                >
                  ...{nextPageOutletCount} more
                </MediaOutletAnchor>
              )}
              <span>-</span>
              <MediaOutletAnchor
                onClick={
                  visibleOutletsPage > 0
                    ? () =>
                        setVisibleOutletsPage(currentPage => currentPage - 1)
                    : () => setShowAllOutlets(false)
                }
              >
                View less
              </MediaOutletAnchor>
            </>
          )}
        </>
      )}
    </FlexBlock>
  );
};

function mediaOutletsPropsAreEqual(
  prevProps: IMediaOutletsProps,
  nextProps: IMediaOutletsProps,
) {
  return prevProps.article.id === nextProps.article.id;
}

export const MemoizedMediaOutlets = memo(
  MediaOutlets,
  mediaOutletsPropsAreEqual,
);
