import styled, { css } from 'styled-components/macro';
import { AccordionVariant } from './Accordion';

export const Wrapper = styled.div<{ variant: AccordionVariant }>(
  ({ variant, theme }) => css`
    display: flex;
    flex-direction: column;

    ${variant === 'orbit-blue'
      ? css`
          background-color: rgba(29, 118, 222, 0.1);
          border-radius: 8px;

          ${Header} {
            padding: 12px 16px;
            flex-flow: row-reverse;
            justify-content: flex-end;
            column-gap: 12px;

            svg path {
              fill: ${theme.colors.orbit};
            }
          }

          ${Content} {
            padding: 12px 8px 24px 16px;
          }
        `
      : ''}
  `,
);

export const Header = styled.button`
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  padding: 0 8px;
`;

export const Content = styled.div<{ expanded: boolean }>(
  ({ expanded }) => css`
    display: ${expanded ? 'block' : 'none'};
    padding: 28px 8px 0;
  `,
);
