import {
  IContentGenerationLimits,
  LimitTypesEnum,
} from '../../../../../api.types';
import { api, useGetClientLimitsByIdQuery } from '../../../../../api';
import { useAppDispatch } from '../../../../../../store/hooks';
import useHasPermission from '../../../../Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from '../../../../Global/permissions/userPermissions.enum';
import useGetCurrentUserPlan from 'app/containers/Global/permissions/useGetCurrentUserPlan.hook';

interface IUserGenerationLimits extends IContentGenerationLimits {
  refresh: () => void;
  loading: boolean;
  outOfPitchRuns: boolean;
  outOfContentGenerations: boolean;
  isFreeUserPlan: boolean;
}

// TODO: Change to zero
const FALLBACK_LIMIT = 0;
const NO_LIMIT = 1000;

export const useGenerationLimits = () => {
  const hasLimitations = useHasPermission(
    userPermissionEnum.GENERATE_CONTENT_LIMIT,
  );
  const dispatch = useAppDispatch();

  const { isFreeOrFreemiumPlan } = useGetCurrentUserPlan();

  const { data: contentGenerationLimits, isFetching: loading } =
    useGetClientLimitsByIdQuery(
      {
        limitTypes: [
          LimitTypesEnum.PITCH_GENERATIONS,
          LimitTypesEnum.PITCH_PREDICTIONS,
          LimitTypesEnum.AUTHOR_EMAIL,
        ],
      },
      { skip: !hasLimitations },
    );

  const refresh = () => {
    if (!hasLimitations) return;
    dispatch(
      api.util.invalidateTags(['content-generation-limit', 'user-limits']),
    );
  };

  const getLimit = (key: LimitTypesEnum) => {
    if (!hasLimitations) return NO_LIMIT;
    if (
      !contentGenerationLimits ||
      !contentGenerationLimits.userLimitDetails ||
      contentGenerationLimits?.userLimitDetails[key] === undefined
    )
      return FALLBACK_LIMIT;
    return contentGenerationLimits.userLimitDetails[key]?.left;
  };

  const getPitchLimit = () => {
    if (
      !contentGenerationLimits ||
      !contentGenerationLimits.userLimitDetails ||
      contentGenerationLimits.userLimitDetails[
        LimitTypesEnum.PITCH_PREDICTIONS
      ] === undefined
    )
      return NO_LIMIT;

    return contentGenerationLimits.userLimitDetails[
      LimitTypesEnum.PITCH_PREDICTIONS
    ]?.left;
  };

  const isOutOfContentGenerations = () => {
    const generationsLeft = getLimit(LimitTypesEnum.AUTHOR_EMAIL);
    if (!generationsLeft || generationsLeft === undefined) return false;

    return generationsLeft < 1;
  };

  const isOutOfPitchRuns = () => {
    const generationsLeft = getPitchLimit();
    if (generationsLeft === null || generationsLeft === undefined) return false;

    return generationsLeft < 1;
  };

  return {
    loading,
    refresh,
    resetTimestamp: new Date(
      contentGenerationLimits?.resetTimestamp || '2023-03-07T16:32:49.412Z',
    ).toLocaleDateString(),
    authorEmailSuggestionsLeft: getLimit(LimitTypesEnum.AUTHOR_EMAIL),
    contentSuggestionsLeft: getLimit(LimitTypesEnum.PITCH_GENERATIONS),
    pitchPredictionLeft: getPitchLimit(),
    outOfPitchRuns: isOutOfPitchRuns(),
    outOfContentGenerations: isOutOfContentGenerations(),
    isFreeUserPlan: isFreeOrFreemiumPlan,
  } as IUserGenerationLimits;
};
