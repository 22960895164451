import { FC } from 'react';
import styled, { css } from 'styled-components';

// To Do: Remove
export const URLInput = styled.input`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  padding: 16.5px 16px;

  &:focus {
    border: 1px solid black;
    outline: none;
  }
`;

// To Do: Remove
export const CollapsableWrapper = styled.div<{ $isHidden: boolean }>`
  display: ${({ $isHidden }) => ($isHidden ? 'flex' : 'none')};
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.smoke};
  margin-bottom: 2em;
`;

export const SocialFieldInput = styled.input(
  ({ theme }) => css`
    font-family: ${theme.fonts.inter};
    font-size: 14px;
    line-height: 20px;
    border: none;
    border-bottom: 1px solid ${theme.colors.onyx};
    padding: 0;
    margin-right: 20px;
    width: 350px;
    &:active,
    &:focus {
      outline: none;
      border-bottom: 1px solid ${theme.colors.orbit};
    }
  `,
);

export const SocialIconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-self: start;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    border: 1px solid ${theme.colors.smoke};
    border-radius: 50%;
    padding: 7px;
    width: 40px;
    height: 40px;
  `,
);

export const SocialFieldsGroupWrapper = styled.div<{ $isLastGroup: boolean }>(
  ({ theme, $isLastGroup }) => css`
    width: 100%;
    padding: 8px 0;
    border-bottom: ${$isLastGroup ? 'none' : `1px solid ${theme.colors.smoke}`};
  `,
);

export const RemoveSocialFieldIcon: FC = () => {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 20"
      width="14"
      height="14"
    >
      <path
        stroke="grey"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
      />
    </svg>
  );
};
