import { FC } from 'react';
import * as S from '../CreateFirstPitch.styles';
import Typography from 'app/components/Typography';
import Avatar from 'app/components/UI/Avatar';
import FlexBlock from 'app/components/UI/FlexBlock';

interface IProps {
  brandTitle: string;
  brandAvatar?: string;
}

const GeneratedContentHeader: FC<IProps> = ({ brandTitle, brandAvatar }) => {
  return (
    <S.Header padding="0 50px">
      <FlexBlock flexDirection="row" alignItems="center">
        <Avatar
          size="40"
          url={brandAvatar}
          name={brandTitle}
          shadow
          margin="0"
        />

        <Typography.Text
          $colorName="onyx"
          $size={20}
          $lineHeight={24}
          $padding="16px"
          $dmSans
          $bold
        >
          {brandTitle}
        </Typography.Text>
      </FlexBlock>
    </S.Header>
  );
};

export default GeneratedContentHeader;
