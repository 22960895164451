import {
  IClassifiedProperty,
  ICreateUserPayload,
  ITenantBase,
  IUser,
} from 'app/api.types';
import { UserRoleEnum } from 'app/containers/Global/slice/types';

export const isTenantUser = (role: UserRoleEnum) => {
  return role === UserRoleEnum.TENANT_USER || role === UserRoleEnum.CLIENT_USER;
};

export const isTenantAdmin = (role: UserRoleEnum) => {
  return (
    role === UserRoleEnum.TENANT_ADMIN || role === UserRoleEnum.CLIENT_ADMIN
  );
};

export const getUserPayload = (user: IUser): ICreateUserPayload => {
  const userStatus = user.statusType.code || 'ACTIVE';
  const useRole = user.role.code || UserRoleEnum.CLIENT_USER;

  const firstName = user.firstName || '';
  const lastName = user.lastName || '';
  const email = user.email || '';
  const mobilePhone = user.mobilePhone || '';

  return {
    ...user,
    firstName,
    lastName,
    email,
    mobilePhone,
    status: userStatus,
    role: useRole,
  };
};

export const isSelectedTenantValid = ({
  userRole,
  userTenant,
  currentUser,
}: {
  userRole: IClassifiedProperty<UserRoleEnum> | null;
  userTenant: ITenantBase | null;
  currentUser: IUser | undefined;
}) => {
  if (!currentUser || !userRole || !userRole.code) return false;

  const creatorRole = currentUser.role.code;

  if (creatorRole === UserRoleEnum.SUPER_ADMIN) {
    if (userRole?.code === UserRoleEnum.SUPER_ADMIN) {
      // This returns true because selected tenant will be emitted in the payload
      return true;
    }
    return userTenant !== null;
  }

  if (isTenantAdmin(creatorRole)) {
    if (!currentUser.tenant || !currentUser.tenant.id) return false;
    if (!userTenant || !userTenant.id) return false;
    return userTenant.id === currentUser.tenant.id;
  }
};

export const getSelectedTenantId = ({
  userRole,
  userTenant,
  currentUser,
}: {
  userRole: IClassifiedProperty<UserRoleEnum> | null;
  userTenant: ITenantBase | null;
  currentUser: IUser | undefined;
}) => {
  if (!currentUser || !currentUser.role) return undefined;
  const creatorRole = currentUser.role.code;

  if (creatorRole === UserRoleEnum.SUPER_ADMIN) {
    if (userRole?.code === UserRoleEnum.SUPER_ADMIN) {
      return undefined;
    }
    return userTenant && userTenant.id ? userTenant.id : undefined;
  }

  if (isTenantAdmin(creatorRole)) {
    if (!currentUser.tenant || !currentUser.tenant.id) return undefined;
    // If creator is tenant admin, do not pass tenant ID in the payload
    return undefined;
  }
};

export const userFieldsModified = (
  user: ICreateUserPayload,
  userData: IUser,
) => {
  return (
    user.firstName !== userData?.firstName ||
    user.lastName !== userData?.lastName ||
    user.email !== userData?.email ||
    user.mobilePhone !== userData?.mobilePhone
  );
};

export const userFieldsPopulated = (user: ICreateUserPayload) => {
  return (
    user.firstName !== '' ||
    user.lastName !== '' ||
    user.email !== '' ||
    user.mobilePhone !== ''
  );
};
