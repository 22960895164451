import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
`;

export const Input = styled.div(
  ({ theme }) => css`
    min-height: 48px;
    border: 1px solid ${theme.colors.smoke};
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 4px 16px;
    column-gap: 8px;
  `,
);

export const Tags = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 6px;
`;

export const Menu = styled.div<{ $maxMenuHeight?: number; $zIndex?: number }>(
  ({ theme, $maxMenuHeight, $zIndex }) => css`
    position: absolute;
    padding: 8px 0;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid ${theme.colors.smoke};
    width: 100%;
    top: calc(100% + 8px);
    z-index: ${$zIndex ? $zIndex : 10};

    ${$maxMenuHeight
      ? css`
          max-height: ${$maxMenuHeight}px;
          overflow-y: scroll;
        `
      : ''}
  `,
);

export const MenuOption = styled.button(
  ({ theme }) => css`
    display: block;
    width: 100%;
    padding: 8px 16px;
    text-align: left;
    background: transparent;
    border: none;
    font-family: ${theme.fonts.inter};
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  `,
);

export const MenuSearch = styled.input`
  width: 20px;
  padding: 0px 5px;
  border: none;
  outline: none;
`;
