import { IAuthorSocial } from 'app/api.types';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';
import { journalistSocialFieldsFilter } from '../../../JournalistList.helpers';
import { SOCIAL_FIELDS_CONFIG } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/parts/Socials/Socials.config';
import React from 'react';
import Button from 'app/components/UI/Button/Button';
import { handleSocialIconClick } from 'utils/helpers';
import Typography from 'app/components/Typography';
import FavoriteJournalistButton from '../../Favorites/FavoriteJournalistButton';

interface IJournalistNameCellProps {
  name: string;
  journalistId?: string | number;
  isFavorite?: boolean;
  isContributor?: boolean;
}

interface IJournalistEmailCellProps {
  email: string;
}

interface IJournalistOutletCellProps {
  outletName: string;
}

interface IJournalistSocialLinksCellProps {
  socials?: IAuthorSocial[];
}

export const JournalistOutletCell: FC<IJournalistOutletCellProps> = ({
  outletName,
}) => {
  return (
    <FlexBlock alignItems="center">
      <Typography.Text
        $dmSans
        $bold
        $size={14}
        hideOverflow
        overflowWidth="380px"
        title={outletName || undefined}
      >
        {outletName ? outletName : ''}
      </Typography.Text>
    </FlexBlock>
  );
};

export const JournalistNameCell: FC<IJournalistNameCellProps> = ({
  name,
  journalistId,
  isFavorite,
  isContributor,
}) => {
  return (
    <FlexBlock alignItems="center" flexDirection="row">
      <Typography.Text
        $size={14}
        hideOverflow
        overflowWidth="250px"
        title={name}
      >
        {isContributor ? `${name} (Contributor)` : name}
      </Typography.Text>
      <div style={{ marginLeft: 'auto', paddingRight: '5px' }}>
        <FavoriteJournalistButton
          journalistId={journalistId}
          isFavorite={isFavorite || false}
        />
      </div>
    </FlexBlock>
  );
};

export const JournalistEmailCell: FC<IJournalistEmailCellProps> = ({
  email,
}) => {
  return (
    <FlexBlock alignItems="center">
      <Typography.Text
        $size={14}
        hideOverflow
        overflowWidth="280px"
        title={email}
      >
        {email}
      </Typography.Text>
    </FlexBlock>
  );
};

export const JournalistSocialLinksCell: FC<IJournalistSocialLinksCellProps> = ({
  socials,
}) => {
  return (
    <FlexBlock
      alignItems="center"
      flexDirection="row"
      flexWrap="wrap"
      rowGap="3px"
    >
      {socials?.filter(journalistSocialFieldsFilter).map(social => {
        const socialConfig = SOCIAL_FIELDS_CONFIG.find(
          configField => configField.value === social.socialType,
        );

        const key =
          social.enrichmentId || social.creatorSocialId || social.handler;

        return (
          <React.Fragment key={key}>
            {socialConfig && (
              <FlexBlock alignItems="center" flexDirection="row">
                <Button
                  variant="icon-button-no-padding"
                  onClick={handleSocialIconClick(social.url)}
                >
                  {socialConfig.icon}
                </Button>
              </FlexBlock>
            )}
          </React.Fragment>
        );
      })}
    </FlexBlock>
  );
};
