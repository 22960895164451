import React from 'react';

function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#0C0C0D" transform="translate(-1255 -540)">
          <g transform="translate(260 72)">
            <g transform="translate(646 224)">
              <g transform="translate(0 216)">
                <g transform="translate(16 16)">
                  <g transform="translate(321)">
                    <g transform="translate(12 12)">
                      <path d="M8.4 5.2a2.403 2.403 0 012.4 2.4v5.6a2.402 2.402 0 01-2.4 2.4H2.8a2.403 2.403 0 01-2.4-2.4V7.6a2.403 2.403 0 012.4-2.4zm0 1.6H2.8a.8.8 0 00-.8.8v5.6a.8.8 0 00.8.8h5.6a.8.8 0 00.8-.8V7.6a.8.8 0 00-.8-.8zM13.2.4a2.403 2.403 0 012.4 2.4v5.6a2.403 2.403 0 01-2.4 2.4h-.8a.8.8 0 110-1.6h.8a.8.8 0 00.8-.8V2.8a.8.8 0 00-.8-.8H7.6a.8.8 0 00-.8.8v.8a.8.8 0 01-1.6 0v-.8A2.402 2.402 0 017.6.4z"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CopyIcon;
