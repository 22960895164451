import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { Authorization } from './containers/Authorization';
import Projects from './containers/Projects';
import People from './containers/People';
import Pitch from './containers/Pitch';
import { IUserListViewRouteParam } from './routes.types';
import UserProfilePage from './containers/Global/pages/UserProfile/UserProfilePage';
import LogoutPage from './containers/Authorization/pages/Logout/Logout';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { AuthorizedRoute } from './containers/Global/permissions/AuthorizedRoute';
import { userPermissionEnum } from './containers/Global/permissions/userPermissions.enum';
import { useRouteChangeTracking } from '../utils/hooks/useRouteChangeTracking';
import { useClientChangeTracking } from 'utils/hooks/useClientChangeTracking';
import Bio from './containers/Bio';
import Blog from './containers/Blog';
import MonitorAlertsPage from './components/MonitorAlerts/MonitorAlertsPage';
import Journalists from './containers/Journalists';
import PressRelease from './containers/PressRelease';
import Byline from './containers/Byline';
import SocialPosts from './containers/SocialPosts';

export const RouteConstants = {
  root: process.env.PUBLIC_URL + '/',
  aboutPage: {
    index: '/about-page',
  },
  userProfile: {
    index: '/profile/main',
    billing: '/profile/plan',
    indexWithParams: '/profile/:page',
  },
  authorization: {
    index: '/login',
    login: '/login',
    loginMfa: '/login/mfa',
    logout: '/logout',
    forgotPassword: '/login/forgot-password',
  },
  projects: {
    index: '/projects',
    list: '/projects/list',
    listByTenant: '/projects/list/t/:tenantId',
    makeContentListByTenantUrl: (tenantId: number) =>
      `/projects/list/t/${tenantId}`,
    contentList: '/projects/tenant/:tenantId/project/:projectId/content',
    makeContentUrl: (tenantId: number, projectId: number) =>
      `/projects/tenant/${tenantId}/project/${projectId}/content`,
  },
  journalists: {
    index: '/journalists',
    list: '/journalists/list',
    listWithParams: '/journalists/list/:view',
    mediaList: '/journalists/list/media-list',
    favorites: '/journalists/list/favorites',
  },
  blog: {
    index: '/blog',
    blog: '/blog/tenant/:tenantId/project/:projectId/content/:blogId/v/:versionId',
    editBlog:
      '/blog/tenant/:tenantId/project/:projectId/edit/:blogId/v/:versionId',
    makeBlogUrl: (
      tenantId: number,
      projectId: number,
      blogId: number,
      versionId: number,
    ) =>
      `/blog/tenant/${tenantId}/project/${projectId}/content/${blogId}/v/${versionId}`,
    makeEditBlogUrl: (
      tenantId: number,
      projectId: number,
      blogId: number,
      versionId: number,
    ) =>
      `/blog/tenant/${tenantId}/project/${projectId}/edit/${blogId}/v/${versionId}`,
    createBlog: '/blog/tenant/:tenantId/project/:projectId/new-blog',
    makeCreateBlogUrl: (tenantId: number, projectId: number) =>
      `/blog/tenant/${tenantId}/project/${projectId}/new-blog`,
    draftBlog: '/blog/tenant/:tenantId/project/:projectId/draft',
    makeCreateDraftBlogUrl: (tenantId: number, projectId: number) =>
      `/blog/tenant/${tenantId}/project/${projectId}/draft`,
  },
  byline: {
    index: '/byline',
    byline:
      '/byline/tenant/:tenantId/project/:projectId/content/:bylineId/v/:versionId',
    editByline:
      '/byline/tenant/:tenantId/project/:projectId/edit/:bylineId/v/:versionId',
    makeBylineUrl: (
      tenantId: number,
      projectId: number,
      bylineId: number,
      versionId: number,
    ) =>
      `/byline/tenant/${tenantId}/project/${projectId}/content/${bylineId}/v/${versionId}`,
    makeEditBylineUrl: (
      tenantId: number,
      projectId: number,
      bylineId: number,
      versionId: number,
    ) =>
      `/byline/tenant/${tenantId}/project/${projectId}/edit/${bylineId}/v/${versionId}`,
    createByline: '/byline/tenant/:tenantId/project/:projectId/new-byline',
    makeCreateBylineUrl: (tenantId: number, projectId: number) =>
      `/byline/tenant/${tenantId}/project/${projectId}/new-byline`,
    draftByline: '/byline/tenant/:tenantId/project/:projectId/draft',
    makeCreateDraftBylineUrl: (tenantId: number, projectId: number) =>
      `/byline/tenant/${tenantId}/project/${projectId}/draft`,
  },
  bio: {
    index: '/bio',
    bio: '/bio/tenant/:tenantId/project/:projectId/content/:bioId/v/:versionId',
    editBio:
      '/bio/tenant/:tenantId/project/:projectId/edit/:bioId/v/:versionId',
    makeBioUrl: (
      tenantId: number,
      projectId: number,

      bioId: number,
      versionId: number,
    ) =>
      `/bio/tenant/${tenantId}/project/${projectId}/content/${bioId}/v/${versionId}`,
    makeEditBioUrl: (
      tenantId: number,
      projectId: number,
      bioId: number,
      versionId: number,
    ) =>
      `/bio/tenant/${tenantId}/project/${projectId}/edit/${bioId}/v/${versionId}`,
    createBio: '/bio/tenant/:tenantId/project/:projectId/new-bio',
    makeCreateBioUrl: (tenantId: number, projectId: number) =>
      `/bio/tenant/${tenantId}/project/${projectId}/new-bio`,
    draftBio: '/bio/tenant/:tenantId/project/:projectId/draft',
    makeCreateDraftBioUrl: (tenantId: number, projectId: number) =>
      `/bio/tenant/${tenantId}/project/${projectId}/draft`,
  },
  socialPosts: {
    index: '/social-posts',
    socialPosts:
      '/social-posts/tenant/:tenantId/project/:projectId/content/:socialPostsId',
    makeSocialPostsUrl: (
      tenantId: number,
      projectId: number,
      socialPostsId: number,
    ) =>
      `/social-posts/tenant/${tenantId}/project/${projectId}/content/${socialPostsId}`,
    createSocialPosts:
      '/social-posts/tenant/:tenantId/project/:projectId/new-social-posts',
    makeCreateSocialPostsUrl: (tenantId: number, projectId: number) =>
      `/social-posts/tenant/${tenantId}/project/${projectId}/new-social-posts`,
  },
  pitch: {
    index: '/pitch',
    pitch:
      '/pitch/tenant/:tenantId/project/:projectId/report/:pitchId/v/:versionId',
    editPitch:
      '/pitch/tenant/:tenantId/project/:projectId/edit/:pitchId/v/:versionId',
    makePitchUrl: (
      tenantId: number,
      projectId: number,
      pitchId: number,
      versionId: number,
    ) =>
      `/pitch/tenant/${tenantId}/project/${projectId}/report/${pitchId}/v/${versionId}`,
    makeEditPitchUrl: (
      tenantId: number,
      projectId: number,
      pitchId: number,
      versionId: number,
    ) =>
      `/pitch/tenant/${tenantId}/project/${projectId}/edit/${pitchId}/v/${versionId}`,
    createPitch: '/pitch/tenant/:tenantId/project/:projectId/new-pitch',
    createFirstPitch: '/pitch/tenant/first-pitch',
    makeCreatePitchUrl: (tenantId: number, projectId: number) =>
      `/pitch/tenant/${tenantId}/project/${projectId}/new-pitch`,
  },
  pressRelease: {
    index: '/press-release',
    pressRelease:
      '/press-release/tenant/:tenantId/project/:projectId/report/:pressReleaseId/v/:versionId',
    editPressRelease:
      '/press-release/tenant/:tenantId/project/:projectId/edit/:pressReleaseId/v/:versionId',
    createPressRelease:
      '/press-release/tenant/:tenantId/project/:projectId/new-pr',
    makePressReleaseUrl: (
      tenantId: number,
      projectId: number,
      pressReleaseId: number,
      versionId: number,
    ) =>
      `/press-release/tenant/${tenantId}/project/${projectId}/report/${pressReleaseId}/v/${versionId}`,
    makeEditPressReleaseUrl: (
      tenantId: number,
      projectId: number,
      pressReleaseId: number,
      versionId: number,
    ) =>
      `/press-release/tenant/${tenantId}/project/${projectId}/edit/${pressReleaseId}/v/${versionId}`,
    makeCreatePressReleaseUrl: (tenantId: number, projectId: number) =>
      `/press-release/tenant/${tenantId}/project/${projectId}/new-pr`,
  },
  people: {
    index: '/users',
    list: '/users/:tenantId?/list/:view',
    makeListUrl: (params: IUserListViewRouteParam) =>
      params.tenantId
        ? `/users/${params.tenantId}/list/${params.view}`
        : `/users/list/${params.view}`,
  },
  misc: {
    error: '/error/:type',
    monitor: '/monitor',
  },
};

export const RootRoutes: FC = () => {
  useRouteChangeTracking();
  useClientChangeTracking();

  return (
    <Switch>
      <Route
        path={RouteConstants.authorization.index}
        component={Authorization}
      />

      <Route
        path={RouteConstants.authorization.logout}
        component={LogoutPage}
      />
      <AuthorizedRoute
        path={RouteConstants.userProfile.indexWithParams}
        component={UserProfilePage}
      />
      <AuthorizedRoute
        path={RouteConstants.projects.index}
        component={Projects}
        requirePermission={userPermissionEnum.READ_PROJECTS}
      />
      <AuthorizedRoute path={RouteConstants.people.index} component={People} />
      <AuthorizedRoute path={RouteConstants.pitch.index} component={Pitch} />
      <AuthorizedRoute
        path={RouteConstants.pressRelease.index}
        component={PressRelease}
      />
      <AuthorizedRoute path={RouteConstants.bio.index} component={Bio} />
      <AuthorizedRoute path={RouteConstants.blog.index} component={Blog} />
      <AuthorizedRoute path={RouteConstants.byline.index} component={Byline} />
      <AuthorizedRoute
        path={RouteConstants.socialPosts.index}
        component={SocialPosts}
      />
      <AuthorizedRoute
        path={RouteConstants.journalists.index}
        component={Journalists}
      />
      <AuthorizedRoute path={RouteConstants.misc.error} component={ErrorPage} />
      <AuthorizedRoute
        path={RouteConstants.misc.monitor}
        component={MonitorAlertsPage}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export const publicRoutes = [
  RouteConstants.authorization.login,
  RouteConstants.authorization.forgotPassword,
];
