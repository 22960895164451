import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const LoadingText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${p => p.theme.colors.sky};
  padding: 16px;
  border-radius: 8px;
`;

export const IFrame = styled.iframe<{ $loaded?: boolean }>(
  ({ theme, $loaded }) => css`
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    ${!$loaded && 'display: none;'};
  `,
);
