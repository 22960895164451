import styled from 'styled-components';

export const ContentLayout = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.haze};
  overflow-y: auto;
  padding-bottom: 70px;
`;

export const Toggle = styled.label`
  cursor: pointer;
  display: flex;
`;

export const ToggleSwitch = styled.div`
  display: inline-block;
  background: transparent;
  border-radius: 100px;
  width: 32px;
  height: 16px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  position: relative;
  outline: 1px solid #3f3f42;

  &:before,
  &:after {
    content: '';
  }

  &:before {
    display: block;
    background: #3f3f42;
    border-radius: 50%;
    width: 10.67px;
    height: 10.67px;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: left 0.25s;
  }
`;

export const ToggleCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  visibility: hidden;

  &:checked + ${ToggleSwitch} {
    background: #00d377;
    outline: none;
    &:before {
      left: 18px;
      background: #f5f5f9;
    }
  }

  &:disabled + ${ToggleSwitch} {
    background: grey;
    cursor: initial;
  }
`;
