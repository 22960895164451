import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import Input from 'app/components/UI/Input/Input';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { BASE_API_URL } from 'utils/constants';
import { validateEmail } from 'utils/helpers';
import { showErrorToast } from 'utils/toast';

interface ISSOFormProps {}

export const SignOnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

enum LoginEnum {
  email = 'email',
}

interface SSOFormElements extends HTMLFormControlsCollection {
  [LoginEnum.email]: HTMLInputElement;
}

interface SSOFormElement extends HTMLFormElement {
  readonly elements: SSOFormElements;
}

const SSOForm: FC<ISSOFormProps> = () => {
  const SSOFormId = 'SSOForm';

  const [isRedirectingToAuth, setIsRedirectingToAuth] = useState(false);

  const [email, setEmail] = useState('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onLoginSSO = (event: React.FormEvent<SSOFormElement>) => {
    event.preventDefault();
    const email = event.currentTarget.elements[LoginEnum.email].value.trim();
    const isEmailValid = validateEmail(email);
    if (!email || !isEmailValid) {
      showErrorToast('Please enter a valid email address');
      return;
    }

    const SSO_URL = `${BASE_API_URL}authentication/sign-in?email=${email}`;
    setIsRedirectingToAuth(true);
    window.location.href = SSO_URL;
  };

  return (
    <div style={{ width: '400px' }}>
      <Typography.Title margin="16px 0 24px 0">Login</Typography.Title>

      <form onSubmit={onLoginSSO} id={SSOFormId}>
        <Typography.Text
          $colorName="steel"
          $size={14}
          $lineHeight={20}
          $margin="0 0 8px 0"
        >
          Enter your email address
        </Typography.Text>
        <Input
          width={'400px'}
          name={LoginEnum.email}
          placeholder="Email address"
          margin="0 0 24px 0"
          fontSize={16}
          value={email}
          variant="loginTextField"
          onChange={handleEmailChange}
        />
      </form>

      <SignOnContainer>
        <Button
          type="submit"
          form={SSOFormId}
          padding="11px 28.5px"
          disabled={!email || isRedirectingToAuth}
          isLoading={isRedirectingToAuth}
        >
          <Typography.Text
            $colorName="white"
            $bold
            $dmSans
            $size={16}
            $lineHeight={24}
          >
            Submit
          </Typography.Text>
        </Button>
      </SignOnContainer>
    </div>
  );
};

export default SSOForm;
