import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  padding: 16px 40px;
  width: 100%;
`;

export const Root = styled.div<{ $hover?: boolean }>(
  ({ theme, $hover = false }) => css`
    border: 1px solid ${$hover ? theme.colors.orbit : theme.colors.aluminum};
    background-color: ${$hover ? '#EBF2FB' : theme.colors.haze};
    width: 100%;
    min-height: 190px;
    border-radius: 8px;
    padding: 15px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
);

export const Input = styled.input`
  display: none;
`;

export const Label = styled.div`
  padding-bottom: 16px;
  padding-left: 8px;
`;
