import {
  useGetArticlesSuggestionsQuery,
  useGetAuthorLatestArticlesQuery,
  useGetSimilarArticlesQuery,
  useGetSimilarArticlesSuggestionsQuery,
} from 'app/api';
import { useMemo } from 'react';

interface IArticlesSearchHookArgs {
  searchTerm: string;
  inclusions: string[];
  exclusions: string[];
  authorId: string;
  targetMediaId: string | number;
  reportItemId: string | number;
  open: boolean;
  isPodcast?: boolean;
  isRelevant: boolean;
}

export const useArticlesSearch = (props: IArticlesSearchHookArgs) => {
  const { data: articles, isFetching: isArticlesFetching } =
    useGetAuthorLatestArticlesQuery(
      {
        authorId: props.authorId,
        inclusions: props.inclusions,
        exclusions: props.exclusions,
        size: 100,
        targetMediaId: props.targetMediaId || '',
        reportItemId: props.reportItemId || undefined,
      },
      {
        skip: !props.open || props.isPodcast || props.isRelevant,
      },
    );

  const { data: similarArticles, isFetching: isSimilarArticlesFetching } =
    useGetSimilarArticlesQuery(
      {
        authorId: props.authorId,
        inclusions: props.inclusions,
        exclusions: props.exclusions,
        size: 100,
        reportItemId: props.reportItemId,
      },
      {
        skip:
          !props.open ||
          props.isPodcast ||
          !props.isRelevant ||
          !props.reportItemId,
      },
    );

  const {
    data: articlesKeywordSuggestions,
    isFetching: isArticlesKeywordSuggestionsFetching,
  } = useGetArticlesSuggestionsQuery(
    {
      authorId: props.authorId,
      term: props.searchTerm,
      inclusions: props.inclusions,
      exclusions: props.exclusions,
    },
    {
      skip: !props.open || props.isPodcast || props.isRelevant,
    },
  );

  const {
    data: similarArticlesKeywordSuggestions,
    isFetching: isSimilarArticlesKeywordSuggestionsFetching,
  } = useGetSimilarArticlesSuggestionsQuery(
    {
      authorId: props.authorId,
      term: props.searchTerm,
      inclusions: props.inclusions,
      exclusions: props.exclusions,
      reportItemId: props.reportItemId,
    },
    {
      skip:
        !props.open ||
        props.isPodcast ||
        !props.isRelevant ||
        !props.reportItemId,
    },
  );

  const articlesData = useMemo(() => {
    if (props.isRelevant)
      return {
        data: similarArticles,
        isFetching: isSimilarArticlesFetching,
      };

    return {
      data: articles?.latestArticles,
      isFetching: isArticlesFetching,
    };
  }, [
    articles,
    similarArticles,
    isArticlesFetching,
    isSimilarArticlesFetching,
    props.isRelevant,
  ]);

  const suggestionsData = useMemo(() => {
    if (props.isRelevant)
      return {
        data: similarArticlesKeywordSuggestions,
        isFetching: isSimilarArticlesKeywordSuggestionsFetching,
      };

    return {
      data: articlesKeywordSuggestions,
      isFetching: isArticlesKeywordSuggestionsFetching,
    };
  }, [
    articlesKeywordSuggestions,
    similarArticlesKeywordSuggestions,
    isArticlesKeywordSuggestionsFetching,
    isSimilarArticlesKeywordSuggestionsFetching,
    props.isRelevant,
  ]);

  return {
    articles: articlesData,
    suggestions: suggestionsData,
  };
};
