import styled, { css } from 'styled-components';
import { hexToRGBA } from 'utils/helpers';

type Variant = 'green' | 'yellow' | 'red';

interface ArticleSimilarityScoreProps {
  variant: Variant;
}
export const ArticleSimilarityScore = styled.div<ArticleSimilarityScoreProps>`
  border-radius: 10px;
  display: flex;
  align-content: center;
  padding: 3px 5px;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'green':
        return css`
          background: ${hexToRGBA(theme.colors.apple, 0.2)};
          & > div {
            color: ${theme.colors.apple};
          }
        `;
      case 'yellow':
        return css`
          background: ${hexToRGBA('#FF8D1A', 0.2)};
          & > div {
            color: #ff8d1a;
          }
        `;
      case 'red':
        return css`
          background: ${hexToRGBA(theme.colors.volatileRed, 0.2)};
          & > div {
            color: ${theme.colors.volatileRed};
          }
        `;
      default:
        return '';
    }
  }}
`;
