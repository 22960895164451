import styled, { css } from 'styled-components/macro';
import { theme } from '../../../../../../../styles/theme';

export const Section = styled.div<{
  padding?: string;
  background?: 'white' | 'haze';
  noBorder?: boolean;
}>(
  ({ noBorder, padding = '16px 24px', background = 'white' }) => css`
    padding: ${padding};
    background-color: ${theme.colors[background]};
    border-bottom: ${noBorder ? 0 : 1}px solid ${theme.colors.smoke};
  `,
);

export const SeenInBar = styled.div<{ percentage: number }>(
  ({ theme, percentage }) => css`
    //background-color: ${theme.colors.haze};
    background: linear-gradient(
      90deg,
      ${theme.colors.smoke} ${percentage}%,
      ${theme.colors.haze} ${percentage}%
    );
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    overflow: hidden;

    .show-on-hover {
      opacity: 0;
      transition: 0.25s ease-in-out;
    }

    &:hover {
      .show-on-hover {
        opacity: 1;
      }
    }
  `,
);

export const SidebarFooter = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.haze};
    border-top: 1px solid ${theme.colors.smoke};
    width: 100%;
    padding: 14px 24px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 12px;
  `,
);

export const AuthorNameInput = styled.input(
  ({ theme }) => css`
    font-family: ${theme.fonts.dmSans};
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    border: none;
    border-bottom: 1px solid transparent;
    padding: 0;
    &:active,
    &:focus {
      outline: none;
      border-bottom: 1px solid ${theme.colors.onyx};
    }
  `,
);

export const RelevancyButton = styled.button<{
  variant: 'red' | 'green';
  active?: boolean;
}>(
  ({ theme, variant, active }) => css`
    width: 56px;
    height: 40px;
    border: 1px solid
      ${variant === 'green' ? theme.colors.kermit : theme.colors.redOrange};
    border-radius: 8px;
    background-color: #fff;
    font-family: ${theme.fonts.dmSans};
    font-weight: bold;
    cursor: pointer;

    transition: 0.25s all ease-in-out;

    &:hover {
      background-color: ${variant === 'green'
        ? 'rgba(0,211,119,0.2)'
        : 'rgba(237,32,41,0.3)'};
    }

    ${active
      ? css`
          background-color: ${variant === 'green'
            ? 'rgba(0,211,119,0.2)'
            : 'rgba(237,32,41,0.3)'};
        `
      : ''}
  `,
);

export const ClassifiedCategoryItem = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.haze};
    padding: 12px;
    border-radius: 8px;
    margin: 0 4px;
  `,
);
