import { ChangeEventHandler } from 'react';
import { showErrorToast } from '../toast';
import { uploadLogoResource } from '../helpers';
import { getErrorText } from '../error-messages';

interface IAvatarUploadHadlerProps {
  onFileUploaded: (url: string) => void;
  fileSizeLimitMb?: number;
}

type AvatarUploadHanderFn = (
  config: IAvatarUploadHadlerProps,
) => ChangeEventHandler<HTMLInputElement>;

export const useAvatarUploadHandler: AvatarUploadHanderFn = ({
  onFileUploaded,
  fileSizeLimitMb = 512,
}) => {
  const handler: ChangeEventHandler<HTMLInputElement> = async event => {
    if (event.target.files && event.target.files[0]) {
      if (!event.target.files[0].type.match('image')) {
        return showErrorToast('Please use .png, .jpg or .jpeg', 'Invalid file');
      }

      try {
        const fileUrl = await uploadLogoResource(event.target.files[0]);
        onFileUploaded(fileUrl);
      } catch (e) {
        if (e?.data?.errorCode === 'MaxUploadSizeExceededException') {
          showErrorToast(
            'Maximum upload size exceeded',
            'Failed to upload file',
          );
          return;
        }
        showErrorToast(getErrorText(e), 'Failed to upload file');
      }
    }
  };

  return handler;
};
