import { Route, RouteProps } from 'react-router-dom';
import { userPermissionEnum } from './userPermissions.enum';
import { FC } from 'react';
import useHasPermission from './useHasPermission.hook';
import DefaultFallback from './DefaultFallback';

interface IProps extends RouteProps {
  requirePermission?: userPermissionEnum;
}

export const ProtectedRoute: FC<IProps> = ({
  requirePermission,
  ...routeProps
}) => {
  const hasPermission = useHasPermission(requirePermission);

  if (hasPermission) {
    return <Route {...routeProps} />;
  } else {
    console.error(
      `⛔️ User does not have required permission to see this page. Required permission: "${requirePermission}"`,
    );
    return <DefaultFallback require={requirePermission} />;
  }
};

export default ProtectedRoute;
