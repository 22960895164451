import { FC } from 'react';
import * as S from '../CreateFirstPitch.styles';
import Button from 'app/components/UI/Button/Button';
import Typography from 'app/components/Typography';
import { useAppDispatch } from 'store/hooks';
import { globalActions } from 'app/containers/Global/slice';
import { RouteConstants } from 'app/routes';

const FormHeader: FC = () => {
  const dispatch = useAppDispatch();
  return (
    <S.Header>
      <Typography.Text
        $colorName="orbit"
        $size={20}
        $lineHeight={24}
        $dmSans
        $bold
      >
        Welcome to PRophet!
      </Typography.Text>
      <Button
        variant="gray"
        onClick={() =>
          dispatch(
            globalActions.authorizeUser({
              redirectUrl: RouteConstants.projects.list,
            }),
          )
        }
      >
        Skip
      </Button>
    </S.Header>
  );
};

export default FormHeader;
