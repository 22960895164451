import Button from 'app/components/UI/Button/Button';
import Gap from 'app/components/UI/Gap';
import { FC, useMemo } from 'react';
import { usePressReleaseContent } from '../../hooks/usePressReleaseContent.hook';
import { PressReleaseContentType } from '../../CreateOrEditPressRelease.constants';
import DatePicker from 'app/components/UI/DatePicker/DatePicker';
import moment from 'moment';
import { NativeInputBox } from 'app/components/ContentGeneration/ContentGeneration.styles';
import PressReleaseField from '../Fields/PressReleaseField';
import {
  FlexibleColumn,
  FlexibleContainer,
  WordCountPill,
} from '../PressRelease.styles';
import Input from 'app/components/UI/Input/Input';
import FlexBlock from 'app/components/UI/FlexBlock';
import { CheckboxPill } from 'app/components/UI/CheckboxPill/CheckboxPill';
import { theme } from 'styles/theme';
import { getWordCount } from 'utils/helpers';

interface IPressReleaseOriginalContentEditorProps {
  multipleContentView: boolean;
}

const PressReleaseOriginalContentEditor: FC<IPressReleaseOriginalContentEditorProps> =
  ({ multipleContentView }) => {
    const {
      content,
      contentSelected,
      handleContentSelect,
      handlePressReleaseInputChange,
      handlePressReleaseQuotesChange,
      handleQuoteAdd,
      handleQuoteRemove,
    } = usePressReleaseContent(PressReleaseContentType.ORIGINAL);

    const pressReleaseCopyWordCounterDisplay = useMemo(() => {
      const wordCount =
        getWordCount(content?.content.pressReleaseCopy || '') || 0;

      const displayText = wordCount === 1 ? 'Word' : 'Words';

      return `${wordCount} ${displayText}`;
    }, [content?.content.pressReleaseCopy]);

    return (
      <>
        {content && (
          <div
            style={{
              padding: multipleContentView ? '1em' : '2em',
              minWidth: '50%',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              flex: 1,
              borderRadius: '8px',
              border: contentSelected
                ? `1px solid ${theme.colors.orbit}`
                : 'none',
            }}
          >
            {multipleContentView && (
              <FlexBlock padding="0 0 12px 0">
                <CheckboxPill
                  checked={contentSelected}
                  onChange={handleContentSelect}
                  text={'Original Content'}
                  disabled={false}
                />
              </FlexBlock>
            )}
            <FlexibleContainer>
              <PressReleaseField
                fieldIndex={1}
                fieldTitle="Headline"
                value={content.content.title}
                valueKey="title"
                placeholder="Add a headline here..."
                onChange={handlePressReleaseInputChange}
              />
            </FlexibleContainer>
            <Gap size={30} />
            <FlexibleContainer>
              <PressReleaseField fieldIndex={2} fieldTitle="Date">
                <DatePicker
                  placeholder="Choose a date..."
                  onChange={e => {
                    handlePressReleaseInputChange(
                      'date',
                      e.format('YYYY-MM-DD HH:mm:ss'),
                    );
                  }}
                  value={
                    content.content.date ? moment(content.content.date) : null
                  }
                />
              </PressReleaseField>
              <PressReleaseField
                fieldIndex={3}
                fieldTitle="Location"
                value={content.content.city}
                valueKey="city"
                placeholder="Add a location here..."
                onChange={handlePressReleaseInputChange}
              />
            </FlexibleContainer>
            <Gap size={30} />
            <PressReleaseField fieldIndex={4} fieldTitle="Press Release Copy">
              <NativeInputBox
                value={content.content.pressReleaseCopy || ''}
                onChange={e =>
                  handlePressReleaseInputChange(
                    'pressReleaseCopy',
                    e.target.value,
                  )
                }
                minRows={10}
                maxRows={20}
                placeholder={'Add Press Release copy here...'}
              />
            </PressReleaseField>
            <FlexibleContainer width={'98%'}>
              <WordCountPill>
                {pressReleaseCopyWordCounterDisplay}
              </WordCountPill>
            </FlexibleContainer>
            <Gap size={30} />
            <FlexibleContainer>
              <PressReleaseField fieldIndex={5} fieldTitle="Quotes">
                <>
                  <FlexibleColumn>
                    {content.content.quotes?.map((quote, quoteIndex) => {
                      return (
                        <Input
                          value={quote}
                          placeholder="Add a quote here..."
                          onChange={e =>
                            handlePressReleaseQuotesChange(
                              quoteIndex,
                              e.target.value,
                            )
                          }
                          variant="contentInput"
                        />
                      );
                    })}
                  </FlexibleColumn>
                  <FlexibleContainer
                    width={'98%'}
                    justifyContent="flex-end"
                    padding="0.5rem"
                  >
                    {(content.content.quotes?.length || 0) > 1 && (
                      <Button
                        variant="danger-anchor"
                        onClick={handleQuoteRemove}
                      >
                        Remove last quote
                      </Button>
                    )}
                    {(content.content.quotes?.length || 0) < 3 && (
                      <Button variant="anchor" onClick={handleQuoteAdd}>
                        Add one more quote
                      </Button>
                    )}
                  </FlexibleContainer>
                </>
              </PressReleaseField>
              <PressReleaseField
                fieldIndex={6}
                fieldTitle="Boiler Plate"
                fieldSubtitle=""
                value={content.content.boilerPlate}
                valueKey="boilerPlate"
                placeholder="Add a boilerplate here..."
                onChange={handlePressReleaseInputChange}
              />
            </FlexibleContainer>
            <Gap size={24} />
          </div>
        )}
      </>
    );
  };

export default PressReleaseOriginalContentEditor;
