import { FC, MouseEventHandler, ReactNode } from 'react';
import * as S from './List.styles';
import Avatar from '../Avatar';
import FlexBlock from '../FlexBlock';
import Typography from '../../Typography';
import { IListItemWrapperProps } from './List.styles';

const { List } = S;
export default List;

interface IListItemProps extends IListItemWrapperProps {
  title: string;
  subTitle?: string;
  avatarUrl?: string;
  badgeText?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  hideAvatar?: boolean;
  boldTitle?: boolean;
  onRemoveClick?: () => void;
  rightIcon?: ReactNode;
}

export const ListItem: FC<IListItemProps> = ({
  title,
  subTitle,
  avatarUrl,
  badgeText,
  onClick,
  hideAvatar,
  boldTitle,
  onRemoveClick,
  rightIcon,
  ...listItemWrapperProps
}) => {
  return (
    <S.ListItemWrapper {...listItemWrapperProps} onClick={onClick}>
      {!hideAvatar && (
        <Avatar name={title} size="56" url={avatarUrl || undefined} />
      )}
      <FlexBlock flex={1} flexDirection="column" justifyContent="center">
        <Typography.Text $bold={boldTitle}>{title}</Typography.Text>
        {subTitle && (
          <Typography.Text $colorName="steel">{subTitle}</Typography.Text>
        )}
      </FlexBlock>
      {badgeText && <S.Badge>{badgeText}</S.Badge>}
      {Boolean(rightIcon) && rightIcon}
      {onRemoveClick && (
        <S.RemoveButton
          onClick={e => {
            e.stopPropagation();
            onRemoveClick();
          }}
        />
      )}
    </S.ListItemWrapper>
  );
};
