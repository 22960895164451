import styled, { css } from 'styled-components/macro';
import Input from '../../../../components/UI/Input/Input';
import TextareaAutosize from 'react-textarea-autosize';

export const PageBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 3, 41, 0.4);
  z-index: 49;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  flex: 1;
  max-height: 100%;
`;

export const PitchContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  //padding: 32px 40px;
  overflow-y: auto;
`;

export const SettingsBarWrapper = styled.div<{ closed?: boolean }>(
  ({ closed, theme }) => css`
    width: ${closed ? '0px' : '418px'};
    border-left: 1px solid ${theme.colors.smoke};
    transition: 0.4s all ease-in-out;

    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .settings-bar-header {
      opacity: ${closed ? '0' : '1'};
      transition: 0.4s all ease-in-out;
    }
  `,
);

export const SettingsBarContent = styled.div<{
  isHidden?: boolean;
  absolute?: boolean;
  scrollable?: boolean;
}>(
  ({ isHidden, absolute, scrollable = false }) => css`
    ${absolute || isHidden
      ? css`
          position: absolute;
        `
      : ''}
    transition: .4s all ease-in-out;
    opacity: ${isHidden ? '0' : '1'};
    //height: calc();
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    width: 100%;
    flex: 1;
    ${scrollable
      ? css`
          overflow-y: scroll;
        `
      : ''}

    &:nth-child(2) {
      transform: translateX(${isHidden ? '-250px' : '0'});
    }

    &:last-child {
      transform: translateX(${isHidden ? '250px' : '0'});
      width: 418px;
    }
  `,
);

export const Section = styled.div(
  ({ theme }) => css`
    padding: 16px 24px 24px;
    border-bottom: 1px solid ${theme.colors.smoke};
  `,
);

export const SettingsSection = styled.div(
  ({ theme }) => css`
    padding: 16px 24px 24px;
    border-bottom: 1px solid ${theme.colors.smoke};
    width: 100%;
  `,
);

export const SettingsBarFooter = styled.div(
  ({ theme }) => css`
    padding: 12px 24px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    position: sticky;
    bottom: 0;
    border-top: 1px solid ${theme.colors.smoke};
    box-shadow: 0 -2px 12px 0 rgba(12, 12, 13, 0.05);
    background: ${theme.colors.white};
    max-height: 83px;
  `,
);

export const JournalistBarFooter = styled.div(
  ({ theme }) => css`
    padding: 12px 24px;
    flex: 1;
    display: flex;
    gap: 1.5rem;
    justify-content: end;
    align-items: flex-end;
    width: 100%;
    position: sticky;
    bottom: 0;
    border-top: 1px solid ${theme.colors.smoke};
    box-shadow: 0 -2px 12px 0 rgba(12, 12, 13, 0.05);
    background: ${theme.colors.white};
    max-height: 83px;
  `,
);

export const SubHeadlineInput = styled.input(
  ({ theme }) => css`
    padding: 8px 0;
    font-family: ${theme.fonts.dmSans};
    font-weight: bold;
    font-size: 18px;
    border: none;
    &:focus {
      outline: none;
    }
  `,
);

export const BodyTextArea = styled.textarea(
  ({ theme }) => css`
    padding: 8px 0px;
    font-family: ${theme.fonts.inter};
    font-size: 16px;
    border: none;
    min-height: calc(100% - 10px);
    color: ${theme.colors.nero};
    &:focus {
      outline: none;
    }
  `,
);

export const HeadlineInput = styled(Input)(
  ({ theme }) => css`
    font-size: 20px;
    font-weight: bold;
    font-family: ${theme.fonts.dmSans};
  `,
);

export const InputBox = styled(TextareaAutosize)<{
  textStyle?: 'title' | 'subTitle' | 'body';
}>(
  ({ theme, textStyle = 'body', disabled }) => css`
    // default text styles
    font-size: 16px;
    font-family: ${theme.fonts.inter};
    line-height: 24px;
    color: ${theme.colors.nero};

    // title text styles
    ${textStyle === 'title'
      ? css`
          font-family: ${theme.fonts.dmSans};
          font-size: 22px;
          line-height: 24px;
          color: ${theme.colors.onyx};
          font-weight: bold;
        `
      : ''}

    // subtitle text styles
    ${textStyle === 'subTitle'
      ? css`
          font-family: ${theme.fonts.dmSans};
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: ${theme.colors.nero};
        `
      : ''}
    
    resize: none;

    // component styling
    padding: 16.5px;
    border: 1px solid transparent;
    border-radius: 10px;
    margin: 4px 0;
    transition: 0.25s border ease-in-out, 0.25s box-shadow ease-in-out;
    min-height: 56px;
    width: 100%;
    background-color: transparent;

    ${!disabled
      ? css`
          &:hover {
            background-color: #fff;
            border: 1px solid ${theme.colors.smoke};
            box-shadow: 0 0 4px rgba(12, 12, 13, 0.05);
          }

          &:focus {
            background-color: #fff;
            border: 1px solid ${theme.colors.orbit};
            outline: none;
            box-shadow: 0 0 4px rgba(12, 12, 13, 0.05);
          }
        `
      : css`
          cursor: default !important;
        `}
  `,
);

export const NativeInputBox = styled(InputBox).attrs({
  as: 'textarea',
})`
  flex: 1;
  z-index: 2;
`;

export const WordCountPill = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.haze};
    border-radius: 20px;
    font-family: ${theme.fonts.inter};
    color: ${theme.colors.onyx};
    font-size: 14px;
    line-height: 16px;
    padding: 10px 12px;

    margin: 16px 0;
    align-self: flex-start;
    opacity: 0;
    transform: translateY(-12px);
    transition: 0.125s ease-in-out;
    z-index: 1;
  `,
);

export const BodyWrapper = styled.div`
  //height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  &:focus-within {
    ${WordCountPill} {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const EditorContainer = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* border-right: 1px solid ${theme.colors.smoke}; */
    transition: 0.125s all ease-in-out;
    overflow: hidden;
    min-height: 100%;

    &:last-child {
      border: none;
    }
  `,
);

export const PitchContentEditorWrapper = styled.div<{
  hoverAnimation?: boolean;
  blueBackground?: boolean;
  fadeOut?: boolean;
  padding?: string;
}>(
  ({ theme, hoverAnimation, blueBackground, fadeOut, padding = '24px' }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    padding: ${padding};
    border-right: 1px solid ${theme.colors.smoke};
    transition: 0.125s all ease-in-out;
    height: 100%;
    &:last-child {
      border: none;
    }

    ${hoverAnimation
      ? css`
          &:hover {
            background-color: ${theme.colors.haze};
          }
        `
      : ''}

    ${blueBackground
      ? css`
          background-color: rgba(29, 118, 222, 0.09);
        `
      : ''}
    
    ${fadeOut
      ? css`
          opacity: 0.5;
        `
      : ''}
  `,
);

export const SocialPostsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`;
