import { FC, useEffect, useMemo, useState } from 'react';
import Card from '../../../../../../components/UI/Card';
import Typography from '../../../../../../components/Typography';
import {
  IPreparedReportItem,
  prepareReportItems,
} from './MediaReportCard.helpers';
import {
  groupByOptions,
  ILimitOption,
  ISortOption,
} from './MediaReportCard.constants';
import Gauge from '../../../../../../components/Gauge/Gauge';
import FlexBlock from '../../../../../../components/UI/FlexBlock';
import Button from '../../../../../../components/UI/Button/Button';
import CarrotUpIcon from '../../../../../../assets/icons/CarrotUpIcon';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { usePitchSlice } from '../../../../slice';
import Select from '../../../../../../components/UI/Select/Select';
import ListViewIcon from '../../../../../../assets/icons/ListViewIcon';
import ContactsRequestModal from '../ContactsRequestModal/ContactsRequestModal';
import { ActionPasserProvider } from 'utils/actionPasser/actionPasser';
import MediaReportTable, {
  TableScrollWrapper,
} from '../MediaReportTable/MediaReportTable';
import CoverIcon from '../../../../../../assets/icons/CoverIcon';
import { Spinner } from '../../../../../../components/UI/Spinner';
import BlurredTable from '../BlurredTable/BlurredTable';
import useHasPermission from '../../../../../Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from '../../../../../Global/permissions/userPermissions.enum';
import { getAveragePercentInterestOfResultsTooltipProps } from 'app/components/UI/Tooltip/Tooltip';
import ContentTypeSelector from 'app/containers/Projects/pages/ContentList/parts/ContentTypeSelector/ContentTypeSelector';
import { IReportV31 } from 'app/api.types';
import { useReportFilters } from './hooks/useReportFilters';
import { useLoadReportData } from './hooks/useLoadReportData';

interface IProps {
  pitchId: number;
  predictionId: number;
  reportId: number;
  initialReport: IReportV31;
  isReportExpanded: boolean;
  isPredictionFetching: boolean;
  printMode?: boolean;
  printModePage?: number;
  topRightText?: string;
}

const MediaReportCard: FC<IProps> = ({
  initialReport,
  pitchId,
  predictionId,
  reportId,
  isReportExpanded,
  printMode,
  printModePage,
  topRightText,
  isPredictionFetching,
}) => {
  const {
    appliedFilters,
    filterOptions,
    setActiveFilters,
    isFetchingReportFilters,
  } = useReportFilters({
    pitchId,
    predictionId,
    reportId,
    shouldSkipFilters: false,
  });

  const {
    loadReportItems,
    report,
    isFetchingReport,
    isFetchingAllItems,
    isFetchingMoreItems,
    handleSortClick,
    sort,
    limitOptions,
    limit,
    onLimitChange,
    onSeeMoreClick,
  } = useLoadReportData({
    initialReport,
    appliedFilters,
    pitchId,
    predictionId,
    reportId,
    isPredictionFetching,
  });

  const isPodcastReport = useMemo(() => {
    return report.creatorType === 'PODCASTER';
  }, [report]);

  const [expanded, setExpanded] = useState(isReportExpanded);
  const [groupBy, setGroupBy] = useState<ISortOption>(groupByOptions[0]);

  const [requestContactsReportItem, setRequestContactsReportItem] =
    useState<IPreparedReportItem | null>(null);

  const hasPermissionToSeeAllResults = useHasPermission(
    userPermissionEnum.READ_ALL_PREDICTION_RESULTS,
  );

  const reportItemData: {
    reportItems: IPreparedReportItem[];
    interestKeys: string[];
  } = useMemo(() => {
    const [reportItems, interestKeys] = prepareReportItems(
      report,
      Boolean(groupBy.value === 'outlet'),
      printMode,
      printModePage,
    );

    return {
      reportItems,
      interestKeys,
    };
  }, [report, groupBy, printMode, printModePage]);

  const dispatch = useAppDispatch();
  const { actions } = usePitchSlice();

  const handleRowClick = (row: IPreparedReportItem) => {
    if (printMode) return;
    if (row.restricted) return;
    if (groupBy.value === 'outlet' && row.titleRow) return;
    if (row.authorId && row.interest !== undefined) {
      dispatch(
        actions.setAuthor({
          id: row.authorId,
          interest: row.interest,
          sentiment: 0,
          podcastId:
            report?.mediaList.type?.code === 'PODCAST' && row.podcastId
              ? row.podcastId
              : null,
          reportItem: row,
          targetMedia: report?.mediaList || null,
        }),
      );

      dispatch(
        actions.setCreator({
          id: row.authorId,
          name: row.authorName || '',
          outlets: row.outlets || [],
          socials: row.authorSocials || [],
          email: row.authorEmail || '',
          avatar: row.authorAvatar || '',
          bio: row.authorBio || '',
          locations: row.authorLocation,
          title: row.authorTitle,
          podcast: row.podcast,
          contributor: row.contributor,
        }),
      );
    }
  };

  const toggleRequestContactsModal = (data: any) => {
    const reportItem = data as IPreparedReportItem;
    setRequestContactsReportItem(reportItem);
  };

  useEffect(() => {
    loadReportItems({ countToLoad: limitOptions[0].value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy]);

  return (
    <>
      <Card.Wrapper
        padding="0"
        minHeight="0"
        margin="40px 0 0 0 "
        overflowVisible={true}
      >
        <Card.Header padding="20px 24px" noBorder={!expanded}>
          <FlexBlock alignItems="center">
            <Gauge
              value={Math.floor((initialReport?.overall || 0) * 100)}
              size={72}
              fontSize={20}
              strokeWidth={6}
              measurementSuffix="%"
              withTooltip={getAveragePercentInterestOfResultsTooltipProps(
                report?.mediaList.name
                  ? report.mediaList.name.toLocaleLowerCase()
                  : '',
              )}
            />
            <Typography.Text $dmSans $bold $size={20} $margin="0 24px">
              {report?.mediaList.name}
            </Typography.Text>
          </FlexBlock>
          <FlexBlock
            alignItems="center"
            justifyContent="flex-end"
            flex={1}
            columnGap="8px"
          >
            {!printMode && (
              <>
                {!isPodcastReport && (
                  <>
                    <ContentTypeSelector
                      options={filterOptions.outletScopes}
                      setSelectedOptions={options => {
                        if (typeof options !== 'function') {
                          const updatedFilters = {
                            ...appliedFilters,
                            outletScopes: options || [],
                          };
                          setActiveFilters(updatedFilters);
                          loadReportItems({ filters: updatedFilters });
                        }
                      }}
                      compactView
                      title="Source"
                      isLoading={isFetchingReportFilters}
                    />

                    <ContentTypeSelector
                      options={filterOptions.categories}
                      setSelectedOptions={options => {
                        if (typeof options !== 'function') {
                          const updatedFilters = {
                            ...appliedFilters,
                            categoriesIds: options
                              ? options.map(option => Number(option))
                              : [],
                          };
                          setActiveFilters(updatedFilters);
                          loadReportItems({ filters: updatedFilters });
                        }
                      }}
                      compactView
                      title="Beat"
                      isLoading={isFetchingReportFilters}
                    />
                  </>
                )}

                <Select<ILimitOption>
                  options={limitOptions.filter(opt => !opt.hidden)}
                  value={limit}
                  onChange={opt => {
                    onLimitChange(opt as ILimitOption);
                  }}
                  fontSize={14}
                  controlPadding="8px 12px"
                  width="212px"
                  formatOptionLabel={opt => (
                    <FlexBlock
                      alignItems="center"
                      columnGap="8px"
                      cursorPointer
                    >
                      <CoverIcon />
                      {opt.label}
                    </FlexBlock>
                  )}
                />
                <Select<ISortOption>
                  options={groupByOptions}
                  value={groupBy}
                  onChange={opt => setGroupBy(opt as ISortOption)}
                  fontSize={14}
                  controlPadding="8px 12px"
                  width="212px"
                  formatOptionLabel={opt => (
                    <FlexBlock
                      alignItems="center"
                      columnGap="8px"
                      cursorPointer
                    >
                      <ListViewIcon size={18} />
                      {opt.label}
                    </FlexBlock>
                  )}
                />
                <Button
                  variant="icon-button-border"
                  onClick={() => setExpanded(e => !e)}
                >
                  <CarrotUpIcon rotateDown={!expanded} />
                </Button>
              </>
            )}
            {Boolean(topRightText) && (
              <Typography.Text $colorName="orbit" $size="16">
                {topRightText}
              </Typography.Text>
            )}
          </FlexBlock>
        </Card.Header>

        <Card.Content padding="0">
          <ActionPasserProvider action={toggleRequestContactsModal}>
            <TableScrollWrapper itemsPerView={expanded ? 13 : 0}>
              <MediaReportTable
                data={reportItemData.reportItems}
                interestKeys={reportItemData.interestKeys}
                onRowClick={handleRowClick}
                groupBy={groupBy.value}
                sort={sort}
                onSortColumnClick={handleSortClick}
                reportId={report?.id}
                isFetchingReport={isFetchingReport}
                isFetchingMoreItems={isFetchingMoreItems}
                isFetchingAllItems={isFetchingAllItems}
              />

              {!printMode && !report.reportItemsPage.last && (
                <FlexBlock padding="16px 0" justifyContent="center">
                  <Button
                    variant="secondary"
                    compact
                    wide
                    isLoading={isFetchingReport}
                    onClick={onSeeMoreClick}
                  >
                    See More
                  </Button>
                </FlexBlock>
              )}

              {limit.last && !hasPermissionToSeeAllResults && (
                <BlurredTable
                  data={[reportItemData.reportItems[1]]}
                  interestKeys={reportItemData.interestKeys}
                  onRowClick={handleRowClick}
                  groupBy={groupBy.value}
                  sort={sort}
                  onSortColumnClick={handleSortClick}
                />
              )}
            </TableScrollWrapper>
          </ActionPasserProvider>
        </Card.Content>
      </Card.Wrapper>
      <ContactsRequestModal
        reportItem={requestContactsReportItem}
        onClose={() => setRequestContactsReportItem(null)}
      />
    </>
  );
};

export default MediaReportCard;
