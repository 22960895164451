import styled, { css } from 'styled-components';

interface ITitleProps {
  margin?: string;
  size?: string;
}

export const Title = styled.h2<ITitleProps>(
  ({ theme, margin = '16px 0', size = '32px' }) => css`
    font-family: ${theme.fonts.dmSans};
    font-size: ${size};
    line-height: 40px;
    color: ${theme.colors.onyx};
    margin: ${margin};
  `,
);
