import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';
import TaylorSection from 'app/components/ContentGeneration/parts/header/parts/TaylorSection';
import Gap from 'app/components/UI/Gap';
import PressReleaseGenerationControls from '../PressReleaseGenerationControls';
import { PressReleaseActions } from './PressReleaseActions';
import { PROPHET_ORACLE_TOOLTIP_PRESS_RELEASE } from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitch.constants';
import PressReleaseContentVersionsDrawer from './PressReleaseContentVersionsDrawer';

const PressReleaseHeader: FC = ({ children }) => {
  return (
    <FlexBlock justifyContent="space-between" minWidth="100%">
      <FlexBlock alignItems="center">
        <TaylorSection taylorTooltip={PROPHET_ORACLE_TOOLTIP_PRESS_RELEASE} />
        {children}
        <Gap size={12} />
        <PressReleaseGenerationControls />
      </FlexBlock>
      <PressReleaseActions />
      <PressReleaseContentVersionsDrawer />
    </FlexBlock>
  );
};

export default PressReleaseHeader;
