import React from 'react';

const TimerIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-574, -104)" fill="#3F3F42">
          <g transform="translate(0, 72)">
            <g transform="translate(32, 12)">
              <g transform="translate(522, 0)">
                <g transform="translate(20, 20)">
                  <path
                    d="M8,0.4 C3.8026359,0.4 0.4,3.8026359 0.4,8 C0.4,12.1973641 3.8026359,15.6 8,15.6 C12.1973641,15.6 15.6,12.1973641 15.6,8 C15.5952559,3.80460255 12.1953975,0.404744106 8,0.4 Z M11.582678,5.31297171 L8.44786404,8.44782525 C8.28787149,8.60783166 8.05466987,8.67032867 7.83610398,8.61177426 C7.61753809,8.55321985 7.44681328,8.38250983 7.38823994,8.16394901 C7.3296666,7.94538819 7.39214341,7.71218116 7.55213596,7.55217475 L10.6869895,4.417322 C10.8469819,4.25732209 11.0801799,4.19483101 11.2987401,4.25338855 C11.5173003,4.31194609 11.6880182,4.48265596 11.746586,4.70121341 C11.8051537,4.91977086 11.7426736,5.1529718 11.5826812,5.31297171 L11.582678,5.31297171 Z"
                    id="Icon"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TimerIcon;
