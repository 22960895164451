import { FC, useState } from 'react';
import * as S from './CollapsableTextSection.styles';
import Button from '../UI/Button/Button';
import Typography from '../Typography';

interface IProps {
  body: string;
  margin?: string;
  padding?: string;
  lineCap?: number;
  buttonActionOverride?: () => void;
}

const CollapsableTextSection: FC<IProps> = ({
  body,
  margin,
  padding,
  lineCap,
  buttonActionOverride,
}) => {
  const [expanded, setExpanded] = useState(false);

  const onButtonClick = () => {
    if (buttonActionOverride) {
      buttonActionOverride();
    } else {
      setExpanded(expanded => !expanded);
    }
  };

  return (
    <S.Wrapper margin={margin} padding={padding}>
      <S.Content expanded={expanded}>
        <div className="text">
          <S.TextWrapper $expanded={expanded} lineCap={lineCap}>
            {body}
          </S.TextWrapper>
        </div>
        <S.ShowMoreOverlay>
          <Button
            padding="12px 79px"
            variant="secondary"
            onClick={onButtonClick}
          >
            <Typography.Text $dmSans $bold $lineHeight={16}>
              See {expanded ? 'Less' : 'More'}
            </Typography.Text>
          </Button>
        </S.ShowMoreOverlay>
      </S.Content>
    </S.Wrapper>
  );
};

export default CollapsableTextSection;
