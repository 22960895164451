import { FC, ReactNode } from 'react';
import { useResponsiveQuery } from './useResponsiveQuery';

interface Props {
  children: ReactNode;
}

const ShowIfDesktop: FC<Props> = ({ children }) => {
  const { desktop } = useResponsiveQuery();
  return desktop ? <>{children}</> : null;
};

export default ShowIfDesktop;
