import { FC, useMemo } from 'react';
import { IApiResponsePageablePart } from '../../../api.types';
import * as S from '../Table.styles';

interface IProps {
  pageInfo: IApiResponsePageablePart;
  onPageNumberChange: (pageNumber: number) => void;
  totalElements: number;
}

interface IPaginationPage {
  id: number;
  name: string;
  disabled?: boolean;
}

export const TablePagination: FC<IProps> = ({
  pageInfo,
  onPageNumberChange,
  totalElements,
}) => {
  const pages: IPaginationPage[] = useMemo(() => {
    const result: IPaginationPage[] = [];
    const totalNumberOfPages = Math.ceil(totalElements / pageInfo.pageSize);

    if (totalNumberOfPages < 7) {
      result.push(
        ...Array(totalNumberOfPages)
          .fill('')
          .map((n, i) => ({
            id: i,
            name: `${i + 1}`,
          })),
      );
    } else {
      for (let i = 0; i < totalNumberOfPages; i++) {
        if (
          Math.abs(i - 0) < 3 ||
          Math.abs(totalNumberOfPages - i) < 3 ||
          Math.abs(pageInfo.pageNumber - i) < 2
        ) {
          result.push({
            id: i,
            name: `${i + 1}`,
          });
        } else if (!result[result.length - 1]?.disabled) {
          result.push({
            id: i,
            name: '...',
            disabled: true,
          });
        }
      }
    }

    return result;
  }, [pageInfo, totalElements]);

  if (pages.length < 2) return null;

  return (
    <S.PaginationWrapper>
      {pages.map(r => (
        <S.PaginationButton
          onClick={() => onPageNumberChange(r.id)}
          disabled={r.disabled}
          active={pageInfo.pageNumber === r.id}
          key={r.name}
        >
          {r.name}
        </S.PaginationButton>
      ))}
    </S.PaginationWrapper>
  );
};
