import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { globalActions } from '../slice';
import { selectAuthorized, selectInitialized } from '../slice/selectors';
import Splash from '../../../components/UI/Splash';
import { matchPath, useHistory, useLocation } from 'react-router';
import { RouteConstants } from 'app/routes';

interface IProps {}

const AuthWrapper: FC<IProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const initialized = useAppSelector(selectInitialized);
  const authorized = useAppSelector(selectAuthorized);
  const location = useLocation();

  useEffect(() => {
    dispatch(globalActions.authorizeUser({}));
  }, [dispatch]);

  useEffect(() => {
    if (initialized) {
      const isAuthRoute = matchPath(location.pathname, {
        path: RouteConstants.authorization.index,
      });
      const isRootRoute = matchPath(location.pathname, {
        path: RouteConstants.root,
        exact: true,
      });

      if ((isAuthRoute || isRootRoute) && authorized) {
        history.push(RouteConstants.projects.list);
      }

      if (!isAuthRoute && !authorized) {
        history.push(RouteConstants.authorization.login);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  if (!initialized) return <Splash />;

  return <>{children}</>;
};

export default AuthWrapper;
