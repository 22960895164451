import FlexBlock from 'app/components/UI/FlexBlock';
import { FC, Dispatch, SetStateAction } from 'react';
import PillRadioButton from './PillRadioButton';
import Typography from 'app/components/Typography';
import Gap from 'app/components/UI/Gap';

export interface IOptionalDetails {
  emphasizeEducation: boolean | undefined;
  emphasizeInterests: boolean | undefined;
  emphasizeRole: boolean | undefined;
}

interface IProps {
  setOptionalDetails: Dispatch<SetStateAction<IOptionalDetails>>;
  compact: boolean;
  optionalDetails: IOptionalDetails;
}

const OptionalDetails: FC<IProps> = ({
  compact,
  optionalDetails,
  setOptionalDetails,
}) => {
  const options = [
    { displayName: 'Personal Interests', key: 'emphasizeInterests' },
    { displayName: 'Previous Role', key: 'emphasizeRole' },
    { displayName: 'Education', key: 'emphasizeEducation' },
  ];

  const toggleEmphasized = (optionKey: string, isEmphasized: boolean) => {
    // Remove option if already emphasized
    if (isEmphasized) {
      setOptionalDetails(currentDetails => ({
        ...currentDetails,
        [optionKey]: undefined,
      }));
    } else {
      setOptionalDetails(currentDetails => ({
        ...currentDetails,
        [optionKey]: true,
      }));
    }
  };

  const toggleUnderstated = (optionKey: string, isUnderstated: boolean) => {
    // Remove option if already understated
    if (isUnderstated) {
      setOptionalDetails(currentDetails => ({
        ...currentDetails,
        [optionKey]: undefined,
      }));
    } else {
      setOptionalDetails(currentDetails => ({
        ...currentDetails,
        [optionKey]: false,
      }));
    }
  };

  return (
    <FlexBlock
      flexDirection="row"
      justifyContent={compact ? 'left' : 'space-between'}
      columnGap="16px"
      minWidth="100%"
      padding={compact ? '8px 16px 8px 16px' : undefined}
    >
      {options.map(option => (
        <FlexBlock flexDirection="column" alignItems="left" key={option.key}>
          <Typography.Text
            $colorName="nero"
            $padding={!compact ? '0 0 0 15px' : undefined}
            $size={compact ? 14 : 16}
            $lineHeight={24}
          >
            {option.displayName}
          </Typography.Text>
          <Gap size={8} />
          <PillRadioButton
            type={option.key}
            emphasized={Boolean(
              optionalDetails[option.key as keyof typeof optionalDetails],
            )}
            understated={
              optionalDetails[option.key as keyof typeof optionalDetails] ===
              false
            }
            setEmphasized={toggleEmphasized}
            setUnderstated={toggleUnderstated}
            compact={compact}
          />
        </FlexBlock>
      ))}
    </FlexBlock>
  );
};

export default OptionalDetails;
