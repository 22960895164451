import { SVGProps } from 'react';

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => {
  const { width = '40', height = '40', ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.0642 19.9503H12.5604V25.6003H17.0308V40.0003C16.4136 39.867 15.8131 39.767 15.2293 39.617C9.92489 38.2837 5.82147 35.2837 2.91905 30.667C1.15091 27.8337 0.183437 24.717 0.0333124 21.4003C-0.33366 13.6503 2.73556 7.53367 9.15758 3.20034C12.894 0.667002 17.1142 -0.266331 21.6013 0.0670023C30.5754 0.733669 37.6814 7.45034 39.4829 15.5337C42.0517 27.117 34.6622 37.5337 24.1034 39.9503C24.0366 39.9503 23.9699 39.9503 23.8698 39.967V25.617H27.7731C28.0733 23.7337 28.3569 21.8837 28.6572 19.9837H22.7522C22.7522 19.8503 22.7189 19.7503 22.7189 19.6337C22.7189 18.4503 22.7189 17.267 22.7189 16.0837C22.7189 14.4837 23.703 13.3837 25.2877 13.2503C26.2385 13.167 27.1893 13.2003 28.1567 13.1837C28.5904 13.1837 29.0241 13.1837 29.4745 13.1837V8.217C28.7572 8.13367 28.0233 8.03367 27.2894 7.967C25.6547 7.817 24.0033 7.717 22.3853 8.13367C19.5162 8.88367 17.5979 10.9337 17.2477 13.8837C17.0642 15.5003 17.1142 17.1337 17.0642 18.7503C17.0642 19.1337 17.0642 19.517 17.0642 19.9503Z"
        fill="#1877F2"
      />
    </svg>
  );
};

export default FacebookIcon;
