import { IApiResponse, ICustomTargetMediaBase, IMedia } from 'app/api.types';
import { useCallback, useMemo, useRef, useState } from 'react';

export const useMediaListRowsSelect = (
  currentRows?: IApiResponse<ICustomTargetMediaBase[]>,
) => {
  const [selectedItems, setSelectedItems] = useState<
    Record<string, ICustomTargetMediaBase>
  >({});
  const selectedItemsRef = useRef<Record<string, ICustomTargetMediaBase>>({});

  const selectedMediaListIds = useMemo(
    () =>
      Object.keys(selectedItems)
        .filter(key => !isNaN(Number(key)))
        .map(Number),
    [selectedItems],
  );

  const allRowsSelectedInCurrentPage = useMemo(() => {
    const currentPageItemIds = currentRows?.content.map(item => item.id) || [];

    return (
      currentPageItemIds.length > 0 &&
      currentPageItemIds.every(id =>
        Object.keys(selectedItems).includes(id.toString()),
      )
    );
  }, [currentRows, selectedItems]);

  const handleSelectItem = (
    item: ICustomTargetMediaBase,
    selected: boolean,
  ) => {
    if (selected) {
      selectedItemsRef.current = {
        ...selectedItemsRef.current,
        [item.id]: item,
      };
    } else {
      const { [item.id]: _, ...newSelectedItems } = selectedItemsRef.current;
      selectedItemsRef.current = newSelectedItems;
    }
    setSelectedItems({ ...selectedItemsRef.current });
  };

  const handleSelectAllItems = useCallback(
    (selected: boolean) => {
      const currentPageItemIds =
        currentRows?.content.map(item => item.id.toString()) || [];

      if (selected && currentRows) {
        // If all items are selected, add them to the selected items
        const newSelectedItems = currentRows?.content.reduce(
          (items, item) => {
            items[item.id] = item;
            return items;
          },
          { ...selectedItemsRef.current },
        );
        selectedItemsRef.current = newSelectedItems;
      } else {
        // If all items are deselected, remove them from the selected items
        selectedItemsRef.current = Object.keys(selectedItemsRef.current)
          .filter(itemId => !currentPageItemIds.includes(itemId))
          .reduce((items, itemId) => {
            items[itemId] = selectedItemsRef.current[itemId];
            return items;
          }, {} as Record<string, ICustomTargetMediaBase>);
      }
      // Sync the state with the ref to trigger a re-render
      setSelectedItems({ ...selectedItemsRef.current });
    },
    [currentRows],
  );

  const handleResetAllSelectedItems = useCallback(() => {
    selectedItemsRef.current = {};
    setSelectedItems({});
  }, []);

  const toggleSelectedRows = useCallback(
    (isSelected: boolean) => {
      handleSelectAllItems(isSelected);
    },
    [handleSelectAllItems],
  );

  return {
    selectedItemsRef,
    selectedMediaListIds,
    allRowsSelectedInCurrentPage,
    toggleSelectedRowsInCurrentPage: toggleSelectedRows,
    handleSelectedRowsReset: handleResetAllSelectedItems,
    handleRowSelect: handleSelectItem,
  };
};
