import { FC, useMemo, useRef, useState } from 'react';
import Input from '../Input/Input';
import CalendarIcon from '../../../assets/icons/CalendarIcon';
import moment, { Moment } from 'moment';
import { DEFAULT_DATE_FORMAT } from '../../../../utils/helpers';
import * as S from './DatePicker.styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPicker } from 'react-dates';
import ClickAwayListener from 'react-click-away-listener';
import { theme } from 'styles/theme';

interface IDatePickerProps {
  value: Moment | null;
  onChange: (date: Moment) => void;
  placeholder?: string;
}

const DatePicker: FC<IDatePickerProps> = ({ value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dateInputRef = useRef<HTMLInputElement>(null);

  const selectedDate = useMemo(() => {
    if (!value) return '';
    return moment(value).format(DEFAULT_DATE_FORMAT);
  }, [value]);

  const handleInputFocus = () => {
    dateInputRef?.current?.blur();
    setIsOpen(true);
  };

  const handleClickAway = (event: any) => {
    if (event.target !== dateInputRef.current) setIsOpen(false);
  };

  const onDayClick = (day: Moment) => {
    setIsOpen(false);
    onChange(day);
  };

  const renderCalendarDay = (day: Moment) => {
    const dayText = day.format('D');
    if (day.isSame(value)) return <strong>{dayText}</strong>;
    return <span>{dayText}</span>;
  };

  return (
    <S.Wrapper>
      <Input
        icon={<CalendarIcon />}
        onFocus={handleInputFocus}
        value={selectedDate}
        inputRef={dateInputRef}
        readOnly
        placeholder={placeholder || 'Select timing date'}
        variant="contentInput"
        border={isOpen ? `1px solid ${theme.colors.orbit}` : undefined}
      />
      {isOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <S.PickerContainer>
            <DayPicker
              onDayClick={onDayClick}
              numberOfMonths={1}
              noBorder
              hideKeyboardShortcutsPanel
              renderDayContents={renderCalendarDay}
            />
          </S.PickerContainer>
        </ClickAwayListener>
      )}
    </S.Wrapper>
  );
};

export default DatePicker;
