export type Breakpoint = {
  from: number;
  to: number;
};

export type Breakpoints = {
  smallMobile: Breakpoint;
  mobile: Breakpoint;
  tablet: Breakpoint;
  desktop: Breakpoint;
  largeDesktop: Breakpoint;
};

export const breakpoints: Breakpoints = {
  smallMobile: {
    from: 0,
    to: 400,
  },
  mobile: {
    from: 0,
    to: 768,
  },
  tablet: {
    from: 768,
    to: 1024,
  },
  desktop: {
    from: 1024,
    to: Infinity,
  },
  largeDesktop: {
    from: 1400,
    to: Infinity,
  },
};

export type ResponsiveBreakpoints = {
  smallMobile: boolean;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  largeDesktop: boolean;
};

export const cssMediaBreakpoints = {
  smallMobile: `@media (max-width: ${breakpoints.smallMobile.to - 1}px)`,
  mobile: `@media (max-width: ${breakpoints.mobile.to - 1}px)`,
  tablet: `@media (min-width: ${breakpoints.tablet.from}px) and (max-width: ${
    breakpoints.tablet.to - 1
  }px)`,
  desktop: `@media (min-width: ${breakpoints.desktop.from}px)`,
  largeDesktop: `@media (min-width: ${breakpoints.largeDesktop.from}px)`,
  mobileOrTablet: `@media (max-width: ${breakpoints.tablet.to - 1}px)`,
  tabletOrDesktop: `@media (min-width: ${breakpoints.tablet.from}px)`,
};
