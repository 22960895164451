import { FC, useEffect, useMemo, useState } from 'react';
import PageLoader from '../../../../components/UI/PageLoader';
import PitchHeader from './parts/PitchHeader/PitchHeader';
import MainGrid from '../../../../components/UI/MainGrid/MainGrid';
import DigDeeperSlideout from '../CreateOrEditPitch/parts/DigDeeperSlideout/DigDeeperSlideout';
import { useAppDispatch } from '../../../../../store/hooks';
import { usePitchSlice } from '../../slice';
import VersionDrawer from './parts/VersionDrawer/VersionDrawer';
import PdfExport from './parts/PdfExport/PdfExport';
import { Helmet } from 'react-helmet-async';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';
import PitchFooter from './parts/PitchFooter/PitchFooter';
import { PITCH_FOOTER_HEIGHT } from './PitchPage.constants';
import { PitchPageContextProvider } from './PitchPage.context';
import PitchPageContent from './PitchPageContent';
import PitchTooltips from './parts/Tooltips/PitchTooltips';
import {
  ContentLayout,
  ContentHeader,
} from 'app/components/UI/ContentLayout/ContentLayout';
import { showErrorToast } from 'utils/toast';
import { useGetPredictedContent } from './PitchPage.hooks';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { handlePitchReportError } from './PitchPage.helpers';
import FlexBlock from 'app/components/UI/FlexBlock';
import ProjectDisplaySkeleton from 'app/components/UI/LoadingSkeletons/Projects/ProjectDisplaySkeleton';
import PredictionControlsSkeleton from 'app/components/UI/LoadingSkeletons/Prediction/PredictionControlsSkeleton';
import { SkeletonDiv } from 'app/components/UI/LoadingSkeletons/Skeletons.styles';
import Gap from 'app/components/UI/Gap';
import PredictionReportsSkeleton from 'app/components/UI/LoadingSkeletons/Prediction/PredictionReportsSkeleton';

interface IProps {}

const PitchPage: FC<IProps> = () => {
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { actions } = usePitchSlice();

  const {
    isPitch,
    prediction,
    content,
    contentError,
    predictionError,
    isLoading,
    isPredictionFetching,
    versionId,
  } = useGetPredictedContent();

  const pageTitle = useMemo(() => {
    if (!prediction) return '';
    const title = isPitch ? 'Pitch' : 'Press Release';
    return `${title} - ${prediction.headline} (V${versionId})`;
  }, [isPitch, prediction, versionId]);

  useEffect(() => {
    if (prediction?.deprecated) {
      showErrorToast(
        'Journalist details have been refreshed. Please edit your pitch and predict it again to see the most recent data.',
      );
    }
  }, [prediction]);

  useEffect(() => {
    dispatch(actions.setAuthor(null));
  }, [actions, dispatch]);

  // if (isLoading) return <PageLoader />;

  if (contentError) {
    return handlePitchReportError(
      contentError as FetchBaseQueryError,
      'Content',
    );
  }

  if (predictionError) {
    return handlePitchReportError(
      predictionError as FetchBaseQueryError,
      'Prediction',
    );
  }

  if (!content && !isLoading) {
    return <EmptyDataNotice title={'Content not found'} />;
  }

  if (!prediction && !isLoading) {
    return <EmptyDataNotice title={'Prediction not found'} />;
  }

  return (
    <PitchPageContextProvider reports={prediction?.reports || []}>
      <Helmet title={pageTitle} />
      <ContentLayout>
        <ContentHeader>
          {isLoading && (
            <FlexBlock
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              minWidth="100%"
            >
              <ProjectDisplaySkeleton />
              <PredictionControlsSkeleton />
            </FlexBlock>
          )}
          {content && prediction && !isLoading && (
            <PitchHeader
              content={content}
              predictedContentType={isPitch ? 'PITCH' : 'PRESS_RELEASE'}
              prediction={prediction}
              exportModalOpen={exportModalOpen}
              setExportModalOpen={setExportModalOpen}
            />
          )}
        </ContentHeader>
        <MainGrid minContent marginBottom={PITCH_FOOTER_HEIGHT}>
          {isLoading && <PredictionReportsSkeleton />}
          {prediction && !isLoading && (
            <PitchPageContent
              reports={prediction.reports}
              predictionId={prediction.id}
              isPredictionFetching={isPredictionFetching}
            />
          )}
        </MainGrid>
      </ContentLayout>
      {content && prediction && (
        <PitchFooter
          pitch={content}
          hasReports={prediction.reports.length}
          setExportModalOpen={setExportModalOpen}
          height={PITCH_FOOTER_HEIGHT}
        />
      )}

      <DigDeeperSlideout />
      <VersionDrawer />
      {content && prediction && (
        <PdfExport pitch={content} prediction={prediction} />
      )}
      <PitchTooltips />
    </PitchPageContextProvider>
  );
};

export default PitchPage;
