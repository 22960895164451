import { usePromptModalContext } from '../../../../../../Global/parts/PromptModal/PromptModal.context';
import { useAppDispatch } from '../../../../../../../../store/hooks';
import { useGenerationLimits } from '../../../hooks/useGenerationLimits';
import { useGlobalSlice } from '../../../../../../Global/slice';
import useOutOfLimitsMessages from '../../../hooks/useOutOfLimitsMessages';

const useLimitExceededPopup = () => {
  const prompt = usePromptModalContext();
  // const userProfile = useAppSelector(selectUser);
  const limits = useGenerationLimits();
  const globalSlice = useGlobalSlice();
  const dispatch = useAppDispatch();
  const messages = useOutOfLimitsMessages();

  const show = () => {
    prompt.createModal({
      title: 'Upgrade Plan',
      description: `You've used all your Taylor generations for this month! Upgrade to our ${messages.planToUpgrade} for additional content generations, or wait until ${limits.resetTimestamp} for your limit to reset.`,
      icon: 'wand',
      okButtonText: 'Upgrade Plan',
      cancelButtonText: `Keep ${messages.currentPlan}`,
      okButtonCallback: () => {
        dispatch(globalSlice.actions.goToUpgradeToPro());
      },
    });
  };

  return {
    show,
  };
};

export default useLimitExceededPopup;
