import ReactAvatar, { ConfigProvider, Cache } from 'react-avatar';
import { CSSProperties, FC, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { localStorageEnum } from '../../../types';

interface IProps {
  url?: string;
  name?: string;
  size?: string;
  margin?: string;
  shadow?: boolean;
  onClick?: () => void;
}

const Wrapper = styled.div<{ margin?: string; $clickable: boolean }>(
  ({ margin = '0 16px 0 0', $clickable }) => css`
    margin: ${margin};
    cursor: ${$clickable ? 'pointer' : 'default'};
  `,
);

const Avatar: FC<IProps> = ({
  name,
  url,
  size = '35',
  margin,
  shadow,
  onClick,
}) => {
  const styles: CSSProperties = useMemo(
    () => ({
      boxShadow: shadow ? '0 0 15px rgba(12,12,13,0.04)' : undefined,
    }),
    [shadow],
  );

  return (
    <Wrapper margin={margin} onClick={onClick} $clickable={Boolean(onClick)}>
      <ReactAvatar
        name={name}
        src={url}
        size={size}
        round
        style={styles}
        color="#3f3f42"
      />
    </Wrapper>
  );
};

interface IConfigProviderProps {}

const cache = new Cache({
  sourceTTL: 7 * 24 * 3600 * 1000,
  sourceSize: 1000,
  cachePrefix: localStorageEnum.avatarCacheKey,
});

export const AvatarConfigProvider: FC<IConfigProviderProps> = ({
  children,
}) => {
  return <ConfigProvider>{children}</ConfigProvider>;
};

export default Avatar;
