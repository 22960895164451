import {
  IPressReleaseContent,
  IPressReleaseState,
} from './CreateOrEditPressRelease.types';

export enum PressReleaseStateActions {
  UPDATE_PRESS_RELEASE = 'UPDATE_PRESS_RELEASE',
  UPDATE_PRESS_RELEASE_CONTENTS = 'UPDATE_PRESS_RELEASE_CONTENTS',
  UPDATE_PRESS_RELEASE_CONTENT = 'UPDATE_PRESS_RELEASE_CONTENT',
  UPDATE_PRESS_RELEASE_CONTENT_VERSIONS = 'UPDATE_PRESS_RELEASE_CONTENT_VERSIONS',
  SET_SELECTED_PRESS_RELEASE_CONTENT = 'SET_SELECTED_PRESS_RELEASE_CONTENT',
  SET_SELECTED_CUSTOM_MEDIA_LIST = 'SET_SELECTED_CUSTOM_MEDIA_LIST',
  SET_PREDICTION = 'SET_PREDICTION',
  SET_TONE = 'SET_TONE',
  SET_PRESS_RELEASE_PROCESS_STATUS = 'SET_PRESS_RELEASE_PROCESS_STATUS',
  TOGGLE_SETTINGS = 'TOGGLE_SETTINGS',
  TOGGLE_VERSIONS = 'TOGGLE_VERSIONS',
  TOGGLE_SOCIALS = 'TOGGLE_SOCIALS',
}

export enum PressReleasePredictionStatus {
  PREDICTION_RUNNING = 'PREDICTION_RUNNING',
  PREDICTION_COMPLETE = 'PREDICTION_COMPLETE',
}

export enum PressReleaseContentType {
  GENERATED = 'GENERATED',
  ORIGINAL = 'ORIGINAL',
}

export const DEFAULT_PRESS_RELEASE_STATE: IPressReleaseState = {
  timingDate: null,
  countries: [{ code: 'US', displayName: 'United States' }],
  runAgainstPodcasts: false,
  states: [],
  mediaSourceFilter: { inclusions: [], exclusions: [] },
  contentTypes: [],
  selectedMediaLists: [],
};

export const DEFAULT_PRESS_RELEASE_CONTENT_STATE: IPressReleaseContent = {
  contentType: PressReleaseContentType.ORIGINAL,
  content: {
    title: '',
    pressReleaseCopy: '',
    quotes: [''],
    date: '',
    city: '',
    boilerPlate: '',
  },
};
