import { FC, useEffect, useState } from 'react';
import Typography from '../../../../components/Typography';
import CodeInput from '../../../../components/UI/CodeInput';
import { ForgotPasswordRow } from '../LoginForm/LoginForm.styles';
import { Anchor } from '../../../../components/UI/Anchor';
import Button from '../../../../components/UI/Button/Button';
import { Divider } from '../../../../components/UI/Divider';
import {
  asyncDelay,
  depersonalizeEmail,
  msToMinutes,
} from '../../../../../utils/helpers';
import { localStorageEnum, sessionStorageEnum } from '../../../../../types';
import { requestResendMfaToken, submitMfaCode } from '../../api';
import { showErrorToast, showSuccessToast } from '../../../../../utils/toast';
import { useHistory } from 'react-router-dom';
import { RouteConstants } from '../../../../routes';
import { useAppDispatch } from '../../../../../store/hooks';
import { globalActions } from '../../../Global/slice';
import Analytics from '../../../../../utils/analytics';

interface IProps {
  mfaToken: string;
}

const MfaForm: FC<IProps> = ({ mfaToken }) => {
  const [mfaCode, setMfaCode] = useState('');
  const [timeToWait, setTimeToWait] = useState<string | null>(null);
  const [codeSent, setCodeSent] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const dispatch = useAppDispatch();

  const history = useHistory();

  const onCodeResendClick = async () => {
    try {
      await requestResendMfaToken(mfaToken);
      showSuccessToast('Check your email.', 'Code sent');
      setCodeSent(true);
    } catch (e) {
      if (e.status === 401) {
        showErrorToast('Please re-login', 'Session expired');
        history.push(RouteConstants.authorization.login);
      } else if (e.status === 429) {
        setCodeSent(true);
        if (e.data?.delayInMillis) {
          setTimeToWait(msToMinutes(e.data?.delayInMillis));
          await asyncDelay(e.data?.delayInMillis);
          setCodeSent(false);
          setTimeToWait(null);
        }
      } else {
        showErrorToast('Unknown error');
      }
    }
  };

  const handleCodeChange = (code: string) => {
    setMfaCode(code);
  };

  useEffect(() => {
    if (mfaCode && mfaCode.length === 6) {
      onCodeSubmit();
    }
  }, [mfaCode]);

  // useEffect(() => {
  //   //TODO: Temp solution for BE bug;
  //   requestResendMfaToken(mfaToken);
  // }, []);

  const onCodeSubmit = async () => {
    try {
      setLoginLoading(true);
      const loginResponse = await submitMfaCode(mfaToken, mfaCode);
      if (loginResponse.accessToken) {
        localStorage.setItem(localStorageEnum.token, loginResponse.accessToken);
        Analytics.track.login();
        dispatch(
          globalActions.authorizeUser({
            redirectUrl: RouteConstants.pitch.createFirstPitch,
          }),
        );
      }
    } catch (e) {
      setLoginLoading(false);
      showErrorToast('Please re-login', 'Invalid code');
      history.push(RouteConstants.authorization.login);
    }
  };

  const getClientsEmail = () => {
    const email = sessionStorage.getItem(sessionStorageEnum.tempMfaEmail);
    return depersonalizeEmail(email || '');
  };

  return (
    <>
      <Typography.Title>2FA Authentication</Typography.Title>
      <Typography.Text $margin="0 0 32px 0">
        A verification code was sent to <strong>{getClientsEmail()}</strong> and
        texted to you, please input the code below to continue with login.
      </Typography.Text>
      <CodeInput onCodeEntered={handleCodeChange} />
      <ForgotPasswordRow>
        <Anchor href="mailto:support@prprophet.ai">
          No longer have access to email?
        </Anchor>
        <Button
          padding="16px 24px"
          onClick={onCodeSubmit}
          isLoading={loginLoading}
        >
          Login
        </Button>
      </ForgotPasswordRow>
      <Divider $margin="24px 0" />
      <ForgotPasswordRow>
        <Anchor onClick={onCodeResendClick} $disabled={Boolean(codeSent)}>
          Resend code
        </Anchor>
        {Boolean(timeToWait) && (
          <Typography.Text>
            Please wait {timeToWait} before requesting again
          </Typography.Text>
        )}
      </ForgotPasswordRow>
    </>
  );
};

export default MfaForm;
