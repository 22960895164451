import { FC } from 'react';
import * as S from './CheckBox.styles';
import CheckmarkIcon from '../../../assets/icons/CheckmarkIcon';

interface IProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const CheckBox: FC<IProps> = props => {
  const handleCheckBoxClick = () => {
    props.onChange(!props.checked);
  };

  return (
    <S.Wrapper
      checked={props.checked}
      onClick={handleCheckBoxClick}
      type="button"
    >
      <CheckmarkIcon />
    </S.Wrapper>
  );
};

export default CheckBox;
