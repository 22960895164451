import { PodcastDemographicsDataEntry } from 'app/api.types';

export const amountValueFormatter = (amount: number) => {
  if (typeof Intl !== 'undefined' && typeof Intl.NumberFormat !== 'undefined') {
    let formatter = Intl.NumberFormat('en-US', { notation: 'compact' });
    return `$${formatter.format(amount)}`;
  } else {
    // Fallback if Intl.NumberFormat is not available
    return amount.toString();
  }
};

export const renderValueString = (
  value: PodcastDemographicsDataEntry | number,
  valueSuffix: string | ((amount: number) => string),
) => {
  if (typeof value === 'number') {
    return typeof valueSuffix === 'function'
      ? valueSuffix(value)
      : `${value.toFixed(1)}${valueSuffix}`;
  } else {
    return `${(value.percentage * 100).toFixed(0)}${valueSuffix}`;
  }
};

export const getDefaultActiveTab = (
  podcastsData: {
    breakdown: any[];
    segments: any[];
    geographies: any[];
  } | null,
) => {
  if (!podcastsData || podcastsData.breakdown.length > 0) return 'breakdown';
  if (podcastsData.segments.length > 0) return 'topSegments';
  if (podcastsData.geographies.length > 0) return 'geographies';

  return 'breakdown';
};

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return '';

  return word.charAt(0).toUpperCase() + word.slice(1);
};
