import Typography from 'app/components/Typography';
import { useAvailableUserRoles } from '../hooks/useAvailableUserRoles';
import { FC, useEffect, useMemo } from 'react';
import Select from 'app/components/UI/Select/Select';
import { IOption } from 'types/commonTypes';
import { UserRoleEnum } from 'app/containers/Global/slice/types';
import { IClassifiedProperty } from 'app/api.types';
import { showErrorToast } from 'utils/toast';

interface IRolesSelectorProps {
  userRole?: IClassifiedProperty<UserRoleEnum> | null;
  onUserRoleChange: (role: IClassifiedProperty<UserRoleEnum>) => void;
}

const RolesSelector: FC<IRolesSelectorProps> = ({
  userRole,
  onUserRoleChange,
}) => {
  const { availableUserRoles, isLoading, isError } = useAvailableUserRoles();

  useEffect(() => {
    if (isError) {
      showErrorToast('Could not load user roles.');
    }
  }, [isError]);

  const userRoles = useMemo(() => {
    if (availableUserRoles) {
      return availableUserRoles.map(role => ({
        label: role.displayName,
        value: role.code,
      }));
    }
    return [];
  }, [availableUserRoles]);

  const selectedUserRoleLabel = useMemo(() => {
    const selectedRole = userRoles.find(role => role.value === userRole?.code);
    if (!selectedRole)
      return {
        label: userRole?.displayName || 'Select a Role',
        value: userRole?.code || '',
      };
    return selectedRole;
  }, [userRole, userRoles]);

  const getUserRoleFromUserOption = (roleOption: IOption<UserRoleEnum>) => {
    return {
      code: roleOption.value,
      displayName: roleOption.label,
    } as IClassifiedProperty<UserRoleEnum>;
  };

  return (
    <>
      <Typography.Text
        $colorName="nero"
        $size={14}
        $lineHeight={20}
        $padding="16px 0 8px 0"
      >
        Role
      </Typography.Text>
      <Select<IOption<string>>
        minWidth="350px"
        controlPadding="6px 12px"
        fontSize={14}
        isLoading={isLoading}
        options={userRoles}
        value={selectedUserRoleLabel}
        placeholder="Select a Role"
        onChange={roleOption =>
          onUserRoleChange(
            getUserRoleFromUserOption(roleOption as IOption<UserRoleEnum>),
          )
        }
      />
    </>
  );
};

export default RolesSelector;
