import { localStorageEnum } from '../types';
import { TENANT_HISTORY_ITEMS_SAVED } from './constants';

export function generateClientViewHistoryLsKey(userId: number) {
  return `${localStorageEnum.clientViewHistoryPrefix}[${userId}]`;
}

export function getClientViewHistory(lsKey: string): number[] {
  const lsValue = localStorage.getItem(lsKey);
  const history = lsValue
    ? JSON.parse(localStorage.getItem(lsKey) || '')
    : null;
  if (Array.isArray(history)) {
    return history.map(item => Number(item));
  }
  return [];
}

export function addClientToViewHistory(lsKey: string, tenantId: number) {
  const history = getClientViewHistory(lsKey);
  localStorage.setItem(
    lsKey,
    JSON.stringify(
      [tenantId, ...history.filter(cid => cid !== tenantId)].slice(
        0,
        TENANT_HISTORY_ITEMS_SAVED + 1,
      ),
    ),
  );
}
