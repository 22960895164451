import { FC, useEffect, useMemo, useRef, useState } from 'react';
import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../store/hooks';
import {
  selectDigDeeperAuthorId,
  selectDigDeeperCreator,
  selectDigDeeperPodcastId,
} from '../../../../slice/selectors';
import { usePitchSlice } from '../../../../slice';
import PageLoader from '../../../../../../components/UI/PageLoader';
import AuthorInfo from './parts/AuthorInfo';
import ExploreArticles from './parts/ExploreArticles';
import SuggestAnEdit from './parts/SuggestAnEdit';
import { showErrorToast } from '../../../../../../../utils/toast';
import { useAuthorSummary } from './hooks/useAuthorSummary';
import { useOnClickOutsideClick } from 'utils/hooks/useOnOutsideClick';

import ContentGenerationDrawer from './parts/ContentGenerationDrawer';
import {
  CONTENT_LENGTH_SELECT_OPTIONS,
  TONE_SELECT_OPTIONS,
} from '../ToneSelectDropDown/ToneSelectDropDown.config';

interface IProps {}

export const DigDeeperSlideout: FC<IProps> = () => {
  const [exploreArticlesOpen, setExploreArticlesOpen] = useState(false);
  const [exploreRelevantArticles, setExploreRelevantArticles] = useState(false);
  const [suggestEditOpen, setSuggestEditOpen] = useState(false);
  const sideDrawerRef = useRef<HTMLDivElement>(null);
  const authorInfoRef = useRef<HTMLDivElement>(null);

  const authorId = useAppSelector(selectDigDeeperAuthorId);
  const podcastId = useAppSelector(selectDigDeeperPodcastId);

  const dispatch = useAppDispatch();
  const { actions } = usePitchSlice();

  const handleClose = () => {
    dispatch(actions.setAuthor(null));
    dispatch(actions.setContentGenerationTone(TONE_SELECT_OPTIONS[0]));
    dispatch(
      actions.setContentGenerationLengthTone(CONTENT_LENGTH_SELECT_OPTIONS[1]),
    );
    dispatch(actions.toggleContentGenerationDrawer(false));
  };

  const creator = useAppSelector(selectDigDeeperCreator);

  const {
    data: creatorSummary,
    isFetching: isCreatorSummaryFetching,
    isError,
  } = useAuthorSummary(
    { authorId: authorId || undefined, podcastId: podcastId || undefined },
    { skip: !Boolean(authorId) },
  );

  const isPodcast = useMemo(() => {
    return Boolean(creator?.podcast);
  }, [creator?.podcast]);

  useEffect(() => {
    if (isError) {
      showErrorToast('Server Error', 'Failed to load author');
      handleClose();
    }
  }, [isError]);

  useEffect(() => {
    setSuggestEditOpen(false);
  }, [authorId]);

  useOnClickOutsideClick(sideDrawerRef, e => {
    // @ts-ignore
    if (e.path?.map(i => i.id).includes('portal-root')) return;
    handleClose();
  });

  // Reset scroll position if creator changes
  useEffect(() => {
    if (creator?.id && authorInfoRef.current) {
      authorInfoRef.current.scrollIntoView();
    }
  }, [creator?.id]);

  // Reset Dig Deeper state when creator changes
  useEffect(() => {
    setExploreArticlesOpen(false);
    setExploreRelevantArticles(false);
    setSuggestEditOpen(false);
  }, [creator?.id]);

  return (
    <div ref={sideDrawerRef}>
      <SideDrawer open={Boolean(authorId)}>
        <AuthorInfo
          onSlideoutClose={handleClose}
          openExploreArticles={(exploreRelevantArticles = false) => {
            setExploreRelevantArticles(exploreRelevantArticles);
            setExploreArticlesOpen(true);
          }}
          openSuggestEdit={() => setSuggestEditOpen(true)}
          summary={creatorSummary}
          isSummaryFetching={isCreatorSummaryFetching}
          ref={authorInfoRef}
        />
      </SideDrawer>

      <SideDrawer open={exploreArticlesOpen}>
        {isCreatorSummaryFetching ? (
          <PageLoader />
        ) : (
          <ExploreArticles
            creatorId={creator?.id || ''}
            creatorName={creator?.name || ''}
            episodes={creatorSummary?.episodes}
            onClose={() => {
              setExploreRelevantArticles(false);
              setExploreArticlesOpen(false);
            }}
            open={exploreArticlesOpen}
            isPodcast={isPodcast}
            isRelevantArticles={exploreRelevantArticles}
          />
        )}
      </SideDrawer>
      {creator && (
        <SideDrawer open={suggestEditOpen}>
          <SuggestAnEdit
            onClose={() => setSuggestEditOpen(false)}
            creator={creator}
          />
        </SideDrawer>
      )}

      {Boolean(creatorSummary) && (
        <ContentGenerationDrawer
          authorEmail={creator?.email?.split(/,|;/)[0] || ''}
          creatorId={creator?.id || ''}
          isPodcast={isPodcast}
        />
      )}
    </div>
  );
};

export default DigDeeperSlideout;
