import { SVGProps } from 'react';

const CarrotDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#1D76DE" transform="translate(-473 -32)">
        <g transform="translate(260)">
          <g transform="translate(24 4)">
            <g transform="translate(189 28)">
              <path d="M.99 3A.68.68 0 00.5 4.163l5.007 4.938c.13.127.312.199.489.199.175 0 .35-.063.489-.2l5-4.93a.676.676 0 00.017-.97.703.703 0 00-.978 0L6.006 7.664 1.478 3.199A.698.698 0 00.989 3z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CarrotDownIcon;
