import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Input from 'app/components/UI/Input/Input';
import { FC, useState } from 'react';
import { logger } from 'utils/apm/logger';

interface IAPMSettingsProps {}

const APMSettings: FC<IAPMSettingsProps> = () => {
  const [error, setError] = useState<Error | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // UI Error Boundary is only triggered if error occurs in the render phase
  if (error) {
    throw error;
  }

  // Trigger Error Boundary Crash
  // This error is caught by the Error Boundary and should be reported to APM service
  const onCrashUI = () => {
    try {
      throw new Error(
        `APM Test - UI Crash - ${errorMessage || 'Unknown error'}`,
      );
    } catch (error) {
      setError(error as Error);
    }
  };

  // Trigger crash on asynchronous action
  // This uncaught error should be captured and reported by APM service automatically
  const onAsyncCrash = () => {
    setTimeout(() => {
      throw new Error(
        `APM Test - Asynchronous Crash - ${errorMessage || 'Unknown error'}`,
      );
    }, 0);
  };

  // Trigger crash and catch it gracefully
  // This error is handled correctly and should be reported to APM service with a custom error message
  const onTryCatchCrash = () => {
    try {
      throw new Error(
        `APM Test - Caught Exception - ${errorMessage || 'Unknown error'}`,
      );
    } catch (error) {
      logger.logError((error as Error).message);
    }
  };

  return (
    <FlexBlock flexDirection="column" minWidth="200px" margin="0 0 0 10px">
      <Typography.Text $dmSans $bold $size={16} $margin="0 0 10px 10px">
        APM Test
      </Typography.Text>
      <Input
        placeholder={'Custom error message for APM service'}
        value={errorMessage}
        onChange={e => setErrorMessage(e.target.value)}
        variant="textFieldWhite"
      />
      <FlexBlock flexDirection="row" columnGap="10px" margin="10px 0 0 0">
        <Button variant="secondary" padding="10px" onClick={onCrashUI}>
          Crash UI
        </Button>
        <Button variant="secondary" padding="10px" onClick={onAsyncCrash}>
          Crash asynchronous action
        </Button>
        <Button variant="secondary" padding="10px" onClick={onTryCatchCrash}>
          Crash gracefully
        </Button>
      </FlexBlock>
    </FlexBlock>
  );
};

export default APMSettings;
