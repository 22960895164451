import { FC, SVGProps } from 'react';
interface IProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CheckmarkIcon: FC<IProps> = ({ color = '#00D377' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill={color} transform="translate(-1870 -470)">
        <g transform="translate(260 72)">
          <g transform="translate(1243 80)">
            <g transform="translate(0 184)">
              <g transform="translate(32 128)">
                <g transform="translate(331 2)">
                  <g transform="translate(4 4)">
                    <path d="M11.43 3.894L4.997 9.96a.887.887 0 01-1.213 0L.571 6.927a.86.86 0 01-.028-1.225.885.885 0 011.24-.03l2.609 2.463 5.825-5.496a.885.885 0 011.24.03.86.86 0 01-.028 1.225z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckmarkIcon;
