import styled, { css } from 'styled-components/macro';
import { createGlobalStyle } from 'styled-components';

export const GlobalPrintStyles = createGlobalStyle`
  @media print {
    #root {
      display: none;
    }
    #print-root {
      overflow: hidden;
    }
    body {
      overflow: hidden;
    }
    @page {
      size: ledger landscape;
      margin: 0;
      
    }
  }
`;

export const BackDrop = styled.div(
  ({ theme }) => css`
    z-index: 700;
    position: absolute;
    top: 0;
    left: 0;
    background: ${theme.colors.smoke};
    width: 100vw;
    min-height: 100vh;
    //overflow: scroll;
    //display: flex;
    //justify-content: center;
    //align-items: stretch;
    //display: none;
    //padding: 35px;
    @media print {
      display: flex;
      background-color: ${theme.colors.steel};
      padding: 0;
    }
  `,
);

export const ContentWrapper = styled.div`
  flex: 1;
  //overflow: scroll;
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: flex-start;
  max-height: 100%;
  row-gap: 16px;
  overflow: hidden;
  //max-width: 1200px;
  //min-width: 1200px;
  @media print {
    row-gap: 0;
  }
`;

export const Page = styled.div<{ dark?: boolean }>(
  ({ dark, theme }) => css`
    //width: 100%;
    //padding-top: 70.92%;
    width: 1632px;
    height: 1056px;
    background-color: ${dark ? theme.colors.stratos : theme.colors.whiteDimmed};
    //border: 1px solid red;
    box-sizing: border-box;
    //margin: 10mm;
    display: flex;
    visibility: hidden;
    @media print {
      display: flex;
      width: 1632px;
      height: 1056px;
      visibility: visible;
      page-break-inside: avoid;
    }
  `,
);

export const PageContent = styled.div`
  padding: 40px 40px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const HideOnPrint = styled.div`
  @media print {
    visibility: hidden;
  }
`;
