import { SVGProps } from 'react';

const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Eye Icon</title>
    <path
      d="M12.09 5.4c.991.015 6.654.298 10.643 4.785a2.715 2.715 0 0 1-.082 3.655A14.629 14.629 0 0 1 12 18.6c-.66 0-6.596-.132-10.733-4.785a2.715 2.715 0 0 1 .082-3.655A14.629 14.629 0 0 1 12 5.4ZM12 7.876c-2.249 0-4.072 1.847-4.072 4.125S9.751 16.125 12 16.125s4.072-1.847 4.072-4.125a4.153 4.153 0 0 0-1.193-2.917A4.045 4.045 0 0 0 12 7.875Zm0 1.65c1.35 0 2.443 1.108 2.443 2.475S13.349 14.475 12 14.475c-1.35 0-2.443-1.108-2.443-2.475S10.651 9.525 12 9.525Z"
      fill="#9A9C9E"
      fillRule="evenodd"
    />
  </svg>
);

export default EyeIcon;
