import {
  IPitch,
  IPitchPrediction,
  IPressRelease,
} from '../../../../../../api.types';
import { FC } from 'react';
import Typography from '../../../../../../components/Typography';
import CollapsableTextSection from '../../../../../../components/CollapsableTextSection/CollapsableTextSection';
import FlexBlock from '../../../../../../components/UI/FlexBlock';
import Button from '../../../../../../components/UI/Button/Button';
import RemoveIcon from '../../../../../../assets/icons/RemoveIcon';
import Avatar from '../../../../../../components/UI/Avatar';
import Accordion from '../../../../../../components/Accordion/Accordion';
import { Divider } from '../../../../../../components/UI/Divider';
import { FormattedTooltip } from '../../../../../../components/UI/InfoTip/InfoTip';

interface IProps {
  summaryDisplayName?: string;
  content: IPitch | IPressRelease;
  prediction: IPitchPrediction;
  onCloseClick: () => void;
}

const PitchSummary: FC<IProps> = ({
  summaryDisplayName,
  content,
  prediction,
  onCloseClick,
}) => {
  return (
    <FlexBlock
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <FlexBlock
        padding="24px 24px 32px 32px"
        flexDirection="column"
        alignItems="stretch"
      >
        <FlexBlock justifyContent="space-between">
          <Typography.Text
            $colorName="orbit"
            $size="16"
            $lineHeight="24"
            $bold
            $dmSans
          >
            {summaryDisplayName || 'Pitch'} Details
          </Typography.Text>
          <Button variant="icon-button-border" onClick={onCloseClick}>
            <RemoveIcon />
          </Button>
        </FlexBlock>
        <FlexBlock flexDirection="column" rowGap="24px">
          <FlexBlock flexDirection="column" rowGap="8px">
            <Typography.Text $dmSans $bold $colorName="nero" $lineHeight={16}>
              Project
            </Typography.Text>
            <FlexBlock alignItems="center">
              <Avatar
                size="32"
                url={content.project.logo}
                name={content.project.name}
              />
              <Typography.Text
                $colorName="steel"
                $size={16}
                hideOverflow
                overflowWidth="493px"
              >
                {content.project.name}
              </Typography.Text>
            </FlexBlock>
          </FlexBlock>

          <FlexBlock columnGap="32px">
            <FlexBlock flexDirection="column" rowGap="8px" minWidth="120px">
              <FlexBlock alignItems="center" columnGap="4px">
                <Typography.Text
                  $dmSans
                  $bold
                  $colorName="nero"
                  $lineHeight={16}
                >
                  Sample Total
                </Typography.Text>
                <FormattedTooltip
                  tooltipPositionLeft
                  message="Based on filters selected. Doesn’t include podcasts."
                />
              </FlexBlock>
              <Typography.Text $colorName="steel" $size={16} hideOverflow>
                {prediction.reports
                  ?.reduce((acc, report) => acc + (report.totalSamples || 0), 0)
                  .toLocaleString() || 0}
              </Typography.Text>
            </FlexBlock>

            <FlexBlock flexDirection="column" rowGap="8px" minWidth="120px">
              <Typography.Text $dmSans $bold $colorName="nero" $lineHeight={16}>
                Country
              </Typography.Text>
              <Typography.Text $colorName="steel" $size={16}>
                {prediction.countries
                  .map(country => country.displayName)
                  .join(', ')}
              </Typography.Text>
            </FlexBlock>
            {prediction.mediaSourceFilter &&
              prediction.mediaSourceFilter.inclusions && (
                <FlexBlock flexDirection="column" rowGap="8px" minWidth="120px">
                  <Typography.Text
                    $dmSans
                    $bold
                    $colorName="nero"
                    $lineHeight={16}
                  >
                    Sources
                  </Typography.Text>
                  <Typography.Text $colorName="steel" $size={16}>
                    {prediction.mediaSourceFilter?.inclusions
                      ?.map(item => item.displayName)
                      .join(', ')}
                  </Typography.Text>
                </FlexBlock>
              )}
          </FlexBlock>
        </FlexBlock>
      </FlexBlock>

      <Divider />

      <FlexBlock
        padding="16px 24px"
        flexDirection="column"
        alignItems="stretch"
      >
        <Accordion
          title={`${summaryDisplayName || 'Pitch'} Content`}
          defaultExpanded
        >
          <Typography.Text $size={20} $dmSans $bold>
            {prediction.headline}
          </Typography.Text>
          <CollapsableTextSection
            body={prediction.body || ''}
            margin="16px 0"
          />
        </Accordion>
      </FlexBlock>

      <Divider />
    </FlexBlock>
  );
};

export default PitchSummary;
