import { FC, useMemo } from 'react';
import * as S from '../../CreateOrEditPitchPage.styles';

import FlexBlock from '../../../../../../components/UI/FlexBlock';
import InfoTip from '../../../../../../components/UI/InfoTip/InfoTip';
import Typography from '../../../../../../components/Typography';
import {
  COUNTRY_CODES_FOR_STATES,
  GEOGRAPHY_TOOLTIP,
} from '../../CreateOrEditPitch.constants';
import MultiSelect, {
  IMultiSelectOption,
} from '../../../../../../components/UI/MultiSelect/MultiSelect';
import GlobeIcon from '../../../../../../assets/icons/GlobeIcon';
import CheckBox from '../../../../../../components/UI/CheckBox/CheckBox';

import IfHasPermission from '../../../../../Global/permissions/IfHasPermission';
import { userPermissionEnum } from '../../../../../Global/permissions/userPermissions.enum';
import LocationIcon from 'app/assets/icons/LocationIcon';
import Gap from 'app/components/UI/Gap';

import PodcastLogoIcon from 'app/assets/icons/PodcastLogoIcon';

import { PitchStateActions, usePitch, usePitchDispatch } from '../PitchContext';
import { useGetAvailablePitchFiltersQuery } from 'app/api';
import useDebounce from 'utils/hooks/useDebounce';
import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import CustomMediaListsSelector from './CustomMediaListsSelector';
import { IPitchAvailableMediaFilter } from 'app/api.types';

export const SettingsBar: FC = () => {
  const { pitch } = usePitch();
  const dispatch = usePitchDispatch();

  const handleCountryUpdate = (countries: IMultiSelectOption[]) => {
    // Filter out states that are not from selected countries
    const filteredStates = pitch.states?.filter(state => {
      return countries?.some(country =>
        state.code.startsWith(`${country.code}_`),
      );
    });

    dispatch({
      type: PitchStateActions.UPDATE_PITCH,
      payload: { countries, states: filteredStates },
    });
  };

  const handleCountryStateUpdate = (states: IMultiSelectOption[]) => {
    dispatch({ type: PitchStateActions.UPDATE_PITCH, payload: { states } });
  };

  const handlePodcastCheckboxClick = (runAgainstPodcasts: boolean) => {
    dispatch({
      type: PitchStateActions.UPDATE_PITCH,
      payload: { runAgainstPodcasts },
    });
  };

  const displayStates = useMemo(() => {
    return pitch.countries.find(country =>
      COUNTRY_CODES_FOR_STATES.includes(country.code),
    );
  }, [pitch.countries]);

  const availableFiltersRequestBody = useMemo(() => {
    return {
      countries: pitch.countries.map(country => country.code),
      contentTypes:
        pitch.contentTypes && pitch.contentTypes.length > 0
          ? pitch.contentTypes.map(contentType => contentType.code)
          : [],
      states:
        pitch.states && pitch.states.length > 0
          ? pitch.states.map(state => state.code)
          : [],
      mediaSourceFilter:
        pitch.mediaSourceFilter && pitch.mediaSourceFilter.inclusions
          ? {
              inclusions: pitch.mediaSourceFilter.inclusions.map(
                inclusion => inclusion.code,
              ),
              exclusions: [],
            }
          : { inclusions: [], exclusions: [] },
    };
  }, [
    pitch.countries,
    pitch.contentTypes,
    pitch.states,
    pitch.mediaSourceFilter,
  ]);

  const debouncedSelectedFilters = useDebounce(
    availableFiltersRequestBody,
    500,
  );

  const hasCountriesFilterAccess = useHasPermission(
    userPermissionEnum.PITCH_COUNTRIES_ACCESS,
  );
  const hasPremiumFilterAccess = useHasPermission(
    userPermissionEnum.SOURCE_4_ACCESS,
  );

  const hasPermissionToFilters =
    hasCountriesFilterAccess || hasPremiumFilterAccess;

  const {
    data: availableFilters,
    isLoading: isLoadingAvailableFilters,
    isFetching: isFetchingAvailableFilters,
    isError: isErrorAvailableFilters,
  } = useGetAvailablePitchFiltersQuery(debouncedSelectedFilters, {
    skip: !hasPermissionToFilters,
  });

  const includesCountryWithPodcastsAvailable = useMemo(() => {
    if (
      !pitch.countries ||
      !availableFilters ||
      !availableFilters.podcastsCountryTypes ||
      availableFilters.podcastsCountryTypes.length === 0
    ) {
      return false;
    }

    return pitch.countries.some(country =>
      (
        availableFilters.podcastsCountryTypes as IPitchAvailableMediaFilter[]
      ).some(podcastCountry => podcastCountry.code === country.code),
    );
  }, [pitch.countries, availableFilters]);

  if (!hasPermissionToFilters) {
    return (
      <S.SettingsSection>
        <FlexBlock
          padding="30px 8px 24px"
          disabled={!includesCountryWithPodcastsAvailable}
        >
          <PodcastLogoIcon />
          <Gap size={12} />
          <FlexBlock flexDirection="column" flex={1}>
            <Typography.Text>Run against Podcasts?</Typography.Text>
          </FlexBlock>
          <CheckBox
            checked={pitch.runAgainstPodcasts}
            onChange={handlePodcastCheckboxClick}
          />
        </FlexBlock>
      </S.SettingsSection>
    );
  }

  if (isErrorAvailableFilters) {
    return (
      <FlexBlock margin="2em" flexDirection="column">
        <Typography.Text $dmSans $bold $size={18}>
          Something went wrong
        </Typography.Text>
        <Typography.Text>Could not load filter options</Typography.Text>
      </FlexBlock>
    );
  }

  return (
    <>
      <S.SettingsSection>
        <IfHasPermission require={userPermissionEnum.PITCH_COUNTRIES_ACCESS}>
          <InfoTip tooltipText={GEOGRAPHY_TOOLTIP}>
            <Typography.Text $colorName="orbit" $dmSans $bold $size={16}>
              Geography
            </Typography.Text>
          </InfoTip>
          <Typography.Text
            $colorName="steel"
            $size={14}
            $lineHeight={16}
            $margin="0 0 12px 8px"
          >
            Country*
          </Typography.Text>

          <MultiSelect
            options={availableFilters?.countryTypes || []}
            selectedOptions={pitch.countries || []}
            onUpdate={handleCountryUpdate}
            icon={<GlobeIcon />}
            placeholder="Select Countries"
            zIndex={16}
            maxMenuHeight={350}
            isFetchingOptions={
              isFetchingAvailableFilters || isLoadingAvailableFilters
            }
          />

          {displayStates && (
            <IfHasPermission require={userPermissionEnum.SOURCE_4_ACCESS}>
              <Typography.Text
                $colorName="steel"
                $size={14}
                $lineHeight={16}
                $margin="16px 0 12px 8px"
              >
                States (AUS, CA, UK, and US only)
              </Typography.Text>
              <MultiSelect
                options={availableFilters?.stateTypes || []}
                selectedOptions={pitch.states || []}
                onUpdate={handleCountryStateUpdate}
                icon={<LocationIcon />}
                placeholder="Select States"
                zIndex={16}
                maxMenuHeight={350}
                searchable
                isFetchingOptions={
                  isFetchingAvailableFilters || isLoadingAvailableFilters
                }
              />
            </IfHasPermission>
          )}
        </IfHasPermission>
        <IfHasPermission require={userPermissionEnum.PITCH_PODCASTS_ACCESS}>
          <FlexBlock
            padding="30px 8px 24px"
            disabled={!includesCountryWithPodcastsAvailable}
            flexDirection="row"
            alignItems="center"
          >
            <PodcastLogoIcon />
            <Gap size={12} />
            <FlexBlock flexDirection="column" flex={1}>
              <InfoTip
                tooltipText="If the country you select has podcasts then this option will be available."
                padding="0"
              >
                <Typography.Text $margin="0 10px 0 0">
                  Run against Podcasts?
                </Typography.Text>
              </InfoTip>
            </FlexBlock>

            <CheckBox
              checked={pitch.runAgainstPodcasts}
              onChange={handlePodcastCheckboxClick}
            />
          </FlexBlock>
        </IfHasPermission>
      </S.SettingsSection>
      <CustomMediaListsSelector />
    </>
  );
};

export default SettingsBar;
