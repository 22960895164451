import { useGetAvailableUserRolesQuery } from 'app/api';

export const useAvailableUserRoles = () => {
  const {
    data: availableUserRoles,
    isError,
    isLoading,
  } = useGetAvailableUserRolesQuery();

  return {
    availableUserRoles,
    isError,
    isLoading,
  };
};
