import { FC } from 'react';
import * as S from './MainGrid.styles';

interface IProps {
  minContent?: boolean;
  fullHeight?: boolean;
  gridColumnStart?: number;
  gridColumnEnd?: number;
  fullWidth?: boolean;
  noGrid?: boolean;
  marginBottom?: number;
}

const MainGrid: FC<IProps> = ({
  children,
  minContent,
  fullHeight,
  gridColumnEnd,
  gridColumnStart,
  fullWidth,
  noGrid,
  marginBottom,
}) => {
  return (
    <S.GridContainer
      $minContent={minContent}
      $fullHeight={fullHeight}
      gridColumnStart={gridColumnStart}
      gridColumnEnd={gridColumnEnd}
      fullWidth={fullWidth}
      noGrid={noGrid}
      $marginBottom={marginBottom}
    >
      {children}
    </S.GridContainer>
  );
};

export default MainGrid;

export const MainGridSection = S.MainGridSection;
