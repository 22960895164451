/**
 * Data Table.
 *
 * Table component which handles default api response IApiResponse as data. Handles pagination as well.
 *
 * @param {data}  data  table data.
 * @param {columns} columns table column configuration. array of ITableColumn type.
 * @param {pagination} pagination type of IPaginationRequestPart.
 * @param {onPaginationChange} onPaginationChange change callback.
 * @param {dataTypeName} dataTypeName how you want your data to be called in this table. Mainly is shown on empty data notice text and is used to save user preferences on LS.
 * @param {renderListItem} renderListItem grid item render function. Enables grid view option.
 * @param {onRowClick} onRowClick table item click callback
 * @param {showCount} showCount enables count in table header
 * @param {emptyNoticeConfig} emptyNoticeConfig configuration of no-data notice
 * @param {renderContainerHeader} renderContainerHeader render function for table header.
 */

import { ReactNode, useEffect, useMemo, useState } from 'react';
import { IPaginationRequestPart, ITableData } from '../../api.types';
import { Row, useTable } from 'react-table';
import * as S from './Table.styles';
import { TablePagination } from './parts/Pagination';
import PageHeader from '../UI/PageHeader';
import ListViewIcon from '../../assets/icons/ListViewIcon';
import GridViewIcon from '../../assets/icons/GridViewIcon';
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon';
import Select from '../UI/Select/Select';
import { pageSizeOptions } from './Table.constants';
import Typography from '../Typography';
import { IOption } from '../../../types/commonTypes';
import PageLoader from '../UI/PageLoader';
import {
  getStoredViewTypeSelection,
  storeViewTypeSelection,
  tableViewModeEnum,
} from './Table.helpers';
import { scrollMainContentToTop } from '../../../utils/helpers';
import EmptyDataNotice, {
  IEmptyDataNoticeProps,
} from '../EmptyDataNotice/EmptyDataNotice';
import { IDataTableColumn } from './Table.types';
import FlexBlock from '../UI/FlexBlock';
import TableSkeleton from '../UI/LoadingSkeletons/Tables/TableSkeleton';

interface IProps<DataItemType> {
  dataTypeName: string;
  data?: ITableData<DataItemType[]>;
  columns: any[];
  pagination: IPaginationRequestPart;
  onPaginationChange: (arg1: IPaginationRequestPart) => void;
  isDataLoading?: boolean;
  renderListItem?: (item: DataItemType) => ReactNode;
  onRowClick?: (item: DataItemType) => void;
  showCount?: boolean;
  emptyNoticeConfig?: IEmptyDataNoticeProps;
  showHeaderOnEmptyData?: boolean;
  renderContainerHeader?: () => ReactNode;
}

const DataTable = <DataItem,>(props: IProps<DataItem>) => {
  const gridViewTypeEnabled = useMemo(
    () => Boolean(props.renderListItem),
    [props.renderListItem],
  );

  const [viewMode, setViewMode] = useState<tableViewModeEnum>(
    gridViewTypeEnabled
      ? getStoredViewTypeSelection(props.dataTypeName)
      : tableViewModeEnum.table,
  );

  useEffect(() => {
    storeViewTypeSelection(props.dataTypeName, viewMode);
  }, [viewMode]);

  useEffect(() => {
    handlePageNumberChange(0);
  }, [props.pagination.page.size]);

  const emptyNoticeProps: IEmptyDataNoticeProps = useMemo(() => {
    if (props.emptyNoticeConfig) return props.emptyNoticeConfig;
    return {
      title: `No ${props.dataTypeName}`,
    };
  }, [props.dataTypeName, props.emptyNoticeConfig]);

  const columns = useMemo(() => props.columns, [props.columns]);

  const tableInstance = useTable({
    columns,
    // @ts-ignore
    data: props.data?.content || [],
  });

  const sortOptions = useMemo<IOption<string>[]>(() => {
    return props.columns
      .filter(c => !c.disableSort)
      .map(column => ({
        value: column.id,
        label: column.Header,
      }));
  }, [props.columns]);

  const activeSortOption = useMemo<IOption<string> | undefined>(() => {
    return (
      sortOptions.find(
        opt => opt.value === props.pagination.sort[0]?.property,
      ) || undefined
    );
  }, [props.columns, props.pagination]);

  const handlePageNumberChange = (page: number) => {
    props.onPaginationChange({
      ...props.pagination,
      page: {
        ...props.pagination.page,
        page,
      },
    });
    scrollMainContentToTop();
  };

  const handleItemsPerPageChange = (value: unknown) => {
    const option = value as IOption<number>;
    if (option.value) {
      props.onPaginationChange({
        ...props.pagination,
        page: {
          ...props.pagination.page,
          size: option.value,
        },
      });
    }
  };

  const handleSortChange = (value: unknown) => {
    const option = value as IOption<string>;

    const { isSortedAsc } = getTdSortProps(option.value);
    props.onPaginationChange({
      ...props.pagination,
      sort: [{ property: option.value, ascending: !isSortedAsc }],
    });
  };

  const handleSortClick = (column: IDataTableColumn<DataItem>) => () => {
    if (column.disableSort || !column?.id) return;
    const { isSortedAsc } = getTdSortProps(column.id);
    props.onPaginationChange({
      ...props.pagination,
      sort: [{ property: column.id, ascending: !isSortedAsc }],
    });
  };

  const getTdSortProps: (arg1: string) => {
    isSorted: boolean;
    isSortedAsc: boolean;
  } = (propName: string) => {
    const columnSort = props.pagination.sort.find(s => s.property === propName);
    if (columnSort) {
      return { isSorted: true, isSortedAsc: columnSort.ascending };
    }
    return { isSorted: false, isSortedAsc: false };
  };

  const activePageSizeOption = useMemo(() => {
    return (
      pageSizeOptions.find(pso => pso.value === props.pagination.page.size) ||
      null
    );
  }, [props.pagination]);

  const handleRowClick = (row: Row) => () => {
    if (props.onRowClick) {
      // @ts-ignore
      props.onRowClick(row.original);
    }
  };

  const isSortEnabled = (columnId: string) => {
    const column = props.columns.find(c => c.id === columnId);
    return !column?.disableSort;
  };

  if (
    props.data?.content.length === 0 &&
    !props.isDataLoading &&
    !props.showHeaderOnEmptyData
  ) {
    return <EmptyDataNotice {...emptyNoticeProps} />;
  }

  const commonSelectStyleProps = {
    minWidth: '178px',
    background: 'transparent',
    fontSize: 14,
    controlPadding: '10.5px 12px',
  };

  return (
    <>
      <PageHeader.Wrapper noBorder>
        <PageHeader.Section $padding="0 24px" flex="unset">
          {gridViewTypeEnabled && (
            <>
              <S.ViewSwitchButton
                active={viewMode === tableViewModeEnum.grid}
                onClick={() => setViewMode(tableViewModeEnum.grid)}
              >
                <GridViewIcon />
              </S.ViewSwitchButton>
              <S.ViewSwitchButton
                active={viewMode === tableViewModeEnum.table}
                onClick={() => setViewMode(tableViewModeEnum.table)}
              >
                <ListViewIcon />
              </S.ViewSwitchButton>
            </>
          )}
          {props.renderContainerHeader && props.renderContainerHeader()}
          {props.showCount && (
            <>
              <Typography.Text $dmSans $bold $size={16}>
                {props.dataTypeName}
              </Typography.Text>{' '}
              <S.CountPill>{props.data?.totalElements}</S.CountPill>
            </>
          )}
        </PageHeader.Section>
        <PageHeader.Section
          $padding="0 24px"
          justifyContent="flex-end"
          gridColumnGap="16px"
        >
          <Typography.Text
            $colorName="steel"
            $margin="0 0 0 16px"
            style={{ wordBreak: 'keep-all' }}
          >
            View:
          </Typography.Text>
          <Select<IOption<number>>
            {...commonSelectStyleProps}
            value={activePageSizeOption || undefined}
            options={pageSizeOptions}
            onChange={handleItemsPerPageChange}
          />
          {(viewMode === tableViewModeEnum.grid ||
            props.dataTypeName === 'Content') && (
            <>
              <Typography.Text $colorName="steel" $margin="0 0 0 16px">
                Sort By:
              </Typography.Text>
              <Select<IOption<string>>
                {...commonSelectStyleProps}
                value={activeSortOption}
                options={sortOptions}
                onChange={handleSortChange}
              />
            </>
          )}
        </PageHeader.Section>
      </PageHeader.Wrapper>

      <S.Wrapper noBackground={viewMode === tableViewModeEnum.grid}>
        <S.TableScrollWrapper>
          {viewMode === 'table' ? (
            <S.Table {...tableInstance.getTableProps()}>
              <S.THead>
                <S.Tr>
                  {tableInstance.headers.map(column => (
                    <S.Th
                      {...getTdSortProps(column.id)}
                      key={column.id}
                      onClick={handleSortClick(column)}
                    >
                      {column.render('Header')}
                      {isSortEnabled(column.id) && <ArrowUpIcon />}
                    </S.Th>
                  ))}
                </S.Tr>
              </S.THead>
              <S.TBody {...tableInstance.getTableBodyProps()}>
                {props.isDataLoading ? (
                  <TableSkeleton
                    columns={tableInstance.headers}
                    viewMode={tableViewModeEnum.table}
                  />
                ) : props.data?.content.length ? (
                  tableInstance.rows.map(row => {
                    tableInstance.prepareRow(row);
                    return (
                      <S.Tr
                        isEven={!Boolean(row.index % 2)}
                        hoverEffect
                        onClick={handleRowClick(row)}
                        key={row.id}
                      >
                        {row.cells.map(cell => (
                          <S.Td
                            key={`${cell.column.id}_${cell.row.id}_${cell.row.index}`}
                          >
                            {cell.render('Cell')}
                          </S.Td>
                        ))}
                      </S.Tr>
                    );
                  })
                ) : (
                  <S.Tr>
                    <S.Td colSpan={props.columns.length}>
                      <FlexBlock justifyContent="center" minWidth="100%">
                        <Typography.Text>
                          No {props.dataTypeName || 'data'} found
                        </Typography.Text>
                      </FlexBlock>
                    </S.Td>
                  </S.Tr>
                )}
              </S.TBody>
            </S.Table>
          ) : (
            <S.ListWrapper>
              {props.renderListItem ? (
                props.isDataLoading ? (
                  <TableSkeleton
                    columns={tableInstance.headers}
                    viewMode={tableViewModeEnum.grid}
                  />
                ) : (
                  props.data?.content.map(props.renderListItem)
                )
              ) : (
                'invalid component config. renderListItem is not provided'
              )}
            </S.ListWrapper>
          )}
        </S.TableScrollWrapper>
        {props.data && (
          <TablePagination
            pageInfo={props.data.pageable}
            onPageNumberChange={handlePageNumberChange}
            totalElements={props.data.totalElements}
          />
        )}
      </S.Wrapper>
    </>
  );
};

export default DataTable;
