import { FC, useMemo } from 'react';
import FlexBlock from '../../FlexBlock';
import { SkeletonDiv } from '../Skeletons.styles';
import ProjectDisplaySkeleton from './ProjectDisplaySkeleton';

interface IProjectCardsSkeletonProps {}

const CardSkeleton: FC = () => {
  const projectsCount = useMemo(() => {
    return Math.floor(Math.random() * 5) + 1;
  }, []);

  return (
    <FlexBlock flexDirection="column" minWidth="280px">
      <ProjectDisplaySkeleton />
      {Array.from({ length: projectsCount }).map((_, idx) => (
        <FlexBlock
          flexDirection="row"
          justifyContent="space-between"
          minWidth="100%"
          alignItems="center"
          key={idx}
        >
          <SkeletonDiv
            height="24px"
            width="50%"
            shimmerAnimation
            borderRadius="8px"
          />
          <SkeletonDiv
            height="32px"
            width="32px"
            shimmerAnimation
            borderRadius="50%"
          />
        </FlexBlock>
      ))}
    </FlexBlock>
  );
};

const ProjectCardsSkeleton: FC<IProjectCardsSkeletonProps> = () => {
  return (
    <FlexBlock
      flexDirection="row"
      columnGap="40px"
      style={{ width: '90%' }}
      margin="20px"
    >
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </FlexBlock>
  );
};

export default ProjectCardsSkeleton;
