import { useGetBlogContentVersionQuery, useGetBlogQuery } from 'app/api';
import { IBlogGenerationPayload, IBlogGenerationResponse } from 'app/api.types';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getToneObjectByValue } from '../BlogPage.helpers';

interface IBlogDraft {
  generatedResponse: IBlogGenerationResponse;
  generationPayload: IBlogGenerationPayload;
}

export const useBlogData = () => {
  const params: {
    tenantId: string;
    projectId: string;
    blogId: string;
    versionId: string;
  } = useParams();

  const [tenantId, projectId, blogId, versionId] = useMemo(
    () => [
      Number(params.tenantId),
      Number(params.projectId),
      Number(params.blogId),
      Number(params.versionId),
    ],
    [params],
  );

  const location = useLocation<IBlogDraft | undefined>();
  const draft = location.state;
  const isDraft = Boolean(draft);

  const {
    data: blogData,
    isError: isBlogError,
    isLoading: isBlogLoading,
  } = useGetBlogQuery({ blogId }, { skip: !blogId });

  const blogContentId = useMemo(() => {
    if (!blogData) return undefined;
    if (blogData.blogContents) {
      return blogData.blogContents.find(
        content => content.version === versionId,
      )?.id;
    }
  }, [blogData, versionId]);

  const blog = useMemo(() => {
    if (blogData) return blogData;
    return undefined;
  }, [blogData]);

  const {
    data: blogContent,
    isError: isBlogContentError,
    isLoading: isBlogContentLoading,
  } = useGetBlogContentVersionQuery(
    { blogContentId },
    { skip: !blogData || !blogContentId },
  );

  const blogTitle = useMemo(() => {
    if (draft) {
      if (draft.generatedResponse && draft.generatedResponse.generatedTitle) {
        return draft.generatedResponse.generatedTitle;
      }
    }

    return undefined;
  }, [draft]);

  const payload = useMemo(() => {
    if (draft && draft.generationPayload) {
      return draft.generationPayload;
    }

    if (blogContent) {
      return {
        blogTopic: blogContent.blogTopic,
        tone: blogContent.tone,
        productKeywords: blogContent.productKeywords,
      } as IBlogGenerationPayload;
    }

    return undefined;
  }, [draft, blogContent]);

  const content = useMemo(() => {
    if (draft) {
      return {
        body: draft?.generatedResponse.generatedBody,
        title: draft?.generatedResponse.generatedTitle,
      };
    }

    if (blogContent) {
      return {
        body: blogContent.body,
        title: blogContent.title,
      };
    }

    return undefined;
  }, [draft, blogContent]);

  const tone = useMemo(() => {
    if (draft) {
      return draft.generationPayload.tone
        ? getToneObjectByValue(draft.generationPayload.tone)
        : undefined;
    }

    if (blogContent) {
      return blogContent?.tone
        ? getToneObjectByValue(blogContent.tone)
        : undefined;
    }
  }, [draft, blogContent]);

  return {
    blogId,
    tenantId,
    tone,
    versionId,
    projectId,
    isDraft,
    blogTitle,
    payload,
    content,
    blog,
    isBlogContentLoading,
    isBlogLoading,
    isError: isBlogError || isBlogContentError,
  };
};
