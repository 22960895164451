/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
// import './locales/i18n';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';

// Metrics
import './utils/apm/logger';
import { version } from '../package.json';
import { ErrorBoundary } from 'react-error-boundary';
import { FallbackPage } from 'app/components/FallbackPage/FallbackPage';

console.log(`%cProphet Platform`, 'color: #1D76DE; font-size: 24px');
console.log(`%cVersion: ${version}`, 'color: #00CAFF; font-size: 16px');

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <React.StrictMode>
          <ErrorBoundary
            fallbackRender={({ error }: { error: Error }) => {
              return <FallbackPage error={error} />;
            }}
          >
            <App />
          </ErrorBoundary>
        </React.StrictMode>
      </HelmetProvider>
    </ThemeProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
