import { api } from 'app/api';
import {
  GetPredictionReportByIdPayload,
  IReportV31,
  PredictionReportActiveFilters,
} from 'app/api.types';
import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { ISort } from 'types/commonTypes';
import { showErrorToast } from 'utils/toast';
import {
  getLimitOptionsByTotalItemsCount,
  ILimitOption,
} from '../MediaReportCard.constants';
import {
  getBestPageSize,
  isGetPredictionReportByIdCached,
  splitCountToPages,
} from '../MediaReportCard.helpers';

const initialSort: ISort = {
  ascending: false,
  property: 'reportItem.certainty',
};

export const useLoadReportData = ({
  initialReport,
  appliedFilters,
  pitchId,
  predictionId,
  reportId,
  isPredictionFetching,
}: {
  pitchId: number;
  predictionId: number;
  reportId: number;
  isPredictionFetching?: boolean;
  initialReport: IReportV31;
  appliedFilters: PredictionReportActiveFilters | undefined;
}) => {
  const dispatch = useDispatch();

  // State to track different loading scenarios
  const [isFetchingMoreItems, setIsFetchingMoreItems] = useState(false);
  const [isFetchingAllItems, setIsFetchingAllItems] = useState(false);

  // Initial sort parameter matches the default sort parameter in the BE
  const [sort, setSort] = useState<ISort>(initialSort);

  const limitOptions = getLimitOptionsByTotalItemsCount(
    initialReport.reportItemsPage.totalElements,
  );

  const [limit, setLimit] = useState<ILimitOption>(limitOptions[0]);

  const [report, setReport] = useState<IReportV31>(initialReport);
  const store = useStore();

  const [lazyGetPredictionReportById, { isFetching: isFetchingReport }] =
    api.useLazyGetPredictionReportByIdQuery();

  useEffect(() => {
    if (!isFetchingReport) {
      setIsFetchingMoreItems(false);
      setIsFetchingAllItems(false);
    }
  }, [isFetchingReport]);

  const loadReportItems = async ({
    countToLoad = limit.value,
    filters = appliedFilters,
    sorter = sort,
    isFetchingMoreItems = false,
  }: {
    countToLoad?: number;
    filters?: { outletScopes?: string[]; categoriesIds?: number[] };
    sorter?: ISort;
    isFetchingMoreItems?: boolean;
  }) => {
    try {
      if (isFetchingMoreItems) {
        setIsFetchingMoreItems(true);
      } else {
        setIsFetchingAllItems(true);
      }

      // Update limit option
      const updatedLimitOption = limitOptions.find(
        opt => opt.value === countToLoad,
      );

      if (updatedLimitOption) {
        setLimit(updatedLimitOption);
      } else {
        showErrorToast('Limit option not found');
      }

      const isDefaultFilterAndSort =
        sorter.property === initialSort.property &&
        sorter.ascending === initialSort.ascending &&
        !filters;

      let loadedCount = isDefaultFilterAndSort
        ? initialReport.reportItemsPage.content.length
        : 0;

      // Guard clause to check if all report items are loaded already
      const totalItemsInReport = initialReport.reportItemsPage.totalElements;
      const isAllItemsLoaded = loadedCount === totalItemsInReport;

      const pagesToLoad: { page: number; size: number }[] = isAllItemsLoaded
        ? []
        : splitCountToPages({
            countToLoad,
            loadedCount,
          });

      const payloads: GetPredictionReportByIdPayload[] = pagesToLoad.map(
        ({ page, size }) => ({
          pitchId,
          predictionId,
          reportId,
          categoriesIds: filters?.categoriesIds,
          outletScopes: filters?.outletScopes,
          pagination: {
            page: {
              page,
              size,
            },
            sort: [sorter],
          },
        }),
      );

      let requestsToMake = 0;
      payloads.forEach(payload => {
        if (!isGetPredictionReportByIdCached(store, payload)) {
          requestsToMake++;
        }
      });

      // If requests count is more than 1 then load all in 1 request
      if (requestsToMake > 1) {
        const response = await lazyGetPredictionReportById(
          {
            ...payloads[0],
            pagination: {
              ...payloads[0].pagination,
              page: { page: 0, size: countToLoad },
            },
          },
          // preferCacheValue
          true,
        ).unwrap();

        setReport(response);

        // Split response to pages and save them
        const pagesToSave = splitCountToPages({ loadedCount: 0, countToLoad });
        let savedCount = 0;
        pagesToSave.forEach(({ page, size }, index) => {
          dispatch(
            api.util.upsertQueryData(
              'getPredictionReportById',
              {
                ...payloads[0],
                pagination: {
                  ...payloads[0].pagination,
                  page: { page, size },
                },
              },
              {
                ...response,
                reportItemsPage: {
                  ...response.reportItemsPage,
                  content: response.reportItemsPage.content.slice(
                    savedCount,
                    savedCount + size,
                  ),
                  last:
                    index === pagesToSave.length - 1
                      ? response.reportItemsPage.last
                      : false,
                },
              },
            ),
          );
          savedCount += size;
        });
      } else {
        const results = await Promise.all(
          payloads.map(payload =>
            lazyGetPredictionReportById(
              payload,
              // preferCacheValue
              true,
            ).unwrap(),
          ),
        );

        if (isDefaultFilterAndSort && !results.length) {
          setReport(initialReport);
          return;
        }

        if (isDefaultFilterAndSort) {
          results.unshift(initialReport);
        }

        setReport({
          ...results[results.length - 1],
          reportItemsPage: {
            ...results[results.length - 1].reportItemsPage,
            content: results.reduce(
              (acc, result) => [
                ...acc,
                // TODO: Fix type
                ...(result.reportItemsPage.content as any),
              ],
              // TODO: Fix type
              [] as any,
              // TODO: Fix type
            ) as any,
          },
        });
      }
    } catch (error) {
      console.error('error', error);
      showErrorToast('Failed to load report items');
    }
  };
  useEffect(() => {
    const isDefaultFilterAndSort =
      sort.property === initialSort.property &&
      sort.ascending === initialSort.ascending &&
      !appliedFilters;

    // If default filter and sort is applied then wait to initial report
    // with first 25 list items to be fetched
    // else load report items on report loading start
    if (
      (isDefaultFilterAndSort && !isPredictionFetching) ||
      (!isDefaultFilterAndSort && isPredictionFetching)
    ) {
      // If initial report is refetched, update paginated report items
      loadReportItems({ countToLoad: limit.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPredictionFetching]);

  const handleSortClick = (column: string) => {
    const updatedSort = {
      property: column,
      ascending:
        sort.property !== column ||
        (sort.property === column && !sort.ascending),
    };

    setSort(updatedSort);
    loadReportItems({ sorter: updatedSort });
  };

  const onSeeMoreClick = async () => {
    if (isFetchingReport) {
      return;
    }

    const size = getBestPageSize(report.reportItemsPage.content.length);

    loadReportItems({
      countToLoad: report.reportItemsPage.content.length + size,
      isFetchingMoreItems: true,
    });
  };

  const onLimitChange = async (opt: ILimitOption) => {
    loadReportItems({ countToLoad: opt.value, isFetchingMoreItems: true });
  };

  return {
    loadReportItems,
    report,
    isFetchingReport,
    isFetchingMoreItems,
    isFetchingAllItems,
    handleSortClick,
    sort,
    limitOptions,
    limit,
    onLimitChange,
    onSeeMoreClick,
  };
};
