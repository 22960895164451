import Button from 'app/components/UI/Button/Button';
import { FC } from 'react';
import { SettingsBarFooter } from 'app/containers/Pitch/pages/CreateOrEditPitch/CreateOrEditPitchPage.styles';
import { usePressReleaseDispatch } from '../../context/PressReleaseContext';
import { PressReleaseStateActions } from '../../CreateOrEditPressRelease.constants';

const PredictPanelFooter: FC = ({ children }) => {
  const dispatch = usePressReleaseDispatch();

  const handleClose = () => {
    dispatch({
      type: PressReleaseStateActions.TOGGLE_SETTINGS,
    });
  };

  return (
    <SettingsBarFooter>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      {children}
    </SettingsBarFooter>
  );
};

export default PredictPanelFooter;
