import { Dispatch, SetStateAction, useMemo } from 'react';

export const useContentTypeSelectorMenu = (
  options: {
    key: string;
    displayName: string;
    count: number;
    checked?: boolean;
  }[],
  setOptions: Dispatch<
    SetStateAction<
      {
        key: string;
        displayName: string;
        count: number;
        checked?: boolean;
      }[]
    >
  >,
) => {
  const isNotAllSelected = useMemo(() => {
    return options.some(option => !option.checked);
  }, [options]);

  const isOneOrMoreSelected = useMemo(() => {
    return options.some(option => option.checked);
  }, [options]);

  const handleOptionCheck = (key: string, checked: boolean) => {
    setOptions(prevState =>
      prevState.map(option =>
        option.key === key ? { ...option, checked: checked } : option,
      ),
    );
  };

  const handleAllOptionsCheck = (checked: boolean) => {
    setOptions(prevState =>
      prevState.map(option => {
        return { ...option, checked: option.count > 0 ? checked : false };
      }),
    );
  };

  return {
    conditions: { isNotAllSelected, isOneOrMoreSelected },
    actions: { handleOptionCheck, handleAllOptionsCheck },
  };
};
