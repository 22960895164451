import {
  api,
  useGetPredictionQuery,
  useLazyGetEmailSuggestionForReportItemQuery,
} from '../../../../../../../api';
import useHasPermission from '../../../../../../Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from '../../../../../../Global/permissions/userPermissions.enum';
import { useEffect, useMemo } from 'react';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
  ToneValueEnum,
} from '../../ToneSelectDropDown/ToneSelectDropDown.types';
import { IAuthorEmailSuggestionResponse } from '../../../../../../../api.types';
import { useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import {
  selectContentGenerationTone,
  selectContentGenerationToneLength,
  selectDigDeeperReportItem,
  selectForceRegenerateEmailContent,
} from '../../../../../slice/selectors';
import { useGenerationLimits } from '../../../hooks/useGenerationLimits';
import {
  showCustomToast,
  showErrorToast,
} from '../../../../../../../../utils/toast';
import { GenerateSuccessToastContent } from '../../../CreateOrEditPitch.constants';
import { usePitchSlice } from '../../../../../slice';
import {
  CONTENT_LENGTH_SELECT_OPTIONS,
  TONE_SELECT_OPTIONS,
} from '../../ToneSelectDropDown/ToneSelectDropDown.config';

interface IHookProps {
  isPodcast: boolean | undefined;
  authorId: string | number;
  targetMediaType?: string;
  digDeeperView?: boolean;
}
type TriggerFunction = (
  forceRefetch?: boolean,
  tone?: IToneSelectOption,
  toneLength?: IContentLengthSelectOption,
) => void;

interface IHookResponse {
  data?: IAuthorEmailSuggestionResponse;
  exist: boolean;
  hasPermission: boolean;
  loading: boolean;
  trigger: TriggerFunction;
}

const useGeneratedEmailContent = (props: IHookProps) => {
  const params =
    useParams<{ pitchId: string; pressReleaseId: string; versionId: string }>();
  const reportItem = useAppSelector(selectDigDeeperReportItem);
  const tone = useAppSelector(selectContentGenerationTone);

  const toneLength = useAppSelector(selectContentGenerationToneLength);

  const limits = useGenerationLimits();
  const dispatch = useAppDispatch();
  const pitchSlice = usePitchSlice();
  const useForcedRefetch = useAppSelector(selectForceRegenerateEmailContent);

  const setUseForceRefetch = (force: boolean) => {
    dispatch(pitchSlice.actions.setForceRegenerateEmailContent(force));
  };

  const contentParams: {
    contentId: number;
    contentType: 'PRESS_RELEASE' | 'PITCH';
  } = useMemo(() => {
    if (params.pressReleaseId) {
      return {
        contentId: Number(params.pressReleaseId),
        contentType: 'PRESS_RELEASE',
      };
    }

    return {
      contentId: Number(params.pitchId),
      contentType: 'PITCH',
    };
  }, [params]);

  const { data: pitchPredictionData, refetch: refetchPredictionData } =
    useGetPredictionQuery(
      {
        pitchId: contentParams.contentId,
        versionId: Number(params.versionId),
        predictionType: contentParams.contentType,
      },
      { skip: reportItem === null },
    );

  const hasPermissionToGenerateEmailContent = useHasPermission(
    userPermissionEnum.GENERATE_AUTHOR_EMAIL_CONTENT,
  );

  const [generateEmailContent] = useLazyGetEmailSuggestionForReportItemQuery();

  const hasPreGeneratedContent = useMemo(() => {
    return (
      hasPermissionToGenerateEmailContent &&
      Boolean(
        Object.keys(reportItem?.generatedContentStatus || {}).some(key => {
          const tone = key as ToneValueEnum;
          if (reportItem?.generatedContentStatus) {
            return (
              reportItem?.generatedContentStatus[tone].syncLogStatusType !==
              'FAILED'
            );
          } else {
            return false;
          }
        }),
      )
    );

    // return (
    //   hasPermissionToGenerateEmailContent &&
    //   !!reportItem?.generatedContentStatus &&
    //   !!reportItem?.generatedContentStatus[tone.value]
    // );
  }, [reportItem, hasPermissionToGenerateEmailContent, tone, toneLength]);

  const findAvailableTone = (): {
    toneOption: IToneSelectOption;
    lengthOption: IContentLengthSelectOption;
  } | null => {
    let availableToneValue: { tone: string; length: string } | null = null;
    if (reportItem?.generatedContentStatus) {
      Object.keys(reportItem.generatedContentStatus).some((objKey: string) => {
        const key = objKey as ToneValueEnum;
        if (
          reportItem?.generatedContentStatus &&
          reportItem?.generatedContentStatus[key] &&
          reportItem?.generatedContentStatus[key].syncLogStatusType !== 'FAILED'
        ) {
          availableToneValue = {
            length:
              reportItem?.generatedContentStatus[key].authorEmailLengthType,
            tone: key,
          };
          return true;
        }
      });
    }

    if (!availableToneValue) return null;

    const toneOption = TONE_SELECT_OPTIONS.find(
      t => t.value === availableToneValue?.tone,
    );

    const lengthOption = CONTENT_LENGTH_SELECT_OPTIONS.find(
      t => t.value === availableToneValue?.length,
    );

    if (!toneOption || !lengthOption) return null;
    return { toneOption, lengthOption };
  };

  const requestBody = {
    bodyCopy: pitchPredictionData?.body || '',
    title: pitchPredictionData?.headline || '',
    tone: tone.value,
    authorEmailLength: toneLength.value,
    targetMediaType: props.targetMediaType
      ? props.targetMediaType
      : props.isPodcast
      ? 'PODCAST'
      : 'BRAND',
    authorId: String(props.authorId),
    podcastId: props.isPodcast ? reportItem?.podcastId : undefined,
    reportItemId: Number(reportItem?.id) || 0,
    regenerate: !hasPreGeneratedContent || useForcedRefetch,
  };

  const trigger: TriggerFunction = async (forceRefetch, tone, toneLength) => {
    if (pitchPredictionData) {
      setUseForceRefetch(!!forceRefetch);
      if (tone) dispatch(pitchSlice.actions.setContentGenerationTone(tone));

      if (toneLength)
        dispatch(pitchSlice.actions.setContentGenerationLengthTone(toneLength));

      try {
        await generateEmailContent(
          {
            ...requestBody,
            regenerate: !!forceRefetch ? true : requestBody.regenerate,
            tone: tone ? tone.value : requestBody.tone,
            authorEmailLength: toneLength
              ? toneLength.value
              : requestBody.authorEmailLength,
          },
          !forceRefetch,
        ).unwrap();
        if (forceRefetch || requestBody.regenerate) {
          refetchPredictionData();
          showCustomToast(
            <GenerateSuccessToastContent
              limit={limits.contentSuggestionsLeft - 1}
            />,
            'toast-violet',
          );
        }
      } catch (e) {
        showErrorToast('Failed to generate content');
      } finally {
        limits.refresh();
      }
    }
  };

  const state =
    api.endpoints.getEmailSuggestionForReportItem.useQueryState(requestBody);

  useEffect(() => {
    if (
      hasPreGeneratedContent &&
      props.authorId &&
      props.authorId !== 'undefined' &&
      !state.currentData
    ) {
      const optionParameters = findAvailableTone();
      setUseForceRefetch(false);
      trigger(
        false,
        optionParameters?.toneOption || undefined,
        optionParameters?.lengthOption,
      );
    }
  }, [hasPreGeneratedContent, pitchPredictionData, props.authorId]);

  return {
    exist: hasPreGeneratedContent,
    data: state.currentData,
    loading: state.isFetching,
    hasPermission: hasPermissionToGenerateEmailContent,
    trigger,
  } as IHookResponse;
};

export default useGeneratedEmailContent;
