import RemoveIcon from 'app/assets/icons/RemoveIcon';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import { FC, useState } from 'react';
import { TONE_SELECT_OPTIONS } from '../ToneSelectDropDown/ToneSelectDropDown.config';
import { formatDate } from 'utils/helpers';

import * as S from './ContentVersionsBar.styles';
import { Anchor } from 'app/components/UI/Anchor';
import Modal from '../../../../../../components/UI/Modal/Modal';
import WarningIcon from 'app/assets/icons/WarningIcon';
import {
  useGetGeneratedContentHistoryQuery,
  useLazyGetGeneratedContentFromHistoryQuery,
} from 'app/api';
import PageLoader from 'app/components/UI/PageLoader';
import { showErrorToast } from 'utils/toast';
import { PitchStateActions, usePitch, usePitchDispatch } from '../PitchContext';
import SideDrawer from 'app/components/UI/SideDrawer/SideDrawer';

interface IProps {
  pitchId: number;
}

const findOptionByValue = (value: string) => {
  const option = TONE_SELECT_OPTIONS.find(option => option.value === value);
  if (!option) return undefined;
  if (option) return { image: option.icon, displayName: option.name };
};

const ContentVersionsBar: FC<IProps> = ({ pitchId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [historyItemsToShow, setHistoryItemsToShow] = useState(10);

  const dispatch = usePitchDispatch();

  const { versionsOpen } = usePitch();

  const handleRestoreClick = (id: number) => {
    setSelectedItem(id);
    setModalOpen(true);
  };

  const [getContentFromHistory, { isLoading: isLoadingItemHistory }] =
    useLazyGetGeneratedContentFromHistoryQuery();

  const handleRestoreConfirm = async () => {
    if (!selectedItem) return;

    try {
      const contentFromHistory = await getContentFromHistory({
        pitchId,
        contentId: selectedItem,
      }).unwrap();

      dispatch({
        type: PitchStateActions.UPDATE_PITCH_CONTENT,
        payload: {
          contentType: 'generated',
          data: {
            contentName: 'generated',
            headline: contentFromHistory.generatedTitle || '',
            body: contentFromHistory.articleByline || '',
            socialPosts: {
              twitterPost: contentFromHistory.twitterPost || '',
              facebookPost: contentFromHistory.facebookPost || '',
              instagramPost: contentFromHistory.instagramPost || '',
              linkedinPost: contentFromHistory.linkedinPost || '',
            },
          },
        },
      });
      setModalOpen(false);
    } catch (error) {
      showErrorToast('Could not load content history.');
      setModalOpen(false);
    }
  };

  const {
    data: contentHistory,
    isLoading,
    isError,
  } = useGetGeneratedContentHistoryQuery(
    { pitchId, size: historyItemsToShow },
    {
      skip: !pitchId,
    },
  );

  const onSeeMoreClick = () => {
    if (contentHistory && historyItemsToShow < 30) {
      setHistoryItemsToShow(prev => prev + 10);
    }
  };

  const handleClose = () => {
    dispatch({ type: PitchStateActions.TOGGLE_VERSIONS, payload: false });
  };

  return (
    <>
      <SideDrawer open={versionsOpen}>
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          padding="24px"
        >
          <FlexBlock flexDirection="column">
            <Typography.Text
              $size={20}
              $lineHeight={24}
              $colorName="onyx"
              $bold
              $dmSans
            >
              Taylor History
            </Typography.Text>
            <Gap size={4} />
            <Typography.Text $size={14} $lineHeight={24} $colorName="steel">
              Previously generated content
            </Typography.Text>
          </FlexBlock>
          <S.FlexItemAligned>
            <Button variant="icon-button-border" onClick={handleClose}>
              <RemoveIcon />
            </Button>
          </S.FlexItemAligned>
        </FlexBlock>
        {isLoading && <PageLoader />}
        {isError && (
          <Typography.Text>
            Could not load content history. Try again later.
          </Typography.Text>
        )}
        {contentHistory && (
          <FlexBlock flexDirection="column">
            {contentHistory.content.map(item => {
              const option = findOptionByValue(item.tone);

              if (!option) return <></>;

              return (
                <S.ContentItem key={item.id}>
                  <FlexBlock flexDirection="column">
                    <S.TonePill>
                      <img
                        alt={option.displayName}
                        width={24}
                        height={24}
                        src={option.image}
                      />
                      <Gap size={8} />
                      <Typography.Text
                        $dmSans
                        $bold
                        $size={14}
                        $lineHeight={16}
                        $colorName="nero"
                      >
                        {option.displayName}
                      </Typography.Text>
                    </S.TonePill>
                    <Typography.Text
                      $size={14}
                      $lineHeight={16}
                      $colorName="onyx"
                      textAlign="left"
                    >
                      Generated By:{' '}
                      <Typography.Text
                        $bold
                        $size={14}
                        $lineHeight={16}
                        $colorName="onyx"
                        textAlign="left"
                      >
                        {`${item.user.firstName} ${item.user.lastName}`}
                      </Typography.Text>
                    </Typography.Text>
                    <Gap size={2} />
                    <Typography.Text
                      $colorName="steel"
                      $size={14}
                      $lineHeight={16}
                    >
                      {formatDate(item.createdAt)}
                    </Typography.Text>
                  </FlexBlock>
                  <FlexBlock alignItems="center">
                    <Anchor onClick={() => handleRestoreClick(item.id)}>
                      Restore
                    </Anchor>
                  </FlexBlock>
                </S.ContentItem>
              );
            })}
            {historyItemsToShow < 30 &&
              contentHistory &&
              contentHistory.size < contentHistory.totalElements && (
                <FlexBlock
                  padding="16px 0"
                  justifyContent="center"
                  minWidth="100%"
                >
                  <Button
                    variant="secondary"
                    compact
                    wide
                    onClick={onSeeMoreClick}
                  >
                    See More
                  </Button>
                </FlexBlock>
              )}
          </FlexBlock>
        )}
      </SideDrawer>
      <Modal open={modalOpen}>
        <FlexBlock flexDirection="column" alignItems="center" padding="40px">
          <S.ContentVersionChangeWarningWrapper>
            <WarningIcon size={48} fill="black" />
          </S.ContentVersionChangeWarningWrapper>
          <Typography.Title>Restore Content</Typography.Title>
          <Typography.Text $colorName="onyx" $size={14} $lineHeight={24}>
            Are you sure you want to restore this generated content?
          </Typography.Text>
          <Typography.Text $colorName="onyx" $size={14} $lineHeight={24}>
            This will replace the existing generated content.
          </Typography.Text>
          <FlexBlock flexDirection="row" margin="20px 0 0 0">
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Gap size={12} />
            <Button
              onClick={handleRestoreConfirm}
              isLoading={isLoadingItemHistory}
              disabled={isLoadingItemHistory}
            >
              Restore
            </Button>
          </FlexBlock>
        </FlexBlock>
      </Modal>
    </>
  );
};

export default ContentVersionsBar;
