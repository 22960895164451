import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as S from './AutoComplete.styles';
import SearchIcon from '../../assets/icons/SearchIcon';
import usePortal from '../../../utils/hooks/usePortal';
import ClickAwayListener from 'react-click-away-listener';
import { Spinner } from '../UI/Spinner';
import CarrotDownIcon from '../../assets/icons/CarrotDownIcon';
import Typography from '../Typography';
import ListViewIcon from '../../assets/icons/ListViewIcon';
import useAutoCompleteMenu from './hooks/useAutoCompleteMenu';

export type AutoCompleteOptionRenderFunction<OptionType> = (
  option: OptionType,
  index: number,
  arr: OptionType[],
  onClick: () => void,
) => ReactNode;

interface IProps<OptionType> {
  placeholder?: string;
  options: OptionType[];
  displayAccessor: string;
  onOptionSelect: (option: OptionType) => void;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  doNotUsePortal?: boolean;
  optionRenderFunction?: AutoCompleteOptionRenderFunction<OptionType>;
  loading?: boolean;
  renderSectionAboveInput?: () => ReactNode;
  renderCustomOption?: () => ReactNode;
  icon?: 'left-search' | 'right-carrot' | 'list-view' | 'no-icon';
  readOnlyInput?: boolean;
  disabled?: boolean;
  $maxWidth?: string;
  noOptionsText?: string;
  selectorHeight?: number;
  menuHeight?: number;
  menuPadding?: string;
  maxMenuHeight?: number;
  onMenuBlur?: () => void;
  onMenuFocus?: () => void;
  wrapperBorderColor?: string;
  wrapperStyles?: CSSProperties;
  closeMenuAfterOptionSelect?: boolean;
}

const AutoComplete = <OptionType,>({
  icon = 'left-search',
  ...props
}: IProps<OptionType>) => {
  const { renderInPortal } = usePortal('autocomplete-menu');

  const [menuOpen, setMenuOpen] = useAutoCompleteMenu(
    Boolean(props.closeMenuAfterOptionSelect),
    props.searchValue,
    Boolean(props.disabled),
    false,
    props.placeholder,
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const menuRenderer = useCallback(
    (node: ReactNode) => {
      if (props.doNotUsePortal) return node;
      return renderInPortal(node);
    },
    [props.doNotUsePortal, renderInPortal],
  );

  const onOptionSelect = (option: OptionType) => {
    props.onOptionSelect(option);
  };

  const defaultOptionRenderer = (option: OptionType) => (
    <S.Option
      // @ts-ignore
      key={option.id ? option.id : `${option[props.displayAccessor]}`}
      onClick={() => onOptionSelect(option)}
    >
      {option[props.displayAccessor as keyof OptionType]}
    </S.Option>
  );

  const renderOption = (item: OptionType, index: number, arr: OptionType[]) => {
    if (props.optionRenderFunction) {
      return props.optionRenderFunction(item, index, arr, () =>
        onOptionSelect(item),
      );
    } else {
      return defaultOptionRenderer(item);
    }
  };

  const onContainerClick = () => {
    if (props.disabled) return;
    inputRef.current?.focus();
    setMenuOpen(true);
  };

  const isEnoughSpaceForMenu: boolean = useMemo(() => {
    const bbox = wrapperRef.current?.getBoundingClientRect();
    const clientHeight = window.innerHeight;

    if (bbox) {
      return clientHeight - bbox.top > 400;
    }
    return true;
  }, []);

  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const calculateOptionsMenuPosition = () => {
    const wrapper = wrapperRef?.current;

    if (!wrapper) return;

    const top = wrapper.offsetTop;
    const left = wrapper.offsetLeft;
    const height = wrapper.offsetHeight;
    const width = wrapper.offsetWidth;

    setMenuPosition({
      top: top || 0,
      left: left || 0,
      width: width || 0,
      height: height || 0,
    });
  };

  useLayoutEffect(() => {
    calculateOptionsMenuPosition();
  }, [props.options]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (menuOpen) {
          setMenuOpen(false);
          if (props.onMenuBlur) props.onMenuBlur();
        }
      }}
    >
      <S.Wrapper
        ref={wrapperRef}
        onClick={onContainerClick}
        disabled={props.disabled}
        maxWidth={props.$maxWidth}
        style={{
          borderColor:
            props.wrapperBorderColor && !setMenuOpen
              ? props.wrapperBorderColor
              : undefined,
          ...(props.wrapperStyles ? props.wrapperStyles : {}),
        }}
      >
        <S.InnerWrapper>
          {icon === 'left-search' && (
            <S.IconContainer>
              <SearchIcon />
            </S.IconContainer>
          )}
          {icon === 'list-view' && (
            <S.IconContainer>
              <ListViewIcon size={20} />
            </S.IconContainer>
          )}
          <S.InputContainer>
            {props.renderSectionAboveInput && props.renderSectionAboveInput()}
            <S.Input
              style={{ background: 'inherit' }}
              placeholder={props.placeholder}
              value={props.searchValue}
              onChange={e => props.onSearchValueChange(e.target.value)}
              onFocus={() => {
                if (props.onMenuFocus) {
                  props.onMenuFocus();
                }
                setMenuOpen(true);
              }}
              ref={inputRef}
              readOnly={props.readOnlyInput}
              disabled={props.disabled}
            />
          </S.InputContainer>
          {icon === 'right-carrot' && !props.loading && (
            <S.IconContainer carrotStyling>
              <CarrotDownIcon />
            </S.IconContainer>
          )}
        </S.InnerWrapper>

        {menuOpen &&
          menuRenderer(
            <S.MenuWrapper
              portal={!props.doNotUsePortal}
              top={
                isEnoughSpaceForMenu ? menuPosition.top : menuPosition.top - 430
              }
              left={menuPosition.left}
              width={menuPosition.width}
              height={menuPosition.height}
              $menuHeight={props.menuHeight}
              $maxMenuHeight={props.maxMenuHeight}
            >
              <div style={{ overflowY: 'auto', maxHeight: '320px' }}>
                {!props.options.length &&
                !props.loading &&
                props.noOptionsText ? (
                  <Typography.Text textAlign="center" style={{ width: '100%' }}>
                    {props.noOptionsText}
                  </Typography.Text>
                ) : (
                  props.options.map(renderOption)
                )}
                {props.renderCustomOption && props.renderCustomOption()}
              </div>
            </S.MenuWrapper>,
          )}
        {props.loading && (
          <S.LoaderContainer>
            <Spinner $size="20px" $isBlue />
          </S.LoaderContainer>
        )}
      </S.Wrapper>
    </ClickAwayListener>
  );
};

export default AutoComplete;
export const AutoCompleteOption = S.Option;
