import { useUpdateUserMutation } from 'app/api';
import { showErrorToast, showSuccessToast } from 'utils/toast';

import {
  isSelectedTenantValid,
  getSelectedTenantId,
} from '../UserModal/UserModal.helpers';
import {
  IClassifiedProperty,
  ICreateUserPayload,
  ITenantBase,
  IUser,
} from 'app/api.types';
import { UserRoleEnum } from 'app/containers/Global/slice/types';
import { useMemo, useState } from 'react';
import { ImageFile, ImageURL } from 'app/components/FileDropzone/ImageDropzone';
import { uploadLogoResource } from 'utils/helpers';

interface IUseUpdateUserHookArgs {
  userId: boolean | number;
  user: ICreateUserPayload;
  userData: IUser | undefined;
  userRole: IClassifiedProperty<UserRoleEnum> | null;
  userTenant: ITenantBase | null;
  currentUser: IUser | undefined;
  avatar: ImageURL | ImageFile | null;
  onClose: () => void;
}

export const useUpdateUser = ({
  userId,
  userRole,
  userData,
  userTenant,
  user,
  currentUser,
  avatar,
  onClose,
}: IUseUpdateUserHookArgs) => {
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();

  const isLoading = useMemo(() => {
    return isUpdatingUser || isUploadingImage;
  }, [isUpdatingUser, isUploadingImage]);

  const shouldUploadImageResource = useMemo(() => {
    if (!avatar) return false;

    if (avatar.type === 'File') return true;

    if (avatar.type === 'URL') {
      if (userId && userData && userData.avatar !== avatar.content) {
        return true;
      }
      if (!userId) {
        return true;
      }
    }

    return false;
  }, [avatar, userData, userId]);

  const updateUserHandler = async () => {
    if (!userId) return;

    const isTenantValid = isSelectedTenantValid({
      userRole,
      userTenant,
      currentUser,
    });

    if (!isTenantValid) {
      showErrorToast('Select a valid tenant', 'Error');
      return;
    }

    if (!userRole || !userRole.code) {
      showErrorToast('User role is required', 'Error');
      return;
    }

    const selectedTenantId = getSelectedTenantId({
      userRole,
      userTenant,
      currentUser,
    });

    let uploadedImage = '';

    if (shouldUploadImageResource) {
      if (!avatar) return;

      if (avatar.type === 'File') {
        try {
          setIsUploadingImage(true);
          uploadedImage = await uploadLogoResource(avatar.content);
        } catch (error) {
        } finally {
          setIsUploadingImage(false);
        }
      }

      if (avatar.type === 'URL') {
        uploadedImage = avatar.content;
      }
    }

    try {
      await updateUser({
        id: userId as number,
        avatar: shouldUploadImageResource ? uploadedImage || '' : user.avatar,
        email: user.email,
        mobilePhone: user.mobilePhone,
        firstName: user.firstName,
        lastName: user.lastName,
        status: user.status,
        role: userRole.code,
        tenantId: selectedTenantId,
      }).unwrap();

      showSuccessToast('User has been updated');
      onClose();
    } catch (error) {
      //@ts-ignore
      const errorMessage = (error as FetchBaseQueryError).data?.message;
      showErrorToast(errorMessage || 'Could not update user', 'Error');
    }
  };

  return { updateUser: updateUserHandler, isLoading };
};
