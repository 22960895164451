import { FC } from 'react';
import { BackButton } from '../Toolbar.styles';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeftIcon';
import { useHistory } from 'react-router-dom';

const NavigationSelector: FC = () => {
  const history = useHistory();

  return (
    <BackButton onClick={history.goBack}>
      <ArrowLeftIcon />
    </BackButton>
  );
};

export default NavigationSelector;
