import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { Dispatch, FC, SetStateAction } from 'react';
import FooterButtons from '../Buttons';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';

interface IProps {
  pitchStatus: string;
  hasReports: boolean;
  setIsRapidGenerationView: Dispatch<SetStateAction<boolean>>;
}

const FooterMenu: FC<IProps> = ({
  setIsRapidGenerationView,
  hasReports,
  pitchStatus,
}) => {
  return (
    <>
      {hasReports && (
        <IfHasPermission require={userPermissionEnum.IS_ENTERPRISE_USER}>
          <FooterButtons.MultiPitchGenerationButton
            setIsGenerationView={setIsRapidGenerationView}
          />
        </IfHasPermission>
      )}
      {pitchStatus === 'ACTIVE' && (
        <IfHasPermission require={userPermissionEnum.MANAGE_PITCH}>
          <FooterButtons.EditPitchButton />
        </IfHasPermission>
      )}
    </>
  );
};

export default FooterMenu;
