import { HeaderButtonWrapper } from '../ContentHeader.styles';

import { theme } from 'styles/theme';
import RegenerateIcon from 'app/assets/icons/RegenerateIcon';
import TimerIcon from 'app/assets/icons/TimerIcon';
import Gap from 'app/components/UI/Gap';
import { FC } from 'react';
import Button from 'app/components/UI/Button/Button';
import {
  PitchStateActions,
  usePitch,
  usePitchDispatch,
} from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/PitchContext';
import { usePitchDataWithContext } from 'app/containers/Pitch/pages/CreateOrEditPitch/hooks/usePitchDataWithContext';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import useHasPermission from 'app/containers/Global/permissions/useHasPermission.hook';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';

interface IContentControls {
  handleRegenerate?: () => void;
  hasContentHistory: boolean;
}

const Controls: FC<IContentControls> = ({ hasContentHistory }) => {
  const dispatch = usePitchDispatch();
  const pitchState = usePitch();

  const { createModal } = usePromptModalContext();

  const {
    handleGenerateContent,
    generationLimits,
    limitExceededPopup,
    isGenerateLoading,
    hasGeneratedContent,
  } = usePitchDataWithContext();

  const toggleVersions = () => {
    dispatch({
      type: PitchStateActions.TOGGLE_VERSIONS,
      payload: !pitchState.versionsOpen,
    });
  };

  const onRegenerateClick = async () => {
    if (generationLimits.contentSuggestionsLeft === 0) {
      limitExceededPopup.show();
    } else {
      createModal({
        title: `Regenerate All`,
        description: `This will regenerate all of your Pitch’s content, overwritting current content. You'll need to revert to a previous version to restore any overwritten content.
        `,
        okButtonText: 'Regenerate',
        cancelButtonText: 'Cancel',
        okButtonDanger: false,
        okButtonCallback: async () => {
          await handleGenerateContent();
        },
      });
    }
  };

  const onGenerateClick = async () => {
    await handleGenerateContent();
  };

  const hasPermissionToGenerateContent = useHasPermission(
    userPermissionEnum.GENERATE_PITCH_CONTENT,
  );

  return (
    <>
      {hasContentHistory && (
        <HeaderButtonWrapper>
          <Button
            variant="icon-button-border"
            onClick={toggleVersions}
            tooltipText="View previous content generations"
          >
            <TimerIcon />
          </Button>
        </HeaderButtonWrapper>
      )}
      <Gap size={8} />
      {!hasGeneratedContent ? (
        <Button
          variant="black"
          padding="15px 24px"
          onClick={onGenerateClick}
          disabled={!hasPermissionToGenerateContent || isGenerateLoading}
        >
          Generate
        </Button>
      ) : (
        <HeaderButtonWrapper $borderColor={theme.colors.orbit}>
          <Button
            variant="icon-button-border"
            onClick={onRegenerateClick}
            tooltipText="Regenerate content"
            disabled={!hasPermissionToGenerateContent || isGenerateLoading}
          >
            <RegenerateIcon fill={theme.colors.orbit} />
          </Button>
        </HeaderButtonWrapper>
      )}
    </>
  );
};

export default Controls;
