import { IAuthorSocial } from '../../../../../../../api.types';
import {
  MutableRefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as S from '../DigDeeperSlideout.styles';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import Avatar from '../../../../../../../components/UI/Avatar';
import Typography from '../../../../../../../components/Typography';
import Button from '../../../../../../../components/UI/Button/Button';
import ContextMenu, {
  ContextMenuOption,
} from '../../../../../../../components/UI/ContextMenu/ContextMenu';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import {
  selectContentGenerationTone,
  selectContentGenerationToneLength,
  selectDigDeeperCreator,
  selectDigDeeperAuthorInterest,
  selectDigDeeperReportItem,
} from '../../../../../slice/selectors';
import Accordion from '../../../../../../../components/Accordion/Accordion';
import { Anchor } from '../../../../../../../components/UI/Anchor';
import SeenInGraph from './SeenInGraph';
import {
  createEMailToLink,
  openInNewTab,
  toPercentageStatistics,
} from '../../../../../../../../utils/helpers';
import { useParams } from 'react-router-dom';
import {
  useUpdateAuthorsNameMutation,
  useUpdateAuthorStatusMutation,
  useUpdateFavoriteCreatorMutation,
} from '../../../../../../../api';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../../../utils/toast';
import { getErrorText } from '../../../../../../../../utils/error-messages';
import { usePitchSlice } from 'app/containers/Pitch/slice';
import { CloseIcon } from '../../../../../../../components/UI/CloseIcon';
import { usePromptModalContext } from '../../../../../../Global/parts/PromptModal/PromptModal.context';
import IfHasPermission from '../../../../../../Global/permissions/IfHasPermission';
import { userPermissionEnum } from '../../../../../../Global/permissions/userPermissions.enum';
import { InfoTipAdvanced } from '../../../../../../../components/UI/InfoTip/InfoTip';
import { RELEVANCY_EXPLAIN_TOOLTIP_BODY } from 'app/containers/Pitch/pages/PitchPage/PitchPage.constants';
import {
  ContentLengthDropDown,
  ToneSelectDropDown,
} from '../../ToneSelectDropDown/ToneSelectDropDown';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
} from '../../ToneSelectDropDown/ToneSelectDropDown.types';
import DotsHorizontalIcon from '../../../../../../../assets/icons/DotsHorizontal';
import RegenerateIcon from '../../../../../../../assets/icons/RegenerateIcon';
import CollapsableTextSection from '../../../../../../../components/CollapsableTextSection/CollapsableTextSection';
import { SpinningAnimation } from '../../../../../../../components/UI/SpinningAnimation';
import useHasPermission from '../../../../../../Global/permissions/useHasPermission.hook';
import useGeneratedEmailContent from '../hooks/useGeneratedEmailContent';
import { joinTextLines } from '../../../CreateOrEditPitch.helpers';
import { useGenerationLimits } from '../../../hooks/useGenerationLimits';
import useOutOfLimitsMessages from '../../../hooks/useOutOfLimitsMessages';
import PodcastDemographicInformation from './PodcastDemographics/PodcastDemographic';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AddSocial } from './Socials/AddSocial';
import CheckmarkIcon from 'app/assets/icons/CheckmarkIcon';
import CreatorSocials from './Socials/CreatorSocials';
import { DigDeeperSocialIcon } from './Socials/Socials.config';
import Gap from 'app/components/UI/Gap';
import PageIconThin from 'app/assets/icons/PageThinIcon';
import FavoriteJournalistButton from 'app/containers/Journalists/pages/parts/Favorites/FavoriteJournalistButton';
import ReactTooltip from 'react-tooltip';
import ArticlesSection from './Articles/ArticlesSection';
import { ICreatorSummary } from '../hooks/useAuthorSummary';
import SeenInSkeleton from 'app/components/UI/LoadingSkeletons/DigDeeper/SeenInSkeleton';
import PodcastDemographicsSkeleton from 'app/components/UI/LoadingSkeletons/DigDeeper/PodcastDemographicsSkeleton';
import { Spinner } from 'app/components/UI/Spinner';
import CreatorCountries from './Locations/CreatorCountries';

interface IProps {
  onSlideoutClose: () => void;
  openExploreArticles: (exploreRelevantArticles: boolean) => void;
  openSuggestEdit: () => void;
  summary?: ICreatorSummary;
  isSummaryFetching: boolean;
}

const AuthorInfo = forwardRef<HTMLDivElement, IProps>(
  (
    {
      onSlideoutClose,
      openExploreArticles,
      openSuggestEdit,
      summary,
      isSummaryFetching,
    },
    ref,
  ) => {
    const interest = useAppSelector(selectDigDeeperAuthorInterest);
    const reportItem = useAppSelector(selectDigDeeperReportItem);

    const contentGenerationTone = useAppSelector(selectContentGenerationTone);
    const contentGenerationToneLength = useAppSelector(
      selectContentGenerationToneLength,
    );

    const creator = useAppSelector(selectDigDeeperCreator);

    const isPodcast = useMemo(() => {
      return Boolean(creator?.podcast);
    }, [creator]);

    const dispatch = useAppDispatch();
    const { actions } = usePitchSlice();

    const { pitchId, pressReleaseId } = useParams<{
      pitchId?: string;
      pressReleaseId?: string;
      versionId?: string;
    }>();

    const digDeeperSourceDisplayName = useMemo(() => {
      if (pitchId) return 'Pitch';
      if (pressReleaseId) return 'Press Release';
      return undefined;
    }, [pitchId, pressReleaseId]);

    const hasPrediction = Boolean(pitchId || pressReleaseId);

    const prompt = usePromptModalContext();

    const [setRelevance] = useUpdateFavoriteCreatorMutation();
    const [updateAuthor] = useUpdateAuthorStatusMutation();
    const [updateDisplayName] = useUpdateAuthorsNameMutation();

    const [editAuthorNameMode, setEditAuthorNameMode] = useState(false);
    const [authorNameForEdit, setAuthorNameForEdit] = useState<string>('');
    const authorNameInputRef = useRef() as MutableRefObject<HTMLInputElement>;

    const generateContentLimits = useGenerationLimits();
    const hasPermissionToSeeEmail = useHasPermission(
      userPermissionEnum.JOURNALIST_EMAIL_INFO_ACCESS,
    );

    const hasPermissionToSeeObscuredEmail = useHasPermission(
      userPermissionEnum.JOURNALIST_EMAIL_INFO_OBSCURED,
    );

    const cannotSeeContactInformation =
      hasPermissionToSeeObscuredEmail || !hasPermissionToSeeEmail;

    const limitMessages = useOutOfLimitsMessages();
    const generatedContent = useGeneratedEmailContent({
      isPodcast: isPodcast,
      authorId: String(creator?.id),
      targetMediaType: reportItem?.targetMediaType,
      digDeeperView: true,
    });

    useEffect(() => {
      setAuthorNameForEdit(creator?.name || '');
      if (editAuthorNameMode) {
        authorNameInputRef.current?.focus();
      }
    }, [editAuthorNameMode, creator]);

    const creatorSocials = useMemo(() => {
      if (!creator) return [];
      if (!creator.socials) return [];
      return creator.socials;
    }, [creator]);

    useEffect(() => {
      ReactTooltip.rebuild();
      ReactTooltip.hide();
    }, []);

    const podcastEmailAddress = creator?.email;

    const noContactInformation = useMemo(() => {
      return !Boolean(podcastEmailAddress || creator?.email);
    }, [creator, podcastEmailAddress]);

    const handlePreferClick = (prefer: boolean) => async () => {
      try {
        const creatorId =
          creator?.id && !isNaN(Number(creator.id))
            ? Number(creator.id)
            : undefined;

        const predictionType = pressReleaseId ? 'PRESS_RELEASE' : 'PITCH';

        const predictedContentId = pressReleaseId
          ? Number(pressReleaseId)
          : Number(pitchId);

        if (creatorId && !isNaN(predictedContentId)) {
          await setRelevance({
            creatorId: creatorId,
            creatorType: isPodcast ? 'PODCASTER' : 'JOURNALIST',
            pitchType: predictionType,
            pitchId: predictedContentId,
            scope: 'PITCH',
            favorite: prefer === reportItem?.preference ? false : prefer,
          }).unwrap();

          dispatch(
            actions.updateCachedPreference(
              reportItem?.preference ? null : prefer,
            ),
          );
          showSuccessToast('Preference set');
        }
      } catch (e) {
        showErrorToast(
          getErrorText(e as FetchBaseQueryError),
          'Failed to set preference',
        );
      }
    };

    const handleDeactivateClick = () => {
      prompt.createModal({
        title: 'Deactivate Journalist',
        description:
          'Are you sure you want to deactivate this journalist across the entire platform?',
        cancelButtonText: 'Cancel',
        okButtonDanger: true,
        okButtonText: 'Deactivate',
        okButtonCallback: async () => {
          if (!creator || !creator.id) return;
          await updateAuthor({ id: creator.id, isActive: false })
            .unwrap()
            .then(() => showSuccessToast('Journalist deactivated', 'Success'))
            .catch(e =>
              showErrorToast(
                getErrorText(e),
                'Failed to deactivate journalist',
              ),
            );
        },
      });
    };

    const handleAuthorNameSave = () => {
      prompt.createModal({
        title: 'Edit Journalist’s Name',
        description: "Are you sure you want to edit this journalist's name?",
        okButtonText: 'Yes',
        cancelButtonText: 'No',
        renderInPortal: true,
        okButtonCallback: async () => {
          setEditAuthorNameMode(false);
          try {
            if (!creator || !creator.id) return;
            await updateDisplayName({
              id: creator.id,
              value: authorNameForEdit,
            }).unwrap();
            setEditAuthorNameMode(false);
            showSuccessToast('Journalist Updated', 'Success');
          } catch (e) {
            showErrorToast(
              getErrorText(e as FetchBaseQueryError),
              'Failed to update journalist',
            );
          }
        },
      });
    };

    const setContentGenerationTone = (tone: IToneSelectOption) => {
      dispatch(actions.setContentGenerationTone(tone));
    };

    const setContentGenerationLengthTone = (
      tone: IContentLengthSelectOption,
    ) => {
      dispatch(actions.setContentGenerationLengthTone(tone));
    };

    const onContentGenerateClick = () => {
      dispatch(actions.toggleContentGenerationDrawer(true));
      generatedContent.trigger();
    };

    const onSeeMoreGeneratedContentClick = () => {
      dispatch(actions.toggleContentGenerationDrawer(true));
    };

    const handleSocialClick = (url: string) => () => {
      if (url.match('@')) {
        openInNewTab(createEMailToLink(url));
      } else {
        openInNewTab(url);
      }
    };

    const socialsBlacklist = useMemo(() => {
      const blackList = ['SECONDARY_EMAIL'];
      if (cannotSeeContactInformation) {
        blackList.push('PRIMARY_EMAIL');
      }
      return blackList;
    }, [cannotSeeContactInformation]);

    const authorSocialsFilter = (social: IAuthorSocial) => {
      return (
        !socialsBlacklist.includes(social.socialType) &&
        social.socialType !== 'PHONE_NUMBER'
      );
    };

    const isAuthorRelevant = useMemo(() => {
      if (!summary || !summary.articles || !summary.articles.latest)
        return false;
      return summary.articles.relevant && summary.articles.relevant.length > 0;
    }, [summary]);

    const [addSocial, setAddSocial] = useState(false);
    const [editSocial, setEditSocial] = useState(false);

    const shouldDisplayMetaDataSection = Boolean(
      interest ||
        creator?.podcast?.rating ||
        (creatorSocials.length || 0) > 0 ||
        summary?.sentimentStatistics,
    );

    return (
      <>
        <S.Section padding="24px" ref={ref}>
          <FlexBlock alignItems="stretch">
            <Avatar
              name={creator?.name || ''}
              size="80"
              url={creator?.avatar || undefined}
            />

            <FlexBlock
              flex={1}
              flexDirection="column"
              justifyContent="flex-start"
            >
              {!editAuthorNameMode ? (
                <FlexBlock
                  // flex={1}
                  columnGap="10px"
                  flexDirection="row"
                  justifyContent="center"
                >
                  <Typography.Text
                    $dmSans
                    $bold
                    $size={24}
                    $margin="0 0 4px 0"
                    key="field"
                    $lineHeight={28}
                  >
                    {creator && creator.name
                      ? creator.contributor
                        ? `${creator.name} (Contributor)`
                        : creator?.name
                      : ''}
                  </Typography.Text>
                </FlexBlock>
              ) : (
                <FlexBlock flexDirection="column">
                  <S.AuthorNameInput
                    value={authorNameForEdit}
                    onChange={e => setAuthorNameForEdit(e.target.value)}
                    ref={authorNameInputRef}
                  />
                  <FlexBlock columnGap="8px" padding="8px 0 16px">
                    <Button
                      variant="icon-round"
                      onClick={handleAuthorNameSave}
                      disabled={
                        authorNameForEdit === creator?.name ||
                        !authorNameForEdit
                      }
                    >
                      <CheckmarkIcon color="#3f3f42" />
                    </Button>
                    <Button
                      variant="icon-round"
                      onClick={() => setEditAuthorNameMode(false)}
                    >
                      <CloseIcon />
                    </Button>
                  </FlexBlock>
                </FlexBlock>
              )}

              {creator?.title && (
                <FlexBlock
                  flexDirection="row"
                  alignItems="center"
                  margin="2px 0"
                  rowGap="5px"
                  flexWrap="wrap"
                >
                  <PageIconThin />
                  <Gap size={6} />
                  <Typography.Text
                    $colorName="orbit"
                    $size={14}
                    $lineHeight={16}
                  >
                    {creator.title}
                  </Typography.Text>
                </FlexBlock>
              )}

              {creator?.locations && Boolean(creator.locations.length) && (
                <CreatorCountries locations={creator.locations} />
              )}

              {creator?.podcast && (
                <>
                  <Typography.Text $margin="4px 0 0">
                    {`${creator.podcast?.title} `}(
                    <b>{`${creator.podcast?.episodeCount} `}</b>
                    {`${
                      creator.podcast?.episodeCount === 1
                        ? 'episode'
                        : 'episodes'
                    })`}
                  </Typography.Text>
                </>
              )}
            </FlexBlock>
            <FlexBlock
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
              columnGap="8px"
              flexWrap="wrap"
            >
              {!isPodcast && isAuthorRelevant && !editAuthorNameMode && (
                <FavoriteJournalistButton
                  journalistId={creator?.id}
                  isFavorite={creator?.favorite || false}
                  noMargin
                />
              )}

              {!isPodcast && (
                <ContextMenu
                  buttonText={<DotsHorizontalIcon />}
                  buttonProps={{ variant: 'icon-button-border' }}
                  menuOptions={{ align: 'right', minWidth: '250px' }}
                  key={`${creator?.id}-context-menu`}
                >
                  <ContextMenuOption onClick={openSuggestEdit}>
                    Suggest an Edit
                  </ContextMenuOption>
                  <IfHasPermission
                    require={userPermissionEnum.SYSTEM_MANAGEMENT}
                  >
                    <ContextMenuOption
                      onClick={() => setEditAuthorNameMode(true)}
                    >
                      Edit Journalist’s Name
                    </ContextMenuOption>
                  </IfHasPermission>
                  <IfHasPermission
                    require={userPermissionEnum.SYSTEM_MANAGEMENT}
                  >
                    <ContextMenuOption onClick={() => setAddSocial(c => !c)}>
                      Add Social Field
                    </ContextMenuOption>
                  </IfHasPermission>
                  <IfHasPermission
                    require={userPermissionEnum.SYSTEM_MANAGEMENT}
                  >
                    <ContextMenuOption onClick={() => setEditSocial(c => !c)}>
                      Edit Social Fields
                    </ContextMenuOption>
                  </IfHasPermission>
                  <IfHasPermission
                    require={userPermissionEnum.SYSTEM_MANAGEMENT}
                  >
                    <ContextMenuOption onClick={handleDeactivateClick}>
                      Deactivate Journalist
                    </ContextMenuOption>
                  </IfHasPermission>
                </ContextMenu>
              )}
              <Button variant="icon-button-border" onClick={onSlideoutClose}>
                <CloseIcon size={16} />
              </Button>
            </FlexBlock>
          </FlexBlock>

          {creator?.bio && (
            <Typography.Text
              $margin="24px 0 0 0"
              $colorName="steel"
              $size={14}
              $lineHeight={20}
            >
              {creator?.bio}
            </Typography.Text>
          )}
        </S.Section>

        {shouldDisplayMetaDataSection && (
          <S.Section background="haze">
            <FlexBlock columnGap="40px" alignItems="center">
              {interest && (
                <FlexBlock flexDirection="column" alignItems="center">
                  <Typography.Text $dmSans $bold $size="32" $lineHeight="40">
                    {interest || '-'}
                  </Typography.Text>
                  <Typography.Text $colorName="steel">Interest</Typography.Text>
                </FlexBlock>
              )}

              {isPodcast && creator?.podcast?.rating && (
                <>
                  <FlexBlock flexDirection="column" alignItems="center">
                    <Typography.Text $dmSans $bold $size="32" $lineHeight="40">
                      {`${Math.round(creator.podcast.rating)}`}
                    </Typography.Text>
                    <FlexBlock flexDirection="row" alignItems="center">
                      <Typography.Text $colorName="steel" $margin="0 8px 0 0">
                        Power Score
                      </Typography.Text>
                      <InfoTipAdvanced tooltipText="" padding="0">
                        <Typography.Text $colorName="steel">
                          Score between 0-100 based on metrics such as audience
                          size, subscribers, chart positions and more.
                        </Typography.Text>
                      </InfoTipAdvanced>
                    </FlexBlock>
                  </FlexBlock>
                </>
              )}

              {!isPodcast &&
                summary?.sentimentStatistics &&
                reportItem?.sentimentType && (
                  <FlexBlock flexDirection="column" alignItems="center">
                    <Typography.Text
                      $dmSans
                      $bold
                      $size="32"
                      $lineHeight="40"
                      capitalize
                    >
                      {reportItem?.sentimentType?.toLowerCase() || '-'}
                    </Typography.Text>

                    <FlexBlock columnGap="8px" alignItems="center">
                      <Typography.Text $colorName="steel" capitalize>
                        Sentiment
                      </Typography.Text>
                      {!!summary.sentimentStatistics && (
                        <InfoTipAdvanced tooltipText="" padding="0">
                          {digDeeperSourceDisplayName && (
                            <FlexBlock flexDirection="column" margin="0">
                              <Typography.Text
                                $colorName="onyx"
                                $size={14}
                                $bold
                                $dmSans
                                $lineHeight={14}
                                $margin="0 0 9px"
                              >
                                {digDeeperSourceDisplayName} Category Sentiment:
                              </Typography.Text>
                              <Typography.Text
                                $colorName="steel"
                                $size={12}
                                $lineHeight={14}
                                $padding="0 0 8px 0"
                                capitalize
                              >
                                {reportItem?.sentimentType?.toLowerCase() ||
                                  '-'}
                              </Typography.Text>
                            </FlexBlock>
                          )}

                          <Typography.Text
                            $colorName="onyx"
                            $size={14}
                            $bold
                            $dmSans
                            $lineHeight={14}
                            $margin="0 0 9px"
                          >
                            Overall Journalist Article Sentiment:
                          </Typography.Text>
                          <FlexBlock flexDirection="column" padding="8px 0">
                            {!isSummaryFetching ? (
                              <>
                                <Typography.Text
                                  $colorName="steel"
                                  $size={12}
                                  $lineHeight={14}
                                >
                                  <strong>
                                    {toPercentageStatistics(
                                      summary.sentimentStatistics.positive
                                        ?.totalPercentage || 0,
                                    )}
                                  </strong>{' '}
                                  Positive
                                </Typography.Text>

                                <Typography.Text
                                  $colorName="steel"
                                  $size={12}
                                  $lineHeight={14}
                                >
                                  <strong>
                                    {toPercentageStatistics(
                                      summary.sentimentStatistics.negative
                                        ?.totalPercentage || 0,
                                    )}
                                  </strong>{' '}
                                  Negative
                                </Typography.Text>

                                <Typography.Text
                                  $colorName="steel"
                                  $size={12}
                                  $lineHeight={14}
                                >
                                  <strong>
                                    {toPercentageStatistics(
                                      (summary.sentimentStatistics.neutral
                                        ?.totalPercentage || 0) +
                                        (summary.sentimentStatistics.mixed
                                          ?.totalPercentage || 0),
                                    )}
                                  </strong>{' '}
                                  Neutral / Mixed
                                </Typography.Text>
                              </>
                            ) : (
                              <Spinner $isBlue />
                            )}
                          </FlexBlock>
                        </InfoTipAdvanced>
                      )}
                    </FlexBlock>
                  </FlexBlock>
                )}
            </FlexBlock>
            {creatorSocials && creatorSocials.length > 0 && (
              <FlexBlock
                padding="12px 0"
                columnGap="8px"
                minHeight="60px"
                flex={1}
                justifyContent="flex-start"
              >
                {creatorSocials.filter(authorSocialsFilter).map(social => (
                  <Button
                    key={social.creatorSocialId}
                    variant="icon-round-social-no-hover"
                    onClick={handleSocialClick(social.url)}
                  >
                    <DigDeeperSocialIcon socialType={social.socialType} />
                  </Button>
                ))}
              </FlexBlock>
            )}
          </S.Section>
        )}

        {reportItem && (
          <S.Section>
            <FlexBlock
              justifyContent="space-between"
              alignItems="stretch"
              padding="0 0 0 8px"
            >
              <FlexBlock flexDirection="column" maxWidth="60%">
                <FlexBlock alignItems="center" columnGap="8px">
                  <Typography.Text $dmSans $bold $size={16} $lineHeight={24}>
                    Relevancy
                  </Typography.Text>
                  <InfoTipAdvanced tooltipText={''} padding="0">
                    <div>
                      <Typography.Text
                        $colorName="onyx"
                        $size={14}
                        $bold
                        $dmSans
                        $lineHeight={14}
                        $margin="0 0 9px"
                      >
                        {isPodcast ? 'Podcast' : 'Journalist'} Relevancy
                      </Typography.Text>
                    </div>
                    <Typography.Text
                      $colorName="steel"
                      $size={12}
                      $lineHeight={14}
                    >
                      {RELEVANCY_EXPLAIN_TOOLTIP_BODY}
                    </Typography.Text>
                  </InfoTipAdvanced>
                </FlexBlock>
                <Typography.Text $lineHeight={24} $colorName="steel">
                  {reportItem?.preference === false &&
                    `Thank you for your feedback, ${
                      creator?.name || 'creator'
                    } will be hidden from results.`}
                  {reportItem?.preference === true &&
                    `Thank you for your feedback.`}
                  {(reportItem?.preference === null ||
                    reportItem?.preference === undefined) &&
                    `Is this ${
                      isPodcast ? 'podcast' : 'journalist'
                    } relevant to Pitch?`}
                </Typography.Text>
              </FlexBlock>

              <FlexBlock columnGap="8px" alignItems="center">
                {reportItem?.preference === false ? (
                  <>
                    {/*<Button variant="anchor" onClick={handlePreferClick(false)}>*/}
                    {/*  Undo*/}
                    {/*</Button>*/}
                  </>
                ) : (
                  <>
                    <S.RelevancyButton
                      variant="green"
                      onClick={handlePreferClick(true)}
                      active={reportItem?.preference === true}
                    >
                      Yes
                    </S.RelevancyButton>
                    <S.RelevancyButton
                      variant="red"
                      onClick={handlePreferClick(false)}
                    >
                      No
                    </S.RelevancyButton>
                  </>
                )}
              </FlexBlock>
            </FlexBlock>
          </S.Section>
        )}

        {reportItem !== null && (
          <IfHasPermission
            require={userPermissionEnum.GENERATE_AUTHOR_EMAIL_CONTENT}
          >
            <S.Section>
              <Accordion
                title="Personalize My Pitch"
                subTitle={
                  generatedContent.exist
                    ? 'Review and customize personalized message.'
                    : 'Select tone to generate a personalized message'
                }
                tooltipContent={
                  <Typography.Text>
                    Generate a personalized email pitch to this journalist
                  </Typography.Text>
                }
                defaultExpanded
              >
                {generatedContent.exist || generatedContent.data ? (
                  <FlexBlock
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {generatedContent.loading && (
                      <FlexBlock justifyContent="center">
                        <SpinningAnimation>
                          <RegenerateIcon fill="#1D76DE" />
                        </SpinningAnimation>
                      </FlexBlock>
                    )}
                    {generatedContent.data && !generatedContent.loading && (
                      <FlexBlock flexDirection="column">
                        <Typography.Text $dmSans $bold $size={18}>
                          {joinTextLines(generatedContent.data.subject)}
                        </Typography.Text>
                        <CollapsableTextSection
                          body={joinTextLines(generatedContent.data.pitch)}
                          margin="16px 0"
                          lineCap={5}
                          buttonActionOverride={onSeeMoreGeneratedContentClick}
                        />
                      </FlexBlock>
                    )}
                  </FlexBlock>
                ) : (
                  <FlexBlock
                    flexDirection="row"
                    alignItems="stretch"
                    flexWrap="wrap"
                  >
                    <FlexBlock
                      alignItems="center"
                      justifyContent="space-between"
                      columnGap="5px"
                      minWidth="100%"
                    >
                      <ToneSelectDropDown
                        value={contentGenerationTone}
                        onChange={setContentGenerationTone}
                        selectComponentWidth="232px"
                      />
                      <ContentLengthDropDown
                        value={contentGenerationToneLength}
                        onChange={setContentGenerationLengthTone}
                        selectComponentWidth="232px"
                      />
                    </FlexBlock>
                    <div
                      style={{
                        marginTop: '8px',
                        width: '100%',
                        display: 'grid',
                      }}
                    >
                      <Button
                        variant="black"
                        onClick={onContentGenerateClick}
                        disabled={
                          generateContentLimits.authorEmailSuggestionsLeft < 1
                        }
                      >
                        Generate
                      </Button>
                    </div>
                    {generateContentLimits.authorEmailSuggestionsLeft < 1 && (
                      <Typography.Text $padding="24px 0 16px">
                        Please{' '}
                        <Anchor onClick={limitMessages.goToBilling}>
                          upgrade to our {limitMessages.planToUpgrade}
                        </Anchor>{' '}
                        for additional content generations.
                      </Typography.Text>
                    )}
                  </FlexBlock>
                )}
              </Accordion>
            </S.Section>
          </IfHasPermission>
        )}

        <S.Section>
          <Accordion
            title="Contact Information"
            defaultExpanded
            tooltipContent={
              !noContactInformation &&
              cannotSeeContactInformation && (
                <Typography.Text $colorName="steel" $size={14}>
                  {`Upgrade to ${limitMessages.planToUpgrade} to view content`}
                </Typography.Text>
              )
            }
          >
            {addSocial && creator && (
              <AddSocial
                authorId={creator.id}
                isOpen={addSocial}
                handleClose={() => setAddSocial(false)}
                creatorType={isPodcast ? 'PODCASTER' : 'JOURNALIST'}
                currentSocials={creator.socials}
              />
            )}
            {noContactInformation ? (
              <>
                <Typography.Text $colorName="steel">
                  No contact information available.
                </Typography.Text>
              </>
            ) : (
              <FlexBlock
                flexDirection="column"
                blur={!cannotSeeContactInformation ? undefined : 5}
                disablePointerEvents={cannotSeeContactInformation}
              >
                <CreatorSocials
                  key={editSocial ? 'EDIT_SOCIALS' : 'VIEW_SOCIALS'}
                  authorId={creator?.id || ''}
                  isEditMode={editSocial}
                  creatorType={isPodcast ? 'PODCASTER' : 'JOURNALIST'}
                  socials={creator?.socials || []}
                />
              </FlexBlock>
            )}
          </Accordion>
        </S.Section>
        {reportItem?.categories && reportItem.categories.length > 0 && (
          <S.Section>
            <Accordion title="Beats" defaultExpanded>
              <FlexBlock flexDirection="row" rowGap="8px" flexWrap="wrap">
                {reportItem.categories.map(beat => (
                  <S.ClassifiedCategoryItem key={beat}>
                    <Typography.Text
                      $size={14}
                      $lineHeight={16}
                      $colorName="onyx"
                    >
                      {beat && beat.replace('/', '')}
                    </Typography.Text>
                  </S.ClassifiedCategoryItem>
                ))}
              </FlexBlock>
            </Accordion>
          </S.Section>
        )}
        {isPodcast && isSummaryFetching && <PodcastDemographicsSkeleton />}
        {isPodcast && !isSummaryFetching && summary?.demographics && (
          <S.Section>
            <Accordion title="Podcast Demographics" defaultExpanded>
              <PodcastDemographicInformation
                demographicsData={summary.demographics}
              />
            </Accordion>
          </S.Section>
        )}

        {!isPodcast && isSummaryFetching ? (
          <SeenInSkeleton />
        ) : (
          !isPodcast &&
          summary &&
          summary?.outlets &&
          summary?.outlets.length > 0 && (
            <S.Section>
              <Accordion title="Seen In" defaultExpanded>
                <SeenInGraph outlets={summary?.outlets || []} />
              </Accordion>
            </S.Section>
          )
        )}
        <ArticlesSection
          isPodcast={Boolean(isPodcast)}
          isArticlesFetching={isSummaryFetching}
          articles={
            isPodcast
              ? summary?.episodes?.content || []
              : {
                  latestArticles: summary?.articles?.latest || [],
                  relevantArticles: summary?.articles?.relevant || [],
                }
          }
          openExploreArticles={openExploreArticles}
          hasPrediction={hasPrediction}
          key={`${creator?.id}-articles-section`}
        />
      </>
    );
  },
);

export default AuthorInfo;
