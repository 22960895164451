import styled, { css } from 'styled-components/macro';

export const FailedAuthorsButton = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.aluminum};
  padding: 16.5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.25s all ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

export const ExpandableSection = {
  Wrapper: styled.div`
    border: 1px solid ${({ theme }) => theme.colors.smoke};
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
  `,
  Toggle: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    align-items: center;
  `,
  CountPill: styled.div`
    white-space: nowrap;
    min-height: 32px;
    padding: 0 20px;
    background-color: rgba(29, 118, 222, 0.1);
    color: #1d76de;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: 0 12px;
  `,
  ToggleIconWrapper: styled.div<{ expanded?: boolean }>(
    ({ expanded }) => css`
      svg {
        transform: rotate(${!expanded ? '180deg' : '0deg'});
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    `,
  ),
};
