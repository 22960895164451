import styled from 'styled-components';

export const StyledErrorsContainer = styled.div<{ $noPadding?: boolean }>`
  padding: ${props => (props.$noPadding ? '0' : '8px')};
  color: red;
  min-height: 16px;
  display: grid;
  grid-row-gap: 8px;
  a {
    color: red;
  }
`;
