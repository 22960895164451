import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import Avatar from '../../../../../../components/UI/Avatar';
import {
  IPitch,
  IPitchPrediction,
  IPressRelease,
} from '../../../../../../api.types';
import Typography from '../../../../../../components/Typography';
import FlexBlock from '../../../../../../components/UI/FlexBlock';
import Button from '../../../../../../components/UI/Button/Button';
import ContextMenu, {
  ContextMenuOption,
} from '../../../../../../components/UI/ContextMenu/ContextMenu';
import { Divider } from '../../../../../../components/UI/Divider';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { usePitchSlice } from '../../../../slice';
import Modal from '../../../../../../components/UI/Modal/Modal';
import RoundIconWrapper from '../../../../../../components/UI/RoundIconWrapper';
import DownloadIcon from '../../../../../../assets/icons/DownloadIcon';
import { SelectableButton } from '../../../../../../components/UI/SelectableButton';
import { downloadCsvExport } from '../../PitchPage.api';
import {
  downloadFile,
  formatDate,
  truncateText,
} from '../../../../../../../utils/helpers';
import { PITCH_PAGE_HEADER_HEADLINE_TEXT_LENGTH_LIMIT } from '../../../../../../../utils/constants';
import IfHasPermission from '../../../../../Global/permissions/IfHasPermission';
import { userPermissionEnum } from '../../../../../Global/permissions/userPermissions.enum';
import SideDrawer from '../../../../../../components/UI/SideDrawer/SideDrawer';
import PitchSummary from '../PitchSummary/PitchSummary';
import Gap from 'app/components/UI/Gap';
import { PitchPageContext } from '../../PitchPage.context';
import DotsHorizontalIcon from 'app/assets/icons/DotsHorizontal';
import DoublePill from 'app/components/UI/DoublePill';
import { usePredictedContentStatus } from '../../PitchPage.hooks';
import { useParams } from 'react-router-dom';
import { showErrorToast } from 'utils/toast';

interface IProps {
  content: IPitch | IPressRelease;
  prediction: IPitchPrediction;
  exportModalOpen: boolean;
  setExportModalOpen: Dispatch<SetStateAction<boolean>>;
  predictedContentType: 'PITCH' | 'PRESS_RELEASE';
}

enum exportOptionsEnum {
  pdf = 'pdf',
  csv = 'csv',
}

const PitchHeader: FC<IProps> = ({
  content,
  prediction,
  exportModalOpen,
  setExportModalOpen,
  predictedContentType,
}) => {
  const [detailsSidebarOpen, setDetailsSidebarOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedExportOption, setSelectedExportOption] =
    useState<exportOptionsEnum | null>(null);

  const dispatch = useAppDispatch();
  const { actions: pitchActions } = usePitchSlice();

  const openVersionDrawer = () => {
    dispatch(pitchActions.toggleVersionDrawer(true));
  };

  const { isRapidGenerationResultsView } = useContext(PitchPageContext);

  const {
    isPredictedContentActive,
    handleDeactivatePredictedContent,
    handlePressReleaseDelete,
  } = usePredictedContentStatus(content, predictedContentType);

  const handleExportModalClose = () => {
    setExportModalOpen(false);
    setSelectedExportOption(null);
    setExportLoading(false);
  };

  const params: {
    versionId: string;
  } = useParams();

  const [versionId] = useMemo(() => [Number(params.versionId)], [params]);

  const predictedContentDisplayName = useMemo(() => {
    if (predictedContentType === 'PRESS_RELEASE') return 'Press Release';
    return 'Pitch';
  }, [predictedContentType]);

  const handleSubmit = async () => {
    setExportLoading(true);
    if (selectedExportOption === exportOptionsEnum.pdf) {
      handleExportModalClose();
      dispatch(
        pitchActions.setPdfPrint({
          pitchId: content.id,
          versionId: versionId,
          contentType: predictedContentType,
        }),
      );
    }

    if (selectedExportOption === exportOptionsEnum.csv) {
      try {
        const file = await downloadCsvExport(
          content.id,
          versionId,
          predictedContentType,
          prediction.reports.map(report => report.mediaList.id),
        );
        await downloadFile(
          `${prediction.headline.replace(/ /g, '_')}_version_${
            prediction.version
          }.xlsx`,
          URL.createObjectURL(file),
        );
      } catch (error) {
        showErrorToast('Could not export CSV. Please try again.');
      } finally {
        handleExportModalClose();
      }
    }
  };

  const toggleVersionDrawer = () => {
    dispatch(pitchActions.toggleVersionDrawer(true));
  };

  return (
    <>
      <FlexBlock
        gridColumnStart="1"
        gridColumnEnd="9"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Avatar
          url={content.project.logo}
          name={content.project.name}
          size="56"
          shadow
        />
        <FlexBlock flexDirection="column" margin="0 8px">
          <Typography.Text
            $size={20}
            $lineHeight={24}
            $dmSans
            $bold
            $colorName="onyx"
            maxWidth="760px"
          >
            {truncateText(
              prediction.headline,
              PITCH_PAGE_HEADER_HEADLINE_TEXT_LENGTH_LIMIT,
            )}
          </Typography.Text>
        </FlexBlock>
      </FlexBlock>
      {!isRapidGenerationResultsView && (
        <FlexBlock
          justifyContent="flex-end"
          alignItems="center"
          gridColumnStart="9"
          gridColumnEnd="-1"
          columnGap="8px"
        >
          <FlexBlock flexDirection="row" alignItems="center">
            <FlexBlock flexDirection="column" alignItems="flex-end">
              <Typography.Text $colorName="steel">Last Edited</Typography.Text>
              <Typography.Text>{formatDate(content.updatedAt)}</Typography.Text>
            </FlexBlock>
            <Gap size={16} />
            <DoublePill
              leftContent={predictedContentDisplayName}
              rightContent={`V${versionId}`}
              onClick={toggleVersionDrawer}
            />
          </FlexBlock>
          <ContextMenu
            buttonText={<DotsHorizontalIcon />}
            buttonProps={{
              variant: 'gray-smoke-square',
            }}
            menuOptions={{ align: 'right' }}
          >
            <ContextMenuOption onClick={() => setDetailsSidebarOpen(true)}>
              <Typography.Text>
                {predictedContentDisplayName} Details
              </Typography.Text>
            </ContextMenuOption>
            <IfHasPermission require={userPermissionEnum.REPORT_EXPORT_ACCESS}>
              <ContextMenuOption onClick={() => setExportModalOpen(true)}>
                <Typography.Text>Export</Typography.Text>
              </ContextMenuOption>
            </IfHasPermission>
            <ContextMenuOption onClick={openVersionDrawer}>
              <Typography.Text>Versions</Typography.Text>
            </ContextMenuOption>
            <IfHasPermission require={userPermissionEnum.MANAGE_PITCH}>
              {predictedContentType === 'PITCH' && (
                <>
                  <Divider $margin="8px" />
                  {isPredictedContentActive && (
                    <ContextMenuOption
                      onClick={handleDeactivatePredictedContent}
                    >
                      <Typography.Text $colorName="redOrange">
                        Delete {predictedContentDisplayName}
                      </Typography.Text>
                    </ContextMenuOption>
                  )}
                </>
              )}
              {predictedContentType === 'PRESS_RELEASE' && (
                <>
                  <Divider $margin="8px" />
                  <ContextMenuOption onClick={handlePressReleaseDelete}>
                    <Typography.Text $colorName="redOrange">
                      Delete {predictedContentDisplayName}
                    </Typography.Text>
                  </ContextMenuOption>
                </>
              )}
            </IfHasPermission>
          </ContextMenu>
        </FlexBlock>
      )}
      <Modal open={exportModalOpen}>
        <FlexBlock justifyContent="center" padding="24px 24px">
          <RoundIconWrapper size={88}>
            <DownloadIcon />
          </RoundIconWrapper>
        </FlexBlock>

        <FlexBlock
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          rowGap="8px"
          padding="8px 0"
        >
          <Typography.Text $dmSans $bold $size={24}>
            Export
          </Typography.Text>
          <Typography.Text>Choose the format for your export.</Typography.Text>
        </FlexBlock>

        <FlexBlock
          flexDirection="column"
          alignItems="stretch"
          padding="18px 24px"
          rowGap="16px"
        >
          <SelectableButton
            active={selectedExportOption === exportOptionsEnum.pdf}
            onClick={() => setSelectedExportOption(exportOptionsEnum.pdf)}
          >
            <Typography.Text $bold>PDF</Typography.Text>
            <Typography.Text $colorName="steel">.PDF</Typography.Text>
          </SelectableButton>

          <SelectableButton
            active={selectedExportOption === exportOptionsEnum.csv}
            onClick={() => setSelectedExportOption(exportOptionsEnum.csv)}
          >
            <Typography.Text $bold>CSV</Typography.Text>
            <Typography.Text $colorName="steel">.CSV</Typography.Text>
          </SelectableButton>
        </FlexBlock>

        <FlexBlock
          justifyContent="center"
          alignItems="center"
          columnGap="12px"
          padding="8px 24px 40px"
        >
          <Button variant="secondary" compact onClick={handleExportModalClose}>
            Cancel
          </Button>
          <Button
            compact
            disabled={!selectedExportOption}
            onClick={handleSubmit}
            isLoading={exportLoading}
          >
            Submit
          </Button>
        </FlexBlock>
      </Modal>

      <SideDrawer open={detailsSidebarOpen}>
        <PitchSummary
          summaryDisplayName={predictedContentDisplayName}
          content={content}
          prediction={prediction}
          onCloseClick={() => setDetailsSidebarOpen(false)}
        />
      </SideDrawer>
    </>
  );
};

export default PitchHeader;
