import CarrotTopIcon from 'app/assets/icons/CarrotTopIcon';
import Typography from 'app/components/Typography';
import { CloseIcon } from 'app/components/UI/CloseIcon';
import FlexBlock from 'app/components/UI/FlexBlock';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { theme } from 'styles/theme';
import { useContentTypeSelectorHeader } from './hooks/useContentTypeSelectorHeader.hook';

import * as S from './ContentTypeSelector.styles';
import ContentSelectorMenu from './ContentSelectorMenu';
import { Spinner } from 'app/components/UI/Spinner';

interface IProps {
  setSelectedOptions: Dispatch<SetStateAction<string[] | undefined>>;
  options: {
    key: string;
    displayName: string;
    count: number;
    checked?: boolean;
  }[];
  compactView?: boolean;
  title?: string;
  isLoading?: boolean;
}

const ContentTypeSelector: FC<IProps> = ({
  options,
  setSelectedOptions,
  compactView = false,
  title,
  isLoading,
}) => {
  const {
    selectedCount,
    selectedDisplayNames,
    selectorTitle,
    optionsHeaderTitle,
  } = useContentTypeSelectorHeader(options, title);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleFilterRemove = (key: string) => {
    setSelectedOptions(
      prevState => prevState?.filter(option => option !== key) || undefined,
    );
  };

  const applyCompactInputStyles = Boolean(
    selectedCount && selectedCount < options.length,
  );

  return (
    <FlexBlock
      alignItems="center"
      justifyContent="space-between"
      columnGap="16px"
    >
      {!compactView && (
        <Typography.Text $size={14} $lineHeight={16} $colorName="steel">
          Show:
        </Typography.Text>
      )}
      <>
        <S.Input
          onClick={() => setIsMenuOpen(prev => !prev)}
          $isActive={isMenuOpen}
          $isCompact={compactView}
          $compactInputStyles={applyCompactInputStyles}
        >
          <FlexBlock
            alignItems="center"
            justifyContent="space-between"
            minWidth="100%"
          >
            <FlexBlock alignItems="center">
              {isLoading && (
                <Spinner $size="16px" $isBlue $margin="0 10px 0 0" />
              )}
              <Typography.Text $colorName="onyx" $size={14} $lineHeight={16}>
                {selectorTitle}
              </Typography.Text>
            </FlexBlock>

            <CarrotTopIcon rotateDown={true} fill={theme.colors.orbit} />
          </FlexBlock>

          {isMenuOpen && (
            <ContentSelectorMenu
              options={options}
              setSelectedOptions={setSelectedOptions}
              menuTitle={optionsHeaderTitle}
              open={isMenuOpen}
              setOpen={setIsMenuOpen}
              compactView={compactView}
            />
          )}
        </S.Input>
        {!compactView && (
          <FlexBlock
            alignItems="center"
            justifyContent="space-between"
            columnGap="8px"
          >
            {selectedCount < options.length &&
              selectedDisplayNames.map(({ displayName, key }) => (
                <S.ActiveFilterButton key={key}>
                  <Typography.Text
                    $colorName="orbit"
                    $size={14}
                    $lineHeight={16}
                  >
                    {displayName}
                  </Typography.Text>
                  <CloseIcon orbit onClick={() => handleFilterRemove(key)} />
                </S.ActiveFilterButton>
              ))}
          </FlexBlock>
        )}
      </>
    </FlexBlock>
  );
};

export default ContentTypeSelector;
