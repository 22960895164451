import { ICustomMediaMember, ICustomTargetMediaDetail } from 'app/api.types';
import { IDataTableColumn } from 'app/components/Table/Table.types';

import {
  JournalistEmailCell,
  JournalistNameCell,
  JournalistOutletCell,
  JournalistSocialLinksCell,
} from '../Table/shared/TableDataCells';

export const getJournalistsMediaListTableConfig: (
  customTargetMedia: ICustomTargetMediaDetail,
) => IDataTableColumn<ICustomMediaMember>[] = customTargetMedia => [
  {
    id: 'Outlet',
    Header: 'Outlet',
    width: '25%',
    //@ts-ignore
    Cell: ({ row }) => (
      <JournalistOutletCell outletName={row?.original?.outlet?.name || ''} />
    ),
  },
  {
    id: 'author',
    Header: 'Author',
    width: '20%',
    //@ts-ignore
    Cell: ({ row }) => {
      return (
        <JournalistNameCell
          name={row.original.journalist?.name || ''}
          journalistId={row?.original?.journalist.id}
          isFavorite={row?.original?.journalist.favorite || false}
          isContributor={row?.original?.journalist?.contributor || false}
        />
      );
    },
  },
  {
    id: 'authorEmail',
    Header: 'Email',
    width: '25%',
    //@ts-ignore
    Cell: ({ row }) => {
      return (
        <JournalistEmailCell email={row.original.journalist?.email || ''} />
      );
    },
  },
  {
    id: 'socials',
    Header: 'Socials',

    disableSort: true,
    width: '30%',
    //@ts-ignore
    Cell: ({ row }) => {
      return (
        <JournalistSocialLinksCell
          socials={row?.original?.journalist?.socials}
        />
      );
    },
  },
];

export const JOURNALIST_ROW_ACTIONS_DISPLAY_VALUES = {
  DELETE: {
    PLURAL: {
      title: 'Delete Media List entries',
      description:
        'You are about to delete several Journalists. Are you sure you want to delete the journalists from your Media List? You will still be able to find the journalists on the All Journalists tab.',
      confirmText: 'Yes, Delete',
      cancelText: 'No, Cancel',
      successMessage: 'Media list entries deleted successfully.',
      errorMessage: 'Could not delete media list entries',
    },
    SINGULAR: {
      title: 'Delete Media List entry',
      description:
        'Are you sure you want to delete the journalist from your Media List? You will still be able to find the journalist on the All Journalists tab.',
      confirmText: 'Yes, Delete',
      cancelText: 'No, Cancel',
      successMessage: 'Media list entry deleted successfully.',
      errorMessage: 'Could not delete media list entry',
    },
  },
};

export const MEDIA_LIST_ACTIONS_DISPLAY_VALUES = {
  DELETE: {
    PLURAL: {
      title: 'Delete Media Lists',
      description:
        'You are about to delete several Media Lists. Are you sure you want to delete the Media Lists? You will still be able to find the journalists again from the All Journalists tab.',
      confirmText: 'Yes, Delete',
      cancelText: 'No, Cancel',
      successMessage: 'Media lists deleted successfully',
      errorMessage: 'Could not delete media lists',
    },
    SINGULAR: {
      title: 'Delete Media List',
      description:
        "Are you sure you want to delete the entire Media List? You won't be able to restore it.",
      confirmText: 'Yes, Delete',
      cancelText: 'No, Cancel',
      successMessage: 'Media list deleted successfully.',
      errorMessage: 'Could not delete media list',
    },
  },
};
