import { FC } from 'react';
import { Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import ProtectedRoute from '../Global/permissions/ProtectedRoute';
import { userPermissionEnum } from '../Global/permissions/userPermissions.enum';
import CreateOrEditBylinePage from './pages/CreateOrEditByline/CreateOrEditBylinePage';
import BylinePage from './pages/BylinePage/BylinePage';

interface IProps {}

const Byline: FC<IProps> = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={RouteConstants.byline.byline}
        component={BylinePage}
        requirePermission={userPermissionEnum.READ_BYLINE}
      />
      <ProtectedRoute
        path={RouteConstants.byline.createByline}
        component={CreateOrEditBylinePage}
        requirePermission={userPermissionEnum.CREATE_BYLINE}
      />
      <ProtectedRoute
        path={RouteConstants.byline.editByline}
        component={CreateOrEditBylinePage}
        requirePermission={userPermissionEnum.MANAGE_BYLINE}
      />
      <ProtectedRoute
        path={RouteConstants.byline.draftByline}
        component={BylinePage}
        requirePermission={userPermissionEnum.MANAGE_BYLINE}
      />
    </Switch>
  );
};

export default Byline;
