import EyeIcon from 'app/assets/icons/EyeIcon';
import styled, { css } from 'styled-components';
import { CommonStyleProps } from 'types/commonTypes';

export const StyledInput = styled.input<
  CommonStyleProps & {
    $withIcon?: boolean;
    $isError?: boolean;
    $borderRadius?: string;
    $textIndent?: string;
    $border?: string;
    variant?:
      | 'default'
      | 'searchBar'
      | 'searchBar-autoWidth'
      | 'textField'
      | 'textFieldWhite'
      | 'loginTextField'
      | 'contentInput';
    disabled?: boolean;
    width?: string | number;
  }
>(
  ({
    $withIcon,
    type,
    $isError,
    $borderRadius,
    $border,
    $textIndent,
    theme,
    $fontSize = 14,
    variant = 'default',
    disabled,
    width,
  }) => css`
    box-sizing: border-box;
    font-size: ${$fontSize}px;
    flex: 1;
    height: 64px;
    padding: ${$withIcon ? '20px 16px 20px 52px' : '20px 16px'};
    padding-right: ${type === 'password' ? '52px' : '16px'};
    ${width ? `width: ${width};` : 'width: 100%;'}
    border: ${$isError ? '1px solid red' : $border || '1px solid #dadee1'};
    border-radius: ${$borderRadius || '12px'};
    background-color: ${theme.colors.white};
    box-shadow: 0 0 0px 1000px white inset;
    transition: border-color 0.3s, box-shadow 0.3s;
    &:focus {
      outline: none;
      border: 1px solid ${$isError ? 'red' : theme.colors.black};
      box-shadow: 0 0 0px 1000px white inset
        ${$isError ? '' : ', 0 2px 8px 0 rgba(9,9,9,0.11)'};
    }

    ${$textIndent &&
    css`
      text-indent: ${$textIndent};
    `}

    ${variant === 'searchBar'
      ? css`
          height: 56px;
          width: 560px;
          background-color: ${theme.colors.smoke};
          box-shadow: none;
        `
      : ''}

    ${variant === 'searchBar-autoWidth'
      ? css`
          height: 56px;
          background-color: ${theme.colors.smoke};
          box-shadow: none;
        `
      : ''}

    ${variant === 'textField'
      ? css`
          height: 60px;
          background-color: transparent;
          box-shadow: none;

          &:focus {
            border-color: ${theme.colors.orbit};
          }
        `
      : ''}

      ${variant === 'textFieldWhite'
      ? css`
          height: 48px;
          background-color: white;
          box-shadow: none;

          &:focus {
            border-color: ${theme.colors.orbit};
          }
        `
      : ''}

    ${variant === 'loginTextField'
      ? css`
          height: 56px;
          background-color: white;
          box-shadow: none;
          font-size: 14px;
          line-height: 20px;
          color: ${theme.colors.onyx};
          padding: 16px;
          border-radius: 8px;
          &::placeholder {
            color: ${theme.colors.aluminum};
            opacity: 1;
          }
          &:focus {
            border-color: ${theme.colors.orbit};
          }
        `
      : ''}

      ${variant === 'contentInput'
      ? css`
          height: 52px;
          background-color: white;
          box-shadow: none;

          &:focus {
            border-color: ${theme.colors.orbit};
            box-shadow: none;
          }
        `
      : ''}

    ${disabled
      ? css`
          background-color: ${theme.colors.haze};
          border-color: ${theme.colors.haze};
          box-shadow: none;
          cursor: default;
        `
      : ''}
  `,
);

export const StyledInputAndIconContainer = styled.div<CommonStyleProps>`
  position: relative;
  width: ${props => props.$width || 'auto'};
`;

export const StyledInputContainer = styled.div<CommonStyleProps>`
  margin: ${p => p.$margin};
  width: ${p => p.$width || '100%'};
`;

export const StyledIconContainer = styled.div<{ variant?: string }>(
  ({ variant = 'default' }) =>
    variant === 'right'
      ? css`
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translate(0, -50%);
        `
      : css`
          z-index: 1;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 16px;
        `,
);

export const StyledEyeIconButton = styled.div<{ $isPasswordVisible: boolean }>`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 6px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s opacity;
  opacity: ${({ $isPasswordVisible }) => ($isPasswordVisible ? 1 : 0.7)};
`;

export const StyledEyeIcon = styled(EyeIcon)``;
