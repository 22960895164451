import styled, { css } from 'styled-components/macro';

export const FullPageCard = styled.div<{ margin?: string; padding?: string }>(
  ({ theme, margin = '40px 0', padding = '12px 0' }) => css`
    background-color: #fff;
    padding: ${padding};
    margin: ${margin};
    grid-column-start: 1;
    grid-column-end: -1;
    height: calc(100% - 80px);
    flex: 1;
    border-radius: 12px;
    border: 1px solid ${theme.colors.smoke};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: min-content;
    overflow: hidden;
    overflow-y: auto;
  `,
);
