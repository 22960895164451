import styled, { css } from 'styled-components';

export const ContentFooter = styled.div(
  ({ theme }) => css`
    position: fixed;
    bottom: 0;
    height: 56px;
    width: 100%;
    display: flex;
    padding: 0 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${theme.colors.smoke};
    background-color: white;
  `,
);

export const ContentFooterLink = styled.a(
  ({ theme }) => css`
    text-decoration: none !important;
    color: ${theme.colors.aluminum}!important;
  `,
);

export const ContentHeaderContainer = styled.div<{
  contentLayoutMaxWidth?: boolean;
}>(
  ({ theme, contentLayoutMaxWidth }) => css`
    margin: 0 auto;
    width: ${contentLayoutMaxWidth ? theme.layout.width : '100%'};
    max-width: ${contentLayoutMaxWidth ? theme.layout.maxWidth : 'auto'};
    padding-left: ${contentLayoutMaxWidth ? '0' : '32px'};
    padding-right: ${contentLayoutMaxWidth ? '0' : '40px'};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
);

interface ContentHeaderWrapperProps {
  autoHeight?: boolean;
  padding?: string;
}

export const ContentHeaderWrapper = styled.div<ContentHeaderWrapperProps>(
  ({ theme, autoHeight, padding }) => css`
    width: 100%;
    border-bottom: 1px solid ${theme.colors.smoke};
    display: flex;
    justify-content: center;
    padding: ${padding || '12px 0'};
    height: ${autoHeight ? 'auto' : '80px'};
  `,
);

export const Content = styled.div`
  width: 100%;
`;
