import {
  useCreateSuggestedSocialPostContentMutation,
  useGetSocialPostQuery,
  useGetSuggestedSocialPostContentMutation,
  useLazyGetSuggestedSocialPostContentByIdQuery,
} from 'app/api';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { initialState, reducer } from './useSocialPostsReducer';
import {
  ICreateSocialPostContentPayload,
  ISocialPostContent,
  ISocialPostContentBase,
  ISocialPostContentVersion,
  SocialPostType,
} from '../types/SocialPost.types';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { makeCreateSuggestedContentPayload } from '../../CreateOrEditSocialPosts/CreateOrEditSocialPostsPage.helpers';

export const useSocialPostsData = () => {
  const { socialPostsId } = useParams<{ socialPostsId: string }>();
  const socialPostId = useMemo(() => Number(socialPostsId), [socialPostsId]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [isSavingContent, setIsSavingContent] = useState(false);

  const {
    data: socialPost,
    isFetching,
    isLoading,
    isError,
  } = useGetSocialPostQuery(socialPostId, {
    skip: !socialPostId,
  });

  const [
    getSocialPostById,
    {
      isLoading: isLoadingSocialPostContent,
      isFetching: isFetchingSocialPostContent,
    },
  ] = useLazyGetSuggestedSocialPostContentByIdQuery();

  const [suggestSocialPostContent] = useGetSuggestedSocialPostContentMutation();

  const [createSocialPostContent] =
    useCreateSuggestedSocialPostContentMutation();

  const groupedContents = useMemo(() => {
    if (!socialPost || !socialPost.socialPostContents)
      return {} as Record<SocialPostType, ISocialPostContentVersion>;

    return socialPost.socialPostContents.reduce((acc, content) => {
      const { postType, version } = content as ISocialPostContentVersion;
      if (!acc[postType] || acc[postType].version < version) {
        acc[postType] = content as ISocialPostContentVersion;
      }
      return acc;
    }, {} as Record<SocialPostType, ISocialPostContentVersion>);
  }, [socialPost]);

  const isContentLoadedBySocialType = useCallback(
    (socialPostType: SocialPostType) => {
      return state.socialPostContents[socialPostType] !== undefined;
    },
    [state.socialPostContents],
  );

  const isContentLoadingBySocialType = useCallback(
    (socialPostType: SocialPostType) => {
      return state.loading[socialPostType] === true;
    },
    [state.loading],
  );

  const handleContentChange = useCallback(
    (content: string, postType: SocialPostType) => {
      dispatch({
        type: 'SET_EDITED_CONTENT',
        postType: postType,
        editedContent: content,
      });
    },
    [],
  );

  const handleVideoDescriptionChange = useCallback(
    (content: string, postType: SocialPostType) => {
      dispatch({
        type: 'SET_EDITED_VIDEO_DESCRIPTION',
        postType: postType,
        editedVideoDescription: content,
      });
    },
    [],
  );

  const canSaveContent = useMemo(() => {
    if (isSavingContent) return false;

    const socialPostContents = state.socialPostContents;
    const editedContent = state.editedContent;
    const editedVideoDescription = state.editedVideoDescription;

    return Object.keys(editedContent).some(type => {
      const postType = type as SocialPostType;
      const original = socialPostContents[postType];
      const edited = editedContent[postType];

      if (!edited || !original) {
        return false;
      }

      const isContentChanged = edited !== original.post;
      const isVideoDescriptionChanged =
        editedVideoDescription[postType] !== undefined &&
        original.videoDescription !== undefined
          ? editedVideoDescription[postType] !== original.videoDescription
          : false;

      return isContentChanged || isVideoDescriptionChanged;
    });
  }, [
    state.editedContent,
    state.socialPostContents,
    state.editedVideoDescription,
    isSavingContent,
  ]);

  const handleContentSave = useCallback(async () => {
    const socialPostContents = state.socialPostContents;
    const editedContent = state.editedContent;
    const editedVideoDescription = state.editedVideoDescription;

    const editedPosts = Object.keys(editedContent)
      .map(type => {
        const postType = type as SocialPostType;
        const original = socialPostContents[postType];
        const edited = editedContent[postType];
        const editedDescription = editedVideoDescription[postType];

        if (!edited || !original) {
          return null;
        }

        const isContentChanged = edited !== original.post;
        const isVideoDescriptionChanged =
          editedDescription !== undefined &&
          original.videoDescription !== undefined
            ? editedDescription !== original.videoDescription
            : false;

        console.log('Video description changed: ', isVideoDescriptionChanged);

        if (isContentChanged || isVideoDescriptionChanged) {
          return {
            ...original,
            post: edited,
            ...(editedDescription !== undefined && {
              videoDescription: editedDescription,
            }),
          };
        }

        return null;
      })
      .filter((post): post is ISocialPostContent => post !== null);

    try {
      setIsSavingContent(true);
      const editedPostsPromises = editedPosts.map(content => {
        const payload = {
          content: {
            contentType: content.postType,
            post: content.post,
            videoDescription: content.videoDescription,
          },
          tone: content.tone,
          socialPostType: content.postType,
          socialPostId: content.socialPostId,
        };

        return createSocialPostContent(payload).unwrap();
      });

      const savedContent = await Promise.all(editedPostsPromises);

      savedContent.forEach(content => {
        dispatch({
          type: 'SET_CONTENT',
          postType: content.postType,
          content: content,
        });
      });

      showSuccessToast('Social post content has been successfully saved.');
    } catch (error) {
      showErrorToast(
        'Could not save post content.',
        getErrorText(error as FetchBaseQueryError),
      );
    } finally {
      setIsSavingContent(false);
    }
  }, [
    state.socialPostContents,
    state.editedContent,
    state.editedVideoDescription,
    createSocialPostContent,
  ]);

  const handleContentRegenerate = useCallback(
    async (tone: string, contentType?: SocialPostType) => {
      if (!socialPost || !socialPost.topic) {
        showErrorToast('Cannot regenerate social posts. Missing parameters.');
        return;
      }

      const socialPostTypesToRegenerate = contentType
        ? [contentType]
        : (Object.keys(state.socialPostContents) as SocialPostType[]);

      try {
        dispatch({
          type: 'SET_MULTIPLE_LOADING',
          postTypes: socialPostTypesToRegenerate,
          loading: true,
        });

        const suggestedContent = await suggestSocialPostContent({
          socialPostTypes: socialPostTypesToRegenerate,
          tone,
          topic: socialPost.topic,
        }).unwrap();

        const suggestedContentPromises = (
          suggestedContent as ISocialPostContentBase[]
        )
          .map(content => makeCreateSuggestedContentPayload(content))
          .filter(
            (payload): payload is ICreateSocialPostContentPayload =>
              payload !== null && payload.content !== undefined,
          )
          .map(content => {
            return createSocialPostContent({
              socialPostId: socialPost.id,
              ...content,
            }).unwrap();
          });

        const savedContent = await Promise.all(suggestedContentPromises);

        savedContent.forEach(content => {
          dispatch({
            type: 'SET_CONTENT',
            postType: content.postType,
            content: content,
          });
          dispatch({
            type: 'SET_MULTIPLE_LOADING',
            postTypes: socialPostTypesToRegenerate,
            loading: false,
          });
        });

        const isMultiplePostsGenerated = savedContent.length > 1;

        showSuccessToast(
          `Social ${
            isMultiplePostsGenerated ? 'posts have' : 'post has'
          } been successfully regenerated!`,
        );
      } catch (error) {
        dispatch({
          type: 'SET_MULTIPLE_LOADING',
          postTypes: socialPostTypesToRegenerate,
          loading: false,
        });
        showErrorToast(
          'Something went wrong.',
          getErrorText(error as FetchBaseQueryError),
        );
      }
    },
    [
      createSocialPostContent,
      socialPost,
      state.socialPostContents,
      suggestSocialPostContent,
    ],
  );

  const loadContentById = useCallback(
    async (id: number, postType: SocialPostType) => {
      try {
        dispatch({
          type: 'SET_LOADING',
          postType: postType,
          loading: true,
        });
        const socialPostContent = await getSocialPostById(id, true).unwrap();
        dispatch({
          type: 'SET_CONTENT',
          postType: socialPostContent.postType,
          content: socialPostContent,
        });
      } catch (error) {
        console.log('Could not load content by ID: ', error);
      } finally {
        dispatch({
          type: 'SET_LOADING',
          postType: postType,
          loading: false,
        });
      }
    },
    [getSocialPostById],
  );

  useEffect(() => {
    const latestContentIds = Object.values(groupedContents).map(content => ({
      id: content.id,
      postType: content.postType,
    }));

    if (latestContentIds.length > 0) {
      latestContentIds.forEach(({ id, postType }) => {
        if (
          !isContentLoadedBySocialType(postType) &&
          !isContentLoadingBySocialType(postType)
        )
          loadContentById(id, postType);
      });
    }
  }, [
    groupedContents,
    loadContentById,
    isContentLoadedBySocialType,
    isContentLoadingBySocialType,
  ]);

  return {
    socialPost,
    socialPostsState: state,
    isLoading,
    isFetching,
    isLoadingSocialPostContent,
    isFetchingSocialPostContent,
    isError,
    canSaveContent,
    isSavingContent,
    loadContentById,
    handleContentChange,
    handleVideoDescriptionChange,
    handleContentRegenerate,
    handleContentSave,
  };
};
