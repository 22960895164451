import { IAuthorEmailSuggestionsPayload } from 'app/api.types';
import { usePitchSlice } from 'app/containers/Pitch/slice';
import {
  selectEmailSuggestionsPayload,
  selectEmailSuggestionsResponse,
  selectIsEmailSuggestionsLoading,
} from 'app/containers/Pitch/slice/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useSuggestionsData = () => {
  const dispatch = useAppDispatch();
  const pitchSlice = usePitchSlice();
  const emailSuggestions = useAppSelector(selectEmailSuggestionsResponse);
  const emailSuggestionsPayload = useAppSelector(selectEmailSuggestionsPayload);
  const isLoading = useAppSelector(selectIsEmailSuggestionsLoading);

  const setPayload = (requestBody: IAuthorEmailSuggestionsPayload) => {
    dispatch(
      pitchSlice.actions.setEmailSuggestionsPayload({ payload: requestBody }),
    );
  };

  const clearResponseState = () => {
    dispatch(pitchSlice.actions.clearEmailSuggestionsResponse());
  };

  const toggleGeneration = (
    requestBody?: IAuthorEmailSuggestionsPayload,
    regenerate?: boolean,
  ) => {
    dispatch(
      pitchSlice.actions.toggleEmailSuggestions({
        payload: requestBody,
        regenerate: regenerate || false,
      }),
    );
  };

  return {
    data: emailSuggestions,
    clearResponseState,
    payload: emailSuggestionsPayload,
    toggleGeneration,
    setPayload,
    isLoading,
  };
};
