import { Spinner } from 'app/components/UI/Spinner';
import { FC } from 'react';
import { TabItem } from './JournalistTab.styles';
import {
  IJournalistTabProps,
  IJournalistTabViewProps,
  TableView,
} from './JournalistTabs';

const Tab: FC<IJournalistTabProps & IJournalistTabViewProps> = ({
  tableView,
  label,
  amount,
  setTableView,
  isActive,
  onClickLogic,
  isFetching,
}) => {
  const tableViewHandler = (label: TableView, isActive: boolean) => {
    if (isActive) {
      setTableView(label);
    }
    if (onClickLogic) {
      onClickLogic();
    }
  };

  return (
    <TabItem
      tableView={tableView}
      label={label}
      onClick={() => tableViewHandler(label, isActive)}
      isActive={isActive}
    >
      {`${label} `}
      {tableView === label ? (
        isFetching ? (
          <Spinner $isBlue $margin="0 0 0 5px" />
        ) : amount ? (
          `(${amount})`
        ) : (
          ''
        )
      ) : (
        <></>
      )}
    </TabItem>
  );
};

export default Tab;
