import { FC } from 'react';
import * as S from './AuthorizedUIWrapper.styles';

import Toolbar from './parts/Toolbar/Toolbar';
import { ToolbarTitlePortalProvider } from './parts/ToolbarTitlePortal/ToolbarTitlePortal.context';

interface IProps {}

const AuthorizedUIWrapper: FC<IProps> = ({ children }) => {
  return (
    <ToolbarTitlePortalProvider>
      <S.PageWrapper id="main-app-content">
        <S.MainContainer>
          <S.HeaderContainer>
            <Toolbar />
          </S.HeaderContainer>

          <S.ContentContainer id="app-main-content-scroll-container">
            {children}
          </S.ContentContainer>
        </S.MainContainer>
      </S.PageWrapper>
    </ToolbarTitlePortalProvider>
  );
};

export default AuthorizedUIWrapper;
