import CheckCircleIcon from 'app/assets/icons/CheckCircleIcon';
import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import RoundedPill from 'app/components/UI/RoundedPill';
import Input from 'app/components/UI/Input/Input';
import { ChangeEvent, FC, ReactNode, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import * as S from '../CreateOrEditBioPage.styles';
import { validateLinkedInUrl } from '../CreateOrEditBioPage.helpers';
import OptionalDetails, { IOptionalDetails } from './OptionalDetails';
import Button from 'app/components/UI/Button/Button';
import { useGetGeneratedBioMutation } from 'app/api';
import { showCustomToast, showErrorToast } from 'utils/toast';
import { getErrorText } from 'utils/error-messages';
import ContentGenerationModal from './GenerationModal';
import { useHistory, useParams } from 'react-router-dom';
import { RouteConstants } from 'app/routes';
import { IBioSuggestionContentPayload } from 'app/api.types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import useLimitExceededPopup from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/hooks/useLimitExceededPopup';
import { useBioGenerationLimits } from '../../BioPage/hooks/useBioGenerationsLimits';
import InfoTip from 'app/components/UI/InfoTip/InfoTip';
import { BioGenerationSuccessToast } from '../../BioPage/parts/BioGenerationSuccessToast';

interface IFormSectionProps {
  index: number;
  title: string;
  subtitle: string;
  compact: boolean;
  subtitleTooltip?: ReactNode;
  isSubtitleBold?: boolean;
}

const FormSection: FC<IFormSectionProps> = ({
  index,
  title,
  subtitle,
  compact,
  subtitleTooltip,
  isSubtitleBold = false,
  children,
}) => {
  return (
    <FlexBlock flexDirection="column" minWidth="100%">
      <FlexBlock flexDirection="row" alignItems="center" columnGap="12px">
        <RoundedPill variant="orbit" size={40}>
          <Typography.Text
            $colorName="orbit"
            $dmSans
            $bold
            $lineHeight={24}
            $size={compact ? 16 : 20}
          >
            {index}
          </Typography.Text>
        </RoundedPill>
        <Typography.Text
          $colorName="onyx"
          $dmSans
          $bold
          $lineHeight={compact ? 20 : 24}
          $size={compact ? 16 : 18}
        >
          {title}
        </Typography.Text>
      </FlexBlock>
      <Gap size={18} />
      <FlexBlock
        flexDirection="row"
        alignItems="center"
        padding="0 16px 8px 16px"
        minWidth="100%"
      >
        <Typography.Text
          $colorName="nero"
          $lineHeight={24}
          $size={compact ? 14 : 16}
          $bold={isSubtitleBold}
        >
          {subtitle}
        </Typography.Text>
        {subtitleTooltip && subtitleTooltip}
      </FlexBlock>
      {children}
    </FlexBlock>
  );
};

interface ICreateBioForm {
  compact?: boolean;
  isDraft?: boolean;
  payload?: IBioSuggestionContentPayload;
  regenerate?: (
    type: 'short' | 'long' | 'all',
    payload: IBioSuggestionContentPayload,
  ) => void;
}

const CreateBioForm: FC<ICreateBioForm> = ({
  compact = false,
  isDraft = false,
  payload,
  regenerate,
}) => {
  const params: {
    tenantId: string;
    projectId: string;
  } = useParams();

  const [tenantId, projectId] = useMemo(
    () => [Number(params.tenantId), Number(params.projectId)],
    [params],
  );

  const history = useHistory();

  const limitExceededPopup = useLimitExceededPopup();

  const { bioGenerationsLeft, refresh: refreshLimits } =
    useBioGenerationLimits();

  const [description, setDescription] = useState(payload?.eventBrief);
  const [linkedInUrl, setLinkedInUrl] = useState(payload?.linkedInURL);

  const [optionalDetails, setOptionalDetails] = useState<IOptionalDetails>({
    emphasizeEducation: payload?.emphasizeEducation,
    emphasizeRole: payload?.emphasizeRole,
    emphasizeInterests: payload?.emphasizeInterests,
  });

  const [isValidLinkedInURL, setIsValidLinkedInURL] = useState<boolean | null>(
    null,
  );

  const validateLinkedInProfileURL = (linkedInURL: string) => {
    if (!validateLinkedInUrl(linkedInURL)) {
      setIsValidLinkedInURL(false);
    } else {
      setIsValidLinkedInURL(true);
    }
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const [generateBio, { isLoading: isGenerateLoading }] =
    useGetGeneratedBioMutation();

  const canGenerate =
    !isGenerateLoading &&
    linkedInUrl &&
    (isValidLinkedInURL !== false || isValidLinkedInURL === null);

  const handleGenerate = async () => {
    if (!linkedInUrl) return;

    if (bioGenerationsLeft === 0) {
      limitExceededPopup.show();
      return;
    }

    const generationPayload: IBioSuggestionContentPayload = {
      eventBrief: description,
      linkedInURL: linkedInUrl,
      ...optionalDetails,
    };

    try {
      const generatedBio = await generateBio(generationPayload).unwrap();

      showCustomToast(
        <BioGenerationSuccessToast
          currentLimit={bioGenerationsLeft - 1}
          message="Bio Generated"
        />,
        'toast-violet',
      );

      refreshLimits();

      history.push({
        pathname: RouteConstants.bio.makeCreateDraftBioUrl(tenantId, projectId),
        state: {
          generatedResponse: generatedBio,
          generationPayload: generationPayload,
        },
      });
    } catch (error) {
      showErrorToast(
        getErrorText(error as FetchBaseQueryError),
        'Failed to Generate Bio',
      );
    }
  };

  const handleRegenerate = () => {
    if (!linkedInUrl || !regenerate) return;

    const regenerationPayload: IBioSuggestionContentPayload = {
      eventBrief: description || undefined,
      linkedInURL: linkedInUrl,
      ...optionalDetails,
    };

    regenerate('all', regenerationPayload);
  };

  return (
    <>
      <FormSection
        index={1}
        title="Brief (optional)"
        subtitle="If applicable, give us an overview of the event or use for this Bio (conference, webinar, etc.)"
        compact={compact}
      >
        <S.ContentInputWrapper>
          <S.NativeInputBox
            value={description || ''}
            onChange={handleDescriptionChange}
            minRows={10}
            maxRows={20}
          />
        </S.ContentInputWrapper>
      </FormSection>
      <Gap size={30} />
      <FormSection
        index={2}
        title="LinkedIn URL"
        subtitle="Who’s this bio about?"
        compact={compact}
        subtitleTooltip={
          <InfoTip
            tooltipPosition="right"
            tooltipText={
              compact
                ? 'Create a new Bio to use a different URL.'
                : 'Creative license may be taken if a LinkedIn profile doesn’t contain enough information'
            }
          />
        }
      >
        <Input
          disabled={isDraft || compact}
          value={linkedInUrl}
          onChange={e => setLinkedInUrl(e.target.value)}
          placeholder="Copy/Paste a LinkedIn URL"
          onBlur={() => validateLinkedInProfileURL(linkedInUrl || '')}
          errors={
            !isValidLinkedInURL && isValidLinkedInURL !== null
              ? ['Enter a valid LinkedIn profile URL.']
              : undefined
          }
          iconVariant="right"
          icon={
            isValidLinkedInURL ? (
              <CheckCircleIcon
                width={24}
                height={24}
                fill={theme.colors.kermit}
              />
            ) : undefined
          }
        />
      </FormSection>
      <Gap size={30} />
      <FormSection
        index={3}
        title="Other Optional Details"
        subtitle="Would you like bio to emphasize any of the following?"
        isSubtitleBold={true}
        compact={compact}
      >
        <OptionalDetails
          compact={compact}
          optionalDetails={optionalDetails}
          setOptionalDetails={setOptionalDetails}
        />
      </FormSection>
      <Gap size={24} />
      <S.FullWidth>
        <Button
          onClick={compact ? handleRegenerate : handleGenerate}
          disabled={!canGenerate}
        >
          <Typography.Text
            $dmSans
            $bold
            $size={18}
            $lineHeight={24}
            $colorName="white"
          >
            {compact ? 'Save & Regenerate' : 'Generate Bio'}
          </Typography.Text>
        </Button>
      </S.FullWidth>
      {!compact && <ContentGenerationModal isOpen={isGenerateLoading} />}
    </>
  );
};

export default CreateBioForm;
