import { ITenant } from 'app/api.types';
import FlexBlock from 'app/components/UI/FlexBlock';
import { memo } from 'react';
import Avatar from 'app/components/UI/Avatar';
import {
  EllipsisText,
  SSOLabel,
  TenantOption as TenantOptionContainer,
} from './TenantModal.styles';
import Typography from 'app/components/Typography';

const MemoizedAvatar = memo(Avatar, (prevProps, nextProps) => {
  return (
    prevProps.url === nextProps.url &&
    prevProps.size === nextProps.size &&
    prevProps.name === nextProps.name
  );
});

const TenantOption = memo(
  ({
    option,
    onClickHandler,
  }: {
    option: ITenant;
    onClickHandler: (option: ITenant) => void;
  }) => (
    <TenantOptionContainer
      key={`${option.id}-${option.name}`}
      onClick={() => onClickHandler(option)}
    >
      <FlexBlock
        flexDirection="row"
        minHeight="56px"
        padding="8px"
        alignItems="center"
        columnGap="10px"
      >
        <MemoizedAvatar
          url={option.logo}
          size="32"
          name={option.name}
          margin="0"
        />
        <EllipsisText $size={14} $lineHeight={16} $colorName="onyx">
          {option.name}
        </EllipsisText>
      </FlexBlock>

      {option.sso && (
        <SSOLabel>
          <Typography.Text $colorName="orbit" $size={14} $lineHeight={20}>
            SSO
          </Typography.Text>
        </SSOLabel>
      )}
    </TenantOptionContainer>
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.option.id === nextProps.option.id &&
      prevProps.option.name === nextProps.option.name &&
      prevProps.onClickHandler === nextProps.onClickHandler
    );
  },
);

export default TenantOption;
