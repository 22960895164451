import styled, { css } from 'styled-components';

const Wrapper = styled.div<{
  noBorder?: boolean;
  padding?: string;
  directionColumnBreakPoint?: number;
}>(
  ({
    theme,
    noBorder,
    padding = '32px 0 24px',
    directionColumnBreakPoint,
  }) => css`
    display: flex;
    padding: ${padding};
    border-bottom: 1px solid ${theme.colors.smoke};
    grid-column-start: 1;
    grid-column-end: -1;
    height: auto;
    ${noBorder
      ? css`
          border: none;
        `
      : ''}

    ${directionColumnBreakPoint
      ? css`
          @media (max-width: ${directionColumnBreakPoint}px) {
            flex-direction: column;
            row-gap: 12px;
          }
        `
      : ''}
  `,
);
const Section = styled.div<{
  justifyContent?: 'flex-start' | 'flex-end' | 'center';
  alignItems?: string;
  $padding?: string;
  gridColumnGap?: string;
  flex?: string;
  wrap?: boolean;
  height?: string;
}>(
  ({
    justifyContent = 'flex-start',
    $padding = 'none',
    gridColumnGap = '0px',
    flex = '1',
    alignItems = 'center',
    wrap,
    height,
  }) => css`
    display: flex;
    flex: ${flex};
    height: ${height || 'auto'};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    padding: ${$padding};
    grid-column-gap: ${gridColumnGap};
    ${wrap
      ? css`
          flex-wrap: wrap;
        `
      : ''}
  `,
);

const PageHeader = {
  Wrapper,
  Section,
};

export default PageHeader;
