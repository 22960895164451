import styled, { css } from 'styled-components/macro';

interface ISelectableButtonProps {
  active?: boolean;
}

export const SelectableButton = styled.button<ISelectableButtonProps>(
  ({ theme, active }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 16.5px;
    border: 1px solid ${theme.colors.aluminum};
    background: #fff;
    border-radius: 8px;
    transition: 0.25s all ease-in-out;
    cursor: pointer;

    &:hover {
      border: 1px solid ${theme.colors.orbit};
    }

    ${active
      ? css`
          background: ${theme.colors.lightBlue};
          border: 1px solid ${theme.colors.orbit};
        `
      : ''}
  `,
);
