import { FC } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { selectAuthorized } from '../slice/selectors';
import AuthorizedUIWrapper from '../../../components/AuthorizedUIWrapper/AuthorizedUIWrapper';
import { RouteConstants } from '../../../routes';
import { userPermissionEnum } from './userPermissions.enum';
import ProtectedRoute from './ProtectedRoute';
import { useMonitorUserRouteGuard } from './useMonitorUserRouteGuard.hook';

interface IProps extends RouteProps {
  requirePermission?: userPermissionEnum;
}

export const AuthorizedRoute: FC<IProps> = props => {
  const authorized = useAppSelector(selectAuthorized);

  const shouldRedirectToMonitorAlertsPage = useMonitorUserRouteGuard();

  if (!authorized) return <Redirect to={RouteConstants.authorization.login} />;

  if (shouldRedirectToMonitorAlertsPage)
    return <Redirect to={RouteConstants.misc.monitor} />;

  return (
    <AuthorizedUIWrapper>
      <ProtectedRoute {...props} />
    </AuthorizedUIWrapper>
  );
};
