import { FC, useEffect, useRef } from 'react';
import ContextMenu, {
  ContextMenuOption,
  IButtonTriggerProps,
} from '../../../../UI/ContextMenu/ContextMenu';
import * as S from '../Toolbar.styles';
import Avatar from '../../../../UI/Avatar';
import CarrotDownIcon from '../../../../../assets/icons/CarrotDownIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { selectUser } from '../../../../../containers/Global/slice/selectors';
import { Divider } from '../../../../UI/Divider';
import Typography from '../../../../Typography';
import FlexBlock from '../../../../UI/FlexBlock';
import { RouteConstants } from '../../../../../routes';
import { NavLink } from 'react-router-dom';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';
import { useGlobalSlice } from 'app/containers/Global/slice';
import UpgradePlanDrawer from './UpgradePlanMenu/UpgradePlanMenu';
import { MonitorNavigationLink } from './Links/MonitorNavigationLink';
import { NewsWireNavigationLink } from './Links/NewsWireNavigationLink';

interface IProps {}

const UserMenu: FC<IProps> = () => {
  const user = useAppSelector(selectUser);

  const { actions: globalActions } = useGlobalSlice();
  const dispatch = useAppDispatch();

  const toggleNeedHelp = () => {
    dispatch(globalActions.toggleNeedHelpModal(true));
  };

  const UserMenuButton = ({ onClick, open }: IButtonTriggerProps) => {
    const ref = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target) && open) {
          onClick(event);
        }
      };

      if (open) {
        document.addEventListener('click', handleClickOutside);
      }

      return () => {
        if (open) {
          document.removeEventListener('click', handleClickOutside);
        }
      };
    }, [onClick, open]);

    return (
      <S.DropDownTriggerButton.Wrapper
        onClick={onClick}
        flipSvg={open}
        ref={ref}
      >
        <Avatar
          margin="0 0 0 8px"
          size="40"
          name={`${user?.firstName} ${user?.lastName}`}
          url={user?.avatar}
        />
        <CarrotDownIcon />
      </S.DropDownTriggerButton.Wrapper>
    );
  };

  const ToolsMenu = ({ onClick, open }: IButtonTriggerProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target) && open) {
          onClick(event);
        }
      };

      if (open) {
        document.addEventListener('click', handleClickOutside);
      }

      return () => {
        if (open) {
          document.removeEventListener('click', handleClickOutside);
        }
      };
    }, [onClick]);

    return (
      <S.NavigationContextMenuLink onClick={onClick} ref={ref}>
        <Typography.Text
          $colorName={!open ? 'white' : 'stellar'}
          $size={16}
          $lineHeight={24}
          $dmSans
          $bold
        >
          Tools
        </Typography.Text>
      </S.NavigationContextMenuLink>
    );
  };

  return (
    <>
      <S.NavigationWrapper>
        <IfHasPermission require={userPermissionEnum.READ_PROJECTS}>
          <S.NavigationLink to={RouteConstants.projects.index}>
            My Projects
          </S.NavigationLink>
        </IfHasPermission>
        <IfHasPermission require={userPermissionEnum.READ_PROJECTS}>
          <ContextMenu
            buttonText="Tools"
            renderButtonTrigger={ToolsMenu}
            menuOptions={{
              minWidth: '305px',
              maxWidth: '320px',
              align: 'right',
              distanceFromTrigger: 7,
              positionOffsetX: -115,
            }}
          >
            <FlexBlock flexDirection="column" padding="8px 16px" rowGap="16px">
              <MonitorNavigationLink>
                <FlexBlock flexDirection="column" rowGap="4px">
                  <Typography.Text
                    $colorName="onyx"
                    $size={16}
                    $lineHeight={24}
                    $dmSans
                    $bold
                  >
                    Monitor (Beta)
                  </Typography.Text>
                  <Typography.Text
                    $colorName="steel"
                    $size={14}
                    $lineHeight={16}
                  >
                    PeakMetrics can help you monitor press coverage
                  </Typography.Text>
                </FlexBlock>
              </MonitorNavigationLink>
              <NavLink to={RouteConstants.journalists.index}>
                <FlexBlock flexDirection="column" rowGap="4px">
                  <Typography.Text
                    $colorName="onyx"
                    $size={16}
                    $lineHeight={24}
                    $dmSans
                    $bold
                  >
                    Journalist Database
                  </Typography.Text>
                  <Typography.Text
                    $colorName="steel"
                    $size={14}
                    $lineHeight={16}
                  >
                    Browse and explore PRophet's database of journalists
                  </Typography.Text>
                </FlexBlock>
              </NavLink>
              <IfHasPermission require={userPermissionEnum.READ_TARGET_MEDIA}>
                <NavLink to={RouteConstants.journalists.mediaList}>
                  <FlexBlock flexDirection="column" rowGap="4px">
                    <Typography.Text
                      $colorName="onyx"
                      $size={16}
                      $lineHeight={24}
                      $dmSans
                      $bold
                    >
                      Media List
                    </Typography.Text>
                    <Typography.Text
                      $colorName="steel"
                      $size={14}
                      $lineHeight={16}
                    >
                      Create your own Media List
                    </Typography.Text>
                  </FlexBlock>
                </NavLink>
              </IfHasPermission>
              <NewsWireNavigationLink />
            </FlexBlock>
          </ContextMenu>
        </IfHasPermission>
        <IfHasPermission require={userPermissionEnum.READ_USERS}>
          <S.NavigationLink to={RouteConstants.people.index}>
            Users
          </S.NavigationLink>
        </IfHasPermission>
      </S.NavigationWrapper>
      <S.NavigationItemVerticalDivider />
      <S.NeedHelpButton onClick={toggleNeedHelp}>Need Help?</S.NeedHelpButton>
      <S.NavigationItemVerticalDivider />
      <ContextMenu
        buttonText="user"
        renderButtonTrigger={UserMenuButton}
        menuOptions={{
          minWidth: '210px',
          maxWidth: '250px',
          align: 'right',
          distanceFromTrigger: 24,
          positionOffsetX: 18,
          visibleOverFlow: true,
        }}
      >
        <FlexBlock alignItems="center" padding="16px">
          <Avatar
            margin="0 16px 0 0"
            size="40"
            name={`${user?.firstName} ${user?.lastName}`}
            url={user?.avatar}
          />
          <FlexBlock flexDirection="column">
            <Typography.Text $dmSans $bold $size={16} $lineHeight={24}>
              {user?.firstName} {user?.lastName}
            </Typography.Text>
            {user && user.role && (
              <Typography.Text $colorName="orbit">
                {user.role.displayName}
              </Typography.Text>
            )}
          </FlexBlock>
        </FlexBlock>
        <UpgradePlanDrawer />

        <NavLink to={RouteConstants.userProfile.index}>
          <ContextMenuOption>
            <Typography.Text>My Profile</Typography.Text>
          </ContextMenuOption>
        </NavLink>
        <Divider />
        <NavLink to={RouteConstants.authorization.logout}>
          <ContextMenuOption>
            <Typography.Text $colorName="redOrange">Sign Out</Typography.Text>
          </ContextMenuOption>
        </NavLink>
      </ContextMenu>
    </>
  );
};

export default UserMenu;
