// RadioButton.tsx
import styled from 'styled-components';

interface RadioButtonProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
}

export const StyledRadioButton = styled.label`
  display: flex;
  gap: 7px;
`;

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  checked,
  onChange,
}) => {
  return (
    <StyledRadioButton>
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
      />
      {label}
    </StyledRadioButton>
  );
};

export default RadioButton;
