import RemoveIcon from 'app/assets/icons/RemoveIcon';
import styled, { css } from 'styled-components/macro';

interface IListProps {
  gridColumnStart?: number | string;
  gridColumnEnd?: number | string;
}

export const List = styled.div<IListProps>(
  ({ gridColumnStart = 1, gridColumnEnd = -1 }) => css`
    grid-column-start: ${gridColumnStart};
    grid-column-end: ${gridColumnEnd};
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    flex: 1;
  `,
);

export interface IListItemWrapperProps {
  clickable?: boolean;
  disabled?: boolean;
  hazeHover?: boolean;
}

export const ListItemWrapper = styled.div<IListItemWrapperProps>(
  ({ theme, clickable, disabled, hazeHover }) => css`
    padding: 12px;
    margin: 0 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    ${clickable
      ? css`
          &:hover {
            background-color: ${hazeHover
              ? theme.colors.haze
              : theme.colors.lightBlue};
          }
        `
      : ''}

    ${disabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
        `
      : ''}
  `,
);

export const Badge = styled.div(
  ({ theme }) => css`
    font-size: 12px;
    line-height: 16px;
    color: ${theme.colors.onyx};
    background-color: rgba(29, 118, 222, 0.1);
    padding: 6px 12px;
    border-radius: 14px;
  `,
);

export const RemoveButton = styled(RemoveIcon)`
  cursor: pointer;
  padding: 10px;
  box-sizing: content-box;
  opacity: 0.7;
  transition: 0.3s opacity;
  width: 12px;
  height: 12px;
  &:hover {
    opacity: 1;
  }
`;
