import { ChangeEventHandler, FC, useMemo } from 'react';
import {
  IPartialPitchData,
  IPitchContent,
  PitchContentName,
} from '../../CreateOrEditPitch.types';
import * as S from '../../CreateOrEditPitchPage.styles';
import FlexBlock from '../../../../../../components/UI/FlexBlock';
import { CheckboxPill } from '../../../../../../components/UI/CheckboxPill/CheckboxPill';
import { getContentTypeString } from '../ToneSelectDropDown/ToneSelectDropDown.config';
import {
  getScreenSize,
  getWordCount,
} from '../../../../../../../utils/helpers';

import SocialPostCard from '../../../../../../components/SocialPostCard/SocialPostCard';
import {
  ContentWrapper,
  InputBox,
  NativeInputBox,
} from 'app/components/ContentGeneration/ContentGeneration.styles';
import { PredictedContentTab } from 'app/components/ContentGeneration/parts/header/parts/Tabs';

interface IProps {
  pitchData: IPitchContent;
  onChange: (pitchData: IPitchContent) => void;
  showContentSelectPill?: boolean;
  selectedContentName?: PitchContentName | null;
  onSelectedContentChange?: (name: PitchContentName) => void;
  screenEstatePercentage?: number;
  hideSubtitle?: boolean;
  disableEditing?: boolean;
  fadeOut?: boolean;
  padding?: string;
  showTabs?: boolean;
  onTabChange?: (tab: string) => void;
  tab: PredictedContentTab;
  digDeeperView?: boolean;
}

const PitchContentEditor: FC<IProps> = ({
  pitchData,
  onChange,
  showContentSelectPill,
  selectedContentName,
  onSelectedContentChange,
  screenEstatePercentage = 0.5,
  hideSubtitle,
  disableEditing,
  fadeOut,
  padding,
  showTabs,
  onTabChange,
  tab,
  digDeeperView = false,
}) => {
  const handleTextChange: (
    type: keyof IPartialPitchData,
  ) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    type => event => {
      onChange({ ...pitchData, [type]: event.target.value });
    };

  const maxLines = useMemo(() => {
    const screenSize = getScreenSize();
    return Math.floor((screenSize.h * screenEstatePercentage) / 24);
  }, [screenEstatePercentage]);

  const hasSocialData = useMemo(() => !!pitchData.socialPosts, [pitchData]);

  if (!hasSocialData && tab === PredictedContentTab.SOCIALS) return <></>;

  return (
    <S.EditorContainer>
      <ContentWrapper
        $borderOrbit={selectedContentName === pitchData.contentName}
        $digDeeperView={digDeeperView}
      >
        {showContentSelectPill && onSelectedContentChange && (
          <FlexBlock padding="0 0 12px 0">
            <CheckboxPill
              checked={selectedContentName === pitchData.contentName}
              onChange={() => onSelectedContentChange(pitchData.contentName)}
              text={getContentTypeString(pitchData.contentName)}
              disabled={tab === PredictedContentTab.SOCIALS}
            />
          </FlexBlock>
        )}

        {tab === PredictedContentTab.PITCH && (
          <>
            <InputBox
              $noBorder
              placeholder="Headline/Title"
              value={pitchData.headline}
              onChange={handleTextChange('headline')}
              textStyle="title"
              minRows={1}
              disabled={disableEditing}
            />
            <S.BodyWrapper>
              <NativeInputBox
                $noBorder
                placeholder="Pitch Content - add content of your pitch here. At least 30 words are required. Use the Generate button above to generate content or Predict Interest on your pitch to source contacts. Your pitch will not be sent automatically. The next step in the flow will help generate personalized emails to journalists."
                value={pitchData.body}
                onChange={handleTextChange('body')}
                minRows={10}
                maxRows={maxLines}
                disabled={disableEditing}
              />
              {!digDeeperView && (
                <S.WordCountPill>
                  {getWordCount(pitchData.body) || 0} Words
                </S.WordCountPill>
              )}
            </S.BodyWrapper>
          </>
        )}

        {tab === PredictedContentTab.SOCIALS && (
          <S.SocialPostsWrapper>
            <SocialPostCard
              social="twitter"
              content={pitchData.socialPosts?.twitterPost || ''}
            />

            <SocialPostCard
              social="facebook"
              content={pitchData.socialPosts?.facebookPost || ''}
            />

            <SocialPostCard
              social="instagram"
              content={pitchData.socialPosts?.instagramPost || ''}
            />

            <SocialPostCard
              social="linkedin"
              content={pitchData.socialPosts?.linkedinPost || ''}
            />
          </S.SocialPostsWrapper>
        )}
      </ContentWrapper>
    </S.EditorContainer>
  );
};

export default PitchContentEditor;
