import { RouteConstants } from 'app/routes';
import { useRouteMatch } from 'react-router-dom';

export const useCreateOrEditPressReleaseParams = () => {
  const createPitchPage = useRouteMatch<{
    tenantId: string;
    projectId: string;
  }>(RouteConstants.pressRelease.createPressRelease);

  const editPitchPage = useRouteMatch<{
    tenantId: string;
    projectId: string;
    pressReleaseId: string;
    versionId: string;
  }>(RouteConstants.pressRelease.editPressRelease);

  return {
    createParams: createPitchPage,
    editParams: editPitchPage,
  };
};
