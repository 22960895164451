import { localStorageEnum } from '../../../types';

export enum tableViewModeEnum {
  grid = 'grid',
  table = 'table',
}

export function storeViewTypeSelection(
  tableDataName: string,
  value: tableViewModeEnum,
) {
  localStorage.setItem(
    localStorageEnum.tableViewTypePrefix + tableDataName,
    value,
  );
}

export const getStoredViewTypeSelection: (name: string) => tableViewModeEnum = (
  tableDataName: string,
) => {
  const value = localStorage.getItem(
    localStorageEnum.tableViewTypePrefix + tableDataName,
  ) as tableViewModeEnum | null;
  if (value) return value;
  return tableViewModeEnum.grid;
};
