import animation1 from 'app/assets/images/anim-1.gif';
import animation2 from 'app/assets/images/anim-2.gif';
import animation3 from 'app/assets/images/anim-3.gif';
import animation4 from 'app/assets/images/anim-4.gif';
import animation5 from 'app/assets/images/anim-5.gif';
import { localStorageEnum } from '../../../../../../../types';

const animations = [animation1, animation2, animation3, animation4, animation5];

export const getPitchAnimation = (returnLastImage?: boolean) => {
  const lastIndex = Number(
    localStorage.getItem(localStorageEnum.lastPitchAnimation) || -1,
  );
  const nextIndex = lastIndex + 1;

  if (returnLastImage) {
    return animations[Math.max(lastIndex, 0)] || animations[0];
  }

  const animation = animations[nextIndex];
  if (Boolean(animation)) {
    localStorage.setItem(
      localStorageEnum.lastPitchAnimation,
      String(nextIndex),
    );
    return animation;
  } else {
    localStorage.setItem(localStorageEnum.lastPitchAnimation, String(0));
    return animations[0];
  }
};
