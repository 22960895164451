import { FC, useMemo } from 'react';
import { IPreparedReportItem } from '../../MediaReportCard/MediaReportCard.helpers';
import { generateObscuredContent } from '../../../../../../../../utils/helpers';
import Button from '../../../../../../../components/UI/Button/Button';
import { ActionPasserConsumer } from '../../../../../../../../utils/actionPasser/actionPasser';
import { Anchor } from '../../../../../../../components/UI/Anchor';
import { commonAuthorTooltipProps } from '../../../../../../../components/UI/Tooltip/Tooltip';
import { HideOnPrint } from '../../PdfExport/PdfExport';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';

interface IProps {
  row: IPreparedReportItem;
}

const EmailCell: FC<IProps> = props => {
  const fieldDisplayOptions = useMemo(() => {
    return {
      isObscured: props.row.obscured,
      isRestricted: props.row.restricted,
      isBlurred: props.row.obscured || props.row.restricted,
    };
  }, [props.row.obscured, props.row.restricted]);

  const getUpgradeTooltipText = () => {
    if (fieldDisplayOptions.isRestricted) return 'Enterprise Account';
    if (fieldDisplayOptions.isObscured) return 'Pro Account';
    return '';
  };

  const email = useMemo(() => {
    const primary = props.row?.authorSocials?.find(
      s => s.socialType === 'PRIMARY_EMAIL',
    );

    if (!primary) return undefined;

    if (fieldDisplayOptions.isBlurred)
      return generateObscuredContent(primary.url);

    return primary.url;
  }, [props.row, fieldDisplayOptions.isBlurred]);

  if (!email) {
    return (
      <ActionPasserConsumer>
        {({ action }) => (
          <HideOnPrint>
            <FlexBlock
              blur={fieldDisplayOptions.isBlurred ? 5 : undefined}
              disablePointerEvents={fieldDisplayOptions.isBlurred}
            >
              <Button
                variant="anchor"
                onClick={e => {
                  e.stopPropagation();
                  action(props.row);
                }}
              >
                Request
              </Button>
            </FlexBlock>
          </HideOnPrint>
        )}
      </ActionPasserConsumer>
    );
  }

  return (
    <FlexBlock
      {...commonAuthorTooltipProps}
      data-tip={fieldDisplayOptions.isBlurred ? getUpgradeTooltipText() : email}
      data-for={
        fieldDisplayOptions.isBlurred
          ? 'upgradeAccountTooltip'
          : 'authorEmailContactTooltip'
      }
      blur={fieldDisplayOptions.isBlurred ? 5 : undefined}
    >
      <Anchor
        href={`mailto:${email}`}
        variant="black"
        onClick={e => e.stopPropagation()}
        maxWidth="150px"
        $disabled={fieldDisplayOptions.isBlurred}
      >
        {email}
      </Anchor>
    </FlexBlock>
  );
};

export default EmailCell;
