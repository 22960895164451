import { SVGProps } from 'react';

const DotsVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#0C0C0D" transform="translate(-1820 -152)">
        <g transform="translate(260 72)">
          <g transform="translate(64 40)">
            <g transform="translate(1148 20)">
              <g transform="translate(328)">
                <g transform="translate(20 20)">
                  <path d="M10 8c0 1.088-.895 1.97-2 1.97S6 9.088 6 8s.895-1.97 2-1.97c1.104 0 1.999.882 2 1.97zM8 4.34c1.105 0 2-.881 2-1.97C10 1.282 9.105.4 8 .4s-2 .882-2 1.97c.001 1.088.896 1.97 2 1.97zm0 7.32c-1.105 0-2 .881-2 1.97 0 1.088.895 1.97 2 1.97s2-.882 2-1.97c-.001-1.088-.896-1.97-2-1.97z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DotsVertical;
