// RadioGroup.tsx
import React, { useState, memo } from 'react';
import styled from 'styled-components';
import RadioButton from './RadioButton';

interface RadioGroupProps {
  options: { label: string; value: string }[];
  searchByHandler: any;
  selectedSearchType: string | undefined;
}

const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
`;

const RadioGroup: React.FC<RadioGroupProps> = memo(
  ({ options, searchByHandler, selectedSearchType }) => {
    const defaultOption = options.length > 0 ? options[0].value : null;

    const [selectedValue, setSelectedValue] = useState<string | null>(
      selectedSearchType || defaultOption,
    );

    const handleRadioChange = (value: string) => {
      setSelectedValue(value);
      searchByHandler(value);
    };

    return (
      <StyledRadioGroup>
        {options.map(option => (
          <RadioButton
            key={option.value}
            label={option.label}
            value={option.value}
            checked={option.value === selectedValue}
            onChange={handleRadioChange}
          />
        ))}
      </StyledRadioGroup>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if options or searchByHandler change
    return (
      prevProps.options === nextProps.options &&
      prevProps.searchByHandler === nextProps.searchByHandler
    );
  },
);

export default RadioGroup;
