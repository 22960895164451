import FlexBlock from 'app/components/UI/FlexBlock';
import { SkeletonDiv } from 'app/components/UI/LoadingSkeletons/Skeletons.styles';
import { FC } from 'react';

interface ISocialPostsSkeletonProps {}

const SocialPostsSkeleton: FC<ISocialPostsSkeletonProps> = () => {
  return (
    <FlexBlock flexDirection="column" rowGap="8px" minWidth="100%">
      <SkeletonDiv height="30px" shimmerAnimation />
      <SkeletonDiv height="79px" shimmerAnimation />
      <SkeletonDiv height="30px" shimmerAnimation />
      <SkeletonDiv height="40px" shimmerAnimation />
    </FlexBlock>
  );
};

export default SocialPostsSkeleton;
