import { IOption } from 'types/commonTypes';
import {
  taylorSelectorStyles,
  taylorSettingsConfiguration,
} from './TaylorSettings.constants';
import { useUpdateTaylorGeneratorSettingMutation } from 'app/api';
import { ITaylorGeneratorSettingsEndpoint } from 'app/api.types';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import { FC, useState } from 'react';
import { getErrorText } from 'utils/error-messages';
import { showErrorToast } from 'utils/toast';
import Select from 'app/components/UI/Select/Select';

interface ISettingsSelectorProps {
  settings: IOption<string>[];
  settingVariant: keyof typeof taylorSettingsConfiguration;
  value: IOption<string> | undefined;
}

const SettingSection: FC<ISettingsSelectorProps> = ({
  settings,
  settingVariant,
  value,
}) => {
  const [updateSetting, { isLoading }] =
    useUpdateTaylorGeneratorSettingMutation();

  const [selectedValue, setSelectedValue] = useState<
    IOption<string> | undefined
  >(value);

  const handleUpdate = async (endpoint: ITaylorGeneratorSettingsEndpoint) => {
    if (selectedValue && selectedValue.value) {
      try {
        await updateSetting({
          endpoint,
          generatorType: selectedValue.value,
        }).unwrap();
      } catch (error) {
        showErrorToast(getErrorText(error));
      }
    }
  };

  return (
    <FlexBlock flexDirection="column" minWidth="200px" margin="0 0 0 10px">
      <Typography.Text $dmSans $bold $size={16} $margin="0 0 0 10px">
        {taylorSettingsConfiguration[settingVariant].title}
      </Typography.Text>
      <Gap size={12} />
      <Select<IOption<string>>
        {...taylorSelectorStyles}
        options={settings}
        value={selectedValue}
        onChange={val => setSelectedValue(val as IOption<string>)}
      />
      <Gap size={12} />
      <Button
        type="button"
        variant="secondary"
        compact
        disabled={
          isLoading ||
          !selectedValue?.value ||
          value?.value === selectedValue.value
        }
        isLoading={isLoading}
        onClick={() =>
          handleUpdate(
            taylorSettingsConfiguration[settingVariant]
              .endpoint as ITaylorGeneratorSettingsEndpoint,
          )
        }
      >
        Update
      </Button>
    </FlexBlock>
  );
};

export default SettingSection;
