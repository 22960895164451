interface IProps {
  size?: number;
}

const DownloadIcon = ({ size = 24 }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#0C0C0D" transform="translate(-1066 -308)">
        <g transform="translate(834 264)">
          <g transform="translate(212 24)">
            <g transform="translate(20 20)">
              <path d="M15.462 24.816l5.083 5.094a4.879 4.879 0 006.91 0l5.083-5.094h11.005A3.264 3.264 0 0146.8 28.08v13.056a3.264 3.264 0 01-3.257 3.264H4.457A3.264 3.264 0 011.2 41.136V28.08a3.264 3.264 0 013.257-3.264h11.005zm20.752 7.344a2.445 2.445 0 00-2.442 2.448 2.445 2.445 0 002.442 2.448 2.445 2.445 0 002.443-2.448 2.445 2.445 0 00-2.443-2.448zM24 3.6c.9 0 1.629.73 1.629 1.632v17.276l6.99-7.006a1.626 1.626 0 012.726.732c.15.563-.01 1.164-.422 1.576l-9.771 9.792-.015.013a1.647 1.647 0 01-.104.095l-.062.046c-.022.016-.044.034-.067.049-.023.016-.048.03-.072.044l-.064.038-.075.036-.07.033c-.024.01-.048.018-.073.026-.026.01-.051.02-.077.028l-.074.019-.08.02-.086.012a1.638 1.638 0 01-.393.01c-.025-.002-.049-.006-.073-.01-.028-.004-.057-.007-.085-.013l-.081-.02c-.025-.006-.05-.011-.074-.018-.026-.008-.051-.018-.077-.028-.025-.008-.05-.016-.073-.026l-.07-.033-.075-.036-.064-.038c-.024-.014-.049-.028-.072-.044-.023-.015-.045-.033-.067-.05-.02-.014-.042-.029-.062-.045-.035-.03-.069-.06-.102-.093l-.017-.015-9.771-9.792a1.634 1.634 0 01.73-2.73c.562-.151 1.162.01 1.573.422l6.991 7.006V5.232c0-.901.73-1.632 1.629-1.632z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DownloadIcon;
