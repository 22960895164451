import { Anchor } from 'app/components/UI/Anchor';
import Gap from 'app/components/UI/Gap';
import {
  ContentLengthDropDown,
  ToneSelectDropDown,
} from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import { PitchPageContext } from 'app/containers/Pitch/pages/PitchPage/PitchPage.context';
import { useContext } from 'react';

const SelectionTypeToggle = () => {
  const { setIsRapidGenerationForAll, isRapidGenerationForAll } =
    useContext(PitchPageContext);
  return (
    <Anchor
      onClick={() => {
        setIsRapidGenerationForAll(prev => !prev);
      }}
    >
      Select tone and length
      <b>{isRapidGenerationForAll ? ' individually' : ' for all'}</b>.
    </Anchor>
  );
};

const GlobalToneAndLengthSelectors = () => {
  const { globalTone, globalLength, setGlobalLength, setGlobalTone } =
    useContext(PitchPageContext);
  return (
    <>
      <ToneSelectDropDown
        menuPlacement="top"
        value={globalTone}
        onChange={setGlobalTone}
      />
      <Gap size={8} />
      <ContentLengthDropDown
        menuPlacement="top"
        value={globalLength}
        onChange={setGlobalLength}
      />
    </>
  );
};

export { GlobalToneAndLengthSelectors, SelectionTypeToggle };
