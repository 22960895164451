import { showErrorToast, showSuccessToast } from 'utils/toast';
import {
  PressReleaseContentType,
  PressReleaseStateActions,
} from '../CreateOrEditPressRelease.constants';
import { IPressReleaseContentVersion } from '../CreateOrEditPressRelease.types';
import {
  usePressRelease,
  usePressReleaseDispatch,
} from '../context/PressReleaseContext';
import { useLazyGetPressReleaseContentByIdQuery } from 'app/api';

export const usePressReleaseContentHistory = () => {
  const { contentVersions } = usePressRelease();
  const dispatch = usePressReleaseDispatch();

  const [getContentById, { isLoading }] =
    useLazyGetPressReleaseContentByIdQuery();

  const handleContentVersionSelect = async (contentId: number) => {
    try {
      const contentById = contentVersions.find(
        content => content.id === contentId,
      );

      if (contentById) {
        const content = await getContentById({ id: contentId }).unwrap();
        if (content) {
          dispatch({
            type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENTS,
            payload: [
              {
                contentType: PressReleaseContentType.ORIGINAL,
                content: {
                  title: content.title,
                  pressReleaseCopy: content.pressReleaseCopy,
                  quotes: content.quotes,
                  date: content.date,
                  city: content.city,
                  boilerPlate: content.boilerPlate,
                },
              },
            ],
          });
          showSuccessToast('Content updated to selected version.');
        } else {
          showErrorToast('Could not load content version.');
        }
      }
    } catch (error) {
      showErrorToast('Could not load content version.');
    }
  };

  const handleInsertContentVersion = (content: IPressReleaseContentVersion) => {
    if (content) {
      dispatch({
        type: PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT_VERSIONS,
        payload: [...contentVersions, content],
      });
    }
  };

  return {
    contentVersions,
    isLoadingContentById: isLoading,
    handleInsertContentVersion,
    handleContentVersionSelect,
  };
};
