import styled, { DefaultTheme, css } from 'styled-components';

export type TextColorName =
  | 'onyx'
  | 'steel'
  | 'orbit'
  | 'nero'
  | 'white'
  | 'whiteDimmed'
  | 'redOrange'
  | 'volatileRed'
  | 'kermit'
  | 'aluminum'
  | 'stellar';

export interface ITextProps {
  $bold?: boolean;
  $size?: number | string;
  $lineHeight?: number | string;
  $margin?: string;
  $colorName?: TextColorName;
  inheritColor?: boolean;
  $dmSans?: boolean;
  $padding?: string;
  hideOverflow?: boolean;
  overflowWidth?: string;
  maxWidth?: string;
  maxWidthSmallScreen?: string;
  textAlign?: 'left' | 'right' | 'center';
  maxLines?: number;
  capitalize?: boolean;
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces';
  flex?: number | string;
  $font?: keyof DefaultTheme['fonts'];
  $letterSpacing?: string;
}

export const Text = styled.div<ITextProps>(
  ({
    theme,
    $bold,
    $size = 14,
    $margin,
    $colorName,
    $dmSans,
    $font,
    hideOverflow,
    overflowWidth = '90%',
    $lineHeight = 24,
    maxWidth,
    maxWidthSmallScreen,
    textAlign = 'left',
    maxLines,
    $padding = '0',
    whiteSpace,
    inheritColor,
    capitalize,
    flex,
    $letterSpacing,
  }) => css`
    // To Do: Refactor the font family interface. The $dmSans property is maintained for backward compatibility.
    font-family: ${$dmSans
      ? theme.fonts.dmSans
      : $font
      ? theme.fonts[$font]
      : theme.fonts.inter};
    font-size: ${$size}px;
    color: ${$colorName ? theme.colors[$colorName] : theme.colors.onyx};
    ${inheritColor
      ? css`
          color: inherit;
        `
      : ''}
    font-weight: ${$bold ? 'bold' : 'normal'};
    margin: ${$margin ? $margin : '0'};
    display: inline-block;
    line-height: ${$lineHeight}px;
    text-align: ${textAlign};
    padding: ${$padding};

    ${$letterSpacing
      ? css`
          letter-spacing: ${$letterSpacing};
        `
      : ''}

    ${whiteSpace
      ? css`
          white-space: ${whiteSpace};
        `
      : ''};

    ${maxWidth
      ? css`
          max-width: ${maxWidth};
        `
      : ''}

    ${capitalize
      ? css`
          text-transform: capitalize;
        `
      : ''}

    ${maxLines
      ? css`
          max-height: ${maxLines * Number($lineHeight)}px;
          overflow: hidden;
        `
      : ''}
    
    ${flex
      ? css`
          flex: ${flex};
        `
      : ''}

    ${hideOverflow
      ? css`
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: ${overflowWidth};
        `
      : ''}
    
    @media(max-width: 1665px) {
      ${maxWidthSmallScreen
        ? css`
            max-width: ${maxWidthSmallScreen};
          `
        : ''}
    }

    a {
      text-decoration: underline;
      color: ${theme.colors.orbit};
      cursor: pointer;
    }
  `,
);
