/**
 *
 * Authorization pages
 *
 */
import { Route, Switch } from 'react-router-dom';
import { RouteConstants } from '../../routes';
import LoginPage from './pages/LoginPage/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';

interface IProps {}

export const Authorization = (props: IProps) => {
  return (
    <Switch>
      <Route
        exact
        path={RouteConstants.authorization.login}
        component={LoginPage}
      />
      <Route
        exact
        path={RouteConstants.authorization.forgotPassword}
        component={ForgotPasswordPage}
      />
    </Switch>
  );
};
