import { FC, useMemo } from 'react';
import FlexBlock from 'app/components/UI/FlexBlock';
import SocialPost from './SocialPost';
import SocialPostsHeader from './SocialPostsHeader';
import { SocialPostsState } from '../hooks/useSocialPostsReducer';
import { ISocialPost, SocialPostType } from '../types/SocialPost.types';
import SocialPostsSkeleton from './LoadingSkeletons/SocialPostsSkeleton';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';
import { useNavigateToContent } from '../../CreateOrEditSocialPosts/hooks/useNavigateToContent';
import Button from 'app/components/UI/Button/Button';

interface ISocialPostsProps {
  socialPostState: SocialPostsState;
  socialPost?: ISocialPost;
  isLoading?: boolean;
  handleContentChange: (content: string, postType: SocialPostType) => void;
  handleVideoDescriptionChange: (
    content: string,
    postType: SocialPostType,
  ) => void;
  handleContentRegenerate: (tone: string, contentType?: SocialPostType) => void;
}

const SocialPosts: FC<ISocialPostsProps> = ({
  socialPostState,
  socialPost,
  handleContentChange,
  handleVideoDescriptionChange,
  handleContentRegenerate,
  isLoading,
}) => {
  const { goToCreateSocialPostPage } = useNavigateToContent();
  const { loading, editedContent, socialPostContents, editedVideoDescription } =
    socialPostState;

  const allItemsLoading = useMemo(
    () =>
      Object.values(loading).length > 0 &&
      Object.values(loading).every(value => value === true),
    [loading],
  );

  const displayLoadingSkeleton = useMemo(
    () => isLoading || allItemsLoading,
    [allItemsLoading, isLoading],
  );

  const displayEmptyDataNotice = useMemo(() => {
    if (socialPost?.socialPostContents.length === 0 && !isLoading) return true;

    return false;
  }, [socialPost, isLoading]);

  return (
    <>
      <SocialPostsHeader socialPostId={socialPost?.id} />
      <FlexBlock
        flexDirection="column"
        minWidth="100%"
        padding="16px 24px 40px 24px"
        rowGap="32px"
      >
        {displayLoadingSkeleton ? (
          <>
            <SocialPostsSkeleton />
            <SocialPostsSkeleton />
            <SocialPostsSkeleton />
          </>
        ) : displayEmptyDataNotice ? (
          <FlexBlock minWidth="100%">
            <EmptyDataNotice
              title="Something went wrong"
              description="Could not load social posts"
              buttonNode={
                <Button
                  variant="transparent"
                  onClick={() => goToCreateSocialPostPage(socialPost)}
                >
                  Regenerate Social Posts
                </Button>
              }
            />
          </FlexBlock>
        ) : (
          Object.keys(socialPostContents).map(key => {
            const postType = key as SocialPostType;
            const socialPost = socialPostContents[postType];

            if (!socialPost) return <></>;

            return (
              <SocialPost
                key={socialPost.id}
                socialPost={socialPost}
                handleContentChange={handleContentChange}
                handleVideoDescriptionChange={handleVideoDescriptionChange}
                handleRegenerate={handleContentRegenerate}
                socialPostContent={editedContent[postType] || ''}
                socialPostContentVideo={editedVideoDescription[postType] || ''}
                isLoading={loading[postType]}
              />
            );
          })
        )}
      </FlexBlock>
    </>
  );
};

export default SocialPosts;
