import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import Gap from 'app/components/UI/Gap';
import RoundedPill from 'app/components/UI/RoundedPill';
import { FC } from 'react';

interface IFormSectionProps {
  index: number;
  title: string;
  subtitle: string;
  compact: boolean;
}

const FormSection: FC<IFormSectionProps> = ({
  index,
  title,
  subtitle,
  compact,
  children,
}) => {
  return (
    <FlexBlock flexDirection="column" minWidth="100%">
      <FlexBlock flexDirection="row" alignItems="center" columnGap="12px">
        <RoundedPill variant="orbit" size={40}>
          <Typography.Text
            $colorName="orbit"
            $dmSans
            $bold
            $lineHeight={24}
            $size={compact ? 16 : 20}
          >
            {index}
          </Typography.Text>
        </RoundedPill>
        <Typography.Text
          $colorName="onyx"
          $dmSans
          $bold
          $lineHeight={compact ? 20 : 24}
          $size={compact ? 16 : 18}
        >
          {title}
        </Typography.Text>
      </FlexBlock>
      <Gap size={18} />
      <FlexBlock
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0 16px 8px 16px"
        minWidth="100%"
      >
        <Typography.Text
          $colorName="nero"
          $lineHeight={24}
          $size={compact ? 14 : 16}
        >
          {subtitle}
        </Typography.Text>
      </FlexBlock>
      {children}
    </FlexBlock>
  );
};

export default FormSection;
