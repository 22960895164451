import ReactSelect, {
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  Props,
  StylesConfig,
} from 'react-select';
import FieldErrors from '../FieldErrors/FieldErrors';
import * as S from './Select.styles';

interface IProps<OptionType> extends Props<OptionType> {
  errors?: string[];
  fontSize?: number;
  disabled?: boolean;
  ref?: React.RefObject<any>;
  controlPadding?: string;
  width?: string;
  minWidth?: string;
  background?: string;
  height?: string;
  toneLengthVariant?: boolean;
  isCompact?: boolean;
  selectorHeight?: number;
  selectorRadius?: number;
}

const Select = <OptionType,>({
  errors,
  fontSize = 16,
  disabled,
  controlPadding = '12.5px 16px',
  width = '100%',
  minWidth,
  background,
  height,
  toneLengthVariant = false,
  isCompact = false,
  selectorHeight,
  selectorRadius,
  ...restProps
}: IProps<OptionType>) => {
  const isError = Boolean(errors?.length);

  const baseCustomStyles: StylesConfig<OptionType> = {
    container: (provided, state) => ({
      height: height ? height : undefined,
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      width,
      minWidth,
      background: 'white',
      padding: selectorHeight ? '0 16px' : controlPadding,
      border: !isError
        ? state.menuIsOpen
          ? '1px solid #BCBCC1'
          : '1px solid #dadee1'
        : '1px solid red',
      borderRadius: selectorRadius || '12px',
      fontSize: `${fontSize}px`,
      cursor: 'pointer',
      height: selectorHeight ? selectorHeight : height ? height : undefined,
      '&:hover': {
        border: '1px solid #BCBCC1',
      },
    }),
  };

  const customToneLengthStyles: StylesConfig<OptionType> = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
      width: '305px',
      height: '146px',
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      right: '-1px',
    }),
    option: (provided, state) => ({
      padding: '0',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.isSelected ? undefined : '#f5f5f9',
        cursor: 'pointer',
      },
      ...provided,
      backgroundColor: state.isSelected ? '#F5F5F9' : undefined,
      height: '62px',
    }),
  };

  const customToneStyles: StylesConfig<OptionType> = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
    option: (provided, state) => ({
      padding: '0',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.isSelected ? undefined : '#f5f5f9',
        cursor: 'pointer',
      },
      ...provided,
    }),
  };

  if (isCompact) {
    const compactControl = (provided: CSSObjectWithLabel) => ({
      ...provided,
      width: 200,
      paddingLeft: 5,
      paddingRight: 10,
      borderRadius: selectorRadius,
      height: selectorHeight,
    });

    customToneLengthStyles.control = compactControl;
    customToneStyles.control = compactControl;
  }

  const toneSelectorStyles = {
    ...baseCustomStyles,
    ...(toneLengthVariant ? customToneLengthStyles : customToneStyles),
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
    return <S.StyledDropdownArrow $isFocused={props.selectProps.menuIsOpen} />;
  };

  return (
    <S.StyledSelectContainer disabled={disabled}>
      <ReactSelect
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        styles={toneSelectorStyles}
        isMulti={false}
        tabIndex={disabled ? -1 : undefined}
        isSearchable={false}
        {...restProps}
      />
      {Boolean(errors?.length) && <FieldErrors errors={errors} />}
    </S.StyledSelectContainer>
  );
};

export default Select;
