import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export default function useQuery() {
  const { search } = useLocation();

  return useMemo<{ [key: string]: string }>(() => {
    const entries = new URLSearchParams(search);
    const result = {};
    // @ts-ignore
    for (const [key, value] of entries) {
      // @ts-ignore
      result[key] = value;
    }
    return result;
  }, [search]);
}
