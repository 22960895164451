import { SVGProps } from 'react';

const GooglePlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40 18.7543V21.2458C39.9829 21.3481 39.9488 21.4505 39.9317 21.5529C39.6927 24.6075 38.8391 27.4744 37.2514 30.1024C34.093 35.3243 29.5006 38.5495 23.4742 39.6929C22.7401 39.8294 21.9889 39.8976 21.2377 40H18.7452C18.6257 39.983 18.5232 39.9488 18.4037 39.9318C14.204 39.5905 10.431 38.14 7.23856 35.3755C1.22917 30.1536 -1.09264 23.5495 0.477997 15.7167C2.2535 6.87716 10.3286 0.221869 19.3427 2.56975e-05C23.5595 -0.102363 27.469 0.938592 30.9859 3.29354C35.7319 6.48467 38.6513 10.9045 39.6927 16.5359C39.8293 17.2696 39.8975 18.0034 40 18.7372V18.7543ZM23.7132 18.5836H14.3235V21.9966H19.9402C19.8207 22.8669 19.4963 23.6007 18.9671 24.215C17.4648 25.9386 15.5185 26.5359 13.3333 26.1946C9.98717 25.6656 7.80194 22.7133 8.10924 19.3345C8.45068 15.5973 12.087 12.9864 15.7234 13.8567C16.6965 14.0956 17.5501 14.5393 18.3184 15.256C19.172 14.3174 20.0085 13.413 20.828 12.5086C20.845 12.5086 20.7426 12.3891 20.6402 12.3038C18.472 10.4949 15.9795 9.77818 13.1967 10.0683C7.10198 10.6997 2.9876 16.7236 4.62652 22.628C6.02644 27.6451 10.7896 30.6656 16.0478 29.8464C22.4328 28.8567 24.4814 22.9522 23.6961 18.5836H23.7132ZM30.0128 18.5836H27.1617V21.4335H30.0128V24.2662H32.898V21.4164H35.6978V18.5666H32.8809V15.7338H30.0299V18.6007L30.0128 18.5836Z"
      fill="#DB4A39"
    />
  </svg>
);

export default GooglePlusIcon;
