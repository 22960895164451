import { FC, SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: number;
}

const RemoveIcon: FC<IProps> = ({ color = '#090909', size = 8, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Remove Icon</title>
    <path
      d="M7.521 6.178A.95.95 0 1 1 6.178 7.52L3.999 5.343 1.821 7.52A.95.95 0 0 1 .478 6.178l2.178-2.179L.478 1.821A.95.95 0 0 1 1.82.478l2.178 2.178L6.178.478A.95.95 0 0 1 7.52 1.82L5.343 3.999 7.52 6.178Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default RemoveIcon;
