import styled, { css } from 'styled-components';

export const StickToTop = styled.div`
  position: sticky;
  top: 0;
  background: white;
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const CenteredContent = styled.div(
  ({ theme }) => css`
    width: ${theme.layout.width};
    max-width: ${theme.layout.maxWidth};
    margin: 0 auto;
  `,
);

export const ContentWrapper = styled.div`
  display: flex;
  width: 714px;
  grid-column-start: 1;
  grid-column-end: -1;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;

export const GridContainer = styled.div`
  margin: 24px auto 104px auto;
  width: 100%;
`;

export const ButtonWrapper = styled.div<{
  $borderColor?: string;
}>(
  ({ $borderColor }) => css`
    > button {
      width: 56px;
      height: 56px;
      ${$borderColor
        ? css`
            border-color: ${$borderColor}!important;
          `
        : ''}
    }
  `,
);

export const CenteredContentFlex = styled.div(
  ({ theme }) => css`
    width: ${theme.layout.width};
    max-width: ${theme.layout.maxWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
);

export const WandAvatar = styled.div(
  ({ theme }) => css`
    width: 56px;
    height: 56px;
    background: ${theme.colors.stratos};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

export const IconButton = styled.div`
  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;
    margin-left: 16px;
  }
`;
