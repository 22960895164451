import { FC } from 'react';

const CoverLetterIcon: FC<{ size?: string }> = ({ size = '48' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#090909" transform="translate(-1594 -184)">
          <g transform="translate(0 88)">
            <g transform="translate(1578 80)">
              <g transform="translate(16 16)">
                <path d="M6.9.6C4.415.6 2.4 2.642 2.4 5.16v13.68c0 2.518 2.015 4.56 4.5 4.56h9c2.485 0 4.5-2.042 4.5-4.56V5.16C20.4 2.642 18.385.6 15.9.6h-9zm9 9.12a.85.85 0 01-.844.855H7.744A.85.85 0 016.9 9.72a.85.85 0 01.844-.855h7.312a.85.85 0 01.844.855zm0 4.11a.85.85 0 01-.844.855H7.744a.85.85 0 01-.844-.855.85.85 0 01.844-.855h7.312a.85.85 0 01.844.855zM7.744 6.3a.85.85 0 00.844-.855.85.85 0 00-.844-.855.85.85 0 00-.844.855.85.85 0 00.844.855z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CoverLetterIcon;
