import { SVGProps } from 'react';

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => {
  const { width = '40', height = '40', ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_247_2379)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="white"
        />
        <path
          d="M6.0708 28.1451C6.0708 23.103 6.0708 18.0608 6.0708 13.0186C6.08766 12.9343 6.10453 12.85 6.12139 12.7657C6.54298 9.96636 8.06068 8.04393 10.7251 7.04899C11.2647 6.84663 11.8718 6.77918 12.4452 6.66113C17.4873 6.66113 22.5295 6.66113 27.5716 6.66113C27.6391 6.66113 27.7066 6.69486 27.774 6.71172C29.376 6.89722 30.7757 7.53803 31.9055 8.68474C33.2715 10.0675 33.9291 11.7539 33.9291 13.6932C33.9291 18.28 33.9291 22.8837 33.9291 27.4706C33.9291 27.7573 33.9123 28.0271 33.8786 28.3137C33.5413 31.7707 30.6576 34.4689 27.1838 34.5026C22.3946 34.5363 17.5885 34.5363 12.7993 34.5026C9.93252 34.5026 7.35242 32.5633 6.45866 29.8315C6.27316 29.2918 6.20571 28.7016 6.0708 28.1451ZM31.1298 20.6072C31.1298 18.28 31.1298 15.9529 31.1298 13.6257C31.1298 11.2648 29.3592 9.46046 27.0152 9.44359C22.3271 9.42673 17.6391 9.42673 12.9511 9.44359C10.6239 9.44359 8.85326 11.248 8.8364 13.5583C8.81954 18.2294 8.81954 22.9175 8.8364 27.5886C8.8364 29.9158 10.6239 31.7033 12.9511 31.7033C17.6391 31.7202 22.3271 31.7033 27.0152 31.7033C27.7066 31.7033 28.3811 31.5347 28.9882 31.1974C30.4216 30.3879 31.0961 29.1232 31.113 27.5043C31.1298 25.194 31.113 22.9006 31.113 20.5903L31.1298 20.6072Z"
          fill="url(#paint0_radial_247_2379)"
        />
        <path
          d="M20 27.5552C16.1046 27.5552 13.0355 24.4691 13.0523 20.5737C13.0523 16.6783 16.1215 13.626 20.0169 13.626C23.9123 13.626 26.9815 16.712 26.9646 20.6074C26.9646 24.5029 23.8955 27.5552 20 27.5552ZM24.1822 20.6074C24.1822 18.2466 22.3778 16.4253 20.0338 16.4084C17.6729 16.4084 15.8348 18.2128 15.8348 20.5737C15.8348 22.9346 17.6392 24.7558 19.9832 24.7727C22.3441 24.7727 24.1822 22.9683 24.1822 20.6074Z"
          fill="url(#paint1_radial_247_2379)"
        />
        <path
          d="M26.9646 12.2432C27.7234 12.2432 28.3474 12.8502 28.3642 13.6091C28.3642 14.368 27.7403 15.0088 26.9814 15.0256C26.2226 15.0256 25.5818 14.3848 25.5818 13.626C25.5818 12.8671 26.2057 12.2432 26.9646 12.2432Z"
          fill="url(#paint2_radial_247_2379)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_247_2379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.4064 23.6594) scale(24.2664 24.2664)"
        >
          <stop stopColor="#FCCC63" />
          <stop offset="0.33" stopColor="#FBAD50" />
          <stop offset="0.64" stopColor="#CD486B" />
          <stop offset="0.95" stopColor="#4C68D7" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_247_2379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20.5906) scale(12.7993 12.7993)"
        >
          <stop stopColor="#FCCC63" />
          <stop offset="0.13" stopColor="#FBAD50" />
          <stop offset="0.64" stopColor="#CD486B" />
          <stop offset="1" stopColor="#4C68D7" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_247_2379"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.8246 18.5511) scale(15.5481 15.859)"
        >
          <stop stopColor="#FCCC63" />
          <stop offset="0.13" stopColor="#FBAD50" />
          <stop offset="0.64" stopColor="#CD486B" />
          <stop offset="1" stopColor="#4C68D7" />
        </radialGradient>
        <clipPath id="clip0_247_2379">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstagramIcon;
