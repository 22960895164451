import { IOption } from '../../../types/commonTypes';

export const pageSizeOptions: IOption<number>[] = [
  {
    value: 10,
    label: '10 Per Page',
  },
  {
    value: 20,
    label: '20 Per Page',
  },
  {
    value: 30,
    label: '30 Per Page',
  },
  {
    value: 50,
    label: '50 Per Page',
  },
];
