import styled, { css } from 'styled-components';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
interface StyledIconButtonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  disabled?: boolean;
}

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 11px;
  grid-row-gap: 0px;
  margin: 24px auto 104px auto;
  width: 100%;
`;

export const FooterContainer = styled.div<{
  $height?: number;
}>(
  ({ $height }) => css`
    width: 100%;
    height: ${$height ? `${$height}px` : '80px'};
    position: fixed;
    bottom: 0;
    background: white;
    box-shadow: 0 -2px 12px 0 rgba(12, 12, 13, 0.05);
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
  `,
);

export const ButtonWrapper = styled.div<{
  $borderColor?: string;
}>(
  ({ $borderColor }) => css`
    > button {
      width: 56px;
      height: 56px;
      ${$borderColor
        ? css`
            border-color: ${$borderColor}!important;
          `
        : ''}
    }
  `,
);

export const StickToTop = styled.div`
  position: sticky;
  top: 0;
  background: white;
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const CenteredContent = styled.div(
  ({ theme }) => css`
    width: ${theme.layout.width};
    max-width: ${theme.layout.maxWidth};
    margin: 0 auto;
  `,
);

export const CenteredContentFlex = styled.div(
  ({ theme }) => css`
    width: ${theme.layout.width};
    max-width: ${theme.layout.maxWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
);

export const WandAvatar = styled.div<{ $size?: string }>(
  ({ theme, $size }) => css`
    width: ${$size || '56px'};
    height: ${$size || '56px'};
    background: ${theme.colors.stratos};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

export const IconButton = styled.div<StyledIconButtonProps>`
  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;
    margin-left: 16px;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
