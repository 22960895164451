import Typography from 'app/components/Typography';
import { FC, useRef } from 'react';
import { Section } from './UserModal.styles';
import ImageDropzone, {
  ImageFile,
  ImageURL,
} from 'app/components/FileDropzone/ImageDropzone';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';

interface IUserAvatarProps {
  image: ImageURL | ImageFile | null;
  handleImageFileLoad: (file: ImageFile | ImageURL | null) => void;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderRightSection?: JSX.Element;
}

const UserAvatar: FC<IUserAvatarProps> = ({
  image,
  handleFileSelect,
  handleImageFileLoad,
  renderRightSection,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <FlexBlock
        flexDirection="row"
        alignItems="center"
        minWidth="100%"
        justifyContent="space-between"
      >
        <FlexBlock flexDirection="column">
          <Typography.Text
            $colorName="onyx"
            $dmSans
            $bold
            $size={16}
            $lineHeight={24}
          >
            {image && image.content ? 'Change' : 'Upload'} Profile Photo
          </Typography.Text>
          <Typography.Text $colorName="steel" $size={14} $lineHeight={20}>
            Max. 512MB. Supported formats: .png, .jpg or .jpeg
          </Typography.Text>
        </FlexBlock>
        {renderRightSection && renderRightSection}
      </FlexBlock>
      <Section>
        <ImageDropzone image={image} handleImageLoad={handleImageFileLoad} />
        <Typography.Text $size={14} $lineHeight={20} $colorName="steel">
          or
        </Typography.Text>
        <input
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          hidden
          ref={fileInputRef}
          onChange={handleFileSelect}
        />
        <Button
          type="button"
          variant="secondary"
          compact
          onClick={() => {
            fileInputRef?.current?.click();
          }}
        >
          Browse file
        </Button>
      </Section>
    </>
  );
};

export default UserAvatar;
