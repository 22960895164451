import { FC, ReactNode, useState } from 'react';
import * as S from './Accordion.styles';
import Typography from '../Typography';
import CarrotUpIcon from '../../assets/icons/CarrotUpIcon';
import FlexBlock from '../UI/FlexBlock';
import { InfoTipAdvanced } from '../UI/InfoTip/InfoTip';

export type AccordionVariant = 'default' | 'orbit-blue';

interface IProps {
  title: string;
  renderTitle?: () => ReactNode;
  subTitle?: string;
  tooltipContent?: ReactNode;
  defaultExpanded?: boolean;
  variant?: AccordionVariant;
  actionButton?: ReactNode;
}

const Accordion: FC<IProps> = ({
  children,
  title,
  renderTitle,
  subTitle,
  tooltipContent,
  defaultExpanded,
  actionButton,
  variant = 'default',
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded || false);

  return (
    <S.Wrapper variant={variant}>
      <S.Header onClick={() => setExpanded(state => !state)}>
        <FlexBlock flexDirection="column" rowGap="2px">
          <FlexBlock alignItems="center" columnGap="4px">
            {renderTitle ? (
              renderTitle()
            ) : (
              <Typography.Text $colorName="orbit" $dmSans $bold $size={16}>
                {title}
              </Typography.Text>
            )}

            {actionButton && actionButton}
            {!!tooltipContent && (
              <InfoTipAdvanced tooltipText="" padding="0">
                {tooltipContent}
              </InfoTipAdvanced>
            )}
          </FlexBlock>
          {!!(expanded && subTitle) && (
            <Typography.Text $lineHeight={16} $colorName="steel">
              {subTitle}
            </Typography.Text>
          )}
        </FlexBlock>
        <FlexBlock padding="4px 0">
          <CarrotUpIcon rotateDown={expanded} />
        </FlexBlock>
      </S.Header>
      <S.Content expanded={expanded}>{children}</S.Content>
    </S.Wrapper>
  );
};

export default Accordion;
