import React from 'react';
import CakeIcon from 'app/assets/icons/CakeIcon';
import GenderIcon from 'app/assets/icons/GenderIcon';
import MoneyIcon from 'app/assets/icons/MoneyIcon';
import { amountValueFormatter } from './PodcastDemographic.helpers';

export const demographicsConfig = {
  availableGeographiesKeys: ['cities', 'countries'],
  availableSegmentsKeys: ['brands', 'interests', 'occupations', 'influencers'],
  availableBreakdownKeys: ['genderBias', 'medianAge', 'medianIncome'],
  geographies: {
    cities: { cardTitle: 'Top Cities' },
    countries: { cardTitle: 'Top Countries' },
  },
  segments: {
    brands: { cardTitle: 'Top Brands' },
    interests: { cardTitle: 'Top Interests' },
    occupations: { cardTitle: 'Top Occupations' },
    influencers: { cardTitle: 'Top Influencers' },
  },
  breakdowns: {
    genderBias: {
      image: <GenderIcon />,
      cardTitle: 'Gender Skew',
      valueSuffix: '%',
    },
    medianAge: {
      image: <CakeIcon />,
      cardTitle: 'Median Age',
      valueSuffix: '',
    },
    medianIncome: {
      image: <MoneyIcon />,
      cardTitle: 'Median Income',
      valueSuffix: amountValueFormatter,
    },
  },
};
