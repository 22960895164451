import {
  ICreateSocialPostContentPayload,
  ISocialPostContentBase,
} from '../SocialPostsPage/types/SocialPost.types';

export const makeCreateSuggestedContentPayload = (
  socialPostContent: ISocialPostContentBase,
): ICreateSocialPostContentPayload | null => {
  if (
    !socialPostContent ||
    !validateSocialPostContentPayload(socialPostContent)
  )
    return null;

  return {
    content: {
      contentType: socialPostContent.contentType,
      post: socialPostContent.post,
      videoDescription: socialPostContent.videoDescription || undefined,
    },
    tone: socialPostContent.tone,
    socialPostType: socialPostContent.contentType,
  };
};

const validateSocialPostContentPayload = (
  socialPostContent: ISocialPostContentBase,
): boolean => {
  if (!socialPostContent) return false;

  return (
    socialPostContent.contentType !== undefined &&
    socialPostContent.post !== undefined &&
    socialPostContent.tone !== undefined
  );
};
