import * as React from 'react';

const DownloadAlternativeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
    <defs>
      <filter
        id="a"
        width="102%"
        height="147.5%"
        x="-1%"
        y="-26.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={-2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation={6}
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.0480762252 0 0 0 0 0.0480491123 0 0 0 0 0.0500554624 0 0 0 0.0472130409 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="#3F3F42"
      fillRule="nonzero"
      filter="url(#a)"
      transform="translate(-1705 -28)"
    >
      <path d="M1724.2 28a1.2 1.2 0 0 1 .96.48l3.6 4.8c.156.208.24.46.24.72v14.4a3.6 3.6 0 0 1-3.6 3.6h-16.8a3.6 3.6 0 0 1-3.6-3.6V34c0-.26.084-.512.24-.72l3.6-4.8a1.2 1.2 0 0 1 .96-.48h14.4Zm-7.2 8.4a1.2 1.2 0 0 0-1.2 1.2v6.928l-1.603-1.425a1.2 1.2 0 0 0-1.594 1.794l3.6 3.2a1.2 1.2 0 0 0 1.594 0l3.6-3.2a1.2 1.2 0 0 0-1.594-1.794l-1.603 1.425V37.6a1.2 1.2 0 0 0-1.2-1.2Zm6.6-6h-13.2l-1.8 2.4h16.8l-1.8-2.4Z" />
    </g>
  </svg>
);

export default DownloadAlternativeIcon;
