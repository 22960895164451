import { ReactNode, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';

const rootPortal = document.getElementById('portal-root');

export default function usePortal(nodeName: string) {
  const node = useRef(document.createElement('div'));

  useEffect(() => {
    const currentNode = node.current;
    rootPortal?.appendChild(currentNode);
    return () => {
      rootPortal?.removeChild(currentNode);
    };
  });

  return useMemo(() => {
    return {
      renderInPortal: (children: ReactNode) => {
        return ReactDOM.createPortal(children, node.current);
      },
    };
  }, [nodeName]);
}
