import {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ArrowRightIcon from 'app/assets/icons/ArrowRightIcon';
import FlexBlock from 'app/components/UI/FlexBlock';
import Typography from 'app/components/Typography';

import upgradeIcon from 'app/assets/images/icon-upgrade.png';

import Button from 'app/components/UI/Button/Button';
import CheckIcon from 'app/assets/icons/CheckIcon';
import { Divider } from 'app/components/UI/Divider';

import Gap from 'app/components/UI/Gap';
import { useAppDispatch } from 'store/hooks';
import { useGlobalSlice } from 'app/containers/Global/slice';
import { createEMailToLink, openInNewTab } from 'utils/helpers';

import * as S from './UpgradePlanMenu.styles';
import {
  PRO_FEATURES,
  ENTERPRISE_FEATURES,
  ENTERPRISE_LEGACY_FEATURES,
} from './UpgradePlanMenu.constants';
import useGetCurrentUserPlan from 'app/containers/Global/permissions/useGetCurrentUserPlan.hook';

const UpgradePlanMenu: FC = () => {
  const globalSlice = useGlobalSlice();
  const dispatch = useAppDispatch();

  const onUpgradeClick = () => {
    dispatch(globalSlice.actions.goToUpgradeToPro());
  };

  const onEnterpriseUpgradeClick = (
    e: React.MouseEvent<Element, MouseEvent>,
  ) => {
    e.preventDefault();
    openInNewTab(createEMailToLink('sales@prprophet.ai', 'Unlock Enterprise'));
  };

  const {
    isProPlan,
    isProV2Plan,
    isProOrProV2Plan,
    isFreePlan,
    isFreemiumPlan,
  } = useGetCurrentUserPlan();

  const [upgradeDrawerExpanded, setUpgradeDrawerExpanded] = useState(false);

  const [submenuPositionTop, setSubmenuPositionTop] = useState(0);

  const upgradeMenuRef: MutableRefObject<ReturnType<typeof setTimeout> | null> =
    useRef(null);

  const upgradeTriggerButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (upgradeTriggerButtonRef.current) {
      setSubmenuPositionTop(upgradeTriggerButtonRef.current.offsetTop);
    }
  }, [upgradeTriggerButtonRef]);

  const handleMouseEnter = () => {
    setUpgradeDrawerExpanded(true);
  };

  const handleMouseLeave = () => {
    upgradeMenuRef.current = setTimeout(() => {
      setUpgradeDrawerExpanded(false);
    }, 200);
  };

  const handleMenuEnter = () => {
    if (upgradeMenuRef.current) {
      clearTimeout(upgradeMenuRef.current);
    }
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setUpgradeDrawerExpanded(prev => !prev);
    if (upgradeMenuRef.current) {
      clearTimeout(upgradeMenuRef.current);
    }
  };

  const features = useMemo(() => {
    if (isFreePlan) return PRO_FEATURES;
    if (isProPlan) return ENTERPRISE_LEGACY_FEATURES;
    if (isProV2Plan) return ENTERPRISE_FEATURES;
    return undefined;
  }, [isFreePlan, isProPlan, isProV2Plan]);

  const planToUpgradeTo = useMemo(() => {
    if (isFreePlan) return 'Pro';
    if (isProOrProV2Plan) return 'Enterprise';
    return undefined;
  }, [isFreePlan, isProOrProV2Plan]);

  // Do not show upgrade plan prompt to users on Free Trial
  if (isFreemiumPlan) return <></>;

  if (!features || !planToUpgradeTo) return <></>;

  return (
    <>
      <S.UpgradePlanButton
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleButtonClick}
        ref={upgradeTriggerButtonRef}
      >
        <FlexBlock flexDirection="row" alignItems="center" flex={1}>
          <img width={24} height={24} src={upgradeIcon} alt="Upgrade plan" />
          <Typography.Text
            $dmSans
            $bold
            $colorName="onyx"
            $size={14}
            $lineHeight={16}
          >
            Upgrade to {planToUpgradeTo}
          </Typography.Text>
        </FlexBlock>
        <ArrowRightIcon />
      </S.UpgradePlanButton>
      <S.UpgradePlanSubMenu
        $isOpen={upgradeDrawerExpanded}
        onMouseEnter={handleMenuEnter}
        onMouseLeave={handleMouseLeave}
        $topPosition={submenuPositionTop}
      >
        <Typography.Text
          $dmSans
          $bold
          $size={16}
          $lineHeight={24}
          $padding="16px 16px 8px 16px"
        >
          Get access to:
        </Typography.Text>
        <Divider />
        <Gap size={16} />
        {features.map((feature, i) => (
          <FlexBlock key={i} alignItems="center" padding="6px 16px" flex={1}>
            <S.CheckIconBlueWrapper>
              <CheckIcon />
            </S.CheckIconBlueWrapper>
            <Gap size={8} />
            <Typography.Text $size={14} $lineHeight={16} $colorName="onyx">
              {feature}
            </Typography.Text>
          </FlexBlock>
        ))}
        <S.UpgradeButtonContainer>
          {isProOrProV2Plan ? (
            <Button variant="primary" onClick={onEnterpriseUpgradeClick}>
              Contact Sales
            </Button>
          ) : (
            <Button variant="primary" onClick={onUpgradeClick}>
              Unlock {planToUpgradeTo}
            </Button>
          )}
        </S.UpgradeButtonContainer>
      </S.UpgradePlanSubMenu>
    </>
  );
};

export default UpgradePlanMenu;
