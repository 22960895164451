import { SVGProps } from 'react';

const GlobeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#0C0C0D" transform="translate(-1543 -407)">
        <g transform="translate(260 72)">
          <g transform="translate(1243 80)">
            <g transform="translate(0 184)">
              <g transform="translate(24 56)">
                <g transform="translate(16 15.429)">
                  <path d="M19.5 10C19.494 4.76 15.246.511 10.003.503L9.999.5l-.004.003C4.75.507.5 4.757.5 10c0 5.242 4.25 9.493 9.495 9.497l.004.003.004-.003c5.243-.009 9.491-4.256 9.497-9.497zm-1.495-.73h-3.641c-.146-2.707-1.097-5.207-2.696-7.13a8.054 8.054 0 016.337 7.13zM7.099 10.73h5.8c-.166 2.626-1.215 5.087-2.9 6.798-1.685-1.711-2.734-4.172-2.9-6.798zm0-1.46c.166-2.626 1.215-5.087 2.9-6.798 1.685 1.71 2.734 4.172 2.9 6.797h-5.8zm4.57 8.59c1.598-1.923 2.55-4.423 2.695-7.13h3.64a8.054 8.054 0 01-6.336 7.13z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GlobeIcon;
