import { Dispatch, FC, createContext, useContext, useReducer } from 'react';
import { PressReleaseStateActions } from '../CreateOrEditPressRelease.constants';

import {
  PressReleaseAction,
  PressReleaseState,
} from './PressReleaseContext.types';
import { INITIAL_PRESS_RELEASE_STATE } from './PressReleaseContext.constants';

function pressReleaseReducer(
  pressReleaseState: PressReleaseState,
  action: PressReleaseAction,
): PressReleaseState {
  switch (action.type) {
    case PressReleaseStateActions.UPDATE_PRESS_RELEASE: {
      return {
        ...pressReleaseState,
        pressRelease: {
          ...pressReleaseState.pressRelease,
          ...action.payload,
        },
      };
    }
    case PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENTS: {
      return {
        ...pressReleaseState,
        pressReleaseContents: [...action.payload],
      };
    }
    case PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT: {
      const content = pressReleaseState.pressReleaseContents.find(
        content => content.contentType === action.payload.contentType,
      );

      const updatedPressReleaseContent = content
        ? pressReleaseState.pressReleaseContents.map(content => {
            if (content.contentType === action.payload.contentType)
              return action.payload;
            return content;
          })
        : [...pressReleaseState.pressReleaseContents, action.payload];

      return {
        ...pressReleaseState,
        versionsOpen: false,
        settingsOpen: false,
        pressReleaseContents: updatedPressReleaseContent,
      };
    }
    case PressReleaseStateActions.UPDATE_PRESS_RELEASE_CONTENT_VERSIONS: {
      return {
        ...pressReleaseState,
        contentVersions: action.payload,
      };
    }
    case PressReleaseStateActions.SET_PREDICTION: {
      return {
        ...pressReleaseState,
        prediction: action.payload,
      };
    }
    case PressReleaseStateActions.SET_SELECTED_CUSTOM_MEDIA_LIST: {
      return {
        ...pressReleaseState,
        selectedCustomMediaLists: action.payload,
      };
    }
    case PressReleaseStateActions.SET_PRESS_RELEASE_PROCESS_STATUS: {
      return {
        ...pressReleaseState,
        pressReleaseProcessStatus: action.payload,
      };
    }
    case PressReleaseStateActions.SET_TONE: {
      return {
        ...pressReleaseState,
        tone: action.payload,
      };
    }
    case PressReleaseStateActions.SET_SELECTED_PRESS_RELEASE_CONTENT: {
      return {
        ...pressReleaseState,
        selectedPressReleaseContent: action.payload,
      };
    }

    case PressReleaseStateActions.TOGGLE_SOCIALS: {
      return {
        ...pressReleaseState,
        socialsTabOpen: action.payload,
      };
    }
    case PressReleaseStateActions.TOGGLE_SETTINGS: {
      return {
        ...pressReleaseState,
        settingsOpen: !pressReleaseState.settingsOpen,
        versionsOpen: false,
      };
    }
    case PressReleaseStateActions.TOGGLE_VERSIONS: {
      return {
        ...pressReleaseState,
        versionsOpen: !pressReleaseState.versionsOpen,
        settingsOpen: false,
      };
    }

    default: {
      return pressReleaseState;
    }
  }
}

const PressReleaseContext = createContext<PressReleaseState | null>(null);

const PressReleaseDispatchContext =
  createContext<Dispatch<PressReleaseAction> | null>(null);

export const PressReleaseProvider: FC = ({ children }) => {
  const [pressRelease, dispatch] = useReducer(
    pressReleaseReducer,
    INITIAL_PRESS_RELEASE_STATE,
  );

  return (
    <PressReleaseDispatchContext.Provider value={dispatch}>
      <PressReleaseContext.Provider value={pressRelease}>
        {children}
      </PressReleaseContext.Provider>
    </PressReleaseDispatchContext.Provider>
  );
};

export function usePressRelease() {
  const context = useContext(PressReleaseContext);

  if (context === null)
    throw new Error(
      'usePressRelease hook must be used within a PressReleaseProvider',
    );
  return context;
}

export function usePressReleaseDispatch() {
  const dispatchContext = useContext(PressReleaseDispatchContext);

  if (dispatchContext === null)
    throw new Error(
      'usePressReleaseDispatch hook must be used within a PressReleaseProvider',
    );
  return dispatchContext;
}
