import { takeLatest, put, select } from 'redux-saga/effects';
import { pitchActions as actions } from '.';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  IAuthorEmailSuggestionsPayload,
  IAuthorEmailSuggestionsResponse,
} from 'app/api.types';
import { getSuggestions } from '../pages/PitchPage/parts/MultiGenerationResults/MultiGenerationResults.api';
import { showErrorToast } from 'utils/toast';
import { selectEmailSuggestionsPayload } from './selectors';

function* loadEmailSuggestions(
  action: PayloadAction<{
    payload?: IAuthorEmailSuggestionsPayload;
    regenerate: boolean;
  }>,
) {
  try {
    let requestBody = action.payload.payload;

    if (!requestBody) {
      requestBody = yield select(selectEmailSuggestionsPayload);
    }

    if (!requestBody) return;

    const response: IAuthorEmailSuggestionsResponse[] = yield getSuggestions(
      requestBody,
      action.payload.regenerate,
    );
    yield put(actions.setEmailSuggestionsResponse(response));
  } catch (error) {
    showErrorToast('Failed to Generate Suggestions');
    yield put(actions.setEmailSuggestionsResponse(undefined));
  }
}

export function* pitchSaga() {
  yield takeLatest(actions.toggleEmailSuggestions.type, loadEmailSuggestions);
}
