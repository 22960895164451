import { SVGProps } from 'react';

const XIcon = (props: SVGProps<SVGSVGElement>) => {
  const { width = '40', height = '40', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_247_12536)">
        <path
          d="M20.0084 40.0168C31.0587 40.0168 40.0168 31.0587 40.0168 20.0084C40.0168 8.95806 31.0587 0 20.0084 0C8.95806 0 0 8.95806 0 20.0084C0 31.0587 8.95806 40.0168 20.0084 40.0168Z"
          fill="black"
        />
        <path
          d="M18.5818 22.0228C18.0111 22.6775 17.4572 23.3153 16.9033 23.97C15.2079 25.9507 13.5126 27.9146 11.8005 29.8953C11.7165 29.996 11.5655 30.0631 11.4312 30.0631C10.9276 30.0799 10.4408 30.0631 9.87012 30.0631C12.539 26.9746 15.1408 23.9196 17.7761 20.8646C15.1408 17.0375 12.5222 13.2272 9.87012 9.34974H10.2226C12.0187 9.34974 13.7979 9.34974 15.594 9.34974C15.8122 9.34974 15.9465 9.4001 16.064 9.58474C17.6754 11.9515 19.3036 14.3015 20.9318 16.6683C20.9989 16.7522 21.0493 16.8361 21.1332 16.9536C21.4018 16.6515 21.6536 16.3661 21.9054 16.064C23.7518 13.9154 25.615 11.7669 27.4446 9.61831C27.6125 9.41689 27.7971 9.33296 28.0657 9.34974C28.5021 9.36653 28.9553 9.34974 29.4757 9.34974C26.9243 12.304 24.44 15.2079 21.9222 18.1286C24.6582 22.1068 27.3775 26.0514 30.1135 30.0463C29.9792 30.0463 29.8785 30.0463 29.7946 30.0463C27.9985 30.0463 26.1857 30.0463 24.3896 30.0463C24.1714 30.0463 24.0371 29.9792 23.9196 29.8113C22.2075 27.3103 20.4786 24.7925 18.7665 22.2914C18.6993 22.2075 18.649 22.1236 18.565 22.0061L18.5818 22.0228ZM27.6796 28.7706C27.6125 28.6699 27.5957 28.6196 27.5621 28.5692C23.4329 22.6775 19.3204 16.769 15.1911 10.8772C15.0904 10.7262 14.9729 10.6926 14.8051 10.6926C14.0665 10.6926 13.3112 10.6926 12.5726 10.6926C12.5055 10.6926 12.4383 10.6926 12.3376 10.7094C12.4383 10.8437 12.5055 10.9612 12.5894 11.0787C15.2079 14.8218 17.8265 18.565 20.445 22.3082C21.9054 24.3896 23.3489 26.471 24.8093 28.5356C24.8764 28.6364 24.9939 28.7539 25.0946 28.7539C25.9507 28.7706 26.79 28.7539 27.6964 28.7539L27.6796 28.7706Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_247_12536">
          <rect width={40} height={40} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XIcon;
