import { FC } from 'react';
import usePortal from '../../../../utils/hooks/usePortal';
import * as S from './SideDrawer.styles';

interface IProps {
  open: boolean;
  portalNodeId?: string;
  compactMode?: boolean;
}

const SideDrawer: FC<IProps> = ({
  open,
  portalNodeId = 'side-drawer-root',
  children,
  compactMode = false,
}) => {
  // const { renderInPortal } = usePortal(portalNodeId);

  return (
    <S.Wrapper open={open} $isCompact={compactMode}>
      {children}
    </S.Wrapper>
  );
};

export default SideDrawer;
export const SidebarFooter = S.SideBarFooter;
