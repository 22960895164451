type ID = number;

enum SocialPostStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum SocialPostType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER',
}

interface IRecordDate {
  createdAt: string;
  updatedAt: string;
}

interface IRecordStatus {
  status: SocialPostStatus;
}

interface IProject {
  id: ID;
  name: string;
  logo?: string;
}

interface ICreator {
  id: ID;
  firstName?: string;
  lastName?: string;
  avatar?: string;
}

/* Social Post Interfaces */

export interface ISocialPostBody {
  title: string;
  topic: string;
  tone: string;
}

export interface ICreateSocialPostPayload extends ISocialPostBody {
  projectId: ID;
}

export interface ISocialPost extends IRecordDate, IRecordStatus {
  id: ID;
  project: IProject;
  title: string;
  topic: string;
  socialPostContents: ISocialPostContentVersion[];
  creator: ICreator;
}

/* Social Post Content Interfaces */

export interface ISocialPostContentVersion extends IRecordDate, IRecordStatus {
  id: ID;
  socialPostId: ID;
  creator: ICreator;
  postType: SocialPostType;
  tone: string;
  version: number;
}

export interface IGenerateSocialPostContentPayload {
  tone: string;
  topic: string;
  socialPostTypes: SocialPostType[];
}

/* Social Post Content By Type Interfaces */

export interface ISocialPostContentBase extends IRecordDate {
  id: ID;
  contentType: SocialPostType;
  tone: string;
  workflowId: string;
  post: string;
  videoDescription?: string;
}

export interface ICreateSocialPostContentPayload {
  content: {
    contentType: SocialPostType;
    post: string;
    videoDescription?: string;
  };
  tone: string;
  socialPostType: SocialPostType;
}

export interface ISocialPostContent {
  id: ID;
  socialPostId: ID;
  userId: ID;
  postType: SocialPostType;
  version: number;
  tone: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  post: string;
  videoDescription?: string;
}

export type ISocialPostContentDetails<T extends SocialPostType> =
  ISocialPostContentBase &
    (T extends SocialPostType.INSTAGRAM | SocialPostType.TWITTER
      ? { postType: T; videoDescription: string }
      : { postType: T });
