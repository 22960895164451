import Button from 'app/components/UI/Button/Button';
import { FC, useContext } from 'react';
import { useSuggestionsData } from '../../../MultiGenerationResults/hooks/useSuggestionsData';
import { PitchPageContext } from '../../../../PitchPage.context';

interface IProps {
  disabled: boolean;
}

const GenerateButton: FC<IProps> = ({ disabled }) => {
  const { toggleGeneration } = useSuggestionsData();
  const { setIsRapidGenerationResultsView } = useContext(PitchPageContext);

  const handleGeneration = () => {
    toggleGeneration();
    setIsRapidGenerationResultsView(true);
  };

  return (
    <Button onClick={handleGeneration} disabled={disabled}>
      Generate
    </Button>
  );
};

export default GenerateButton;
