import { FC, useMemo, useState } from 'react';
import MainGrid from '../../../../components/UI/MainGrid/MainGrid';
import PageHeader from '../../../../components/UI/PageHeader';
import Button from '../../../../components/UI/Button/Button';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useGetProjectByIdQuery, useGetContentQuery } from '../../../../api';
import {
  defaultPaginationSettings,
  getTableColumnConfig,
  noDataNoticeConfig,
} from './ContentList.constants';
import {
  IProjectContentMetaDataItem,
  IContentItem,
  IPaginationRequestPart,
} from '../../../../api.types';
import DataTable from '../../../../components/Table/DataTable';
import { RouteConstants } from '../../../../routes';
import IfHasPermission from '../../../Global/permissions/IfHasPermission';
import { userPermissionEnum } from '../../../Global/permissions/userPermissions.enum';
import PencilIcon from 'app/assets/icons/PencilIcon';
import Gap from 'app/components/UI/Gap';
import FlexBlock from 'app/components/UI/FlexBlock';
import Avatar from 'app/components/UI/Avatar';
import Typography from 'app/components/Typography';

import {
  ContentLayout,
  ContentHeader,
  ContentFooter,
} from 'app/components/UI/ContentLayout/ContentLayout';
import ContentTypeSelector from './parts/ContentTypeSelector/ContentTypeSelector';
import ContextMenu, {
  ContextMenuOption,
} from 'app/components/UI/ContextMenu/ContextMenu';
import EmptyDataNotice from 'app/components/EmptyDataNotice/EmptyDataNotice';
import ProjectDisplaySkeleton from 'app/components/UI/LoadingSkeletons/Projects/ProjectDisplaySkeleton';

export interface IContent {
  id: number;
  version: number;
  type: string;
  title: string;
  predicted: boolean;
  createdBy: string;
  created: string;
  updated: string;
}

const ContentList: FC = () => {
  const [pagination, setPagination] = useState<IPaginationRequestPart>(
    defaultPaginationSettings,
  );

  const params: { projectId: string; tenantId: string } = useParams();
  const history = useHistory();
  const [projectId, tenantId] = useMemo(
    () => [Number(params.projectId), Number(params.tenantId)],
    [params],
  );

  const {
    data: project,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
  } = useGetProjectByIdQuery({ projectId: projectId }, { skip: !projectId });

  const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(
    undefined,
  );

  const {
    data: contentData,
    isLoading: isContentLoading,
    isFetching: isContentFetching,
    isError,
  } = useGetContentQuery(
    {
      projectId,
      pagination,
      contentTypes: selectedOptions,
    },
    { skip: !projectId },
  );
  const defaultSelectedOptions = useMemo(() => {
    if (!project?.contentMeta) {
      return undefined;
    }

    const options = project?.contentMeta
      ?.filter(option => option.count > 0)
      .map(option => ({
        displayName: option.displayName,
        key: option.key,
        count: option.count,
        checked:
          selectedOptions && selectedOptions.length > 0
            ? selectedOptions.includes(option.key)
            : true,
      })) as IProjectContentMetaDataItem[];

    return options;
  }, [project, selectedOptions]);

  const navigateToContentItem = (item: IContentItem) => {
    switch (item.contentType) {
      case 'BLOG':
        history.push(
          RouteConstants.blog.makeBlogUrl(
            tenantId,
            projectId,
            item.contentId,
            item.latestVersion,
          ),
        );
        break;
      case 'BYLINE':
        history.push(
          RouteConstants.byline.makeBylineUrl(
            tenantId,
            projectId,
            item.contentId,
            item.latestVersion,
          ),
        );
        break;
      case 'BIO':
        history.push(
          RouteConstants.bio.makeBioUrl(
            tenantId,
            projectId,
            item.contentId,
            item.latestVersion,
          ),
        );
        break;
      case 'PITCH':
        history.push(
          RouteConstants.pitch.makePitchUrl(
            tenantId,
            projectId,
            item.contentId,
            item.latestVersion || 1,
          ),
        );
        break;
      case 'PRESS_RELEASE':
        history.push(
          RouteConstants.pressRelease.makePressReleaseUrl(
            tenantId,
            projectId,
            item.contentId,
            item.latestVersion || 1,
          ),
        );
        break;
      case 'SOCIAL_POST':
        history.push(
          RouteConstants.socialPosts.makeSocialPostsUrl(
            tenantId,
            projectId,
            item.contentId,
          ),
        );
        break;

      default:
        break;
    }
  };

  const columns = useMemo(() => getTableColumnConfig(tenantId), [tenantId]);

  return (
    <ContentLayout>
      <ContentHeader>
        <FlexBlock flexDirection="row" alignItems="center">
          {isProjectLoading || isProjectFetching ? (
            <ProjectDisplaySkeleton />
          ) : (
            <>
              <Avatar
                size="56px"
                url={project?.logo}
                name={project?.name}
                shadow
              />

              <FlexBlock flexDirection="column" alignItems="left">
                <Typography.Text $size={14} $lineHeight={16} $colorName="steel">
                  Projects
                </Typography.Text>
                <Typography.Text
                  $dmSans
                  $bold
                  $colorName="onyx"
                  $size={20}
                  $lineHeight={24}
                >
                  {project?.name}
                </Typography.Text>
              </FlexBlock>
            </>
          )}
        </FlexBlock>
        <PageHeader.Section justifyContent="flex-end">
          <IfHasPermission require={userPermissionEnum.CREATE_PROJECTS}>
            <ContextMenu
              menuOptions={{
                minWidth: '305px',
                align: 'right',
                distanceFromTrigger: 16,
              }}
              renderButtonTrigger={({ onClick }) => (
                <Button onClick={onClick}>
                  <PencilIcon />
                  <Gap size={8} />
                  Create Content
                </Button>
              )}
              buttonText={''}
            >
              <IfHasPermission require={userPermissionEnum.CREATE_BLOG}>
                <ContextMenuOption onClick={() => {}}>
                  <NavLink
                    to={RouteConstants.blog.makeCreateBlogUrl(
                      tenantId,
                      projectId,
                    )}
                  >
                    <FlexBlock flexDirection="column" alignItems="left">
                      <Typography.Text
                        $dmSans
                        $bold
                        $size={16}
                        $lineHeight={24}
                      >
                        Blog
                      </Typography.Text>
                      <Typography.Text
                        $size={14}
                        $lineHeight={16}
                        $colorName="steel"
                      >
                        Generate a blog template based on a topic
                      </Typography.Text>
                    </FlexBlock>
                  </NavLink>
                </ContextMenuOption>
              </IfHasPermission>
              <IfHasPermission require={userPermissionEnum.CREATE_BYLINE}>
                <ContextMenuOption onClick={() => {}}>
                  <NavLink
                    to={RouteConstants.byline.makeCreateBylineUrl(
                      tenantId,
                      projectId,
                    )}
                  >
                    <FlexBlock flexDirection="column" alignItems="left">
                      <Typography.Text
                        $dmSans
                        $bold
                        $size={16}
                        $lineHeight={24}
                      >
                        Byline
                      </Typography.Text>
                      <Typography.Text
                        $size={14}
                        $lineHeight={16}
                        $colorName="steel"
                      >
                        Generate a byline based on a topic
                      </Typography.Text>
                    </FlexBlock>
                  </NavLink>
                </ContextMenuOption>
              </IfHasPermission>
              <IfHasPermission require={userPermissionEnum.CREATE_BIO}>
                <ContextMenuOption onClick={() => {}}>
                  <NavLink
                    to={RouteConstants.bio.makeCreateBioUrl(
                      tenantId,
                      projectId,
                    )}
                  >
                    <FlexBlock flexDirection="column" alignItems="left">
                      <Typography.Text
                        $dmSans
                        $bold
                        $size={16}
                        $lineHeight={24}
                      >
                        Bio
                      </Typography.Text>
                      <Typography.Text
                        $size={14}
                        $lineHeight={16}
                        $colorName="steel"
                      >
                        Generate a bio from a LinkedIn URL
                      </Typography.Text>
                    </FlexBlock>
                  </NavLink>
                </ContextMenuOption>
              </IfHasPermission>
              <IfHasPermission require={userPermissionEnum.CREATE_PITCH}>
                <ContextMenuOption onClick={() => {}}>
                  <NavLink
                    to={RouteConstants.pitch.makeCreatePitchUrl(
                      tenantId,
                      projectId,
                    )}
                  >
                    <FlexBlock flexDirection="column" alignItems="left">
                      <Typography.Text
                        $dmSans
                        $bold
                        $size={16}
                        $lineHeight={24}
                      >
                        Pitch
                      </Typography.Text>
                      <Typography.Text
                        $size={14}
                        $lineHeight={16}
                        $colorName="steel"
                      >
                        Input your pitch copy and find relevant journalists
                      </Typography.Text>
                    </FlexBlock>
                  </NavLink>
                </ContextMenuOption>
              </IfHasPermission>
              <IfHasPermission
                require={userPermissionEnum.CREATE_PRESS_RELEASE}
              >
                <ContextMenuOption onClick={() => {}}>
                  <NavLink
                    to={RouteConstants.pressRelease.makeCreatePressReleaseUrl(
                      tenantId,
                      projectId,
                    )}
                  >
                    <FlexBlock flexDirection="column" alignItems="left">
                      <Typography.Text
                        $dmSans
                        $bold
                        $size={16}
                        $lineHeight={24}
                      >
                        Templated Press Release
                      </Typography.Text>
                      <Typography.Text
                        $size={14}
                        $lineHeight={16}
                        $colorName="steel"
                      >
                        Input your press release copy and find relevant
                        journalists
                      </Typography.Text>
                    </FlexBlock>
                  </NavLink>
                </ContextMenuOption>
              </IfHasPermission>
              <IfHasPermission require={userPermissionEnum.CREATE_SOCIAL_POST}>
                <ContextMenuOption onClick={() => {}}>
                  <NavLink
                    to={RouteConstants.socialPosts.makeCreateSocialPostsUrl(
                      tenantId,
                      projectId,
                    )}
                  >
                    <FlexBlock flexDirection="column" alignItems="left">
                      <Typography.Text
                        $dmSans
                        $bold
                        $size={16}
                        $lineHeight={24}
                      >
                        Social Posts
                      </Typography.Text>
                      <Typography.Text
                        $size={14}
                        $lineHeight={16}
                        $colorName="steel"
                      >
                        Generate social posts for Facebook, Instagram, LinkedIn,
                        and X
                      </Typography.Text>
                    </FlexBlock>
                  </NavLink>
                </ContextMenuOption>
              </IfHasPermission>
            </ContextMenu>
          </IfHasPermission>
        </PageHeader.Section>
      </ContentHeader>
      <MainGrid minContent>
        <DataTable<IContentItem>
          dataTypeName="Content"
          data={contentData}
          columns={columns}
          pagination={pagination}
          onPaginationChange={setPagination}
          onRowClick={navigateToContentItem}
          renderContainerHeader={() => {
            if (!defaultSelectedOptions) return <></>;
            return (
              <ContentTypeSelector
                options={defaultSelectedOptions}
                setSelectedOptions={setSelectedOptions}
              />
            );
          }}
          emptyNoticeConfig={{
            ...noDataNoticeConfig,
            buttonLinkTo: RouteConstants.pitch.makeCreatePitchUrl(
              tenantId,
              projectId,
            ),
          }}
          isDataLoading={isContentLoading || isContentFetching}
        />
        {isError && (
          <EmptyDataNotice
            title="Something went wrong"
            description=" Could not load content"
          />
        )}
      </MainGrid>
      <ContentFooter />
    </ContentLayout>
  );
};

export default ContentList;
