import {
  useDeactivatePitchMutation,
  useDeletePressReleaseMutation,
  useGetPitchQuery,
  useGetPredictionQuery,
  useGetPressReleaseQuery,
} from 'app/api';
import { IPitch, IPressRelease } from 'app/api.types';
import { usePromptModalContext } from 'app/containers/Global/parts/PromptModal/PromptModal.context';
import { RouteConstants } from 'app/routes';
import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from 'utils/toast';

export const useGetPredictedContent = () => {
  const params: {
    pitchId: string;
    pressReleaseId: string;
    versionId: string;
    tenantId: string;
    projectId: string;
  } = useParams();

  const history = useHistory();

  const isPressRelease = useMemo(() => {
    return Boolean(params.pressReleaseId);
  }, [params]);

  const isPitch = useMemo(() => {
    return Boolean(params.pitchId);
  }, [params]);

  const [pitchId, pressReleaseId, versionId, tenantId, projectId] = useMemo(
    () => [
      Number(params.pitchId),
      Number(params.pressReleaseId),
      Number(params.versionId),
      Number(params.tenantId),
      Number(params.projectId),
    ],
    [params],
  );

  const {
    data: pitchData,
    isFetching: isPitchDataFetching,
    isError: isPitchError,
    error: pitchError,
  } = useGetPitchQuery({ pitchId }, { skip: !isPitch });

  const {
    data: pressReleaseData,
    isFetching: isPressReleaseFetching,
    isError: isPressReleaseError,
    error: pressReleaseError,
  } = useGetPressReleaseQuery(
    { id: pressReleaseId },
    { skip: !isPressRelease },
  );

  const contentError = useMemo(() => {
    return isPitch ? pitchError : pressReleaseError;
  }, [isPitch, pressReleaseError, pitchError]);

  const contentData = useMemo(() => {
    return isPitch ? pitchData : pressReleaseData;
  }, [isPitch, pitchData, pressReleaseData]);

  const isContentError = useMemo(() => {
    return isPitch ? isPitchError : isPressReleaseError;
  }, [isPitch, isPressReleaseError, isPitchError]);

  const isLoadingContent = useMemo(() => {
    return isPitch ? isPitchDataFetching : isPressReleaseFetching;
  }, [isPitch, isPitchDataFetching, isPressReleaseFetching]);

  const {
    data: predictionData,
    isLoading: isPredictionLoading,
    isFetching: isPredictionFetching,
    isError: isPredictionError,
    error: predictionError,
  } = useGetPredictionQuery(
    {
      pitchId: isPitch ? pitchId : pressReleaseId,
      versionId,
      predictionType: isPitch ? 'PITCH' : 'PRESS_RELEASE',
    },
    { refetchOnMountOrArgChange: false },
  );

  useEffect(() => {
    if (contentData && !predictionData && !isPredictionLoading && !isPitch) {
      const pressReleaseData = contentData as IPressRelease;
      history.replace(
        RouteConstants.pressRelease.makeEditPressReleaseUrl(
          tenantId,
          projectId,
          pressReleaseData.id,
          pressReleaseData.latestVersion,
        ),
      );
    }
  }, [
    contentData,
    predictionData,
    isPitch,
    isPredictionLoading,
    history,
    projectId,
    tenantId,
  ]);

  const loading = useMemo(
    () => isLoadingContent || isPredictionLoading,
    [isLoadingContent, isPredictionLoading],
  );

  return {
    isPitch,
    isPressRelease,
    prediction: predictionData,
    content: contentData,
    isLoading: loading,
    isPredictionFetching,
    isContentError,
    isPredictionError,
    predictionError,
    contentError,
    versionId,
  };
};

export const usePredictedContentStatus = (
  predictedContent: IPitch | IPressRelease,
  predictedContentType: 'PITCH' | 'PRESS_RELEASE',
) => {
  const { createModal } = usePromptModalContext();
  const history = useHistory();

  const params: {
    tenantId: string;
    projectId: string;
  } = useParams();

  const [tenantId, projectId] = useMemo(
    () => [Number(params.tenantId), Number(params.projectId)],
    [params],
  );

  const [
    deactivatePitch,
    { isSuccess: isDeactivateSuccess, isError: isDeactivateError },
  ] = useDeactivatePitchMutation();

  const [deletePressRelease] = useDeletePressReleaseMutation();

  useEffect(() => {
    if (isDeactivateSuccess) {
      showSuccessToast('Pitch deleted');
      history.push(RouteConstants.projects.makeContentUrl(tenantId, projectId));
    }
    if (isDeactivateError) {
      showErrorToast('Unknown Error');
    }
  }, [isDeactivateError, isDeactivateSuccess, history, projectId, tenantId]);

  const isPredictedContentActive = useMemo(() => {
    return predictedContent.status === 'ACTIVE';
  }, [predictedContent]);

  const handleDeactivatePredictedContent = () => {
    createModal({
      title: 'Delete pitch?',
      description: 'Are you sure you want to delete this pitch?',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      okButtonCallback: async () => {
        await deactivatePitch({ pitchId: predictedContent.id });
      },
    });
  };

  const handlePressReleaseDelete = () => {
    createModal({
      title: 'Delete Press Release?',
      description: 'Are you sure you want to delete this press release?',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      okButtonCallback: async () => {
        try {
          await deletePressRelease({ id: predictedContent.id }).unwrap();
          showSuccessToast('Press Release deleted');
          history.push(
            RouteConstants.projects.makeContentUrl(tenantId, projectId),
          );
        } catch (error) {
          showErrorToast('Could not delete press release');
        }
      },
    });
  };

  return {
    isPredictedContentActive,
    handleDeactivatePredictedContent,
    handlePressReleaseDelete,
  };
};
