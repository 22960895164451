import Typography from 'app/components/Typography';
import Tooltip from 'app/components/UI/Tooltip/Tooltip';
import { FC } from 'react';

export const FavoriteJournalistTooltip: FC = () => {
  return (
    <Tooltip
      id="favoriteJournalistTooltip"
      getContent={dataTip => {
        if (!dataTip) return <></>;
        return (
          <>
            <Typography.Text
              $colorName={
                dataTip === 'Delete journalists' ? 'volatileRed' : 'orbit'
              }
              $size={14}
            >
              {dataTip}
            </Typography.Text>
          </>
        );
      }}
    />
  );
};
