import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { logger } from 'utils/apm/logger';
import { useEffect } from 'react';
import FlexBlock from '../UI/FlexBlock';
import EmptyDataNotice from '../EmptyDataNotice/EmptyDataNotice';

interface FallbackPageProps {
  error?: string | Error;
}

export function FallbackPage({ error }: FallbackPageProps) {
  useEffect(() => {
    if (error) {
      logger.logError(error);
    }
  }, [error]);

  return (
    <>
      <Helmet>
        <title>Application Error</title>
        <meta name="description" content="An error occurred" />
      </Helmet>
      <Wrapper>
        <FlexBlock>
          <EmptyDataNotice
            title={'Oops! Something went wrong.'}
            description={
              "We're sorry for the inconvenience. Our team has been notified and is working to fix the issue."
            }
          />
        </FlexBlock>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
`;
