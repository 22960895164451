import {
  IJournalistReportItemV31,
  IPodcasterReportItemV31,
  IReportItemV31,
  IReportV31,
} from 'app/api.types';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import {
  IContentLengthSelectOption,
  IToneSelectOption,
} from '../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.types';
import {
  CONTENT_LENGTH_SELECT_OPTIONS,
  TONE_SELECT_OPTIONS,
} from '../CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown.config';

interface IPitchPageContext {
  isRapidGenerationView: boolean;
  isRapidGenerationResultsView: boolean;
  isRapidGenerationForAll: boolean;
  setIsRapidGenerationView: Dispatch<SetStateAction<boolean>>;
  isGenerating: boolean;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  setIsRapidGenerationForAll: Dispatch<SetStateAction<boolean>>;
  setIsRapidGenerationResultsView: Dispatch<SetStateAction<boolean>>;
  allSelectedItems: Record<number, number[]>;
  setAllSelectedItems: Dispatch<SetStateAction<Record<number, number[]>>>;
  selectedReportItems: IReportItemV31[];
  updateContentToneOptionByID?: (
    reportId: number,
    option: Record<number, IToneSelectOption>,
  ) => void;
  updateContentLengthOptionByID?: (
    reportId: number,
    option: Record<number, IContentLengthSelectOption>,
  ) => void;
  contentToneOptions?: Record<number, Record<number, IToneSelectOption>>;
  contentLengthOptions?: Record<
    number,
    Record<number, IContentLengthSelectOption>
  >;
  selectedItemsCount: number;
  currentItemsCount: number;
  currentPublicationsCount: number;
  setCurrentItemsCount: Dispatch<SetStateAction<number>>;
  globalTone: IToneSelectOption;
  globalLength: IContentLengthSelectOption;
  setGlobalTone: Dispatch<SetStateAction<IToneSelectOption>>;
  setGlobalLength: Dispatch<SetStateAction<IContentLengthSelectOption>>;
  setLocalTonesForRegeneration: Dispatch<
    SetStateAction<Record<number, IToneSelectOption>>
  >;
  localTonesForRegeneration: Record<number, IToneSelectOption>;
  localLengthsForRegeneration: Record<number, IContentLengthSelectOption>;
  setLocalLengthsForRegeneration: Dispatch<
    SetStateAction<Record<number, IContentLengthSelectOption>>
  >;
  resetSelectedItems: () => void;
}

export const PitchPageContext = createContext<IPitchPageContext>({
  isRapidGenerationView: false,
  isRapidGenerationForAll: true,
  isRapidGenerationResultsView: false,
  isGenerating: false,
  setIsGenerating: () => {},
  setIsRapidGenerationForAll: () => {},
  setIsRapidGenerationView: () => {},
  setIsRapidGenerationResultsView: () => {},
  allSelectedItems: [],
  setAllSelectedItems: () => {},
  selectedReportItems: [],
  selectedItemsCount: 0,
  currentItemsCount: 0,
  currentPublicationsCount: 0,
  setCurrentItemsCount: () => {},
  globalTone: TONE_SELECT_OPTIONS[0],
  globalLength: CONTENT_LENGTH_SELECT_OPTIONS[0],
  setGlobalTone: () => {},
  setGlobalLength: () => {},
  resetSelectedItems: () => {},
  setLocalLengthsForRegeneration: () => {},
  setLocalTonesForRegeneration: () => {},
  localTonesForRegeneration: {},
  localLengthsForRegeneration: {},
});

interface IProps {
  reports: IReportV31[];
}

export const PitchPageContextProvider: FC<IProps> = ({ children, reports }) => {
  const [isRapidGenerationView, setIsRapidGenerationView] =
    useState<boolean>(false);
  const [isRapidGenerationResultsView, setIsRapidGenerationResultsView] =
    useState<boolean>(false);
  const [isRapidGenerationForAll, setIsRapidGenerationForAll] = useState(true);

  const selectedItemsByReport = useMemo(() => {
    return reports
      .map(report => report.id)
      .reduce((o, key) => ({ ...o, [key]: [] }), {});
  }, [reports]);

  const [allSelectedItems, setAllSelectedItems] = useState<
    Record<number, number[]>
  >(selectedItemsByReport || {});

  const resetSelectedItems = () => {
    setAllSelectedItems(selectedItemsByReport);
  };

  const selectedItemsCount = useMemo(() => {
    const count = Object.values(allSelectedItems)
      .map(selection => selection.length)
      .reduce((acc, currentValue) => acc + currentValue, 0);
    return count;
  }, [allSelectedItems]);

  const [currentItemsCount, setCurrentItemsCount] = useState(0);

  const [globalTone, setGlobalTone] = useState<IToneSelectOption>(
    TONE_SELECT_OPTIONS[0],
  );

  const [globalLength, setGlobalLength] = useState<IContentLengthSelectOption>(
    CONTENT_LENGTH_SELECT_OPTIONS[0],
  );

  const [contentToneOptions, setContentToneOptions] = useState<
    Record<number, Record<number, IToneSelectOption>>
  >({});

  const [contentLengthOptions, setContentLengthOptions] = useState<
    Record<number, Record<number, IContentLengthSelectOption>>
  >({});

  const updateContentLengthOptionByID = (
    reportId: number,
    option: Record<number, IContentLengthSelectOption>,
  ) => {
    setContentLengthOptions(state => ({ ...state, [reportId]: option }));
  };

  const updateContentToneOptionByID = (
    reportId: number,
    option: Record<number, IToneSelectOption>,
  ) => {
    setContentToneOptions(state => ({ ...state, [reportId]: option }));
  };

  const [localTonesForRegeneration, setLocalTonesForRegeneration] = useState<
    Record<number, IToneSelectOption>
  >({});

  const [localLengthsForRegeneration, setLocalLengthsForRegeneration] =
    useState<Record<number, IContentLengthSelectOption>>({});

  const selectedReportItems = useMemo(() => {
    return Object.values(
      reports
        .filter(
          report =>
            allSelectedItems[report.id] &&
            allSelectedItems[report.id].length > 0,
        )
        .map(report =>
          //@ts-ignore
          report.reportItemsPage.content.filter(reportItem =>
            allSelectedItems[report.id].includes(reportItem.id),
          ),
        ),
    ).flat();
  }, [allSelectedItems, reports, contentToneOptions, contentLengthOptions]);

  const currentPublicationsCount = useMemo(() => {
    function mapPublicationIds(
      item: IJournalistReportItemV31 | IPodcasterReportItemV31,
    ) {
      if ('outlets' in item && item.outlets.length > 0) {
        return item.outlets[0].id;
      } else if ('podcast' in item) {
        return item.podcast?.id;
      }
    }
    return selectedReportItems
      .map(mapPublicationIds)
      .filter((value, index, self) => self.indexOf(value) === index).length;
  }, [selectedReportItems]);

  const [isGenerating, setIsGenerating] = useState(false);

  return (
    <PitchPageContext.Provider
      value={{
        isRapidGenerationView,
        isRapidGenerationForAll,
        isRapidGenerationResultsView,
        setIsRapidGenerationForAll,
        setIsRapidGenerationView,
        setIsRapidGenerationResultsView,
        allSelectedItems,
        setAllSelectedItems,
        selectedReportItems,
        updateContentLengthOptionByID,
        updateContentToneOptionByID,
        contentLengthOptions,
        contentToneOptions,
        selectedItemsCount,
        currentItemsCount,
        currentPublicationsCount,
        setCurrentItemsCount,
        isGenerating,
        setIsGenerating,
        globalTone,
        globalLength,
        setGlobalTone,
        setGlobalLength,
        resetSelectedItems,
        setLocalLengthsForRegeneration,
        setLocalTonesForRegeneration,
        localLengthsForRegeneration,
        localTonesForRegeneration,
      }}
    >
      {children}
    </PitchPageContext.Provider>
  );
};
