import styled from 'styled-components/macro';

export const SpinningAnimation = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  > * {
    animation: 1s spinner infinite;
    animation-timing-function: linear;
  }
`;
