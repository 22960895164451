import CopyIcon from 'app/assets/icons/CopyIcon';
import { SocialProviderIcon } from 'app/assets/icons/social-providers/SocialProviderIcon';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';
import { SOCIAL_FIELDS_CONFIG } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/DigDeeperSlideout/parts/Socials/Socials.config';
import { ToneSelectDropDown } from 'app/containers/Pitch/pages/CreateOrEditPitch/parts/ToneSelectDropDown/ToneSelectDropDown';
import { FC, useMemo, useRef, useState } from 'react';
import { useHover } from 'utils/hooks/useHover';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { SocialContentInput } from '../../CreateOrEditSocialPosts/CreateOrEditSocialPostsPage.styles';
import { ISocialPostContent, SocialPostType } from '../types/SocialPost.types';
import RegenerateIcon from './icons/RegenerateIcon';
import { getToneObjectByValue } from 'app/containers/Blog/pages/BlogPage/BlogPage.helpers';
import React from 'react';
import SocialPostsSkeleton from './LoadingSkeletons/SocialPostsSkeleton';

interface ISocialPostProps {
  socialPost: ISocialPostContent;
  socialPostContent: string;
  socialPostContentVideo?: string;
  handleContentChange: (
    content: string,
    socialPostType: SocialPostType,
  ) => void;
  handleVideoDescriptionChange: (
    content: string,
    socialPostType: SocialPostType,
  ) => void;
  handleRegenerate: (tone: string, contentType?: SocialPostType) => void;
  isLoading?: boolean;
}

const SocialPost: FC<ISocialPostProps> = ({
  socialPost,
  socialPostContent,
  socialPostContentVideo = '',
  handleContentChange,
  handleVideoDescriptionChange,
  handleRegenerate,
  isLoading,
}) => {
  const [regenerateTone, setRegenerateTone] = useState(
    getToneObjectByValue(socialPost.tone),
  );

  const displayVideoDescription = useMemo(() => {
    return (
      socialPost.postType === SocialPostType.TWITTER ||
      socialPost.postType === SocialPostType.INSTAGRAM
    );
  }, [socialPost.postType]);

  const socialPostRef = useRef<HTMLDivElement>(null);
  const isHoveredOver = useHover(socialPostRef);
  const socialProviderDisplayMeta = SOCIAL_FIELDS_CONFIG.find(
    socialProvider => socialProvider.value === socialPost.postType,
  );

  const isRegenerating = useMemo(() => isLoading, [isLoading]);

  const onContentChange = (content: string, isDescription?: boolean) => {
    if (isDescription) {
      handleVideoDescriptionChange(content, socialPost.postType);
    } else {
      handleContentChange(content, socialPost.postType);
    }
  };

  const onRegenerate = () => {
    handleRegenerate(regenerateTone.value, socialPost.postType);
  };

  const onTextCopy = async () => {
    try {
      let textToCopy = socialPostContent;

      if (socialPostContentVideo) {
        textToCopy += `\nSuggested Image / Video:\n${socialPostContentVideo}`;
      }

      await window.navigator.clipboard.writeText(textToCopy);
      showSuccessToast('Text copied to clipboard!');
    } catch (e) {
      showErrorToast(
        'Your browser does not support this function.',
        'Failed to copy text.',
      );
    }
  };

  if (!socialProviderDisplayMeta) {
    return <></>;
  }

  return (
    <FlexBlock
      flexDirection="column"
      rowGap="16px"
      ref={socialPostRef}
      minWidth="100%"
      style={{
        boxSizing: 'border-box',
        border: '1px solid transparent',
        ...(isHoveredOver
          ? {
              border: '1px solid rgba(228, 228, 233, 1)',
              borderRadius: '8px',
            }
          : undefined),
      }}
      padding="10px"
    >
      <FlexBlock
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        minWidth="100%"
      >
        <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
          <SocialProviderIcon socialType={socialPost.postType} />
          <Typography.Text $bold $dmSans $lineHeight={18} $size={16}>
            {socialProviderDisplayMeta.label === 'X'
              ? 'X/Threads'
              : socialProviderDisplayMeta.label}
          </Typography.Text>
        </FlexBlock>
        {isHoveredOver && !isRegenerating && (
          <FlexBlock flexDirection="row" alignItems="center" columnGap="8px">
            <ToneSelectDropDown
              value={regenerateTone}
              onChange={opt => setRegenerateTone(opt)}
              isCompact
              selectorHeight={40}
              selectorRadius={8}
            />

            <Button
              compact
              variant="transparent-compact"
              onClick={onRegenerate}
              padding="5px"
            >
              <FlexBlock alignItems="center" columnGap="8px">
                <RegenerateIcon />
                <Typography.Text
                  $size={16}
                  $lineHeight={18}
                  $colorName="onyx"
                  $bold
                  $dmSans
                >
                  Regenerate
                </Typography.Text>
              </FlexBlock>
            </Button>
            <Button
              compact
              variant="transparent-compact"
              onClick={onTextCopy}
              padding="5px"
            >
              <FlexBlock alignItems="center" columnGap="8px">
                <CopyIcon />
                <Typography.Text
                  $size={16}
                  $lineHeight={18}
                  $colorName="onyx"
                  $bold
                  $dmSans
                >
                  Copy
                </Typography.Text>
              </FlexBlock>
            </Button>
          </FlexBlock>
        )}
      </FlexBlock>
      {!isRegenerating ? (
        <>
          <SocialContentInput
            placeholder="Write your post here..."
            value={socialPostContent}
            onChange={e => onContentChange(e.target.value)}
            minRows={1}
            maxRows={25}
            disabled={false}
          />
          {displayVideoDescription && (
            <>
              <Typography.Text
                $lineHeight={20}
                $size={14}
                $colorName="onyx"
                $bold
                $dmSans
                $padding="0 0 0 8px"
              >
                Suggested Image / Video:
              </Typography.Text>
              <SocialContentInput
                placeholder="Write your image / video suggestion here..."
                value={socialPostContentVideo}
                onChange={e => onContentChange(e.target.value, true)}
                minRows={1}
                maxRows={25}
                disabled={false}
              />
            </>
          )}
        </>
      ) : (
        <SocialPostsSkeleton />
      )}
    </FlexBlock>
  );
};

export default React.memo(SocialPost);
