import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { globalSaga } from './saga';
import { GlobalState } from './types';
import {
  addClientToViewHistory,
  generateClientViewHistoryLsKey,
} from '../../../../utils/client-view-history';
import { userPermissionEnum } from '../permissions/userPermissions.enum';
import { RouteConstants } from '../../../routes';
import { localStorageEnum } from '../../../../types';
import { ITenant, IUser } from 'app/api.types';

export const initialState: GlobalState = {
  initialized: false,
  authorized: false,
  redirectTo: null,
  user: undefined,
  activeTenantId: null,
  activeTenantObject: null,
  needHelpModalOpen: false,
  permissions: [],
  stripeModalOpen: false,
  redirectAfterPayment: null,
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    authorizeUser(state, action: PayloadAction<{ redirectUrl?: string }>) {},
    reloadUser(state, action: PayloadAction<{}>) {},
    setUser(
      state,
      action: PayloadAction<{
        user: IUser | null;
        permissions?: userPermissionEnum[];
        skipPermissionUpdate?: boolean;
      }>,
    ) {
      if (action.payload.user) {
        state.user = {
          ...action.payload.user,
        };
        if (!action.payload.skipPermissionUpdate) {
          state.permissions = action.payload.permissions || [];
        }
        state.authorized = true;
        state.initialized = true;
      } else {
        state.user = undefined;
        state.permissions = [];
        state.authorized = false;
        state.initialized = true;
      }
    },
    redirectTo(state, action: PayloadAction<string | null>) {
      state.redirectTo = action.payload;
    },
    setActiveTenantId(state, action: PayloadAction<number>) {
      state.activeTenantId = action.payload;
      addClientToViewHistory(
        generateClientViewHistoryLsKey(state.user?.id || 0),
        action.payload,
      );
    },
    setActiveTenantObject(state, action: PayloadAction<ITenant>) {
      state.activeTenantObject = action.payload;
    },
    toggleNeedHelpModal(state, action: PayloadAction<boolean>) {
      state.needHelpModalOpen = action.payload;
    },
    toggleStripeModal(state, action: PayloadAction<boolean>) {
      state.stripeModalOpen = action.payload;
    },
    goToUpgradeToPro(state, action: PayloadAction<undefined>) {
      state.redirectAfterPayment = window.location.href;
      state.redirectTo = RouteConstants.userProfile.billing;
    },
    handleSuccessfulProUpgrade(
      state,
      action: PayloadAction<{ newToken: string }>,
    ) {
      localStorage.setItem(localStorageEnum.token, action.payload.newToken);
      if (state.redirectAfterPayment) {
        window.location.href = state.redirectAfterPayment;
      } else {
        window.location.reload();
      }
    },
  },
});

export const { actions: globalActions } = slice;

export const useGlobalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: globalSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGlobalSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
