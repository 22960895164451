import { SVGProps } from 'react';

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill={props.color || '#3F3F42'} transform="translate(-1868 -1028)">
        <g transform="translate(260 72)">
          <g transform="translate(1243 80)">
            <g transform="translate(353 864)">
              <g transform="translate(12 12)">
                <path d="M14.32 8.408c-.054.13-.133.249-.232.349l-4.525 4.53a1.066 1.066 0 01-1.507 0 1.07 1.07 0 01-.001-1.51l2.704-2.709H2.667a1.068 1.068 0 010-2.136h8.092l-2.704-2.71a1.07 1.07 0 010-1.51 1.066 1.066 0 011.508 0l4.525 4.531c.304.306.396.765.231 1.165z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowRightIcon;
