import { createContext, FC, ReactNode, useState } from 'react';
import { IToolbarTitlePortalState } from './ToolbarTitlePortal.types';

export const ToolbarTitlePortalContext =
  createContext<IToolbarTitlePortalState>({
    node: null,
    setNode: () => {},
    setBackButtonRoute: () => {},
    setGoBackButton: () => {},
    setTitle: () => {},
    title: undefined,
    backButtonRoute: undefined,
    goBackButton: undefined,
  });

interface IProps {}

export const ToolbarTitlePortalProvider: FC<IProps> = ({ children }) => {
  const [node, setNode] = useState<ReactNode | null>(null);
  const [backButtonRoute, setBackButtonRoute] = useState<string | undefined>(
    undefined,
  );
  const [goBackButton, setGoBackButton] = useState<boolean | undefined>(
    undefined,
  );

  const [title, setTitle] = useState<string | undefined>(undefined);

  return (
    <ToolbarTitlePortalContext.Provider
      value={{
        node,
        setNode,
        backButtonRoute,
        setBackButtonRoute,
        goBackButton,
        setGoBackButton,
        title,
        setTitle,
      }}
    >
      {children}
    </ToolbarTitlePortalContext.Provider>
  );
};
