import { FC, useMemo } from 'react';
import * as S from './Toolbar.styles';
import { useRouteMatch } from 'react-router-dom';
import { RouteConstants } from '../../../../routes';
import ClientSelector from './parts/ClientSelector';
import UserMenu from './parts/UserMenu';
import PitchDetailsHeader from './parts/PitchDetailsHeader';
import ToolbarTitlePortalConsumer from '../ToolbarTitlePortal/ToolbarTitlePortalConsumer';
import ProphetLogoIcon from 'app/assets/icons/ProphetLogo';
import { globalActions } from 'app/containers/Global/slice';
import { useAppDispatch } from 'store/hooks';
import NavigationSelector from './parts/NavigationSelector';

interface IProps {}

const Toolbar: FC<IProps> = () => {
  const projectsListPage = useRouteMatch(RouteConstants.projects.list);
  const contentListPage = useRouteMatch(RouteConstants.projects.contentList);
  const journalistsListPage = useRouteMatch(RouteConstants.journalists.list);
  const usersListPage = useRouteMatch(RouteConstants.people.list);

  const pitchDetailsPage = useRouteMatch<{
    versionId: string;
    pitchId: string;
  }>(RouteConstants.pitch.pitch);

  // Pitch Pages

  const pitchCreatePage = useRouteMatch<{ versionId: string; pitchId: string }>(
    RouteConstants.pitch.createPitch,
  );
  const pitchEditPage = useRouteMatch<{ versionId: string; pitchId: string }>(
    RouteConstants.pitch.editPitch,
  );

  const pitchPageData: {
    versionId?: number;
    page?: 'details' | 'create' | 'edit';
    pitchId?: number;
  } | null = useMemo(() => {
    if (!pitchCreatePage && !pitchEditPage && !pitchDetailsPage) return null;

    const versionId =
      pitchCreatePage?.params.versionId ||
      pitchEditPage?.params.versionId ||
      pitchDetailsPage?.params.versionId;

    const pitchId =
      pitchCreatePage?.params.pitchId ||
      pitchEditPage?.params.pitchId ||
      pitchDetailsPage?.params.pitchId;

    const page = pitchCreatePage
      ? 'create'
      : pitchDetailsPage
      ? 'details'
      : pitchEditPage
      ? 'edit'
      : undefined;

    return { versionId: Number(versionId), page, pitchId: Number(pitchId) };
  }, [pitchDetailsPage, pitchCreatePage, pitchEditPage]);

  const dispatch = useAppDispatch();

  return (
    <S.Wrapper>
      <S.ApplicationLogoContainer>
        <ProphetLogoIcon
          cursor="pointer"
          onClick={() => {
            if (!projectsListPage) {
              dispatch(
                globalActions.authorizeUser({
                  redirectUrl: RouteConstants.projects.list,
                }),
              );
            }
          }}
        />
      </S.ApplicationLogoContainer>
      <S.NavigationItemVerticalDivider />
      <S.ToolbarSection style={{ paddingLeft: '20px' }}>
        {Boolean(projectsListPage || journalistsListPage || usersListPage) && (
          <ClientSelector
            redirectUrlFn={
              usersListPage ? RouteConstants.people.makeListUrl : undefined
            }
          />
        )}
        {Boolean(contentListPage) && (
          <>
            <NavigationSelector />
            <ClientSelector />
          </>
        )}
        {Boolean(pitchPageData) && (
          <PitchDetailsHeader pitchData={pitchPageData} />
        )}
        <ToolbarTitlePortalConsumer />
      </S.ToolbarSection>
      <S.ToolbarSection justifyContent="flex-end">
        <UserMenu />
      </S.ToolbarSection>
    </S.Wrapper>
  );
};

export default Toolbar;
