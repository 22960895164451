import { useState, useEffect, useRef } from 'react';

function useAutoCompleteMenu(
  closeMenuAfterOptionSelect: boolean,
  searchValue: string,
  disabled: boolean,
  initialMenuOpenState: boolean,
  placeholder?: string,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [menuOpenState, setMenuOpen] = useState(initialMenuOpenState);
  const closeMenuAfterOptionSelectRef = useRef(closeMenuAfterOptionSelect);

  useEffect(() => {
    if (menuOpenState && closeMenuAfterOptionSelect) {
      setMenuOpen(false);
    }
  }, [placeholder]);

  useEffect(() => {
    closeMenuAfterOptionSelectRef.current = closeMenuAfterOptionSelect;
  }, [closeMenuAfterOptionSelect]);

  useEffect(() => {
    if (
      closeMenuAfterOptionSelectRef.current &&
      searchValue &&
      !menuOpenState &&
      !disabled
    )
      setMenuOpen(true);
  }, [searchValue, menuOpenState, disabled]);

  return [menuOpenState, setMenuOpen];
}

export default useAutoCompleteMenu;
