import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { Spinner } from './Spinner';

interface IProps {
  absolute?: boolean;
  showLoaderAtTop?: boolean;
}

const LoaderWrapper = styled.div<{
  absolute?: boolean;
  showLoaderAtTop?: boolean;
}>(
  ({ absolute, showLoaderAtTop }) => css`
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    padding: 24px;
    align-items: ${showLoaderAtTop ? 'flex-start' : 'center'};
    grid-column-start: 1;
    grid-column-end: -1;
    ${absolute
      ? css`
          position: absolute;
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, 0.06);
          top: 0;
        `
      : ''}
  `,
);

const PageLoader: FC<IProps> = ({ absolute, showLoaderAtTop }) => {
  return (
    <LoaderWrapper absolute={absolute} showLoaderAtTop={showLoaderAtTop}>
      <Spinner $isBlue $size="30px" />
    </LoaderWrapper>
  );
};

export default PageLoader;
