import { IEpisode } from '../../../../../../../api.types';
import { FC } from 'react';
import { Anchor } from '../../../../../../../components/UI/Anchor';
import Typography from '../../../../../../../components/Typography';
import { secondsToHms } from '../../../../../../../../utils/helpers';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';

interface IProps {
  episode: IEpisode;
}

const EpisodeListItem: FC<IProps> = ({ episode }) => {
  return (
    <FlexBlock
      flexDirection="column"
      padding="6px 0"
      rowGap="4px"
      margin="6px 0"
      key={episode.title}
    >
      {episode.url ? (
        <Anchor href={episode.url} target="_blank">
          {episode.title}
        </Anchor>
      ) : (
        <Typography.Text $colorName="orbit">{episode.title}</Typography.Text>
      )}
      <Typography.Text maxLines={5} title={episode.description}>
        {episode.description}
      </Typography.Text>
      <Typography.Text $colorName="steel">
        {secondsToHms(episode.lengthInSeconds)}
      </Typography.Text>
    </FlexBlock>
  );
};

export default EpisodeListItem;
