import { FC } from 'react';
import FlexBlock from '../../FlexBlock';
import { SkeletonDiv } from '../Skeletons.styles';

interface IProjectDisplaySkeletonProps {}

const ProjectDisplaySkeleton: FC<IProjectDisplaySkeletonProps> = () => {
  return (
    <FlexBlock
      flexDirection="row"
      columnGap="10px"
      minWidth="300px"
      alignItems="center"
      margin="10px 0 10px 0"
    >
      <SkeletonDiv
        shimmerAnimation
        height="56px"
        borderRadius="50%"
        width="56px"
      />
      <FlexBlock flexDirection="column" minWidth="70%" rowGap="5px">
        <SkeletonDiv
          shimmerAnimation
          height="24px"
          borderRadius="8px"
          width="60%"
        />
        <SkeletonDiv
          shimmerAnimation
          height="24px"
          borderRadius="8px"
          width="80%"
        />
      </FlexBlock>
    </FlexBlock>
  );
};

export default ProjectDisplaySkeleton;
