import { userPermissionEnum } from './userPermissions.enum';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserPermissions } from '../slice/selectors';
import { useMemo } from 'react';
import { satisfiesPermission } from './permissions.helpers';

const useHasPermission = (require?: userPermissionEnum) => {
  const userPermissions = useAppSelector(selectUserPermissions);

  return useMemo(() => {
    if (!require) return true;
    return satisfiesPermission(require, userPermissions);
  }, [require, userPermissions]);
};

export const useHasPermissionResolver = () => {
  const userPermissions = useAppSelector(selectUserPermissions);
  return function (require?: userPermissionEnum) {
    if (!require) return true;
    return satisfiesPermission(require, userPermissions);
  };
};

export default useHasPermission;
