import styled, { css } from 'styled-components/macro';

export const IndicatorDot = styled.div<{ color: string }>(
  ({ color }) => css`
    height: 10px;
    width: 10px;
    display: inline-block;
    background-color: ${color};
    border-radius: 50%;
  `,
);
