import { SVGProps } from 'react';

const GridViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#3F3F42" transform="translate(-336 -233)">
        <g transform="translate(260 72)">
          <g transform="translate(64 145)">
            <g transform="translate(0 4)">
              <g transform="translate(12 12)">
                <path d="M2.8 1.2H6a1.6 1.6 0 011.6 1.6V6A1.6 1.6 0 016 7.6H2.8A1.6 1.6 0 011.2 6V2.8a1.6 1.6 0 011.6-1.6zm7.2 0h3.2a1.6 1.6 0 011.6 1.6V6a1.6 1.6 0 01-1.6 1.6H10A1.6 1.6 0 018.4 6V2.8A1.6 1.6 0 0110 1.2zm0 7.6h3.2a1.6 1.6 0 011.6 1.6v3.2a1.6 1.6 0 01-1.6 1.6H10a1.6 1.6 0 01-1.6-1.6v-3.2A1.6 1.6 0 0110 8.8zm-7.2 0H6a1.6 1.6 0 011.6 1.6v3.2A1.6 1.6 0 016 15.2H2.8a1.6 1.6 0 01-1.6-1.6v-3.2a1.6 1.6 0 011.6-1.6z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GridViewIcon;
