import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $hoverMode?: boolean;
}>(
  ({ theme, $hoverMode }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-radius: 50%;
    outline: none;
    width: 96px;
    height: 96px;
    border: 1px solid ${$hoverMode ? '#1D76DE4D' : theme.colors.smoke};
    background: ${$hoverMode ? '#EBF2FB' : theme.colors.haze};
    padding: 4px;
  `,
);

export const LoadImageContainer = styled.div<{ $active: boolean }>(
  ({ theme, $active }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ${!$active ? '4px' : '0'};
    border: 2px dotted ${$active ? theme.colors.orbit : theme.colors.smoke};
    border-radius: 50%;
  `,
);

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const LoadedImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
`;
