import React from 'react';

function PencilIcon({ fill = '#FFFFFF' }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Pitch"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Project-1.0.0" transform="translate(-1450, -104)" fill={fill}>
          <g id="Content" transform="translate(0, 72)">
            <g id="right" transform="translate(1434, 12)">
              <g id="content" transform="translate(16, 16)">
                <g id="asset" transform="translate(0, 4)">
                  <path
                    d="M15.2384089,4.21556266 L11.7849259,0.762175495 C11.5530361,0.530279257 11.2385167,0.4 10.9105639,0.4 C10.5826112,0.4 10.2680918,0.530279257 10.036202,0.762175495 L8.00041756,2.7978774 L8.00030395,2.79799023 L8.00019112,2.79810383 L0.762236091,10.0357723 C0.529549722,10.2671412 0.399113727,10.5819889 0.400004533,10.9101191 L0.400004533,14.3635063 C0.40078977,15.0460743 0.953947556,15.5992103 1.63654243,15.5999955 L5.0900656,15.5999955 C5.41819017,15.6008852 5.73303363,15.4704689 5.96440903,15.2378153 L15.2384468,5.96418066 C15.7205325,5.48097561 15.7205156,4.69874683 15.2384089,4.21556266 L15.2384089,4.21556266 Z M12.7653423,6.68853189 L9.31183765,3.23514473 L10.9105423,1.63648449 L14.3640655,5.08987166 L12.7653423,6.68853189 Z"
                    id="Icon"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PencilIcon;
