import { FC, useMemo } from 'react';
import FlexBlock from '../UI/FlexBlock';
import EmptyDataNotice from '../EmptyDataNotice/EmptyDataNotice';
import { useParams } from 'react-router-dom';
import { errorCodes } from './error-codes';

interface IProps {}

const ErrorPage: FC<IProps> = () => {
  const params = useParams<{ type: string }>();

  const error = useMemo(() => {
    if (params.type) {
      const e = errorCodes[params.type];
      if (e) {
        return e;
      }
    }
    return {
      title: 'Error',
      description: `Unknown error. (Code: ${params.type || 'NONE'})`,
    };
  }, [params.type]);

  return (
    <FlexBlock>
      <EmptyDataNotice title={error.title} description={error.description} />
    </FlexBlock>
  );
};

export default ErrorPage;
