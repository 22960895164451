import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  flex: 1;
  grid-column-start: 1;
  grid-column-end: -1;
  height: 100%;
  padding: 180px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
`;

export const Image = styled.img`
  max-height: 200px;
`;
