import {
  IJournalistReportItemV31,
  IPodcasterReportItemV31,
  IReportItemV31,
} from 'app/api.types';

export function getReporterName(reportItem: IReportItemV31) {
  if ('journalist' in reportItem) {
    const journalistReportItem = reportItem as IJournalistReportItemV31;
    return journalistReportItem.journalist?.name || '';
  }

  if ('podcaster' in reportItem) {
    const podcasterReportItem = reportItem as IPodcasterReportItemV31;
    return podcasterReportItem.podcaster?.name || '';
  }

  return '';
}

export function getPublicationTitle(reportItem: IReportItemV31) {
  if ('podcast' in reportItem) {
    const podcasterReportItem = reportItem as IPodcasterReportItemV31;
    return podcasterReportItem.podcast.title;
  }

  if (reportItem.outlets && reportItem.outlets[0]) {
    const journalistReportItem = reportItem as IJournalistReportItemV31;
    return `${journalistReportItem.outlets[0].name} ${
      journalistReportItem.outlets[0].country || ''
    }`;
  }

  return '';
}
