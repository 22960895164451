import { IPressRelease } from 'app/api.types';
import Typography from 'app/components/Typography';
import Avatar from 'app/components/UI/Avatar';

import FlexBlock from 'app/components/UI/FlexBlock';
import { FC } from 'react';

export interface IPredictPanelHeaderProps {
  pressRelease?: IPressRelease;
}

const PredictPanelHeader: FC<IPredictPanelHeaderProps> = ({ pressRelease }) => {
  return (
    <FlexBlock
      flexDirection="row"
      justifyContent="left"
      alignItems="center"
      padding="32px 24px"
      minWidth="100%"
    >
      <Avatar
        name={pressRelease?.project.name}
        url={pressRelease?.project.logo}
        size="56"
      />
      <FlexBlock flexDirection="column">
        <Typography.Text
          $bold
          $dmSans
          $colorName="onyx"
          $size={20}
          $lineHeight={24}
        >
          Predict
        </Typography.Text>
        <Typography.Text
          $size={16}
          $lineHeight={24}
          $colorName="onyx"
          whiteSpace="break-spaces"
        >
          {pressRelease?.title}
        </Typography.Text>
      </FlexBlock>
    </FlexBlock>
  );
};

export default PredictPanelHeader;
