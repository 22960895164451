interface IProps {
  size?: number;
  fill?: string;
}

const WarningIcon = ({ size = 24, fill = '#fff' }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill={fill} transform="translate(-630 -956)">
        <g transform="translate(606 936)">
          <g transform="translate(24 20)">
            <path d="M23.071 18.525L14.115 3.021a2.444 2.444 0 00-4.23 0L.929 18.526a2.452 2.452 0 00-.002 2.45A2.442 2.442 0 003.043 22.2h17.914c.873 0 1.68-.467 2.116-1.225a2.452 2.452 0 00-.002-2.45zM11.185 9.96a.815.815 0 111.629 0v4.08a.815.815 0 11-1.63 0V9.96zM12 18.937a1.223 1.223 0 01-1.222-1.224A1.223 1.223 0 1112 18.937z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WarningIcon;
