import { SVGProps } from 'react';

const DropDownCarrotDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#1D76DE" transform="translate(-1549 -233)">
        <g transform="translate(260 72)">
          <g transform="translate(64 145)">
            <g transform="translate(1022)">
              <g transform="translate(53)">
                <g transform="translate(150 16)">
                  <path d="M1.32 4a.93.93 0 00-.652.266.905.905 0 000 1.285l6.676 6.583c.173.17.416.266.652.266a.917.917 0 00.652-.266l6.667-6.574a.901.901 0 00.022-1.294.937.937 0 00-1.304 0l-6.025 5.952L1.97 4.266A.93.93 0 001.319 4z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DropDownCarrotDown;
