import { ILatestArticle } from '../../../../../../../api.types';
import { FC, useEffect, useMemo } from 'react';

import Typography from '../../../../../../../components/Typography';
import {
  formatDate,
  generateObscuredContent,
} from '../../../../../../../../utils/helpers';
import FlexBlock from '../../../../../../../components/UI/FlexBlock';
import styled, { css } from 'styled-components';
import { commonAuthorTooltipProps } from 'app/components/UI/Tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';
import { MemoizedMediaOutlets } from './Outlets/MediaOutlets';
import { ArticleSimilarityScore } from './Articles/ArticleItems.styles';
import IfHasPermission from 'app/containers/Global/permissions/IfHasPermission';
import { userPermissionEnum } from 'app/containers/Global/permissions/userPermissions.enum';

interface IProps {
  article: ILatestArticle;
  borderBottom?: boolean;
  showRelevancyScore?: boolean;
}

const ContentWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ArticleListItemWrapper = styled.div<{ $border?: boolean }>(
  ({ theme, $border }) => css`
    ${$border
      ? css`
          border-bottom: 1px solid ${theme.colors.smoke};
        `
      : ''}
  `,
);

const ArticleListItem: FC<IProps> = ({
  article,
  borderBottom,
  showRelevancyScore = false,
}) => {
  const isArticleObscured = article.obscured || article.restricted;

  const articleTitleText = isArticleObscured
    ? generateObscuredContent(article.title)
    : article.title;

  const articleDescriptionText = isArticleObscured
    ? generateObscuredContent(article.description, true)
    : article.description;

  useEffect(() => {
    if (article.obscured || article.restricted) {
      ReactTooltip.rebuild();
    }
  }, [article]);

  const similarityScoreVariant = useMemo(() => {
    if (!article.similarityScore) return 'red';

    if (article.similarityScore >= 0.75 && article.similarityScore <= 1) {
      return 'green';
    }

    if (article.similarityScore >= 0.56 && article.similarityScore < 0.75) {
      return 'yellow';
    }

    if (article.similarityScore >= 0.4 && article.similarityScore < 0.56) {
      return 'red';
    }

    return 'red';
  }, [article.similarityScore]);

  return (
    <ArticleListItemWrapper $border={borderBottom}>
      <FlexBlock
        flexDirection="column"
        padding="6px 0"
        rowGap="4px"
        margin="6px 0"
        key={article.id}
        {...commonAuthorTooltipProps}
        data-tip={isArticleObscured && 'Enterprise Account'}
        data-for={isArticleObscured && 'upgradeArticlesTooltip'}
        blur={isArticleObscured ? 5 : undefined}
      >
        <Typography.Text
          $colorName="onyx"
          $size={12}
          $lineHeight={16}
          $padding="0 0 3px 0"
        >
          Featured in:{' '}
        </Typography.Text>
        <MemoizedMediaOutlets article={article} />
        <Typography.Text $bold>{articleTitleText}</Typography.Text>
        <Typography.Text $colorName="nero">
          <ContentWrapper>{articleDescriptionText}</ContentWrapper>
        </Typography.Text>
        <FlexBlock
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          minWidth="100%"
        >
          <Typography.Text $colorName="steel" $size={12} $lineHeight={16}>
            First published on: {formatDate(article.publishedAt)}
          </Typography.Text>
          {article.similarityScore && showRelevancyScore && (
            <IfHasPermission require={userPermissionEnum.SYSTEM_MANAGEMENT}>
              <ArticleSimilarityScore variant={similarityScoreVariant}>
                <Typography.Text $dmSans $bold $lineHeight={14} $size={12}>
                  {article.similarityScore.toFixed(2)}
                </Typography.Text>
              </ArticleSimilarityScore>
            </IfHasPermission>
          )}
        </FlexBlock>
      </FlexBlock>
    </ArticleListItemWrapper>
  );
};

export default ArticleListItem;
