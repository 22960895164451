import { SVGProps } from 'react';

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#BCBCC1" transform="translate(-1872 -228)">
        <g transform="translate(260 72)">
          <g transform="translate(1243 80)">
            <g transform="translate(0 56)">
              <g transform="translate(369 20)">
                <path d="M8 .4A7.6 7.6 0 1015.6 8 7.609 7.609 0 008 .4zm-.146 3.508a.877.877 0 110 1.754.877.877 0 010-1.754zm.73 8.184H8a.585.585 0 01-.585-.584V8a.585.585 0 110-1.17H8c.323 0 .585.263.585.585v3.508a.585.585 0 110 1.17z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default InfoIcon;
