import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
  size?: 24;
}
const LocationIcon: React.FC<IProps> = ({ size, ...props }) => {
  if (size === 24) {
    return (
      <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M12.3.6C7.495.6 3.6 4.428 3.6 9.15c0 7.41 7.54 14.25 8.7 14.25 1.16 0 8.7-6.84 8.7-14.25C21 4.428 17.105.6 12.3.6Zm0 11.97c-1.922 0-3.48-1.531-3.48-3.42s1.558-3.42 3.48-3.42 3.48 1.531 3.48 3.42a3.39 3.39 0 0 1-1.02 2.418 3.511 3.511 0 0 1-2.46 1.002Z"
          fill="#090909"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.2.4C4.997.4 2.4 2.952 2.4 6.1c0 4.94 5.027 9.5 5.8 9.5.773 0 5.8-4.56 5.8-9.5C14 2.952 11.403.4 8.2.4Zm0 7.98c-1.281 0-2.32-1.02-2.32-2.28 0-1.26 1.039-2.28 2.32-2.28s2.32 1.02 2.32 2.28a2.26 2.26 0 0 1-.68 1.612 2.34 2.34 0 0 1-1.64.668Z"
        fill="#9A9C9E"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default LocationIcon;
