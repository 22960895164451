import { SVGProps } from 'react';

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Calendar Icon</title>
    <path
      d="M14.333 1.23h-1.9V.616A.625.625 0 0 0 11.8 0a.625.625 0 0 0-.633.615v.616H4.833V.615A.625.625 0 0 0 4.2 0a.625.625 0 0 0-.633.615v.616h-1.9C.967 1.23.4 1.782.4 2.46V14.77C.4 15.45.967 16 1.667 16h12.666c.7 0 1.266-.551 1.267-1.23V2.461c0-.68-.567-1.23-1.267-1.231Zm0 3.693H1.667V2.462h1.9v.615c0 .34.283.615.633.615.35 0 .633-.275.633-.615v-.615h6.334v.615c0 .34.283.615.633.615.35 0 .633-.275.633-.615v-.615h1.9v2.461Z"
      fill="#9A9C9E"
      fillRule="evenodd"
    />
  </svg>
);

export default CalendarIcon;
