import { FC, useCallback, useMemo } from 'react';
import { IApiResponsePageablePart } from 'app/api.types';
import * as S from '../JournalistsTable.styles';
import Typography from 'app/components/Typography';
import { MAX_JOURNALISTS_TO_EXPORT } from '../JournalistsTable.constants';
import Button from 'app/components/UI/Button/Button';
import FlexBlock from 'app/components/UI/FlexBlock';

interface IProps {
  pageInfo: IApiResponsePageablePart;
  onPageNumberChange: (pageNumber: number) => void;
  totalElements: number;
  selectedCount?: number;
  resetSelectedRows?: () => void;
}

interface IPaginationPage {
  id: number;
  name: string;
  disabled?: boolean;
}

export const JournalistsTablePagination: FC<IProps> = ({
  pageInfo,
  onPageNumberChange,
  totalElements,
  selectedCount,
  resetSelectedRows,
}) => {
  const pages: IPaginationPage[] = useMemo(() => {
    const result: IPaginationPage[] = [];
    const totalNumberOfPages = Math.ceil(totalElements / pageInfo?.pageSize);

    if (totalNumberOfPages < 7) {
      result.push(
        ...Array(totalNumberOfPages)
          .fill('')
          .map((n, i) => ({
            id: i,
            name: `${i + 1}`,
          })),
      );
    } else {
      for (let i = 0; i < totalNumberOfPages; i++) {
        if (
          Math.abs(i - 0) < 3 ||
          Math.abs(totalNumberOfPages - i) < 3 ||
          Math.abs(pageInfo.pageNumber - i) < 2
        ) {
          result.push({
            id: i,
            name: `${i + 1}`,
          });
        } else if (!result[result.length - 1]?.disabled) {
          result.push({
            id: i,
            name: '...',
            disabled: true,
          });
        }
      }
    }

    return result;
  }, [pageInfo, totalElements]);

  const handleDeselectRows = useCallback(() => {
    if (resetSelectedRows) {
      resetSelectedRows();
    }
  }, [resetSelectedRows]);

  const exportSelectionNotice = useMemo(() => {
    if (!selectedCount || selectedCount < 1) return <></>;

    return (
      <FlexBlock
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        columnGap="8px"
        flexWrap="wrap"
      >
        <Typography.Text
          $colorName={
            selectedCount > MAX_JOURNALISTS_TO_EXPORT ? 'redOrange' : 'orbit'
          }
          $size={16}
          $lineHeight={20}
        >
          {selectedCount} / {MAX_JOURNALISTS_TO_EXPORT} Journalists Selected
        </Typography.Text>
        <Button variant="secondary" compact onClick={handleDeselectRows}>
          Deselect All
        </Button>
      </FlexBlock>
    );
  }, [selectedCount, handleDeselectRows]);

  if (pages.length < 2)
    return (
      <>
        {selectedCount && selectedCount > 0 ? (
          <S.PaginationWrapper>{exportSelectionNotice}</S.PaginationWrapper>
        ) : (
          <></>
        )}
      </>
    );

  return (
    <S.PaginationWrapper>
      {selectedCount && selectedCount > 0 ? (
        <>{exportSelectionNotice}</>
      ) : (
        <div></div>
      )}

      <FlexBlock
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        columnGap="8px"
      >
        {pages.map(r => (
          <S.PaginationButton
            onClick={() => onPageNumberChange(r.id)}
            disabled={r.disabled}
            active={pageInfo.pageNumber === r.id}
            key={r.name}
          >
            {r.name}
          </S.PaginationButton>
        ))}
      </FlexBlock>
    </S.PaginationWrapper>
  );
};
