import React from 'react';

function FolderIcon() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Pitch"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Start-3.0.0" transform="translate(-1542, -104)" fill="#FFFFFF">
          <g id="Content" transform="translate(0, 72)">
            <g id="right" transform="translate(1194, 12)">
              <g id="Atom/Button/★+Text/Medium" transform="translate(332, 0)">
                <g id="content" transform="translate(16, 16)">
                  <g id="asset" transform="translate(0, 4)">
                    <path
                      d="M14.7692308,3.67273457 L8.25480769,3.67273457 L6.15384615,1.56222459 C5.92359807,1.32955796 5.61023989,1.19912329 5.28365385,1.20000444 L1.23076923,1.20000444 C0.551312035,1.20068236 0.00067039548,1.75382655 0,2.43637174 L0,13.6113283 C0.000898127053,14.2676748 0.530516489,14.7994305 1.18389385,14.8 L14.83774,14.8 C15.4792653,14.799096 15.9991001,14.2768987 16,13.6324578 L16,4.90909739 C15.9993292,4.22655238 15.4486878,3.67340844 14.7692308,3.67273457 Z M1.23076923,2.43637174 L5.28365385,2.43637174 L6.51442308,3.67273457 L1.23076923,3.67273457 L1.23076923,2.43637174 Z"
                      id="Icon"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FolderIcon;
