import styled, { css } from 'styled-components';

export const Divider = styled.div<{ $margin?: string }>(
  ({ theme, $margin = '0' }) => css`
    height: 1px;
    width: auto;
    background: ${theme.colors.smoke};
    margin: ${$margin};
  `,
);

export const VerticalDivider = styled.div<{
  $margin?: string;
  $height?: string;
}>(
  ({ theme, $margin = '0', $height = 'auto' }) => css`
    height: ${$height};
    width: 1px;
    background: ${theme.colors.smoke};
    margin: ${$margin};
  `,
);
