import {
  ChangeEventHandler,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Modal from '../UI/Modal/Modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useGlobalSlice } from '../../containers/Global/slice';
import {
  selectNeedHelpModalOpen,
  selectUser,
} from '../../containers/Global/slice/selectors';
import FlexBlock from '../UI/FlexBlock';
import RoundIconWrapper from '../UI/RoundIconWrapper';
import HelpIcon from '../../assets/icons/HelpIcon';
import Typography from '../Typography';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import {
  asyncDelay,
  captureScreenShot,
  readFileAsDataUrl,
  validateEmail,
} from '../../../utils/helpers';
import * as S from './NeedHelpModal.styles';
import { TextArea } from '../UI/TextArea';
import { useSubmitFeedbackMutation } from '../../api';
import { showErrorToast } from '../../../utils/toast';
import CheckCircleIcon from '../../assets/icons/CheckCircleIcon';
import UploadIcon from '../../assets/icons/UploadIcon';
import { logger } from 'utils/apm/logger';

interface IProps {}

const NeedHelpModal: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const { actions } = useGlobalSlice();
  const modalOpen = useAppSelector(selectNeedHelpModalOpen);
  const user = useAppSelector(selectUser);

  const toggleModal: () => void = async () => {
    dispatch(actions.toggleNeedHelpModal(!modalOpen));
    await asyncDelay(400);
    setSubmitted(false);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const [images, setImages] = useState<string[]>([]);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [message, setMessage] = useState('');

  const isSubmitActive = useMemo(() => {
    return !!name && !!email && !!message;
  }, [name, email, message]);

  const [submitted, setSubmitted] = useState(false);

  const [submitFeedback, { isLoading, isSuccess, isError }] =
    useSubmitFeedbackMutation();

  const getScreenShot: () => void = async () => {
    await asyncDelay(2000); // Avoid UI render blocking
    const image = await captureScreenShot('main-app-content');
    setImages(state => [...state, image]);
  };

  useEffect(() => {
    if (modalOpen) {
      setImages([]);
      getScreenShot();

      setName(user ? `${user.firstName} ${user.lastName}` : '');
      setEmail(user ? `${user.email}` : '');
      setMessage('');
    }
  }, [modalOpen, user]);

  useEffect(() => {
    if (nameError && name) setNameError(null);
    if (emailError && validateEmail(email)) setEmailError(null);
  }, [name, email]);

  const validateFields: () => boolean = () => {
    let valid = true;

    if (!name) {
      valid = false;
      setNameError('Please fill your name');
    }

    if (!validateEmail(email)) {
      valid = false;
      setEmailError('Please enter valid email address');
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      submitFeedback({
        name,
        email,
        comments: message,
        images,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      // toggleModal();
      // showSuccessToast('Your feedback has been submitted');
      setSubmitted(true);
    }
    if (isError) {
      showErrorToast('Unknown Error. Please try again');
      logger.logError(
        'Need Help - Submit feedback - Could not submit feedback',
      );
    }
  }, [isSuccess, isError]);

  const onFileChange: ChangeEventHandler<HTMLInputElement> = async event => {
    if (
      event?.target?.files &&
      event?.target?.files[0] &&
      event?.target?.files[0].type.match('image/')
    ) {
      const image = await readFileAsDataUrl(event?.target?.files || undefined);

      if (image) {
        setImages(state => [...state, image]);
      }
    } else {
      showErrorToast(
        'File format is not supported. Please upload a jpg or png file.',
      );
    }
  };

  const onImageRemove = (index: number) => () => {
    setImages(prevState => prevState.filter((_, idx) => idx !== index));
  };

  return (
    <>
      <Modal open={modalOpen}>
        {!submitted ? (
          <>
            <FlexBlock padding="24px" justifyContent="center">
              <RoundIconWrapper size={88}>
                <HelpIcon />
              </RoundIconWrapper>
            </FlexBlock>

            <FlexBlock
              padding="0 24px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              rowGap="8px"
            >
              <Typography.Text $dmSans $bold $size={24}>
                Need Help?
              </Typography.Text>
              <Typography.Text>
                Please fill out form below and add any supporting screenshots.
              </Typography.Text>
            </FlexBlock>

            <FlexBlock flexDirection="column" rowGap="16px" padding="24px 40px">
              <Typography.Text $colorName="steel">Your Name</Typography.Text>

              <Input
                value={name}
                type="text"
                onChange={ev => setName(ev.target.value)}
                errors={nameError ? [nameError] : undefined}
              />

              <Typography.Text $colorName="steel">Your Email</Typography.Text>

              <Input
                value={email}
                type="email"
                onChange={ev => setEmail(ev.target.value)}
                errors={emailError ? [emailError] : undefined}
              />

              <Typography.Text $colorName="steel">Message</Typography.Text>

              <TextArea
                value={message}
                onChange={ev => setMessage(ev.target.value)}
              />
            </FlexBlock>

            <FlexBlock
              padding="0 40px"
              columnGap="8px"
              maxWidth="459px"
              flexWrap="wrap"
              rowGap="8px"
            >
              <input
                type="file"
                ref={inputRef}
                onChange={onFileChange}
                accept="image/*"
                hidden
              />

              {images.map((image, index) => (
                <S.ImageContainer>
                  <S.Image key={image} src={image} />
                  <S.RemoveButtonContainer>
                    <S.RemoveButton onClick={onImageRemove(index)} />
                  </S.RemoveButtonContainer>
                </S.ImageContainer>
              ))}

              <S.Image clickable onClick={() => inputRef?.current?.click()}>
                <UploadIcon />
              </S.Image>
            </FlexBlock>

            <FlexBlock
              justifyContent="center"
              columnGap="12px"
              padding="32px 0 40px"
            >
              <Button variant="secondary" onClick={toggleModal} compact>
                Cancel
              </Button>
              <Button
                compact
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={!isSubmitActive}
              >
                Submit
              </Button>
            </FlexBlock>
          </>
        ) : (
          <>
            <FlexBlock padding="24px" justifyContent="center">
              <RoundIconWrapper size={88}>
                <CheckCircleIcon />
              </RoundIconWrapper>
            </FlexBlock>

            <FlexBlock
              padding="0 24px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              rowGap="8px"
            >
              <Typography.Text $dmSans $bold $size={24}>
                Support Request Submitted
              </Typography.Text>
              <Typography.Text maxWidth="420px" textAlign="center">
                Thank you for reaching out! A member of the PRophet support team
                will contact you soon.
              </Typography.Text>
            </FlexBlock>

            <FlexBlock
              justifyContent="center"
              columnGap="12px"
              padding="32px 0 40px"
            >
              <Button variant="secondary" onClick={toggleModal} compact>
                Done
              </Button>
            </FlexBlock>
          </>
        )}
      </Modal>
    </>
  );
};

export default NeedHelpModal;
