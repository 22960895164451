import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import CheckBox from 'app/components/UI/CheckBox/CheckBox';
import FlexBlock from 'app/components/UI/FlexBlock';
import Select from 'app/components/UI/Select/Select';
import { FC, useMemo, useState } from 'react';

import { CollapsableWrapper, URLInput } from './AddSocial.styles';
import { api, useCreateAuthorSocialMutation } from 'app/api';
import { AuthorSocialType, CreatorType, IAuthorSocial } from 'app/api.types';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { ISocialTypeOption, SOCIAL_FIELDS_CONFIG } from './Socials.config';
import { CloseIcon } from 'app/components/UI/CloseIcon';
import { useAppDispatch } from 'store/hooks';
import { usePitchSlice } from 'app/containers/Pitch/slice';

interface IProps {
  authorId: string | number;
  isOpen: boolean;
  handleClose: () => void;
  creatorType: CreatorType;
  currentSocials: IAuthorSocial[];
}

export const AddSocial: FC<IProps> = ({
  authorId,
  isOpen,
  handleClose,
  creatorType,
  currentSocials,
}) => {
  const dispatch = useAppDispatch();
  const { actions } = usePitchSlice();

  const [isHidden, setIsHidden] = useState(false);

  const socialFieldsCountByType = useMemo(() => {
    const socialsCount = currentSocials.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.socialType]: (acc[cur.socialType] || 0) + 1,
      }),
      {} as { [key in AuthorSocialType]: number },
    );

    return socialsCount;
  }, [currentSocials]);

  const socialFieldsConfig = useMemo(() => {
    // Filter out social fields that have reached their max capacity
    return SOCIAL_FIELDS_CONFIG.filter(
      field =>
        !socialFieldsCountByType[field.value as AuthorSocialType] ||
        socialFieldsCountByType[field.value as AuthorSocialType] <
          field.maxFieldsOfType,
    );
  }, [socialFieldsCountByType]);

  const [socialType, setSocialType] = useState<null | ISocialTypeOption>(
    socialFieldsConfig[0],
  );

  const [socialUrl, setSocialUrl] = useState('');

  const [createSocial, { isLoading }] = useCreateAuthorSocialMutation();

  const handleSocialCreate = async () => {
    if (!socialType || !socialUrl || !authorId) {
      return;
    }

    if (socialType.validateField && !socialType.validateField(socialUrl)) {
      showErrorToast('Invalid Field.');
      return;
    }

    try {
      const socialField = await createSocial({
        creatorId: Number(authorId),
        socialType: socialType.value as AuthorSocialType,
        creatorType,
        handle: socialUrl,
        hidden: isHidden,
        disabled: false,
      }).unwrap();

      dispatch(
        api.util.invalidateTags([
          'pitch-prediction-report-paginated',
          'pitch-prediction',
          'author-search',
          'custom-target-media',
        ]),
      );

      dispatch(
        actions.updateCreatorsSocials({
          socialType: socialField.socialType,
          url: socialField.handle,
          hidden: socialField.hidden,
          creatorSocialId: socialField.creatorSocialId,
          disabled: socialField.disabled,
          handler: socialField.handle,
        }),
      );

      handleClose();
      showSuccessToast('Social field created.');
    } catch (error) {
      handleClose();
      showErrorToast('Could not create Social field.');
    }
  };

  const OptionLabelFormatter = (option: ISocialTypeOption) => (
    <FlexBlock
      flexDirection="row"
      columnGap="8px"
      alignItems="center"
      maxHeight="55px"
    >
      {option.icon && (
        <FlexBlock justifyContent="center" alignItems="center">
          {option.icon}
        </FlexBlock>
      )}
      <Typography.Text $size={16} inheritColor $lineHeight={24}>
        {option.label}
      </Typography.Text>
    </FlexBlock>
  );

  return (
    <CollapsableWrapper $isHidden={isOpen}>
      <FlexBlock
        flexDirection="column"
        justifyContent="left"
        alignItems="left"
        minWidth="100%"
      >
        <FlexBlock flexDirection="row" justifyContent="space-between">
          <Typography.Text $colorName="steel" $padding="0 0 5px 10px">
            Social Type
          </Typography.Text>
          <CloseIcon onClick={() => handleClose()} />
        </FlexBlock>

        <Select<ISocialTypeOption>
          value={socialType}
          options={socialFieldsConfig}
          formatOptionLabel={OptionLabelFormatter}
          onChange={option => {
            const o = option as ISocialTypeOption;
            setSocialType(o);
          }}
          width="470px"
          disabled={isLoading}
        />
      </FlexBlock>
      <FlexBlock
        flexDirection="column"
        justifyContent="left"
        alignItems="left"
        minWidth="100%"
      >
        <Typography.Text $colorName="steel" $padding="5px 0 5px 10px">
          Social handle URL
        </Typography.Text>

        <URLInput
          value={socialUrl}
          placeholder="Enter URL"
          onChange={e => setSocialUrl(e.currentTarget.value)}
          disabled={isLoading}
        />
      </FlexBlock>
      <FlexBlock
        flexDirection="row"
        columnGap="30px"
        margin="20px 0 20px 0 "
        minWidth="100%"
        justifyContent="space-around"
      >
        <FlexBlock flexDirection="column" alignItems="center">
          <Typography.Text
            $colorName="steel"
            $padding="0 0 5px 10px"
            $dmSans
            $bold
          >
            Hidden
          </Typography.Text>
          <CheckBox
            checked={isHidden}
            onChange={checked => {
              setIsHidden(checked);
            }}
          />
        </FlexBlock>
        <Button
          variant="secondary-blue"
          wide
          onClick={handleSocialCreate}
          disabled={isLoading}
        >
          Add Field
        </Button>
      </FlexBlock>
    </CollapsableWrapper>
  );
};
