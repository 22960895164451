import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGlobalSlice } from '../slice';
import { selectRedirectTo } from '../slice/selectors';

interface IProps {}

const ReduxRedirectWrapper: FC<IProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const globalActions = useGlobalSlice().actions;

  const redirectTo = useAppSelector(selectRedirectTo);
  const history = useHistory();

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      dispatch(globalActions.redirectTo(null));
    }
  }, [redirectTo, globalActions, dispatch, history]);

  return <>{children}</>;
};

export default ReduxRedirectWrapper;
