import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Star } from '../../icons/tone-icons/star.svg';
import { ReactComponent as BlackStar } from '../../icons/tone-icons/black-star.svg';

interface StarIconProps {
  width: number;
  height: number;
  color: string;
  isLoading?: boolean;
}

const IconWithColor = styled(Star)<{ color: string }>`
  fill: ${props => props.color};
  transition: fill 0.5s ease-in-out;
`;

const FavoriteIcon: React.FC<StarIconProps> = ({
  width,
  height,
  color,
  isLoading,
}) => {
  return (
    <>
      {!isLoading && color === 'completely-black' && (
        <BlackStar width={width} height={height} color="black" />
      )}
      {!isLoading && color !== 'completely-black' && (
        <IconWithColor width={width} height={height} color={color} />
      )}
    </>
  );
};

export default FavoriteIcon;
