import { useRequestAuthorContactInfoMutation } from 'app/api';
import EnvelopeBigIcon from 'app/assets/icons/EnvelopeBigIcon';
import EnvelopeIcon from 'app/assets/icons/EnvelopeIcon';
import TwitterIcon from 'app/assets/icons/TwitterIcon';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import CheckBox from 'app/components/UI/CheckBox/CheckBox';
import FlexBlock from 'app/components/UI/FlexBlock';
import Modal from 'app/components/UI/Modal/Modal';
import RoundIconWrapper from 'app/components/UI/RoundIconWrapper';
import { FC, useEffect, useState } from 'react';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { IPreparedReportItem } from '../MediaReportCard/MediaReportCard.helpers';
import { FlexBlockWithBorder } from './ContactsRequestModal.styles';

interface IProps {
  reportItem: IPreparedReportItem | null;
  onClose: () => void;
}

export const ContactsRequestModal: FC<IProps> = ({ reportItem, onClose }) => {
  const [requestEmailCheckbox, setRequestEmailCheckbox] = useState(false);
  const [requestTwitterCheckbox, setRequestTwitterCheckbox] = useState(false);

  const toggleSelectAll = () => {
    if (requestEmailCheckbox === requestTwitterCheckbox) {
      setRequestEmailCheckbox(!requestEmailCheckbox);
      setRequestTwitterCheckbox(!requestTwitterCheckbox);
    } else {
      setRequestEmailCheckbox(true);
      setRequestTwitterCheckbox(true);
    }
  };

  const [onRequestSubmit, { isLoading, isSuccess, isError }] =
    useRequestAuthorContactInfoMutation();

  const handleRequestSubmit = async () => {
    try {
      const socialsArray: string[] = [];

      if (requestEmailCheckbox) {
        socialsArray.push('PRIMARY_EMAIL');
      }
      if (requestTwitterCheckbox) {
        socialsArray.push('TWITTER');
      }

      if (!reportItem?.authorId || !socialsArray) {
        return;
      }

      await onRequestSubmit({
        authorId: reportItem.authorId,
        socials: socialsArray,
        targetMediaType: reportItem.targetMediaType ?? 'DEFAULT',
      }).unwrap();
    } catch (error) {
      // To Do: Handle contact information request error
    }
  };

  useEffect(() => {
    setRequestEmailCheckbox(false);
    setRequestTwitterCheckbox(false);
  }, [reportItem]);

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(
        'Your request has been submitted. A member of the PRophet support team reach out to you shortly.',
      );
      onClose();
    }
    if (isError) {
      showErrorToast('Unknown error');
    }
  }, [isSuccess, isError]);

  return (
    <Modal open={Boolean(reportItem)}>
      <FlexBlock
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="24px"
        maxWidth="511px"
      >
        <FlexBlock
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="16px"
          rowGap="24px"
          maxWidth="511px"
        >
          <RoundIconWrapper size={88}>
            <EnvelopeBigIcon />
          </RoundIconWrapper>

          <Typography.Text $dmSans $bold $size={24}>
            Request Contact
          </Typography.Text>

          <Typography.Text textAlign="center">
            You’re requesting contact information for{' '}
            <Typography.Text $bold>{reportItem?.authorName}</Typography.Text>,
            select which contact information you’d like PRophet to find for you.
          </Typography.Text>
        </FlexBlock>

        <FlexBlockWithBorder
          flexDirection="column"
          alignItems="start"
          rowGap="16px"
          padding="24px 40px"
          minWidth="100%"
        >
          <Button variant="anchor" onClick={toggleSelectAll}>
            {requestEmailCheckbox && requestTwitterCheckbox
              ? 'Deselect All'
              : 'Select All'}
          </Button>

          <FlexBlock alignItems="center" columnGap="8px">
            <CheckBox
              checked={requestEmailCheckbox}
              onChange={() => setRequestEmailCheckbox(!requestEmailCheckbox)}
            />

            <RoundIconWrapper size={40}>
              <EnvelopeIcon />
            </RoundIconWrapper>

            <Typography.Text $colorName="nero" $padding="0 0 0 4px">
              Email Address
            </Typography.Text>
          </FlexBlock>

          <FlexBlock alignItems="center" columnGap="8px">
            <CheckBox
              checked={requestTwitterCheckbox}
              onChange={() =>
                setRequestTwitterCheckbox(!requestTwitterCheckbox)
              }
            />

            <RoundIconWrapper size={40}>
              <TwitterIcon />
            </RoundIconWrapper>

            <Typography.Text $colorName="nero" $padding="0 0 0 4px">
              Twitter @username
            </Typography.Text>
          </FlexBlock>
        </FlexBlockWithBorder>

        <FlexBlock columnGap="12px">
          <Button compact variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            compact
            variant="primary"
            isLoading={isLoading}
            onClick={handleRequestSubmit}
            disabled={
              requestEmailCheckbox === false && requestTwitterCheckbox === false
            }
          >
            Request
          </Button>
        </FlexBlock>
      </FlexBlock>
    </Modal>
  );
};

export default ContactsRequestModal;
