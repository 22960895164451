import { FC, useEffect, useMemo, useState } from 'react';
import ContextMenu, {
  ContextMenuOption,
  IButtonTriggerProps,
} from '../../../../UI/ContextMenu/ContextMenu';
import { ClientItem } from '../Toolbar.styles';
import Avatar from '../../../../UI/Avatar';
import * as S from '../Toolbar.styles';
import CarrotDownIcon from '../../../../../assets/icons/CarrotDownIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  selectActiveTenantId,
  selectUser,
} from '../../../../../containers/Global/slice/selectors';
import { useGlobalSlice } from '../../../../../containers/Global/slice';
import ComponentLoader from '../../../../UI/ComponentLoader';
import Typography from '../../../../Typography';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';
import { RouteConstants } from 'app/routes';
import {
  generateClientViewHistoryLsKey,
  getClientViewHistory,
} from '../../../../../../utils/client-view-history';
import { TENANT_HISTORY_ITEMS_SAVED } from '../../../../../../utils/constants';
import Button from '../../../../UI/Button/Button';
import FlexBlock from '../../../../UI/FlexBlock';
import SideDrawer from '../../../../UI/SideDrawer/SideDrawer';
import RemoveIcon from '../../../../../assets/icons/RemoveIcon';
import Input from '../../../../UI/Input/Input';
import SearchGrayIcon from '../../../../../assets/icons/SearchGrayIcon';
import List, { ListItem } from '../../../../UI/List/List';
import ArrowRightIcon from '../../../../../assets/icons/ArrowRightIcon';
import useDebounce from 'utils/hooks/useDebounce';
import { useGetTenantsQuery, useLazyGetTenantsQuery } from 'app/api';
import { IUserListViewRouteParam } from 'app/routes.types';

interface IProps {
  redirectUrlFn?: (params: IUserListViewRouteParam) => string;
}

const ClientSelector: FC<IProps> = ({ redirectUrlFn }) => {
  const activeTenantId = useAppSelector(selectActiveTenantId);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { actions: globalActions } = useGlobalSlice();

  const params = useParams<{ tenantId?: string }>();

  const usersListPage = useRouteMatch<{ tenantId?: string }>(
    RouteConstants.people.list,
  );

  const displayActiveTenant = useMemo(() => {
    if (usersListPage && !usersListPage.params.tenantId) return false;
    return true;
  }, [usersListPage]);

  const canHideActiveTenant = useMemo(() => {
    if (usersListPage && usersListPage.params.tenantId) return true;
    return false;
  }, [usersListPage]);

  const routeClientId = params?.tenantId ? Number(params.tenantId) : null;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [clientSearchValue, setClientSearchValue] = useState('');

  const debouncedClientSearchValue = useDebounce(clientSearchValue, 500);

  const history = getClientViewHistory(
    generateClientViewHistoryLsKey(user?.id || 0),
  );

  const { data: recentTenants } = useGetTenantsQuery({
    ids: history.length ? history : undefined,
    tenantStatus: 'ACTIVE',
    pagination: {
      page: {
        page: 0,
        size: 5,
      },
      sort: [],
    },
  });

  const { data: activeTenant } = useGetTenantsQuery(
    {
      ids: [activeTenantId || 0],
      tenantStatus: 'ACTIVE',
      pagination: {
        page: {
          page: 0,
          size: 1,
        },
        sort: [],
      },
    },
    {
      skip: !activeTenantId,
    },
  );

  const { data: tenantsByName, isError: isClientSearchError } =
    useGetTenantsQuery({
      name: debouncedClientSearchValue,
      tenantStatus: 'ACTIVE',
      pagination: {
        page: {
          page: 0,
          size: 10,
        },
        sort: [{ property: 'name', ascending: true }],
      },
    });

  const [tenantsSearchTrigger] = useLazyGetTenantsQuery();

  const populateActiveTenantId = async (refreshTenantId?: boolean) => {
    if (refreshTenantId) {
      if (tenantsByName && tenantsByName.content[0]) {
        dispatch(globalActions.setActiveTenantId(tenantsByName.content[0].id));
      } else {
        try {
          const tenants = await tenantsSearchTrigger({
            tenantStatus: 'ACTIVE',
            pagination: {
              page: {
                page: 0,
                size: 10,
              },
              sort: [],
            },
          }).unwrap();
          if (tenants && tenants.content[0]) {
            dispatch(globalActions.setActiveTenantId(tenants.content[0].id));
          }
        } catch (error) {
          localStorage.removeItem(
            generateClientViewHistoryLsKey(user?.id || 0),
          );
          window.location.reload();
          return;
        }
      }
    } else {
      if (!activeTenantId) {
        if (history.length) {
          dispatch(globalActions.setActiveTenantId(history[0]));
        } else if (tenantsByName && tenantsByName.content[0]) {
          dispatch(
            globalActions.setActiveTenantId(tenantsByName.content[0].id),
          );
        }
      }
    }
  };

  useEffect(() => {
    populateActiveTenantId();
  }, [routeClientId, history, tenantsByName]);

  useEffect(() => {
    // Edge case: if tenant ID from history becomes not available, refresh active client id manually
    if (activeTenant && !activeTenant.content[0]) {
      if (activeTenantId === history[0]) {
        populateActiveTenantId(true);
      }
    }
  }, [activeTenant]);

  const tenant = useMemo(() => {
    if (!recentTenants || !recentTenants.content || !tenantsByName) return null;

    const tenants = [
      ...recentTenants?.content
        .filter(c => c.id !== activeTenantId)
        .sort((a, b) => history.indexOf(a.id) - history.indexOf(b.id)),
      ...tenantsByName.content.filter(
        c => c.id !== activeTenantId && !history.includes(c.id),
      ),
    ];

    const currentTenant = activeTenant?.content[0] || null;

    return {
      current: currentTenant,
      list: tenants,
    };
  }, [activeTenantId, recentTenants, history]);

  useEffect(() => {
    if (!activeTenantId && tenant?.current) {
      dispatch(globalActions.setActiveTenantId(tenant.current.id));
    }
  }, [tenant?.current, activeTenantId]);

  useEffect(() => {
    if (sidebarOpen) setSidebarOpen(false);
  }, [activeTenantId]);

  useEffect(() => {
    if (!sidebarOpen) {
      setClientSearchValue('');
    }
  }, [sidebarOpen]);

  useEffect(() => {
    if (activeTenant && activeTenant.content[0] && activeTenant.content[0].id) {
      dispatch(globalActions.setActiveTenantObject(activeTenant.content[0]));
    }
  }, [activeTenant]);

  const renderButtonTrigger = ({ onClick, open }: IButtonTriggerProps) => {
    const hasMultipleTenants = Boolean(tenant?.list && tenant.list.length > 1);

    const currentTenantToRender = activeTenant && activeTenant.content[0];

    if (!displayActiveTenant && hasMultipleTenants)
      return (
        <S.DropDownTriggerButton.Wrapper
          onClick={hasMultipleTenants ? onClick : () => {}}
          flipSvg={open}
        >
          <Typography.Text $colorName="white" $dmSans $bold $size={20}>
            All Tenants
          </Typography.Text>
          <CarrotDownIcon />
        </S.DropDownTriggerButton.Wrapper>
      );

    return !currentTenantToRender ? (
      <ComponentLoader />
    ) : (
      <S.DropDownTriggerButton.Wrapper
        onClick={hasMultipleTenants ? onClick : () => {}}
        flipSvg={open}
      >
        <Avatar
          url={currentTenantToRender?.logo || undefined}
          name={currentTenantToRender?.name}
          size="40"
        />
        <Typography.Text $colorName="white" $dmSans $bold $size={20}>
          {currentTenantToRender?.name || '-'}
        </Typography.Text>
        {hasMultipleTenants && <CarrotDownIcon />}
      </S.DropDownTriggerButton.Wrapper>
    );
  };

  const showSidebarTrigger: boolean = useMemo(() => {
    return (
      (tenantsByName &&
        tenantsByName.content.length > TENANT_HISTORY_ITEMS_SAVED + 1) ||
      false
    );
  }, [tenantsByName]);

  return (
    <>
      <ContextMenu
        buttonText="client"
        renderButtonTrigger={renderButtonTrigger}
        menuOptions={{
          minWidth: '412px',
          align: 'left',
          distanceFromTrigger: 16,
          positionOffsetX: 16,
        }}
      >
        {!displayActiveTenant && activeTenant && activeTenant.content[0] && (
          <NavLink
            to={
              redirectUrlFn
                ? redirectUrlFn({
                    tenantId: activeTenant.content[0].id,
                    view: 'users',
                  })
                : RouteConstants.projects.makeContentListByTenantUrl(
                    activeTenant.content[0].id,
                  )
            }
            key={activeTenant.content[0].id}
          >
            <ContextMenuOption hoverAnimation="blue-text" $padding="6px 24px">
              <ClientItem>
                <Avatar
                  name={activeTenant.content[0].name}
                  url={activeTenant.content[0].logo || undefined}
                  size="40"
                />
                <Typography.Text $dmSans $bold $size={16} $lineHeight={16}>
                  {activeTenant.content[0].name}
                </Typography.Text>
              </ClientItem>
            </ContextMenuOption>
          </NavLink>
        )}
        {canHideActiveTenant && redirectUrlFn && (
          <NavLink
            to={redirectUrlFn({
              view: 'users',
            })}
            key={'all-tenants'}
          >
            <ContextMenuOption hoverAnimation="blue-text" $padding="6px 24px">
              <ClientItem>
                <Avatar name="" url={undefined} size="40" />
                <Typography.Text $dmSans $bold $size={16} $lineHeight={16}>
                  All Tenants
                </Typography.Text>
              </ClientItem>
            </ContextMenuOption>
          </NavLink>
        )}
        {tenant?.list.slice(0, 5).map(tenant => (
          <NavLink
            to={
              redirectUrlFn
                ? redirectUrlFn({ tenantId: tenant.id, view: 'users' })
                : RouteConstants.projects.makeContentListByTenantUrl(tenant.id)
            }
            key={tenant.id}
          >
            <ContextMenuOption hoverAnimation="blue-text" $padding="6px 24px">
              <ClientItem>
                <Avatar
                  name={tenant.name}
                  url={tenant.logo || undefined}
                  size="40"
                />
                <Typography.Text $dmSans $bold $size={16} $lineHeight={16}>
                  {tenant.name}
                </Typography.Text>
              </ClientItem>
            </ContextMenuOption>
          </NavLink>
        ))}
        {showSidebarTrigger && (
          <FlexBlock
            alignItems="stretch"
            flexDirection="column"
            minWidth="100%"
            padding="16px 24px"
          >
            <Button variant="gray" compact onClick={() => setSidebarOpen(true)}>
              <Typography.Text $lineHeight={16} $dmSans $bold>
                Browse All Tenants
              </Typography.Text>
            </Button>
          </FlexBlock>
        )}
      </ContextMenu>
      <SideDrawer open={sidebarOpen}>
        <FlexBlock
          justifyContent="space-between"
          padding="24px"
          alignItems="flex-start"
        >
          <FlexBlock flexDirection="column" padding="8px">
            <Typography.Text $colorName="steel" $lineHeight={24}>
              Tenants
            </Typography.Text>
            <Typography.Text $dmSans $bold $size={20} $lineHeight={24}>
              All Tenants
            </Typography.Text>
          </FlexBlock>

          <Button
            variant="icon-button-border"
            onClick={() => setSidebarOpen(false)}
          >
            <RemoveIcon />
          </Button>
        </FlexBlock>

        <FlexBlock padding="8px 32px 8px 24px">
          <Input
            variant="searchBar-autoWidth"
            icon={<SearchGrayIcon />}
            placeholder="Search for Tenant..."
            value={clientSearchValue}
            onChange={e => setClientSearchValue(e.target.value)}
          />
        </FlexBlock>

        <FlexBlock flexDirection="column" alignItems="stretch">
          <List>
            {!tenantsByName && isClientSearchError && (
              <Typography.Text
                $margin="10px 30px"
                $dmSans
                $size={18}
                $lineHeight={24}
              >
                Something went wrong. Try again later.
              </Typography.Text>
            )}
            {tenantsByName?.content.map(tenant => {
              return (
                <NavLink
                  to={
                    redirectUrlFn
                      ? redirectUrlFn({ tenantId: tenant.id, view: 'users' })
                      : RouteConstants.projects.makeContentListByTenantUrl(
                          tenant.id,
                        )
                  }
                  key={tenant.id}
                >
                  <ListItem
                    title={tenant.name}
                    avatarUrl={tenant.logo}
                    boldTitle
                    clickable
                    hazeHover
                    subTitle={
                      tenant.projectCount
                        ? `${tenant.projectCount} Project${
                            tenant.projectCount > 1 ? 's' : ''
                          }`
                        : '0 Projects'
                    }
                    rightIcon={<ArrowRightIcon color="rgb(188, 188, 193)" />}
                  />
                </NavLink>
              );
            })}
          </List>
        </FlexBlock>
      </SideDrawer>
    </>
  );
};

export default ClientSelector;
