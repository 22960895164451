import { toast, ToastOptions } from 'react-toastify';
import styled, { css } from 'styled-components/macro';
import CheckCircleIcon from '../app/assets/icons/CheckCircleIcon';
import WarningIcon from '../app/assets/icons/WarningIcon';
import { ReactNode } from 'react';

// Applies to all toasts
export const BASE_TOAST_CONFIG: ToastOptions = {
  position: 'bottom-center',
  autoClose: 3500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  icon: false,
};

export const ToastMessageStyle = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    column-gap: 12px;

    a {
      color: ${({ theme }) => `${theme.colors.white}`};
      text-decoration: underline;
    }
  `,
  Icon: styled.div`
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #fff;
      }
    }
  `,
  Title: styled.div(
    ({ theme }) => css`
      font-family: ${theme.fonts.dmSans};
      font-weight: bold;
      font-size: 16px;
      color: #fff;
    `,
  ),
  Body: styled.div(
    ({ theme }) => css`
      font-family: ${theme.fonts.inter};
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      flex: 1;
      text-align: right;
    `,
  ),
  VioletToastWrapper: styled.div`
    background-color: #372175;
    display: flex;
    align-items: center;
    flex: 1;
    column-gap: 12px;
  `,
  VolatileRedToastWrapper: styled.div`
    background-color: #ca3023;
    display: flex;
    align-items: center;
    flex: 1;
    column-gap: 12px;
  `,
};

export const showErrorToast = (
  message: string,
  title?: string,
  options?: ToastOptions,
) => {
  const mTitle = title || message || 'Error';
  const mBody = title ? message : '';
  toast.error(
    <ToastMessageStyle.Wrapper>
      <ToastMessageStyle.Icon>
        <WarningIcon />
      </ToastMessageStyle.Icon>
      <ToastMessageStyle.Title
        dangerouslySetInnerHTML={{
          __html: mTitle,
        }}
      />
      <ToastMessageStyle.Body dangerouslySetInnerHTML={{ __html: mBody }} />
    </ToastMessageStyle.Wrapper>,
    { ...BASE_TOAST_CONFIG, ...(options ? options : {}) },
  );
};

export const showSuccessToast = (
  message: string | ReactNode,
  title?: string | ReactNode,
) => {
  const mTitle = title || message || 'Error';
  const mBody = title ? message : '';
  toast.info(
    <ToastMessageStyle.Wrapper>
      <ToastMessageStyle.Icon>
        <CheckCircleIcon />
      </ToastMessageStyle.Icon>
      <ToastMessageStyle.Title>{mTitle}</ToastMessageStyle.Title>
      <ToastMessageStyle.Body>{mBody}</ToastMessageStyle.Body>
    </ToastMessageStyle.Wrapper>,
    BASE_TOAST_CONFIG,
  );
};

export const showCustomToast = (
  body: ReactNode,
  toastClassName?: string,
  toastId?: string,
) => {
  toast.info(<ToastMessageStyle.Wrapper>{body}</ToastMessageStyle.Wrapper>, {
    ...BASE_TOAST_CONFIG,
    className: toastClassName,
    autoClose: 7500,
    toastId: toastId ?? undefined,
  });
};

export const createPermanentToast = (body: ReactNode) => {
  const id = toast.info(
    <ToastMessageStyle.Wrapper>{body}</ToastMessageStyle.Wrapper>,
    {
      ...BASE_TOAST_CONFIG,
      autoClose: false,
      closeOnClick: false,
    },
  );

  return {
    id,
    close() {
      toast.dismiss(id);
    },
  };
};

// async function showTestToasts() {
//   showSuccessToast('Success is the key.');
//   await asyncDelay(2000);
//   showErrorToast('Some Errors.');
//   await asyncDelay(2000);
//   // showTestToasts();
// }
//
// // showTestToasts();
