import { FC, useState, MouseEvent, ReactNode } from 'react';
import Modal from 'app/components/UI/Modal/Modal';
import RoundIconWrapper from 'app/components/UI/RoundIconWrapper';
import FlexBlock from 'app/components/UI/FlexBlock';
import WarningIcon from 'app/assets/icons/WarningIcon';
import Typography from 'app/components/Typography';
import Button from 'app/components/UI/Button/Button';
import { IPromptModal } from './PromptModal.types';
import { PromptModalContext } from './PromptModal.context';
import usePortal from '../../../../../utils/hooks/usePortal';
import WandIcon from '../../../../assets/icons/WandIcon';
import CheckmarkIcon from '../../../../assets/icons/CheckmarkIcon';

interface IProps {}

export const PromptModalProvider: FC<IProps> = ({ children }) => {
  const [modals, setModals] = useState<IPromptModal[]>([]);

  const portal = usePortal('modal-prompt-provider');

  const createModal = (modal: IPromptModal) => {
    setModals(state => [
      ...state,
      { ...modal, id: new Date().getTime().toString(17), open: true },
    ]);
  };

  const handleOkClick: (
    modal: IPromptModal,
  ) => (event: MouseEvent<Element>) => void = modal => event => {
    if (modal.okButtonCallback) {
      modal.okButtonCallback(event);
    }
    setModals(state =>
      state.map(item => ({
        ...item,
        open: item.id === modal.id ? false : item.open,
      })),
    );
  };

  const handleCancelClick: (
    modal: IPromptModal,
  ) => (event: MouseEvent<Element>) => void = modal => event => {
    if (modal.cancelButtonCallback) {
      modal.cancelButtonCallback(event);
    }
    setModals(state =>
      state.map(item => ({
        ...item,
        open: item.id === modal.id ? false : item.open,
      })),
    );
  };

  const renderModal = (modal: IPromptModal, children: ReactNode) => {
    if (modal.renderInPortal) {
      return portal.renderInPortal(children);
    }
    return children;
  };

  const getIcon = (icon: IPromptModal['icon']) => {
    switch (icon) {
      case 'wand':
        return <WandIcon />;
      case 'checkmark':
        return <CheckmarkIcon />;
      default:
        return <WarningIcon />;
    }
  };

  return (
    <>
      <PromptModalContext.Provider value={{ createModal }}>
        {children}
      </PromptModalContext.Provider>

      {modals.map(modal =>
        renderModal(
          modal,
          <Modal open={Boolean(modal.open)} key={modal.id}>
            <FlexBlock padding="24px" justifyContent="center">
              <RoundIconWrapper size={88} svgSize="32" svgFillBlack>
                {getIcon(modal.icon)}
              </RoundIconWrapper>
            </FlexBlock>
            <FlexBlock
              padding="0 24px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              rowGap="8px"
              maxWidth="512px"
            >
              <Typography.Text $dmSans $bold $size={24}>
                {modal.title}
              </Typography.Text>
              <Typography.Text textAlign="center">
                {modal.description || ''}
              </Typography.Text>
            </FlexBlock>

            <FlexBlock
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              padding="24px 40px"
              columnGap="8px"
            >
              {modal.cancelButtonText && (
                <Button
                  variant="secondary"
                  compact
                  onClick={handleCancelClick(modal)}
                >
                  {modal.cancelButtonText}
                </Button>
              )}
              <Button
                variant={modal.okButtonDanger ? 'danger' : 'primary'}
                compact
                onClick={handleOkClick(modal)}
              >
                {modal.okButtonText || 'OK'}
              </Button>
            </FlexBlock>
          </Modal>,
        ),
      )}
    </>
  );
};
