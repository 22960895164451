import Typography from 'app/components/Typography';
import FlexBlock from 'app/components/UI/FlexBlock';
import { FC, useMemo, useState } from 'react';
import { localStorageEnum } from 'types';
import { taylorSelectorStyles } from '../TaylorSettings/TaylorSettings.constants';
import Button from 'app/components/UI/Button/Button';
import Gap from 'app/components/UI/Gap';
import { IOption } from 'types/commonTypes';
import Select from 'app/components/UI/Select/Select';
import styled, { css } from 'styled-components';
import APMSettings from './APMSettings';

interface ILocalSettingsProps {}

const Divider = styled.div(
  ({ theme }) => css`
    height: 1px;
    width: 100%;
    background-color: ${theme.colors.smoke};
    grid-column-start: 1;
    grid-column-end: -1;
    margin: 20px 16px;
  `,
);

const LocalSettings: FC<ILocalSettingsProps> = () => {
  const handleUpdate = () => {
    const updatedFlag = showSimilarArticlesScore;
    localStorage.setItem(
      localStorageEnum.digDeeperSimilarArticlesScore,
      String(updatedFlag),
    );
    setShowSimilarArticlesScore(updatedFlag);
  };

  const [showSimilarArticlesScore, setShowSimilarArticlesScore] =
    useState<boolean>(() => {
      const storedValue = localStorage.getItem(
        localStorageEnum.digDeeperSimilarArticlesScore,
      );
      return storedValue === 'true';
    });

  const selectedOption = useMemo(() => {
    if (showSimilarArticlesScore) return { label: 'Yes', value: 'true' };
    return { label: 'No', value: 'false' };
  }, [showSimilarArticlesScore]);

  const options = useMemo(() => {
    return [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ];
  }, []);

  return (
    <FlexBlock flexDirection="column" margin="25px 25px 80px" minWidth="90%">
      <FlexBlock flexDirection="column" minWidth="200px" margin="0 0 0 10px">
        <Typography.Text $dmSans $bold $size={16} $margin="0 0 0 10px">
          Show Relevant Articles score in Dig Deeper panel
        </Typography.Text>
        <Gap size={12} />
        <Select<IOption<string>>
          {...taylorSelectorStyles}
          options={options}
          value={selectedOption}
          onChange={val =>
            setShowSimilarArticlesScore(
              (val as IOption<string>).value === 'true',
            )
          }
        />
        <Gap size={12} />
        <Button
          type="button"
          variant="secondary"
          compact
          onClick={handleUpdate}
        >
          Update
        </Button>
      </FlexBlock>
      <Divider />
      <APMSettings />
    </FlexBlock>
  );
};

export default LocalSettings;
